import React from 'react'
import FlowContainer from '../../common/container/core/FlowContainer'
import { getSalesDetails, getSalesTxns } from '../../../actions/loan_product_actions'
import CommonReactTable from '../../../helpers/commonReact_table'
import { Link } from 'react-router-dom'
import { Homeacc_prvdrLogoselector, lbl_amt, page_count, rt_algn, title_case } from '../../../helpers/common_helper'
import moment from 'moment'
import { TooltipLabel } from '../../common/partial'
import {ImArrowUpRight,ImArrowDownLeft} from "react-icons/im";
import { Modal } from 'react-bootstrap'
import { FcCalendar, FcExternal, FcInternal } from 'react-icons/fc'
import { getFloatSwitchAccounts, getRefAccounts } from '../../../actions/account_txn_actions'
import DatePicker from 'react-datetime';
import { dd_value } from '../../../actions/common_actions'
import Select from "react-select";
import { Config } from '../../../helpers/storage_helper'
import withRouter from '../../../Router/Withrouter'
import { BasicReactTable } from '../../../helpers/react_table'


 class SalesContainer extends FlowContainer {


	state={
			sales_search:{},
			row_data:[],
			column_data:[],
			loader:false,
			no_data:false,
			txn_data:[],
			txn_column:[],
			txn_modal:false,
			paginate : Config('paginate') ? Config('paginate') : null,
			salesData: {},
			modify_search:false,
			floatIn: [],
			floatOut:[],
			total_count:0,
			paymentProof: null
	}
	componentWillMount(){
		const searchParams = this.props.params.cust_id;
		this.loadAccounts()
		this.load_flow_rel_mgr(null,"multiselect_rm_name")
		this.setOptionsListToStateFromAppApi('common', 'list', {...this.def_req ,status: "enabled", biz_account: true}, ['sales_status', 'name'], "sales_status");
		if(searchParams!="report"){
		  this.set_request_data(searchParams)			
		}

	}

	set_request_data = (searchParams) =>{

		var sales = {}
		sales['req_parameter'] = searchParams
		sales['initiated_from'] = moment().subtract(1, 'months').date(moment().date()).format('YYYY-MM-DD')
		sales['initiated_to'] =  moment().format('YYYY-MM-DD')
		this.setState({sales_search:sales,params_value:searchParams},()=>{this.handleSubmit(true,searchParams)})
	}

	loadAccounts = () => {
		var account = {"country_code": this.country_code};    
		  getFloatSwitchAccounts(this.req({account},true))
			.then((response) => {
			this.resp(response)
			  if(!response){return };
			  if(response.status === "success"){
				if(response.data != ""){
				  this.setState({accounts:response.data.acc_prvdrs})
				}     
			  }else{ 
				alert(response.message + "\n" + JSON.stringify(response.data));  
			  }
			}
		  );
	  }

	 

	getTxn=(data)=>{
		this.setState({sales_doc_id : data.sales_doc_id, salesData : data},()=>this.setState({txn_modal:true, loader:true}))
		const photoPaymentProof = JSON.parse(data.photo_payment_proof);
		this.setPaymentProof(photoPaymentProof);
		var request={
			sales_doc_id: data.sales_doc_id
		}
		getSalesTxns(this.req(request))
		.then((response)=>{
			this.resp(response)
			if(!response)return;
			
			if(response.status=='success'){
				const floatInData = response.data.filter(val => val.txn_type == 'float_in');
                const floatOutData = response.data.filter(val => (val.txn_type == 'float_out' || val.txn_type =='float_in_refunded'));
			
				this.setState({
					floatIn : floatInData.length > 0 ? floatInData[0] : [],
					floatOut : floatOutData.length >0 ? floatOutData[0] : [],
					sales_doc_id : data.sales_doc_id
				},()=>this.setState({loader:false}))
			}else{
				this.setState({loader:false})
			}
		})
	}
	setPaymentProof = (proof) =>{
		let lastProof = null;
		if (Array.isArray(proof) && proof.length > 0) {
			 lastProof = proof[proof.length - 1];
		}

		this.setState({paymentProof : lastProof});
	}

	handleSubmit=(onMount,type=null)=>{
		this.setState({loader:true,no_data:false,row_data:[]})
		const { sales_search } = this.state;
		const newSalesSearch = sales_search.status == null ? { ...sales_search, status: undefined } : { ...sales_search };
		var request = { sales_search: newSalesSearch };
		if(this.state.paginate != null){
			request['paginate'] = this.state.paginate
		}
		if(onMount){
			if(type != "report"){
				request = {sales_search: this.state.sales_search}
			}else{
				request = {
					sales_search: {
					  req_parameter: type !== "report" ? type : undefined,
					  created_at: moment().format('YYYY-MM-DD')
					}
				  };
			}
		
		  }
		// getSalesDetails(this.req(request))
		// .then((response)=>{
		// this.resp(response)
		// if(!response){return this.setState((state)=>{state.loader=false;state.no_data=true})}
		// if(response.status=='success'){
		// 	if(response.data.results.length<=0){this.setState({no_data:true})}
		// 	this.setState((state)=>{state.loader=false})
		// 	this.setState({row_data:response.data.results, total_count:response.data.total_count ? response.data.total_count : null },()=>this.setState({column_data:this.columnData()}))
		// }
		// })
		getSalesDetails(this.req(request))
			.then((response)=>{
			this.resp(response)
			if(!response){return this.setState({loader : false})}
			if(response.status=='success'){
				if(response.data.results.length<=0){this.setState({no_data:true})}
				this.setState({loader : false})
				this.setState({row_data:response.data.results, total_count:response.data.total_count ? response.data.total_count : null },()=>this.setState({column_data:this.columnData()}))
			}
		})
	}
	
	columnData=()=>{
		return[
			// {
			// 	Header: "Channel",
			// 	id: "channel",
			// 	accessor: (row) => row.channel,
			// 	Cell: (cell) => {
			// 	  return <>{title_case(cell.value)}</>;
			// 	},
			//   },
			  {
				Header : <div className='text-center'>From</div>,
				id : "from_acc_prvdr_code",
				accessor: row=>row.from_acc_prvdr_code,
				Cell: (cell) => {
					return <div style={{marginLeft : "25%"}}><Homeacc_prvdrLogoselector logotype={cell.value}/>
					</div>
				}
			  },
			  {
				Header : <div className='text-center'>To</div>,
				id : "to_acc_prvdr_code",
				accessor: row=>row.to_acc_prvdr_code,
				Cell: (cell) => {
					return <div style={{marginLeft : "25%"}}><Homeacc_prvdrLogoselector logotype={cell.value}/>
					</div>
				}
			  },
			  {
				Header: <div className='text-center'>Float Switch ID</div>,
				id: "sales_doc_id",
				accessor: row=>row.sales_doc_id,
				Cell: (cell) => {
					return <>{title_case(cell.value)}</>;
				},
			  },
			  {
				Header:<div className='text-center'>Status</div> ,
				id: "status",
				accessor: row=>row.status,
				Cell: (cell) => {
					return <>{dd_value(cell.value, cell.value)}</>;
				},
			  },
			  {
				Header: <div className='d-flex justify-content-center'>Customer Name</div>,
				id: "cust_name",
				accessor: row=>row.cust_name,
				Cell: (cell) => {
					return <>{cell.value} 
					</>
				}
			  },
			  {
				Header: <div className='d-flex justify-content-center'>Customer ID</div>,
				id: "cust_id",
				accessor: row=>row.cust_id,
				Cell: (cell) => {
					return <><Link  to={"/borrower/indiv/view/"+cell.value} target="_blank" >{title_case(cell.value)}</Link></>;
				},  
			  },
			  {
				Header:<div className='d-flex justify-content-center'>Mobile Number</div>,
				id: "cust_mobile_num",
				accessor: row=>row.cust_mobile_num,
				Cell: (cell) => {
					return <>{title_case(cell.value)}</>;
				},
			  },
			//   {
			// 	Header: "Purpose",
			// 	id: "purpose",
			// 	accessor: row=>row.purpose,
			// 	Cell: (cell) => {
			// 		return <>{title_case(cell.value)}</>;
			// 	},
			//   },
			  {
				Header:<div className='text-right'>Float Received</div>,
				id: "product_amt",
				accessor: row=>row.product_amt,
				Cell: (cell) => {
					return <div>{ cell.value? rt_algn(lbl_amt(cell.value,this.currency_code)):''}</div>;
				},
			  },
			  {
				Header: <div className='text-right' style={{marginRight:"10%"}}>Fee</div>,
				id: "product_fee",
				accessor: row=>row.product_fee,
				Cell: (cell) => {
					return <div>{cell.value? rt_algn(lbl_amt(cell.value,this.currency_code)):''}</div>;
				},
			  },
			  {
				Header: <div className='text-right'>Float Sent</div>,
				id: "transfer_amt",
				accessor: row=>row.transfer_amt,
				Cell: (cell) => {
					return <>{cell.value? rt_algn(lbl_amt(cell.value,this.currency_code)):''}</>;
				},
			  },
			//   {
			// 	Header: <div className='text-center'>Switch to</div>,
			// 	id: "is_flow_acc_transfer",
			// 	accessor: row=>row.is_flow_acc_transfer,
			// 	Cell: (cell) => {
			// 		return <>{cell.value == true ? "Other Flow Customer" : "Own Account"}</>;
			// 	},
			//   },
			  {
				Header:  <div className='d-flex justify-content-center'>Initiated at</div>,
				id: "created_at",
				accessor: row=>row.created_at,
				Cell: (cell) => {
					return <>{cell.value==null ? 'NA' : moment(cell.value).format("YYYY-MM-DD HH:mm")}</>;
				},
			  },
			  {
				Header:  <div className='d-flex justify-content-center'>Float Received at</div>,
				id: "payment_date",
				accessor: row=>row.payment_date,
				Cell: (cell) => {
					return <>{cell.value==null ? 'NA' : moment(cell.value).format("YYYY-MM-DD HH:mm")}</>;
				},
			  },
			  {
				Header:  <div className='d-flex justify-content-center'>Float Sent at</div>,
				id: "delivery_date",
				accessor: row=>row.delivery_date,
				Cell: (cell) => {
					return <>{cell.value==null ? 'NA' : moment(cell.value).format("YYYY-MM-DD HH:mm")}</>;
				},
			  },
			  {
				Header: <div className='d-flex justify-content-center'>Channel</div>,
				id: "channel",
				accessor: row=>row.channel,
				Cell: (cell) => {
					return <>{title_case(cell.value)}</>;
				},
			  },
			  {
				Header: <div className='d-flex justify-content-center'>Action</div>,
				id: "view_transaction",
				accessor: row=><button className='btn-md text-white product_takeup_submit table_btn_pad' data-toggle="modal" data-target="#txnModal" onClick={()=>this.getTxn(row)} disabled={this.state.calling_api}>View Txns</button>
			  }
		]
	}

	chk_txn_type(txn_type){

		  if (txn_type === 'float_out') {
			  return<span > <ImArrowUpRight className="mr-2" color="red"/> {title_case(txn_type)}</span>
		  }
		  else{
			  return <span> <ImArrowDownLeft className="mr-2" color = "green"/> {title_case(txn_type)}</span>
		  }
	}

	txn_column_data=()=>{
		return [
			{
				Header : "SI No.",
				id: "row",
				width:50,
				Cell: (cell) => {
					return <div className='text-left pl-2'>{cell.index+1}</div>;
				}
			},
			{
				Header: <div className='text-center'>Transaction type</div>,
				id: "txn_type",
				accessor: row=>row.txn_type,
				minWidth:130,
				Cell: (cell) => {
					{return <div className='text-center'>{this.chk_txn_type(cell.value)}</div>}
				},
			  },
			  {
				Header: <div className='text-center'>Transaction Amount</div>,
				id: "amount",
				accessor: row=>row.amount,
				minWidth:130,
				Cell: (cell) => {
					return <div style={{paddingRight: '25%'}}>{rt_algn(lbl_amt(cell.value,this.currency_code))}</div>;
				},
			  },
			  {
				Header: <div className='text-center'>Transaction Date / Time</div>,
				id: "txn_date",
				accessor: row=>row.txn_date,
				minWidth:150,
				Cell: (cell) => {
					return <div className='text-center'>{moment(cell.value).format('DD-MMM-YYYY hh-mm a')}</div>
				}
			},
				{
				Header: <div className='text-center'>From Account</div>,
				id: "from_ac_name",
				accessor: row=>row.from_ac_name,
				Cell: (cell) => {
					return <><div className='text-center'><TooltipLabel className='d-block' full_text={cell.value} no_reduce={false} /></div>
					</>
				}
			  },
			  {
				Header: <div className='text-center'>To Account</div>,
				id: "to_ac_name",
				accessor: row=>row.to_ac_name,
				Cell: (cell) => {
					return <><div className='text-center'><TooltipLabel className='d-block' full_text={cell.value}  no_reduce={false} /></div>
					</>
				}
			  },
			  {
				Header: <div className='text-center'>Transaction ID</div>,
				id: "txn_id",
				accessor: row=>row.txn_id,
				Cell: (cell) => {
					return <div className='text-center'>{cell.value} 
					</div>
				}
			  },
		]
	}
	handleFilter = (e,name) => {
	
		var id;
		var value;
		if(Array.isArray(e)){
			this.setMultiselectOptionOnState(e,name,this.state.sales_search)
			this.setState({select_value:e})
		}
		else{
		 id = e.target.id;
		 value = e.target.value;
		}
		this.setState(prevState => ({
		  ...prevState,sales_search: {...prevState.sales_search,[id]: value}
		}));
	}

	handleDatepicker(value, name){
		value = moment(value).format("YYYY-MM-DD");
		if (value === "Invalid date") {
			const updatedSalesSearch = { ...this.state.sales_search };
			delete updatedSalesSearch[name];
			this.setState({ sales_search: updatedSalesSearch });
		} else {
			this.setState((prevState) => ({sales_search: {...prevState.sales_search,[name]: value,}}));
		}
	}

	

	oncheckBoxChange = (e)=>{
		var checkbox = document.getElementById('flexCheckDefault');
		var isChecked = checkbox.checked;
		if(isChecked == true){
			this.setState({sales_search :{...this.state.sales_search,is_flow_acc_transfer : true}})
		}else{
			this.setState({sales_search :{...this.state.sales_search,is_flow_acc_transfer : false}})
		}
	}
	handleClose=()=>{
		this.setState({txn_modal:false,txn_data:[]})
	}
	
	pagination = (page, count, sorted)=>{
			this.setState((state) => {
				return {
				  paginate: {
					...state.paginate,
					pagination_count: count,
					pagination_page:page,
					sorting:sorted,
					total_count : this.state.total_count
				  },
				};
			  }, ()=>this.handleSubmit());
		

		
	}

	handleExportCSV = (event) => {

		const { sales_search } = this.state;
		const newSalesSearch = sales_search.status == null ? { ...sales_search, status: undefined } : { ...sales_search };
		let search_criteria = JSON.parse(JSON.stringify(newSalesSearch));
		let resp = null;
		if(event == 'email') {
			search_criteria.export = 'email'
			resp = window.confirm(`The current search exceeds ${Config("export_csv").limit} rows. So the exported data will be sent to your email shortly.`)
		}

		if(event == 'email' && !resp){
			return
		}else{
			this.handleCSVapi(search_criteria, event)
		}
	}
	
	handleCSVapi(sales_search, action){
		let calling_api = true 
		var request={sales_search, paginate : false}
		if (action == 'email') calling_api = false 
		
		getSalesDetails(this.req(request))
			.then((response) => {
				this.resp(response)
				if(action == 'email'){
					return
				}

				if(response){
					this.setState(state=>state.export_data = response.data.results)
				}
				
			});

		}

	checkVal = (value, type = null) => {
        if(value == null || value == undefined){
            return 'NA';
        }else{
            if (type == 'date'){
                return moment(value).format('DD MMM YYYY hh:mm a');
            }else if(type =='amt'){
                return lbl_amt(value,this.currency_code);
            }else{
                return  value;
            }
            
        }
        
    }


	parseAccountName =(accountName, type = null) => {
	
		if (accountName == undefined || accountName == null) {
			return null;
		}

		const regex = /^(\d+)\s\(([^-]+)\s-/;
		const match = accountName.match(regex);
	
		if (match) {
			if(type == 'acc_num'){
				return match[1];
			}else if(type == 'prvdr'){
				return match[2].trim();
			}else{
				return accountName;
			}
		}else{
			return null;
		}
	  
	}

	calulate_txn_amount = (amount = null, fee = null, reward_amt = null, status) => {
		if(amount && fee && status !='refunded'){
			return lbl_amt( (amount-fee)+reward_amt , this.currency_code)
		}else if(status =='refunded'){
			return lbl_amt( amount , this.currency_code)
		}else if(amount == null){
			return lbl_amt( 0 , this.currency_code)
		}
	}

  render() {

	let {salesData, paymentProof} = this.state;

    return (
      <div>
		
		{/* <Modal className='txn_modal' show={this.state.txn_modal} onHide={this.handleClose} backdrop="static" keyboard={false}>
			<Modal.Header className='p-0 sms_for_close' closeButton>
				<Modal.Title className='sms_modal_header p-2'><h4 className=' text-center text-light'>Transactions of Float Switch ID : {this.state.sales_doc_id}</h4></Modal.Title>
			</Modal.Header>
			<Modal.Body className='text-left '>
				<div className="w-100 ">
				{this.state.txn_data&&this.state.txn_data.length>0?
					<CommonReactTable row_data={this.state.txn_data} column_data={this.state.txn_column} showPag={this.state.txn_data.length > 10}  minRow={0} /> 
				:
					<h4 className='text-center p-3'>No Data Found</h4>
				}
				</div>
			</Modal.Body>
			<Modal.Footer>
				
			</Modal.Footer>
		</Modal> */}

		<Modal className='txn_modal' show={this.state.txn_modal} onHide={this.handleClose} backdrop="static" keyboard={false}>
		<Modal.Header className='p-0 sms_for_close' closeButton>
			<Modal.Title className='sms_modal_header p-2'><h4 className=' text-center text-light'>Transactions of Float Switch ID : {this.state.sales_doc_id}</h4></Modal.Title>
		</Modal.Header>
			
			<Modal.Body className='text-left '>
				{ this.state.txn_modal && !this.state.loader ?
				<div className='px-4'>
					<div className=' text-center' style={{backgroundColor:'#3D3E53'}}>
						<p className='text-white m-0 p-3 bold' style={{fontSize:'16px'}} >Customer Details</p>
					</div>

					<div className='py-3'>
						<div className='d-flex switch_txn_pad'>
							<p className='m-0 col-3 txn_model_txt' >Customer ID</p>
							<Link to={"/borrower/indiv/view/"+this.state.salesData.cust_id} target="_blank" ><p className='m-0  txn_model_subTxt' style={{fontSize :'15px' , color:'#17A2B8'}}>{this.state.salesData.cust_id}</p></Link>
						</div>
						<div className='d-flex switch_txn_pad'>
							<p className='m-0 col-3 txn_model_txt' >Customer Name</p>
							<p className='m-0 txn_model_txt txn_model_subTxt'>{this.state.salesData.cust_name}</p>
						</div>
						<div className='d-flex switch_txn_pad'>
							<p className='m-0 col-3 txn_model_txt' >Switch Amount</p>
							<p className='m-0 txn_model_txt txn_model_subTxt'>{lbl_amt(this.state.salesData.product_amt,this.currency_code)}</p>
						</div>
						<div className='d-flex switch_txn_pad'>
							<p className='m-0 col-3 txn_model_txt' >Fee</p>
							<p className='m-0 txn_model_txt txn_model_subTxt'>{lbl_amt(this.state.salesData.product_fee,this.currency_code)}</p>
						</div>
						<div className='d-flex switch_txn_pad'>
							<p className='m-0 col-3 txn_model_txt' >Transaction Amount</p>
							<p className='m-0 txn_model_txt txn_model_subTxt'>{this.calulate_txn_amount(salesData.product_amt, salesData.product_fee,salesData.reward_amt,salesData.status)}</p>
						</div>
					</div>

					<div className=' row'>
			
						<div  className='col-sm-12 col-md-6 col-lg-6'>
							<div className=' text-center' style={{backgroundColor:'#3D3E53'}}>
								<p className='text-white m-0 p-3 bold' style={{fontSize:'16px'}} >Customer Transfer Details</p>
							</div>
						
							<div className='py-3'>
								<div className='d-flex switch_txn_pad align-items-center'>
									<p className='m-0 col-6 txn_model_txt' >From A/C</p>
									<img className={`img-fluid ${this.checkVal(this.state.floatIn.from_ac_name) == 'NA' ? 'd-none' : ''}`} style={{width:'8%', height:'10%'}} src={`/img/${this.parseAccountName(this.state.floatIn.from_ac_name, 'prvdr')}_logo_color.png`} alt={this.parseAccountName(this.state.floatIn.from_ac_name, 'prvdr')}/> 
									<p className={`${this.checkVal(this.state.floatIn.from_ac_name) == 'NA' ? '': 'pl-3'} m-0 txn_model_subTxt txn_model_txt`} >{this.checkVal(this.parseAccountName(this.state.floatIn.from_ac_name, 'acc_num'))}</p>
								</div>
								<div className='d-flex switch_txn_pad align-items-center'>
									<p className='m-0 col-6 txn_model_txt' >To A/C</p>
									<img className={`img-fluid ${this.checkVal(this.state.floatIn.to_ac_name) == 'NA' ? 'd-none' : ''}`} style={{width:'8%', height:'10%'}} src={`/img/${this.parseAccountName(this.state.floatIn.to_ac_name, 'prvdr')}_logo_color.png`} alt={this.parseAccountName(this.state.floatIn.to_ac_name, 'prvdr')}/>	
									<p className={`${this.checkVal(this.state.floatIn.to_ac_name) == 'NA' ? '': 'pl-3'} m-0 txn_model_subTxt txn_model_txt`} >{this.checkVal(this.parseAccountName(this.state.floatIn.to_ac_name, 'acc_num'))}</p>
								</div>
								<div className='d-flex switch_txn_pad align-items-center'>
									<p className='m-0 col-6 txn_model_txt' >Amount</p>
									<p className='m-0 txn_model_subTxt txn_model_txt' >{this.checkVal(this.state.floatIn.amount, 'amt')}</p>
								</div>
								<div className='d-flex switch_txn_pad align-items-center'>
									<p className='m-0 col-6 txn_model_txt' >Transaction ID</p>
									<p className='m-0 txn_model_subTxt txn_model_txt' >{this.checkVal(this.state.floatIn.txn_id)}</p>
								</div>
								<div className='d-flex switch_txn_pad align-items-center'>
									<p className='m-0 col-6 txn_model_txt' >Transaction Time</p>
									<p className='m-0 txn_model_subTxt txn_model_txt' >{this.checkVal(this.state.floatIn.txn_date, 'date')}</p>
								</div>
							</div>
						
						</div>
						<div  className='col-sm-12 col-md-6 col-lg-6'>
							<div className=' text-center' style={{backgroundColor:'#3D3E53'}}>
								<p className='text-white m-0 p-3 bold' style={{fontSize:'16px'}} >Flow Transfer Details</p>
							</div>
						
							<div className='py-3'>
								<div className='d-flex switch_txn_pad align-items-center'>
									<p className='m-0 col-6 txn_model_txt' >From A/C</p>
									<img className={`img-fluid ${this.checkVal(this.state.floatOut.from_ac_name) == 'NA' ? 'd-none' : ''}`} style={{width:'8%', height:'10%'}} src={`/img/${this.parseAccountName(this.state.floatOut.from_ac_name, 'prvdr')}_logo_color.png`} alt={this.parseAccountName(this.state.floatOut.from_ac_name, 'prvdr')}/>	
									<p className={`${this.checkVal(this.state.floatOut.from_ac_name) == 'NA' ? '': 'pl-3'} m-0 txn_model_subTxt txn_model_txt`} >{this.checkVal(this.parseAccountName(this.state.floatOut.from_ac_name, 'acc_num'))}</p>
								</div>
								<div className='d-flex switch_txn_pad align-items-center'>
									<p className='m-0 col-6 txn_model_txt' >To A/C</p>
									<img className={`img-fluid ${this.checkVal(this.state.floatOut.to_ac_name) == 'NA' ? 'd-none' : ''}`} style={{width:'8%', height:'10%'}} src={`/img/${this.parseAccountName(this.state.floatOut.to_ac_name, 'prvdr')}_logo_color.png`} alt={this.parseAccountName(this.state.floatOut.to_ac_name, 'prvdr')}/>	
									<p className={`${this.checkVal(this.state.floatOut.to_ac_name) == 'NA' ? '': 'pl-3'} m-0 txn_model_subTxt txn_model_txt`} >{this.checkVal(this.parseAccountName(this.state.floatOut.to_ac_name, 'acc_num'))}</p>
								</div>
								<div className='d-flex switch_txn_pad align-items-center'>
									<p className='m-0 col-6 txn_model_txt' >Amount</p>
									<p className='m-0 txn_model_subTxt txn_model_txt' >{this.checkVal(this.state.floatOut.amount, 'amt')}</p>
								</div>
								<div className='d-flex switch_txn_pad align-items-center'>
									<p className='m-0 col-6 txn_model_txt' >Transaction ID</p>
									<p className='m-0 txn_model_subTxt txn_model_txt' >{this.checkVal(this.state.floatOut.txn_id)}</p>
								</div>
								<div className='d-flex switch_txn_pad align-items-center'>
									<p className='m-0 col-6 txn_model_txt' >Transaction Time</p>
									<p className='m-0 txn_model_subTxt txn_model_txt' >{this.checkVal(this.state.floatOut.txn_date, 'date')}</p>
								</div>
							</div>
						
						</div>
							
					</div>

					<div >
						<div className=' text-center' style={{backgroundColor:'#3D3E53'}}>
							<p className='text-white m-0 p-3 bold' style={{fontSize:'16px'}} >Switch Progress</p>
						</div>
						<div className='py-3'>
							<div className='d-flex switch_txn_pad'>
								<p className='m-0 col-3 txn_model_txt' >Status</p>
								<p className='m-0 txn_model_txt txn_model_subTxt' >{salesData.status == 'dropped' ? "Failed" : salesData.status == 'pymt_proof_uploaded' ? "Payment Proof Uploaded" : dd_value(salesData.status, salesData.status)}</p>
							</div>
							<div className='d-flex switch_txn_pad'>
								<p className='m-0 col-3 txn_model_txt' >Switch Initiated</p>
								<p className='m-0 txn_model_txt txn_model_subTxt' >{moment(salesData.created_at).format('DD MMM YYYY hh:mm a')}</p>
							</div>
							{salesData.payment_date && 
								<div className='d-flex switch_txn_pad'>
									<p className='m-0 col-3 txn_model_txt'>Payment received</p>
									<p className='m-0 txn_model_txt txn_model_subTxt' >{moment(salesData.payment_date).format('DD MMM YYYY hh:mm a')}</p>
								</div>
							}

							{paymentProof && paymentProof.uploaded_at && 
								<div className='d-flex switch_txn_pad'>
									<p className='m-0 col-3 txn_model_txt'>Payment Proof Uploaded</p>
									<p className='m-0 txn_model_txt txn_model_subTxt' >{moment(paymentProof.uploaded_at).format('DD MMM YYYY hh:mm a')}</p>
								</div>
							}

							{paymentProof && paymentProof.captured_at && paymentProof.captured &&
								<div className='d-flex switch_txn_pad'>
									<p className='m-0 col-3 txn_model_txt'>Payment Proof {paymentProof.status =='approve' ? "Approved" : "Rejected"}</p>
									<p className='m-0 txn_model_txt txn_model_subTxt' >{moment(paymentProof.captured_at).format('DD MMM YYYY hh:mm a')}</p>
								</div>
							}

							{salesData && salesData.refund_requested_at &&
								<div className='d-flex switch_txn_pad'>
									<p className='m-0 col-3 txn_model_txt'>Refund Requested</p>
									<p className='m-0 txn_model_txt txn_model_subTxt' >{moment(salesData.refund_requested_at).format('DD MMM YYYY hh:mm a')}</p>
								</div>
							}		

							{salesData && salesData.refunded_date &&
								<div className='d-flex switch_txn_pad'>
									<p className='m-0 col-3 txn_model_txt'>Refunded Date</p>
									<p className='m-0 txn_model_txt txn_model_subTxt' >{moment(salesData.refunded_date).format('DD MMM YYYY hh:mm a')}</p>
								</div>
							}	

							{salesData && salesData.status =='dropped' && 
								<div className='d-flex switch_txn_pad'>
									<p className='m-0 col-3 txn_model_txt'>Switch Failed</p>
									<p className='m-0 txn_model_txt txn_model_subTxt' >{moment(salesData.updated_at).format('DD MMM YYYY hh:mm a')}</p>
								</div>
							}

							{salesData && salesData.delivery_date && salesData.status =='delivered' && 
								<div className='d-flex switch_txn_pad'>
									<p className='m-0 col-3 txn_model_txt'>Switch successful</p>
									<p className='m-0 txn_model_txt txn_model_subTxt' >{moment(salesData.delivery_date).format('DD MMM YYYY hh:mm a')}</p>
								</div>
							}
						</div>
					</div>
				</div>

				:
					<>
						<p className='text-light mt-5 text-center'>Loading...</p>
						<div className="product_takeup_loader"></div>
					</>
				}
			</Modal.Body>
		</Modal>


	
        <div className="container containerTopmargin max-width no-padding">
				<div className="floatAdvancetitleBox">
					<h3 className="preHeaderpad no-margin" style={{paddingLeft:15,fontSize:20}}>SEARCH - Float Switch </h3>
				</div>	
				{(!this.state.params_value || this.state.modify_search) &&
				<>
				<div className='row mt-4'>
					<div className=' col-md-12  row'>
						<div className='col-md-3 col-sm-6 mt-3'>
							<div className='m-2'>
								<div className='row'>
									<label className='col-10'  htmlFor='start_date' >Initiated From</label>
									<label className='col-2  product_textr' htmlFor='start_date'><FcCalendar size={20}/></label>
								</div>							
								<DatePicker defaultValue = {moment(this.state.sales_search.initiated_from, "YYYY-MM-DD")} inputProps={{ id: 'start_date', name: 'start_date' ,autoComplete:'off'}} closeOnSelect={true} dateFormat="DD MMM YYYY" onChange={(value) => {this.handleDatepicker(value, "initiated_from")}} timeFormat={false} required="required" isValidDate={(current) => {
									return current.isSameOrBefore(moment().startOf("day"));
								}}/>
							</div>
						</div>

						<div className='col-md-3 col-sm-6 mt-3'>
							<div className='m-2'>
								<div className='row'>
									<label className='col-10'  htmlFor='end_date' >Initiated To</label>
									<label className='col-2 product_textr' htmlFor='end_date'><FcCalendar size={20}/></label>
								</div>
								<DatePicker defaultValue = {moment(this.state.sales_search.initiated_to, "YYYY-MM-DD")}  inputProps={{ id: 'end_date', name: 'end_date' ,autoComplete:'off'}} closeOnSelect={true} dateFormat="DD MMM YYYY" onChange={(value) => {this.handleDatepicker(value, "initiated_to")}} timeFormat={false} required="required" isValidDate={(current) => {
									return current.isSameOrBefore(moment().startOf("day"));
								}}
								/>
							</div>
						</div>
						<div className='col-md-3 col-sm-6 mt-3'>
							<div className='m-2'>
								<div className='row'>
									<label className='col-10'  htmlFor='switched_from' >Switched From</label>
									<label className='col-2  product_textr' htmlFor='switched_from'><FcCalendar size={20}/></label>
								</div>							
								<DatePicker defaultValue = {moment(this.state.sales_search.transaction_from, "YYYY-MM-DD")} inputProps={{ id: 'switched_from', name: 'switched_from' ,autoComplete:'off'}} closeOnSelect={true} dateFormat="DD MMM YYYY" onChange={(value) => {this.handleDatepicker(value, "switched_from")}} timeFormat={false} required="required" isValidDate={(current) => {
									return current.isSameOrBefore(moment().startOf("day"));
								}}/>
							</div>
						</div>

						<div className='col-md-3 col-sm-6 mt-3'>
							<div className='m-2'>
								<div className='row'>
									<label className='col-10'  htmlFor='switched_to' >Switched To</label>
									<label className='col-2 product_textr' htmlFor='switched_to'><FcCalendar size={20}/></label>
								</div>
								<DatePicker defaultValue = {moment(this.state.sales_search.transaction_to, "YYYY-MM-DD")}  inputProps={{ id: 'switched_to', name: 'switched_to' ,autoComplete:'off'}} closeOnSelect={true} dateFormat="DD MMM YYYY" onChange={(value) => {this.handleDatepicker(value, "switched_to")}} timeFormat={false} required="required" isValidDate={(current) => {
									return current.isSameOrBefore(moment().startOf("day"));
								}}
								/>
							</div>
						</div>

						<div className="col-md-3 col-sm-6 mt-3">
							<div className='m-2 no-padding-input'>
								<label className='product_check_label'>Status</label>
								<select className="form-control" defaultValue={null}  onChange={(e)=>{this.handleFilter(e)}}  id="status" >
								{this.getSelectOptions("float_switch_status")}
								</select>
							</div>
						</div>
						<div className='col-md-3 col-sm-6 mt-3'>
							<div className='m-2 no-padding-input'>
								<label className='product_check_label'>Switch To</label>
								<select className="form-control" defaultValue={null}  onChange={(e)=>{this.handleFilter(e)}}  id="transfer_acc" >
								<option value={null}>choose</option>
								<option value="all">All</option>
								<option value="only_flow_cust">Only Flow Customer</option>
								<option value="own_acc">Own Account</option>
								</select>
							</div>
						</div>

						{/* <div className='col-md-3 col-sm-6'>
							<div className='m-2 sales_input_ph sales_search row'>
								<label className='product_check_label col-10 m-0 p-0' htmlFor='created_at'>Transaction Date</label>
								<label className='col-2 p-0 m-0 d-flex justify-content-end'><FcCalendar size={20} className='m-0 p-0'/></label>
								<div className='col-12 w-100 p-0 m-0'>
									<input className='form-control col-12' id='created_at'  onChange={(e)=>this.handleFilter(e)}  type='date'/>
								</div>
							</div>
						</div> */}
						<div className='col-md-3 col-sm-6 mt-3'>
							<div className='m-2 no-padding-input'>
								<label className='product_check_label'>From A/C</label>
								<select className="form-control" value={this.state.sales_search.from_acc_prvdr ? this.state.sales_search.from_acc_prvdr : ""}  onChange={(e)=>{this.handleFilter(e)}}  id="from_acc_prvdr" >
								<option value="">choose</option>
								{this.state.accounts&&this.state.accounts.length>0&&this.state.accounts.map((item,index)=>{
									return(<option key={index} value={item}>{item}</option>)
								})}
								</select>
							</div>
						</div>
						<div className='col-md-3 col-sm-6 mt-3'>
							<div className='m-2 no-padding-input'>
								<label className='product_check_label'>To A/C</label>
								<select className="form-control" value={this.state.sales_search.to_acc_prvdr ? this.state.sales_search.to_acc_prvdr : ""}  onChange={(e)=>{this.handleFilter(e)}}  id="to_acc_prvdr" >
								<option value="">choose</option>
								{this.state.accounts&&this.state.accounts.length>0&&this.state.accounts.map((item,index)=>{
									return(<option key={index} value={item}>{item}</option>)
								})}
								</select>
							</div>
						</div>

						<div className='col-md-3 col-sm-6 mt-2'>
							<div className='m-2 no-padding-input'>
								<label className='product_check_label'>Channel</label>
								<select className="form-control" value={this.state.sales_search.channel ? this.state.sales_search.channel : ""}  onChange={(e)=>{this.handleFilter(e)}}  id="channel" >
								<option value="">choose</option>
								<option value="sms">SMS</option>
								<option value="ussd">USSD</option>
								<option value="cust_app">Customer App</option>
								<option value="new_cust_app">New Customer App</option>
								</select>
							</div>
						</div>
						<div className="col-md-3 col-sm-6 mt-4">
              <div className="m-2">
							<Select onChange={(e) => {this.handleFilter(e,'flow_rel_mgr_id')}}
                              value = {this.state.select_value}
							  options={this.state.flow_rel_mgr}
							  placeholder="Flow Relationship Manager"
							  styles={this.select_custom_styles()}
							  classNamePrefix="my-select" // Add a custom class prefix to target the components
							  isMulti/>
               </div>
             </div>
					</div>
				</div>
				<div className='row w-100'>
					<div className="col-md-6 col-sm-12 mt-3">
						<div className='form-group m-2 sales_input_ph no-padding-input' >
								<label className='product_check_label'>Customer ID / Float Switch ID /Mobile Number</label>
								<input className='form-control' id='req_parameter' placeholder='Enter Customer ID / Float Switch ID ' onChange={(e)=>this.handleFilter(e)} type='text' value={this.state.sales_search['req_parameter']}/>
						</div>
					</div>
					<div className=' col-md-3 col-sm-6 d-flex align-items-center'>
						<div className='m-2 w-100 text-center'>
							<button className='btn sales_search_submit' onClick={()=>this.handleSubmit()} disabled={(this.state.loader || Object.keys(this.state.sales_search).length <= 0 )}>Search</button>
						</div>
					</div>
				</div>
               </>
			   }
			   {!this.state.modify_search && this.state.params_value &&
			   <div className='d-flex align_items-center justify-content-end'>
			   <div className='mt-2'>
			     <button type="button" class="btn btn-secondary" onClick={()=>{this.setState({modify_search:!this.state.modify_search})}}>modify search</button>

			   </div>
			   </div>
			   }
				{/* {this.state.row_data.length>0&& */}
					<div className={`${(this.state.params_value && !this.state.modify_search)  ?"":"sales_search_table" } text-center`} style={{display: this.state.row_data.length > 0 ? 'block':'none'}}>
						<div className='text-left'>
							<label><b>Total Count : </b></label><label className='ml-2'><b>{this.state.paginate && this.state.total_count ? this.state.total_count : this.state.row_data.length}</b></label>
						</div>

						{/* <CommonReactTable showPag={this.state.row_data.length > 10} minRow={0} row_data={this.state.row_data} column_data={this.state.column_data} resizable={false} defaultPageSize={10} exportCSV={true} csvFile={"Float Switch"} csvData={this.state.row_data}/>        */}

						{this.state.paginate ? <BasicReactTable row_data={this.state.row_data !== undefined ? this.state.row_data : []} column_data={this.state.column_data}  pagination={true} default_page_size = {10} export_csv={true} searchData={[{loan_doc_id:this.props.loan_doc_id,title:'View Transaction'}]} FName={"Float Switch"}  server_side_pagination={this.state.paginate} csv_total_data={this.state.total_count} export_csv_datas={this.state.export_data ?this.state.export_data  : [] } csv_file={true}  filter_data={this.pagination} handleExport={this.handleExportCSV} total_page_count={page_count(this.state.total_count, this.state.paginate.pagination_count)} /> 
						:
						<BasicReactTable row_data={this.state.row_data !== undefined ? this.state.row_data : []} column_data={this.state.column_data}  pagination={ true} default_page_size = {10} export_csv={true} searchData={[{loan_doc_id:this.props.loan_doc_id,title:'View Transaction'}]} FName={"Float Switch"}    export_csv_datas={this.state.row_data !== undefined ? this.state.row_data : [] } csv_file={true}    />
			}               

					</div>
				{this.state.no_data&&<h6 className='text-center text-light p-5 m-5'>No Data Found</h6>}
				{this.state.row_data.length == 0 && this.state.loader &&
					<>
						<p className='text-light mt-5 text-center'>Loading...</p>
						<div className="product_takeup_loader"></div>
					</>
				}
			</div>
      </div>
    )
  }
}
export default withRouter(SalesContainer)