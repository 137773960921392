import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import '../../../styles/flow.css';
import FileUpload from '../../common/component/FileUpload';
import {Edit} from '../../common/partial';
class DataProvider extends FlowComponent {
 state = {
    comp_name: "data_provider",
  }

  
  handleCodeChange = (event) => {
    //event.target.value = this.country_code.charAt(0) + event.target.value;
    var val = event.target.value;

    this.setState({[event.target.value]: this.country_code.charAt(0) + val})
    this.handleChange(event);
  }

  handleNameChange = (event) => {
        var value = this.country_code.charAt(0)
        value = value + event.target.value.substring(0, 3).toUpperCase();

         document.getElementById("data_prvdr_code").value = value;
         this.props.data['data_prvdr_code'] = value;
         //event.target.value = value
         this.toUpperHandleChange(event);
  }


  render(){
    return (
    <div id="" >
      <div className="row">
        <div className="col-md-2"><h4>{this.props.title ? this.props.title : "Data Provider"}</h4></div>
        <div className={`col-md-1 ${this.state.edit_button_style}`} >
          <Edit entity="data_prvdr" onClick={this.handleEdit}/>
          {/*<input type="button" onClick={this.handleEdit} value="Edit"/>*/}
         </div>
          <div className={`col-md-1 ${this.state.save_button_style}`}>
          <input  onClick={this.handleSave} type="button" className="btn btn-success" value="Save"/>
        </div>
      </div>
      <div className={this.state.div_style}>
        <div className="row">
          <div className="col-md-3">
            <span>Data Provider Name</span><font className="text-danger">   *  </font>
            <input id="name" value={this.props.data.name} onChange={this.props.mode == 'create' ? this.handleNameChange : this.toUpperHandleChange}  required="required" className="form-control" />
          </div>
          <div className="col-md-3">
            <span>Data Provider Type</span><font className="text-danger">   *  </font>
            <select id="provider_type" value={this.props.data.provider_type} onChange={this.handleChange}  required="required" className="form-control">
              {this.getSelectOptions("data_provider_type")}
            </select>
          </div>
          <div className="col-md-3">
            <span>Data Provider Code</span><font className="text-danger">  *  </font>
            <input type="text" id="data_prvdr_code" disabled  value={this.props.data.data_prvdr_code} maxLength={4} onChange={this.toUpperHandleChange}  required className="form-control"  />
          </div>
         <div className="col-md-3">
          <span>Contract Name</span><font className="text-danger">  *  </font>
          <input type="text" id="contract_name" value={this.props.data.contract_name} onChange={this.handleChange}  required className="form-control"  />
          </div>
        </div>
        <br/>
          <div className="row"> 
            <div className="col-md-3">        
            <span>New Customer Commission</span> ({this.currency_code})
            <input id="cust_comm" value={this.props.data.cust_comm} onChange={this.handleChange}  className="form-control" />
            </div>
            <div className="col-md-3">        
            <span>Repayment Commission</span> ({this.currency_code})
            <input id="repay_comm" value={this.props.data.repay_comm} onChange={this.handleChange}  className="form-control" />
            </div>
          </div>

      <hr/>
         <div className="row">
          <div className="col-md-6">
            <FileUpload id="data_prvdr_logo" label= "Logo" data_prvdr_id={this.props.data.id} 
            db_file_name={this.props.data.data_prvdr_logo} type="image" mode={this.props.mode} 
            data_prvdr_code={this.props.data.data_prvdr_code} onCreateUpload={this.handleChange}
            onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e)}} />
          </div>
          </div>
      </div>       
    </div>
      );
  }
}
export default DataProvider;
