import React from 'react';
import '../../../styles/flow.css';
import '../../market/container/datatables.css';
import {listAccount,updatePrimaryAccount,setDefaultAccount,removeDisabledAccount} from '../../../actions/account_actions';
import FlowContainer from '../../common/container/core/FlowContainer';
import {dd_value} from '../../../actions/common_actions';
import {AccountTransaction} from '../component/AccountTransaction';
import StatusButton from '../../common/component/core/StatusButton';
import {Homeacc_prvdrLogoselector, create_column, lbl_amt, multi_acc_pur_tostring} from '../../../helpers/common_helper';
import {check_priv, get} from '../../../helpers/storage_helper';
import {FaHandsHelping} from "react-icons/fa";

import { CiCircleRemove } from "react-icons/ci";

import $ from 'jquery';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BasicReactTable } from '../../../helpers/react_table';
class ListAccount extends FlowContainer {

  state = {
     accountList: null  ,
     editScreen: false,     
     row_data:[],
     language_json : get("language_json")
  }


   updatePrimaryAccount = (event) => {
    const is_primary_acc = true;
      var request = {};
      if(this.props.entity === "customer"){
        const cust_id = this.props.entity_id;  
        request = {"id":event.target.id,cust_id,"is_primary_acc":is_primary_acc};
      }else if(this.props.entity === "lender"){
         const lender_code = this.props.entity_id;
         request = {"id":event.target.id,lender_code,"is_primary_acc":is_primary_acc};

      }
    const request_data = {"account":request}
      updatePrimaryAccount(this.req(request_data))
         .then((response) => {
          if(!response){return };
              if(response.status === "success"){
                window.location.reload();
              }else{
                alert(response.message + "\n" + JSON.stringify(response.data));  
              }
            }
          ); 

        }
  makePrimaryButton(account_id, is_primary_acc, status){
    if(is_primary_acc == 0 && status === "enabled"){
      return <input id={account_id} type='button' className='btn btn-primary' onClick={this.updatePrimaryAccount} value="Make Primary A/C " />
    }else{
      return null;
    }
  }

  handleEditAccount = (event) => {
    //this.setState({editScreen: true, account_id: event.target.id});
    this.props.onEditClick(event);

  }
  handleAccTxns = (event) => {
    const acc_id = event.target.id;
    this.entity_id = this.props.entity_id;    
    this.entity = this.props.entity;    
    window.open("/acc_txns/"+this.entity+"/"+this.entity_id+"/"+acc_id,"_blank");
  }

  componentWillMount(){
      const country_code = this.country_code;
      this.account = {}
      this.table_id = this.props.entity_id;  
      this.account = {country_code};      
      if(this.props.entity == "customer"){
        this.account.cust_id = this.props.entity_id;  
        this.list_accounts(this.account);
      }else if(this.props.entity == "lender"){
        this.account.lender_code = this.props.entity_id;
        this.setOptionsListToStateFromAppApi('common', 'list', {...this.def_req ,status: "enabled", biz_account: true}, ['acc_prvdr_code', 'name'], "acc_prvdr");
      }else if(this.props.entity == "acc_prvdr"){
         this.account.acc_prvdr_code = this.props.entity_id;
         this.list_accounts(this.account);
      }

      if(check_priv(this.props.entity , 'view_acc_txn')){
          this.view_txn_btn_style = "show_div"
      }else{
          this.view_txn_btn_style = "disable_div"
      }

     if(check_priv(this.props.entity , 'create_account') || check_priv('account', 'configure_btn')){
          this.configure_btn_style = "show_div"
     }else{
          this.configure_btn_style = "disable_div"
     }
  }  

  list_accounts = (account) => {
      listAccount(this.req({account}))
        .then((response) => {
          if(!response){return };
          this.setState(state => state.row_data = response.data )
        }); 
        
        this.setState({accountList : this.getListColumns() })           
  }

  removeDisabledAccount(data){
    var obj={}
    obj['country_code']=get('country_code')
    obj['cust_id']=this.props.entity_id
    obj['status']=null
    

    var req ={id:data.id}
   if(window.confirm("Are you sure to remove this account ? "))  {
    removeDisabledAccount(this.req(req))
    .then((response)=>{
      if(!response){return}
      if(response.status=="success"){
        alert(response.message)  
        this.list_accounts(obj )
        
      }
    })
   }
    

  }

  changeDefaultAcc = (account_id) => {
    setDefaultAccount(this.req({id: account_id}))
      .then((response) => {
        if(!response){return };
        alert(response.message);
        if(response.status === "success"){
          window.location.reload();
        }
      })
  }


  getListColumns = () => {
    const is_super_user = ['super_admin', 'app_support', 'it_admin','ops_analyst','ops_admin'].includes(get('role_codes'))
   
  
    let data =[ 
      {
        Header : () =>(<div className={'text-center'}>ACC PRVDR</div>),
        id : "acc_prvdr_code",
        accessor:"bracc_prvdr_codeanch",
        Cell: cell => <div className={'text-center'}>
                                          {/* {cell.row.original.acc_prvdr_code} */}
                                          <div  >
                                          <Homeacc_prvdrLogoselector product_takeup_print={true} logotype={cell.row.original.acc_prvdr_code} />
                                          </div>
                                </div>

      },
      {
        Header : () =>(<div className={'text-center'}>A/C Number</div>),
        id : "acc_number",
        accessor:"bracc_numberanch",
        Cell: cell => <div className={'text-center'}>{cell.row.original.acc_number}</div>

      },
      {
        Header : () =>(<div className={'text-center'}>Alt A/C Number</div>),
        id : "alt_acc_num",
        accessor:"bralt_acc_numanch",
        Cell: cell => <div className={'text-center'}>{cell.row.original.alt_acc_num}</div>

      },
      {
        Header : () =>(<div className={'text-center'}>Acc Purpose</div>),
        id : "acc_purpose",
        accessor:"bracc_purposeanch",
        Cell: cell => <div className={'text-center'}>{cell.row.original.acc_purpose}</div>

      },

      {
        Header : () =>(<div className={'text-center'}>Branch</div>),
        id : "branch",
        accessor:"branch",
        Cell: cell => <div className={'text-center'}>{cell.row.original.branch}</div>

      },
      
      {
        Header : () =>(<div className={'text-center'}>Balance</div>),
        id : "balance",
        accessor:"balance",
        Cell: cell => <div className={'text-center'}>{cell.row.original.balance}</div>

      },
     
      {
        Header : () =>(<div className={'text-center'}>R&C Verified</div>),
        id : "channel",
        accessor:"channel",
        Cell: cell => <div className={'text-center'}>{cell.row.original.channel == 'cust_app' ? <i class="fa-solid fa-x"></i> : <i class="fa fa-check" aria-hidden="true"></i>}</div>

      },
      {
        Header : () =>(<div className='text-left ml-3'>Status</div>),
        id : "status",
        accessor:"status",
        Cell: cell => <div className={` d-flex align-items-center   justify-content-start ml-3`} > <StatusButton  className={``} head='1' id={cell.row.original.id} parent={this.props.entity} entity="accounts" status={cell.row.original.status} btn_disabled={cell.row.original.status === 'disabled' && !is_super_user}/> 
        
        {(cell.row.original.status == 'disabled' )&& (check_priv("account","remove")) &&
        <OverlayTrigger  placement="top"  delay={{ show: 250, hide: 400 }} overlay={  <Tooltip id="button-tooltip"> Remove  </Tooltip>}>     
        <img src='/img/remove.png' style={{width:'19px',height:'19px'}} className='ml-2 pointer' onClick={()=>this.removeDisabledAccount(row)}/>

        </OverlayTrigger>
        }
        </div>
      },
      {
        Header : () =>(<div className='ml-3'>Action</div>),
        id : "action",
        accessor:"action",
        show:check_priv('account','change_status'),
        Cell: cell => <StatusButton head='2' id={cell.row.original.id} parent={this.props.entity} entity="accounts" status={cell.row.original.status} btn_disabled={cell.row.original.status === 'disabled' && !is_super_user}/>
      },
      {
        Header : "Action",
        id : "create_account",
        accessor:"create_account",
        // show:check_priv(this.props.entity , 'create_account') || check_priv('account', 'configure_btn'),
        Cell: cell => <input id={cell.row.original.id} type="button" value="Configure" onClick={this.handleEditAccount} className={`btn btn-primary btn-std ${this.configure_btn_style}`}/>

      },
      {
        Header : "Action",
        id:'is_primary_acc',
        accessor:"is_primary_acc",

        // show: check_priv('account', 'set_default'),
        Cell : cell =>{ return (cell.row.original.is_primary_acc != 1 && cell.row.original.status != 'disabled') ? <div className='d-flex align-items-center'>
         
          <button type="button" onClick={()=>this.changeDefaultAcc(cell.row.original.id)} className ={`btn btn-sm btn-primary` }>Make default acc</button>   </div> : <></>},
        width : 100
      }   
    
  ]
  console.log('data', data)
 return data
}


  changeDataPrvdr = (event) => {
    var table = $('#table2').DataTable();
    table.destroy();
    this.selected_acc_prvdr_code = event.target.value;
    this.account.network_prvdr_code = event.target.value;
    this.list_accounts(this.account);
  }

  render(){    
    return( 
        <>
          {this.props.entity == "lender" &&
          <div className='row text-labelblue'>
          <div className="col-md-3">
                <span>Account Provider</span><font className="text-danger">   *  </font>
                <select id="network_prvdr_code" onChange={this.changeDataPrvdr}  className="form-control" type="text" required="required">
                  <option selected value="">Select</option>
                   {this.state.acc_prvdr_dd}
                </select>
          </div>
          </div>
        }       
                {this.state.accountList != null &&
                <>                      
                  <BasicReactTable pagination={false}  row_data={this.state.row_data !== undefined ? this.state.row_data : []} column_data={this.state.accountList}  default_page_size={10}/>                      
                </>
                }  

                {this.state.accountList == null &&  
                  <div><p>No Accounts Available ! </p></div>
                }
      
      </>
      );      
 } 
 
}


export default ListAccount;
