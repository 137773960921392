import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import '../../../styles/flow.css';
import FileUpload from '../../common/component/FileUpload';

class AccountProvider extends FlowComponent {

  state = {
    comp_name: "account_provider"
  }

  constructor(props){
    super(props);
    this.state = {values: []};
  }

  /*dynamicField(){

    return this.state.values.map((el,i)=>
        <div key={i}>
          <input id="account_types" type="text" value={el || ''} onChange={this.handleChange.bind(this,i)} className="form-control" />
          <input type="button" value="remove" onClick={this.removeClick.bind(this,i)} />
        </div>
      )
  }

  handleChange(i, event){
    let values = [...this.state.values];
    values[i] = event.target.value;
    this.setState({values});
  }

  removeClick(i){
    let values = [...this.state.values];
    values.splice(i,1);
    this.setState({values});
  } 

  addClick(){
    this.setState(prevState => ({values : [...prevState.values, '']}))
  }*/

  /*handleAddIcon = (event) => {

    this.setState(prevState => ({values: [...prevState.values, '']}));

  }*/


  render(){
     
    return (

          <div id="">
          <div className="container no-margin">
            <div className="row">
                <h4>Account Provider Details</h4>
            </div>

            <div className="row">
                <div className="col-md-3 account_prvdr">
                  <span>Account Provider Name</span><font className="text-danger">   *  </font>
                  <input id="name" value={this.props.data.name} onChange={this.toUpperHandleChange} required="required" className="form-control" />
                </div>

               {/* <div className="col-md-3">
                  <span>Account Type</span><font className="text-danger">   *  </font>
                  <input id="account_types" value={this.props.data.account_types} onChange={this.handleChange} required="required" className="form-control" />
                </div>*/}
           
                <div className="col-md-3">
                  <span>Account Provider Code</span><font className="text-danger">   *  </font>
                  <input id="acc_prvdr_code" value={this.props.data.acc_prvdr_code} maxLength={4} onChange={this.handleChange} required="required" className="form-control" />

                </div>
                <div className="col-md-6">
            <FileUpload id="acc_provider_logo" label= "Logo" acc_prvdr_id={this.props.data.id} acc_prvdr_code={this.props.data.acc_prvdr_code}
              db_file_name={this.props.data.acc_provider_logo} type="image" mode={this.props.mode}
              onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e)}} 
              onCreateUpload={this.handleChange}/>
          </div>

            </div>

             <br/>
       {/*  <div className="row">
          <div className="col-md-6">
            <FileUpload id="acc_provider_logo" label= "Logo" acc_prvdr_id={this.props.data.id} acc_prvdr_code={this.props.data.acc_prvdr_code}
              db_file_name={this.props.data.acc_provider_logo} type="image" mode={this.props.mode}
              onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e)}} 
              onCreateUpload={this.handleChange}/>
          </div>
          </div>*/}
            

          </div>
          </div>

   /*<div id="" >
      <div className="row">
        <div className="col-md-2"><h4>{this.props.title ? this.props.title : "Account Provider Details"}</h4></div>

        <div className={`col-md-1 ${this.state.edit_button_style}`} >
          <input type="button" onClick={this.handleEdit} value="Edit"/>
         </div>
          <div className={`col-md-1 ${this.state.save_button_style}`}>
          <input  onClick={this.handleSave} type="button" className="btn btn-success" value="Save"/>
        </div>
        
      </div>
      <div className={this.state.div_style}>
        <div className="row">
          <div className="col-md-3">
            <span>Account Provider Name</span>
            <input id="name" value={this.props.data.name} onChange={this.handleChange}  required="required" className="form-control" />
           
          </div>
          </div>
          <br/>
          <div className="row">
          <div className="col-md-3">
            <span>Account Type</span><font className="text-danger">   *  </font>
      
            {this.dynamicField()}
            <input id="account_types" value={this.props.data.account_types} onChange={this.handleChange.bind(this)} required="required" className="form-control" />
             

          </div>   
          <div className="col-md-3">
            <input type='button'  id='button' value='add' onClick={this.addClick.bind(this)} />
          </div>         
        </div>

      


      </div>
    </div>*/

      );
  }
}

export default AccountProvider;
