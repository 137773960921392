import React, { useState } from 'react';
import * as moment from "moment";
import { OverlayTrigger, Tooltip, Popover, Badge, Button } from 'react-bootstrap';

import { lbl_repayment_amt, lbl_amt } from '../../helpers/product_helper';
import { late_days } from '../../helpers/borrower_helper';
import { checkNA, filter, format_date, reduce_character, calc_percent, cust_score_color } from '../../helpers/common_helper';
import { lbl_view_btn, check_priv } from '../../helpers/storage_helper';
import '../../styles/flow.css';
import { dd_value } from '../../actions/common_actions';
import { get_time_diff } from '../../helpers/common_helper';
import Softphone from '../cs_management/container/cs_soft_phone';
import { useDispatch, useSelector } from 'react-redux';
import { setValuesIntoRedux } from '../../Redux/Slices/CommonSlice';
import { useEffect } from 'react';

export function ViewEdit(props) {
  return (
    <input id={props.id} type='button' onClick={props.onClick}
      value={lbl_view_btn(props.entity)}
      className="btn-sm btn-primary" />

  );
}

export function Edit(props) {

  const edit_priv = check_priv(props.entity, "edit");
  return (
    <>
      {edit_priv &&
        <input type='button' class="btn btn-secondary btn-std" onClick={props.onClick}
          value="Edit" />
      }
    </>

  );
}

export function CustID(props) {
  return (
    <OverlayTrigger overlay={<Tooltip id="tooltip">{props.biz_name}/{props.cust_name}</Tooltip>}>
      <span className="d-inline-block">
        {props.acc_number}
      </span>
    </OverlayTrigger>
  );
}
export function Product(props) {
  const overlay = "FA Amount :" + lbl_amt(props.loan.loan_principal, props.currency_code) + "\n"
    + "Repayment :" + lbl_repayment_amt(props.loan, props.currency_code);
  return (
    <OverlayTrigger overlay={<Tooltip id="tooltip">{overlay}</Tooltip>}>
      <span className="d-inline-block">
        {props.loan.product_name}
      </span>
    </OverlayTrigger>
  );
}

function get_csf_values(loan_product, meta_data) {
  const csf_values = loan_product.prod_csf_values;

  const cs_model_code = loan_product.cs_model_code
  const result_configs = loan_product.result_configs

  if (meta_data != null && meta_data != undefined && meta_data != "") {
    var st_date_item = filter(meta_data, 'csf_type', 'meta_txn_start_date')[0]
    var end_date_item = filter(meta_data, 'csf_type', 'meta_txn_end_date')[0]
    var txn_days_item = filter(meta_data, 'csf_type', 'meta_txn_days')[0]
    var cal_days_item = filter(meta_data, 'csf_type', 'meta_cal_days')[0]

    const days = txn_days_item.csf_normal_value + ' days'
    const dates = format_date(st_date_item.csf_normal_value, false) + ' to ' + format_date(end_date_item.csf_normal_value, false) + ' (' + cal_days_item.csf_normal_value + ' days)'

    var dates_item = { code: "Txn Dates", value: dates }
    var days_item = { code: "Nett Txn Days", value: days }

    var new_data = [dates_item, days_item]
    var meta_data_div = new_data.map((data_item, i) => {
      return (
        <div>
          <span className="cust_font">&nbsp;&nbsp;&nbsp;{dd_value(data_item.code)} : {data_item.value}</span>
        </div>
      );
    });
  }

  if (result_configs) {
    var result_config_div = result_configs.map((result_config, i) => {
      return (
        <div>
          <span className="cust_font">&nbsp;&nbsp;&nbsp;
            Score {result_config.score_from} to {result_config.score_to} - {dd_value(result_config.score_result_code)}
          </span>
        </div>
      );
    });
  }

  if (csf_values != null || csf_values != undefined) {

    var all_items = csf_values.map((csf_value, i) => {
      return (
        <div>

          {/* <span className="cust_font">&nbsp;&nbsp;&nbsp;{dd_value(csf_value.csf_type)} : {csf_value.csf_gross_value} ({csf_value.weightage}%)</span> */}
          <span className="cust_font">&nbsp;&nbsp;&nbsp;{csf_value.weightage}% - {dd_value(csf_value.csf_type)} : {csf_value.csf_gross_value} ({csf_value.csf_value}/10)</span>

        </div>
      );
    }

    );

    return (

      <div>
        <b className="cust_font">Score Model Name - {dd_value(cs_model_code)} </b>
        <br />
        <b className="cust_font">Eligibility Criteria</b>
        {result_config_div}
        <hr />
        <b className="cust_font">Score Factor Values</b>
        {all_items}

        {meta_data_div &&
          <>
            <hr />
            <b className="cust_font">Note</b>
            {meta_data_div}
          </>
        }
      </div>

    );
  } else {
    return <label>{loan_product.result_msg}</label>
  }
}


export function CustScore(props) {

  return (
    <OverlayTrigger trigger="click" overlay={<Popover>{get_csf_values(props.loan_product, props.meta_data)}</Popover>}>
      <h4><Badge className='badge_style' variant={cust_score_color(props.loan_product.result_code)} pill>{checkNA(props.loan_product.cust_score)}</Badge></h4>
    </OverlayTrigger>
  );
}





export function FAProductActionOverlay(props) {
  var overlay_msg = props.result_msg;

  if (props.result_code === "eligible") {
    overlay_msg = ""
  }

  return (
    <>
      {overlay_msg &&
        <OverlayTrigger overlay={<Tooltip id="tooltip">{overlay_msg}</Tooltip>}>
          <label style={{ paddingTop: '5px', color: 'red' }}>Result Info</label>
        </OverlayTrigger>
      }
    </>
  );
}


export function TooltipWithElement(props) {
  return (
    <div>
      {props.overlay == undefined ?
        <span className="d-inline-block">
          {props.base}
        </span>
        :
        <OverlayTrigger trigger="click" overlay={<Popover>{props.overlay}</Popover>}>
          <span className="d-inline-block">
            {props.base}
          </span>
        </OverlayTrigger>
      }
    </div>
  );
}

export function TooltipLabel(props) {

  return (
    <div>
      {props.full_text ?
        <OverlayTrigger overlay={<Tooltip id="tooltip">{props.full_text}  {props.overlay?"/":""} {props.overlay}</Tooltip>}>

          <span className="d-inline-block">

            {props.no_reduce ? props.full_text : reduce_character(props.full_text)}

          </span>
        </OverlayTrigger>
        :
        <span className="d-inline-block">

          NA

        </span>
      }
    </div>
  );
}

export function AccountTransactionToolTipLabel (props) {
  return(
    <div>
      {props.full_text ?
          <OverlayTrigger overlay={<Tooltip id="tooltip">{props.full_text}</Tooltip>}>

            <span className="d-inline-block">    
            
            {props.no_reduce ? props.full_text : reduce_character(props.full_text)}
            
            </span>
          </OverlayTrigger>
          :
            <span className="d-inline-block"> 
            
            NA
            
            </span>	
      }
		</div>
  )
}
   
export function ExcesstimeAction({ data, timedata, item, action, time_limit }) {
  const dispatch = useDispatch()
  const datas = useSelector(state => state.cs_soft_phone)
  const[customer_data,setcustomer_data] = useState(false)
  const[is_calling,setis_calling] = useState(false)

 

  // useEffect(()=>{
  //   // setcustomer_data(customer_data)

  //   // dispatch(setValuesIntoRedux({cust_id:customer_data.cust_id}))
  // },[customer_data])



  let time_diff
  const min_time = time_limit[0] * 60000
  const max_time = time_limit[1] * 60000
  if (item === 'next_action_conf') {
    var time = (data.loan_approved_date == null) ? data.loan_appl_date : data.loan_approved_date
  }
  else if(item === "first_attempt_time"){
    var time = data.first_attempt_time
  }
  else if(item === "last_attempt_time"){
    var time = data.attempt_time
  }else if(item === "last_updated_at"){ 
    var time = data.updated_at
  }else if(item==='created_at'){
    var time=data.created_at
  }
  else {
    var time = (item === "approved") ? data.loan_approved_date : data.loan_appl_date  
  }

  time_diff = moment.duration(moment(timedata).diff(moment(time)))
  let time_exceeded = false
  let time_blink = false
  let white_text = false
  if (time_diff._milliseconds < min_time) {
    time_exceeded = false
    time_blink = false
    white_text = true
  }
  else if (time_diff._milliseconds >= min_time && time_diff._milliseconds <= max_time) {
    time_exceeded = true
    time_blink = false
  } else if (time_diff._milliseconds > max_time) {
    time_exceeded = false
    time_blink = true
  }  
  var splitted_name = null
  if(item !== "last_updated_at"&&item!='created_at'){
     splitted_name = data.loan_approver_name.split(' ')
  }
  return (
    <>
  
      {item === "applied" &&
        <>
          {white_text &&
            <span>{get_time_diff(timedata, data.loan_appl_date)}</span>
          }
          {time_exceeded &&
            <span className="text-redDark">{get_time_diff(timedata, data.loan_appl_date)}</span>
          }
          {time_blink &&
            <span className="text-redDark">{get_time_diff(timedata, data.loan_appl_date)}<i style={{paddingLeft:5}} class="fa-solid fa-triangle-exclamation excess_time_blinkerTable"></i></span>
          }
        </>
      }

      {item === "approved" &&
        <>
          {white_text &&
            <span>{get_time_diff(timedata, data.loan_approved_date)}</span>
          }
          {time_exceeded &&
            <span className="text-redDark">{get_time_diff(timedata, data.loan_approved_date)}</span>
          }
          {time_blink &&
            <span className="text-redDark">{get_time_diff(timedata, data.loan_approved_date)}<i style={{paddingLeft:5}} class="fa-solid fa-triangle-exclamation excess_time_blinkerTable"></i></span>
          }
        </>
      }

      {item === "first_attempt_time" &&
        <>
          {/* {time_exceeded ?
            <span className="text-red">{get_time_diff(timedata, data.first_attempt_time)}</span> : <span>{get_time_diff(timedata, data.loan_approved_date)}</span>
          } */}
          {white_text &&
            <span>{get_time_diff(timedata, data.first_attempt_time)}</span>
          }
          {time_exceeded &&
            <span className="text-redDark">{get_time_diff(timedata, data.first_attempt_time)}</span>
          }
          {time_blink &&
            <span className="text-redDark">{" "}{get_time_diff(timedata, data.first_attempt_time)}<i style={{paddingLeft:5}} class="fa-solid fa-triangle-exclamation excess_time_blinkerTable"></i></span>
          }
        </>
      }      

      {item === "last_attempt_time" &&
        <>
          {/* {time_exceeded ?
            <span className="text-red">{get_time_diff(timedata, data.attempt_time)}</span> : <span>{get_time_diff(timedata, data.loan_approved_date)}</span>
          } */}
          {white_text &&
            <span>{get_time_diff(timedata, data.attempt_time)}</span>
          }
          {time_exceeded &&
            <span className="text-redDark">{get_time_diff(timedata, data.attempt_time)}</span>
          }
          {time_blink &&
            <span className="text-redDark">{get_time_diff(timedata, data.attempt_time)}<i style={{paddingLeft:5}} class="fa-solid fa-triangle-exclamation excess_time_blinkerTable"></i></span>
          }
        </>
      }

      {item === "next_action" &&
        <>          
          {time_exceeded || time_blink ?
            <span className="text-uppercase"><i class="fa-solid fa-phone text-white">&nbsp;&nbsp;</i>{splitted_name[0]}</span> : <span>{splitted_name[0]}</span>
          }
        </>
      }
      {item === "next_action_conf" &&
        <>
          {time_exceeded || time_blink ?
            <div className='text-center'><Button className="btn-std" onClick={() => { action(data.cust_id, data.loan_doc_id)}} >Get Phone Confirmation </Button></div> : null

            // <div className='text-center'><Button className="btn-std" onClick={() => { action(data.cust_id, data.loan_doc_id)}} >Get Phone Confirmation </Button></div> : null
          }
        </>
      }

      {item === "last_updated_at" && 
        <>
          {white_text &&
            <span>{get_time_diff(timedata, data.updated_at)}</span>
          }
          {time_exceeded &&
            <span className="text-redDark">{get_time_diff(timedata, data.updated_at)}</span>
          }
          {time_blink &&
            <span className="text-redDark">{get_time_diff(timedata, data.updated_at)}<i style={{paddingLeft:5}} class="fa-solid fa-triangle-exclamation excess_time_blinkerTable"></i></span>
          }
        </>
      }
      {item === "created_at" && 
        <>
          {white_text &&
            <span>{get_time_diff(timedata, data.created_at)}</span>
          }
          {time_exceeded &&
            <span className="text-redDark">{get_time_diff(timedata, data.created_at)}</span>
          }
          {time_blink &&
            <span className="text-redDark">{get_time_diff(timedata, data.created_at)}<i style={{paddingLeft:5}} class="fa-solid fa-triangle-exclamation excess_time_blinkerTable"></i></span>
          }
        </>
      }
    </>
  )
}

export function Remarks(props) {
  return (
    <div>
      {props.remarks ?

        <OverlayTrigger overlay={<Tooltip id="tooltip">{props.remarks}</Tooltip>}>
          <span className="d-inline-block">
            {reduce_character(props.remarks)}
          </span>
        </OverlayTrigger>

        :

        <span className="d-inline-block">
          NA
        </span>


      }
    </div>

  );
}


export function LatePay(props) {
  const borrower = props.borrower
  var [ontime_nos, one_day_nos, two_day_nos, two_plus_nos] = [0, 0, 0, 0];
  var [ontime_pc, one_day_pc, two_day_pc, two_plus_pc] = ['0 %', '0 %', '0 %', '0 %'];
  ontime_nos = borrower.tot_loans - borrower.late_loans
  ontime_pc = calc_percent(ontime_nos, borrower.tot_loans)
  const late_days_obj = JSON.parse(borrower.late_days)

  if (late_days_obj) {
    one_day_nos = late_days(late_days_obj, '1');
    one_day_pc = calc_percent(one_day_nos, borrower.tot_loans)
    two_day_nos = late_days(late_days_obj, '2');
    two_day_pc = calc_percent(two_day_nos, borrower.tot_loans)
    two_plus_nos = late_days(late_days_obj, '2_plus');
    two_plus_pc = calc_percent(two_plus_nos, borrower.tot_loans)
  }
  const days = ontime_nos + " | " + one_day_nos + " | " + two_day_nos + " | " + two_plus_nos;
  const pc = ontime_pc + " | " + one_day_pc + " | " + two_day_pc + " | " + two_plus_pc;

  return (
    <OverlayTrigger overlay={<Tooltip id="tooltip">{pc}</Tooltip>}>
      <span className="d-inline-block">
        {days}
      </span>
    </OverlayTrigger>
  )
}




