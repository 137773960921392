import React, {useEffect, useState} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Toggle from "./Toggle";

const ConfirmModal = ({alert_message=null,note=null,is_toggle=null,size=null})=>{


    const [show,setshow] = useState(false);

    
    useEffect(()=>{
        setshow(!show)
    },[is_toggle])

   
        
    const ToggleModal =(type=null)=>{
       

        is_toggle(type)
        setshow(!show)

    }

    

    return(
  <div className="">
         <Modal className="confirm_modal" show={show} onHide={()=>ToggleModal(null)}
          size= {size!=null ? "" :"lg"}
       aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
       <b>Confirmation</b> 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="m-0 text-white sizel" style={{lineHeight:'25px'}}>
         {alert_message}
        </p>
        {note!=null &&
        <p  className="mb-0 my-3  sizem text-info">
         Note : {note}
       </p>
        }

        <div className="d-flex align-items-center justify-content-end">
        <button type="button" class="btn btn-confirm mx-3 " onClick={(e)=>ToggleModal("confirm")} >Confirm</button>
        <button type="button" class="btn btn-cancel text-white mx-2"  onClick={()=>ToggleModal("cancel")} >Cancel</button>
        </div>
      </Modal.Body>
     
    </Modal>
    </div>
    )

}


export default ConfirmModal