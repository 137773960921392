import React from 'react';
import {Form, Col , Row, Button,checkbox ,Modal} from 'react-bootstrap';
import Select from "react-select";
import FlowComponent from '../../common/component/core/FlowComponent';
import DatePicker from 'react-datetime';
import moment from 'moment';
import {check_priv} from '../../../helpers/storage_helper';
import '../../../styles/flow.css';
import { BasicReactTable } from '../../../helpers/react_table';
import { dd_value } from '../../../actions/common_actions';
import { Homeacc_prvdrLogoselector, format_date_tbl, lbl_amt } from '../../../helpers/common_helper';
import { watchlistReport } from '../../../actions/borrower_actions';
import { get } from '../../../helpers/storage_helper';
import { Link } from 'react-router-dom';
import {Overlay, Tooltip, OverlayTrigger} from 'react-bootstrap'

class WatchlistCustomers extends FlowComponent{


constructor(props){
    super(props);
    this.state = {
         filters:[
            {key:"Customer",type:"input"},
            {key:"Initiated by",type:"select"},

            {key:"start_date",type:"date"},
            {key:"end_date",type:"date"},
            {key:"Reason",type:"select"},


        ],
        column_datas:this.column_datas(),
        criteria_arr:{
            start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
            end_date: moment().format('YYYY-MM-DD')
        },
    }; 
}

componentWillMount(){
    this.setOptionsListToStateFromAppApi("common","priv_users",this.req({priv_code:'',status:'enabled',role_codes:["rm_support"]}), ['id' , 'name']);

    this.setOptionsListToStateFromAppApi("common","priv_users",this.req({priv_code:'',status:'enabled',role_codes:["operations_auditor"]}), ['id' , 'name'],"operations_auditor");

    this.getWatchLIstCustomers(this.state.criteria_arr)
}

getWatchLIstCustomers(criteria_arr=null){
    this.setState({loader:true})
    var req={}
   
     if(criteria_arr){
        req = criteria_arr
     }
    console.log('req', req)
    watchlistReport(this.req(req))
    .then((response)=>{
        if(!response){     this.setState({loader:false,is_onchanged:false})
         return}
        if(response.status=="success"){
            this.setState({row_datas:response.data, loader:false,is_onchanged:false
            })
        }
    })
}


handleOnChange(val,type){

    var obj={...this.state.criteria_arr}
    obj[type]=val
    if(val==""){
        delete obj[type];
    }
    this.setState({criteria_arr:obj,is_onchanged:val?true:false})
}

submitHandler(){
    this.setState({loader:true})
    this.getWatchLIstCustomers(this.state.criteria_arr)

}

showDetails(data){

    this.setState({modalBox1:true,ModalData:data})

}

clearHandler(){

    this.setState({criteria_arr:{}})
}

column_datas () {
    const columns = [
          {
            Header : <p className='text-center m-0'>Acc Number </p> ,
            accessor: "acc_num",  

            Cell : cell =>  {return <div className='d-flex align-items-center'> <Homeacc_prvdrLogoselector logotype={cell.row.original.acc_prvdr_code}/> <span className='ml-2'>{cell.row.original.acc_num}</span></div>}
        },
        {
            Header :<p className='text-center m-0'>Customer ID </p> ,
            accessor: "cust_id",  
            Cell: (cell) => (<p className='cust_follow_text watch_list_cust_id m-0' style={{ textAlign: "center" ,color:""}}><Link to={"/borrower/indiv/view/" + cell.row.original.cust_id} target="_blank" >{cell.row.original.cust_id}</Link></p>),

        },
        {
            Header : <p className='text-center m-0'>Customer Name </p> ,
            accessor: "cust_name",  

            Cell : cell =>  {return <p className='text-white text-center m-0'>{cell.row.original.cust_name} </p>}
        },
        {
            Header : <p className='text-center m-0'>Initiated by </p> ,
            accessor: "initiated_by",  

            Cell : cell =>  {return <p className='text-white text-center m-0'>{cell.row.original.initiated_by}</p>}
        },
        {
            Header : <p className='text-center m-0'>Added on </p> ,
            accessor: "initiated_on",  

            Cell : cell =>  {return <p className='text-white text-center m-0'>{ moment(cell.row.original.initiated_on).format("DD MMM YYYY")}</p>}
        },
     
        {
            Header :  <p className='text-center m-0'>Reason </p>,
            accessor: "reason",  

            Cell : cell =>  {return <p className='text-white text-center m-0'>{(cell.row.original.reason&& cell.row.original.reason.length>0)?
            
                <>
                {cell.row.original.reason.includes("incomplete_kyc") ?
                 <div>
                          <p className='m-0 text-white'>Incomplete KYC </p>
                        {(cell.row.original.missing_documents && cell.row.original.missing_documents.length>0 ) &&
                           cell.row.original.missing_documents.map((items,idx)=>{return(
                             <span key={idx}> {idx==0 ?"(":""} {dd_value(items,'biz_documents')} {cell.row.original.missing_documents.length - 1 !== idx && <>,<br/> </>} {cell.row.original.missing_documents.length - 1 == idx  ? ")":""}</span>
                           )})
                          }
                     </div>:
                <>
                {cell.row.original.reason.map((val, id) => (
                    <>
                    

                     <OverlayTrigger key='local_balance' placement="top" overlay={<Tooltip id="local_balance">{ dd_value(val, ["rms_watchlist","under_watch"] )}</Tooltip>}>

                    <span key={id}>
                    {dd_value(val, ["rms_watchlist","under_watch"] )}
                    {cell.row.original.reason.length - 1 !== id && <br />}
                  </span> 
                     </OverlayTrigger>
                  
                 </>
                ))}
                </>
                }
              </>
              :"-"}</p>}
        },
        {
            Header : <p className='text-center m-0'>Details </p> ,
            accessor: "Details",  
            Cell : cell =>  {return <div className='d-flex justify-content-center'>
                <button type="button" class="btn btn-primary" onClick={()=>{this.showDetails(cell.row.original)}}>view</button>


            </div>}
        },





  ]
  return columns.filter((column) => column.show !== false);
}


handleModalClose = (event) => {
    this.setState({
        modalBox1: false,
        modalBox2: false, mapShow: false
    });
}

getHistory(data) {
    var a = [];
    a.push(
        <div class={` w-100 my-4 late_loan_parent `} >

            <div className={`row d-flex align-items-center w-100 m-0 py-4 late_loans_table `}>
                <p className='m-0 col-4 text-center viewCus_labelVal left_border text-white'> Amount </p>
                <p className='m-0 col-4 text-center viewCus_labelVal left_border text-white'>Due Date </p>
                <p className='m-0 col-4 text-center viewCus_labelVal sizem right_border text-white'> Settled Date</p>

            </div>

            <div className={`w-100 ${(data && data.length > 6) ? "scroll_table" : ""} `}>
               
                 
                    <>
                   
                        { data.map((item, idx) => {
                            return (
                                <div className={`row d-flex my-3 mx-0 align-items-center`} key={idx}>
                                    <p className='text-white p-0 col-4 text-center'>{lbl_amt(item.loan_principal, get('market').currency_code)}</p>
                                    <p className='text-white p-0 col-4 text-center'>{format_date_tbl(item.due_date)}</p>
                                    <p className='text-white p-0 col-4 text-center'>{format_date_tbl(item.paid_date)}</p>
                                </div>
                            )
                        })}
                  
                    </>
                
            </div>
        </div>

    )
    return a;
}

render(){
	return(
        <div className={`${this.state.modalBox1 ? "disable_div":""}`}>
            {this.state.modalBox1&& 
                <Modal show={this.state.modalBox1} onHide={this.handleModalClose} >

                <Modal.Header closeButton className=" py-0 d-flex align-items-center profile_summary_modal" show={this.state.modalBox1} onHide={this.handleModalClose}>
                  <h2 className='text-white text-center w-100'>Watchlist</h2>

                </Modal.Header>

                <Modal.Body>
                    <p className='mb-2 text-white'>Reason: <b>
                    {this.state.ModalData.reason.includes("incomplete_kyc") ?
                
                        " Incomplete KYC"
                       
                     : <>
                         {(this.state.ModalData.reason&& this.state.ModalData.reason.length>0)?
                                                                
                                                                <>{
                                                                    this.state.ModalData.reason.map((val,id)=>{return(
                                                                      <span>{dd_value(val,["rms_watchlist","under_watch"])} {(this.state.ModalData.reason.length-1) !=id ? ",":""} </span>
                                                                  )})
                                                                  } </>
                        :"-"}
                      </>}              
                     
                    </b>
                                                               </p>

                  {(this.state.ModalData.missing_documents && this.state.ModalData.missing_documents.length>0 ) &&
                     <p className='mb-2 text-white'> Missing Documents: <b>
                     {(this.state.ModalData.missing_documents && this.state.ModalData.missing_documents.length>0 ) &&
                           this.state.ModalData.missing_documents.map((items,idx)=>{return(
                             <span key={idx}>  {dd_value(items,'biz_documents')} {this.state.ModalData.missing_documents.length - 1 !== idx && <>, </>} </span>
                           )})
                          }
                        </b></p>
                  }
                     <p className='mb-2 text-white'>Remarks: <b>{this.state.ModalData.remarks ?this.state.ModalData.remarks:"-" }</b></p>

                    <p className='mb-2 text-white'>Total late repayments: <b>{(this.state.ModalData.late_loans &&(this.state.ModalData.late_loans.length>0)) ? this.state.ModalData.late_loans.length:0}</b></p>
                    {this.state.ModalData.late_loans && this.state.ModalData.late_loans.length>0 &&
                    <>
                                      {this.getHistory(this.state.ModalData.late_loans)}

                    </>}
                </Modal.Body>

                </Modal>
          }
        <div className='floatAdvancetitleBox mt-4 p-3'>
        <h2 className='text-white m-0 py-2 '>Watchlist Customers</h2>
        </div>
        <div className='py-4' style={{backgroundColor: 'rgb(32, 41, 64)'}}>

            <div className='row m-0 d-flex align-items-center'>

                {this.state.filters&& this.state.filters.map((item,idx)=>{return(
                    <div className='col-lg-3 col-md-12 col-sm-12 my-3'>
                    <fieldset className='border border-white p-2 my-2 ' style={{borderRadius:'5px'}}>
                        <legend className='w-auto text-light ml-4 px-2  mb-0'style={{fontSize:'13px'}}>{dd_value(item.key)}</legend>
                        <div className='flagged_cust'>
                     {(item.type=="input") &&
                        <Form.Group as={Col} className={` px-2 py-0 no-margin  `}>

                        <Form.Control id="req_parameter" placeholder=' Name/Cust ID/Mobile No' className='p-0' autoComplete='off' onChange={(e) => {this.handleOnChange(e.target.value,"no_criteria")}} >
                        </Form.Control>
                    </Form.Group>
                    }

                    {item.type=="select" &&


                    <Form.Control as="select" id="audited_by" value={ this.state.criteria_arr[(item.key == "Reason") ? "reason" :"initiated_by"] ?this.state.criteria_arr[(item.key == "Reason") ? "reason" :"initiated_by"] :""} onChange={(e) =>{this.handleOnChange(e.target.value, `${(item.key == "Reason") ? "reason" :"initiated_by"}`)}}
                    placeholder={Object.keys(this.state.criteria_arr).length == 0 ? "choose":""}
              

                    >
                      {((Object.entries(this.state.criteria_arr).length == 0) && item.key != "Reason" )&& 
                      <option value="">choose</option> }
                    <option value="all">All</option>
                    { (item.key == "Reason" ) ? ( get('role_codes')=="operations_auditor" ? this.getSelectOptions("under_watch"):
                     this.getSelectOptions("rms_watchlist")) :

                     (get('role_codes')=="ops_admin" ?
                     <>
                     {this.state.operations_auditor_dd}
                     {this.state.priv_users_dd}
                     </>
                        :
                      this.state.priv_users_dd )}
                    </Form.Control>
                   
                    }
                    {item.type=="date" && 
                    <div className='px-2'>
                    <DatePicker  closeOnSelect={true} value={this.state.criteria_arr[item.key]? moment(this.state.criteria_arr[item.key]).format("DD MMM YYYY"):"" }  dateFormat="DD MMM YYYY" timeFormat={false} required="required"
                    isValidDate={(current) => {
                        const today = moment().startOf('day');
                        return current.isSameOrBefore(today, 'day');  }} 
                    onChange={(value) => {this.handleOnChange( moment(value).format("YYYY-MM-DD"),item.key)}}  inputProps={{ placeholder: `DD MMM YYYY`,autoComplete:'off' }}  />
                    </div>
                    }
                    </div>
                    </fieldset>
                    </div>
                )})}


            </div>

            <div className='d-flex justify-content-center my-3 mb-5'>
               <div>
                <button type="button" class={`btn btn-secondary btn-lg mx-2`} onClick={()=>{this.clearHandler()}}>Reset</button>

                </div>
                <div>
                <button type="button" class={`btn btn-primary btn-lg  ${( this.state.is_onchanged )  ? "":"disable_div"} ${this.state.loader ? "disable_div":""} `} onClick={()=>{this.submitHandler()}}>Submit</button>

                </div>

               </div>
        </div>

        <div>

        {this.state.loader ? <>
           <p className='text-light mt-5 text-center' >Loading...</p>
           <div className="product_takeup_loader "></div></>:
        <>
        {this.state.row_datas && this.state.row_datas.length>0 ?

        <BasicReactTable className='border' row_data={this.state.row_datas} csv_file={true} FName="Watchlist Customers" a column_data={this.state.column_datas} default_page_size={10} pagination={this.state.row_datas.length>10 ?true:false}  />
       :
       <p className='text-white text-center my-3'>NO DATA</p>
        }
        </>
        }
        </div>

    </div>

		);
    }
}
export default WatchlistCustomers;
