import {call_fetch, app_path} from './../helpers/fetch_helper';
import { get } from '../helpers/storage_helper';
import { get_path } from '../helpers/common_helper';


export const createAccount = request   => {
   return call_fetch(get_path('admin') + '/account/create', request);
}

export const listAccount = request  => {
   return call_fetch(get_path('admin') + '/account/list', request);
}

export const updatePrimaryAccount = request  => {
   return call_fetch(get_path('admin') + '/account/make_primary', request);
}

export const viewAccount = request  => {
   return call_fetch(get_path('admin') + '/account/view', request);
}

export const updateAccount = request  => {
   return call_fetch(get_path('admin') + '/account/update', request);
}

export const setDefaultAccount = request => {
   return call_fetch(get_path('admin') + '/account/set_default', request);
}
export const getAgentDetails = request => {
   return call_fetch(get_path('kyc') + '/partner/agent_details', request);
}

export const getRetrevelAccs = request => {
   return call_fetch(get_path('kyc') + '/common/retrieval_accounts', request);
}

export const removeDisabledAccount = request => {
   return call_fetch(get_path('admin') + '/account/remove_account', request);

}