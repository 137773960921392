import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {reqWriteOff, getWriteOff, appr_reject_writeoff, getRecoveryAmount} from '../../../actions/write_off_action';
import {getLoanProv} from '../../../actions/common_actions';
import CurrentOSFA from '../../lender/component/CurrentOSFA';
import {get_repayment_summary} from '../../../actions/loan_actions';
import {lbl_amt, word_count} from '../../../helpers/common_helper';
import  {check_priv} from '../../../helpers/storage_helper';
import Select from 'react-select';
class WriteOff extends FlowComponent {
    state = {
        modalBox: false,
        write_off :{},
        writeoff_data : {},
        summary : {},
        loan_prov : {},
        message_count : 0,
        loan_provisioning : {},
        is_selected_prov_year : false
      }
    componentWillMount(){
        
        this.write_off = {'loan_prov_id' : ''}
        const request = {'loan_doc_id' : this.props.loan_doc_id, 'mode' : 'capture', 'amount' : 0};
        get_repayment_summary(this.req(request))
        .then((response) => {
            this.resp(response);
            if(!response){return};
            if(response.status === 'success'){
                this.setState({summary : response.data});
            }
        });
        
        if([null, 'rejected'].includes(this.props.write_off_status)){
            this.setOptionsListToStateFromAppApi("write_off","loan_prov_year", this.req(), ["loan_prov_id", "loan_prov_year_bal"]);
        }

        if(this.props.write_off_status == 'requested'){
            const request = {'write_off_id' : this.props.write_off_id};
            getWriteOff(this.req(request, true))
            .then((response) => {
                this.resp(response);
                if(!response){return};
                if(response.status === 'success'){
                    this.setState({writeoff_data : response.data});
                }
            });
        }
    }
    handleApproveReject = (event) => {
        let mode = event.target.value
        const request = {'write_off_id' : this.state.writeoff_data.id, "loan_doc_id" : this.props.loan_doc_id, "mode" : mode};
        appr_reject_writeoff(this.req(request))
        .then((response) => {
            this.resp(response);
            if(!response){return};
            if(response.status === 'success'){
                this.setState({ modalBox: false });
                window.location.reload();
                if(mode == 'Approve'){
                    alert(`Write Off Approved successfully.`);
                }else{
                    alert(`Write Off Approval rejected successfully.`);
                }  
                
            }
        });
    }

    handleOpen = (event) => { 
        this.setState({ modalBox: true });    
    }

    handleClose = (event) => {
        this.setState({ modalBox: false, message_count : 0}); 
    }
    handleReason = (event) => {
        var write_off = this.state.write_off
		write_off[event.target.id] = event.target.value
		this.setState({write_off, message_count: word_count(event.target.value)});
    }

    handleReqWriteOff = (event) => {
        let write_off = this.state.write_off
        let request;
        
        if(this.state.summary.os_principal > this.state.loan_prov.balance){
            alert("Provisioning amount is less than write off amount. Cannot write off for this customer.");
            return;
        }

        // if(this.state.message_count < 1000){
        //     alert("Remarks length should be in more than 1000 characters");
        //     return; 
        // }
        const data = {"loan_doc_id": this.props.loan_doc_id, "acc_prvdr_code" : this.props.acc_prvdr_code};
        
        if(write_off.remarks && this.write_off.loan_prov_id){
            request = {'loan_prov_id' : this.write_off.loan_prov_id, "write_off_amount": this.state.write_off_amount, ...data, ...write_off};
        }
        else{
            alert("Please enter required field");
            return;
        }
        
        reqWriteOff(this.req(request, true))
            .then((response) => {
                this.resp(response);
                if(!response){return};
                if(response.status === 'success'){
                    this.setState({ modalBox: false });
                    alert(`Write Off ${response.data.status} submitted successfully.`);
                    window.location.reload();
                }
            });
    }

    getRecoveryAmount = (e) => {
        
        const request = {"loan_doc_id": this.props.loan_doc_id, "loan_prov_id" : this.write_off.loan_prov_id};
        if(this.write_off.loan_prov_id){
            getRecoveryAmount(this.req(request))
            .then((response) => {
                this.resp(response);
                if(!response){return};
                if(response.status === 'success'){
                    this.setState({ is_selected_prov_year: true, write_off_amount : this.state.summary.os_principal + response.data});
                }
            });
        }
        
        
    }
    render(){
        
        let btn_value = this.props.write_off_status == 'requested' ? 'Approve' : 'Submit for Approval';
        let close_btn_value = this.props.write_off_status == 'requested' ? 'Reject' : 'Cancel';
        return (
            <div>

                 {[null, 'rejected'].includes(this.props.write_off_status) && check_priv('loan', 'request_write_off') &&
                
                    <div className="col-md-12">
                        <Button variant="primary" onClick={this.handleOpen} value='write_off'>Request Write Off</Button>
                        <br/>
                        <br/>
                    </div>
                  
                }
                {this.props.write_off_status == 'requested' && check_priv('loan', 'approve_write_off') &&
                    <div className="col-md-12">
                        <Button variant="primary" onClick={this.handleOpen} value='write_off'>Approve Write Off</Button>
                        <br/>
                        <br/>
                    </div>
                }
                <Modal show={this.state.modalBox} onHide={this.handleClose} size="xl">
                    <Modal.Header closeButton show={this.state.modalBox} onHide={this.handleClose}>
                        <Modal.Title>
                            {[null, 'rejected'].includes(this.props.write_off_status) && "Request Write Off "} 
                            {this.props.write_off_status == 'requested' && "Approve Write Off "} 
                             - {this.state.summary.cust_name} / {this.state.summary.acc_number}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{width : '1000px', margin: '0 auto'}}>   
                        <div class="d-flex">
                            <div class="mr-auto p-2" style={{width : '350px'}}>
                                {this.props.write_off_status == 'requested' && 
                                <div className='text-labelblue'>
                                    <Row>
                                        <Col><b>Requester</b></Col>
                                        <Col>{this.state.writeoff_data.req_name}</Col>
                                    </Row>
                                    <Row>
                                        <Col><b>Requested Date</b></Col>
                                        <Col>{this.state.writeoff_data.req_date}</Col>
                                    </Row>
                                </div> 
                                
                                }
                            </div>
                            <div class="p-2">
                                <Link className="nav-link" to={`/call_log/search/${this.props.cust_id}`} target='_blank'>Reason Call Log</Link>
                            </div>
                            <div class="p-2">
                                <Link className="nav-link" to={`/field_visit/search/${this.props.cust_id}`} target='_blank'>Reason Visit Log</Link>
                            </div>
                        </div>
                        <div >
                            <hr/> 
                            <CurrentOSFA summary={this.state.summary} show_waiver = {false}/>   
                            <hr/>
                        </div>
                        {this.props.write_off_status == 'requested' &&
                        <div className='text-labelblue'>
                            <Row>
                                <div className='col-md-2 text-center'><b>Remarks</b></div>
								<div className='col-md-10' style={{textAlign:'justify'}}>{this.state.writeoff_data.remarks}</div>
                            </Row>
							<hr/>
                        </div>
                        }
                        {/* <div style={{textAlign : 'center'}}>
                            <div style={{fontSize : '40px'}}>Write Off</div>
                            <div style={{fontSize : '30px'}}>{lbl_amt(this.state.summary.os_principal,this.currency_code)}</div>
                            <hr/>
                        </div>
                         */}
                         <div>
                        {[null, 'rejected'].includes(this.props.write_off_status) &&
                            <Row>
                                <label className="col-md-5 text-right"><b>Provisioning Balance</b><font className="text-danger">   *  </font></label>
                                 <div className="col-sm-4 reset-padding">
                                 <select id="loan_prov_id" ref={select => this.loan_prov = select} onChange={(e) => {this.handleSelfChange(e); this.getRecoveryAmount(e)}} className={`form-control ${this.state.credit_style}`} type="text">
                                    {this.state.loan_prov_year_dd}   
                                </select>
                                 </div> 
                            </Row>
                        }
                        <div>
                            <Row>
                                {this.state.is_selected_prov_year &&
                                    <Col className='writeoff-text'>
                                        <div>Write Off</div>
                                        <div>
                                            {lbl_amt(this.state.write_off_amount,this.currency_code)} 
                                        </div>
                                    </Col>
                                }
                                {this.props.write_off_status == 'requested' &&
                                    <>
                                    <Col className='writeoff-text'>
                                        <div>Provisioning Year</div>
                                        <div>
                                            {this.state.writeoff_data.year} 
                                        </div>
                                    </Col>
                                    <Col className='writeoff-text'>
                                        <div>Balance</div>
                                        <div>
                                            {lbl_amt(this.state.writeoff_data.balance,this.currency_code)} 
                                        </div>
                                    </Col>
                                    <Col className='writeoff-text'>
                                        <div>Write Off</div>
                                        <div>
                                            {lbl_amt(this.state.writeoff_data.write_off_amount,this.currency_code)} 
                                        </div>
                                    </Col>
                                    </>
                                }
                                 <hr/>
                            </Row>
                           
                        </div>
                        
                        {this.props.write_off_status == 'requested' &&
                            <Row>

                            </Row>
                        }
                        </div>
                        {[null, 'rejected'].includes(this.props.write_off_status) &&
                        <div>
                            <Row>
                                <Col>
                                    <Col><label for="" className="font-weight-bold">Remarks<font className="text-danger">   *  </font></label></Col>
                                    <Col><div>
                                            <textarea type="text" id="remarks" className="form-control" onChange={(e) =>{this.handleReason(e);}}  maxLength='2000' rows="5" cols="30"/>
                                        </div>
                                    </Col>
                                    <div id="float_style">
                                        <b>({this.state.message_count} char )</b>
                                    </div>
                                </Col>
                                
                                
                            </Row>
                        </div>
                        
                        }
                        
                    </Modal.Body>

                    <Modal.Footer>
                        
                    {/* <Button variant="secondary" onClick={this.handleClose}>{close_btn_value}</Button> */}
                    {[null, 'rejected'].includes(this.props.write_off_status) &&
                        <>
                        <input type="button"  className="btn btn-secondary" onClick={this.handleClose} value={close_btn_value}/>
                        <input type="button" disabled={this.state.calling_api} className="btn btn-primary" onClick={this.handleReqWriteOff} value={btn_value}/> 
                        </>
                    }
                    {this.props.write_off_status == 'requested' && 
                        <>                        
                        <input type="button" disabled={this.state.calling_api} className="btn btn-secondary" onClick={this.handleApproveReject} value={close_btn_value}/>
                        <input type="button" disabled={this.state.calling_api} className="btn btn-primary" onClick={this.handleApproveReject} value={btn_value}/>
                        </>
                    }

                </Modal.Footer>
          </Modal>
            </div>
        )
    }
}
export default WriteOff;
