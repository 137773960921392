import { Navigate } from 'react-router-dom';
import React from 'react';
import '../../../styles/flow.css';
import FlowContainer from '../../common/container/core/FlowContainer';
import { createMasterDataKey } from '../../../actions/master_data_actions';
import MasterDataKey from '../component/MasterDataKey';

class MasterDataKeyContainer extends FlowContainer{

	state = {
		data_keys : "",
		master_data_key: {country_code: "*"},
		toList: false
	}


	handleSubmit = (event)  => {
		 event.preventDefault();
		 //alert("hyy");
		 var master_data_key = this.state.master_data_key;
		 //master_data_key.country_code = "*";
			createMasterDataKey(this.req({master_data_key}))
		      .then((response) => {
         		 if(!response){return };
		             alert("Master Data Key saved succesfully!");
		              this.setState({toList: true});
		          }
		        );
	}

  render(){

  	if (this.state.toList === true) { 
           return <Navigate to={`/master_data_key/list`} />
    }
    return (
	 <div id="" >
	 	<div className="container">
	        <MasterDataKey id="master_data_key" data={this.state.master_data_key} onComponentChange={this.handleComponentChange}/>
            <input type="button" className="btn btn-primary" onClick={this.handleSubmit} value="Save" />
   </div>
    </div>
    );
  }
}


export default MasterDataKeyContainer;

