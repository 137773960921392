import FlowComponent from '../common/component/core/FlowComponent';
import React from 'react';
import {format_date, title_case, lbl_amt, percent_rnd, get_path} from '../../helpers/common_helper';
import '../../styles/flow.css';
import {Link} from "react-router-dom";
import {Button,Modal, OverlayTrigger, Popover} from 'react-bootstrap';
import {dd_value} from "../../actions/common_actions";
import CommonReactTable from '../../helpers/commonReact_table';
import {listTaskRequests, approveTaskReq } from '../../actions/task_actions';

import {get} from '../../helpers/storage_helper';
import Accordion from 'react-bootstrap/Accordion';
import {Card} from 'react-bootstrap'
import {BsChevronDown} from 'react-icons/bs';
import LastNFAs from '../loan_appl/component/LastNFAs';
import DisplayImage from '../common/component/DisplayImage';
import {capitalize_first_letter,get_dir_path} from '../../helpers/common_helper';
import {GoVerified}  from "react-icons/go";
import moment from 'moment';
import {BsFillPersonFill} from "react-icons/bs";
import {FcApproval} from 'react-icons/fc';
import { BasicReactTable } from '../../helpers/react_table';
class TaskRequests extends FlowComponent {

	state = {
        rowdatas :[],
        copy : [],
        columns : [],
        approval_modal :false,
        upgrade_amt:null,
        modal_data:{},
        show_table: false,
        task_approval:false,
        root:get_path("view_file"),
   
    fa_upgrade_details :[
        {
        label:"Requested on",key:"approved_date"},
        {label:"Approved Amount",key:"amount"},
        {label:"RM Name",key:"approver_name"},
        {label:"Last Visited Date",key:"last_visit_date"},
        {label:"RM’s Remarks",key:"approval_reason"}]
    }

	handleToggle = (e) => {

        switch(e){
            case("Account"):
                this.setState(state => state.show_table = !this.state.show_table);
                break;
        }
    }

    componentWillMount() {

        this.drawTable()
    }

    drawTable(){
        listTaskRequests(this.req({'task_type' : this.props.task_type, "role_code" : get('role_codes')}))
        .then((response) => {
            this.resp(response)
            if(!response){return}
            if(response){
                this.setState({refresh: false});
                this.setState(state => state.rowdatas = response.data)
                this.setState(state => state.copy = response.data)
                this.setState(state => state.columns =  this.getcolumndatas())
            }
        });
    }

    view_documents(data){
        this.setState({view_documents_modal:true,view_documents_data:data})
    }

    getcolumndatas = () => {
        let column_datas =[];
        if(this.props.task_type=="waiver_request" || this.props.task_type== "fa_upgrade_request"){

        let view_access = ["customer_success", "customer_success_officer","it_admin","super_admin","market_admin","app_support",'ops_anlyst'];
       
        {view_access.includes(get('role_codes')) && column_datas.push({
                                                                        Header : "Approval Pending with", 
                                                                        id: 'approval_data',
                                                                        minWidth:60,
                                                                        accessor:row =>
                                                                        <>
                                                                        {row.approval_data ?
                                                                         <OverlayTrigger placement='auto' trigger={['hover', 'focus']} overlay={  <Popover style={{fontSize: "15px", padding: "15px", color:"mediumblue", fontFamily: "Montserrat"}}>{row.approval_data[0].approvers_name}</Popover>}>
                                                                                            <div>
                                                                                               Pending  {row.approval_data[0].role_codes} approval
                                                                                            </div>
                                                                        </OverlayTrigger>
                                                                        :"-"}
                                                                        </>
                                                                    })};

        // {get('role_codes') == "ops_admin" && column_datas.push({
        //                                                             Header : "Status",
        //                                                             id: 'approval_json',
        //                                                             minWidth:100,
        //                                                             maxWidth:200,
        //                                                             accessor:row => row.approval_json.filter((json=>json.person_id == get('user_id'))).map((json)=>{
        //                                                             return(
        //                                                                     <p style={{fontSize:'13'}}>{ json.approved ? 'Pending with others' : 'Pending your Approval' }</p>
        //                                                                 )})
        //                                                         })
        {get('role_codes') == "ops_admin"    && column_datas.push({
                                                            Header : "Action",
                                                            id: 'ids', 
                                                            Cell: cell => (<Button variant="primary" onClick={e => this.handleApprovalModal(e ,cell.row.original)}>view</Button> )
                                                        })} 
        }
		switch(this.props.task_type){			
			case 'waiver_request':				
				return [
					{
                        Header : "Cust ID",
                        id: 'cust_id',
                        minWidth:100,
                        maxWidth:200,
                        accessor: row => <Link style={{color: "#17a2b8 !important"}} to={"/borrower/indiv/view/"+row.cust_id} target="_blank">{row.cust_id}</Link>
                    },
                    {
                        Header : "Loan Doc ID",
                        id: 'loan_doc_id',
                        minWidth:100,
                        maxWidth:200,
                        accessor: row => <Link style={{ color: "#17a2b8 !important"}} to={"/fa/view/"+row.loan_doc_id} target="_blank">{row.loan_doc_id}</Link>
                    },
                    {
                        Header : "Biz Name",
                        id: 'biz_name',
                        minWidth:100,
                        maxWidth:200,
                        accessor: row => {return title_case(row.biz_name)}
                    },
                    {
                        Header : "Waiver Amount",
                        id: 'task_json',
                        minWidth:100,
                        maxWidth:200,
                        accessor: row => {return lbl_amt(row.task_json.waiver_penalty , this.currency_code)}            
                    },
                    {
                        Header : "Requested On",
                        id: 'created_at',
                        minWidth:100,
                        maxWidth:200,
                        accessor: row => {return format_date(row.created_at)}
                    }, ...column_datas]

            case 'view_doc_request' :
                				
				return [
					{
                        Header :<div className='text-center'>Cust ID</div> ,
                        id: 'cust_id',
                     
                        accessor: row => <div className='text-center'><Link  style={{color: "#17a2b8 !important"}} to={"/borrower/indiv/view/"+row.cust_id} target="_blank">{row.cust_id}</Link></div> 
                    },
                    {
                        Header : <div className='text-center'>RM Name</div>,
                        id: 'rm_name',
                      
                        accessor: row => { return <div className="text-center">{row.task_json.requested_by}</div> }
                    },
                    {
                        Header :<div className='text-center'>Document Name</div> ,
                        id: 'doc_name',
                    
                        accessor: row => {return  <div className="text-center">{ dd_value(row.task_json.doc_name)}</div> }
                    },
                    {
                        Header :<div className='text-center'>Date of Request</div> ,
                        id: 'date_of_req',
                     
                        accessor: row =>  {return  <div className="text-center">{ format_date(row.task_json.requested_date)}</div>}            
                    },
                    {
                        Header :<div className='text-center'>Action</div> ,
                        id: 'action',
                     
                        accessor: row =>  {return  <div className="text-center"><button type="button" class="btn btn-primary py-3" style={{borderRadius:'7px'}} onClick={()=>{this.view_documents(row)}}>View</button>
                        </div>}            
                    }
                    , ...column_datas]

			case 'fa_upgrade_request':				
            return[
                {
                    Header : "Cust ID",
                    id: 'cust_id',
                    accessor: row => <Link style={{color: "#17a2b8 !important"}} to={"/borrower/indiv/view/"+row.cust_id} target="_blank">{row.cust_id}</Link>
                },
                {
                    Header : "Biz Name",
                    id: 'biz_name',
                    accessor: row => {return title_case(row.biz_name)}
                },
                {
                    Header : "Assessment FA Limit",
                    id: 'assesment_fa_limit',
                    accessor: row => {return lbl_amt(row.assesment_fa_limit, this.currency_code)}
                }, {
                    Header : "Assessment Date",
                    id: 'last_assessment_date',
                    accessor: row => {return format_date(row.last_assessment_date)}
                },

                {
                    Header : "Requested Amount",
                    id: 'requested_amount',
                    accessor: row => {return lbl_amt(row.task_json.requested_amount , this.currency_code)}            
                },
                {
                    Header : "Available amounts",
                    id: 'available_amounts',
                    show:false,
                    accessor:row => row.task_json.available_amounts
                },
    
                {
                    Header : "Current FA Limit",
                    id: 'crnt_fa_limit',
                    accessor:row => {return lbl_amt(row.task_json.crnt_fa_limit , this.currency_code)}
                }
                , ...column_datas         ]

	    }
    }

   

   

    handleApprovalModal = (e, event) =>{
        console.log('event', event)
        this.setState({approval_modal: true, modal_data : event})
    }

    handleApprovalModalClose = (modal_type=null) =>{
        if(modal_type=="faUpgradeModal"){
           this.setState({ faUpgradeModal:false})
        }else{
            this.setState({approval_modal: false,view_documents_modal:false,view_documents_reject_modal:false,task_approval:false,upgrade_remarks:''})

        }
    }

    handleUpgradeAmtCheckbox = (event) => {
        this.setState({upgrade_amt : event.target.value});
    }

    handleTaskRequest = (event) =>{
        const action = event.target.value
        const task_id = event.target.id
        const task_type = this.props.task_type

        const request = {action, task_id, task_type}

        // if(!this.state.upgrade_amt && action == 'approve' && task_type == 'fa_upgrade_request' && !this.state.faUpgradeModal){
        //     alert("Please select the amount");
        //     return
        // }

        if(task_type == 'fa_upgrade_request'){
            request.amount = this.state.modal_data.approval_json[0]['amount']
            request[ event.target.value=="approve" ?'approval_reason' :'reject_reason'] = this.state.upgrade_remarks
        }
    
        const resp = window.confirm(`Are you sure to ${action} ?`);
        if(resp == false){
            return;
        }
        if(this.state.taskRejectReason){request["reject_reason"]=this.state.taskRejectReason}

        approveTaskReq(this.req(request))
        .then((response) => {
            this.resp(response)
            if(!response){return}
            if(response){
                if(response.status === "success"){
                    if(action=="approve" && (this.props.task_type == 'view_doc_request')){
                        this.setState({task_approval: true,faUpgradeModal:false,upgrade_remarks:""})
                    }else{
                       alert(response.message); 
                       this.setState({view_documents_modal: false,view_documents_reject_modal:false,faUpgradeModal:false,upgrade_remarks:""})

                    }
                    this.setState({approval_modal: false})
                    this.drawTable()				
                }
            }
        })
    }

  

    render(){ 
        var approved = false
        {console.log('this.state.modal_data', this.state.modal_data)}
        const Remarks = (this.state.modal_data &&this.state.modal_data.approval_json) ? this.state.modal_data.approval_json[0]["approval_reason"]:"-";
        this.state.modal_data &&this.state.modal_data.approval_json  && this.state.modal_data.approval_json.filter((json=>json.person_id == get('user_id'))).map(json =>
            approved = json.approved
        )
        return (
            <div className={this.state.faUpgradeModal ? "disable_div":""}>
                <Accordion>
                    <Card >	
                        <div className={`${this.state.copy && this.state.copy.length==0 ?'empty_display_list': 'non_empty_display_list'}`}>
                        <div className="card-header card-header-icon card-header-primary home_header_d-flex_at_response justify-space-between">
                            <h4 className="card-title text-white tableHead_font title-bottom ">{this.props.title}</h4>
                            <Accordion.Toggle className = "col" as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("Account")}>
                                <BsChevronDown className = {this.state.show_table ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow"} size="18"/>
                            </Accordion.Toggle>		
                        </div>
                        </div>	
                        
                    
                        <div className="card-body no-padding justify-content-center">
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    {/* <CommonReactTable showPag={true} defaultPageSize={20} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} resizable={false} defaultSorted={[{id:"created_at",desc:true}]}/>                      */}
                                    {this.state.copy && this.state.copy.length>0 &&
                                     <BasicReactTable  row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} pagination={true}    default_page_size={20}   defaultSorted={[{id:"created_at",desc:true}]}  /> 
                                     }

                                </Card.Body>
                            </Accordion.Collapse>
                        </div>
                    </Card>
                </Accordion>
                <Modal show={this.state.approval_modal} onHide={this.handleApprovalModalClose}  backdrop="static" size="xl" style={{position: "fixed"}}>
                    <Modal.Header closeButton className="p-0" show={this.state.approval_modal} onHide={this.handleApprovalModalClose}>
                        <Modal.Title className='py-3 px-3 modal_title_bg'>{dd_value(this.props.task_type)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            {this.props.task_type == 'waiver_request' &&
                                <>
                                    <div className="floatAdvanceperiodHeaderBox">
                                        <h3 style={{fontSize: "18px"}} className="floatAdvanceperiodHeader pointer no-margin" onClick={()=>{window.open(`/borrower/indiv/view/${this.state.modal_data.cust_id}`, '_blank')}}>Customer :<h1 className="floatAdvanceBluetitle no-margin">{this.state.modal_data.cust_id}</h1></h3>
                                    </div>
                                    <div className="row no-margin pastFloatAdvanceBox justify-content-center">
                                        <div className="col-lg-10 no-padding personalDetailsbox ">
                                            <ul>
                                                <li>
                                                    <div className="row no-margin justify-content-center">
                                                        <div className="no-padding col-lg-5">
                                                            <p className="personalDetailstext no-margin">Biz Name</p>
                                                        </div>
                                                        <div className="col-lg-5 no-padding">
                                                            <ul className="personalPastDetailstext">
                                                                <li>{this.state.modal_data.biz_name}</li>
                                                            </ul>                            
                                                        </div> 
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="row no-margin justify-content-center">
                                                        <div className="no-padding col-lg-5">
                                                            <p className="personalDetailstext no-margin">FA ID</p>
                                                        </div>
                                                        <div className="col-lg-5 no-padding">
                                                            <ul className="personalPastDetailstext">
                                                                <li>{this.state.modal_data.loan_doc_id}</li>
                                                            </ul>                            
                                                        </div> 
                                                    </div>
                                                </li>
                                                {this.state.modal_data && this.state.modal_data.task_json &&
                                                <>                                                    
                                                    <li>
                                                        <div className="row no-margin justify-content-center">
                                                            <div className="no-padding col-lg-5">
                                                                <p className="personalDetailstext no-margin">Current OS Penalty</p>
                                                            </div>
                                                            <div className="col-lg-5 no-padding">
                                                                <ul className="personalPastDetailstext">
                                                                    <li>{lbl_amt(this.state.modal_data.task_json.os_penalty, this.currency_code)}</li>
                                                                </ul>                            
                                                            </div> 
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="row no-margin justify-content-center">
                                                            <div className="no-padding col-lg-5">
                                                                <p className="personalDetailstext no-margin">Request Waiver Amount</p>
                                                            </div>
                                                            <div className="col-lg-5 no-padding">
                                                                <ul className="personalPastDetailstext">
                                                                    <li>{lbl_amt(this.state.modal_data.task_json.waiver_penalty, this.currency_code)}</li>
                                                                </ul>                            
                                                            </div> 
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="row no-margin justify-content-center">
                                                            <div className="no-padding col-lg-5">
                                                                <p className="personalDetailstext no-margin">Late For</p>
                                                            </div>
                                                            <div className="col-lg-5 no-padding">
                                                                <ul className="personalPastDetailstext">
                                                                    <li>{this.state.modal_data.task_json.penalty_days} days</li>
                                                                </ul>                            
                                                            </div> 
                                                        </div>
                                                    </li>
                                                </>

                                                }
                                                <li>
                                                    <div className="row no-margin justify-content-center">
                                                        <div className="no-padding col-lg-5">
                                                            <p className="personalDetailstext no-margin">Requested On</p>
                                                        </div>
                                                        <div className="col-lg-5 no-padding">
                                                            <ul className="personalPastDetailstext">
                                                                <li>{this.state.modal_data.created_at}</li>
                                                            </ul>                            
                                                        </div> 
                                                    </div>
                                                </li>   

                                                 <li>
                                                    <div className="row no-margin justify-content-center">
                                                        <div className="no-padding col-lg-5">
                                                            <p className="personalDetailstext no-margin">RM Remarks</p>
                                                        </div>
                                                        <div className="col-lg-5 no-padding">
                                                            <ul className="personalPastDetailstext">
                                                                <li>{(Remarks) ? Remarks :"-"}</li>
                                                            </ul>                            
                                                        </div> 
                                                    </div>
                                                </li>                           
                                            </ul>
                                        </div>
                                    </div>
                                </>
                             }
                             {this.props.task_type == 'fa_upgrade_request' &&
                                <>
                                
                                <div className=' py-2 px-0' style={{border:'1px solid gray',borderRadius:"10px",background:"#20233f"}}>
                                    <div className='d-flex align-items-center py-2 px-3'>
                                    <p className='m-0'><BsFillPersonFill color="white" size="21"/></p>
                                    <h2 className='m-0 text-white pl-2' style={{fontSize:'17px'}}> Customer Details</h2>

                                    {/* <h2 className=" no-margin pl-2" style={{fontSize:"14px"}}>Customer :<h2 className="floatAdvanceBluetitle no-margin">{this.state.modal_data.cust_id}</h2></h2> */}

                                    </div>
                                    <hr className='m-0' style={{border:'1px solid gray',width:'100%'}}/>

                                    <div className='m-0 row py-2 pb-4 px-3 col-12'>
                                        <div className='col-lg-6 col-md-6 p-0'>
                                        <div className='d-flex align-items-center row m-0 col-12 p-0 '>
                                        <p className="personalDetailstext no-margin col-4">Customer ID</p>
                                        <p className="personalDetailstext text-white col-7 no-margin"> <h2 className="floatAdvanceBluetitle p-0 fixed_font_size no-margin" style={{fontSize:"12px"}}>{this.state.modal_data.cust_id}</h2></p>
                                        </div>

                                        <div className='d-flex align-items-center row m-0 col-12 p-0 '>
                                        <p className="personalDetailstext no-margin col-4">Biz Name</p>
                                        <p className="personalDetailstext text-white col-7 no-margin"> <b>{this.state.modal_data.biz_name}</b></p>
                                        </div>

                                       
                                        </div>

                                        <div className='col-lg-6 col-md-6 p-0'>
                                        <div className='d-flex align-items-center row m-0 col-12 p-0 '>
                                        <p className="personalDetailstext no-margin col-4">No of FA's Taken</p>
                                        {this.state.modal_data &&
                                        <p className="personalDetailstext text-white col-7 no-margin"> {this.state.modal_data.no_of_fas }</p>
                                        }
                                        </div>

                                        <div className='d-flex align-items-center row m-0 col-12 p-0 '>
                                        <p className="personalDetailstext no-margin col-4">Repayment Rate</p>
                                        {this.state.modal_data &&

                                        <p className="personalDetailstext text-white col-7 no-margin"> <b>{percent_rnd(this.state.modal_data.repayment_rate, 100)}</b></p>
                                       
                                         } </div>

                                        
                                        </div>
                                    </div>

                                </div>


                    
                                </>
                             }

                            {this.state.modal_data.cust_id&&
                                    <LastNFAs cust_id = {this.state.modal_data.cust_id} last_n_fas ='4' background={"#20233f"} />
                            }
                            {/* {this.state.modal_data._original && this.state.modal_data._original.last_loan &&
                                <>
                                    <div className="floatAdvanceperiodHeaderBox mt-3">
                                        <h2 className="floatAdvanceperiodHeader no-margin">Last FA</h2>
                                    </div>
                                    <div className="row no-margin pastFloatAdvanceBox justify-content-center">
                                        <div className="col-lg-10 no-padding personalDetailsbox ">
                                            <ul>
                                                <li>
                                                    <div className="row no-margin justify-content-center">
                                                        <div className="no-padding col-lg-5">
                                                            <p className="personalDetailstext no-margin">Float Advance</p>
                                                        </div>
                                                        <div className="col-lg-5 no-padding">
                                                            <ul className="personalPastDetailstext">
                                                                <li>{lbl_amt(this.state.modal_data._original.last_loan.loan_principal, this.currency_code)}</li>
                                                            </ul>                            
                                                        </div> 
                                                                            
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="row no-margin justify-content-center">
                                                        <div className="no-padding col-lg-5">
                                                            <p className="personalDetailstext no-margin">Disbursed Date</p>
                                                        </div>
                                                        <div className="col-lg-5 no-padding">
                                                            <ul className="personalPastDetailstext">
                                                                <li>{format_date(this.state.modal_data._original.last_loan.disbursal_date)}</li>
                                                            </ul>                            
                                                        </div> 
                                                    </div>
                                                </li>                            
                                            </ul>
                                        </div>
                                    </div>
                                </>

                            } */}
                        <div >
                        {this.props.task_type == 'fa_upgrade_request'  &&this.state.modal_data && this.state.modal_data.approval_json &&
                         <>
                                    
                                   
                                <div className='mt-5' style={{border:'1px solid gray',borderRadius:'10px',background:'#20233f'}}>
                                <div className='d-flex align-items-center py-2 px-3'>
                                    <p className='m-0'><GoVerified color="white" size="21"/></p>
                                    <h2 className='m-0 text-white pl-3' style={{fontSize:'17px'}}> FA Upgrade Request Details
</h2>


                                    </div>
                                    <hr className='m-0' style={{border:'1px solid gray',width:'100%'}}/>                                    
                                    <div className='row m-0 px-4 py-1'>
                                    {this.state.fa_upgrade_details && this.state.fa_upgrade_details.map((item,idx)=> {
                                        return (
                                            <div key={idx} className={` py-3 ${item.key =="approval_reason" ? "col-12":"col-6"} `}>
                                               
                                                <p className="personalDetailstext p-0 no-margin">{item.label}</p>
                                                <ul className="personalPastDetailstext m-0 py-1" style={{fontWeight:'500'}}>
                                                {this.state.modal_data.approval_json[0][item.key] ? 
                                                <li>
                                                    {(item.key =="amount")? 
                                                    <>
                                                   { lbl_amt(this.state.modal_data.approval_json[0][item.key],get('market').currency_code)}
                                                    </>
                                                    :
                                                     (item.key =="approved_date")?
                                                     <>{
                                                     moment(this.state.modal_data.approval_json[0][item.key]).format('DD MMM YYYY')}
                                                     </>
                                                     :
                                                     this.state.modal_data.approval_json[0][item.key]
                                                    }
                                                </li>
                                                :""}

                                                   
                                                    
                                                </ul> 
                                            </div>
                                        );
                                    })}
                                    </div>

                                     {/* {this.props.task_type == 'fa_upgrade_request' &&

                                <div className="row justify-content-center align-items-center ">

                                <label  className="col-sm-3  no-margin no-padding"><p className= "personalDetailstext" style={{paddingTop:"20px"}}>Select Amount to upgrade</p></label>
                                    {this.state.modal_data._original && this.state.modal_data._original.task_json &&  this.state.modal_data._original.task_json.available_amounts &&
                                        this.state.modal_data._original.task_json.available_amounts.map((amount) =>{
                                            
                                            return(
                                                <ul className="personalPastDetailstext">
                                                    <li><label><input type="radio" name="appr" value={amount} onClick={this.handleUpgradeAmtCheckbox} /> &nbsp;&nbsp;
                                                        {console.log(amount, 'amountsss')}
                                                        {console.log(this.state.modal_data._original.requested_amount, 'requested_amount')}
                                                        {this.state.modal_data._original.requested_amount == amount ? <><b>{lbl_amt(amount, this.currency_code)} </b>{this.state.modal_data._original.task_json.available_amounts.length  > 1 ? <span>(customer requested amount)</span> : <span></span>}</> : lbl_amt(amount, this.currency_code)}&nbsp;&nbsp;&nbsp;
                                                        </label>
                                                    </li>
                                                    
                                                </ul>    
                                            )  
                                        })
                                    }
                                </div> 
                                 }     */}
                         </div>
                      
                         </>
                        }
                         
                        </div>              
                        </form>
                    </Modal.Body>
                    <Modal.Footer className=" my-2 justify-content-center align-items-center py-2" >
                    {this.state.modal_data && approved == false &&
                    <>
                      
                        <>
                            <Button id = {this.state.modal_data.id}  value = "approve"   variant="primary" disabled={this.state.calling_api}  onClick = {(e)=> (this.props.task_type == 'fa_upgrade_request') ? this.setState({faUpgradeModal:true,upgrademodal:'approve'}) :this.handleTaskRequest(e) } >Approve</Button>
                            <div></div>

                            <Button id = {this.state.modal_data.id}   value = "reject"  variant="danger" disabled={this.state.calling_api} style={{marginLeft:10}} onClick = {(e)=> (this.props.task_type == 'fa_upgrade_request') ?this.setState({faUpgradeModal:true,upgrademodal:'reject'}):this.handleTaskRequest(e)}>Reject</Button>
                        </>
                        
                    </>                    
                    }
                    </Modal.Footer>
                </Modal> 

                {this.state.view_documents_data &&

                
                <Modal className= {` ${this.state.view_documents_modal ? "modal_background_blur" :""}`}  show={this.state.view_documents_modal} onHide={this.handleApprovalModalClose}  size="xl" style={{position: "fixed",opacity:`${this.state.view_documents_reject_modal ? "0.9" :""}`}}>
                  

                    <Modal.Header className="modal-header p-0" style={{background:"#223b56"}}>
                    <h4 class="modal-title py-3" id="exampleModalLabel">Document Approval</h4>
                    <div>
                        <button type="button" className="close d-flex align-items-center" onClick={this.handleApprovalModalClose}>
                            <span aria-hidden="true" className='close_call_log'>&times;</span>
                        </button>
                    </div>
                 </Modal.Header>
                    <Modal.Body className= {` pb-0`}>
                       <div className= {`row m-0 d-flex align-items-center justify-content-between ${this.state.view_documents_reject_modal ? "disable_div" :""}`}>
                        <div className='col-3'>
                        <p className='text-white'> Customer Name</p>
                        <h2 className='text-white  document_approval_text'> {this.state.view_documents_data.biz_name}</h2>
                        </div>
                        <div className='col-3'>
                        <p className='text-white'> Document Name</p>
                        <h2 className='text-white document_approval_text' > {dd_value(this.state.view_documents_data.task_json.doc_name)}</h2>
                        </div>
                        <div className='col-3'>
                        <p className='text-white'> Account Number</p>
                        <h2 className='text-white document_approval_text' > <img className="img-fluid" src={`/img/${this.state.view_documents_data.acc_prvdr_code}_logo_color.png`}  width={"50px"} height={"33px"}/> {this.state.view_documents_data.acc_number}</h2>
                        </div>
                        <div className='col-3'>
                        <p className='text-white'> Account Ownership</p>
                        <h2 className='text-white document_approval_text' > {this.state.view_documents_data.task_json.acc_ownership ? dd_value(this.state.view_documents_data.task_json.acc_ownership,"acc_ownership"):"-"}</h2>
                        </div>

                       </div>

                       <div className={`d-flex row m-0 align-items-center justify-content-center mt-5 mb-3 ${this.state.view_documents_reject_modal ? "disable_div" :""}`}>
                        <div className='col-lg-6 col-md-12 col-sm-12 h-100'>
                        { this.state.view_documents_data.task_json.doc_name=="self_declaration" ?
                            <div style={{height:'560px',border:'1px solid gray'}}>
                                <object id="pdf-object" type='application/pdf' className='bg-secondary' data={`${this.state.root}${this.state.view_documents_data.task_json.file_path}?#toolbar=0&navpanes=0&page=1`}  width={'100%'} height={'100%'} allowtransparency='true'>  
                                    <p className='text-center'>NO DATA</p>
                                </object> 
                                <div className='d-flex justify-content-end'>
                                <a href={`${this.state.root}${this.state.view_documents_data.task_json.file_path}`} className='btn btn-success btn-sm my-0 mx-3' target="_blank">Download Pdf</a>
                                </div>
                        </div>
                       :
                       <DisplayImage image_path={get_dir_path( this.state.view_documents_data.task_json.file_path ,this.state.view_documents_data.task_json.file_name)} index={null} rotate={false}  style={{height:'550px',width:'100%'}} file_name={this.state.view_documents_data.task_json.file_name}   actual_file={this.state.view_documents_data.task_json.file_name} />

                    
                      }

                        </div>
                       </div>
                    </Modal.Body>
                    <Modal.Footer className={` my-2 justify-content-center align-items-center py-2 ${this.state.view_documents_reject_modal ? "disable_div":""}`} >
                       
                        <>
                            <Button   value = "approve" id={this.state.view_documents_data.id} variant="primary" disabled={this.state.calling_api}  onClick = {this.handleTaskRequest} >Approve</Button>
                            <div></div>
                            <Button    value = "reject" id={this.state.view_documents_data.id}  variant="danger" disabled={this.state.calling_api} style={{marginLeft:10}} onClick = {()=>{this.setState({view_documents_reject_modal:true})}} >Reject</Button>
                        </>
                    
                    
                    </Modal.Footer>
                </Modal>    
                }

                {(this.state.view_documents_reject_modal || (this.state.task_approval)) &&
                <Modal show={this.state.view_documents_reject_modal || this.state.task_approval } onHide={ this.handleApprovalModalClose ()} size={`${this.state.task_approval ? "sm":""}`}  backdrop="static"  style={{position: "fixed",boxShadow:"none",}} aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                  

                <Modal.Header className="modal-header p-0" style={{background: `${!this.state.task_approval ?"#8b8b8b":"rgb(6, 183, 13"}`}}>
                 {!this.state.task_approval  &&
                 <>
                    <h4 class="modal-title py-3" id="exampleModalLabel"> <b>Reject Reason</b></h4>
                 
                  <div>
                      <button type="button" className="close d-flex align-items-center" onClick={()=>{this.setState({view_documents_reject_modal:false})}}>
                          <span aria-hidden="true" className='close_call_log'>&times;</span>
                      </button>
                   </div>
                </>
                }
                 
               </Modal.Header>
                

                  <Modal.Body className={this.state.task_approval ? "approve_modal_style py-5 ":""} style={{background: `${!this.state.task_approval ?" ":"#06b70d"}`}}>
                  {!this.state.task_approval ?
                    <div className='d-flex align-items-center row col-12 m-0'>
                        <p className='m-0 text-white col-3'> <b>Reason :</b></p>
                        <div className='col-9 p-0'>
                        <input type="text" id="txn_id" onChange={(e) => {this.setState({taskRejectReason:e.target.value})}} className="form-control  col-md-12" tabIndex = '-1' autoComplete='off' required="required"/>
                        </div>
                    </div>:
                        <div>

                        <div className='approve_modal_close_bttn'>
                            <button type="button" className="close d-flex align-items-center" onClick={()=>{this.handleApprovalModalClose()}}>
                                <span aria-hidden="true" className='close_call_log'>&times;</span>
                            </button>
                        </div>

                        <div className='row justify-content-center' style={{background:"rgb(6, 183, 13"}}>
                            <div>
                            <p  className='text-white m-0 text-center'><GoVerified size={"30"}  /></p>
                            <p className='text-white m-0 pt-3' style={{fontSize:'15px'}}> <b>Approval Success</b></p>
                            </div>
                        
                        </div>
                    </div>

                    }
                  </Modal.Body>

                  {!this.state.task_approval &&

                  <Modal.Footer className=" my-2 justify-content-center align-items-center py-2" >
                     
                      <div className={this.state.taskRejectReason ? "":"disable_div"}>
                          
                          <Button    value = "reject" id={this.state.view_documents_data ? this.state.view_documents_data.id:""}  variant="primary" disabled={this.state.calling_api} style={{marginLeft:10,borderRadius:'10px'}}  onClick = {this.handleTaskRequest} >Submit</Button>
                      </div>
                  
                  
                  </Modal.Footer>
                  }

                </Modal>  
                }

            <Modal  show={this.state.faUpgradeModal} onHide={()=>{this.handleApprovalModalClose (this.state.faUpgradeModal ?"faUpgradeModal":null)}}  style={{position: "fixed",boxShadow:"none",}} aria-labelledby="contained-modal-title-vcenter"  backdrop="static"  >
                <Modal.Header className="modal-header p-0" style={{background:"#223b56"}}>
                  <h4 class="modal-title py-3" id="exampleModalLabel"> FA Upgrade {(this.state.upgrademodal=="approve") ? "Approved " :"Rejected" }</h4>
                  <div>
                        <button type="button" className="close d-flex align-items-center" style={{margin:'-15px 2px 0px 0px'}} onClick={()=>{this.handleApprovalModalClose (this.state.faUpgradeModal ?"faUpgradeModal":null)}}>
                            <span aria-hidden="true" className='close_call_log'>&times;</span>
                        </button>
                    </div>
                 </Modal.Header>
                  <Modal.Body className= {` pb-0`}>
                   <div className='d-flex align-items-center justify-content-between addWishList col-12 px-0'>
                                                <textarea 
                                                    rows="4" 
                                                    placeholder='Remarks' 
                                                    className='w-100 p-3 addwatchListTextarea' 
                                                    onChange={(e) => { this.setState({ upgrade_remarks: e.target.value }) }}  
                                                   
                                                >
                                                </textarea>
                                               
                                                </div>
                                         
                                            <div className='d-flex justify-content-center my-3'>
                                            <Button   value = {this.state.upgrademodal}id={this.state.modal_data ?this.state.modal_data.id:""} variant="primary" disabled={!this.state.upgrade_remarks}  onClick ={this.handleTaskRequest} >{this.state.upgrademodal}</Button>
                    </div>
                  </Modal.Body>
                  
              </Modal>   
             </div>
        );

    }
}


export default TaskRequests;
