import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import '../../../styles/flow.css';
import FileUpload from '../../common/component/FileUpload';
import {Edit} from '../../common/partial';

class Lender extends FlowComponent {

  state = {
    comp_name: "lender"
  }

    handleNameChange = (event) => {
        var value = this.country_code.charAt(0)
        value = value + event.target.value.substring(0, 3).toUpperCase();

       // document.getElementById("lender_code").value = value;
        this.props.data['lender_code'] = value;
        
        this.toUpperHandleChange(event);
  }



  render(){
     
    return (
    <div id="" >

      <div className="row">
        <div className="col-md-2"><h4>{this.props.title ? this.props.title : "Lender Details"}</h4></div>

        <div className={`col-md-1 ${this.state.edit_button_style}`} >
        <Edit entity="lender" onClick={this.handleEdit}/>
          {/*<input type="button" onClick={this.handleEdit} value="Edit"/>*/}
         </div>
          <div className={`col-md-1 ${this.state.save_button_style}`}>
          <input  onClick={this.handleSave} type="button" className="btn btn-success" value="Save"/>
        </div>
      </div>
      
      <div className={this.state.div_style}>
        <div className="row">
          <div className="col-md-3">
            <span>Lender Name</span><font className="text-danger">   *  </font>
            <input id="name" value={this.props.data.name} onChange={this.props.mode == 'create' ? this.handleNameChange : this.toUpperHandleChange}   required="required" className="upperCase form-control" />
             
          </div>
          <div className="col-md-3">
            <span>Lender Type</span><font className="text-danger">   *  </font>
            <select id="lender_type" value={this.props.data.lender_type} onChange={this.handleChange}  required="required" className="form-control">
             {this.getSelectOptions("lender_type")}
            </select>

          </div>  
          <div className="col-md-3">
            <span>Lender Code</span><font className="text-danger"> * </font>
            <input type="text" id="lender_code" disabled value={this.props.data.lender_code} maxLength={4} onChange={this.handleChange}  required className="form-control" />
          </div>
        </div>
        <br/>
         <div className="row">
          <div className="col-md-6">
            <FileUpload id="lender_logo" label= "Logo" lender_id={this.props.data.id} 
            db_file_name={this.props.data.lender_logo} type="image" mode={this.props.mode} 
            onCreateUpload={this.handleChange} lender_code={this.props.data.lender_code} 
            onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e)}} 
            />
          </div>
          </div> 
      </div>
    </div>

      );
  }
}

export default Lender;
