import React from 'react'
import {Form} from 'react-bootstrap';
import DatePicker from 'react-datetime';
import FlowComponent from '../../common/component/core/FlowComponent';
import { format } from 'date-fns';
import { dd } from '../../../actions/common_actions';

class SMSReport extends FlowComponent{

    state ={

    }
    componentWillMount(){
        this.handleCurdate()
    }

    handleCurdate(){
        const date = new Date()
        const cur_date = format(date, 'dd MMM y') 
        return cur_date
    }

    handlePurposeList(){
      
        var vbn = dd ("purpose",null)
        var options = [];
      
        if(vbn.length > 0){

            if(this.props.data.otp_type == 'all'){
                options =  vbn.map((item, index) =>  <><option key={index} value={item.data_code}>{item.data_value}</option></>)
            }
            else if(this.props.data.otp_type == 'otp'){
                options =  vbn.map((item, index) => {if(item.data_value.includes('OTP') === true)
                {return <option key={index} value={item.data_code}>{item.data_value}</option>}});

            }
            else if(this.props.data.otp_type == 'non_otp'){
                options =  vbn.map((item, index) => {if(item.data_value.includes('OTP') === false)
                {return <option key={index} value={item.data_code}>{item.data_value}</option>}});
               
            }
            
            return options;
            
   
        }
    }

    render(){
        return(
            <Form className="mb-3" >
	        	<Form.Row className = "my-4" style={{display:"flex",alignItems:"center"}}>

                        <div className="col-3 no-padding-input" style={{color:"white"}}>
                            <span style={{color:"white"}}>OTP Type</span><font className="text-danger"> * </font>
                            <Form.Control as= "select" id="otp_type" onChange={this.handleChange} >
                            <option value = ''>choose</option>
                            <option value = 'all'>ALL</option>
                            <option value = 'otp'>OTP</option>
                            <option value = 'non_otp'>NON OTP</option>
                                </Form.Control>  
                        </div>

                        <div className="col-3 no-padding-input" style={{color:"white"}}>
                        <span style={{color:"white"}}> Purpose</span>
                            
                            <Form.Control as= "select" id="purpose" onChange={this.handleChange} >
                           
                                <option value = '' >choose</option>
                                {this.handlePurposeList()}                   
                            
                            </Form.Control>
                            
                    </div>

                    <div className="col-3">
                        <span style={{color:"white"}}>Start Date</span><font className="text-danger"> * </font>
                        <DatePicker defaultValue={this.handleCurdate()} onChange={(value) => {this.handleDateChange("start_date", value)}}   closeOnSelect={true}     dateFormat="DD MMM YYYY" timeFormat={false}  />
                    </div>

                    <div className="col-3">
                        <span style={{color:"white"}}>End Date</span><font className="text-danger"> * </font>
                        <DatePicker defaultValue={this.handleCurdate()} onChange={(value) => {this.handleDateChange("end_date", value)}}   closeOnSelect={true}     dateFormat="DD MMM YYYY" timeFormat={false}  />
                    </div> 

                    </Form.Row>
                    <Form.Row className='my-4'>

                    <div className="col-3 no-padding-input" style={{color:"white"}}>
                        <span style={{color:"white"}}>Vendor Code</span>
                        <Form.Control as ="select" id = 'vendor_code' onChange={this.handleChange}>
                        
                        {this.getSelectOptions("vendor")}
                        </Form.Control>
                    </div>

                    <div className="col-3 no-padding-input" style={{color:"white"}}>
                        <span style={{color:"white"}}>Direction</span>
                        <Form.Control as ="select" id = 'direction' onChange={this.handleChange}>
                        {this.getSelectOptions("direction")}
                        </Form.Control>
                    </div>

                    <div className="col-3 no-padding-input" style={{color:"white"}}>
                        <span style={{color:"white"}}> Status</span>
                            <Form.Control as="select" id = "status" onChange={this.handleChange}>
                            {this.getSelectOptions("sms_status")}
                            </Form.Control>
                    </div>

                    <div className="col-3 no-padding-input" style={{color:"white"}}>
                        <span style={{color:"white"}}>Float Advance ID</span>
                        <Form.Control id="loan_doc_id" onChange={this.handleChange} ></Form.Control>  
                    </div>


                    </Form.Row>

                    <Form.Row className='my-4'>
                    <div className="col-3 no-padding-input" style={{color:"white"}}>
                        <span style={{color:"white"}}> Mobile Number</span>
                            <Form.Control id="mobile_num" onChange={this.handleChange}></Form.Control>
                    </div>
                    </Form.Row>
                </Form>

        )
    }

}export default SMSReport