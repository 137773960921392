import React, {useEffect, useState} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Toggle from "./Toggle";

  const ConfirmModal = ({alert_message=null,note=null,is_toggle=null,is_editable_btns =null,cust_data=null,is_pending_call, is_swap_acc,SoftphoneCallOptions})=>{


  const [show,setshow] = useState(false);


  useEffect(()=>{
    if(is_editable_btns || is_pending_call || SoftphoneCallOptions){
      setshow(true)
    }else{
      setshow(!show)
    }
  },[is_toggle])
        
    const ToggleModal =(type=null)=>{


        is_toggle(type)
        setshow(!show)

    }

    

    return(
  <div className="">
         <Modal className="confirm_modal" show={show} onHide={()=>ToggleModal("close")}
          size="lg"
       aria-labelledby="contained-modal-title-vcenter"
         backdrop="static"
        keyboard={false}
      centered 
    >
     <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter confirm_Modal">
      
       <b>{is_editable_btns ? cust_data.search_button ? "Number Not Found " :"Customer Not Found" :"Confirmation"}</b> 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <p className="m-0 text-white">{alert_message}</p>
        </div>
        {SoftphoneCallOptions ? 
           <div className="d-flex align-items-center justify-content-end">
              <button type="button" class="btn btn-confirm mx-3  px-4" onClick={(e)=>ToggleModal("yes")} >  physical phone</button>
              <button type="button" class="btn btn-cancel mx-3  text-white  px-4" onClick={(e)=>ToggleModal("no")} > soft-phone</button>

   
           </div>
        :
        
      
      <div className="d-flex align-items-center justify-content-end">
        {/* <button type="button" class="btn btn-confirm mx-3 " onClick={(e)=>ToggleModal("confirm")} >Confirm</button>
        <button type="button" class="btn btn-cancel mx-2"  onClick={()=>ToggleModal(null)} >Cancel</button> */}
        <button type="button" class="btn btn-cancel text-white mx-2"  onClick={()=>ToggleModal( is_editable_btns  ? "continue" : is_pending_call ? "cancel": null)} >{is_editable_btns ? "Continue" :"Cancel"}</button>
        {cust_data && cust_data.search_button &&
        <button type="button" class="btn btn-confirm mx-3 " onClick={(e)=>ToggleModal( is_editable_btns  ? "search_customer" :"confirm")} > {is_editable_btns ? "Search Customer":"Confirm"}</button>
        }
        {(is_pending_call || is_swap_acc) &&
         <button type="button" class="btn btn-confirm mx-3 " onClick={(e)=>ToggleModal("confirm")} > Confirm</button>
        }

        </div>}
      </Modal.Body>
     
    </Modal>
    </div>
    )

}


export default ConfirmModal