import React from 'react';
import FlowContainer from '../../common/container/core/FlowContainer';
import InputSelectionHeader from '../component/InputSelectionHeader';
import DateTimePicker from 'react-datetime';
import moment from "moment";
import { BiAlarm ,BiUser,BiMoney,BiIdCard,BiCommentCheck,BiTask,BiCommentAdd,BiRefresh,BiDollarCircle} from "react-icons/bi";
import { BsInfo} from "react-icons/bs";
import { BsXOctagonFill } from "react-icons/bs";
import { IoMdArrowDropright } from "react-icons/io";
import {RoundOffSplitter, convertMinutesToHours, get_cs_agent_names} from '../../../helpers/common_helper';
import {ImMobile} from 'react-icons/im'
import{BsFillPersonCheckFill,BsFileEarmarkCheckFill} from 'react-icons/bs';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { listAccountProvider } from '../../../actions/account_provider_actions';
import { get } from '../../../helpers/storage_helper';
import { each } from 'jquery';
import { Link } from "react-router-dom";
import {getRefAccounts} from '../../../actions/account_txn_actions';
import Biz_opertations_yesterday_results from '../component/Biz_opertations_yesterday_results';
import { Biz_operation_live_reports,getCurrencyDetails, inbound_voice_call_report } from '../../../actions/report_actions';
import { AiOutlineBarChart, AiOutlineSchedule } from 'react-icons/ai';
import ReportChart from './ReportChart';


class Biz_operation_live_results extends FlowContainer {
    state ={
      lowestBalanceByProvider:{},
        this_month:true,
        last_month:false,
        today:true,
        yester_day:false,
        active:'Today',
        acc_prvdrs:[],
        Fas_pending:[{"title":"With RM","icon":<BiUser/>,"value":0,"time":"25 " },
                     {"title":"With Customer","icon":<BiIdCard/>,"value":0,"time":"25" },],

        Disbursement_pending:[{"title":"Returned Disbursement","value":5},
                      {"title":"Duplicate Disbursement","value":0},
                      {"title":"Un-Returned Disbursement","value":0}],
       
       mobile_users:[{"title":"Newly Onboarded","icon":<BiUser/>,"value":100,"total":"500" ,"background":'#9b3e9a' },
                     {"title":"Installations","icon":<BiUser/>,"value":100,"total":"500 " ,"background":'#9b3e9a' },
                     {"title":"Disbursal","icon": <BsFillPersonCheckFill/>, "total":"400","value":250,"background":'#bfc95c' },
                     {"title":"FAs Applied","icon":<BiCommentAdd/>,"value":400 ,"background":'#c47630'},
                     {"title":"FAs Repaid","icon":<BiTask/>,"value":100,"background":'#3d9668' }],
       float_account_balance:'',
       repayment_view:'',
       activeAcc_prvdrs:null,
       all_network_prvdr:[],
       live_btn:true,
       mobile_users_view:'',
       fas_pending_view:'',
       fas_pending_disbursement:'',
       start_date: moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD"),
       end_date: moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD")   ,
       data:[],
       to_repayment_call:0,
       last_repayment_days:0,
       firstbalance:0,
       selected_val:5,
       loader:false,
       current_active_acc_prvdr:'',
       martket_countryCode : get('market').country_code,
       active_currency:'',
       onbord:"false",
       repayment_loader:false,
       mobile_users_loader:false,
       active_acc_prvdr:null,
       lazy_loading:[],
       shouldRefresh: false,
       inbound_voice_chart_data: []


      
     }
     componentWillMount(){
       
       var request = {country_code: get('market').country_code, time_zone: get('market').time_zone, status: ""};      
       listAccountProvider({...request, status: 'enabled', biz_account: true})
         .then((response)=>{
             if(!response){return };
             if(response.status === "success"){
                 let acc_prvdrs = []
                 response.data.list.map(each=>
                     acc_prvdrs.push(each.acc_prvdr_code)
                 )
           this.setState({acc_prvdrs,active_acc_prvdr:acc_prvdrs[0] })     
          
 
             }
         })    
        this.biz_ops_live_reports()

        this.currency_details()
       this.setState({martket_countryCode : get('market').country_code,})
     }

    //  inbound calls report
    componentWillMount(){
      this.get_inbound_voice_call_data()

    }

     componentDidMount() {
      this.refreshTimer = setTimeout(() => {
        this.setState({ shouldRefresh: true });
      }, 300000); 
    }
  
    componentWillUnmount() {
      clearTimeout(this.refreshTimer);
    }

     currency_details() {
      getCurrencyDetails(this.req())
      .then((response)=>{
      if(response.status=="success"){
         this.setState({currency_details: response.data, active_currency:response.data[this.props.country_code].currency_code})
      }
      })
    }

     componentDidMount() {
       this.interval = setInterval(() => this.setState({ time: Date.now()}, this.biz_ops_live_reports()), 60000);
     }
     componentWillUnmount() {
       clearInterval(this.interval);
     }
     componentDidUpdate(prevProps, prevState, snapshot){
       if(this.state.to_repayment_call != this.state.last_repayment_days){
         
        this.setState({to_repayment_call:this.state.last_repayment_days,repayment_loader:true},() =>{
          this.biz_ops_live_reports('repayment_metrics',  moment().subtract(this.state.to_repayment_call, 'days').format('YYYY-MM-DD'), this.state.end_date)
          
        })
        

       }
        if(this.state.activeAcc_prvdrs!=this.props.active_acc_prvdr){
         this.setState({activeAcc_prvdrs:this.props.active_acc_prvdr,loader:true})
         this.biz_ops_live_reports()
         if(this.state.lazy_loading !=undefined && this.state.lazy_loading.length > 0){
          this.biz_ops_live_reports(this.state.lazy_loading)

        }

       }

       if(this.props.country_code!=this.state.martket_countryCode){
       
          var curreny_code=this.state.currency_details ? this.state.currency_details[this.props.country_code].currency_code:''
          this.setState({active_currency:curreny_code,martket_countryCode:this.props.country_code,loader:true },()=> this.biz_ops_live_reports())
     
       }
      
      

     }

     biz_ops_live_reports(criteria=null,start_date=this.state.start_date,end_date=this.state.end_date,acc_prvdr=this.props.active_acc_prvdr,country_code=this.state.martket_countryCode){
    
      Biz_operation_live_reports(this.req({"report_type":'operation_dashboard',"criteria":criteria,"start_date":start_date,"end_date":end_date,"acc_prvdr_code":acc_prvdr,"country_code":this.state.martket_countryCode}, false))
      .then((response)=>{
        if(!response){return };
        if(response.status=="success"){
        
          var resp_data=response.data
          var data = this.state.data
          data = {...data,...resp_data};
          if(response.data.config){
           this.setState({dash_config: response.data.config})
          }
           this.setState({data:data,loader:false,repayment_loader:false})

           if(response.data.fas_pending){
         
            const fas_pending =response.data.fas_pending;
            const fas_pending_tittle_order = ['w_rm','w_cust'];
            const fas_pending_icon = [<BiUser/>,<BiIdCard/>,<BiMoney/>,<BiTask/>];
            const emptyarray=[];
            fas_pending_tittle_order.forEach((items,idx)=>{
              
              fas_pending[items]['icon'] = fas_pending_icon[idx]
              emptyarray[idx] = fas_pending[items]
             
            })
            this.setState({Fas_pending:emptyarray,fas_pending_view:response.data.fas_pending})
           }
           if(response.data.fas_pending_disbursement){
         
            const fas_pending_disbursement =response.data.fas_pending_disbursement;
            const fas_pending_tittle_order = ['rd_count','dd_count','un_rd_count'];
            const emptyarray=[];

            fas_pending_tittle_order.forEach((items,idx)=>{
              emptyarray[idx] = fas_pending_disbursement[items]
            })
            this.setState({Disbursement_pending:emptyarray,fas_pending_disbursement:response.data.fas_pending_disbursement})
           }
           if(response.data.mobile_users){
            
              var mobile_users =response.data.mobile_users;
              const mobile_users_tittle_order = ['newly_onboarded','registered','active','fas_applied','fas_repaid'];
              const mobile_users_background = ['#9b3e9a','#9b3e9a','#bfc95c','#c47630','#3d9668'];
              const mobile_users_icon = [<BiUser/>,<BiUser/>,<BsFillPersonCheckFill/>,<BiCommentAdd/>,<BiTask/>];
              var mob_emptyarray=[];
              mobile_users_tittle_order.forEach((items,idx)=>{
                mobile_users[items]['background'] = mobile_users_background[idx]
                mobile_users[items]['icon'] = mobile_users_icon[idx]
                mob_emptyarray[idx] = mobile_users[items]
              })
              this.setState({mobile_users:mob_emptyarray,mobile_users_view:response.data.mobile_users,mobile_users_loader:false})
            } 

             if(response.data.repayment_metrics){
               this.setState({repayment_view:response.data.repayment_metrics})
             
            }

            if (response.data.account_balance) {
              var active_acc_prvdrs = [];
              var lowestBalanceByProvider = {};
            
              for (var key in response.data.account_balance) {
                active_acc_prvdrs.push(key);
            
                const accounts = response.data.account_balance[key];
                const lowestBalanceProviderAccount = accounts.reduce((minAccount, currentAccount) => {
                  return currentAccount.balance < minAccount.balance ? currentAccount : minAccount;
                }, accounts[0]);
                lowestBalanceByProvider[key] = lowestBalanceProviderAccount.balance;
              }
              const current_active_acc_prvdrs = active_acc_prvdrs[0];
            
              this.setState({
                float_account_balance: response.data.account_balance,
                active_acc_prvdr: active_acc_prvdrs,
                current_active_acc_prvdr: current_active_acc_prvdrs,
                lowestBalanceByProvider: lowestBalanceByProvider,
              });
            }

          

           
          
     
          }
        
       
      })
     }

      get_inbound_voice_call_data = ()=>{
        var request ={report_type:'inbound_voice_calls_downtime',acc_prvdr:this.props.active_acc_prvdr,start_date:this.state.start_date,end_date:this.state.end_date,screen:'live'}

      inbound_voice_call_report(this.req({...request})).then((response)=>{
        if(!response) return;
        if(response.status =='success'){

          let x_axis_data = response.data.map((val)=>{
                let match = get_cs_agent_names(val.x);
                if (match) {
                    return match;
                }
                return val.x;
              })
          let y_axis_data_in_mins = response.data.map((val)=>val.y)

          let y_axis_data_in_hrs = response.data.map((val)=>{
            let formattedTime = convertMinutesToHours(val.y);
            return formattedTime;
          })

          this.setState({inbound_voice_chart_data:[{data:y_axis_data_in_mins,name:'Down Time In Minutes'},{data:y_axis_data_in_hrs,name:'Down Time In Hours'}],x_axis_inbound_voice_data:x_axis_data})
        }
        else{
          return
        }

      }).catch(error=>{
        alert(error)
      })


     }

 
 
     acquisition_targets(type){
      if(type=="this_month"){
        this.setState({this_month:true ,last_month:false})
      }
      else if(type=="today"){
       this.setState({yester_day:false ,today:true})
      }else if(type=="yester_day"){
 
       this.setState({yester_day:true ,today:false})
      }
      else {
       this.setState({this_month:false ,last_month:true})
      }
     }
 
     tabCheck = (e) =>{
            
       this.setState({
           ...this.state,
           active:e,
 
           })
         
           if(e=='Today'){
            this.setState({last_repayment_days:0,start_date:  moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD"),end_date: moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD")})
            // this.biz_ops_live_reports('repayment_metrics', moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD"),  moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD"))
           }else if(e=='Yesterday'){

            this.setState({start_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),end_date: moment().subtract(1, 'days').format("YYYY-MM-DD"),last_repayment_days :1})
            // this.biz_ops_live_reports('repayment_metrics',  moment().subtract(1, 'days').format('YYYY-MM-DD'),  moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD"))
           }
           else if(e=='Day_b_f_y_day'){

            this.setState({start_date: moment().subtract(2, 'days').format('YYYY-MM-DD'),end_date: moment().subtract(2, 'days').format('YYYY-MM-DD'),last_repayment_days :2})
            // this.biz_ops_live_reports('repayment_metrics',  moment().subtract(2, 'days').format('YYYY-MM-DD'),  moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD"))
           } 
           else if(e=='Last_5_days'){
             
            // this.last_fivedays_reapaymentHandler()
            this.setState({start_date: moment().subtract(5, 'days').format('DD-MM-YYYY'),end_date: moment(new Date(), 'DD-MM-YYYY').format("DD-MM-YYYY"),last_repayment_days : this.state.selected_val})
            // this.biz_ops_live_reports('repayment_metrics',  moment().subtract(this.state.last_repayment_days, 'days').format('YYYY-MM-DD'),  moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD"))

           }else{
          //  this.biz_ops_live_reports('repayment_metrics', moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD"),  moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD"))

           }
   }
 
   network_prvdr_handler(acc_prvdr){
     
     this.setState({current_active_acc_prvdr:acc_prvdr})
    
   }
 
   biz_operation_liveHandler(type){
     if(type=="live"){
       this.setState({live_btn:true, yester_day_btn:false, day_bf_btn:false,this_month_btn:false,last_month_btn:false})
     }
     else if(type=="yesterday"){
       this.setState({live_btn:false, yester_day_btn:true, day_bf_btn:false,this_month_btn:false,last_month_btn:false})
     }
   }
   common_percentage_cal(val,total){
     if(total==0){
      return "";
     }
     else if((val/total)*100 >=0){
      return (val/total*100).toFixed()+ "% ";
     }else{
      return "0 % ";
     }
   
   }

   illustrate_percentage_cal(numerator, denominator){
    if(denominator == 0 ) return "N/A"
    return numerator + "/" + denominator
   }

   last_fivedays_reapaymentHandler(val){
     this.setState({last_repayment_days:val, selected_val:val})
    //  this.biz_ops_live_reports('repayment_metrics',  moment().subtract(val, 'days').format('DD-MM-YYYY'),  moment(new Date(), 'DD-MM-YYYY').format("DD-MM-YYYY"))
   }
  
   float_acc_view_handler(type){
     this.setState({[type]:true})
   }
   lazy_loading_handler(val){
       
     
    var lazy_loading_array = this.state.lazy_loading
    lazy_loading_array.push(val)  
    this.setState({lazy_loading:lazy_loading_array})
   
    this.biz_ops_live_reports(lazy_loading_array.length >0 ? lazy_loading_array:null)

  }
  onboarded_notification(val){

    this.setState({onbord:val})

  }

  convertMinutesToHoursAndMinutes (minutes){
    
    const duration = moment.duration(minutes, 'minutes');
    var mins=duration.minutes()
    var hours=duration.hours()
    if((mins>60)&& (hours>0)){
      var str= (hours >1)? `${hours} hr ${mins} minutes` : `${hours} hours ${mins} minutes`
    }
    return str;
  };
 
    render(){
    return (

        <div style={{minHeight:'80vh'}}>
         
       
            <>
            <div className='row ' style={{background:'#4291da',padding:'15px'}}>
              <div className='d-flex align-items-center'>

              <p className='text-white no-margin business_operations_text biz_operation_font_size' style={{fontSize:'18px'}}><b>LIVE</b></p>
              <div className='d-flex align-items-center biz_operation_font_size'>
              <p className='no-margin biz_operation_font_size' ><b>{ moment(new Date(), 'DD-MM-YYYY').format("DD MMM ")} <span className='biz_operation_font_size' > | </span></b>  </p>
              <p className='no-margin biz_operation_font_size'  ><b>{ new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</b></p>
              </div>


              </div>

            </div>
            {this.state.loader ? 
              <div class="loader">
             
              <div class="cogs">
                <div class="cog cog0">
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                </div>
                <div class="cog cog1">
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                </div>
                <div class="cog cog2">
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                </div>
              </div>
              <div className='load_dot'>
              <div class="dot">F</div>
              <div class="dot">L</div>
              <div class="dot">O</div>
              <div class="dot">W</div>
              <div class="dot">I</div>
              <div class="dot">N</div>
              <div class="dot">G</div>
              </div>
            
            </div>
             
              :
            <>
            <div className='d-flex justify-content-around ' style={{"margin-right": "6px" ,"margin-left": "6px"}} >
                <div className='col-md-6 px-0 business_operations_border biz_operation_fas_pending' style={{ position: 'relative'}}>
                <div className='row'>
                    <div className='d-flex align-items-center '>
                    <div className='d-flex align-items-center biz_operation_font_size' style={{ margin: '10px 0px 0px 20px' }}>
                        <BiAlarm size={30} color={"white"} style={{ marginTop: '-10px' }}  />
                        <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', fontSize: '18px' ,fontWeight:"bold" }}>FAs Pending</h2>
                    </div>
                    <div>
                        {this.state.fas_pending_view ? " " :
                        <div className='ml-4'> <button type="button" class="btn btn-outline-secondary btn-sm mb-0" style={{ color: 'white', borderRadius: '10px' }} onClick={() => this.lazy_loading_handler("fas_pending")}> <b>Show</b></button> </div>
                        }
                    </div>
                    </div>
                </div>
                <div className='business_operations_bottom_border'></div>
                        
                <div className='d-flex justify-content-between align-items-center row biz_operration_padding'>
                    {this.state.Fas_pending.map((item, idx) => (
                    <div className='col-md-6' key={idx}>
                        <div className='biz_operration_padding business_operations_border biz_opr_fas_pending biz_operation_font_size' style={{ marginTop: '20px' }}>
                        <p className='text-white no-margin text-center' style={{ fontWeight:"bold" }}> {item.title} </p>
                        <div className='d-flex align-items-center justify-content-around' style={{ marginTop: '15px' }}>
                            <p className={`fas_pending_icon_color no-margin ${this.state.fas_pending_view ? " " : 'float_account_balance_bgblur'}`} style={{ fontSize: '30px' }}>{item.icon}</p>
                            <p className={`text-white no-margin biz_operation_font_size  ${this.state.fas_pending_view ? " " : 'float_account_balance_bgblur'}`}> <b className='biz_ops_count_fontsize'> {item.value} </b></p>
                            <p className={`no-margin fas_pending_icon_color business_operations_text  ${this.state.fas_pending_view ? " " : 'float_account_balance_bgblur'}`}>for {item.time == null ? '0' : item.time} mins</p>
                        </div>
                        </div>
                    </div>
                    ))}
                </div>
                </div>
                <div className='col-md-6'>
                  <div className=' business_operations_border biz_operation_fas_pending' style={{ position: 'relative' }}>
                  <div className='row'>
                      <div className='d-flex align-items-center'>
                      <div className='d-flex align-items-center biz_operation_font_size' style={{ margin: '10px 0px 0px 20px' }}>
                      <img className={`img-fluid `}src={`/img/Disbursal.png`} alt={""}  style={{ marginTop: '-10px',width:"25%" }}/> 
                          <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', fontSize: '18px'  ,fontWeight:"bold" }}>Disbursal</h2>
                      </div>
                      <div className='d-flex align-items-center' style={{ margin: '5px 0px 0px -10px' }}>{this.state.fas_pending_disbursement &&
                            <h2 className='text-white no-margin' style={{ padding: '10px 0px 10px 10px', fontSize: '12px'}}>(Last {this.state.dash_config.dup_disb_validity} days)</h2>}
                      </div>
                      <div>
                          {this.state.fas_pending_disbursement ? " " :
                          <div className='ml-4'> <button type="button" class="btn btn-outline-secondary btn-sm mb-0" style={{ color: 'white', borderRadius: '10px' }} onClick={() => this.lazy_loading_handler("fas_pending_disbursement")}> <b>Show</b></button> </div>
                          }
                      </div>
                      </div>
                  </div>
                  <div className='business_operations_bottom_border'></div>

                  <div className='d-flex justify-content-between align-items-center row biz_operration_padding'>
                      {this.state.Disbursement_pending.map((item, idx) => (
                      <div className='col-md-4' key={idx}>
                          <div className='biz_operration_padding business_operations_border biz_opr_fas_pending biz_operation_font_size' style={{ marginTop: '20px' }}>
                          <p className='text-white no-margin text-center' style={{fontWeight:"bold" }}> {item.title} </p>
                          <div className='d-flex align-items-center justify-content-center' style={{ marginTop: '30px' }}>
                              <p className={`fas_pending_icon_color no-margin ${this.state.fas_pending_disbursement ? " " : 'float_account_balance_bgblur'}`} style={{ fontSize: '30px' }}>{item.icon}</p>
                              <p className={`text-white no-margin biz_operation_font_size  ${this.state.fas_pending_disbursement ? " " : 'float_account_balance_bgblur'}`}> <b className='biz_ops_count_fontsize'> {item.value} </b></p>
                          </div>
                        </div>
                    </div>
                    ))}
                </div>
                </div>
                </div>
            </div>

            <div className='row d-flex align-items-center justify-content-around' style={{marginTop:'1%'}}>
            <div className='col-md-6 col-lg-2  h-100 col-sm-12 biz_operations_active_customers ' style={{padding:'10px 10px 3% 10px'}} >
           
               <div className={` `}>
              <Link to={'/active_cust_wo_fa/list'}>
                <div className='d-flex align-items-center justify-content-center' style={{cursor:'pointer'}}>
                  <p className='text-white business_operations_dates no-margin' style={{paddingLeft:'10px',cursor:'pointer'}} > <b>Active Customers</b></p>
                  <IoMdArrowDropright   size={30} color={'white'}/>
                </div>
                </Link>
                {this.state.data.active_customer_info? " " :
                    <div className='d-flex justify-content-center' > <button type="button" class="btn btn-outline-secondary btn-sm" style={{color:'white',borderRadius:'10px'}} onClick={()=>this.lazy_loading_handler("active_customer_info")} > <b>Show</b></button> </div>
                 }
                 <div className={` ${this.state.data.active_customer_info ? " " : 'float_account_balance_bgblur'}`}>
                <div className='d-flex justify-content-between' style={{marginTop:'30px'}}>
                    <label> Without FA</label>
                    <div className= {`d-flex align-items-center  `}>
                      <div style={{color:'#4bb6e6'}}>
                          <p className={`text-white biz_operation_font_size no-margin no-padding ${this.state.data.active_customer_info ? " " : 'float_account_balance_bgblur'}`}><b className='biz_ops_count_fontsize'>{this.state.data.active_customer_info ?this.state.data.active_customer_info.wa_fa :''}</b></p>
                        </div>  
                        <div className={``} style={{color:'#4bb6e6'}}>
                        <p className= ' no-margin biz_ops_green_text' style={{paddingLeft:'10px'}}><b>{ this.state.data.active_customer_info ?this.common_percentage_cal(this.state.data.active_customer_info.wa_fa,this.state.data.active_customer_info.tot):''}</b></p>
                        </div> 
                    </div>
                      
                  </div>

                  <div className= {`progress`} style={{height:'7px'}}> 
                    <div className="progress-bar progress-bar-striped"  role="progressbar" style={{width:`${this.state.data.active_customer_info ?this.common_percentage_cal(this.state.data.active_customer_info.wa_fa,this.state.data.active_customer_info.tot):'23%'}`,background:'#f37b0b'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                      </div>
                  </div>
                  
                  <div className='d-flex justify-content-between' >
                    <label style={{marginTop:'18px'}}> Total</label>
                    <div className='d-flex align-items-center'>
                      <div style={{color:'#4bb6e6'}}>
                          <p className='text-white biz_operation_font_size no-margin no-padding'><b className='biz_ops_count_fontsize'>{this.state.data.active_customer_info ?this.state.data.active_customer_info.tot:''}</b></p>
                        </div>  
                      
                    </div>
                      
                  </div>
                  <div className= {`progress ${this.state.data.active_customer_info ? " " : 'float_account_balance_bgblur'}`} style={{height:'7px'}}> 
                    <div className="progress-bar progress-bar-striped"  role="progressbar" style={{width:`100%`,background:'#f37b0b'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                      </div>
                  </div>
                  </div>
             </div>
            </div>

            <div className='col-md-6 col-lg-2 h-100  col-sm-12 Acquisition_Targets biz_operations_active_customers biz_ops_responsive_acq_targets ' style={{padding:'15px'}} >
             
               <div className={``}>
                <div className='d-flex align-items-center justify-content-center ' style={{margin:'0px 0px 15px 0px'  ,fontWeight:"bold" }}>
                  <p className='text-white business_operations_dates  text-center no-margin' > <b>Acquisition Targets</b></p>
                </div>
                {this.state.data.acquisition_target? " " :
                    <div className='d-flex justify-content-center ' > <button type="button" class="btn btn-outline-secondary btn-sm" style={{color:'white',borderRadius:'10px'}} onClick={()=>this.lazy_loading_handler("acquisition_target")} > <b>Show</b></button> </div>
                 }
               
                <div className= {`d-flex align-items-center justify-content-center ${this.state.data.acquisition_target ? " " : 'float_account_balance_bgblur'}`}>
                  <div className='d-flex  align-items-center business_operations_border' style={{borderRadius:'0px',padding:'5px' ,fontWeight:"bold" }}>
                    <p className={`text-white no-margin biz_ops_acq_targets_text ${this.state.this_month ? "business_operations_live_btn" : " "}`}  onClick={()=>this.acquisition_targets("this_month")}  style={{padding:'3px',cursor:'pointer',fontSize:'11px'}} >This month</p>
                    <div class="biz_operations_vl"></div>
                    <p className={`text-white no-margin biz_ops_acq_targets_text ${this.state.last_month ? "business_operations_live_btn" : " "}`} style={{padding:'3px',cursor:'pointer',fontSize:'11px'}} onClick={()=>this.acquisition_targets("last_month")}  >Last month</p>
                  </div>
                </div>
                {this.state.this_month &&
                   <div className={`${this.state.data.acquisition_target ? " " : 'float_account_balance_bgblur'}`}>
                   <div className= {`d-flex justify-content-between  `}>
                       <label> Acquired to date</label>
                       <div className='d-flex align-items-center'>
                         <div style={{color:'#4bb6e6'}}>
                             <p className='text-white biz_operation_font_size no-margin no-padding'><b className='biz_ops_count_fontsize'>{this.state.data.acquisition_target ?this.state.data.acquisition_target.acq.this_month :''}</b></p>
                           </div>  
                           <div style={{color:'#4bb6e6'}}>
                           <p className='text-white no-margin biz_ops_green_text' style={{paddingLeft:'10px'}}><b>{this.state.data.acquisition_target ?this.common_percentage_cal(this.state.data.acquisition_target.acq.this_month,this.state.data.acquisition_target.total.this_month):''}</b></p>
                           </div> 
                       </div>
                         
                     </div>
                     <div className="progress" style={{height:'7px'}}> 
                       <div className="progress-bar progress-bar-striped"  role="progressbar" style={{width:`${this.state.data.acquisition_target ?this.common_percentage_cal(this.state.data.acquisition_target.acq.this_month ,this.state.data.acquisition_target.total.this_month):'75%'}`,background:'#5f895e'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                         </div>
                     </div>
                     
                     <div className='d-flex justify-content-between' >
                       <label style={{marginTop:'10px'}}>{moment(new Date(), 'DD-MM-YYYY').format("MMMM")} Target</label>
                       <div className='d-flex align-items-center'>
                         <div style={{color:'#4bb6e6'}}>
                             <p className='text-white biz_operation_font_size no-margin no-padding'><b className='biz_ops_count_fontsize'>{this.state.data.acquisition_target?this.state.data.acquisition_target.total.this_month :''}</b></p>
                           </div>  
                         
                       </div>
                         
                     </div>
                     <div className="progress" style={{height:'7px'}}> 
                       <div className="progress-bar progress-bar-striped"  role="progressbar" style={{width:`100%`,background:'#5f895e'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                         </div>
                     </div>
                   </div>
                }
                 {this.state.last_month &&
                       <>
                       <div className='d-flex justify-content-between'>
                           <label> Acquired to date</label>
                           <div className='d-flex align-items-center'>
                             <div style={{color:'#4bb6e6'}}>
                                 <p className='text-white biz_operation_font_size no-margin no-padding'><b className='biz_ops_count_fontsize'>{this.state.data.acquisition_target ?this.state.data.acquisition_target.acq.last_month :''}</b></p>
                               </div>  
                               <div style={{color:'#4bb6e6'}}>
                               <p className='text-white no-margin biz_ops_green_text' style={{paddingLeft:'10px'}}><b>{this.state.data.acquisition_target ?this.common_percentage_cal(this.state.data.acquisition_target.acq.last_month,this.state.data.acquisition_target.total.last_month):''}</b></p>
                               </div> 
                           </div>
                             
                         </div>
                         <div className="progress" style={{height:'7px'}}> 
                           <div className="progress-bar progress-bar-striped"  role="progressbar" style={{width:`${this.state.data.acquisition_target ?this.common_percentage_cal(this.state.data.acquisition_target.acq.last_month ,this.state.data.acquisition_target.total.last_month):''}`,background:'#5f895e'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                             </div>
                         </div>
                         
                         <div className='d-flex justify-content-between' >
                           <label style={{marginTop:'10px'}}>{moment().subtract(1, 'months').format('MMMM')} Target</label>
                           <div className='d-flex align-items-center'>
                             <div style={{color:'#4bb6e6'}}>
                                 <p className='text-white biz_operation_font_size no-margin no-padding'><b className='biz_ops_count_fontsize'>{this.state.data.acquisition_target ?this.state.data.acquisition_target.total.last_month :''}</b></p>
                               </div>  
                             
                           </div>
                             
                         </div>
                         <div className="progress" style={{height:'7px'}}> 
                           <div className="progress-bar progress-bar-striped"  role="progressbar" style={{width:`100%`,background:'#5f895e'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                             </div>
                         </div>
                       </>
                }
              </div>
            </div>

            <div className='col-md-6 col-lg-2  h-100  col-sm-12 fas_due biz_operations_active_customers  ' style={{padding:'15px'}} >
       
               <div className={` `}>
                <div className='d-flex align-items-center justify-content-center ' style={{margin:'0px 0px 15px 0px'}}>
                  <p className='text-white business_operations_dates  text-center no-margin' > <b>FAs Due</b></p>
                </div>
                {this.state.data.fas_due? " " :
                   <div className='d-flex justify-content-center' > <button type="button" class="btn btn-outline-secondary btn-sm" style={{color:'white',borderRadius:'10px'}} onClick={()=>this.lazy_loading_handler("fas_due")} > <b>Show</b></button> </div>
                }
               <div className={`${this.state.data.fas_due ? " " : 'float_account_balance_bgblur'}`}>
                <div className= {`d-flex align-items-center justify-content-center `}>
                  <div className='d-flex  align-items-center business_operations_border' style={{borderRadius:'0px',padding:'5px'}}>
                    <p className={`text-white no-margin  ${this.state.today ? "business_operations_live_btn" : " "}`}  onClick={()=>this.acquisition_targets("today")}  style={{padding:'3px',cursor:'pointer'}} >Today</p>
                    <div class="biz_operations_vl"></div>
                    <p className={`text-white no-margin ${this.state.yester_day ? "business_operations_live_btn" : " "}`} style={{padding:'3px',cursor:'pointer'}} onClick={()=>this.acquisition_targets("yester_day")}  >Yesterday</p>
                  </div>
                </div>
                {this.state.today &&
                   <>
                   <div className='d-flex justify-content-between'>
                       <label> Paid so for</label>
                       <div className='d-flex align-items-center'>
                         <div style={{color:'#4bb6e6'}}>
                             <p className='text-white biz_operation_font_size no-margin no-padding'><b className='biz_ops_count_fontsize'>{this.state.data.fas_due ?this.state.data.fas_due.today.paid :''}</b></p>
                           </div>  
                           <div style={{color:'#4bb6e6'}}>
                           <p className='text-white no-margin biz_ops_green_text' style={{paddingLeft:'10px'}}><b>{this.state.data.fas_due ?this.common_percentage_cal(this.state.data.fas_due.today.paid ,this.state.data.fas_due.today.tot):''}</b></p>
                           </div> 
                       </div>
                         
                     </div>
                     <div className="progress" style={{height:'7px'}}> 
                       <div className="progress-bar progress-bar-striped"  role="progressbar" style={{width:`${this.state.data.fas_due ?this.common_percentage_cal(this.state.data.fas_due.today.paid ,this.state.data.fas_due.today.tot):''}`,background:'#0072db'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                         </div>
                     </div>
                     
                     <div className='d-flex justify-content-between' >
                       <label style={{marginTop:'10px'}}>Today Due</label>
                       <div className='d-flex align-items-center'>
                         <div style={{color:'#4bb6e6'}}>
                             <p className='text-white biz_operation_font_size no-margin no-padding'><b className='biz_ops_count_fontsize'>{this.state.data.fas_due?this.state.data.fas_due.today.tot :''}</b></p>
                           </div>  
                         
                       </div>
                         
                     </div>
                     <div className="progress" style={{height:'7px'}}> 
                       <div className="progress-bar progress-bar-striped"  role="progressbar" style={{width:`100%`,background:'#0072db'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                         </div>
                     </div>
                   </>
                }
                 {this.state.yester_day &&
                      <>
                      <div className='d-flex justify-content-between'>
                          <label> Paid so for</label>
                          <div className='d-flex align-items-center'>
                            <div style={{color:'#4bb6e6'}}>
                                <p className='text-white biz_operation_font_size no-margin no-padding'><b className='biz_ops_count_fontsize'>{this.state.data.fas_due ?this.state.data.fas_due.yesterday.paid :''}</b></p>
                              </div>  
                              <div style={{color:'#4bb6e6'}}>
                              <p className='text-white no-margin biz_ops_green_text' style={{paddingLeft:'10px'}}><b>{this.state.data.fas_due?this.common_percentage_cal(this.state.data.fas_due.yesterday.paid ,this.state.data.fas_due.yesterday.tot):''}</b></p>
                              </div> 
                          </div>
                            
                        </div>
                        <div className="progress" style={{height:'7px'}}> 
                          <div className="progress-bar progress-bar-striped"  role="progressbar" style={{width:`${this.state.data.fas_due ?this.common_percentage_cal(this.state.data.fas_due.yesterday.paid ,this.state.data.fas_due.yesterday.tot):''}`,background:'#0072db'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                        
                        <div className='d-flex justify-content-between' >
                          <label style={{marginTop:'10px'}}>Today Due</label>
                          <div className='d-flex align-items-center'>
                            <div style={{color:'#4bb6e6'}}>
                                <p className='text-white biz_operation_font_size no-margin no-padding'><b className='biz_ops_count_fontsize'> {this.state.data.fas_due ?this.state.data.fas_due.yesterday.tot :''}</b></p>
                              </div>  
                            
                          </div>
                            
                        </div>
                        <div className="progress" style={{height:'7px'}}> 
                          <div className="progress-bar progress-bar-striped"  role="progressbar" style={{width:`100%`,background:'#0072db'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                      </>
                }
              </div>
              </div>
            </div>

            <div className='col-md-6 col-lg-2 h-100 col-sm-12 biz_operations_active_customers  biz_leads_pending' style={{padding:'10px 10px 1% 10px'}} >
           
              <div className={``}>
                <div className='d-flex align-items-center justify-content-center' style={{margin:'7px 0px 15px 0px'}}>
                  <p className='text-white business_operations_dates no-margin'> <b>Leads Pending</b></p>
                </div>
                {this.state.data.lead_pending? " " :
                   <div className='d-flex justify-content-center' > <button type="button" class="btn btn-outline-secondary btn-sm" style={{color:'white',borderRadius:'10px'}} onClick={()=>this.lazy_loading_handler("lead_pending")} > <b>Show</b></button> </div>
                }
                <div className={`${this.state.data.lead_pending ? " " : 'float_account_balance_bgblur'}`}>
                <div className='d-flex justify-content-between' style={{marginTop:'15px'}}>
                    <label> With RMs</label>
                    <div className='d-flex align-items-center'>
                      <div style={{color:'#4bb6e6'}}>
                          <p className='text-white biz_operation_font_size no-margin no-padding'><b className='biz_ops_count_fontsize'>{this.state.data.lead_pending ? this.state.data.lead_pending.w_rm :''}</b></p>
                        </div>  
                        {/* <div style={{color:'#4bb6e6'}}>
                        <p className='text-white no-margin biz_ops_green_text' style={{paddingLeft:'10px'}}><b>{this.state.data ?this.common_percentage_cal(this.state.data.lead_pending.w_rm ,this.state.data.lead_pending.total):''}</b></p>
                        </div>  */}
                    </div>
                      
                  </div>
                  <div className="progress" style={{height:'7px'}}> 
                    <div className="progress-bar progress-bar-striped"  role="progressbar" style={{width:`${this.state.data.lead_pending ?this.common_percentage_cal(this.state.data.lead_pending.w_rm ,this.state.data.lead_pending.total):''}`,background:'#b71589'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                      </div>
                  </div>
                  
                  <div className='d-flex justify-content-between' >
                    <label > With R&C</label>
                    <div className='d-flex align-items-center'>
                      <div style={{color:'#4bb6e6'}}>
                          <p className='text-white biz_operation_font_size no-margin no-padding'><b className='biz_ops_count_fontsize'>{this.state.data.lead_pending ? this.state.data.lead_pending.w_rc :''}</b></p>
                        </div>  
                      
                    </div>
                      
                  </div>
                  <div className="progress" style={{height:'7px'}}> 
                    <div className="progress-bar progress-bar-striped"  role="progressbar" style={{width:`${this.state.data.lead_pending ?this.common_percentage_cal(this.state.data.lead_pending.w_rc ,this.state.data.lead_pending.total):''}`,background:'#b71589'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                      </div>
                  </div>

                  <div className='d-flex justify-content-between' >
                    <label > Without FAs</label>
                    <div className='d-flex align-items-center'>
                      <div style={{color:'#4bb6e6'}}>
                          <p className='text-white biz_operation_font_size no-margin no-padding'><b className='biz_ops_count_fontsize'>{this.state.data.lead_pending ? this.state.data.lead_pending.wo_fa :''}</b></p>
                        </div>  
                      
                    </div>
                      
                  </div>
                  <div className="progress" style={{height:'7px'}}> 
                    <div className="progress-bar progress-bar-striped"  role="progressbar" style={{width:`${this.state.data.lead_pending ?this.common_percentage_cal(this.state.data.lead_pending.wo_fa ,this.state.data.lead_pending.total):''}`,background:'#b71589'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                      </div>
                  </div>
                  </div>
             </div>
            </div>

            <div className='col-md-12 col-lg-2  h-100 col-sm-12 biz_operations_active_customers  biz_ops_penalty' style={{padding:'10px 10px 2% 10px'}} >
          
              <div className={`h-100 p-2`}>
                <div className='d-flex align-items-center justify-content-center' style={{margin:'8px 0px 13px 0px'}}>
                  <p className='text-white business_operations_dates no-margin'> <b>Penalty</b> {this.state.data.penalty && <span className="chart_radio_font">(Last {this.state.dash_config.penalty_validity} days)</span>}</p>
                </div>
                {this.state.data.penalty? " " :
                   <div className='d-flex justify-content-center' > <button type="button" class="btn btn-outline-secondary btn-sm" style={{color:'white',borderRadius:'10px'}} onClick={()=>this.lazy_loading_handler("penalty")} > <b>Show</b></button> </div>
                }
                <div className={`${this.state.data.penalty ? " " : 'float_account_balance_bgblur'}`}>
                <div className='d-flex justify-content-between' style={{marginTop:'15px'}}>
                    <label>Imposed</label>
                    <div className='d-flex align-items-center'>
                      <div className='roundoffvalue round_offval_fontsize d-flex' style={{color:'#4bb6e6'}}>
                       <RoundOffSplitter  val={this.state.data.penalty ? this.state.data.penalty.imposed :''} unit={this.state.active_currency} align_center={true} position={"top"}/>  
                        
                        </div>  
                        
                    </div>
                      
                  </div>
                  <div className="progress" style={{height:'7px'}}> 
                    <div className="progress-bar progress-bar-striped"  role="progressbar" style={{width:`100%`,background:'#d7da47'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                      </div>
                  </div>
                  
                  <div className='d-flex justify-content-between' >
                    <label > Waived</label>
                    <div className='d-flex align-items-center'>
                      <div  className='roundoffvalue d-flex round_offval_fontsize' style={{color:'#4bb6e6'}}>
                       <RoundOffSplitter per_val={(this.state.data.penalty) ? ((this.state.data.penalty.waived/this.state.data.penalty.imposed)*100)?((Math.round(this.state.data.penalty.waived/this.state.data.penalty.imposed*100))+'%'):('0%') :" "}val={this.state.data.penalty ? this.state.data.penalty.waived :''} unit={this.state.active_currency}align_center={true} position={"top"}/>  
                       
                          {/* <p className='text-white biz_operation_font_size no-margin no-padding'><b>300</b></p> */}
                        </div>  
                      
                    </div>
                      
                  </div>
                  <div className="progress" style={{height:'7px'}}> 
                    <div className="progress-bar progress-bar-striped"  role="progressbar" style={{width:`${this.state.data.penalty ? this.common_percentage_cal(this.state.data.penalty.waived,this.state.data.penalty.imposed):''}`,background:'#d7da47'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                      </div>
                  </div>

                  <div className='d-flex justify-content-between' >
                    <label >Collected</label>
                    <div className='d-flex align-items-center'>
                      <div  className='roundoffvalue d-flex round_offval_fontsize'  style={{color:'#4bb6e6'}}>
                       <RoundOffSplitter per_val={(this.state.data.penalty) ? ((this.state.data.penalty.collected/this.state.data.penalty.imposed)*100)?((Math.round(this.state.data.penalty.collected/this.state.data.penalty.imposed*100))+'%'):('0%') :" "} val={this.state.data.penalty ? this.state.data.penalty.collected :''} unit={this.state.active_currency} align_center={true} position={"top"}/>  
                       
                          {/* <p className='text-white biz_operation_font_size no-margin no-padding'><b>300</b></p> */}
                        </div>  
                      
                    </div>
                      
                  </div>
                  <div className="progress" style={{height:'7px'}}> 
                    <div className="progress-bar progress-bar-striped"  role="progressbar" style={{width:`${this.state.data.Penalty ?this.common_percentage_cal(this.state.data.penalty.collected,this.state.data.penalty.imposed):''}`,background:'#d7da47'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                      </div>
                  </div>
                  </div>
                  </div>
            </div>
            </div>

            <div className='row  d-flex justify-content-between' style={{}}>
              <div className='col-md-4'>
             
              <div className={`h-100 biz_operations_active_customers biz_operation_fas_pending Agreement_Due biz_operration_padding `}>
               <div className='d-flex align-items-center justify-content-center'>
                <p className='text-white text-center no-margin no-padding biz_operation_font_size' style={{fontWeight:"bold" }}>Agreement Due</p>
                {this.state.data.get_aggr_due_count? " " :
                   <div className='ml-4' > <button type="button" class="btn btn-outline-secondary btn-sm" style={{color:'white',borderRadius:'10px'}} onClick={()=>this.lazy_loading_handler("get_aggr_due_count")} > <b>Show</b></button> </div>
                 }
              </div>
                <div className= {`row no-margin ${this.state.data.get_aggr_due_count ? " " : 'float_account_balance_bgblur'}`}>
                <div className='col-12  ' >
                  <div className=' no-margin d-flex justify-content-between align-items-center' style={{padding:'15px 0px'}}>
                  <div className='d-flex align-items-center text-white'>
                  <p className='text-white text-center no-margin' style={{paddingRight:'10px' ,fontWeight:"bold" }}>Expiring</p>
                  <RoundOffSplitter className="biz_operation_font_size" val={`Agreement expiring in ${this.state.data.get_aggr_due_count? this.state.data.get_aggr_due_count.exp_days:'13'}  days `} style={{color:'white'}} unit={""} align_center={true} position={"top"} notify={true} />
                  </div>
                  <p className='text-white no-margin biz_operation_font_size'><b className='biz_ops_count_fontsize'>{this.state.data.get_aggr_due_count? this.state.data.get_aggr_due_count.counts.expiring:''}</b></p>
                  </div>

                  <div className=' no-margin d-flex justify-content-between align-items-center' >
                  <div className='d-flex align-items-center text-white'>
                  <p className='text-white text-center no-margin' style={{paddingRight:'10px' ,fontWeight:"bold" }}>Expired</p>
                  </div>
                  <p className='text-white no-margin biz_operation_font_size'><b className='biz_ops_count_fontsize'>{this.state.data.get_aggr_due_count? this.state.data.get_aggr_due_count.counts.expired:''}</b></p>
                  </div>
                
               
                </div>
                {/* <div className='col-2 align-self-center' >
                <IoMdArrowDropright  color={ "white"} size={35}/>
                </div> */}
                </div>
              </div>
              </div>

              <div className='col-md-4'>
              
               
              <div className={`h-100 biz_operations_active_customers biz_operation_fas_pending Agreement_Due biz_operration_padding `}>
                <div className='d-flex justify-content-center'> 
                <p className='text-white text-center no-margin no-padding biz_operation_font_size' style={{fontWeight:"bold" }}>FA Upgrade Request</p>
                {this.state.data.get_fa_requst_count? " " :
                   <div className='ml-3' > <button type="button" class="btn btn-outline-secondary btn-sm" style={{color:'white',borderRadius:'10px'}} onClick={()=>this.lazy_loading_handler("get_fa_requst_count")} > <b>Show</b></button> </div>
                }
                </div>
                <div className= { `row no-margin ${this.state.data.get_fa_requst_count ? " " : 'float_account_balance_bgblur'} `}>
                <div className='col-12  mt-5' >
                  <div className='my-2 mx-0 d-flex justify-content-between align-items-center' style={{padding:'px 0px'}}>
                  <div className='d-flex align-items-center text-white'>
                  <p className='text-white text-center no-margin' style={{paddingRight:'10px' ,fontWeight:"bold" }}>Pending</p>
                  {/* <RoundOffSplitter className="biz_operation_font_size" val={"Agreement expiring in 14 days "} style={{color:'white'}} unit={""} align_center={true} position={"top"} notify={true} /> */}
                  </div>
                  <p className= {`text-white no-margin biz_operation_font_size `}><b className='biz_ops_count_fontsize'>{this.state.data.get_fa_requst_count? this.state.data.get_fa_requst_count:10}</b></p>
                  </div>

              
                </div>
                {/* <div className='col-2 align-self-center' >
                <IoMdArrowDropright  color={ "white"} size={35}/>
                </div> */}
                </div>
              </div>
              </div>

              <div className='col-md-4'>
            
                              
              <div className={`h-100 biz_operations_active_customers biz_operation_fas_pending Agreement_Due biz_operration_padding `}>
               <div className='d-flex justify-content-center align-items-center'>
                <p className='text-white text-center no-margin no-padding biz_operation_font_size' style={{fontWeight:"bold" }}>Complaints</p>
                {this.state.data.get_complaints_count ? 
                  <div className='ml-3 text-white' > (Last {this.state.dash_config.complaints_validity} days)</div>          
                 :
                   <div className='ml-3' > <button type="button" class="btn btn-outline-secondary btn-sm" style={{color:'white',borderRadius:'10px'}} onClick={()=>this.lazy_loading_handler("get_complaints_count")} > <b>Show</b></button> </div>
                }
                </div>
                <div className='row no-margin'>
                <div className='col-10 my-3 ' >
                  <div className=' no-margin d-flex justify-content-between align-items-center'>
                  <div className='d-flex align-items-center text-white' style={{padding:"15px 0px"}}>
                  <p className='text-white text-center no-margin' style={{paddingRight:'10px' ,fontWeight:"bold" }}>Need Resolution</p>
                  </div>
                  <p className= {`text-white no-margin biz_operation_font_size  ${this.state.data.get_complaints_count ? " " : 'float_account_balance_bgblur'} `}><b className='biz_operation_font_size' style={{fontSize:'22px'}}>{this.state.data.get_complaints_count? this.state.data.get_complaints_count.raised:0}</b></p>
                  </div>

                  <div className=' no-margin d-flex justify-content-between align-items-center' style={{paddingBottom:'10px'}}>
                  <div className='d-flex align-items-center text-white'>
                  <p className='text-white text-center no-margin' style={{paddingRight:'10px' ,fontWeight:"bold" }}>Total</p>
                  </div>
                  <p className= {`text-white no-margin biz_operation_font_size ${this.state.data.get_complaints_count ? " " : 'float_account_balance_bgblur'}`}><b className='biz_ops_count_fontsize'>{this.state.data.get_complaints_count? this.state.data.get_complaints_count.total:9}</b></p>
                  </div>
                
               
                </div>
                <div className='col-2 align-self-center' >
                <Link to={'/complaint_lists'}><IoMdArrowDropright  color={ "white"} size={35}/></Link>
                </div>
                </div>
              </div>
              </div>
            </div>

            <div className='business_operations_border biz_operation_fas_pending' style={{marginTop:'3%',position:'relative'}}>
            <div className='row'>
                <div className='d-flex align-items-center biz_operation_font_size ' style={{margin:'10px 0px 0px 20px'}}>
                     <ImMobile size={20} color={"white"} style={{marginTop:'-3px'}}/>
                     <h2 className='text-white no-margin' style={{padding:'3px 0px 10px 10px',fontSize:'18px' ,fontWeight:"bold" }} >Mobile Users</h2>
                     {this.state.mobile_users_view ? " " : 
                     <div className='ml-3 '> <button type="button" class="btn btn-outline-secondary btn-sm" style={{color:'white',borderRadius:'10px'}} onClick={()=>this.lazy_loading_handler("mobile_users")} > <b>Show</b></button> </div>
                    }
              </div>
            </div>
          
             <div className='business_operations_bottom_border' ></div>
             {}
             
             
              <div className={`d-flex justify-content-between align-items-center row biz_operration_padding mb-3 `}  >
              {this.state.mobile_users.map((items, idx) => {
                return (
                  <div className='col-md-2 ' id={idx} key={idx}>
                    <div className='biz_operration_padding business_operations_border biz_opr_fas_pending biz_operation_font_size' style={{ marginTop: '20px' }}>
                      {items.title === "Newly Onboarded" && (
                       <>
                       <div className='d-flex justify-content-between'>
                         <p className='text-white no-margin text-center' style={{fontWeight:"bold" }}>{items.title}</p>
                         {this.state.onbord === "false" ? (
                           <BsInfo size={25} color={"white"} onClick={() => this.onboarded_notification("true")} style={{transform: "translate(3px, -6px)"}} />
                         ) : (
                           <BsXOctagonFill size={15} color={"white"} onClick={() => this.onboarded_notification("false")} />
                         )}
                       </div>
                       {this.state.onbord === "false" ? (
                         <div className={`d-flex align-items-center justify-content-between ${this.state.mobile_users_view ? "" : 'float_account_balance_bgblur'}`} style={{ marginTop: '15px', padding: '-1px 10px' }}>
                           <div className='d-flex align-items-center'>
                             <p className='text-white no-margin biz_operation_font_size no-padding' style={{ fontSize: '18px' }}>
                               <b>{items.value}/</b>
                             </p>
                             <p className='text-white no-margin biz_operation_font_size ' style={{ fontSize: '18px', paddingLeft: '10px' }}>
                               {items.total}
                             </p>
                           </div>
                           <div className='biz_ops_mobile_installations' style={{ background: `${items.background}` }}>
                             <p className='text-white no-margin' style={{ fontSize: '18px' }}>{items.icon}</p>
                           </div>
                         </div>
                       ) : (
                         <div>
                           <h6 className='text-white' style={{fontSize:"5px",marginTop:"3px"}}>The counts of newly onboarded customers who installed the app during the onboarding process.</h6>
                           <div className='d-flex justify-content-between '>
                             <p className='text-white no-margin  ' style={{ fontSize: '11px',fontWeight:"bold" }}>Total Onboarded</p>
                             <p className='text-white no-margin  ' style={{ fontSize: '11px',fontWeight:"bold" }}>
                               {items.total}
                             </p>
                           </div>
                           <div className='d-flex justify-content-between '>
                             <p className='text-white no-margin ' style={{ fontSize: '11px',fontWeight:"bold"  }}>App installed</p>
                             <p className='text-white no-margin  ' style={{ fontSize: '11px',fontWeight:"bold"  }}>
                               {items.value}
                             </p>
                           </div>
                         </div>
                       )}
                     </>
                     
                      )}
                      {items.title !== "Newly Onboarded" && (
                        <>
                          <p className='text-white no-margin text-center ' style={{fontWeight:"bold" }}>{items.title}</p>
                          <div className={`d-flex align-items-center justify-content-between ${this.state.mobile_users_view ? "" : 'float_account_balance_bgblur'}`} style={{ marginTop: '15px', padding: '0px 10px' }}>
                            <div className='d-flex align-items-center'>
                              <p className='text-white no-margin biz_operation_font_size no-padding' style={{ fontSize: '20px' }}>
                                <b className='biz_ops_count_fontsize'>{items.value}</b>
                              </p>
                              <p className='text-white no-margin biz_ops_green_text' style={{ paddingLeft: '10px' }}>
                                <b>{items.total ? ((items.value / items.total) * 100).toFixed(0) + '%' : ''}</b>
                              </p>
                            </div>
                            <div className='biz_ops_mobile_installations' style={{ background: `${items.background}` }}>
                              <p className='text-white no-margin' style={{ fontSize: '20px' }}>{items.icon}</p>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}

               
             
            </div>
              
            </div>

            <div className='row'>
              <div className='col-md-12 col-lg-6'>
                <div  className='business_operations_border biz_operation_fas_pending' style={{marginTop:'3%'}}>
                  <div className='row mx-0'>
                    <div className='d-flex align-items-center biz_operation_font_size ' style={{margin:'10px 0px 0px 20px'}}>
                        <BiRefresh size={25} color={"white"} style={{marginTop:'-3px'}}/>
                        <h2 className='text-white no-margin' style={{padding:'3px 0px 10px 10px',fontSize:'18px' ,fontWeight:"bold" }} >Repayment</h2>
                    
                  </div>
                  <div className='business_operations_bottom_border' ></div>
                  
                  </div>
                  <div className='business_operations_bottom_border' ></div>
                  {this.state.repayment_view ? "" :
                   <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary " style={{color:'white'}} onClick={()=>this.lazy_loading_handler('repayment_metrics')} > <b>Show</b></button> </div>
                  }
                 <Tabs
                  defaultActiveKey={this.state.active}
                  id="justify-tab-example"
                  className={`mb-3 ${this.state.repayment_view ? "" : 'float_account_balance_bgblur'}`}
                  justify style={{marginTop:'7px'}}
                  onSelect={(e)=>this.tabCheck(e)} 
                >
                  <Tab eventKey="Today" title={ this.state.active =='Today' ? <button type="button" class="btn btn-info repayment_tittle_button" style={{padding:'7px 42px',background:'#4291da'}}> <b> Today </b></button> : <p className='text-white no-margin'>Today</p>} style={{fontSize:'10px'}}  >

                  {this.state.repayment_loader ? 
                    <div class="d-flex justify-content-center" style={{padding:'65px 20px'}}>
                      <div class="spinner-border  text-light" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    :

                    <div className={` d-flex justify-content-around align-items-center ${this.state.repayment_view ? " " : 'float_account_balance_bgblur'}`}>
                     <div className='biz_operration_padding business_operations_border biz_opr_fas_pending biz_operation_font_size' style={{padding:'20px',margin:'26px 0px 39px' ,fontWeight:"bold"  }}>
                        <p className='text-white no-margin'>Ontime Metrics</p>
                    
                        <p className=' no-margin text-center fas_pending_icon_color' style={{padding:'6px 0px'}}> <b>{this.state.repayment_view ? this.illustrate_percentage_cal(this.state.repayment_view.ontime.ontime ,this.state.repayment_view.ontime.total) : "N/A"}  </b></p>
                        <p className='text-white no-margin text-center biz_ops_green_text'> <b>{this.state.repayment_view ? this.common_percentage_cal(this.state.repayment_view.ontime.ontime ,this.state.repayment_view.ontime.total):'N/A'}</b></p>
                     </div>

                     <div className='biz_operration_padding business_operations_border biz_opr_fas_pending biz_operation_font_size' style={{margin:'26px 0px 39px' ,fontWeight:"bold" }}>
                        <p className='text-white text-center no-margin'>Overdue Metrics</p>
                        <p className="text-white no-margin text-center" style={{fontSize:'13px'}}>(Due a day before)</p>
                       
                        <p className=' no-margin text-center fas_pending_icon_color' style={{padding:'6px 0px'}}> <b>{this.state.repayment_view ? this.illustrate_percentage_cal(this.state.repayment_view.overdue.paid ,this.state.repayment_view.overdue.total) : "N/A"} </b></p>
                        <p className='text-white no-margin text-center biz_ops_green_text'> <b>{this.state.repayment_view ?this.common_percentage_cal(this.state.repayment_view.overdue.paid ,this.state.repayment_view.overdue.total):'N/A'}</b></p>
                     </div>
                    </div>

                    }


                  </Tab>
                  <Tab eventKey="Yesterday"  title={ this.state.active =='Yesterday' ? <button type="button" class="btn btn-info  no-margin repayment_tittle_button" style={{padding:'8px 30px',background:'#4291da'}}> <b> Yesterday </b></button> : <p className='text-white no-margin'>Yesterday</p>} >
                    {this.state.repayment_loader ? 
                  <div class="d-flex justify-content-center" style={{padding:'65px 20px'}}>
                      <div class="spinner-border  text-light" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    :
                  <div className={` d-flex justify-content-around align-items-center ${this.state.repayment_view ? " " : 'float_account_balance_bgblur'}`}>
                     <div className='biz_operration_padding business_operations_border biz_opr_fas_pending biz_operation_font_size' style={{padding:'20px',margin:'26px 0px 39px' ,fontWeight:"bold" }}>
                        <p className='text-white no-margin'>Ontime Metrics</p>
                    
                        <p className=' no-margin text-center fas_pending_icon_color' style={{padding:'6px 0px'}}> <b   className='biz_ops_count_fontsize'>{this.state.repayment_view ? this.illustrate_percentage_cal(this.state.repayment_view.ontime.ontime ,this.state.repayment_view.ontime.total) : "N/A"}  </b></p>
                        <p className='text-white no-margin text-center biz_ops_green_text'> <b>{this.state.repayment_view ? this.common_percentage_cal(this.state.repayment_view.ontime.ontime ,this.state.repayment_view.ontime.total):'N/A'}</b></p>
                     </div>

                     <div className='biz_operration_padding business_operations_border biz_opr_fas_pending biz_operation_font_size' style={{margin:'26px 0px 39px' ,fontWeight:"bold" }}>
                        <p className='text-white text-center no-margin'>Overdue Metrics</p>
                        <p className="text-white no-margin text-center" style={{fontSize:'13px'}}>(Due a day before)</p>
                       
                        <p className=' no-margin text-center fas_pending_icon_color' style={{padding:'6px 0px'}}> <b  className='biz_ops_count_fontsize'>{this.state.repayment_view ? this.illustrate_percentage_cal(this.state.repayment_view.overdue.paid ,this.state.repayment_view.overdue.total) : "N/A"} </b></p>
                        <p className='text-white no-margin text-center biz_ops_green_text'> <b>{this.state.repayment_view ?this.common_percentage_cal(this.state.repayment_view.overdue.paid ,this.state.repayment_view.overdue.total):'N/A'}</b></p>
                     </div>
                    </div>
                     }
                  </Tab>
                  <Tab eventKey="Day_b_f_y_day" title={ this.state.active =='Day_b_f_y_day' ? <button type="button" class="btn btn-info no-margin repayment_tittle_button" style={{padding:'7px 19px',background:'#4291da'}}> <b> Day b/f y/day </b></button> : <p className='text-white no-margin'> Day b/f y/day</p>}>
                  {this.state.repayment_loader ?  <div class="d-flex justify-content-center" style={{padding:'65px 20px'}}>
                      <div class="spinner-border  text-light" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div> :
                  <div className={` d-flex justify-content-around align-items-center ${this.state.repayment_view ? " " : 'float_account_balance_bgblur'}`}>
                     <div className='biz_operration_padding business_operations_border biz_opr_fas_pending biz_operation_font_size' style={{padding:'20px',margin:'26px 0px 39px' ,fontWeight:"bold" }}>
                        <p className='text-white no-margin'>Ontime Metrics</p>
                    
                        <p className=' no-margin text-center fas_pending_icon_color' style={{padding:'6px 0px'}}> <b  className='biz_ops_count_fontsize'>{this.state.repayment_view ? this.illustrate_percentage_cal(this.state.repayment_view.ontime.ontime ,this.state.repayment_view.ontime.total) : "N/A"}  </b></p>
                        <p className='text-white no-margin text-center biz_ops_green_text'> <b>{this.state.repayment_view ? this.common_percentage_cal(this.state.repayment_view.ontime.ontime ,this.state.repayment_view.ontime.total):'N/A'}</b></p>
                     </div>

                     <div className='biz_operration_padding business_operations_border biz_opr_fas_pending biz_operation_font_size' style={{margin:'26px 0px 39px' ,fontWeight:"bold" }}>
                        <p className='text-white text-center no-margin'>Overdue Metrics</p>
                        <p className="text-white no-margin text-center" style={{fontSize:'13px'}}>(Due a day before)</p>
                       
                        <p className=' no-margin text-center fas_pending_icon_color' style={{padding:'6px 0px'}}> <b  className='biz_ops_count_fontsize'>{this.state.repayment_view ? this.illustrate_percentage_cal(this.state.repayment_view.overdue.paid ,this.state.repayment_view.overdue.total) : "N/A"} </b></p>
                        <p className='text-white no-margin text-center biz_ops_green_text'> <b>{this.state.repayment_view ?this.common_percentage_cal(this.state.repayment_view.overdue.paid ,this.state.repayment_view.overdue.total):'N/A'}</b></p>
                     </div>
                    </div>
                  }
                  </Tab>
                  <Tab eventKey="Last_5_days" title={ this.state.active =='Last_5_days' ? <button type="button" class="btn-info no-margin repayment_tittle_button" style={{padding:'6px 13px',background:'#4291da'}}> <div className='d-flex align-items-center'>
                    <p className='no-margin' style={{paddingRight:'5px'}}>Last</p>
                  <div >
                  <select name="cars" id="days" onChange={(event)=>this.last_fivedays_reapaymentHandler(event.target.value)}>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="30">30</option>
                  </select>

                </div>
                <p className='no-margin' style={{paddingLeft:'5px'}}>Days</p>
                  </div> </button> :<p className='text-white no-margin'>Last 5 days </p>} >
                  {this.state.repayment_loader ?  <div class="d-flex justify-content-center" style={{padding:'65px 20px'}}>
                      <div class="spinner-border  text-light" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div> :
                  <div className={` d-flex justify-content-around align-items-center ${this.state.repayment_view ? " " : 'float_account_balance_bgblur'}`}>
                     <div className='biz_operration_padding business_operations_border biz_opr_fas_pending biz_operation_font_size' style={{padding:'20px',margin:'26px 0px 39px' ,fontWeight:"bold" }}>
                        <p className='text-white no-margin'>Ontime Metrics</p>
                    
                        <p className=' no-margin text-center fas_pending_icon_color' style={{padding:'6px 0px'}}> <b  className='biz_ops_count_fontsize'>{this.state.repayment_view ? this.illustrate_percentage_cal(this.state.repayment_view.ontime.ontime ,this.state.repayment_view.ontime.total) : "N/A"}  </b></p>
                        <p className='text-white no-margin text-center biz_ops_green_text'> <b>{this.state.repayment_view ? this.common_percentage_cal(this.state.repayment_view.ontime.ontime ,this.state.repayment_view.ontime.total):'N/A'}</b></p>
                     </div>

                     <div className='biz_operration_padding business_operations_border biz_opr_fas_pending biz_operation_font_size' style={{margin:'26px 0px 39px' ,fontWeight:"bold" }}>
                        <p className='text-white text-center no-margin'>Overdue Metrics</p>
                        <p className="text-white no-margin text-center" style={{fontSize:'13px'}}>(Due a day before)</p>
                       
                        <p className=' no-margin text-center fas_pending_icon_color' style={{padding:'6px 0px'}}> <b  className='biz_ops_count_fontsize'>{this.state.repayment_view ? this.illustrate_percentage_cal(this.state.repayment_view.overdue.paid ,this.state.repayment_view.overdue.total) : "N/A"} </b></p>
                        <p className='text-white no-margin text-center biz_ops_green_text'> <b>{this.state.repayment_view ?this.common_percentage_cal(this.state.repayment_view.overdue.paid ,this.state.repayment_view.overdue.total):'N/A'}</b></p>
                     </div>
                    </div>
                    }
                  </Tab>
                </Tabs>
                </div>
             
              </div>

              <div className='col-md-12 col-lg-6'>
                    
              <div className={` business_operations_border biz_operation_fas_pending `} style={{ position: 'relative', minHeight: '287px' }}>
    <div className=''>
        <div className='d-flex align-items-center biz_operation_font_size ' style={{ margin: '10px 0px 0px 20px' }}>
            <BiDollarCircle size={25} color={"white"} style={{ marginTop: '-3px' }} />
            <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', fontSize: '18px', fontWeight: "bold" }}>Float Account Balance</h2>
        </div>
        <div className='business_operations_bottom_border'></div>
        {this.state.float_account_balance ? "" :
            <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary" style={{ color: 'white' }} onClick={() => this.lazy_loading_handler('account_balance')} > <b>Show</b></button> </div>
        }
        <div className='' style={{ 'marginTop': '2%', overflowY: 'auto', maxHeight: '200px' }}>
            <table className={`table table-bordered ${this.state.float_account_balance ? "" : 'float_account_balance_bgblur'}`} style={{ marginBottom: '25px' }}>
                <thead>
                    <tr>
                        <th className='text-white' scope="col"> <b> Network Provider</b></th>
                        <th className='text-white' scope="col"><b>Balance</b></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.float_account_balance ?
                        <tr style={{ padding: '5px 0px 10px 5px' }}>
                            {this.state.active_acc_prvdr ?
                                <td className='d-flex align-items-center flex-wrap'>
                                    {this.state.active_acc_prvdr &&
                                        this.state.active_acc_prvdr.map((each, idx) =>
                                            <div className={`d-flex align-items-center justify-content-center  ${this.state.current_active_acc_prvdr === this.state.current_active_acc_prvdr[0] || this.state.current_active_acc_prvdr === each ? "" : "opac_50"}`} style={{ height: 45, width: 75, marginRight: 15, cursor: 'pointer', border: " 2px solid #424141", padding: "6px" }} key={idx} >
                                                <img className={`img-fluid `} src={`/img/${each}_logo_color.png`} alt={each} onClick={() => this.network_prvdr_handler(each)} />
                                                {this.state.current_active_acc_prvdr === each && (
                                                    this.state.lowestBalanceByProvider[this.state.current_active_acc_prvdr] < 10000000 ? (
                                                        <img className={`img-fluid `} src={`/img/down_Arrow_with_bg.png`} alt={""} style={{ width: "32%", marginLeft: "-16px", transform: "translate(14px, -22px)" }} />
                                                    ) : (
                                                        ""
                                                    )
                                                )}
                                            </div>
                                        )
                                    }
                                </td>
                                : <td>no-data</td>}
                            <td></td>
                        </tr>
                        : ''}
                    {this.state.float_account_balance ?
                        <>
                            {this.state.float_account_balance && this.state.float_account_balance[this.state.current_active_acc_prvdr].map((datas) =>
                                <tr style={{ height: '30px' }}>
                                    <td><p className='text-white no-margin' style={{ margin: '30px 0px' }}><img className={`img-fluid `} src={`/img/${datas.acc_prvdr_code}_logo_color.png`} alt={datas.acc_prvdr_code} width={50} height={12} />	 {datas.acc_number} ({datas.acc_prvdr_code})</p></td>
                                    <td className='round_offval_fontsize'>
                                        {datas.balance > 0 ? (
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <RoundOffSplitter val={datas.balance} unit={this.state.active_currency} align_center={true} position={"top"} />
                                                {datas.balance < 10000000 ?
                                                    (
                                                        <img className={`img-fluid `} src={`/img/down_arrow.png`} alt={""} style={{ "marginLeft": "9px" }} />
                                                    ) : (
                                                        <img className={`img-fluid `} src={`/img/up_arrow.png`} alt={""} style={{ "marginLeft": "9px" }} />
                                                    )
                                                }
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <p className='text-white no-margin text-center' style={{ margin: '5px 0px', fontSize: '10px' }}>(
                                            {datas.time &&
                                                <>
                                                    {(datas.time > 60) ?
                                                        (Math.floor((moment.duration(datas.time, 'minutes')).asHours())) + `${((Math.floor((moment.duration(datas.time, 'minutes')).asHours())) > 0) ? " hours" : " hour"} ` + ((moment.duration(datas.time, 'minutes')).minutes()) + ` ${(((moment.duration(datas.time, 'minutes')).minutes()) > 1) ? "mins ago" : "minute"}`
                                                        :
                                                        datas.time + " mins ago"}
                                                </>
                                            }
                                        )</p>
                                    </td>
                                </tr>
                            )
                            }
                        </>
                        :
                        <>
                            <tr style={{ height: '90px' }}>
                                <td><p className='text-white no-margin' style={{ margin: '30px 0px' }}><img className={`img-fluid `} src={`/img/"UZEM"}_logo_color.png`} alt={"UZEM"} width={50} height={12} />	 3687687568 </p></td>
                                <td><p className='text-white no-margin' style={{ margin: '30px 0px' }}>R837568356</p></td>
                                <td> <p className='text-white no-margin' style={{ margin: '30px 0px' }}>
                                    743 mins ago
                                </p></td>
                            </tr>
                            <tr style={{ height: '50px' }}>
                                <td><p className='text-white no-margin' style={{ margin: '30px 0px' }}><img className={`img-fluid `} src={`/img/"UZEM"}_logo_color.png`} alt={"UZEM"} width={50} height={12} />	 3687687568 </p></td>
                                <td><p className='text-white no-margin' style={{ margin: '30px 0px' }}>R837568356</p></td>
                                <td> <p className='text-white no-margin' style={{ margin: '30px 0px' }}>
                                    743 mins ago
                                </p></td>
                            </tr>
                        </>
                    }
                </tbody>
            </table>
        </div>
    </div>
</div>
              </div>
            </div>
            {/* call chart */}


            {/* <div className='row'>
              
              <div className='col-md-12 col-lg-6'>
                <div className='business_operations_border biz_operation_fas_pending' style={{height:'400px'}}>
                        <div className='row'>
                            <div className='d-flex align-items-center biz_operation_font_size ' style={{margin:'10px 0px 0px 10px'}}>
                                <AiOutlineBarChart size={25} color={"white"} style={{marginTop:'-4px'}}/>
                                <h2 className='text-white no-margin' style={{padding:'3px 0px 10px 10px',fontSize:'18px'}}>SIP Down Time chart</h2>
                            </div>
                        </div>
                    <div className='business_operations_bottom_border' ></div> 
                    
                    
                    {this.state.inbound_voice_chart_data.length>0 ? 
                    <>
                     {this.state.x_axis_inbound_voice_data && this.state.x_axis_inbound_voice_data.length>0 ?
                    <div className={`row d-flex justify-content-between align-items-center`}>
                        <div className='col-12'>
                        <ReportChart data={this.state.inbound_voice_chart_data ? this.state.inbound_voice_chart_data :[]} type="bar"  show_toolbar={false} axisBorder={true} 
                      legend={true} y_axis_tittle={"Time"}  x_axis_format={false} grid={false}  biz_ops_chart_height={this.state.inbound_voice_chart_data ? 450:300} xaxistype='category' categories={this.state.x_axis_inbound_voice_data} biz_ops_colunm_width={'30%'}/>
                    </div>
                    </div>
                    :
                    <p className='text-white text-center' style={{marginTop:'21%',fontSize:'15px'}}>no data</p>
                  }
                    </>
                    :
                    <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary " style={{color:'white'}} onClick={()=>this.get_inbound_voice_call_data()} > <b>Show</b></button> </div>
                     }
                  
                    
                    </div>
                   </div>
    
             
              
            </div> */}
            </>
             }
            </>
             
        </div>
    );
};
}
export default Biz_operation_live_results;