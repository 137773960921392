import React from "react";
import moment from "moment";
import {
  get_fresh_chat_channels,
  get_fresh_chat_reports,
} from "../../../actions/fresh_chat_action";
import FlowComponent from "../../common/component/core/FlowComponent";
import ReportChart from "../component/ReportChart";
import FreshChatChartHeader from "../component/fresh_chat_chart_header";
import { RiBarChartFill } from "react-icons/ri";
import { AiOutlineLineChart } from "react-icons/ai";
import { data } from "jquery";
import { BasicReactTable } from "../../../helpers/react_table";
import { AiOutlineClockCircle } from "react-icons/ai";
import { title_case } from "../../../helpers/common_helper";
import { RiBarChartBoxFill, RiCalendarTodoFill, RiDashboardFill } from "react-icons/ri";




class FreshChatChart extends FlowComponent {
  state = {
    chart_type: [],
    country: { country_code: "global" },
    addl_filtration: {},
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    selected_support_group: null,
    selected_issue_channels: [],
    selected_agents: [],
    active_tab: "issue_type",

    chart_data: [],
    table_data: [],
    chart_loader:false
  };

  request_parameter = () => {
    return {
      filter: {
        chart_type: [this.state.active_tab],
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        country_code: this.state.country.country_code,
        support_group: this.state.selected_support_group ? this.state.selected_support_group.value :'global',
        addl_filtration: {
          agent_ids: this.state.selected_agents.map((val) => val.id),
          issue_channels: this.state.selected_issue_channels.map(
            (val) => val.id
          ),
        },
      },
    };
  };

  componentWillMount() {
   this.get_fresh_chat_dd()
    this.get_fresh_chat_report();
  }

  get_fresh_chat_dd = ()=>{
    let request_parameter = {filter:{
      country_code:this.state.country.country_code,
      support_group:this.state.selected_support_group && this.state.selected_support_group.value ? this.state.selected_support_group.value : null
    }}
    get_fresh_chat_channels(this.req(request_parameter)).then((response) => {
      if (!response) return;
      if (response.data) {
        this.resp(response);
        this.setState({
          channels: response.data.channels ? response.data.channels : [],
          agents: response.data.agents ? response.data.agents : [],
          support_groups: response.data.support_groups
            ? [{data_code:'All',data_value:'global'},...response.data.support_groups]
            : [],
          issue_channels: response.data.issue_channels
            ? response.data.issue_channels
            : [],
          support_members: response.data.support_members
            ? response.data.support_members
            : [],
        });
      }
     
      return;
    });
  }

  get_fresh_chat_report = () => {
    this.setState({chart_loader:true})
    var request = this.request_parameter();
    // this.setState({ loader: true });
    get_fresh_chat_reports(this.req(request))
      .then((response) => {
        if (!response) return;
        if (response) {
          this.resp(response);
          this.setState({ loader: false });
          if (
            response.data &&
            response.data[this.state.active_tab] &&
            response.data[this.state.active_tab].data.length > 0
          ) {
            this.setState({
              chart_data: response.data[this.state.active_tab],
              table_data: response.data.table_data,
              response_time_data: response.data.avg_first_response_time,
              resolution_time_data: response.data.avg_resolution_time,
            });
          }
         
        }
        this.setState({chart_loader:false})
      })
      .catch((error) => {
        alert(error);
        return;
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.start_date !== this.state.start_date ||
      prevState.end_date !== this.state.end_date ||
      prevState.country !== this.state.country ||
      prevState.active_tab !== this.state.active_tab ||
      prevState.selected_support_group !==
        this.state.selected_support_group ||

      prevState.selected_issue_channels !=  this.state.selected_issue_channels  ||
      prevState.selected_agents != this.state.selected_agents
    ) {
      this.get_fresh_chat_report();
    }
    if(prevState.country !== this.state.country || prevState.selected_support_group !== this.state.selected_support_group){
      this.get_fresh_chat_dd()
    }
  }

 

 

  handleTabChange = (tab) => {
    this.setState({ active_tab: tab });
  };

  handleChange = (key, value, event = null, from_group_select = false) => {
    if (key == "selected_agents") {
      if(from_group_select){
        if (event.target.checked) {
          this.setState({ group_checked: true });
          this.setState({ [key]: value });
          return;
        } else if (!event.target.checked) {
          this.setState({ [key]: [], group_checked: false });
          return;
        }

      }
      
    }
     if (key == "start_date" || key == "end_date") {
      value = moment(value).format("YYYY-MM-DD");
    }



    this.setState({ [key]: value });
  };

  chart_tabs = (charts = [], chart_values = [], icons = []) => {
    return (
      charts &&
      charts.map((value, index) => (
        <div
          className="col-md-auto d-flex fresh_chat_chart_tabs justify-content-between"
          style={{
            border:
              this.state.active_tab == chart_values[index]
                ? "1px solid white"
                : "",
            borderRadius:
              this.state.active_tab == chart_values[index] ? "10px" : "",
            backgroundColor:
              this.state.active_tab == chart_values[index] ? "#1955A5" : "",
          }}
          key={index}
          onClick={(e) => this.handleTabChange(chart_values[index])}
        >
          <div className="row d-flex justify-content-between ">
            <div className="col-md-auto d-flex align-items-center">
              <label className="mr-3">{icons[index]}</label>
              {value}
            </div>
          </div>
        </div>
      ))
    );
  };


  issue_colors = {
    'L1':'#10B277',
    'L2 - Error':'#B60101',
    'L2 - Others':'#017EFA'
  }

  get_table_issue_type = (obj) => {
    return (
      <>
        {Object.keys(obj).map((key) => (
          <div className="col-md-auto d-flex justify-content-start align-items-center mb-2 mr-2" key={key} style={{ borderRadius:'7px', backgroundColor:this.issue_colors[key] ? this.issue_colors[key]: '#10B277', color:'white', fontWeight:400, padding: '2px 5px'}}>
            <p className="m-0 p-0 mr-3" style={{fontFamily:'Montserrat', color:"white", fontSize:'11px',fontWeight:400}}>{key}</p>
            <p style={{ borderRadius:'5px',backgroundColor:'rgba(255, 255, 255, 0.43)', fontFamily:'Montserrat',color:'white', fontSize:'10px', width:'17px', textAlign:'center', color:'white', height:'16px'}} className="m-0">{obj[key]}</p>
             
        </div>
        ))}
      </>
    );
  };

  column_data = () => {
    let column;
    column = [
      {
        Header: (
          <p
            className=" m-0 d-flex align-items-center pl-1"
            style={{ height: "40px" }}
          >
            Issue Type
          </p>
        ),
        id: "issue_type",
        maxWidth: 250,
        minWidth: 200,
        accessor: (row) => row.issue_type,
        Cell: (cell) => {
          return <div className="">{cell.value}</div>;
        },
        Footer: (
          <p
            className="text-center pl-3 d-flex align-items-center"
            style={{ height: "40px" }}
          >
            Total
          </p>
        ),
      },
      {
        Header: (
          <p
            className="m-0 d-flex align-items-center "
            style={{ height: "40px" }}
          >
            Issue Level
          </p>
        ),
        id: "issue_level_count",
        maxWidth: 300,
        minWidth: 200,
        accessor: (row) => row.issue_level_count,
        Cell: (cell) => {
          return (
            <div className="row d-flex justify-content-start ml-1">{this.get_table_issue_type(cell.value)}</div>
          );
        },
      },
      {
        Header: (
          <p
            className="text-center m-0 d-flex align-items-center "
            style={{ height: "40px" }}
          >
            Count
          </p>
        ),
        id: "count_percentage",
        accessor: (row) => row.total_issue_level_count,
        Cell: (cell) => {
          return <div className="justify-content-center">{cell.value}</div>;
        },
        Footer: (
          <p
            className="text-center m-0 d-flex align-items-center"
            style={{ height: "40px" }}
          >
            {this.state.table_data.total_count
              ? this.state.table_data.resolved
              : 0}
          </p>
        ),
      },
      {
        Header: (
          <p
            className="text-center m-0 d-flex align-items-center "
            style={{ height: "40px" }}
          >
            Count(%)
          </p>
        ),
        id: "total_issue_level_count",
        accessor: (row) => row.total_issue_level_count,
        Cell: (cell) => {
          return (
            <>{`${Math.round(
              (cell.value / this.state.table_data.total_count) * 100
            )} %`}</>
          );
        },
      },
      {
        Header: (
          <p
            className="text-center m-0 d-flex align-items-center "
            style={{ height: "40px" }}
          >
            Resolution Time
          </p>
        ),
        id: "avg_resolution_time",
        accessor: (row) => row.avg_resolution_time,
        Cell: (cell) => {
          return <>{`${cell.value} Min`}</>;
        },
      },
    ];

    return column;
  };

  resolution_data = (data)=>{
    if(!data || typeof data !== 'object') return
   
      return Object.keys(data).map(
        (main_key) => (
          <div
            key={main_key}
            className={`${main_key == 'tech_support'? 'col-md-5' :'col-md-6'} justify-content-center main_key_container p-3`}
            style={{ textAlign: "center" }}
          >
            <div className="p-2" style={{paddingLeft:'1%', fontWeight:'400', fontSize:'15px'}}>{title_case(main_key)}</div>
            <div className="row d-flex justify-content-center">
              {Object.keys(
                data[main_key]
              ).map((sub_key) => (
                <div key={sub_key} className="col-md-auto" style={{ borderRight: main_key == 'customer_success' && sub_key =='UGA' ? '5px solid #10B277':''}}>
                  <p style={{fontWeight:'700', fontSize:'15px'}}>{`${data[main_key][sub_key]} mins`}</p>
                  <p style={{margin:0}}>{sub_key}</p>
                </div>
              ))}
            </div>
          </div>
        )
      )

  }

  render() {
    let y_axis_tittles = {
      issue_type: "Count Of Issue Type",
      issue_level: "Issue Level",
      conversation_volume_chart: "Count Of Conversation",
    };
    let x_axis_tittles = {
      issue_type: "Issue Type",
      issue_level: "Count Of Issue Level",
      conversation_volume_chart: "Date Based Conversation",
    };
    return (
      <div style={{ padding: "2%"}}>
        <div className="row d-flex align-items-center mb-4">
          <span style={{padding:'1%'}}><RiDashboardFill color="white"  size={25}/></span>
          <span style={{fontWeight:400, fontSize:'24px', color:'white'}}>Support Performance Dashboard</span>

        </div>
        <FreshChatChartHeader
          data={this.state}
          handleChange={this.handleChange}
          get_fresh_chat_report = {this.get_fresh_chat_report}
        />

        <div className="fresh_chat_chart_head">
         <div>
         <div
            className="row d-flex p-4 mb-4"
            style={{ background: "#303E52", borderRadius: "10px" }}
          >
            {this.chart_tabs(
              ["By Issue Type", "By Issue Level", "Conversation Trend"],
              ["issue_type", "issue_level", "conversation_volume_chart"],
              [
                <RiBarChartFill size={20} color="white" />,
                <RiBarChartFill size={20} color="white" />,
                <AiOutlineLineChart size={20} color="white" />,
              ]
            )}
          </div>
          {!this.state.chart_loader ? (
            <div className="">
              <ReportChart
                data={
                  this.state.active_tab == "issue_level" &&
                  this.state.chart_data[this.state.active_tab]
                    ? [
                        {
                          data: this.state.chart_data.data.map((val) => val.y),
                          name: "Issue Level",
                        },
                      ]
                    : [this.state.chart_data]
                }
                type={
                  this.state.active_tab == "conversation_volume_chart"
                    ? "line"
                    : "bar"
                }
                y_axis_tittle={
                  this.state.active_tab && y_axis_tittles[this.state.active_tab]
                }
                xaxistype="category"
                x_axis_format={false}
                show_toolbar={false}
                grid={false}
                color={["#00e396"]}
                biz_ops_chart_height={650}
                x_axis_tittle={x_axis_tittles[this.state.active_tab]}
                categories={
                  this.state.active_tab == "issue_level" &&
                  this.state.chart_data[this.state.active_tab]
                    ? this.state.chart_data.data.map((val) => val.x)
                    : undefined
                }
                horizontal={
                  this.state.active_tab == "issue_level" ? true : undefined
                }
                dataLabel={true}
                
              />
              </div>
            ) : <div style={{height:'650px'}} className="d-flex align-items-center">
              <div className="product_takeup_loader" ></div>
              </div>
          }
         </div>

          {!this.state.loader ? <div className="row d-flex justify-content-between">
                <div className="col-8 fresh_chat_border" style={{padding:'1%', borderRadius:'10px', backgroundColor:'#666A7766'}}>
                  <div className="d-flex align-items-center p-3 mb-4">
                  <span><RiCalendarTodoFill size={20} color="white"/></span><span style={{color:'white', fontSize:'15px', fontWeight:400, marginLeft:'3px'}}>Issue Count</span>
                  </div>
                  <BasicReactTable
                    row_data={
                      this.state.table_data.row_data
                        ? this.state.table_data.row_data
                        : []
                    }
                    column_data={this.column_data()}
                    pagination={ this.state.table_data && this.state.table_data.row_data && this.state.table_data.row_data.length>5?true:false}
                    default_page_size={5}
                    footer={this.state.table_data.row_data?true:false}
                    
                  />
                  {!this.state.table_data.row_data&& 
              !this.state.loader&&
                <div className="mt-5">
                  <p className="text-center text-white mt-5">No Data</p>
                </div>
  }

                </div>
                
            <div className="col-4 response_time">
              <div className="mb-2" style={{ padding: "5%", backgroundColor:'rgba(102, 106, 119, 0.40)', borderRadius:'10px'}}>
                <div className="d-flex ">
                  <AiOutlineClockCircle size={20} color="white" />
                  <p style={{paddingLeft:'1%', fontWeight:'400', fontSize:'15px'}}>Average First Response Time</p>
                </div>
                <div className="row d-flex justify-content-between " style={{padding:'2%'}}>
                    {this.resolution_data(this.state.response_time_data)}
                </div>
              </div>

              {/* resolution time */}

              <div className="mb-2" style={{ padding: "5%", backgroundColor:'#666A7766', borderRadius:'10px'}}>
                <div className="d-flex">
                  <AiOutlineClockCircle size={20} color="white" />
                  <p style={{paddingLeft:'1%', fontWeight:'400', fontSize:'15px'}}>Average Resolution Time</p>
                </div>
                <div className="row d-flex justify-content-between" style={{padding:'2%'}}>
                    {this.resolution_data(this.state.resolution_time_data)}
                </div>
              </div>
              <div className="" style={{ padding: "5%", backgroundColor:'#666A7766', borderRadius:'10px'}}>
                <div className="d-flex">
                  <RiBarChartBoxFill size={20} color="white" />
                  <p style={{paddingLeft:'1%', fontWeight:'400', fontSize:'15px'}} className="m-0">Issue Status</p>
                </div>
                <div className="row justify-content-between d-flex">
                    <div className="row justify-content-between d-flex " style={{padding:'1% 10%', fontSize:'16px', fontWeight:'500'}}>
                    <div className="col-md-6 mb-2">
                      Total Issues
                      </div>
                    <div className="col-md-auto  ml-auto mb-2">
                      {this.state.table_data ? this.state.table_data.total_count: 0}

                    </div>
                    <div className="col-md-6 mb-1">
                      Resolved Issues

                    </div>
                    <div className="col-md-auto  ml-auto mb-2">
                      {this.state.table_data ? this.state.table_data.resolved: 0}

                    </div>
                    <div className="col-md-6 mb-2">
                      Un Resolved Issues
                    </div>
                    <div className="col-md-auto  ml-auto mb-2">
                      {this.state.table_data ? this.state.table_data.un_resolved: 0}

                    </div>

                    </div>
                </div>
              </div>

            </div>
          </div>
          : 
            <div className="d-flex justify-content-center" style={{height:'400px'}}>
              <div className="product_takeup_loader"></div>
            </div>
            }
        </div>
  
        
      </div>
    );
  }
}

export default FreshChatChart;
