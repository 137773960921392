import { get_path } from '../helpers/common_helper';
import {call_fetch, app_path} from './../helpers/fetch_helper';

export const listSettlementLogs = request  => {
    return call_fetch(get_path('admin') + '/settlement/summary', request);
}

export const Rescheduletask = request  => {
    return call_fetch(get_path('admin') + '/settlement/reschedule', request);
}

export const viewRunReport = request  => {
    return call_fetch(get_path('admin') + '/settlement/report', request);
}

export const getRetryAccounts = request  => {
    return call_fetch(get_path('admin') + '/settlement/schedule_acc_list', request);
}

export const getDownloadFolder = request => {
    return call_fetch(get_path('admin') + '/settlement/download_folder', request);
}
export const unknownTxnsPrcn = request => {
    return call_fetch(get_path('admin') + '/settlement/unknown_txn_prcn', request);
}

export const sendEmail = request => {
    return call_fetch(get_path('admin') + '/settlement/email', request);
}
