import React from 'react';
import {  Navigate } from 'react-router-dom';
import '../../../styles/flow.css';
import FlowComponent from '../../common/component/core/FlowComponent';
import {rt_algn} from '../../../helpers/common_helper';
import {lbl_repayment_amt,lbl_flow_fee_obj,lbl_amt} from './../../../helpers/product_helper';
import {createAgreement, listProducts} from '../../../actions/agreement_actions';
import DatePicker from 'react-datetime';
import $ from 'jquery';
import {get} from '../../../helpers/storage_helper';
class CreateMasterAgreement extends FlowComponent {
	
	state = {
		productList: null,
	//	product_id_csv: {},
		modal_close:false,
		toList: false,
		type : null
		//td_checkbox: false
	}


	componentWillMount(){	
		const comp_id = "master_agreement";
	    this[comp_id] = {};
	//	this.product_ids = [];
		this.agrmt_for = "onboarded";
		this.product_group = "float_advance";
		this.setOptionsToStateFromApi("lender");
	 /*	const request = {status: "enabled"}
		listLoanProduct(this.req(request))
        .then((response) => {
          if(!response){return };     

          const productList = response.data.map((loan_product,i)=>
	            <tr><td><input id={loan_product.id} type="checkbox" onChange={(e) => {this.handleProductCheckbox(e)}}/></td>
	            <td> {loan_product.product_name}</td>
	            <td> {loan_product.product_type}</td>
	            <td> {rt_algn(lbl_amt(loan_product.max_loan_amount, this.currency_code))}</td>
	            <td> {loan_product.duration} days</td>
	            <td> {rt_algn(lbl_flow_fee_obj(loan_product, this.currency_code))}</td>     
	            <td> {rt_algn(lbl_repayment_amt(loan_product, this.currency_code))}</td>
	            </tr>
	        );

  		this.setState({productList: productList});     
        });*/
	}

	// handleProductCheckbox = (event) => {
	// 	if(event.target.checked){
	// 		/*var product_ids = this.product_ids.slice();
	// 		product_ids.push(event.target.id);
	// 		this.product_ids = product_ids;*/
	// 		this.product_ids.push(event.target.id);

	// 	}else{	
	// 		this.product_ids = this.product_ids.filter(product_id => product_id != event.target.id);			
	// 	}

	// 	//event.target.id = 'product_id_csv';
	// 	//event.target.value = this.product_ids;
	// 	//this.handleChange(event);
	// 	//event.target.checked = true;
	// }

	handleCreateAgreementClick = (event) => {
		
		const master_agreement = { 
	
								  "lender_code": this.master_agreement.lender_code, 
								  
								  "duration_type" : this.master_agreement.duration_type,
								  "name": this.master_agreement.name,
								  "aggr_type": this.agrmt_for,
								  "aggr_duration":this.master_agreement.validity,
								  "acc_purpose" : this.product_group
		};
		// if(master_agreement.duration_type == 'by_fas'){
		// 	master_agreement.aggr_duration = null;
		// }else if(master_agreement.duration_type == 'by_days'){
		// 	master_agreement.fa_count = null;
		// }
		
		createAgreement(this.req({master_agreement}, true))
        .then((response) => {
        	this.resp(response);
          if(!response){return };
            if(response.status === "success"){
              alert(response.message);
              this.setState({toList: true});
              				//aggr_file_rel_path: response.data.aggr_file_rel_path});
            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          }
        );
	}    
//  componentDidUpdate(){
// 	$('#list_products').dataTable({
// 		  "order" : [],
// 	      "bAutoWidth": true,
// 	      "bDestroy": true, 
// 	      "searching": false,
// 	      "pageLength": 25
// 	    });
// 	}
// //
	
	/*headerCheckbox = (event) => {
		if(event.target.checked){
			this.setState({td_checkbox: true},() =>{this.loadTable()});
		} 
		else
		{
			this.setState({td_checkbox: false},() =>{this.loadTable()});
		}		
	}*/

	loadProducts = (event) => {
		this.setState({productList: null});

		this[event.target.id] = event.target.value;
		
		if(event.target.id == 'duration_type'){
			const type = (event.target.value == 'days') ? 'days' : (event.target.value == 'fas') ? 'fas' : 'months';
			this.setState({type});
		}

		if(this.lender_code == null){
			alert("Please select Lender");
		}

	 	// if(this.data_prvdr_code && this.lender_code && this.agrmt_for){
			// const request = {"lender_code": this.lender_code, "agrmt_for": this.agrmt_for};
			// listProducts(this.req(request, true))
	  //      .then((response) => {
	  //      	this.resp(response)
	  //        if(!response){return };
	  //          if(response.status === "success"){
	  //          	this.product_ids = [];
	  //          	response.data.map((loan_product, i) => 
			// 			this.product_ids.push(loan_product.id)
			// 		);
	              
	  //            const productList = response.data.map((loan_product,i)=>
		 //           <tr><td><input id={loan_product.id} type="checkbox" onChange={(e) => {this.handleProductCheckbox(e)}} defaultChecked/></td>
		 //           <td> {loan_product.product_name}</td>
		 //           <td> {loan_product.product_type}</td>
		 //           <td> {rt_algn(lbl_amt(loan_product.max_loan_amount, this.currency_code))}</td>
		 //           <td> {loan_product.duration} days</td>
		 //           <td> {rt_algn(lbl_flow_fee_obj(loan_product, this.currency_code))}</td>     
		 //           <td> {rt_algn(lbl_repayment_amt(loan_product, this.currency_code))}</td>
		 //           </tr>
	  //      	  );

  	// 			this.setState({productList});
	  //          } 
	  //         /* else{
	  //            alert(response.message + "\n" + JSON.stringify(response.data));  
	  //          } */
	  //        }
	  //      );
	  //  }
		else{
			// console.log("ELSE")
		}
		this.handleSelfChange(event);
	}

	
	render(){

		if (this.state.toList === true) { 
      		return <Navigate to={`/agreement/list`}/>
    	}

		return(
			<div className="container text-labelblue">
					
				<h5>{this.props.title}</h5>
				<br/>

				<div className="row">
					<div className="col-md-2">
						<span>Lender</span><font className="text-danger">   *  </font>
						<select id="lender_code" onChange={this.loadProducts} ref={this.props.createRef} className="form-control" type="text" required="required">
							{this.state.lender_dd}
						</select>
					</div>
					<div className="col-md-2">
						<span>Product Group</span>
						<select id="product_group"  value = {this.state.product_group} onChange={this.loadProducts} className="form-control" type="text" required="required">
						{this.getSelectOptions("product_group")}
						</select>
					</div>
					{/* <div className="col-3">
                        <span>Territory</span>
						<Form.Control as="select" id="territory"  onChange={this.handleChange}>
							{this.getSelectOptions("product_group")}
						</Form.Control>
                    </div> */}
					<div className="col-md-2">
						<span>Agreement Type</span><font className="text-danger">   *  </font>
            			<select id="agrmt_for" onChange={this.loadProducts} className="form-control" type="text" required="required">
							<option selected>Choose</option>
							<option value="onboarded">Onboarded Customers</option>
            			</select>
					</div>
					<div className="col-md-2">
						<span>Duration Type</span><font className="text-danger">   *  </font>
							<select id="duration_type" onChange={this.loadProducts} className="form-control" type="text" required="required">
								<option selected>Choose</option>
								<option value="days">By days</option>
								<option value="fas">By FAs</option>
								<option value="months">By Months</option>
							</select>
					</div>
					<div className="col-md-2">
            			<span>Name</span>
            			<input type="text" id="name" onChange={this.handleSelfChange} className="form-control" />              		
      				</div>
					{/*<div className="col-md-2">
            			<span>Data Provider</span><font className="text-danger">   *  </font>
            			<select id="data_prvdr_code" onChange={this.loadProducts} className="form-control" type="text" required="required">
               				{this.state.data_provider_dd}
            			</select>
      				</div>*/}
      				
				{/*</div>
				<br/>
				<div className="row">*/}
				
				{/*	<div className="col-md-2">
						<span>Valid From</span><font className="text-danger">   *  </font>	
						 <DatePicker closeOnSelect={true} onChange={(value) => {this.handleSelfDateChange("valid_from", value)}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
					</div>
					<div className="col-md-2">
						<span>Valid Upto</span>
						 <DatePicker closeOnSelect={true} onChange={(value) => {this.handleSelfDateChange("valid_upto", value)}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
					</div>*/}
					{this.state.type == 'days' &&
						<div className="col-md-2">
						<span>Agreement Validity</span><font className="text-danger">   *  </font>
            			<select id="validity" onChange={this.handleSelfChange} className="form-control" type="text" required>
               				<option selected>Choose</option>
               				<option value="30">30 Days</option>
               				<option value="60">60 Days</option>
               				<option value="90">90 Days</option>
               				<option value="120">120 Days</option>
               				<option value="150">150 Days</option>
               				<option value="180">180 Days</option>
            			</select>
					</div>
					}
					{this.state.type == 'fas' &&
						<div className="col-md-2">
							<span>Agreement Validity</span><font className="text-danger">   *  </font>
							<select id="validity" onChange={this.handleSelfChange} className="form-control" type="text" required>
								<option >Choose</option>
								<option value="8">Until completing 8 FAs</option>
								<option value="15">Until completing 15 FAs</option>
							</select>
						</div>
					}
					{this.state.type == 'months' &&
						<div className="col-md-2">
							<span>Agreement Validity</span><font className="text-danger">   *  </font>
							<select id="validity" onChange={this.handleSelfChange} className="form-control" type="text" required>
								<option >Choose</option>
								<option value="12">12 months</option>
								<option value="15">15 months</option>
								<option value="18">18 months</option>
								<option value="24">24 months</option>
							</select>
						</div>
					}
					<div className="col-md-2 mt-4">
		    		<input type="button" disabled={this.state.calling_api} value="Create Agreement" onClick={this.handleCreateAgreementClick} className="btn btn-primary" />
		    	</div>
					
				</div>
			
					
					
		
				{/*	{(this.state.productList != null) &&
						<div id='datatable'>
						<h4>Select Products</h4>
				            <table id ='list_products' className="display strip list">
				                <thead>
				                  	<tr>
				                  	  <th>Select{/*<input type='checkbox' onChange={(e) => {this.headerCheckbox(e)}} /></th>
				                      <th>Product Name</th>
				                      <th>Product Type</th>
				                      <th>FA Amount</th>
				                      <th>Duration</th>
				                      <th>Flow Fee</th>				                    
				                      <th>Repayment</th>
				                    </tr>
				                </thead>
				                <tbody> 
				                    {this.state.productList}
				                </tbody>
				             </table>           
				        </div>
		    		} */}
		    
				
		    

		    	
		    </div>
		    	

		);
	}

}

export default CreateMasterAgreement;
