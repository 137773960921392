import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ViewConfigurations, updateConfigurations } from '../../../actions/common_actions';
import { listAccountProvider } from '../../../actions/account_provider_actions';
import Form from 'react-bootstrap/Form';
import AutoRetryConfiguration from '../../../UI/Configurations/Component/AutoRetryConfigurations';
import FlowComponent from './core/FlowComponent';
import { get, Config } from '../../../helpers/storage_helper';
import { it, th } from 'date-fns/locale';
import {FiSettings} from  "react-icons/fi";

class CommonModal extends FlowComponent {
    constructor() {
        super();
        this.state = {
            show_modal: true,
            active_tab: "failed_fas_retry",
            arr: [],
            loader: true,
            addl_key:false
        };
    }
    handleClose() {
        this.setState({ show_modal: false,addl_key:true })
    }

    componentWillMount(){
        this.setState({ show_modal: true })

    }

    render() {

        return (
            <>

                <Modal
                    show={this.props.showModal}
                    onHide={() => this.props.toggleModal()}
                    size='lg'
                    className='modal_background_blur'

                >
                    <Modal.Header className='p-0' closeButton >
                        <Modal.Title>
                        <div className='row m-0 '  style={{background:'gray',border:'1px solid black'}}>
                <div className='col-lg-3 py-2 modal_settings_border' >
                    <div className='d-flex align-items-center justify-content-center  row m-0'>
                     <p className='m-0 col-10 text-center text-white' style={{fontSize:'15px '}} >Settings</p>
                    <div className=' col-2 p-1 settings_top' >
                      <FiSettings size={"17"} color='white'/>
                    </div>
                    </div>
                </div>
              
                <div className='col-lg-8 py-2'>
                <p className='m-0 text-center text-white' style={{fontSize:'15px '}}>Auto Retry Configurations</p>
               </div>
                </div>
                            </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='px-0 pt-0'>
                       
                        <AutoRetryConfiguration  handleClose={()=>this.handleClose()}/>
                    </Modal.Body>
                    <Modal.Footer>
                       
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}



export default CommonModal;