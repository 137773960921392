import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import '../../../styles/flow.css';
import { Link } from 'react-router-dom';
//import {getCSDataCode} from '../../../actions/master_data_actions';
import {createScoreModel, listScoreModels} from '../../../actions/score_model_actions';
import $ from 'jquery';
import {dd_value} from '../../../actions/common_actions';
import {check_priv} from '../../../helpers/storage_helper';
import { Modal , Button} from 'react-bootstrap';
import { CSVLink } from "react-csv";
import CommonReactTable from '../../../helpers/commonReact_table';
import { BasicReactTable } from '../../../helpers/react_table';

class ScoreModels extends FlowComponent {

	state = {
		ScoreModelsList: null,
    lists:[],
    columndatas:[]    
	}

	componentWillMount(){
    this.score_model = {};

		const request = {country_code: this.country_code}
		listScoreModels(this.req(request))
        .then((response) => {
          if(!response){return };
          this.setState(state=>state.lists = response.data)
          this.setState(state => state.columndatas = this.columndatas()) 
          // const ScoreModelsList = response.data.map((score_model,i)=>


          //     <tr key={i}>
          //       <td > {++i}</td>
          //       <td > {score_model.model_code}</td>
          //       <td > {dd_value(score_model.model_name)}</td>
          //       <td > {check_priv('score','weightage_list') && 
          //                     <>
          //                     <Link to={`/score_models/weightage/${score_model.model_code}/${score_model.model_name}`} target="_blank">

          //                       <input type="button" value="Weightages" className="btn-sm btn-primary`" />
          //                     </Link>&nbsp;&nbsp;&nbsp;
          //                     </>
          //                     }
          //                     {check_priv('score','result_config_list') && 
          //         			       <Link to={`/score_models/result_config/${score_model.model_code}`} target="_blank">
          //                         <input type="button" value="Result Configs" className="btn-sm btn-primary" />
          //                      </Link>
          //                     }
          //       </td>
               
          //     </tr>
          // );
          
          // this.setState({ScoreModelsList: ScoreModelsList});


          //  $('#list_score_models').dataTable({
          //     "order" : [],
          //  	"bAutoWidth": true
          //  	 });
          
           });

	}

  columndatas () {
    return [
      {
        Header:<div className='d-flex justify-content-center'>SI No.</div> , 
        id: "row",    
        Cell: cell => 
          <div className='d-flex justify-content-center'>{cell.row.index+1}</div>,
        
      },
      {
        Header : <div className='d-flex justify-content-start'>CS Model Code</div>,
        id : "model_code",
        accessor: "model_code",
        Cell:(cell)=>{return(<div className='d-flex justify-content-start'>{cell.row.original.model_code}</div>) }
      },
      {
        Header : <div className='d-flex justify-content-start' >CS Model Name </div> ,
        id : "model_name",
        accessor: "model_name",
        Cell:({row})=>{return (<div className='d-flex justify-content-start'>{dd_value(row.original.model_name)}</div>)}
      },
      {
        Header :  <div className='d-flex justify-content-center' >Model Configs</div>,
        id : "model_code_sample",
        show: check_priv('score','weightage_list') ? true : false,
        accessor: "model_code_sample",
        Cell:({row})=><div className='d-flex justify-content-center'>
              <Link to={`/score_models/weightage/${row.original.model_code}/${row.original.model_name}`} target="_blank">
                <input type="button" value="Weightages" className="btn-sm btn-primary`" />
              </Link>
              <Link style={{paddingLeft:20}} to={`/score_models/result_config/${row.original.model_code}`} target="_blank">
                <input type="button" value="Result Configs" className="btn-sm btn-primary" />
              </Link>
            </div>,
        width:'165px',
        
            
                
      },      
    ]
  }

  handleClose = (event) => {
      this.setState({ modalBox: false });
  }

  addButtonClick = (event) => {

   
    createScoreModel(this.req(this.score_model))
    .then((response) => {
          if(!response){return };                    
          if(response.status == "success"){
            alert(JSON.stringify(response.message)); 
            this.setState({modalBox: false}); 
            window.location.reload();
          }
      
        });   
  }

  handleChangeDataValue = (event) => {
    // Copy data from one text box to another
         this.handleSelfChange(event);
         var value = event.target.value;
         value = value.replace(/ /g, "_").toLowerCase();
         document.getElementById('model_code').value = value;
         this.score_model['model_code'] = value;
  }

  addScoreModel = (event) => {
    this.setState({modalBox: true});
  }
		
	render(){    
		return(
			<div className="container containerTopmargin max-width no-padding">
      {check_priv('score','create') &&
			  <div style={{textAlign:"right"}}>
          <input type="button" value="Add Score Model" onClick={this.addScoreModel} className="btn btn-primary" />
        </div>
      }
          <div>
                {/* <table id ='list_score_models' className="table">
                        <thead className={"text-info"}>
                          <tr><th>S.No</th>
                          <th>CS Model Code</th>
                          <th>CS Model Name</th>
                          <th>Model Configs</th>
                          </tr>
                        </thead>
                        <tbody> 
                          {this.state.ScoreModelsList}
                        </tbody>
                </table>  */}                 
                <h5 className='no-margin personalDetailstextval preHeaderpad headerTitle'>Score Models</h5>                                                                
                {/* <CommonReactTable showPag={true} minRow={0} row_data={this.state.lists !== undefined ? this.state.lists : []} column_data={this.state.columndatas} resizable={false} exportCSV={true} csvFile={"ScoreModels"} csvData={this.state.lists ? this.state.lists : []} searchData={[{title:'Score Models'}]}/>                       */}

                 <BasicReactTable row_data={this.state.lists} column_data={this.state.columndatas} csv_file={true} FName={"ScoreModels"} export_csv_datas={this.state.lists} searchData={[{title:'Score Models'}]} pagination={this.state.lists.length > 9 ? true : false} default_page_size={10}/>
    			</div>
      
      <Modal show={this.state.modalBox} onHide={this.handleClose}>
          <Modal.Header closeButton show={this.state.modalBox} onHide={this.handleClose}>
              <Modal.Title>Add Score Model </Modal.Title><br/>
          </Modal.Header>

          <Modal.Body>      
            <div className="container text-labelblue">
              <div className="d-flex align-items-center justify-content-around">
                <div className="col-5 text-center">
                  <span>Score Model Value</span><font className="text-danger"> * </font>
                  <input type="text" id="model_name" value={this.score_model.model_name} onChange={this.handleChangeDataValue} className="form-control" />
                </div>
                <div className="col-5 text-center">
                  <span>Score Model Code</span>
                  <input type="text" id="model_code" value={this.score_model.model_code} onChange={this.handleSelfChange} className="form-control" disabled />
                </div>
              </div>                                          
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>Close</Button>
            <Button style={{marginLeft:10}}type="button" variant="primary" onClick={this.addButtonClick}>Add</Button>
          </Modal.Footer>
          </Modal>
          </div>
			);
	}
}

export default ScoreModels;