import React from 'react'
import CommonReactTable from "../../../helpers/commonReact_table";
import FlowContainer from '../../common/container/core/FlowContainer'
import { getPrevContCust } from '../../../actions/churn_actions'
import moment from 'moment';
import { lbl_date, title_case } from '../../../helpers/common_helper';
import { Link } from 'react-router-dom';
import {dd_value} from '../../../actions/common_actions';
import { FcCalendar } from 'react-icons/fc';
import { Modal } from 'react-bootstrap';
import { getHolidayList } from '../../../actions/lead_actions';
import { custFollowUpTask } from '../../../actions/churn_actions';
import {format_date,Homeacc_prvdrLogoselector,person_name, lbl_amt} from '../../../helpers/common_helper';
import { Config } from '../../../helpers/storage_helper';
import FlowComponent from '../../common/component/core/FlowComponent';
import {format_date_tbl} from '../../../helpers/common_helper';
import { BasicReactTable } from '../../../helpers/react_table';
import { setValuesIntoRedux } from '../../../Redux/Slices/CommonSlice';
import { connect } from 'react-redux';
class PrevContacCust extends FlowComponent {

    state={
        row_data:[],
        column_data:[],
        market_holidays:[],
        reason : {}

    }
    column_data=()=>{
      return[
        {
          Header : "Account Number",
          id: "acc_prvdr_code",
          sortable:false,
          accessor:row => <div className='d-flex align-items-center' style={{paddingLeft:'40px'}}> <Homeacc_prvdrLogoselector logotype={row.acc_prvdr_code}/> <p className='no-margin' style={{paddingLeft:'20px'}}> {row.acc_num}</p>  </div> ,
          

        },{
          Header: "Biz Name",
          id: "biz_name",
          accessor:row=>row.biz_name,
          Cell: (cell) =>(<p className='cust_follow_text' style={{textAlign:"center"}}>{title_case(cell.value)}</p>),
          

        },
        {
          Header: "Customer Name",
          id: "cust_name",
          accessor:row=>row.cust_name,
          Cell: (cell) =>(<p className='cust_follow_text' style={{textAlign:"center"}}>{title_case(cell.value)}</p>),
          

        },
        {
          Header: "Customer ID",
          id: "cust_id",
          width : 150,
          accessor:row=>row.cust_id,
          Cell: (cell) =>(<p className='cust_follow_text' style={{textAlign:"center"}}><Link to={"/borrower/indiv/view/"+cell.value} target="_blank" >{cell.value}</Link></p>),
        

        },{
          Header: "Mobile Number",
          id: "mobile_num",
          width : 150,
          accessor:row=>row.mobile_num,
          Cell: (cell) =>(<p className='cust_follow_text' style={{textAlign:"center"}}>{title_case(cell.value)}</p>),
        

        },
        {
          Header: "Reason",
          id: "reason",
          width:200,
          accessor:row=>row.task_json.reason,
          Cell: (cell) =>(<p className='cust_follow_text' style={{textAlign:"center"}}>{title_case(cell.value)}</p>),
          

        },{
          Header: "Status",
          id: "status",
          width : 200,
          accessor:row=>row.status,
          Cell: (cell) =>(<p className='cust_follow_text' style={{textAlign:"center"}}>{title_case(cell.value)}</p>),
          

        },{
          Header: "Last Follow up Date",
          id: "updated_at",
          width : 150,
          accessor:row=>row.updated_at,
          Cell: (cell) =>(<p className='cust_follow_text' style={{textAlign:"center"}}>{cell.value?moment(cell.value).format('DD-MMM-YYYY'):'NA'}</p>),
          

        },{
          Header: "Next Follow up Date",
          id: "next_follow_up_date",
          width : 150,
          accessor:row=>row.task_json.next_follow_up_date,
          Cell: (cell) =>(<p className='cust_follow_text' style={{textAlign:"center"}}>{cell.value?moment(cell.value).format('DD-MMM-YYYY'):'NA'}</p>),
          

        },
        // {
        //   Header: "Next Follow Up Date",
        //   id: "next_follow_up_date",
        //   accessor:row=>row.task_json.next_follow_up_date,
        //   Cell: (cell) =>(<p className='cust_follow_text' style={{textAlign:"center"}}>{cell.value?moment(cell.value).format('DD-MMM-YYYY'):'NA'}</p>),
          

        // },
      ]
    }
    componentDidUpdate(prevProps){
      if(this.props.tab == 'prev_cont_cust' && prevProps.tab != 'prev_cont_cust'){

        this.setState({loader:true,row_data:[],no_data:false})
        getPrevContCust(this.req({'status' : 'to_be_followed', 'criteria' : 'prev_cont_cust'}))
         .then((response)=>{
            this.resp(response)
            if(!response)return this.setState({loader:false,no_data:true});
            if(response && response.status=='success'){
                this.setState({row_data:response.data,column_data:this.column_data(),loader:false})
                if(response.data.length==0){
                  this.setState({no_data:true})
                }
            }
        })
        
      }
     
    }

    handleClose=()=>{
      this.setState({cust_follow_up_modal:false})
  }
  changeDaysForReason=(e)=>{
    var reason_with_day = Config('follow_up_intervals').third_follow_up
    this.setState({resp_day_for_reason:reason_with_day[this.state.reason]})
    this.minDateAfter(reason_with_day[this.state.reason])
 }

minDateAfter(number) {
  if(Number.isInteger(number)){
      const dates = [];
      const today = moment();
      var i=0;
      while(dates.length<=number) {
        const nextDate = moment(today).add(i, 'days');
        if (nextDate.day() !== 0 &&  !this.state.market_holidays.includes(nextDate.format('YYYY-MM-DD'))) {
          dates.push(moment(nextDate).format('YYYY-MM-DD'));
        }
        i++
      }
      this.setState({min_follow_up_date:dates[dates.length-1]},()=>
      this.setState({next_follow_up_date:moment(this.state.min_follow_up_date). format('YYYY-MM-DD')}))
  }
  else{
      this.setState({min_follow_up_date:false})
  }
}
changeHandler = (e) => {      
  const toFilter = e
  const filterfrom = this.state.cust_wo_fa_list    
  const filteredData = filterfrom.filter(value => { 
    return(    
    value.cust_id !== null && value.cust_id.toLowerCase().includes(toFilter.toLowerCase())     
    )
  })
  this.setState(state=>state.copy = filteredData)		
}
  get_holidays=()=>{
      var request={
          days:14
      }
      getHolidayList(this.req(request))
      .then((response)=>{	
          if(!response){return};
          if(response.status == "success"){
              this.setState({market_holidays:response.data.market_holidays})
          }
          })
  }

  handleRemarks = (event) =>{
    if(event.target.value){
      var reason = this.state.reason
      reason[event.target.id] = event.target.value
      this.setState({reason});
    }
    
  }

  refreshList = () => {		
		
        this.componentDidMount()
	}

  render() {
    
    return (
      <div className='mx-2'>
        {this.state.row_data.length>0&&
        <div className='cust_next_follow_up' style={{marginTop:5}}>

        {/* <CommonReactTable className='border' row_data={this.state.row_data}  column_data={this.state.column_data}  FName="Customer Follow Up" global_search={false} showPag={this.state.row_data.length>10} minRow={0} default_page_size={10}  />  */}

        <BasicReactTable csv_file={true} row_data={this.state.row_data !== undefined ? this.state.row_data : []} column_data={this.state.column_data}  pagination={this.state.row_data.length>10} default_page_size = {10}  />  

        </div>}
        {this.state.no_data&&<h6 className='text-center text-light p-5 m-5'>No Data Found</h6>}
        {this.state.row_data.length==0&&this.state.loader&&<>
          <p className='text-light  text-center' style={{marginTop:'35vh'}}>Loading...</p>
          <div className="product_takeup_loader"></div>
        </>}
      </div>
    )
  }
}

const mapDispatchToProps ={
  setValuesIntoRedux

};

export default connect(mapDispatchToProps) (PrevContacCust)
