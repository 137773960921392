import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import '../../../styles/flow.css';
import {dd_value} from '../../../actions/common_actions';
import {listWeightages, updateWeightages, createFactor, listCSFactors} from '../../../actions/score_model_actions';
import $ from 'jquery';
import { Navigate } from 'react-router-dom';
import { Modal , Button} from 'react-bootstrap';
import {check_priv} from '../../../helpers/storage_helper';
import CommonReactTable from '../../../helpers/commonReact_table';
import withRouter from '../../../Router/Withrouter';
import { BasicReactTable } from '../../../helpers/react_table';


class CSWeightage extends FlowComponent {

	state = {
     weightageList: [],
     //save_btn_style: "enable_div",
     cs_weightages: null,	 
	 lists:[]
  	}

	componentWillMount(){
		this.new_cust_disable = false;
		this.repeat_cust_disable = false;
		this.new_cust_style = "disable_div";
     	this.repeat_cust_style = "disable_div";
     	this.new_save_style = "disable_div";
     	this.repeat_save_style = "disable_div";
		this.csw_comp = {}
		this.total_new = 0;
		this.total_repeat = 0;
		this.score_model = this.props.params.score_model;
		this.model_name = this.props.params.score_model_name;
		const request = {cs_model_code: this.score_model, country_code: this.country_code};

		listWeightages(this.req(request))
		.then((response) => {
          if(!response){return };     
          this.cs_weightages = response.data;
		  this.setState(state=>state.lists=response.data)
          const weightageList = this.renderWeightageList()
          
         
        // $('#list_weightage').removeAttr('width').dataTable({
        // 		  	  "order" : [],
        //              "ordering": false,
        //             /* "columnDefs": [{
        //              	'targets': [3],
        //              	'orderable': false,
        //              }],*/ 
        //           dom:'Bfrtip',
        //           buttons: [
        //           'csv','excel','print'
        //           ]   
                  
        // });
        });     
          
		

	}

	

	handleCustWeightage = (event) => {		
		var id = event.target.id;
		var value = event.target.value;
		
		if(Number(event.target.value) > 100){
			return
		}
		var temp_total_new = this.total_new
		var temp_total_repeat = this.total_repeat

		var skip_render = false;

		this.cs_weightages.map((csw,i)=> {
			if(id == csw.csf_type+"_new"){
				temp_total_new -= Number(csw.new_cust_weightage);
				temp_total_new += Number(value);
				if(temp_total_new <= 100  &&  Number(value) >= 0){
					csw.new_cust_weightage = value;
					if(temp_total_new == 100){
						this.new_save_style = "enable_div";
					}else{
						this.new_save_style = "disable_div";
					}
				}else{
					skip_render = true;	
				}
				return
				//alert(csw.new_cust_weightage)
			}else if(id == csw.csf_type+"_repeat"){
				temp_total_repeat -= Number(csw.repeat_cust_weightage);
				temp_total_repeat += Number(value);
				
				if(temp_total_repeat <= 100 &&  Number(value) >= 0){
					csw.repeat_cust_weightage = value;
					if(temp_total_repeat == 100){
						this.repeat_save_style = "enable_div";
					}else{
						this.repeat_save_style = "disable_div";
					}
				}else{
					skip_render = true;	
				}
				return
			}  
		});

		if(!skip_render){
			this.handleSelfChange(event);
			this.renderWeightageList();
		}
			
	}

	handleBackButton = (event) => {
		this.setState({toPrev: true});
	}

	renderWeightageList = () => {
		
		if(this.cs_weightages != null){
			this.total_new = 0
			this.total_repeat = 0
			this.cs_weightages.map((csw,i)=>{
				this.total_new += Number(csw.new_cust_weightage);
				this.total_repeat += Number(csw.repeat_cust_weightage);

			});	
			
			var j = Object.keys(this.cs_weightages).length;	
			var weightageList = this.columndatas()		
			// var weightageList = this.cs_weightages.map((csw,i)=>
		    //     <tr key={i}>
			//         <td>{++i}</td>
			//         <td>{dd_value(csw.csf_type)}</td>
			//         <td>
			// 	        <div className="row">
			// 	        	<div className="col-md-8" >
			// 	        		<input id={`${csw.csf_type}_new`} type="text" value={csw.new_cust_weightage} 
			// 	        			tabIndex={i++} onChange={this.handleCustWeightage} className={`form-control weightage_style ${this.new_cust_style}`} disabled={this.new_cust_disable} />
			// 	        	</div>
			// 	        	<div className="col-md-4 width-10">%</div>
			//         	</div>
			//        	</td>
			//         <td>
			// 	         <div className="row">
			// 	        	<div className="col-md-8" >
			// 				        <input id={`${csw.csf_type}_repeat`} type="text" value={csw.repeat_cust_weightage} 
			// 	        			tabIndex={++j}onChange={this.handleCustWeightage} className={`form-control weightage_style ${this.repeat_cust_style}`} disabled={this.repeat_cust_disable}/>
			// 	       		 </div>
			// 	        	<div className="col-md-4 width-10">%</div>
			// 	        </div>
			//         </td>
		    //     </tr>
		       
		    //     );

	        // weightageList.push( 
	        //         <tr key={++j}>
			//         <td></td>
			//         <td><b>Total</b></td>
			//         <td><center>{this.total_new} %</center></td>
			//         <td><center>{this.total_repeat} %</center></td>
			//         </tr>
	        // );

			// this.setState(state=>state.columndatas = this.columndatas())
		     
		    this.setState({weightageList});
		}
			    


	}

	columndatas () {
		var j = Object.keys(this.cs_weightages).length;
		var i = 1;
		return [
			{
				Header : <div className='d-flex justify-content-center'>SI No</div>, 
				id: "row",				      
				Cell: ({row}) => {
					return <div className='text-center'>{row.index+1}</div>;
				},
				Footer : <div className='text-center'><b></b></div>
			},
			{
				Header : "Factor",
				id : "csf_type",
				accessor: row => {return dd_value(row.csf_type)},
				Footer : <div className='text-center text-white'><b>Total</b></div>
			},
			{
				Header : () => (<div className='text-center'>
									Weightage for New Cust
									{check_priv('score','weightage_edit') &&
										<div className='d-flex justify-content-around align-items-center' style={{marginTop:10}}>												
											<input type="button" id="new_cust" value="Edit" onClick={this.editWeightage} className="btn btn-success"/>
											<input type="button" value="Save" onClick={this.handleSaveClick} className={`btn btn-success ${this.new_save_style}`} />
										</div>		                      	
									}
								</div>),
				id : "sample_1",				
				accessor: row => <div className='d-flex justify-content-around align-items-center'>
									<div className='col-6'>
										<input id={`${row.csf_type}_new`} type="text" value={row.new_cust_weightage} 
										tabIndex={i++}	 onChange={this.handleCustWeightage} className={`form-control weightage_style ${this.new_cust_style}`} disabled={this.new_cust_disable} />
									</div>
									<div className='col-5'>%</div>
								 </div>,
				Footer : <div className='text-center text-white'><b>{this.total_new} %</b></div>			
			},
			{
				Header : () => (<div className='text-center'>
									Weightage for Repeat Cust
									{check_priv('score','weightage_edit') &&
										<div className='d-flex justify-content-around align-items-center' style={{marginTop:10}}>											
											<input type="button" id="repeat_cust" value="Edit" onClick={this.editWeightage} className="btn btn-success"/>
											<input type="button" value="Save" onClick={this.handleSaveClick} className={`btn btn-success ${this.repeat_save_style}`} />
										</div>
									}
								</div>),
				id : "sample_2",				
				accessor: row => <div className='d-flex justify-content-around align-items-center'>
									<div className='col-6'>
											<input id={`${row.csf_type}_repeat`} type="text" value={row.repeat_cust_weightage} 
											tabIndex={j++} onChange={this.handleCustWeightage} className={`form-control weightage_style ${this.repeat_cust_style}`} disabled={this.repeat_cust_disable}/>
										</div>
									<div className='col-5'>%</div>
								</div>,	
				Footer : <div className='text-center text-white'><b>{this.total_repeat} %</b></div>
			},
		]
	}


	editWeightage = (event) => {
		var style_variable =  event.target.id+"_style";
		var score_disable = event.target.id+"_disable";

		if(this[style_variable] == "disable_div"){
			this[style_variable] = "enable_div";
			if(style_variable.includes("new")){
				this.repeat_cust_disable = true;
				this.new_cust_disable = false;
			}else if(style_variable.includes("repeat")){
				this.new_cust_disable = true;
				this.repeat_cust_disable = false;
			}
			
		}else{
			this[style_variable] = "disable_div";
			
		}

		this.renderWeightageList();
		
	}


	handleSaveClick = (event) => {
		this.csw_comp.score_model = this.score_model;
		const cs_weightages = this.csw_comp;

		updateWeightages(this.req({cs_weightages}))
		.then((response) => {
          if(!response){return };     
          this.new_save_style = "disable_div";
          this.repeat_save_style = "disable_div";
          this.renderWeightageList();
          
          if(response.status == "success"){
          	alert("Weightage updated successfully");
          	window.location.reload();
          }
      
        });     

	}

	addBtnClick = (event) => {
		const request = {cs_model_code: this.score_model, country_code: this.country_code};
		listCSFactors(this.req(request))
		.then((response) => {
          if(!response){return };                    
          if(response.status == "success"){
          	//alert(JSON.stringify(response.data));
          	this.getSelectOptionsFromResult(response.data, "cs_factors" ,["id", "name"]);
          }
      
        });     
		this.setState({modalBox: true});
	}

	handleAddFactor = (event) => {

		const cs_model_weightage = {"country_code": this.country_code, "cs_model_code": this.score_model, "csf_type": this.state.csf_type, "new_cust_weightage": 0, "repeat_cust_weightage": 0};
		createFactor(this.req({cs_model_weightage}))
		.then((response) => {
          if(!response){return };     
          
          alert(response.message);
          window.location.reload();
      
        });     
	}

	handleClose = (event) => {
      this.setState({ modalBox: false });
     
    }

	

	render(){		
		if(this.state.toPrev === true){
			return <Navigate to="/score_models" />
		}
		
		return(
		<>
		
			<div className="container containerTopmargin max-width no-padding">
				<div className="d-flex justify-content-between align-items-center">					
					<h4 className='no-margin personalDetailstextval preHeaderpad headerTitle'>CS Weightage</h4>											
					{check_priv('score','weightage_create') &&
						<div>
							<input  type="button" value="Add New Factor" onClick={this.addBtnClick} className="btn btn-primary" />
						</div>	
					}
				</div>				
				<div className="d-flex justify-content-between align-items-center">					
					<h4 className='no-margin headerTitle'>Score Model: <b>{dd_value(this.model_name)}</b></h4>												
				</div>
				


				<div className="csWeightagetab">					
        				{/* <table id ='list_weightage' className="table">
		                      <thead className={"text-info"}>
		                      <tr><th>S.No</th>
		                      <th>Factor</th>
		                      <th>Weightage for New Cust
		                      	{check_priv('score','weightage_edit') &&
		                      	<>
									&nbsp;&nbsp;&nbsp;	                      	
		                      		<br/><input type="button" id="new_cust" value="Edit" onClick={this.editWeightage} className="btn btn-success"/>&nbsp;&nbsp;&nbsp;
		                      		<input type="button" value="Save" onClick={this.handleSaveClick} className={`btn btn-success ${this.new_save_style}`} />
		                      	</>		                      	
		                      	}
		                      	</th>
		                      <th>Weightage for Repeat Cust
		                      	{check_priv('score','weightage_edit') &&
		                      	<>
		                    		&nbsp;&nbsp;&nbsp;
		                      		<br/><input type="button" id="repeat_cust" value="Edit" onClick={this.editWeightage} className="btn btn-success"/>&nbsp;&nbsp;&nbsp;
		                      		<input type="button" value="Save" onClick={this.handleSaveClick} className={`btn btn-success ${this.repeat_save_style}`} />
		                      	</>
		                      	}
		                      	</th>
		                      </tr></thead>
		                      
		                       <tbody>
		                      		{this.state.weightageList}
								</tbody>
         				</table> */}						
						{/* <CommonReactTable showPag={false} minRow={0} row_data={this.state.lists !== undefined ? this.state.lists : []} column_data={this.state.weightageList} resizable={false} sortable={false} exportCSV={true} csvFile={"CS Weightage"} csvData={this.state.lists ? this.state.lists : []}/>     */}

						<BasicReactTable row_data={this.state.lists !== undefined ? this.state.lists : []}  column_data={this.state.weightageList} csv_file={true} FName={"CS Weightage"} csvData={this.state.lists ? this.state.lists : []} default_page_size={10} footer={true}/>    											
				</div>				
				<div className="d-flex no-margin">
					<input type="button" value="Back" onClick={this.handleBackButton} className="btn btn-primary" />
				</div>
			</div>
			<div >
			<Modal show={this.state.modalBox} onHide={this.handleClose} style={{width:'-700px'}}>
              <Modal.Header closeButton show={this.state.modalBox} onHide={this.handleClose}>
                  <Modal.Title><h3>Add New Factor</h3></Modal.Title>
              </Modal.Header>
              
              <Modal.Body>
	              <div className="container">

	              	<div className="d-flex justify-content-around align-items-baseline">
	              		{/*<div className="col-md-6">
	              			<div className="row">*/}
	              				<div>
	              					<label className='no-margin'>Factor</label>
									<select id="csf_type" onChange={this.handleSelfChangeState} className="form-control p-0">
										{this.state.cs_factors_dd}
									</select>
	              				</div>	              				
	              			</div>
	              		{/*</div>
	              	</div>*/}
	              	<br/>

	              {/*	<div className="row">
	              		<div className="col-md-6">
	              			<div className="row">
	              				<div className="col-md-6">
	              					<label>Weightage</label>
	              				</div>
	              				<div className="col-md-6">
		              				<input id="new_cust_weightage" type="text" value="0" onChange={this.handleSelfChangeState} className="form-control" />
	              				</div>
	              			</div>
	              		</div>
	              	</div>*/}



	              </div>
              </Modal.Body>

              <Modal.Footer>
              <Button variant="primary" onClick={this.handleAddFactor}>Add</Button>
                <Button style={{marginLeft:10}} variant="secondary" onClick={this.handleClose}>Close</Button>
              </Modal.Footer>
          </Modal>
          </div>
          </>

			);
	}
}

export default withRouter(CSWeightage) ;