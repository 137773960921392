import { get_path } from '../helpers/common_helper';
import { admin_path, app_path, call_fetch } from './../helpers/fetch_helper';
import {remove_all,get, set_logout} from  './../helpers/storage_helper';
import { PURGE } from 'redux-persist';
// import { persistor } from '../Redux/Store';

export const userService = {  
    login,
    logout,
 };



function login(email, password, uuid) {
    
    const kyc_path = get_path("kyc")
    const master_data_version = get('master_data_version');
    
    return call_fetch(kyc_path +`/user/login`, { email, password, master_data_version, uuid}, false, false);
}

function logout(req) {

    const kyc_path = get_path("kyc")
//    persistor.purge()
   // remove user from local storage to log user out
   call_fetch(kyc_path +`/user/logout`);
   remove_all();
   set_logout();
}
