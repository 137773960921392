import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ViewConfigurations, updateConfigurations } from '../../../actions/common_actions';
import { listAccountProvider } from '../../../actions/account_provider_actions';
import Form from 'react-bootstrap/Form';

import { get, Config } from '../../../helpers/storage_helper';
import FlowComponent from '../../common/component/core/FlowComponent';
import { it, th } from 'date-fns/locale';

class AutoRetryConfigurations extends FlowComponent {
    constructor() {
        super();
        this.state = {
            show_modal: true,
            active_tab: "failed_fas_retry",
            arr: [],
            loader: true,
            active_side_bar:"fas"
        };
    }
    handleClose() {
        this.setState({ show_modal: false })
    }


    componentWillMount() {


        listAccountProvider(this.req({ "country_code": get('market').country_code, status: 'enabled', biz_account: true }))
            .then((response) => {
                if (!response) { return }
                if (response.status == "success") {
                    this.setState({ acc_prvdr_lists: response.data.list })
                }
            })
        this.get_config_lists()
    }
   
   componentDidUpdate(prevProps, prevState) {
        if(prevState.active_tab != this.state.active_tab){
            this.get_config_lists()
        }
   }


    get_config_lists() {
        this.setState({ loader: true })
        const activeTab = this.state.active_tab
        var req = { "menu": this.state.active_tab, "country_code": get('market').country_code }
        ViewConfigurations(this.req(req))
            .then((response) => {
                if (!response) { return }
                if (response.status == "success") {
                    this.setState({ configData: response.data.config, arr: response.data.config.auto_retry_enabled_list, responseData: response.data, loader: false, [activeTab] : response.data.status == 'enabled' ? true : false })

                    console.log('response', response)
                }
            })
    }

    handleCheckbox(value, type) {
        const selectedTab = this.state.active_tab
        this.setState({ [selectedTab]: value })
    }



    handle_acc_prvdr_lists(value, acc_prvdr) {

        var b = [...this.state.arr]

        if (value) {

            if (!(b.includes(acc_prvdr))) {
                b.push(acc_prvdr)
                this.setState({ arr: b })
            }

        } else {

            if (b.length > 0) {
                var a = b.filter((item, idx) => { return item !== acc_prvdr })
                this.setState({ arr: a })
            }
        }
    }

    updateHandler() {
        const activeTab = this.state.active_tab
        var req = {
            id: this.state.responseData.id, status:this.state[activeTab]  ? "enabled" : "disabled", config:{
                "auto_retry_enabled_list": this.state.arr, country_code: get('market').country_code}
        }
        updateConfigurations(this.req(req))
            .then((response) => {
                if (!response) { return this.setState({ loader: false }) }
                if (response.status == "success") {
                    alert(response.message);
                    this.get_config_lists()
                    // this.setState({configData:response.data,arr:response.data.auto_retry_enabled_list})
                    // console.log('response', response)
                }
            })
    }
    activeTabsHandler(value) {
        this.setState({ active_tab: value }, () => { this.get_config_lists() })

    }

    render() {
        const active_config_tab = this.state.active_side_bar
        const active_tab_plural =  this.state.active_side_bar == 'fas' ? 'FAs' : 'Switches'

        return (
            <div>

                

                <div className='row m-0  '  >
                    
                    <div className='col-lg-3 px-0'>
                        <div className= {` pointer px-0 py-1 pt-4 `} style={{borderRight:'1px solid black'}} onClick={()=>this.setState({active_side_bar:"fas",  active_tab:"failed_fas_retry"})}>
                        <p className={ `m-0 py-3 configuration_menu text-center text-white ${(this.state.active_side_bar=="fas")?"active_modal_menu":""}`}> Float Advance</p>
                        </div>

                        <div className= {` pointer px-0 py-1 pt-4 `} style={{borderRight:'1px solid black'}} onClick={()=>this.setState({active_side_bar:"switch", active_tab:"failed_switch_retry"})}>
                        <p className={ `m-0 py-3 configuration_menu text-center text-white ${(this.state.active_side_bar=="switch")?"active_modal_menu":""}`}>Float Switch</p>
                        </div>
                    </div>
              
                <div className='col-lg-9 py-3' >
                    
              
                    {!this.state.loader ?
                            <div>
                                <div className='d-flex align-items-center py-2 row m-0 pointer'>
                                    <div className={`col-md-6 px-0  py-2 ${(this.state.active_tab == `failed_${active_config_tab}_retry`) ? "modal_active_tab" : "non_active_tab"}`} onClick={() => this.activeTabsHandler(`failed_${active_config_tab}_retry`)}>
                                        <p className={`m-0 py-2 text-center ${(this.state.active_tab == `failed_${active_config_tab}_retry`) ? " black_text" : "text-white"}`}> Failed {active_tab_plural}  {(this.state.active_tab == `failed_${active_config_tab}_retry`) ? <span> ({(this.state.responseData && this.state.responseData.status == "disabled") ? "OFF" : "ON"}) </span> :""}</p>
                                    </div>
                                    <div className={`col-md-6 px-0 py-2 ${(this.state.active_tab !== `failed_${active_config_tab}_retry`) ? "modal_active_tab" : "non_active_tab"}`} onClick={() => this.activeTabsHandler(`unknown_${active_config_tab}_retry`)}>
                                        <p className={` m-0 py-2 text-center ${(this.state.active_tab == `unknown_${active_config_tab}_retry`) ? " black_text" : "text-white"}`}> Unknown {active_tab_plural}  {(this.state.active_tab == `unknown_${active_config_tab}_retry`) ? <span>({(this.state.responseData && this.state.responseData.status == "disabled") ? "OFF" : "ON"}) </span>:""} </p>
                                    </div>
                                </div>

                                <div className='row m-0 pt-4  d-flex justify-content-center align-items-center'>
                                    <div className='col-5 px-0'>
                                        <p className='text-white m-0'> {(this.state.active_tab == `failed_${active_config_tab}_retry`) ?` Enable Auto Retry for Failed ${active_tab_plural} ` :`Enable Auto Retry for Unknown ${active_tab_plural}`}</p>
                                    </div>
                                    <div className='col-1 px-0'>


                                        <div class="form-check pl-0 pointer">
                                            <Form>
                                                <Form.Check type="switch" id="custom-switch" className='switch_btn_style pointer' defaultChecked={(this.state.responseData && this.state.responseData.status == "enabled")} onChange={(e) => { this.handleCheckbox(e.target.checked, "auto_retry_failed") }} />

                                            </Form>
                                        </div>

                                    </div>

                                </div>
                                <div className='d-flex align-items-center justify-content-center mt-2'>
                                    <div className='horizontal_line col-6'></div>

                                </div>
                                {this.state.acc_prvdr_lists && this.state.acc_prvdr_lists.length > 0 &&

                                    <div className='mt-4 '>
                                        {
                                            this.state.acc_prvdr_lists.map((item, idx) => {
                                                return (

                                                    <div className='row m-0 py-2  d-flex justify-content-center align-items-center'>
                                                        <div className='col-3 px-0'>
                                                            <p className='text-white m-0 col-6'>{item.acc_prvdr_code}</p>
                                                        </div>
                                                        <div className='col-1 px-0'>

                                                            <div class="form-check m-0 pl-0 pointer">
                                                                <input type="checkbox" style={{ height: '13px' }} className="col-6 mt-1" id={`${item.acc_prvdr_code}`} defaultChecked={this.state.configData && this.state.configData.auto_retry_enabled_list.length > 0 && this.state.configData.auto_retry_enabled_list.includes(item.acc_prvdr_code)} onClick={(e) => this.handle_acc_prvdr_lists(e.target.checked, item.acc_prvdr_code)} />

                                                            </div>

                                                        </div>

                                                    </div>

                                                )
                                            })}
                                    </div>

                                }

                            <div className='mt-2 px-5 d-flex justify-content-end'  >
                                <div className='col-3'>
                                    <button type="button" class="btn btn-primary px-5 py-2 btn-lg" onClick={() => this.updateHandler()}>Save</button>
                                </div>
                            </div>

                            </div>
                            : <>
                                <p className='text-light mt-3 text-center'>Loading...</p>
                                <div className="product_takeup_loader"></div>
                            </>
                        } 
                   
               
                </div>

                </div>


                
                        {/* {!this.state.loader ?
                            <div>
                                <div className='d-flex align-items-center py-2 row m-0 pointer'>
                                    <div className={`col-md-6 px-0  py-2 ${(this.state.active_tab == "failed_fas_retry") ? "modal_active_tab" : "non_active_tab"}`} onClick={() => this.activeTabsHandler("failed_fas_retry")}>
                                        <p className={`m-0 py-2 text-center ${(this.state.active_tab == "failed_fas_retry") ? " black_text" : "text-white"}`}> Failed FAs  {(this.state.active_tab == "failed_fas_retry") ? <span> ({(this.state.configData && this.state.configData.status == "disabled") ? "OFF" : "ON"}) </span> :""}</p>
                                    </div>
                                    <div className={`col-md-6 px-0 py-2 ${(this.state.active_tab !== "failed_fas_retry") ? "modal_active_tab" : "non_active_tab"}`} onClick={() => this.activeTabsHandler("unknown_fas_retry")}>
                                        <p className={` m-0 py-2 text-center ${(this.state.active_tab == "unknown_fas_retry") ? " black_text" : "text-white"}`}> Unknown FAs {(this.state.active_tab == "unknown_fas_retry") ? <span>({(this.state.configData && this.state.configData.status == "disabled") ? "OFF" : "ON"}) </span>:""} </p>
                                    </div>
                                </div>

                                <div className='row m-0 pt-4  d-flex justify-content-center align-items-center'>
                                    <div className='col-5 px-0'>
                                        <p className='text-white m-0'> Enable auto retry for failed FA</p>
                                    </div>
                                    <div className='col-1 px-0'>


                                        <div class="form-check pl-0 pointer">
                                            <Form>
                                                <Form.Check type="switch" id="custom-switch" className='switch_btn_style ' defaultChecked={(this.state.configData && this.state.configData.status == "enabled")} onChange={(e) => { this.handleCheckbox(e.target.checked, "auto_retry_failed") }} />

                                            </Form>
                                        </div>

                                    </div>

                                </div>
                                <div className='d-flex align-items-center justify-content-center mt-2'>
                                    <div className='horizontal_line col-6'></div>

                                </div>
                                {this.state.acc_prvdr_lists && this.state.acc_prvdr_lists.length > 0 &&

                                    <div className='mt-4 '>
                                        {
                                            this.state.acc_prvdr_lists.map((item, idx) => {
                                                return (

                                                    <div className='row m-0 py-2  d-flex justify-content-center align-items-center'>
                                                        <div className='col-3 px-0'>
                                                            <p className='text-white m-0 col-6'>{item.acc_prvdr_code}</p>
                                                        </div>
                                                        <div className='col-1 px-0'>

                                                            <div class="form-check m-0 pl-0 pointer">
                                                                <input type="checkbox" style={{ height: '13px' }} className="col-6 mt-1" id={`${item.acc_prvdr_code}`} defaultChecked={this.state.configData && this.state.configData.auto_retry_enabled_list.length > 0 && this.state.configData.auto_retry_enabled_list.includes(item.acc_prvdr_code)} onClick={(e) => this.handle_acc_prvdr_lists(e.target.checked, item.acc_prvdr_code)} />

                                                            </div>

                                                        </div>

                                                    </div>

                                                )
                                            })}
                                    </div>

                                }

                            <div className='mt-2 px-5 d-flex justify-content-end'  >
                                <div className='col-3'>
                                    <button type="button" class="btn btn-primary px-5 py-2 btn-lg" onClick={() => this.updateHandler()}>Save</button>
                                </div>
                            </div>

                            </div>
                            : <>
                                <p className='text-light mt-3 text-center'>Loading...</p>
                                <div className="product_takeup_loader"></div>
                            </>
                        } */}
                    
                   
            </div>
        );
    }
}



export default AutoRetryConfigurations;