import React, { useEffect } from "react";
import { useState } from "react";
import { Config, get } from "../../../helpers/storage_helper";
import {
  call_fetch,
  app_path,
} from "../../../helpers/fetch_helper";
import { get_path } from "../../../helpers/common_helper";
import { setValuesIntoRedux } from "../../../Redux/Slices/CommonSlice";
import {useDispatch, useSelector } from "react-redux";
import { store } from "../../../Redux/Store";







const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.type = "text/javascript";
    script.setAttribute("chat", "true");
    script.id ='freshchat_script'
    script.onload = resolve;
    script.onerror = reject;
   
    document.body.appendChild(script);
  });
};


const FreshChat = () => {
  
  const [user_details, setUserDetails] = useState(null);
  const shoftphonestate = useSelector(state=>state.cs_soft_phone)
  const market = get("market");

  const dispatch = useDispatch()

  const get_user_details = async () => {
    call_fetch(get_path("kyc") + "/fresh_chat/get_user_details", {
      country_code:market ?  market.country_code: '',
      time_zone:market? market.time_zone:'',
    }).then((response) => {
      // console.log("hoiii", response.data);
      if (!response) return;
      if (response.status == "success") {
        // console.log("hoiii", response.data);
        // console.log(response);
        let data = response.data;
        if (data) {
          setUserDetails({
            first_name: data.first_name,
            last_name: data.last_name,
            mobile_num: data.mobile_num,
            email: data.email,
            restore_id: data.restore_id,
            person_id:data.person_id,
            country_code : data.country_code,
            tags:data.tags
          });
        }
        return;
      }
       else {
        return;
      }
    });
  };

  const save_restore_id = (restore_id) => {
    // console.log(restore_id);
    const response = call_fetch(get_path("kyc") + "/fresh_chat/save_restore_id", {
      country_code: market.country_code,
      time_zone: market.time_zone,
      restore_id: restore_id,
    })

    if (!response) return;

    return;
  };

  useEffect(() => {
      
    get_user_details();

  }, []);

  useEffect(() => {
    if (user_details && user_details.email) {
      const widget_token = Config("fresh_chat_widget_token");
      const host_url = Config("fresh_chat_host_url");

      const fresh_chat_script = Config('fresh_chat_script')
      // const fresh_chat_script = '//in.fw-cdn.com/31205069/560527.js'

      // console.log("second", user_details);

      // console.log(window.fcWidget,'window')


      loadScript(fresh_chat_script)                              
        .then(() => {
          
         if(document.getElementById('freshchat_script')){
          // console.log(user_details.email,'email')
          window.fcWidget.init({
            token: widget_token,
            host: host_url,
            externalId: user_details.person_id,
            restoreId: user_details.restore_id ? user_details.restore_id : null,
            
          });

          // window.fcWidget.on("widget:opened", ()=> {
          //  dispatch(setValuesIntoRedux({showMenus:false}))
          // });
          

          window.fcWidgetMessengerConfig = {
            tags:user_details.tags ? user_details.tags : null,
            externalId: user_details.person_id,
            restoreId: user_details.restore_id,
            config: {
                eagerLoad: true,
                // headerProperty:{
                //  hideChatButton: store.getState().cs_soft_phone.showMenus ?
                // }
            },
           };

          window.fcWidget.on("widget:loaded", function(resp) {
         
          // console.log(window.fcWidgetMessengerConfig,'llll')

          window.fcWidget.user.get(function (resp) {
            var status = resp && resp.status,
              data = resp && resp.data;
              // console.log(resp,'getuser')
              if(data && data.restoreId && data.restoreId != user_details.restore_id && user_details.email==data.email){
                  save_restore_id(data.restoreId)
                }

              // console.log("exist_user", data);
            if (status !== 200) {
                // console.log('inside creation')
              window.fcWidget.user.setProperties({
                externalId:user_details.person_id,
                firstName: user_details.first_name,
                lastName: user_details.last_name + " " +user_details.country_code,
                email: user_details.email,
                phone: user_details.mobile_num,
              });
              window.fcWidget.on("user:created", function (resp) {
                var status = resp && resp.status,
                  data = resp && resp.data;
                // console.log("user_created_data", data);
                if (status === 200) {
                  if (data.restoreId) {
                    // console.log("restoreId", data.restoreId);
                    save_restore_id(data.restoreId);
                  }
                }
              });
            }
          });
        })
          
         }
      })
        .catch((error) => {
          console.error("Error loading script:", error);
        });
    }

    return()=>{
      const scriptElement = document.getElementById('freshchat_script');
      if (scriptElement) {
        document.body.removeChild(scriptElement);

      }
     if(window.fcWidget){
      window.fcWidget.destroy()
     }
    }
  }, [user_details]);

  return <div></div>;
};

export default FreshChat;
