import React from 'react';
import FlowContainer from '../../common/container/core/FlowContainer';
import InputSelectionHeader from '../component/InputSelectionHeader';
import moment from "moment";
import { listAccountProvider } from '../../../actions/account_provider_actions';
import { get } from '../../../helpers/storage_helper';
import { Link } from "react-router-dom";
import {getRefAccounts} from '../../../actions/account_txn_actions';
import It_opertations_yesterday_results from '../component/It_opertations_yesterday_results';
import It_operation_live_results from '../component/It_operation_live_results';
import { Biz_operation_live_reports} from '../../../actions/report_actions';
import DatePicker from 'react-datetime';
import { BiCalendar } from "react-icons/bi";
import withRouter from '../../../Router/Withrouter';

class ItOperation extends FlowContainer {
    state ={
       
       acc_prvdrs:[],
      refresh_count: 0,
      all_network_prvdr:[],
      // live_btn:true,
      start_date:'' ,
      end_date: '',
      country_code: get('market').country_code,
      history_data:[],
      active_view_bttn:false,
      loader:false,
      last_days_report_count:0,
      last_report_in:"Days",
      start_date_view_btn:false,
      last_days_and_month_btn:false,
 
  

    }

    componentDidUpdate(){
      if(window.location.pathname=="/it_operations/past" || window.location.pathname=="/it_operations/live"){
        sessionStorage.setItem('path',window.location.pathname)
      }
    }

    componentWillMount(){
      
      var request = {country_code: get('market').country_code, time_zone: get('market').time_zone, status: ""};      
      listAccountProvider({...request, status: 'enabled', biz_account: true})
		.then((response)=>{
			if(!response){return };
			if(response.status === "success"){
                let acc_prvdrs = []
                response.data.list.map(each=>
                    acc_prvdrs.push(each.acc_prvdr_code)
                )
                
          this.setState({acc_prvdrs})     
          // this.get_ref_accounts(acc_prvdrs[0]) 

			}
      if(sessionStorage.getItem('path')=="/it_operations/past"){
        this.biz_operation_liveHandler("yesterday") 
      }else if(sessionStorage.getItem('path')=="/it_operations/live"){
        this.biz_operation_liveHandler("live") 
      }
		})    
    
    }
   
   
    
    float_acc_view_handler(type){
      this.setState({[type]:true})
    }

    set_attributes(array){
        if((this.state.country_code != array.country_code  || this.state.activeCurrency != array.activeCurrency|| this.state.gds_report_active_accPrvds!=array.gds_report_active_accPrvds ) &&  array.country_code!="*"){
          this.setState(array)
        }
      }

    acquisition_targets(type){
     if(type=="this_month"){
       this.setState({this_month:true ,last_month:false})
     }
     else if(type=="today"){
      this.setState({yester_day:false ,today:true})
     }else if(type=="yester_day"){

      this.setState({yester_day:true ,today:false})
     }
     else {
      this.setState({this_month:false ,last_month:true})
     }
    }

    tabCheck = (e) =>{
           
      this.setState({
          ...this.state,
          active:e,

          })
  } 
  
  get_ref_accounts(acc_prvdr){
    const account = {"lender_code":this.lender_code, "country_code": this.country_code,to_recon : true , network_prvdr_code :acc_prvdr }
   var Network_prvdr=[]
    getRefAccounts(this.req({account}))
    .then((response)=>{
      this.setState({all_network_prvdr:response.data})

    })
  }

  network_prvdr_handler(acc_prvdr){
 
    this.setState({active_acc_prvdr:acc_prvdr})
    this.get_ref_accounts(acc_prvdr)
  }

  biz_operation_liveHandler(type){
    if(type=="live"){
      this.setState({last_days_and_month_btn:false,start_date_view_btn:false,live_btn:true,today_btn:false, yester_day_btn:false,loader:true, day_bf_btn:false,this_month_btn:false,last_month_btn:false,start_date:"",end_date:""})
   
    }
    else if(type=="yesterday"){
      this.setState({start_date_view_btn:false,last_days_and_month_btn:false,live_btn:false,today_btn:false, yester_day_btn:true,loader:true, day_bf_btn:false,this_month_btn:false,last_month_btn:false,start_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),end_date:moment().subtract(1, 'days').format("YYYY-MM-DD")})
 
    }
    else if(type=="day_bf_btn"){
      this.setState({start_date_view_btn:false,last_days_and_month_btn:false,live_btn:false,today_btn:false, yester_day_btn:false,loader:true, day_bf_btn:true,this_month_btn:false,last_month_btn:false, start_date: moment().subtract(2, 'days').format('YYYY-MM-DD'),end_date:moment().subtract(2, 'days').format('YYYY-MM-DD') })
    }
    else if(type=="this_month_btn"){
      this.setState({start_date_view_btn:false,last_days_and_month_btn:false,live_btn:false,today_btn:false, yester_day_btn:false,loader:true, day_bf_btn:false,this_month_btn:true,last_month_btn:false, start_date: moment().startOf('month').format("YYYY-MM-DD"),end_date:moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD")})
    }
    else if(type=="last_month_btn"){
      this.setState({start_date_view_btn:false,last_days_and_month_btn:false,live_btn:false,today_btn:false, yester_day_btn:false,loader:true, day_bf_btn:false,this_month_btn:false,last_month_btn:true, start_date: moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD"),
    end_date: moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD")})
    }
    else if(type=="today_btn"){
      this.setState({start_date_view_btn:false,last_days_and_month_btn:false,live_btn:false,today_btn:true, yester_day_btn:false,loader:true, day_bf_btn:false,this_month_btn:false,last_month_btn:false, start_date: moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD"),
    end_date: moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD")})
    }
    
  }
  biz_ops_dateSection_handler(type,val){

    this.setState({[type]:val})
  }

  set_history_data(key_name,response_data){
    // var resp_data=response_data[key_name]
    var report_history_data = this.state.history_data
  
    report_history_data = {...report_history_data, ...response_data};
    this.setState({history_data:report_history_data})
  }

  biz_ops_View_section_handler(criteria=null,start_date=this.state.start_date,end_date=this.state.end_date,acc_prvdr=this.state.gds_report_active_accPrvds ?this.state.gds_report_active_accPrvds :null ,country_code=this.state.country_code, addl_data=null){
     this.setState({active_view_bttn:true})

     if(criteria=="apply_to_disb_time_chart"){
      this.setState({disbursement_spin_loader:true})
     }
     if(criteria=="repay_to_settle_time_chart"){
      this.setState({repayment_spin_loader:true})
     }
    
      //  this.setState({live_btn:false,yester_day_btn: this.state.day_bf_btn ? false : this.state.this_month_btn ? false :this.state.last_month_btn ?false :this.state.today_btn?false: this.state.start_date_view_btn ? false :true })
        Biz_operation_live_reports(this.req({"report_type":'operation_dashboard',"criteria":criteria,"start_date":start_date,"end_date":end_date,"acc_prvdr_code":acc_prvdr,"country_code":country_code,"history":true,"addl_data":addl_data}, false))
        .then((response)=>{
          if(!response){return };
          if(response.status=="success"){

            var resp_data=response.data
           
               var report_history_data = this.state.history_data
               report_history_data = {...report_history_data, ...resp_data};
               this.setState({history_data:report_history_data,active_view_bttn:false,loader:false})

              if(response.data.appl_approvals){

                this.set_history_data("appl_approvals",resp_data)
               
             }
             if(response.data.penalty){
              this.set_history_data("penalty",resp_data)

            }
             if(response.data.fa_applied_by_count){
              this.set_history_data("fa_applied_by_count",resp_data)
                         
             }
             if(response.data.fas_pending_disbursement){
              this.set_history_data("fas_pending_disbursement",resp_data)   
             }
             if(response.data.disb_delay_reason){
              this.set_history_data("fa_applied_by_count",resp_data)

             }
             if(response.data.rm_metrics){
              this.set_history_data("rm_metrics",resp_data)

             }
             if(response.data.rm_visit_chart){
              this.set_history_data("rm_visit_chart",resp_data)

             }
             if(response.data.float_switch_report_day_wise){
              this.set_history_data("float_switch_report_day_wise",resp_data)

             }
             if(response.data.apply_to_disb_time_chart){
              this.set_history_data("apply_to_disb_time_chart",resp_data)
             }
             if(response.data.repay_to_settle_time_chart){
              this.set_history_data("repay_to_settle_time_chart",resp_data)

             }
             if(response.data.repay_to_settle_time_chart){
              this.set_history_data("repay_to_settle_time_chart",resp_data)

             }
             if(criteria == "switch_perf_report") {
              this.set_history_data("switch_perf_report",resp_data)
             }
             if(criteria == 'disb_perf_report') {
              this.set_history_data("disb_perf_report",resp_data)
             }

             if(criteria=="apply_to_disb_time_chart"){
               this.setState({disbursement_spin_loader:false})
             }
             if(criteria=="repay_to_settle_time_chart"){
              this.setState({repayment_spin_loader:false})
             }
             

             
            }
          
         
        })
    
   
  }
  biz_ops_last_month_handler(type,val){
    if(type=="report_month"){
      this.setState({last_report_in:val})
    } else if(type=="report_days") {
      this.setState({last_days_report_count: Number(val)})
    }

  }
  biz_ops_lastreport_days(){
     this.setState({last_days_and_month_btn:true,start_date_view_btn:false,live_btn:false,today_btn:false, yester_day_btn:false, day_bf_btn:false,this_month_btn:false,last_month_btn:false,loader:true})
    if(this.state.last_days_report_count!=0){
      if(this.state.last_report_in == "Days"){
        this.setState({start_date : moment().subtract(this.state.last_days_report_count, 'days').format('YYYY-MM-DD') },()=>this.biz_ops_View_section_handler())
       
      }else  if(this.state.last_report_in =="Months"){
        this.setState({start_date : moment().subtract(this.state.last_days_report_count, 'months').format('YYYY-MM-DD') },()=>this.biz_ops_View_section_handler())
      }
  
    }
    
  }
  start_date_view_handler(){
    this.setState({start_date_view_btn:true,yester_day_btn:false,live_btn:false,today_btn:false, day_bf_btn:false,this_month_btn:false,last_month_btn:false,loader:true,end_date:this.state.end_date==""? moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD"):this.state.end_date })
     this.biz_ops_View_section_handler()
  }

    render(){
        return(
          <div className='containerTopmargin max-width no-padding' id='convertPDF' style={{backgroundColor:'#1a2035'}}>
            <div style={{padding: window.location.pathname === '/it_operations/print'? '15px' : '0px'}}>
            <h2 className='text-white' style={{paddingLeft:'15px',margin:'-45px 0px 10px 0px'}}>IT Operations</h2>
            <div className='no-padding d-flex justify-content-end biz_ops_export_pdf_bttn'>                                         
                            {window.location.pathname === '/it_operations/print' ?
                                <div>
                                    <button className='btn-std' style={{borderRadius:5,border:'none',backgroundColor:'#fff',marginLeft:'10px'}} onClick={()=>window.print()}>Export to PDF</button>
                                    <Link to={sessionStorage.getItem('path')} className='btn-std' style={{marginLeft:15,borderRadius:5,border:'none',backgroundColor:'#fff'}}>Back</Link>
                                </div>
                                 :
                                <Link className='btn-std' style={{marginLeft:15,borderRadius:5,border:'none',backgroundColor:'#fff'}} to={"/it_operations/print"}>Export To PDF</Link>                                                                    
                            }                            
              </div> 
            <InputSelectionHeader set_attr = {(res)=>this.set_attributes(res)}   incl_prvdr={true} show_currency={false} show_global={false} sh live_dashbaord_reduce_space={true}></InputSelectionHeader>
         
            <div  style={{background:'#182747',padding:'20px'}}>
            {window.location.pathname=="/it_operations/past" || window.location.pathname=="/it_operations/print" && sessionStorage.getItem('path')=="/it_operations/past"?(
            <>
            <div className='row d-flex align-items-center justify-content-center' style={{padding: `${this.state.live_btn ?"0px 28px":"0px 25px" }`}}>
             <div className='col-md-12 no-padding'>
                <button type="button" className={`btn  ${this.state.live_btn ? "business_operations_live_btn business_operations_text yesterday_active_btn " :'btn-outline-info business_operations_dates'} `} onClick={()=>this.biz_operation_liveHandler("live")}>Live</button>
                <button type="button"  className={`btn ${this.state.today_btn ?"business_operations_live_btn business_operations_text yesterday_active_btn ":"btn-outline-info business_operations_dates" }`}  onClick={()=>this.biz_operation_liveHandler("today_btn")} >Today</button> 
                <button type="button" className={`btn ${this.state.yester_day_btn ?"business_operations_live_btn business_operations_text yesterday_active_btn ":"btn-outline-info business_operations_dates" }`}  onClick={()=>this.biz_operation_liveHandler("yesterday")}>Yesterday</button>
                <button type="button" className={`btn ${this.state.day_bf_btn ?"business_operations_live_btn business_operations_text yesterday_active_btn ":"btn-outline-info business_operations_dates" }`}  onClick={()=>this.biz_operation_liveHandler("day_bf_btn")}  >Day b/f y/day</button>
                <button type="button" className={`btn ${this.state.this_month_btn ?"business_operations_live_btn business_operations_text yesterday_active_btn ":"btn-outline-info business_operations_dates" }`}  onClick={()=>this.biz_operation_liveHandler("this_month_btn")} >This month</button>
                <button type="button" className={`btn ${this.state.last_month_btn ?"business_operations_live_btn business_operations_text yesterday_active_btn ":"btn-outline-info business_operations_dates" }`}  onClick={()=>this.biz_operation_liveHandler("last_month_btn")}>Last month</button> 
       
            </div>
            </div>
             <div className={` d-flex justify-content-between align-items-center `} style={{margin:'7px 24px'}}>
            <div className='d-flex justify-content-around align-items-center business_operations_border row m-0 col-md-12 col-lg-7 col-sm-12  no-padding'style={{borderRadius:'0px',background:`${this.state.start_date_view_btn? "#4291da" :"" }`}} >
                <div className='d-flex align-items-center col-5 row px-0 py-2 ' >
                <label className='text-white no-margin col-lg-4 text-end' for='start'>Start Date</label>
                <div className="form-group monthly_report_input col-md-12 col-lg-8 col-sm-12 px-2 py-0 m-0  d-flex align-items-center  churn_report_date_picker" >

                  <label className='col-1.5 no-margin d-flex p-0 ' style={{ position: 'static' }} htmlFor='churn_report_start_date'>
                      <p className='m-0 pr-1'><BiCalendar size={20} /></p>
                      {/* <div className="churn_report_vl pr-2" > <b className="pl-1" style={{ color: 'red' }}>*</b></div> */}
                  </label>

                  <div className='col  px-2 py-1 pr-0 '>
                      
                  <DatePicker closeOnSelect={true} 
                  isValidDate={(current) => {
                  const today = moment().startOf('day');
                  return current.isSameOrBefore(today, 'day');  }} 
                  value={ this.state.start_date? moment(this.state.start_date).format('DD MMM YYYY'):moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD")} 
                   onChange={(value)=>this.biz_ops_dateSection_handler( "start_date", moment(value).format("YYYY-MM-DD"),"to")}
                    inputProps={{ id: 'churn_report_start_date', name: 'Referral_from',autoComplete:'off' }}  dateFormat= { "DD MM YYYY"}
                  showMonthYearPicker timeFormat={false} required="required" onKeyDown={(event) => event.preventDefault()}/>

                  </div>
                </div>

                {/* <input style={{marginLeft:'10px',padding:'3px 15px'}} type="date" id="start" name="trip-start"  value={`${this.state.start_date}`}  onChange={(e)=>this.biz_ops_dateSection_handler("start_date",e.target.value)}></input> */}
                </div>

          
                <div className='d-flex align-items-center col-5 row px-0 py-2 ' >
                <label className='text-white no-margin col-lg-4 text-end'  for='start'>End Date</label>
                <div className="form-group monthly_report_input col-md-12 col-lg-8 col-sm-12 px-2 py-0 m-0  d-flex align-items-center  churn_report_date_picker" >

                  <label className='col-1.5 no-margin d-flex p-0 ' style={{ position: 'static' }} htmlFor='churn_report_start_date'>
                      <p className='m-0 pr-1'><BiCalendar size={20} /></p>
                      {/* <div className="churn_report_vl pr-2" > <b className="pl-1" style={{ color: 'red' }}>*</b></div> */}
                  </label>

                  <div className='col  px-2 py-1 pr-0 '>
                      
                  <DatePicker closeOnSelect={true} 
                  isValidDate={(current) => {
                  const today = moment().startOf('day');
                  return current.isSameOrBefore(today, 'day');  }} 
                  value={ this.state.end_date? moment(this.state.end_date).format('DD MMM YYYY'):moment().subtract(1, 'days').format("YYYY-MM-DD")} 
                   onChange={(value)=>this.biz_ops_dateSection_handler( "end_date", moment(value).format("YYYY-MM-DD"),"to")}
                    inputProps={{ id: 'churn_report_start_date', name: 'Referral_from',autoComplete:'off' }}  dateFormat= { "DD MM YYYY"}
                  showMonthYearPicker timeFormat={false} required="required" onKeyDown={(event) => event.preventDefault()}/>

                  </div>
                </div>

                {/* <input style={{marginLeft:'10px',padding:'3px 15px'}} type="date" id="end" name="trip-start"  value={`${this.state.end_date}`}  min="" max="" onChange={(e)=>this.biz_ops_dateSection_handler("end_date",e.target.value)}></input> */}
                
                </div>

               
                <div className='col-2'>
                <button type="button" class="btn btn-secondary" style={{padding: '7px 28px'}}  onClick={()=>this.start_date_view_handler()} disabled={this.state.start_date !=moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD")? false:true}>View</button>
                </div>
            </div>
            <div className='col-md-12 col-sm-12 col-lg-4 col-xl-3 d-flex justify-content-center no-padding business_operations_border' style={{borderRadius:'0px',background:`${this.state.last_days_and_month_btn ? "#4291da" :""}`}} >
              <div className='d-flex align-items-center' style={{padding:'7px'}}>
                <div>
                  <p className='text-white no-margin business_operations_dates'> Last </p>
                </div>
                <div className='business_operations_dates'>
                  <select name="cars" id="days" style={{padding:'5px'}} onChange={(e)=>this.biz_ops_last_month_handler("report_days" ,e.target.value)}>
                   <option value="0">0</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="30">30</option>
                  </select>

                </div>
                <div className='business_operations_dates'>
                  <select name="cars" id="days" style={{padding:'5px 10px'}} onChange={(e)=>this.biz_ops_last_month_handler("report_month",e.target.value)} >
                    <option value="Days">Days</option>
                    <option value="Months">Months</option>
                    
                  </select>
                </div>
                <div>
                     <button type="button" class="btn btn-secondary btn-sm business_operations_dates" style={{padding:'7px 20px'}} onClick={()=>this.biz_ops_lastreport_days()} disabled={this.state.last_days_report_count !=0 ? false:true}>View</button>
                </div>
              </div>
            </div>
            </div> 
            </>
            ):null}
                {this.state.live_btn &&
              <It_operation_live_results country_code={this.state.country_code } active_acc_prvdr={this.state.gds_report_active_accPrvds}/>
                }
                {this.state.yester_day_btn || this.state.day_bf_btn || this.state.this_month_btn ||  this.state.last_month_btn || this.state.today_btn || this.state.start_date_view_btn || this.state.last_days_and_month_btn ?
                  <It_opertations_yesterday_results  set_attr = {(res)=>this.set_attributes(res)}  data={this.state} report_history= {(a,b,c,d,e,f)=>this.biz_ops_View_section_handler(a,b,c,d,e,f)} />
                :"" } 
               
            </div>
         
            </div>
          </div> 
		)
    }
}

export default withRouter(ItOperation);