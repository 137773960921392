import React,{useState} from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import {get} from '../../../helpers/storage_helper';
import {capitalize_first_letter} from '../../../helpers/common_helper';
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import{rm_reassign,assign_rm_territories,cust_details,temp_assign_details,getSMSDetails,getCusomerCount,getParishwithCustCount}from '../../../actions/relationship_manager_actions';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { dd_value } from '../../../actions/common_actions';
import Select from "react-select";
import { IoClose } from "react-icons/io5";


class  RMReassignment extends FlowComponent{

    constructor(props){
        super(props);
        this.state = {
            by_territory_status:true,
          
            from_rm_id:null,
            to_rm_id: null,
            isActive:false,
            flow_rel_mgr_dd:'',
            filteredArray:[],
            cust_id:null,
            active:'By Territory',
            cust_detls:{},
            cust_id_active:false,
            is_temp_assign:true,
            to_assign_rmName:'',
            temp_assign_rm_id:null,
            customer_data:[],
            tot_cust_count:'',
            new_reassign:false,
            temp_reassign:false,
            loader:false,
            from_assign_rmName:'',
            resp_active_status:false,
            reason_for_reassign : "",
            sms_temp:"",
            reassignmentFilters:["By Parish" , "By District" ,"By Territory" , "By RM" , "By Customer"],
            activeTab: (get('market').country_code=="RWA") ? "By District" :"By Parish",
            selected_items:{},
            showPlaceholder:true
        }; 
    }
  
  
    
        componentWillMount(){
         
            this.load_flow_rel_mgr();
            
        }
        componentDidMount() {
          this.get_parish()

       }

       get_parish(){
        var loader_name = "parish_loader" 
        this.setState({[loader_name]:true})
        var req ={by_parish :true}
        getParishwithCustCount(this.req(req))
        .then((response)=>{
          if(!response){this.setState({loader:false,[loader_name]:false}); return}
          if(response.status=="success"){
            this.setState({[[loader_name]]:false, originalOptions :response.data.parish,filteredOptions: response.data.parish.slice(0, 200) , selected_items:this.state.selected_items ,select_value:this.state.select_value });
          }
        })
       }
       
       get_district(from_rm_id=null){
        var loader_name = from_rm_id ? "district_loader" : "parish_loader" 
        this.setState({[loader_name]:true})
        var req ={}
        if(from_rm_id){
          req.by_district = true
          req.from_rm_id =from_rm_id
        }else{
          req.by_parish = true
        }
        getCusomerCount(this.req(req))
        .then((response)=>{
          if(!response){this.setState({loader:false}); return}
          if(response.status=="success"){
            this.setState({[[loader_name]]:false,total_district:response.data.district , filteredOptions:response.data.district , selected_items:from_rm_id ? {}:this.state.selected_items ,select_value:from_rm_id ? []:this.state.select_value });

          }
        })
       }
  
      
        getSMSDetailhandler(){
            var req = {"reason_for_reassign" : this.state.reason_for_reassign}
            getSMSDetails(this.req({...req}))
            .then((response)=>{
                if(!response){return  };
                this.setState({sms_temp: response.data})
            })
         }
        onchange_ReasonForReassignhandler(e,type)
        {
        if(type=="rm_has_resigned")
        {
            this.setState({reason_for_reassign : type},() => this.getSMSDetailhandler())  
        }
        else
        {
            this.setState({reason_for_reassign : type},() => this.getSMSDetailhandler())  
        }
                 

        }
        onchange_Reassignhandler(e,type,addl_key=true){
            if(type==='from_rm_id'){
                this.state.flow_rel_mgr_dd.filter((item,index)=> item.props.value==e.target.value ? this.setState({from_assign_rmName: item.props.children}):null)
                this.setState({from_rm_id:e.target.value})
                if(addl_key){
                var request = {"from_rm_id":e.target.value,country_code: get('market').country_code, time_zone: get('market').time_zone, status: ""};      
                rm_reassign({...request})
                .then((response)=>{
                    if(!response){return  };
                    let newArr = [...response.data.rm_data]
                     newArr.map((date,index)=>{
                        newArr[index].isActive=false
                    })
                    this.setState({
                        filteredArray:newArr,
                        isActive:false,
                        // from_rm_name:response.data.rm_name,
                        tot_cust_count:response.data.total_cust_count

                    })
                  })

                }else if(this.state.activeTab=="By District"){
                 this.get_district(e.target.value)
                } 
                if(this.state.activeTab=="By Parish"){
                  this.setState({ filteredOptions: this.state.originalOptions ? this.state.originalOptions.slice(0, 100):[]})
                  // this.updateFilteredOptions()
                }
                  
                // });
            }
            else if(type==='from_cust_id'){
                this.setState({cust_id:e.target.value

                })
            }
            else if(type==='temp_assign'){
                
                const filt=this.state.customer_data.filter((each)=>each.rm_name==e.target.value)
                this.setState({temp_assign_rm_id:filt[0].id,from_assign_rmName:filt[0].from_rm_name})

            }
            else if(type==='to_rm_id'){
                this.state.flow_rel_mgr_dd.filter((item,index)=> item.props.value==e.target.value ? this.setState({to_assign_rmName: item.props.children}):null)
              this.setState({to_rm_id:e.target.value})
            }
         
      

        }
      
        item_handler(item,idx,key){

        let selected = [ ...this.state[key] ];
            selected[idx] = {...selected[idx], isActive: !selected[idx].isActive};
            this.setState({
                ...this.state,
                [key]:selected,
                isActive:true
            })
        }

        rm_reassignment(req){
          assign_rm_territories({...req})
          .then((response)=>{
              if(!response){
                  this.setState({loader:false,filteredArray:[]})
                  return 
              };
              if(response.status === "success"){
                  alert(response.message)
                  this.setState({loader:false})
                  window.location.reload()
              }
             
          })
        }
      

        territory_Assign(type){
          if(type=="by_parish" || type=="by_district"){
            if(this.state.to_rm_id){
              if(this.state.selected_items.location && this.state.selected_items.location.length > 0 ){
                if(this.state.from_rm_id != this.state.to_rm_id){
               if (window.confirm("Are you sure to assign this to " + this.state.to_assign_rmName)) {
                  if(this.state.from_rm_id){
                    var key =  (type=="by_parish") ? "parish" :"district"
                   const req = {'from_rm_id':this.state.from_rm_id,'to_rm_id':this.state.to_rm_id,'reason_for_reassign':this.state.reason_for_reassign,'assign_by': key,[key]:this.state.selected_items.location}
                   this.setState({loader:true})
                  this.rm_reassignment(this.req(req))
               }else{alert("please select from assigned RM")}
                  
               }  
              }else{alert("Should not reassign to the same RM")}
              }
              else{alert("please select parish")}          
          }else{alert('please select to assign RM')}
  
          }
            if(type==='by_territory'){
                    let selected =this.state.filteredArray.filter(each=>each.isActive==true)
                    var rm_territories=[]
                   
                   selected.map((item,id)=>{
                    rm_territories.push(item.territory)
                   })
                       if(this.state.to_rm_id){
                       if(rm_territories.length>=1){
                        if (window.confirm("Are you sure to assign all customers from territories " +" ' "+ rm_territories.join(", ")+" ' "+" to " + this.state.to_assign_rmName)) {
                           if(this.state.from_rm_id){
                            const req = {'from_rm_id':this.state.from_rm_id,'to_rm_id':this.state.to_rm_id,'territories':rm_territories,'reason_for_reassign':this.state.reason_for_reassign,time_zone: get('market').time_zone,'assign_by':"territory"}
                            this.setState({loader:true})
                           this.rm_reassignment(req)
                        }else{alert("please select from assigned RM")}
                           
                        }  
                       }
                       else{alert("please select territories")}          
                   }else{alert('please select to assign RM')}
           
            }
            if(type==='by_rm'){
                let by_rm=[]
                this.state.filteredArray.map((data,id)=>{
                    by_rm.push(data.territory)
                })
                // this.setState({by_rm_territories: by_rm})
                if(this.state.from_rm_id){
                if(this.state.to_rm_id){
                        if (window.confirm("Are you sure to assign all customers from territories " +" ' "+ by_rm.join(", ") +" ' "+" to " + this.state.to_assign_rmName )) {
                            const req = {'from_rm_id':this.state.from_rm_id,'to_rm_id':this.state.to_rm_id,'reason_for_reassign':this.state.reason_for_reassign,time_zone: get('market').time_zone,'assign_by':"rm","temporary_assign":this.state.is_temp_assign}
                            this.setState({loader:true})
                            this.rm_reassignment(req)

      
                        } 
                     
                   }
                   else{alert('please select to-assign RM')}
                }
                else{alert('please select from assigned RM')}
          
            }
            if(type==='by_customer'){
                if(this.state.to_rm_id){
                    if(this.state.reason_for_reassign){
                    if (window.confirm("Are you sure to assign "+ this.state.cust_id +" to " +this.state.to_assign_rmName )) {
                        const req = { 'from_rm_id':this.state.cust_detls.rm_id,'cust_id':this.state.cust_id,'to_rm_id':this.state.to_rm_id,'reason_for_reassign':this.state.reason_for_reassign,time_zone: get('market').time_zone,'assign_by':"customer"}
                        this.setState({loader:true})
                        this.rm_reassignment(req)

                    } 
                }
                else{alert("Please select the reason for reassignment")}
            }
            else{alert("Please select to-assign RM")}
              
            }

            if(type==='temp_assign'){
                if(this.state.temp_assign_rm_id){

                    if(this.state.to_rm_id){
                        if (window.confirm("Are you sure to assign  to " +this.state.to_assign_rmName)) {
                            const req = {'from_rm_id':this.state.temp_assign_rm_id,'to_rm_id': parseInt(this.state.to_rm_id),'reason_for_reassign':this.state.reason_for_reassign,time_zone: get('market').time_zone,'assign_by':"temp_assign"}
                            this.setState({loader:true})
                            this.rm_reassignment(req)

                        } 
                    }else{alert("Please Select to-assign RM")}
                    }else{alert('please select temporarily-assigned RM')}
                   
              
            }
      
        }
    
     
        tabCheck = (e) =>{
           
            this.setState({
                ...this.state,
                active:e,
                filteredArray:[],
                cust_id_active:false,
                reason_for_reassign:""
                })
           this.load_flow_rel_mgr()
          if(e=='From Temporary Assignments'){
            this.temp_rmassign()
          }
        }

        cust_id_SubmitHandler(){
            if(this.state.cust_id){
                // this.setState({cust_id_active:true})
                const req = {
                   "borrower_search_criteria": {
                    "mode": "search",
                    "req_parameter": this.state.cust_id,
                    },
                 time_zone: get('market').time_zone,
                 country_code:get('market').country_code
                }
                cust_details({...req})
                .then((response)=>{
                    if(!response){ this.setState({loader:false,filteredArray:[]})
                    return  };
                    if(response.status === "success"){
                   this.setState({cust_detls:response.data,cust_id_active:true, cust_id:response.data.cust_id})}
                })
            }
             else{
                 alert('enter valid Customer ID')
             }

        }
        isPermanent_assign(e){
            this.setState({is_temp_assign:e.target.checked})
        }

        temp_rmassign=()=>{
            const req={time_zone:get('market').time_zone}
            temp_assign_details({...req})
            .then((response)=>{
                if(!response){return  };
                if(response.status === "success"){
                this.setState({customer_data:response.data,resp_active_status:true})}

            })
        }
      
        newReassign=(type)=>{
            this.setState({reason_for_reassign:"", from_assign_rmName:""})
          if(type=='new_reassign'){
              this.setState({new_reassign:true,temp_reassign:false,temp_assign_rm_id:'',to_rm_id:''})

          }
          else{
            this.setState({new_reassign:false,temp_reassign:true,filteredArray:''})
          }
        }


        handlePurposeChange = (selectedOptions, category) => { 
            // Update state with selected values
            this.setState({
              select_value: selectedOptions
            });
          
            // Update selected_items state based on the category
            this.setState(prevState => ({
              selected_items: {
                ...prevState.selected_items,
                [category]: selectedOptions.map(option => option.value),
                ['data_label'] :selectedOptions.map(option => option.label)
              }
            }));
          };

          updateFilteredOptions = (inputValue) => {
            const originalOptions = (this.state.activeTab=="By Parish" )?this.state.originalOptions : this.state.total_district 
            if (inputValue.trim().length > 0) {
                // If there's a search value, filter all options and ensure selected options are included
                this.setState({ filteredOptions: originalOptions.filter(option => option.label.toLowerCase().includes(inputValue.toLowerCase())) });
            } else  {
                // If no search value, limit options to the first 100 and ensure selected options are included
                this.setState({ filteredOptions: originalOptions ? originalOptions.slice(0, 100) :""});
            }
        };
    

          removeParish = (item, idx) => {
            console.log('item,idx', item,idx)
            // Remove item from selected_items state
            this.setState(prevState => {
              const updatedSelectedItems = prevState.selected_items.location.filter((_, index) => index !== idx);
              const updatedSelectedValues = prevState.select_value.filter(option => option.label !== item);
              const data_labels_arr = updatedSelectedValues.map(function(item) {return item.label;});
           
              return {
                selected_items: {
                  ...prevState.selected_items,
                  location: updatedSelectedItems,
                  ['data_label'] :data_labels_arr

                },
                select_value: updatedSelectedValues
              };
            });
          };
          
         
          updateActiveTab=(item)=>{
            this.load_flow_rel_mgr()

            this.setState({from_rm_id:null,new_reassign:false,temp_reassign:false,cust_id_active:false,
              reason_for_reassign:null,
              filteredArray:[], activeTab:item,from_assign_rmName:null,select_value:"",selected_items:{}})

          }

          checkDisable=()=>{
           
            if((this.state.activeTab == "By Parish") || (this.state.activeTab == "By District")){
              if(this.state.selected_items.location && this.state.selected_items.location.length > 0 && this.state.to_rm_id && this.state.reason_for_reassign ){
                return false;
              }else{
                return true;
              }

            }
          }
         
          loader=()=>{
            return (
              <div class="d-flex justify-content-center" style={{marginTop:'9%'}}>
              <div class="spinner-border text-info" role="status">
              <span class="sr-only">Loading...</span>
              </div>
              </div> 
            )
          }
    render(){
      const { select_value, filteredOptions } = this.state;

        return(
           
            <>
            <h2 className='text-white text-center'> RM-Customer Reassignment</h2>
        
           <div className='row mt-5 mx-0 d-flex justify-content-center col-12'>
           <div className=' row mx-0 d-flex justify-content-center align-items-center col-lg-6 col-md-12 col-sm-12 '>

            {this.state.reassignmentFilters.map((item,idx)=>{return(
            
                <div className={`col-lg-4 pointer mb-3 ${(item=="By Parish" && get('market').country_code=="RWA") ? "display_none":""}`} key={idx} onClick={()=>{this.updateActiveTab(item) }}>
                    <div className={`reassignment_filter ${(this.state.activeTab == item) ? "reassignmentTab":""}`}>
                        <p className='text-center mb-0 text-white py-3 px-2'> {item}</p>
                    </div>
                </div>
            )})}
           </div>
          
           </div>
            
            {(this.state.loader) ? this.loader() :
            <div>
            {( this.state.activeTab=="By Parish" || this.state.activeTab=="By District")&& 
            <>{
              (this.state.parish_loader && this.state.activeTab=="By Parish" ) ? 
             this.loader()
              :
            <div className='row m-0  d-flex justify-content-center mx-0'>
                <div className='col-lg-12 mt-5'>
                <div className={this.state.filteredArray.length >0 ?'d-flex justify-content-around':"d-flex justify-content-around reassign_assignFrom_label"}  >
                       

             <div  className ={"form-group col-md-3 form_rm_reassignment" } >
             <label className='text-white' >Assign From</label>
             <select id={ (this.state.activeTab=="By Parish" )?"by_parish":"by_district"} className="form-control"  required="required"  onChange={(e)=>this.onchange_Reassignhandler(e,"from_rm_id",false)} >

                    {this.state.flow_rel_mgr_dd}                     
                </select> 
              </div>
            <div className='col-lg-3 px-0' >
            { <div >
             {(this.state.activeTab=="By Parish" || this.state.activeTab=="By District")  &&  this.state.from_assign_rmName &&
             <>{ this.state.activeTab=="By District" && this.state.district_loader  ? this.loader()
             :
             <div className="col-12 px-0 by_parish_rm_reassignment relative_position">
              <label className='text-white text-center d-flex justify-content-center' style={{fontSize:'15px'}}>Select {(this.state.activeTab=="By Parish") ? "Parish" :"District"} To Reassign</label>
              {this.state.showPlaceholder  && 
                <p className='text-white m-0 place_holder_align'>Choose</p>
                }    
              <Select
                onChange={(selectedOptions) => this.handlePurposeChange(selectedOptions, "location")}
                value={select_value}
                options={(this.state.activeTab=="By Parish") ? filteredOptions:this.state.total_district}
                placeholder={this.state.showPlaceholder ? "" : "Search..."}
                styles={this.select_custom_styles()}
                classNamePrefix="my-select"
                isClearable={true}
                hideSelectedOptions={false}
                isMulti
                onInputChange={(inputValue) => {
                    if (inputValue.trim().length > 0) {
                        this.setState({ showPlaceholder: false });
                    } else {
                        this.setState({ showPlaceholder: true });
                    }
                    this.updateFilteredOptions(inputValue);
                }}
            />
              <div className='mt-5'>
                {this.state.selected_items.data_label && this.state.selected_items.data_label.length > 0 && this.state.selected_items.data_label.map((item, idx) => (
                  <div className='example color' key={idx} style={{cursor: 'pointer', boxShadow: '0px 4px 13px 7px #00000040'}}>{console.log('itemsss', item)}
                    <div className='row mx-0 col-12 px-0 d-flex align-items-center'>
                      <div className='d-flex col-12 px-0 row mx-0 d-flex align-items-center relative_position'>
                        <p className='text-white col-11 d-flex justify-content-center no-margin' style={{paddingTop: '15px'}}>{dd_value(item, "location")}</p>
                        <p className=' col-1 no-margin closeChoosableFields'  onClick={() => this.removeParish(item, idx)}> <IoClose size={"20"} color='black' />
</p>
                      </div>
                    </div>
                    <div>
                      <hr className='hr_reassign' style={{margin: '10px 0px 0px 0px'}}/>
                    </div>
                  </div>
                ))}
              </div>
              </div>
              }
            </>
            }
             </div>
            }
            </div>
            { this.state.from_assign_rmName  ?
              <div  className ={"form-group col-md-3 form_rm_reassignment " }>
              <label   className='text-white'>Assign To</label>
                 <select id="from_acc_id" className="form-control"  required="required"  onChange={(e)=>this.onchange_Reassignhandler(e,"to_rm_id")}  >
                       {this.state.flow_rel_mgr_dd}                          
                 </select>

                 <div className = "form-group ">                
              <label className='text-white' style={{marginTop:'16px'}}>Reason for reassignment</label>
              <div className='d-flex justify-content-center'>
                <div class="form-check">
                <input class="form-check-input form-check-input-reassignment radio_input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" value={this.state.reason_for_reassign} onChange={(e)=>this.onchange_ReasonForReassignhandler(e,"rm_has_resigned")}/>
                <label class="form-check-label" for="flexRadioDefault5" style={{fontSize:'13px'}}>
                {this.state.from_assign_rmName} has resigned
                </label>
                </div>
                <div class="form-check" style={{marginLeft:'20px'}}>
                <input class="form-check-input form-check-input-reassignment radio_input" type="radio" name="flexRadioDefault" id="flexRadioDefault6" value={this.state.reason_for_reassign} onChange={(e)=>this.onchange_ReasonForReassignhandler(e,"other_reason")}/>
                <label class="form-check-label" for="flexRadioDefault6" style={{fontSize:'13px'}}>
                Other reason
                </label>
                </div>
                </div>
                <div>
              <p className='form-group text-white text-center'style={{marginTop:'20px'}}>Note : Customers  WILL BE communicated about this reassignment with an SMS template as below.</p>
              {this.state.reason_for_reassign &&
              <p className='form-group text-white text-justify'>SMS template : <span className='font-italic'>{this.state.sms_temp}</span></p>
                }
              </div>          
                </div>
                  <div className = {`mt-4 ${(this.checkDisable() )? "disable_div":""}`} style={{marginTop:'60px'}}>
                   <button type="button" className="btn btn-info" style={{borderRadius:'10px'}}   onClick={()=>this.territory_Assign(  (this.state.activeTab=="By Parish" )? "by_parish" :"by_district" )}>Assign</button>
                </div>
              </div>:''}
            </div>
                </div>      
            </div>}
            </>
            }

            {this.state.activeTab=="By Territory" && 
            <div>
                 <div className='row justify-content-center'>
                {this.state.loader ? " ":
                <div>
                    {this.state.filteredArray.length>0 ?
                
                <p className='text-white no-margin text-center' style={{fontSize:'19px',padding:'30% 0px 0px 0px'}}>Select Territories to Reassign</p>
                        :''}
                </div>
                }
           </div>
           {this.state.loader ?this.loader()
           : 
            <div className={this.state.filteredArray.length >0 ?'d-flex justify-content-around':"d-flex justify-content-start reassign_assignFrom_label"}  >
             
             <div  className ={this.state.filteredArray.length>0?"form-group col-md-3 form_rm_reassignment" :'form-group col-md-4 reassign_territory_form '} >
             <label className='text-white' style={{marginTop:'16px'}}>Assign From</label>
                  <select id="from_acc_id" className="form-control"    required="required"  onChange={(e)=>this.onchange_Reassignhandler(e,"from_rm_id")} >
                      {this.state.flow_rel_mgr_dd}                     
                  </select>

                  
             
            </div>
            <div >
            {
               this.state.filteredArray.length>0?  
               <div className='rm_territories' >
               {this.state.filteredArray.map((item,idx)=>{return (
           <div className='example color'  key={idx}  style={{backgroundColor: item.isActive && '#49aebe',cursor:'pointer',boxShadow:' 0 1rem 3rem rgba(0, 0, 0, 0.175) '}}  onClick={ this.state.by_territory_status ? ()=>this.item_handler(item,idx,'filteredArray') : ''} >
           <div className='d-flex  justify-content-around align-items-center '  >   
           <p className='text-white no-margin' style={{paddingTop:'15px'}}>{capitalize_first_letter(item.territory)} ( {item.ter_cust_count} )</p>    
           {/* <p className='text-white no-margin ' style={{paddingTop:'15px'}}>{item.rm_name}</p>  */}
       
           </div>
           <div>
           <hr className='hr_reassign'  style={{margin:'10px 0px 0px 0px'}}/>
           </div>
           </div>
             )})}
           
           </div>:''
            }
            </div>
            {this.state.filteredArray.length>0 ?
              <div  className ={this.state.filteredArray.length>0?"form-group col-md-3 form_rm_reassignment " :'form-group col-md-4 reassign_territory_form '}>
              <label  style={{margin:'8% 0px 5px 0px'}}  className='text-white'>Assign To</label>
                 <select id="from_acc_id" className="form-control"  required="required"  onChange={(e)=>this.onchange_Reassignhandler(e,"to_rm_id")}  >
                       {this.state.flow_rel_mgr_dd}                          
                 </select>

                 <div className = "form-group ">                
              <label className='text-white' style={{marginTop:'16px'}}>Reason for reassignment</label>
              <div className='d-flex justify-content-center'>
                <div class="form-check">
                <input class="form-check-input form-check-input-reassignment radio_input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" value={this.state.reason_for_reassign} onChange={(e)=>this.onchange_ReasonForReassignhandler(e,"rm_has_resigned")}/>
                <label class="form-check-label" for="flexRadioDefault5" style={{fontSize:'13px'}}>
                {this.state.from_assign_rmName} has resigned
                </label>
                </div>
                <div class="form-check" style={{marginLeft:'20px'}}>
                <input class="form-check-input form-check-input-reassignment radio_input" type="radio" name="flexRadioDefault" id="flexRadioDefault6" value={this.state.reason_for_reassign} onChange={(e)=>this.onchange_ReasonForReassignhandler(e,"other_reason")}/>
                <label class="form-check-label" for="flexRadioDefault6" style={{fontSize:'13px'}}>
                Other reason
                </label>
                </div>
                </div>
                <div>
              <p className='form-group text-white text-center'style={{marginTop:'20px'}}>Note : Customers  WILL BE communicated about this reassignment with an SMS template as below.</p>
              {this.state.reason_for_reassign &&
              <p className='form-group text-white text-justify'>SMS template : <span className='font-italic'>{this.state.sms_temp}</span></p>
                }
              </div>          
                </div>
                  <div className = {`mt-4 ${this.state.reason_for_reassign ? "":"disable_div"}`} style={{marginTop:'60px'}}>
                   <button type="button" className={`btn btn-info ${this.state.reason_for_reassign ? "":"disable_div"}`} style={{borderRadius:'10px'}}  disabled={!this.state.isActive } onClick={()=>this.territory_Assign("by_territory")}>Assign</button>
                </div>
            </div>:''}
         
           
          
          
            </div>}
            </div>
            }

            {this.state.activeTab == "By RM" && 
            <div>

                <div className='d-flex justify-content-center' style={{marginTop:'61px'}}>

                <div class="form-check px-2 d-flex align-items-center">
                <input class="form-check-input form-check-input-reassignment pl-0 radio_input" type="radio" name="by_rm_lables" id="flexRadioDefault1" onChange={()=>this.newReassign("new_reassign")} />
                <label class="form-check-label pl-0" for="flexRadioDefault1" style={{fontSize:'16px'}}>
                    New Reassignment
                </label>
                </div>
                <div class="form-check px-0 d-flex align-items-center" style={{marginLeft:'20px'}}>
                <input class="form-check-input form-check-input-reassignment pl-0 radio_input" type="radio" name="by_rm_lables" id="flexRadioDefault2" onChange={()=>this.newReassign("temp_reassign")} onClick={()=>this.temp_rmassign()} />
                <label class="form-check-label pl-0" for="flexRadioDefault2" style={{fontSize:'16px'}}>
                From Temporary Assignments
                </label>
                </div>
                </div>
                {this.state.loader ? this.loader():
                <>
                {this.state.new_reassign && <>
                <div className={this.state.filteredArray.length>0?'d-flex justify-content-around ':'d-flex justify-content-start reassign_assignFrom_label'} style={{marginTop:this.state.filteredArray.length>0
                ? '3%' :'-45px'}}>
                <div className ={this.state.filteredArray.length>0?"form-group col-md-3 form_rm_reassignment " :'form-group col-md-4 reassign_territory_form '} >
                <label style={{marginTop:'32px'}} className='text-white'>Assign From</label>
                <select id="from_acc_id" className="form-control"    required="required"  onChange={(e)=>this.onchange_Reassignhandler(e,"from_rm_id")} >

                    {this.state.flow_rel_mgr_dd}    
                                
                </select>
                </div>

                <div>
                
                    {this.state.filteredArray.length>0?
                    <>
                <p className='text-white no-margin text-center' style={{fontSize:'14px'}}>{this.state.from_assign_rmName}'s Territories</p>
                <div className='rm_territories' >
                {this.state.filteredArray.map((item,idx)=>{return (
                <div className='example color'  key={idx}  style={{backgroundColor: item.isActive && '#49aebe',boxShadow:' 0 1rem 3rem rgba(0, 0, 0, 0.175) '}}   >
                <div className='d-flex  justify-content-around align-items-center '  >   
                <p className='text-white no-margin' style={{paddingTop:'15px'}}>{capitalize_first_letter(item.territory)} ({item.ter_cust_count})</p>    


                </div>
                <div>
                <hr className='hr_reassign'  style={{margin:'10px 0px 0px 0px'}}/>
                </div>
                </div>
                )})}


                </div>
                </>:'' }
                {this.state.filteredArray.length>0?<h3 className='text-white text-center'>Total Customers : {this.state.tot_cust_count}</h3>:''}

                </div>
                {this.state.filteredArray.length>0?
                <div className ={this.state.filteredArray.length>0?"form-group col-md-3 form_rm_reassignment " :'form-group col-md-4 reassign_territory_form '}>
                <label  style={{margin:'30px 0px 5px 0px'}}>Assign To</label>
                <select id="from_acc_id" className="form-control"  required="required"  onChange={(e)=>this.onchange_Reassignhandler(e,"to_rm_id")}  >
                    {/* <option>Assign To</option> */}
                    {this.state.flow_rel_mgr_dd}                          
                </select>
                {this.state.filteredArray.length>0 &&
                <div class="form-check">
                    <input class="form-check-input reassign_by_rm" type="checkbox" value="" id="by_rm" onChange={(e)=>this.isPermanent_assign(e)}  defaultChecked={this.state.is_temp_assign}  />
                    <label class="form-check-label" for="by_rm" style={{fontSize:'12px',opacity:'0.8'}}>
                    Assign Temporarily
                    </label>                  

                    <div className = "form-group ">      
                <label className='text-white' style={{marginTop:'16px'}}>Reason for reassignment</label>
                <div className='d-flex justify-content-center'>
                <div class="form-check">
                <input class="form-check-input form-check-input-reassignment reassign_by_rm radio_input" type="radio" name="flexRadiobutton" id="flexRadioDefault7" value={this.state.reason_for_reassign} onChange={(e)=>this.onchange_ReasonForReassignhandler(e,"rm_has_resigned")}/>
                <label class="form-check-label" for="flexRadioDefault7" style={{fontSize:'13px'}}>
                {this.state.from_assign_rmName} has resigned
                </label>
                </div>
                <div class="form-check" style={{marginLeft:'20px'}}>
                <input class="form-check-input form-check-input-reassignment reassign_by_rm radio_input" type="radio" name="flexRadiobutton" id="flexRadioDefault8" value={this.state.reason_for_reassign} onChange={(e)=>this.onchange_ReasonForReassignhandler(e,"other_reason")}/>
                <label class="form-check-label" for="flexRadioDefault8" style={{fontSize:'13px'}}>
                Other reason
                </label>
                </div>
                </div>

                <div>
                <p className='text-white text-center'  style={{marginTop:'20px'}}>Note : Customers <b style={{color:this.state.is_temp_assign?'red':"white"}}>{this.state.is_temp_assign ? "WILL NOT BE" : "WILL BE"}</b> communicated about this reassignment{this.state.is_temp_assign ? "." : " with a SMS template as below."}</p>
                {!this.state.is_temp_assign && this.state.reason_for_reassign &&
                <p className='form-group text-white text-justify'>SMS template : <span className='font-italic'>{this.state.sms_temp}</span></p>
                }
                </div>          
                </div>
                    </div>
                }
                <div style={{marginTop:'20px'}}>
                <button type="button" className={`btn btn-info rm_territory_assign_btn ${this.state.reason_for_reassign ? "":"disable_div"}`} style={{borderRadius:'10px'}}  onClick={()=>this.territory_Assign("by_rm")}>Assign</button>
                </div> 
                </div> : ''}


                </div>
                </> }
                </>
                }



                {this.state.temp_reassign &&
                <> 
                {this.state.customer_data.length>0 && this.state.resp_active_status &&

                <div className='d-flex justify-content-center'>
                <div className ={'form-group col-md-4 '}  style={{marginTop:'25px'}}>

                <select id="from_acc_id" className="form-control"    required="required"  style={{marginTop:'35px'}} onChange={(e)=>this.onchange_Reassignhandler(e,"temp_assign")}  >
                    
                    <option>Temporary Assigned RM</option>
                    {this.state.customer_data.map((item,key)=>{
                        return(
                            <>
                        <option> {item.rm_name}</option> 
                            </>
                        )
                    })}                     
                </select>
                

                <select id="from_acc_id" className="form-control"  required="required"  style={{margin:'30px 0px 5px 0px'}} onChange={(e)=>this.onchange_Reassignhandler(e,"to_rm_id")}  >
                    <option>Assign To</option>
                    {this.state.flow_rel_mgr_dd}                          
                </select>
                {this.state.from_assign_rmName &&
                <div className = "form-group ">                
                <label className='text-white' style={{marginTop:'16px'}}>Reason for reassignment</label>
                <div className='d-flex justify-content-center'>
                <div class="form-check">
                <input class="form-check-input form-check-input-reassignment radio_input" type="radio" name="flexRadioDefault" id="flexRadioDefault9" value={this.state.reason_for_reassign} onChange={(e)=>this.onchange_ReasonForReassignhandler(e,"rm_has_resigned")}/>
                <label class="form-check-label" for="flexRadioDefault9" style={{fontSize:'13px'}}>
                {this.state.from_assign_rmName} has resigned
                </label>
                </div>
                <div class="form-check" style={{marginLeft:'20px'}}>
                <input class="form-check-input form-check-input-reassignment radio_input" type="radio" name="flexRadioDefault" id="flexRadioDefault10" value={this.state.reason_for_reassign} onChange={(e)=>this.onchange_ReasonForReassignhandler(e,"other_reason")}/>
                <label class="form-check-label" for="flexRadioDefault10" style={{fontSize:'13px'}}>
                Other reason
                </label>
                </div>
                </div>
                <div>
                <p className='form-group text-white text-center' style={{marginTop:'20px'}}>Note : Customers  WILL BE communicated about this reassignment with an SMS template as below.</p>
                {this.state.reason_for_reassign &&
                <p className='form-group text-white text-justify'>SMS template : <span className='font-italic'>{this.state.sms_temp}</span></p>
                }
                </div>          
                </div>
                }
                {/* <div style={{marginTop:'30px'}}> */}
                <div  className='d-flex justify-content-around' style={{alignItems : "center", maxHeight : "140px"}}> 
                <button type="button" className= {`btn btn-info text-center  ${this.state.reason_for_reassign ? "":"disable_div"}`} style={{borderRadius:'10px'}}   onClick={()=>this.territory_Assign("temp_assign")}>Assign</button>

                </div>

                </div>
                </div>
                }     
                { this.state.resp_active_status && this.state.customer_data.length<=0 &&
                <div className='d-flex justify-content-center'>
                <h2 className='text-white' style={{marginTop:'50px'}}>Currently there is no temporary RM assignment.</h2>
                </div>
                } 

                </>
                }


            </div>
            }

           {this.state.activeTab == "By Customer" && 
             <div>
                  {/* <hr className='tab_tittle_hr cmn-t-underline' style={{width:'146px',marginLeft:'77%',border:'0.3px solid #22cbed'}}/> */}
            <div className='d-flex justify-content-center align-items-center' style={{marginTop:'50px'}}>
              <div className="col-md-5">
                        <div className="form-group d-flex" >
                          <label className="md-label-floating" style={{fontSize:'12px'}}>Cust Name / Cust ID / Account Num / Mobile No / National ID</label>
                          <input type="text"   className="form-control"  onChange={(e)=>this.onchange_Reassignhandler(e,"from_cust_id")} />
                          <button type="button" class="btn btn-primary btn-sm" style={{marginLeft:'10px'}} onClick={()=>this.cust_id_SubmitHandler()}>Search</button>
                        </div>
                </div>
             
                

              
           </div>
           {this.state.loader ? this.loader()  :
           <>
           {this.state.cust_id_active &&
              <div className='container'>
                 {this.state.cust_detls ?  
                 <>
              <div  className='reassign_cust_details' style={{background:'#202940',margin:'20px 85px'}}>
               <h2 className='text-white' style={{background:'#01062c',padding:'10px'}}>Customer Details</h2>
             
               <div className='d-flex justify-content-around' style={{padding:'25px 0px'}}>
                <p className='text-white no-margin'>Biz Name : {this.state.cust_detls.biz_name}</p>
                <p className='text-white  no-margin'>Territory : {this.state.cust_detls.territory}</p>
                <p className='text-white  no-margin'>RM Name : {this.state.cust_detls.rm_name}</p>
               </div>
              </div>
             
              
              <div className='d-flex justify-content-center'>
              <div className = "form-group col-5">
              <label className='text-white' style={{marginTop:'16px'}}>Assign To</label>

                <select id="from_acc_id" className="form-control" required="required"  onChange={(e)=>this.onchange_Reassignhandler(e,"to_rm_id")} >
                      {this.state.flow_rel_mgr_dd}                     
                  </select> 
               </div>  
               </div>           
                           
              <div className='d-flex justify-content-center'>
              <label className='text-white text-center' style={{marginTop:'16px'}}>Reason for reassignment :  </label>
                <div class="form-check">
                <input class="form-check-input form-check-input-reassignment radio_input" style={{marginLeft:'20px' ,marginTop:'13px'}} type="radio" name="flexRadioDefault" id="flexRadioDefault11" value={this.state.reason_for_reassign} onChange={(e)=>this.onchange_ReasonForReassignhandler(e,"rm_has_resigned")}/>
                <label class="form-check-label" for="flexRadioDefault11" style={{marginLeft:'20px',marginTop:'16px',fontSize:'13px'}}>
                {this.state.cust_detls.rm_name} has resigned
                </label>
                </div>
                <div class="form-check" style={{marginLeft:'20px'}}>
                <input class="form-check-input form-check-input-reassignment radio_input" style={{marginTop:'13px'}} type="radio" name="flexRadioDefault" id="flexRadioDefault12" value={this.state.reason_for_reassign} onChange={(e)=>this.onchange_ReasonForReassignhandler(e,"other_reason")}/>
                <label class="form-check-label" for="flexRadioDefault12" style={{marginTop:'16px',fontSize:'13px'}}>
                Other reason
                </label>
                </div>
                </div>
                <div className=' d-flex justify-content-center' style={{marginTop:'20px'}}>
              <p className='form-group text-white text-center col-5'>Note : Customers  WILL BE communicated about this reassignment with an SMS template as below.</p>
              </div>
              <div className=' d-flex justify-content-center'>
              {this.state.reason_for_reassign &&
              <p className='form-group text-white text-justify col-5'>SMS template : <span className='font-italic'>{this.state.sms_temp}</span></p>
                }
              </div>          
                
                  <div  className='d-flex justify-content-around' style={{alignItems : "center", maxHeight : "140px"}}>            
                  <button type="button" className="btn btn-info text-center" style={{borderRadius:'10px'}}   onClick={()=>this.territory_Assign("by_customer")}>Assign</button>      
                  </div>  
             
                 </> 
              :<div className='text-white text-center'>Enter Valid custmerID </div>   
            }
            </div>
           }
          </> }
             </div>   
            }
          
            </div>
        }
           </>
            );
           
        }
    }

export default RMReassignment ;