import React from 'react';
import '../../../styles/flow.css';
import {Modal,Button } from 'react-bootstrap';
import{getParFasList}from "../../../actions/report_actions";
import {get} from "../../../helpers/storage_helper";
import {getCurrencyDetails} from "../../../actions/report_actions";
import { Link} from 'react-router-dom';
import { BasicReactTable } from '../../../helpers/react_table'

import FlowComponent from '../../common/component/core/FlowComponent';
import CommonReactTable from '../../../helpers/commonReact_table';

export default class ViewParFas extends FlowComponent{

    state ={
        modalBox : false,
        rowdatas:[],
        columndatas:[], 
        copy:[],
        currencyDetails:{},
        currencyCode:"",
        currencyValue:"",
        loader : false       
    }

    componentDidMount(){
  
       
    }
   
    handleModelOpen =async()=>{
        this.setState({ modalBox: true });

        this.setState({loader:true})
        await getParFasList({"country_code":this.props.country_code, "acc_prvdr_code":this.props.lender == null ? this.props.acc_prvdr_code : null, "par_day":this.props.parday, "date":this.props.date ,"time_zone":get('market').time_zone,"lender" : this.props.lender})
        .then((response)=>{
            var currency_value= this.props.state_data.country_code=="*" ?this.props.state_data.activeCurrencyVal:null
            var currency_code= this.props.state_data.country_code=="*" ?this.props.state_data.activeCurrency: (this.props.state_data.country_code=="UGA" ? "UGX":"RWF")
            this.setState({loader:false})
            if(!response){return }
            if(response.status=="success")
            { 
             
                this.setState({rowdatas:response.data,copy : response.data,columndatas:this.columndatas(),par_fa: this.convertExportCsvDatas(response.data, currency_value,currency_code,["par_loan_principal"]),
                header_props:this.convert_header_props(this.columndatas(),this.props.state_data.activeCurrency,["par_loan_principal"])})
				
           }
        })
    }

    handleModalClose = (event) => {
      this.setState({ modalBox: false });
    }
    amount_conversion = (amount,country_code)=>{
        if(country_code == "UGA" && this.props.country_code == "*"){

            const curren = this.props.state_data.currencyDetails[country_code].forex_rates["USD"]
            return  (Math.round(amount * Number(curren))+ " " + " "+"USD");

        }else if(country_code == "RWA" && this.props.country_code == "*"){

            const curren = this.props.state_data.currencyDetails[country_code].forex_rates["USD"]
            return  (Math.round(amount * Number(curren))+ " " + " "+"USD");

        }else if(this.props.country_code == country_code){
            if(country_code == "UGA"){
                return  (amount.toLocaleString()+" "+" "+"UGX")
            }else if(country_code == "RWA"){
                return  (amount.toLocaleString()+" "+" "+"RWF")
            }
        }
        
    }

    columndatas(){
        return[
            {
                Header : <div className='text-center'>SI No</div>,
                accessor: "SI No", 
                width:100,               
                Cell : cell =>{return <div className='text-center'>{cell.row.index+1}</div>}
            },
            {
                Header : <div className='text-center'>FA ID</div>,
                accessor: "loan_doc_id",
                Cell: cell => {return <div className='text-center'><Link to={"/fa/view/"+cell.row.original.loan_doc_id} target="_blank" className="on_going">{cell.row.original.loan_doc_id}</Link></div>}         
            },
            {
                Header : <div className='text-center'>PAR Amount</div>,
                accessor: "par_loan_principal",
                width:150,
                Cell:  cell => {return <><div className='text-right mr-5'>{this.amount_conversion(cell.row.original.par_loan_principal,cell.row.original.country_code)}</div></>}      
               
            }
        ]
    }


    render(){


		return(

		<div className="" style={{'padding-left':'0px'}} >
		    <div className="pull">   
                <button type="button" class="btn btn-primary" onClick={this.handleModelOpen} >View</button>	
	        </div>
	    
		   <Modal show={this.state.modalBox} onHide={this.handleModalClose} size="lg">
             	<Modal.Header closeButton show={this.state.modalBox} onHide={this.handleModalClose} >
                 	 <Modal.Title><h4>FAs List</h4></Modal.Title>
              	</Modal.Header>
              	<Modal.Body>
                  <div>
		            
					 <div className='d-flex justify-content-between align-items-center' style={{padding:5}}>
						<p className='no-margin text-white'><b>Total Entries : {this.state.copy.length}</b></p>
						
					</div>
                    {this.state.loader ? <>
                    <p className='text-light mt-5 text-center' >Loading...</p>
                    <div className="product_takeup_loader "></div></>
                    :
                    <BasicReactTable className = 'border' row_data={this.state.copy !== undefined ? this.state.copy : []} export_csv_datas={this.state.par_fa}  column_data={this.state.columndatas} csv_file={true} FName="PARfasList" default_page_size={10} pagination={true}    global_search={false}/> 

					// <CommonReactTable showPag={true} minRow={0} row_data={} column_data={this.state.columndatas} resizable={false} defaultSorted={[{id:"par_loan_principal",desc:false}]} exportCSV={true} export_csv_datas={this.state.portfolio_csv_data}   csvFile={"PARfasList"} csvData={this.state.copy}/>
                  }                                       
		        </div>
             	</Modal.Body>
             	<Modal.Footer>
                	<Button variant="secondary" onClick={this.handleModalClose}>Close</Button>
              	</Modal.Footer>
          	</Modal>
		

		    </div>

			);
		}
	
}