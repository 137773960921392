import React, { useEffect, useState } from "react";

const ShowCalledTime = ({CallDialedTime}) => {

    const [dialedTime, setDialedTime] = useState(null)


    const showDailedTime = (CallDialedTime) => {
        setDialedTime( () => {
            const options = { timeZone: 'Africa/Kampala' };

            const currentDateTime = new Date().toLocaleString('en-US', options);
            const currentTime = new Date(currentDateTime);

            const endDateTime = new Date(CallDialedTime);
            const endTimeInKampala = new Date(endDateTime);

            const diffInMilliseconds = currentTime - endTimeInKampala;
            const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));


            if (diffInMinutes >= 60) {
                const hours = Math.floor(diffInMinutes / 60);
                return hours > 1 ? `${hours} hours ago` : `${hours} hour ago`;
            } else {
                return diffInMinutes >= 1 ? `${diffInMinutes} min ago` : `just now`;
            }
        })
        
    }

    useEffect(() => {
        showDailedTime(CallDialedTime); 
        const intervalId = setInterval(() => showDailedTime(CallDialedTime), 60000);
    
        // Clear the interval when the component is unmounted or navigated away
        return () => clearInterval(intervalId);
      }, []); 
    
    return (
        <p>{dialedTime}</p>
    )
}

export default ShowCalledTime;