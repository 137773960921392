import {call_fetch, admin_path} from './../helpers/fetch_helper';
import { get } from '../helpers/storage_helper';
import { get_path } from '../helpers/common_helper';



export const approveKYC = request => {

    return call_fetch(get_path('kyc') + '/audit_kyc/cust_reg', request);
}

export const updateLeadStatus = request => {

    return call_fetch(get_path('kyc') + '/audit_kyc/update', request);
}

export const auditName = request => {

    return call_fetch(get_path('kyc') + '/audit_kyc/audit_name', request);
}

export const bypassAuditName = request => {

    return call_fetch(get_path('kyc') + '/audit_kyc/bypass_holder_name_audit', request);
}

export const submit_holder_name_proof = request => {

    return call_fetch(get_path('kyc') + '/audit_kyc/holder_name_proof', request);
}

export const rejectKYC = request => {

    return call_fetch(get_path('kyc') + '/audit_kyc/reject', request);
}

