import {call_fetch, admin_path, app_path} from './../helpers/fetch_helper';
import { get } from '../helpers/storage_helper';
import { get_path } from '../helpers/common_helper';

let kyc_path = get("country_code") == "RWA" ? process.env.REACT_APP_RWA_KYC_APP_API_ROOT : process.env.REACT_APP_UGA_KYC_APP_API_ROOT

export const createBorrower = request  => {
   return call_fetch(get_path("kyc")  + '/borrower', request);
} 

export const viewBorrower = request  => {
   return call_fetch(get_path("kyc")  + '/borrower/view', request);
}

/*export const updateBorrower = request  => {
   return call_fetch(admin_path + '/borrower/update', request);
}*/
export const borrowerSearch = request  => {
   return call_fetch(get_path("kyc")  + '/borrower/search', request);
}

// export const additionalApproval = request  => {
//    return call_fetch(admin_path + '/borrower/additional_approval', request);
// }

export const bring_to_probation = request  => {
   return call_fetch(get_path("admin") + '/borrower/bring_to_probation', request);
}

export const updateStatus = request  => {
   return call_fetch(get_path("admin") + '/borrower/update_status', request);
}
export const validateCustomer = request  => {
    return call_fetch(get_path("kyc")  + '/borrower/validate', request);
}

export const getCustProfile = request  => {
    return call_fetch(get_path("kyc")  + '/borrower/profile', request);
}

export const closeProfile = request  => {
   return call_fetch(get_path("kyc")  + '/borrower/close', request);
}

export const allowManualCapture = request  => {
   return call_fetch(get_path("admin") + '/borrower/manual_capture', request);
}

export const allowCustAppAccess = request => {
   return call_fetch(get_path("admin") + '/borrower/app_access', request);
}
export const thirdparty_details=request=>{
   return call_fetch(get_path("admin") + '/borrower/third_party_details',request);
}
export const raised_complaints=request=>{
   return call_fetch(get_path("admin") + '/borrower/list_complaints',request);
}
export const resolved_complaints=request=>{
   return call_fetch(get_path("admin") + '/borrower/resolved_complaints',request);
}
export const view_cust_complaints=request=>{
   return call_fetch(get_path("admin") + '/borrower/view_cust_complaint',request);
}
export const view_gps=request=>{
   return call_fetch(get_path("kyc")  + '/borrower/view_gps',request);
}

export const initiateRekyc=request=>{
   return call_fetch(get_path("admin") + '/borrower/re_kyc',request);
}

export const customerLocations=request=>{
   return call_fetch(get_path("kyc")  +'/borrower/cust_locations',request);
}
export const flagging_profile=request=>{
   return call_fetch(get_path("kyc") + '/borrower/flagging/flag_profile',request);
}
export const profileUnFlagged=request=>{
   return call_fetch(get_path("kyc") + '/borrower/flagging/un_flag',request);
}



export const createOverdueCustpdf = request => {
   return call_fetch(get_path("admin")  + '/borrower/overdue_cust/pdf', request)
}

export const updateShopType = request => {
   return call_fetch(get_path("kyc")  +'/borrower/update_shop_type',request);
}


export const addWatchLIst =request =>{
   return call_fetch(get_path("kyc")  + '/borrower/cust_watchlist', request)

}

export const viewWatchHistory = request =>{
   return call_fetch(get_path("kyc")  + '/borrower/view_history', request)

}
export const unWatchProfile = request =>{
   return call_fetch(get_path("kyc")  + '/borrower/cust_unwatch', request)

}

export const watchlistReport = request =>{
   return call_fetch(get_path("kyc")  + '/borrower/watchlist_report', request)

}



export const flaggedCustomers = request => {
   return call_fetch(get_path("kyc") + '/borrower/flagging/customers_list', request)
}


export const softEnable = request => {
   return call_fetch(get_path("kyc") + '/borrower/flagging/soft_enable', request)
}

export const get_cust_switch_details = request => {
   return call_fetch(get_path("admin") + '/sales/get_cust_switch', request)
}
export const sendReferralSMS = request  => {
   return call_fetch(get_path("admin") + '/borrower/send_referral_sms', request);
}

export const profileEditHistory = request  => {
   return call_fetch(get_path("kyc")  + '/borrower/show_profile_history', request);
}



// update_status


