import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import {Alert, Row, Col } from 'react-bootstrap';
import '../../../styles/flow.css';
import {capitalize_first_letter, getValueFromLangJson, get_path} from '../../../helpers/common_helper';
import {dd_value} from '../../../actions/common_actions';
import GoogleMapSetup from '../../../helpers/googleMap';

class AddressPanel extends FlowComponent {

	render(){
		return(					
			<>
			<div variant="" className="floatAdvancetitleBox" style={{backgroundColor:"#202940"}}>
				<div className="floatAdvanceperiodHeaderBox">
					<h2	 className={"floatAdvanceperiodHeader padL_15 no-margin"}>{this.props.title}</h2>
				</div>
				<div className="row no-margin" style={{paddingBottom:15}}>					
						{
						Object.entries(this.props.data).map(([key, value])=> {						
				    	return(
				    	key != "id"	&&
							<div key={key} className="col-md-3">
								{key === "gps" ? 
									<div>
										<p className='no-margin viewCus_label'>{dd_value(key,'address_fields')}</p>
										<p className='no-margin viewCus_labelVal' data-toggle="modal" data-target="#exampleModal" style={{cursor:'pointer'}}>{capitalize_first_letter(value)}</p>
										<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
											<div class="modal-dialog" role="document">
												<div class="modal-content">
													<div class="modal-header">
														<h5 class="modal-title" id="exampleModalLabel">Map View</h5>
														<button type="button" class="close" data-dismiss="modal" aria-label="Close">
														<span aria-hidden="true">&times;</span>
														</button>
													</div>  
													<div class="modal-body">
														<GoogleMapSetup coordinates={value}/>
													</div>                                                                                                          
												</div>
											</div>
										</div>
									</div>
									:
									<div>
										<p className='no-margin viewCus_label'>{dd_value(key,'address_fields')}</p>
										<p className='no-margin viewCus_labelVal'>{capitalize_first_letter(value)}</p>
									</div>
								}								
							</div>						
				    	);})
    					}
					{this.props.data.photo_shop_path &&
					<div className='col-md-3'>
							<b>{getValueFromLangJson("establishment_photo")}</b>
							<img src={get_path("view_file") + this.props.data.photo_shop_path + "/s_" + this.props.data.photo_shop} 
							alt={this.props.data.photo_shop_path + "/s_" + this.props.data.photo_shop}
							/>
					</div>
					}
				</div>	
			</div>
			</>
			);
	}
}
export default AddressPanel;
