import React from "react";
import {call_await_fetch, app_path} from '../../../helpers/fetch_helper';
import {get} from '../../../helpers/storage_helper';

function SessionsRow(props){
    const market = get('market')

    async function logout() {
        const response = await call_await_fetch(app_path + '/whatsapp/logout', {country_code : market.country_code, time_zone : market.time_zone, session : props.mobile_num});
        props.deleteSession(props.id)
    }

    return(
        <tr className='whatsapp-tr'>
            <td className="whatsapp-border whatsapp-padding">{props.mobile_num}</td>
            <td className="whatsapp-border whatsapp-padding">{props.userId}</td>
            <td className="whatsapp-border whatsapp-padding"><button className='logoutBtn' onClick={logout}>Logout</button></td>
        </tr>
    )
}

export default SessionsRow;