import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import {format_date} from '../../../helpers/common_helper';
import DatePicker from 'react-datetime';
import {Edit} from '../../common/partial';

class Organisation extends FlowComponent {
   state = {
    org: null,
    comp_name: "org",
    dropdown_div_style: "disable_div"
   
    /*name: [],
    inc_name: [],
    inc_number: [],
    inc_date: [],
    tax_id: []*/
  }

   componentWillMount(){
    super.componentWillMount();
    
  }



  render(){
    return (

      <div id=""  className='no-padding-input'>

          <div className="row mt-4">

           <div className="col-md-4"><h4>{this.props.title ? this.props.title : "Organisation Details"}</h4></div>

          <div className={`col-md-1 ${this.state.edit_button_style}`} >
            <Edit entity={this.props.entity} onClick={this.handleEdit}/>
            {/*<input type="button" onClick={this.handleEdit} value="Edit"/>*/}
          </div>
          <div className={`col-md-1 ${this.state.save_button_style}`}>
            <input onClick={this.handleSave} type="button" className="btn btn-success" value="Save"/>
          </div>
       </div>
        <div className={this.state.div_style}>
          <div className="row" >
              <div className="col-md-3">
                <span>General Name</span><font className="text-danger">   *  </font>
                <input id="name"  value={this.props.data.name}   onChange={this.toUpperHandleChange} onKeyUp={this.handleMakeCopy("inc_name")} className="form-control" type="text" required="required"/>
              </div>
              <div className="col-md-3">
                <span>Name of Incorporation</span>
                <input id="inc_name"   value={this.props.data.inc_name}   onChange={this.handleChange} className="form-control" type="text"  />
              </div>
              <div className="col-md-3">
                <span>Incorporation Number</span>
                <input id="inc_number"  value={this.props.data.inc_number}   onChange={this.handleChange} className="form-control" type="text"  />
              </div>  
              <div className="col-md-3">
                <span>Date of Incorporation</span>

                  <DatePicker  value={format_date(this.props.data.inc_date)} closeOnSelect={true} onChange={(value) => {this.handleDateChange("inc_date", value)}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>

               
              </div>          
            </div><br/>
            <div className="row">
                <div className="col-md-3">
                  <span>TAX Identifier</span>
                  <input id="tax_id"  value={this.props.data.tax_id}   onChange={this.handleChange} className="form-control" type="text" />
                </div>
            </div>
          </div>  
        </div>

    );
  }
}


export default Organisation;
