import React from 'react';
import { Navigate } from 'react-router-dom';
import '../../../styles/flow.css';
import '../../market/container/datatables.css';
import {format_date, title_case} from '../../../helpers/common_helper';
import FlowContainer from '../../common/container/core/FlowContainer';
import _ from 'lodash';
import CommonReactTable from '../../../helpers/commonReact_table';
import {Card,Button} from 'react-bootstrap';
import { format } from 'date-fns';
import {getCustomReport, getReport} from '../../../actions/report_actions';
import DailyAgreeReport from '../component/DailyAgreement';
import { Link} from 'react-router-dom';
import { BasicReactTable } from '../../../helpers/react_table';


class DailyAgreementReport extends FlowContainer{

    state ={
        columns :[],
        report_list:[],
        daily_agree_report_list:{"report_type" : "daily_agreements"},
        daily_agree_table:"hide_div",
        copy:[]
     
    }

    componentWillMount(){

        this.handleCurDate()
    }

    handleCurDate = () =>{

        const date = new Date()
        const cur_date = format(date, 'yyyy-MM-dd')
        const daily_agree_report_list = this.state.daily_agree_report_list
        daily_agree_report_list['start_date'] = cur_date
        daily_agree_report_list['end_date'] = cur_date
        this.setState({daily_agree_report_list})
        this.handleSearch()

    }

    changeHandler = (e) => {		
		const toFilter = e
		const filterfrom = this.state.report_list
		const filteredData = filterfrom.filter(value => {
			return(
				value.rm_name !==null && value.rm_name.toLowerCase().includes(toFilter.toLowerCase()) ||
				value.rm_name !==null && value.rm_name.includes(toFilter) ||
        value.rm_name !==null && value.rm_name.toLowerCase().includes(toFilter.toLowerCase()) ||
        value.rm_name !==null && value.rm_name.toLowerCase().includes(toFilter.toLowerCase())         				
			)
		})
		this.setState(state=>state.copy = filteredData)		
	}

    handleFilter = (event) => {
        let ids = event.map((e)=>e.value);
        this.setState({daily_agree_report_list:{...this.state.daily_agree_report_list, flow_rel_mgr_id:ids}})
    }

    getCustomer(row){
        return(  
            row.value.map(function (value) {
                let concat_str = "";
                if(row.value.length > 1){
                    concat_str = ",";
                }
                return <Link to={"/borrower/indiv/view/"+value} target="_blank" className="flow_custid" >{value+concat_str+" "}</Link>
            })
        ) 
    }
    
    handleSearch =(event)=>{

        getReport(this.req(this.state.daily_agree_report_list))
        .then((response) => {
            this.resp(response)
            if(!response){return };
                if(response.status === "success"){
                        this.resp(response)
                        var columns = this.state.columns

                            columns = [{  
                                            Header: 'Date',  
                                            id :'date',
                                            accessor:row =>format_date(row.date, false,"dd-MM-yy") ,
                                           
                                        },{  
                                            Header: 'RM Name',  
                                            id :'rm_name',
                                            sortable:true,
                                            accessor : row =>title_case(row.rm_name),
                                           
                                        },{  
                                            Header: 'Cust Ids',  
                                            accessor: 'cust_ids', 
                        					Cell: row => this.getCustomer(row),
                                            style: {whiteSpace: 'unset'},
                                            width : 465
                                        },{  
                                            Header: <div className='text-center'>Agreements</div>,  
                                            id :'agreements',
                                            accessor : row =><p className='m-0 text-center'>{row.agreements}</p>,
                                            style : {textAlign:"center"} 
                                        },]
                    }
                    this.setState({columns,report_list:response.data,daily_agree_table:"show_div", copy:response.data})           
        })

}


    render(){
        return(

            <div className="container containerTopmargin max-width no-padding">            
                <form className="mb-3" autocomplete="off">
                    <Card>
                        <Card.Header style = {{backgroundColor: "#01062c" , color: "white"}}><h4>Daily Agreement Reports</h4></Card.Header>
                        <Card.Body style={{padding:15}}>
                                <DailyAgreeReport  id="daily_agree_report_list" onComponentChange={this.handleComponentChange} data = {this.state.daily_agree_report_list} handleFilter={this.handleFilter}/>   
                                <div className="form-group row reset-margin justify-content-center py-4">
                                    <Button disabled={this.state.calling_api} className= {`col-md-2 mr-3 btn btn-primary`} onClick={this.handleSearch}>SUBMIT</Button>
                                </div>
                        </Card.Body>  
                    </Card>
                </form>
                {!_.isEmpty(this.state.daily_agree_report_list) && 
                    <div className={`mt-3 ${this.state.daily_agree_table}`} >	
                        <div className='d-flex justify-content-between align-items-center' style={{padding:5}}>
                                            <p className='no-margin text-white'><b>Total Entries : {this.state.copy.length}</b></p>
                            <div style={{position:'relative'}}>
                                <i class="fa-solid fa-magnifying-glass search_pos"></i>
                                <input placeholder='Search here' className='search_posInput' onChange={(e)=>this.changeHandler(e.target.value)}></input>
                                {/* <button className='btn-std btn btn-primary' onClick={()=>this.resetHandler()}>Reset</button> */}
                            </div>
                        </div>		

                          <BasicReactTable row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns}  pagination={this.state.copy.length > 10} default_page_size = {10} export_csv={true}  searchData={[{...this.state.daily_agree_report_list,title:'Daily Agreement'}]} FName={"Daily Agreement Report"} /> 

                            {/* <CommonReactTable showPag={true} minRow={0} defaultPageSize={20} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} resizable={false} exportCSV={true} csvFile={"Daily Agreement Report"} csvData={this.state.copy ? this.state.copy : []} searchData={[{...this.state.daily_agree_report_list,title:'Daily Agreement'}]}/>    				 */}
                    </div>
                }
            </div>
        )
    }
}export default DailyAgreementReport 