import React from "react";

import FileUpload from '../../common/component/FileUpload';
import FlowComponent from '../../common/component/core/FlowComponent';
import { get } from '../../../helpers/storage_helper';
import Alert from 'react-bootstrap/Alert';

import {submit_holder_name_proof} from '../../../actions/lead_actions';

class FileUploadComponent  extends FlowComponent {
  constructor() {
    super();
    // this.uga_owner_address_alignment=["parish","sub_county","county","village","district","region","location","landmark"]
    // this.rwa_owner_address_alignment=["province","district","sector","cell","village","location","territory","landmark"]
   this.state={
    lead:{},
   }
}

sumbit_acc_holder_proof(a=null,action=null){
 if((this.props.data.mismatch_reason=="franchise_r_distributor_name") ){
  const name = this.props.data.get_data_value.first_name + this.props.data.get_data_value.last_name
  const name2=  this.props.data.get_data_value.last_name + this.props.data.get_data_value.first_name 
  var customer_name = name.toLowerCase();
  var customer_name_2=name2.toLowerCase()

  var entered_name = (this.state.holder_name.toLowerCase()).replace(/ +/g, "");
  
  var name_array=[customer_name.replace(/ +/g, ""),customer_name_2.replace(/ +/g, "")]
 }
 
  if( (this.props.data.mismatch_reason=="franchise_r_distributor_name") ? (name_array.includes(entered_name)):true){
    this.setState({wrong_name:false})

    var req={"action":action ,"holder_name":this.state.holder_name,"account_holder_name_proof":this.state.lead.account_holder_name_proof,"entity_id":this.props.data.get_data_value.entity_id,"entity":this.props.data.get_data_value.entity,"country_code":get('market').country_code}
    submit_holder_name_proof(this.req(req))
    .then((response)=>{
      if(!response){return ""}
      if(response.status=="success"){
        this.props.get_data_handler(null,null, this.props.data.mismatch_reason=="franchise_r_distributor_name" ? "franchise_holder_name":"")
      }
    })
  }
  else{
    this.setState({wrong_name:true})
  }

}
alert_close(){
  this.setState({wrong_name:false})

}
get_holder_name(value,key){
  this.setState({[key]:value})

}
  setAuditLeadState = (event) => {
    this.setChangeToState(event)
   }

    render() {
      return <h2>
          <div className='col-12 p-0'>
                <FileUpload  entity_id={this.props.entity_id} className="audit_kyc_file_upload" lead_id = {this.props.lead_id} id="account_holder_name_proof"  mode = "create" entity ='leads' type="image"          
                onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e, "reload")}} onCreateUpload={(e)=>this.setAuditLeadState(e)} />
          </div>
        
          <div className='col-12 p-0'>
                          <p className='text-white m-0 pt-3' style={{fontSize:'12px'}}> ENTER THE NAME IN SMS SCREENSHOT</p>
                        <div className="row d-flex align-items-center m-0">

                        <div class="form-group col-10 no-padding mt-2 mb-4">
                          <input type="text" className='py-2 px-3' id="lname" name="lname" style={{width:'100%',background:'#a0a2a3',color:'black',border:'hidden',fontSize:'14px'}} autocomplete="off" placeholder='ENTER HOLDER NAME' onChange={(e)=>this.get_holder_name(e.target.value,"holder_name")}/>
                        </div>  
                        {this.state.wrong_name &&


                       

                        <Alert  variant="success" className="alert alert-danger audit_kyc_alert py-3 col-10 mt-2" show={this.state.text_blink}  onClose={() => this.alert_close()} dismissible>
                        <p className='no-margin text-white' style={{fontSize:'13px'}}> The entered name is incorrect. Please enter the correct name.  </p>
                        </Alert>
                        
                        }
                        <div className="col-2">
                        <button type="button" class="btn btn-secondary " disabled={(this.state.lead.account_holder_name_proof && this.state.holder_name)?false:true} onClick={()=>{this.sumbit_acc_holder_proof("franchise_holder_name","submit")}}><b>Save</b></button>
                        </div>
                        </div>
                        {this.props.data.call_log_text_status ? 
                          this.props.call_log_verification() :""}
            </div>
      </h2>;
    }
  }
  export default FileUploadComponent