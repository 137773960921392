import { get_path } from '../helpers/common_helper';
import {call_fetch} from './../helpers/fetch_helper';

export const createCSResultScore = request  => {
   return call_fetch(get_path('admin') + '/score_model/cs_result_config', request);
}

export const getCSResultConfig = request  => {
   return call_fetch(get_path('admin') + '/score_model/cs_result_config/list', request);
}

export const listWeightages = request  => {
   return call_fetch(get_path('admin') + '/score_model/weightages', request);
}

export const updateWeightages = request  => {
   return call_fetch(get_path('admin') + '/score_model/weightages/update', request);
}

export const createFactor = request  => {
   return call_fetch(get_path('admin') + '/score_model/cs_factor', request);
}

export const listCSFactors = request  => {
   return call_fetch(get_path('admin') + '/score_model/cs_factor/list', request);
}

export const createScoreModel = request  => {
   return call_fetch(get_path('admin') + '/score_model/create', request);
}

export const listScoreModels = request => {
   return call_fetch(get_path('admin') + '/score_model/list', request);
}

export const createCreditScoreModel = request  => {
   return call_fetch(get_path('admin') + '/upload_cust_txns', request);
}


export const createCustCSFValues = request  => {
   return call_fetch(get_path('admin') + '/score_model/cust_csf_values/create', request);
}