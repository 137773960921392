import React from 'react';
import '../../../styles/flow.css';
import FlowContainer from '../../common/container/core/FlowContainer';
import {Modal,Button } from 'react-bootstrap';
import SearchLoanCriteria from '../../loan_appl/component/SearchLoanCriteria';
import {searchLoan} from '../../../actions/loan_actions';
import {dd_value} from '../../../actions/common_actions';
import {format_date_tbl, lbl_date,page_count,rt_algn, title_case} from '../../../helpers/common_helper';
import {lbl_amt} from './../../../helpers/product_helper';
import ViewTxnContainer from './view_transaction_container';
import {CustID, Product} from '../../common/partial';
import ViewTransactions from '../../loan/component/ViewTransactions';
import $ from 'jquery';
import {Link} from "react-router-dom";
import CommonReactTable from '../../../helpers/commonReact_table';
import { Config, get } from '../../../helpers/storage_helper';
import { BasicReactTable } from '../../../helpers/react_table';

class
LoanSearchContainer extends FlowContainer {
	constructor(props){
	    super(props);
	    this.state = {
	    	search_btn_style : "",
	        data_prvdr_name_list: [],
	        lender_name_list: [],
	        product_name_list: [],
	        searchLoans: {"voided_fa" : "false"},
	        datatable_div_style: "hide_div",
	        search_loan:"show_div",
	        modify_search:"hide_div",
	        toView: false ,
			copy:[],
			rowdatas:[],
			columndatas:[],
			paginate :  Config('paginate') ? Config('paginate') : null,
			export_data:[],
			search_criteria:null,
			total : 0,
			loader : false,
			language_json : get("language_json")
	    };
	}

	
	viewLoanPage = (event) => {
		// var prev_table = $('#list').DataTable();
		// prev_table.destroy();
		const loan_search_criteria = this.state.searchLoans;
		if(Object.keys(loan_search_criteria).length > 0){	

				searchLoan(this.req({loan_search_criteria}, true))
					.then((response) => {	

				if(!response){return };
					var loanResults = [];
					if(response.data.results.length > 0){
						this.loan =  response.data.results;
						response.data.results.map((loan,i)=>window.open("/fa/view/"+loan.loan_doc_id,"_blank"));	
					}
			});
		}
		else{
			alert("No results found."); 
		}
	}
    handleSearch = (event) => {	
		this.setState({loader:true})
		var mode=event.target.id; 
		var prev_table = $('#list').DataTable();
		prev_table.destroy();
		this.setState({copy : []})
		if(this.state.paginate){
			this.setState({paginate : Config('paginate')});
		}
    	this.setState({datatable_div_style: "hide_div",
    					search_btn_style : "disable_div"});
		this.setState({total : 0})
		if(!this.state.searchLoans.status && !this.state.searchLoans.req_parameter){
			window.alert("Please enter a valid search criteria")
			this.setState({loader:false})
		}
		else if(this.state.searchLoans.status=="disbursed" && (!this.state.searchLoans.disbursal_date__from || !this.state.searchLoans.disbursal_date__to)){
			window.alert("Please enter a valid search criteria")
			this.setState({loader:false})
		}else{
			this.LoanSearch(mode)
		}
		
	}
	
	LoanSearch(mode){
		this.setState({loader:true})
    	const loan_search_criteria = this.state.searchLoans;
		loan_search_criteria.mode=mode;
 		if(Object.keys(loan_search_criteria).length > 0){
			loan_search_criteria.date_field = loan_search_criteria.status == "settled" ? "true" : "false" ;
			this.setState(state=>state.search_criteria = loan_search_criteria)
			var req_json = this.state.paginate ? {loan_search_criteria, paginate : this.state.paginate} : {loan_search_criteria}
		  searchLoan(this.req(req_json, true))
		        .then((response) => {
		        	this.resp(response)
		        	this.setState({search_btn_style : ""});
	          if(!response){return this.setState({loader : false})};
			  if( response.data.results && response.data.results.length == 0) this.setState({loader : false})
						   var loanResults = [];
						
							if(response.data.mode == 'view'){
								window.open("/fa/view/"+response.data.loan_doc_id,"_blank")
								// this.loan =  response.data.results;
								// response.data.results.map((loan,i)=>window.open("/fa/view/"+loan.loan_doc_id,"_blank"));
								
							}
							else 
							if(response.data.mode == 'search'){
								this.setState(state=>state.rowdatas = response.data.results)
								this.setState(state=>state.columndatas = this.columndatas(this.state.language_json))
								this.loan =  response.data.results;
								
								this.setState({search:true})
							// 	loanResults = response.data.results.map((loan,i)=> <tr>
							// <td><Link className='link-doc-id' to={"/fa/view/"+loan.loan_doc_id} target="_blank">{loan.loan_doc_id}</Link></td>
							
							// <td>{loan.acc_number}</td>

							// <td>{loan.product_name}</td>

							// <td>{loan.flow_rel_mgr_name}</td>
							// <td>{loan.biz_name + " | " + loan.cust_name}</td>

							// <td> {format_date_tbl(loan.disbursal_date)}</td>
							// <td> {format_date_tbl(loan.due_date)}</td>

							// <td data-sort={loan.loan_principal}> {rt_algn(lbl_amt(loan.loan_principal))}</td>
							// <td data-sort={loan.flow_fee}> {rt_algn(lbl_amt(loan.flow_fee))}</td>
							// <td data-sort={loan.paid_amount}> {rt_algn(lbl_amt(loan.paid_amount))}</td>  
							// <td data-sort={loan.paid_date}> {loan.paid_date ? lbl_date(loan.paid_date) : "Not Paid"}</td>
							// <td> {loan.overdue_days}</td>
							           
							
							// {/*<td> {dd_value(loan.status)}</td>*/}
							// <td> <ViewTransactions parent="loan_search" loan_doc_id={loan.loan_doc_id}/></td>
							// 	</tr>
							// 	);
								
		           			this.setState({datatable_div_style: "show_div",loanResults: loanResults,search_loan:"hide_div",modify_search:"show_div"});
		           			// $('#list').removeAttr('width').dataTable({
							// 	   "order" : [],
							// 	   "pageLength": 25,
						    //   "columns": [
		           			//    {"width": "15%"},
				            //    {"width": "10%"},
				            //    {"width": "10%"},
				            //    {"width": "15%"},
				            //    {"width": "10%"},
				            //    {"width": "20%"},
				            //    {"width": "10%"},  
				            //    {"width": "10%"},
				            //    {"width": "10%"},
				            //    {"width": "10%"},
				            //    {"width": "5%"},
				            //    {"width": "5%"},
				            //    {"width": "5%"},

		           			//  ],
						    //   dom:'Bfrtip',
						    //   buttons: [
                            //      'csv','excel','print'
						    //   ]
						    // });	
							if(this.state.paginate != null){								
								this.setState(state=>state.total=response.data.total_count)	
								this.page_count()
							}
							else{
								this.setState(state=>state.total=response.data.results.length)
							}
							this.setState((state) => {
								return { copy : response.data.results };
							}, ()=>this.setState({loader : false}));

			           	}else{
			          		alert("No results found.");   		
			           	}
		          }
		        );
		}else{
			this.setState({search_btn_style : ""});
			alert("Choose atleast one filter to search Float Advance.")
		}					        
	}

	columndatas (language_json) {
		return[
			{
			  Header : language_json.fa_id, 
			  id: "loan_doc_id",				       
			  accessor: (row) => <Link className='link-doc-id' to={"/fa/view/"+row.loan_doc_id} target="_blank">{row.loan_doc_id}</Link>			
			},
			{
			  Header : language_json.account_num,
			  id: "acc_number",
			  accessor:row => {return row.acc_number}
			},
			// {
			//   Header : "Product",
			//   id: "product_name",
			//   accessor:row => {return row.product_name}
			// },

			{
			  Header : language_json.rm,
			  id: "flow_rel_mgr_name",
			  sortable : false,
			  accessor:row => {return row.flow_rel_mgr_name}
			},
			{
			  Header : language_json.biz_name + "|" + language_json.cust_name,
			  id: "biz_name",
			  accessor:row => {return row.biz_name + " | " + row.cust_name}
			},
			{
			  Header : language_json.disb_date,
			  id: "disbursal_date",
			  accessor:row => {return format_date_tbl(row.disbursal_date)}
			},
			{
			  Header : language_json.due_date,
			  id: "due_date",
			  accessor:row => {return format_date_tbl(row.due_date)}
			},
			{
			  Header : () => (<div className='text-center'>{language_json.fa_amount} ({this.currency_code})</div>), 
			  id: "loan_principal",
			  accessor:row => {return rt_algn(lbl_amt(row.loan_principal))}
			},
			{

			  Header : () => (<div className='text-right'>{language_json.fee} ({this.currency_code})</div>),
			  id: "flow_fee",
			  accessor:row => {return rt_algn(lbl_amt(row.flow_fee))}
			},
			{
				Header : () => (<div className='text-right'>{language_json.paid_amount} ({this.currency_code})</div>),
				id: "paid_amount",
				accessor:row => {return rt_algn(lbl_amt(row.paid_amount))},
				width:120
			},
			{
				Header : language_json.paid_date,
				id: "paid_date",
				accessor:row => {return row.paid_date ? lbl_date(row.paid_date) : "Not Paid"}
			},
			{
				Header : language_json.applied_by,
				id: "applied_by",
				sortable : this.state.paginate ? false : true,
				accessor:row => {return row.loan_applicant_name}
			  },
			  {
				Header : language_json.channel,
				id: "channel",
				accessor:row => dd_value(row.channel,'channel'),
			  },
			{
				Header : language_json.overdue_days,
				id: "overdue_days",
				accessor:row => {return <div className='text-center'>{row.overdue_days}</div>}
			},
			{
				Header : "Lead Created By",
				id: "lead_created_by",
				accessor:row =>title_case(row.lead_created_by),
				sortable : this.state.paginate ? false : true,
			},
			{
				Header : "Lead Creator Name",
				id: "lead_creator_name",
				accessor:row =>row.lead_creator_name,
				width:200,
				sortable : this.state.paginate ? false : true,
			},
			{
				Header : language_json.action,
				id: "action",
				sortable : this.state.paginate ? false : true,
				accessor:row => <ViewTransactions parent="loan_search" loan_doc_id={row.loan_doc_id}/>
			},
		  ]
	}

	changeHandler = (e) => {		
		const toFilter = e
		const filterfrom = this.state.rowdatas
		const filteredData = filterfrom.filter(value => {
			return(
				value.loan_doc_id !==null && value.loan_doc_id.toLowerCase().includes(toFilter.toLowerCase()) ||
				value.acc_number !==null && value.acc_number.includes(toFilter) ||
				value.product_name !==null && value.product_name.toLowerCase().includes(toFilter.toLowerCase()) ||
				value.flow_rel_mgr_name !==null && value.flow_rel_mgr_name.toLowerCase().includes(toFilter.toLowerCase()) ||
				value.biz_name !==null && value.biz_name.toLowerCase().includes(toFilter.toLowerCase())	
			)
		})
		this.setState(state=>state.copy = filteredData)		
	}

	viewLoan = (event) => {
		const loan_doc_id = event.target.id;
		window.open("/fa/view/"+loan_doc_id,"_blank");
	}

	viewTxn = (event) => {
		this.setState({loan_doc_id : event.target.id});
		this.setState({modalBox: true});
	}

	handleModalClose = (event) => {
      this.setState({ modalBox: false });
   }
	handleModifySearch = (event) => {
	this.setState({
		search_loan:"show_div",
		modify_search:"hide_div"
	})
	this.setState({paginate : Config('paginate'), copy : [], rowdatas : [], total : 0})
	}	


	handlePurposeChange = (selected_options,id) =>{
		this.setMultiselectOptionOnState(selected_options,id,this.state.searchLoans)
	
  }
	pagination = (page, count, sorted)=>{
			
		this.setState((state) => {
			return {
			  paginate: {
				...state.paginate,
				pagination_count: count,
				pagination_page:page,
				sorting:sorted,
				total_count : this.state.total
			  },
			};
		  }, ()=>this.LoanSearch('searchbtn'));	
	}

	page_count = ()=>{
			
		const total = this.state.total
		const page_count_mod = (total%this.state.paginate.pagination_count)
		
		if(page_count_mod > 0){
			const page_count = (total/this.state.paginate.pagination_count)
			this.setState({'page_count' : Math.trunc(page_count)+1}, ()=>console.log(this.state.page_count))

		}else{
			const page_count = (total/this.state.paginate.pagination_count)
			this.setState({'page_count' : page_count})
		}
	}

	handleExportCSV = (event) => {
		let loan = JSON.parse(JSON.stringify(this.state.search_criteria));
		let resp = null;
		if(event == 'email') {
			loan.export = 'email'
			resp = window.confirm(`The current search exceeds ${Config("export_csv").limit} rows. So the exported data will be sent to your email shortly.`)
		}

		if(event == 'email' && !resp){
			return
		}
		else{
			this.handleCSVapi(loan, event)
		}
	}

	handleCSVapi(loan, action){
		let calling_api = true
		if (action == 'email') calling_api = false 
		var request={loan_search_criteria:loan, paginate : false}
		searchLoan(this.req(request, true, null, calling_api), true)
			.then((response) => {
				this.resp(response)
				if(action == 'email'){
					return
				}
				this.setState(state=>state.export_data = response.data.results)
			})
	}

	render(){
	
		return(
			<div className="container containerTopmargin max-width no-padding">
				<div className="floatAdvancetitleBox">
					<h3 className="preHeaderpad no-margin" style={{paddingLeft:15,fontSize:20}}>{this.state.language_json.search_fa_title}</h3>
				</div>	
				<div className='text-right' style={{marginTop: this.state.modify_search === "show_div" ? 15 : 0}}>						
					<div className={this.state.modify_search}>

						<button type="button" className="btn btn-md btn-light" id="float_style"onClick={this.handleModifySearch} >{this.state.language_json.modify_search}</button>

					</div>
				</div>	
				 <div className={this.state.search_loan}>
					<SearchLoanCriteria id="searchLoans" mode="loan" 
										onSearch={this.handleSearch} 
										search_btn_style={this.state.search_btn_style}
										onComponentChange={this.handleComponentChange} 
										onComponentReset={this.handleComponentReset}
										disable_btn = {this.state.calling_api} 
										handlePurposeChange={(a,b)=>{this.handlePurposeChange(a,b)}}
										onComponentElementChange={this.handleComponentElemetChange}/>
		           </div>
				   {this.state.rowdatas.length > 0 &&	
				<div id="" style={{marginTop:15}}>		
					<div className={this.state.datatable_div_style} >
						{/* <div id='' className={"table-responsive"}>
            			<br/>
		                <table scrollX id ='list'  className='table list no-footer'>
		                  <thead className={"text-info"}>
		                  <tr>
		                  <th className="loanid">FA ID</th>
		                  <th>Account Number</th>
		                  <th>Product</th>
		                  <th>RM</th>
		                  <th>Biz Name | Cust Name</th>
		                  <th>Disbursal Date</th>
		                  <th>Due Date</th>
		           
		                  <th>FA Amount ({this.currency_code})</th>
		                  <th>Fee ({this.currency_code})</th>
		                  <th>Paid Amount ({this.currency_code})</th>
		                  <th>Paid Date</th>

		                  <th>Overdue Days</th>
		                  <th>Action</th>
		                  </tr></thead>
		                  <tbody> 
		                 {this.state.loanResults}
		                  </tbody>
		                </table>   
		              </div>   */}
					  <div className= {`${this.state.paginate ? 'd-flex justify-content-between align-items-center col-md-5' : 'd-flex justify-content-between align-items-center'}`} style={{padding:5, top : `${this.state.paginate ? '48px' : '0px'}`}}>
							{!this.state.loader && <p className='no-margin text-white'><b>{this.state.language_json.total_entries} : {this.state.total}</b></p>}
							{!this.state.paginate &&
								<div style={{position:'relative'}}>
									<i class="fa-solid fa-magnifying-glass search_pos"></i>
									<input placeholder='Search here' className='search_posInput' onChange={(e)=>this.changeHandler(e.target.value)}></input>
									{/* <button className='btn-std btn btn-primary' onClick={()=>this.resetHandler()}>Reset</button> */}
								</div>
							}
						</div>
						<div style={{marginTop : `${this.state.paginate ? '18px' : '0px'}` }}>{this.state.copy.length>0&&
						<>
							<div style={{display:this.state.loader ?'none':''}}>
								{this.state.paginate == null ? 
									// <CommonReactTable showPag={true} defaultPageSize={10} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} resizable={false} defaultSorted={[{id:"disbursal_date",desc:false}]} exportCSV={true} csvFile={"Loans List"} csvData={this.state.copy !== undefined ? this.state.copy : []} searchData={[{...this.state.searchLoans,title:'Search Float Advance'}]}/> 
									<BasicReactTable pagination={true} default_page_size={10}  row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} defaultSorted={[{id:"disbursal_date",desc:false}]} csv_file={true} FName={"Loans List"} export_csv_datas={this.state.copy !== undefined ? this.state.copy : []} searchData={[{...this.state.searchLoans,title:'Search Float Advance'}]} /> 
									:

									<BasicReactTable pagination={true} default_page_size={10}  row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} defaultSorted={[{id:"disbursal_date",desc:false}]} csv_file={true} FName={"Loans List"} export_csv_datas={this.state.export_data !== undefined ? this.state.export_data : []} searchData={[{...this.state.searchLoans,title:'Search Float Advance'}]} filter_data = {this.pagination} server_side_pagination = {this.state.paginate} total_page_count = {page_count(this.state.total, this.state.paginate.pagination_count)}  handleExport = {this.handleExportCSV} csv_total_data = {this.state.total !== undefined ? this.state.total : 0} />
									// <CommonReactTable pagination={true} defaultPageSize={10} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} resizable={false} defaultSorted={[{id:"disbursal_date",desc:false}]} exportCSV={true} csvFile={"Loans List"} csvData={this.state.export_data !== undefined ? this.state.export_data : []} searchData={[{...this.state.searchLoans,title:'Search Float Advance'}]} filter_data = {this.pagination} pagination = {true} page_count = {this.state.page_count}  handleExport = {this.handleExportCSV} csv_total_data = {this.state.total !== undefined ? this.state.total : 0}/>
								}
							</div>
							</>}
						</div>                                   
					</div>
				</div>
				}
				<div style={{display:this.state.loader ?'':'none'}}>	
								<div style={{marginTop:"15%"}} > <p className='text-light mt-3 text-center'>Loading...</p>
									<div className="product_takeup_loader"></div>
								</div>
							</div>
	
			</div>
			);
	}
}
export default LoanSearchContainer;
