import { get_path } from '../helpers/common_helper';
import {call_fetch} from './../helpers/fetch_helper';


export const createTask = request => {
    return call_fetch(get_path('app') + '/task/create', request)
}
 
export const listTaskRequests = request => {
    return call_fetch(get_path("app") + '/task/list', request)
}
export const approveTaskReq = request => {
    return call_fetch(get_path('app') + '/task/approval', request)
}
