import React from 'react';
import Select from 'react-select';

import {updateStatus,get_status_change_history} from '../../../../actions/borrower_actions';
import  {lbl_status, style_status,variant_color,variant_value} from '../../../../helpers/common_helper';
import  {check_priv} from '../../../../helpers/storage_helper';
import {format_date_tbl} from '../../../../helpers/common_helper';
import RecordAudit from '../RecordAudit'
import FlowComponent from './FlowComponent';
import {Link} from 'react-router-dom';
import {dd_value} from '../../../../actions/common_actions';
import { Modal , Button,Badge, Col} from 'react-bootstrap';

import '../../../../styles/flow.css';

class CustomerStatus extends FlowComponent{

	state = {
		modalBox:false,
		status_change :{},
		audit_type: "status_change"	


	}

	componentWillMount(){
		this.setState({fa_status: this.props.fa_status});	
		var parent = this.props.parent;
		if(!parent){
			var entity = this.props.entity;

			parent = entity.slice(0,-1);
			if(parent == "borrower"){
				parent = "customer";
			}else if(parent == "loan_product"){
				parent = "product";
			}else if(parent == "acc_provider"){
				parent = "acc_prvdr";
			}else if(parent == "master_data_key"){
				parent = "data_key";
			}			

		}

		if(check_priv(parent , 'create')){
	        this.btn_style = false
	    }else{
	        this.btn_style = true
	    }

	}


	componentWillReceiveProps(props){

		this.setState({fa_status: props.fa_status});		
		var parent = props.parent;		
		if(!parent){
			var entity = props.entity;

			parent = entity.slice(0,-1);
			if(parent == "borrower"){
				parent = "customer";
			}else if(parent == "loan_product"){
				parent = "product";
			}else if(parent == "acc_provider"){
				parent = "acc_prvdr";
			}else if(parent == "master_data_key"){
				parent = "data_key";
			}			

		}


		if(check_priv(parent , 'create')){
	        this.btn_style = false
	    }else{
	        this.btn_style = true
	    }
	}


 	validate_status_change(status_change) {
     	if(status_change.remarks && status_change.status_reason){
      		return true
    }else{
      alert("Please enter required fields")

      return false}
    
  }
	updateStatus = (event) =>{
			// const resp = window.confirm("Are you sure to change the status?");
		// if(resp == false){
		// 	return;
		// }
		let status_change = this.state.status_change
		let valid = this.validate_status_change(status_change)
		let fa_status = event.target.value.toLowerCase()+"d"; 
		if(valid){
		const request = {"id":this.props.id,"cust_id":this.props.cust_id,mobile_num:this.props.mobile_num,fa_status,...status_change};
		const request_data = {[event.target.name] : request}
		updateStatus(this.req(request_data))
		.then((response) => {
			if(!response){return };
				if(response.status === "success"){
					//this.setState({status})
					if(response.data!=1){
						alert(response.data)
					}
					this.handleModalClose()
					window.location.reload();
				}else{
					alert(response.message + "\n" + JSON.stringify(response.data));  
				}
			}
			);
	}
		
	      //this.setState({credit_score_factor_id: event.target.id, toView: true});
	}

	handleModalClose = () => {
		this.setState({modalBox:false})
	}

	handleStatusChange = (event) =>{

		var status_change = this.state.status_change
		status_change[event.target.id] = event.target.value
		this.setState({status_change});
	
	}

	handleReasonChange = (selectedOptions) =>{
      
       var status_change = this.state.status_change
     
       status_change.status_reason = selectedOptions.map((item) => ( item.value))

        
        // this.setState({checkin_req[event.target.id] : event.target.value});
       this.setState({status_change});

  }

	handleModalOpen = (event) => {

			this.setState({modalBox:true})

		// this.updateStatusModal(this.state.status)	
	   } 
	
	

	   render(){
	   	return(
	   		<>
		   		<Modal show={this.state.modalBox} onHide={this.handleModalClose} size="l">
					<Modal.Header closeButton show={this.state.modalBox} onHide={this.handleModalClose}>
						<Modal.Title>Reason to {lbl_status(this.state.fa_status)}</Modal.Title>
					</Modal.Header>
					<Modal.Body>

						<div className="form-group row mx-0 mb-2 ">
		                	<label for=""  className="col-sm-5 font-weight-bold label__name reset-padding"> Reason<font className="text-danger">   *  </font></label>
		               		<div className="col-sm-7 reset-padding">
		                   		<div>

		                   		{this.state.fa_status === "disabled" &&

		                   		<Select onChange={(e) =>{this.handleReasonChange(e);}}
                              			options={this.getMultiSelectOptions('reason_for_enable')} isMulti/>
                              	}
                              	{this.state.fa_status === "enabled" &&
                              	<Select onChange={(e) =>{this.handleReasonChange(e);}}
                              			options={this.getMultiSelectOptions('reason_for_disable')} isMulti/>
                              	}


		                   		</div>
		                   		<div className="control-me"></div>
		               		</div>
		               	</div>
		               	<div className="rule-form-group form-group row reset-margin">
		                   	<label for="" className="col-sm-5 font-weight-bold label__name reset-padding">Remarks<font className="text-danger">   *  </font></label>
		               		<div className="col-sm-7 reset-padding">
		                   		<div>
		                   			<textarea type="text" id="remarks" className="form-control" onChange={(e) =>{this.handleStatusChange(e);}}  maxLength='256'/>
		            			</div>
		            		</div>
		            	</div>          
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleModalClose} className={"btn-std"}>Close</Button>
						<Button variant="primary" name={this.props.entity} value={lbl_status(this.state.fa_status)} className={"ml-2 btn-std"} onClick={this.updateStatus}>Submit</Button>
					</Modal.Footer>
				</Modal> 

				<RecordAudit record_code = {this.props.cust_id} audit_type = {this.state.audit_type} title = "Customer Status" ref={instance =>{this.RecordAudit = instance; }} />
				<button className="btn btn-primary btn-std" onClick ={() => this.RecordAudit.audit_changes()}>Status History</button>
				<button  id={this.props.id} type='button' name={this.props.entity} onClick={this.handleModalOpen} className="btn btn-primary btn-std ml-2" disabled={this.btn_style}>{lbl_status(this.state.fa_status)}</button>
	        </>
	   		);
	   }
}

export default CustomerStatus;
