import React from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from "moment";

class BarChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            series: this.props.data,
            options: {
                chart: {
                    width: this.props.width || '100%', // Set width based on prop or default to 100%
                    height: this.props.height || '80%', // Set height based on prop or default to 80%
                    zoom: {
                        enabled: this.props.show_toolbar === undefined ? true : this.props.show_toolbar
                    },
                    toolbar: {
                        show: this.props.show_toolbar === undefined ? true : this.props.show_toolbar
                    }
                },
                colors: this.props.colors === undefined ? ["#00e396", "#ff3154", "#fadd17"] : this.props.colors,
                plotOptions: {
                    bar: {
                        dataLabels: {
                            position: 'top'
                        },
                        horizontal: this.props.layout === "horizontal",
                        columnWidth: this.props.biz_ops_colunm_width === undefined ? '50%' : this.props.biz_ops_colunm_width,
                        distributed: this.props.distributed === undefined ? false : this.props.distributed
                    }
                },
                dataLabels: {
                    enabled: this.props.dataLabel,
                    offsetY: this.props.type === "bar" ? -20 : 0,
                    style: {
                        colors: this.props.colors === undefined ? ["#00e396", "#ff3154", "#fadd17"] : this.props.colors
                    },
                    formatter: (val, { seriesIndex, dataPointIndex, w }) => {
                        if (this.props.show_bar_label) {
                            return this.props.bar_labels[dataPointIndex];
                        }
                        const formattedVal = this.props.roundOff ? Math.floor(val) : Math.abs(val).toFixed(2);
                        return new Intl.NumberFormat("en-US").format(formattedVal) + " " + (this.props.curr || '');
                    }
                },
                stroke: {
                    curve: 'smooth',
                    width: 3
                },
                title: {
                    text: this.props.title,
                    align: 'center',
                    style: {
                        color: "#999"
                    }
                },
                legend: {
                    show: this.props.legend === undefined ? true : this.props.legend,
                    height: '100',
                    showForSingleSeries: true,
                    horizontalAlign: "right",
                    position: "right",
                },
                yaxis: {
                    type: this.props.xaxistype,
                    tickPlacement: "on",
                    lines: {
                        show: true
                    },
                    labels: {
                        hideOverlappingLabels: false,
                        style: {
                            colors: '#999'
                        }
                    },
                    title: {
                        text: this.props.x_axis_title || "",
                        style: {
                            color: '#939394'
                        }
                    },
                    categories: this.props.categories || []
                },
                xaxis: {
                    lines: {
                        show: true
                    },
                    min: 0,
                    ...(this.props.target && {
                        max: Math.max(...this.props.target.map(t => t.value)) + (0.1 * Math.max(...this.props.target.map(t => t.value))),
                    }),
                    forceNiceScale: true,
                    labels: {
                        style: {
                            colors: '#999'
                        },
                        formatter: (val) => {
                            const formattedVal = this.props.roundOff ? Math.floor(val) : Math.abs(val).toFixed(2);
                            return new Intl.NumberFormat("en-US").format(formattedVal) + " " + (this.props.curr || '');
                        }
                    },
                    axisBorder: {
                        show: this.props.axisBorder,
                        color: '#78909C',
                        offsetX: 0,
                        offsetY: 0
                    },
                    title: {
                        text: this.props.y_axis_tittle || "",
                        rotate: -90,
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: '#939394',
                        },
                    },
                },
                grid: {
                    show: this.props.grid === undefined ? true : this.props.grid,
                    borderColor: '#90A4AE',
                    strokeDashArray: 0,
                    position: 'back',
                    yaxis: {
                        lines: {
                            show: true
                        }
                    },
                    row: {
                        colors: undefined,
                        opacity: 0.5
                    },
                    column: {
                        colors: undefined,
                        opacity: 0.5
                    }
                },
                forecastDataPoints: {
                    count: this.props.dataProjections && this.props.projectionMonthDiff ? this.props.projectionMonthDiff : 0,
                    fillOpacity: 0,
                    strokeWidth: 3,
                    dashArray: 7,
                },
                ...(this.props.target && {
                    annotations: {
                        yaxis: this.props.target.map((item, index) => {
                            return {
                                y: item.value,
                                strokeDashArray: 0,
                                strokeWidth: 2,
                                borderColor: index === 1 ? '#ff0000' : '#00E396',
                                label: {
                                    style: {
                                        color: '#fff',
                                        background: index === 1 ? '#00E396' : '#ff0000',
                                        width: '100%',
                                        display: this.props.showTarget ? 'block' : 'none'
                                    },
                                    text: `${item.title} target: ${item.value}`
                                }
                            };
                        }),
                    }
                }) || {
                    annotations: {
                        yaxis: [],
                    }
                }
            }
        };

        if (this.props.y_axis_format !== undefined) {
            this.state.options.yaxis.labels.formatter = (val) => {
                const formattedVal = moment(val).format(this.props.y_axis_format ? "D MMM Y" : "MMM Y");
                return formattedVal;
            };
        }

        if (this.props.total_fas !== undefined || this.props.total_fas === false) {
            this.state.options.dataLabels.formatter = (val) => {
                const percentage = (val / this.props.total_fas) * 100 || 0;
                return percentage.toFixed(0) + '%';
            };
        }
    }

    render() {
        const legendStyles = `
            .apexcharts-legend {
                transform: translate(-13px, -111px);
                width: auto !important;
                max-width: 30%;
            }
        `;

        return (
            <div className="chart-container">
                <style>{legendStyles}</style>
                {(this.state.series && this.state.series.length > 0 && this.state.series[0].data && this.state.series[0].data.length > 0) ?
                    <ReactApexChart options={this.state.options} series={this.state.series} type={this.props.type} width={this.props.width} height={this.props.height} />
                    :
                    <div className='py-5'>
                        <p className='text-center text-light'>No Data Found</p>
                    </div>
                }
            </div>
        );
    }
}

export default BarChart;
