import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setValuesIntoRedux } from '../../../Redux/Slices/CommonSlice';

const CallTimer = () => {
  const dispatch = useDispatch();
  const datas = useSelector((state) => state.cs_soft_phone);
  const [callDuration, setCallDuration] = useState(datas.call_duration);

  const updateCallDuration = (prevDuration) => {
    let [hours, minutes, seconds] = prevDuration.split(':').map(Number);

    seconds += 1;
    if (seconds === 60) {
      seconds = 0;
      minutes += 1;
      if (minutes === 60) {
        minutes = 0;
        hours += 1;
      }
    }

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCallDuration((prevDuration) => {
        const newDuration = updateCallDuration(prevDuration);
        dispatch(setValuesIntoRedux({ call_duration: newDuration }));
        return newDuration;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [dispatch]);

  return (

    <span id="callDuration" style={{color:'white'}}>{datas.call_duration}</span>

  );
};

export default CallTimer;
