import FlowComponent from '../common/component/core/FlowComponent';
import React from 'react';
import {format_date_tbl, Homeacc_prvdrLogoselector, title_case} from '../../helpers/common_helper';
import { Link} from 'react-router-dom';
import {searchLead} from "../../actions/lead_actions";
import {TooltipLabel} from "../common/partial";
import { get } from '../../helpers/storage_helper';
import { listAccountProvider } from '../../actions/account_provider_actions';
import CommonReactTable from '../../helpers/commonReact_table';
import Accordion from 'react-bootstrap/Accordion';
import {Card} from 'react-bootstrap'
import {BsChevronDown} from 'react-icons/bs';
import AdminHome from './AdminHome';
import { dd_value } from '../../actions/common_actions';
import { BasicReactTable } from '../../helpers/react_table';

class PendingRMAllocList extends FlowComponent {

	state = {
		columns :[],
		lists : [],
		copy:[],
		dyn_Btn_selected:[],
		all_count:0,				
		filter_actions:{},
		all_Btn_status:false,
		all_Acc_pvdrs:[],
		show_table: false	
		
	}

	handleToggle = (e) => {

        switch(e){
            case("Account"):
                this.setState(state => state.show_table = !this.state.show_table);
                break;
        }
    }
	
	componentWillMount() {
		const ls_market = get('market')
		var req = {country_code: ls_market.country_code, time_zone: ls_market.time_zone, status: ""};		
		listAccountProvider({...req, status: 'enabled', biz_account: true})		
		.then((response)=>{
			if(!response){return };
			if(response.status === "success"){
				let acc_prvdrs = []            
				response.data.list.map(each=>
				acc_prvdrs.push(each.acc_prvdr_code)
				)  
				this.setState(state=>state.all_Acc_pvdrs = acc_prvdrs) 				           
			}
		});
		this.drawTable()				
    }

	drawTable () {
		const market = get('market')
		const market_name = market.country_code
		let lead_search_criteria = {status : '09_pending_rm_alloc', profile_status: 'open'}
      	searchLead(this.req({lead_search_criteria}))
			.then((response) => {				
				if(!response){return };
				if(response.status === "success"){
					this.setState(state => state.columns = this.columndatas())									
					if (response.data.length > 0){																							
						this.setState({lists:response.data})
						this.setState(state=>state.all_count = response.data.length)																					
						const newArr = get([market_name]+'_rmAllocpending') === undefined ?  this.state.all_Acc_pvdrs : get([market_name]+'_rmAllocpending')														
						this.state.all_Acc_pvdrs.map(each=>{																				
							const count = this.state.lists.filter(indiv => indiv.acc_prvdr_code === each)		
							const length = count.length										
							this.setState(state=>state.filter_actions[each] = [{count:0},{status:newArr.includes(each)}],()=>{
								this.dynClickhandler(this.state.filter_actions[each][1].status,each)									

							})
							this.setState(state=>state.filter_actions[each][0].count = length)
						})	
					}											       		
				}
			})
	}

	columndatas () {
		return (
			[
				{
					Header : "A/C Number",
					accessor: 'account_num',
					minWidth: 160
				},
				{
					Header : "Lead Channel",
					id : 'channel',
					accessor : row=> row.channel?title_case(row.channel):'N/A'
				},
				{
					Header : "Business Name",
					accessor: 'biz_name',
					Cell: cell => <TooltipLabel full_text={cell.row.original.biz_name} overlay={[cell.row.original.first_name, cell.row.original.last_name].join(" ")} no_reduce={true} />
				},
				{
					Header : "Reg Date",
					accessor: 'lead_date', 
					Cell: row => format_date_tbl(row.value),
					
				},
				{
					Header : "ACC PRVDR",
					accessor: 'acc_prvdr_code',
					Cell: row => <Homeacc_prvdrLogoselector logotype={row.value}/>
				},
				{
					Header : "Mobile Number",
					accessor: 'mobile_num'				
				},
				{
					Header : "Location",
					accessor: 'location',
				},
				{
					Header : "Action",
					sortable: false,
					accessor: 'id' ,
					Cell: row =>  <Link to={"lead/edit/"+ row.value} target="_blank" >Allocate RM</Link>
				}
			]
		)
	}

	dynClickhandler (key,val) {
		const market = get('market')
		const market_name = market.country_code												
		if(key){												
			if(val !== "ALL"){								
				const find_val = this.state.dyn_Btn_selected.findIndex(each=> each === val)
				if(find_val == -1) {					
					this.state.dyn_Btn_selected.push(val)					
					this.setState(state=>state.filter_actions[val][1].status = key)
					if(this.state.dyn_Btn_selected.length === this.state.all_Acc_pvdrs.length) {												
						this.setState(state=>state.all_Btn_status = true)
					}
				}					
				localStorage.setItem([market_name]+'_rmAllocpending',JSON.stringify(this.state.dyn_Btn_selected))										
			}else{					
				const newOne = []
				this.state.all_Acc_pvdrs.map(e=>{
					this.state.filter_actions[e][1].status = true
					newOne.push(e)
				})					
				this.setState(state=>state.dyn_Btn_selected = newOne)																															
				localStorage.setItem([market_name]+'_rmAllocpending',JSON.stringify(newOne))
				this.setState(state=>state.all_Btn_status=key)				
			}					
		}		
		else if(!key){					
			if(val !== "ALL"){											
				const find_val = this.state.dyn_Btn_selected.findIndex(each=>each === val)							
				if(find_val > -1){					
					this.state.dyn_Btn_selected.splice(find_val,1)
					this.setState(state=>state.filter_actions[val][1].status = key)
				}							
				this.setState(state=>state.all_Btn_status=false)													
				localStorage.setItem([market_name]+'_rmAllocpending',JSON.stringify(this.state.dyn_Btn_selected))
			}else{				
				this.state.all_Acc_pvdrs.map(e=>
					this.state.filter_actions[e][1].status = false
				)				
				this.setState(state=>state.dyn_Btn_selected = [])								
				localStorage.setItem([market_name]+'_rmAllocpending',JSON.stringify([]))
				this.setState(state=>state.all_Btn_status=key)
			}
		}					
		const newArr = get([market_name]+'_rmAllocpending')				
		let combinedArr = []
		newArr.map(each=> {			
			const filterArr = this.state.lists.filter(indiv=>indiv.acc_prvdr_code === each)
			combinedArr.push(...filterArr)			
		})		
		this.setState(state=>state.copy = combinedArr)									
	}	

    render(){		
        return (
        	// <div className='text-white home_table_box_margin_at_response'>
				// <div className="card">		
				<Accordion>
                <Card>	
					<div className={`${this.state.copy && this.state.copy.length==0 ?'empty_display_list': 'non_empty_display_list'}`}>
					<div className="card-header card-header-icon card-header-primary home_header_d-flex_at_response justify-space-between">
						<h4 className="card-title text-white  tableHead_font">Leads with Pending RM Allocation</h4>
						{this.state.lists.length > 0 &&
							<>
								<div className='countREL card-title'>
									<button className={'filterBtns allBtn_size'} onClick={()=>this.dynClickhandler(!this.state.all_Btn_status,"ALL")} style={{filter: !this.state.all_Btn_status ? "opacity(0.5)" : ''}}>ALL</button>
									<div className='countABS' style={{backgroundColor : !this.state.all_Btn_status ? "lightgrey" : ''}}>
										<label>{this.state.all_count}</label>
									</div>
								</div>									
								{this.state.all_Acc_pvdrs.map((each,idx)=>									
									<div key={idx} className='countREL card-title'>																					
											<>
												<div style={{width:60,height:30}} className="filterBtns"  onClick={()=>this.dynClickhandler(!this.state.filter_actions[each][1].status,each)}>													  
													<img className='img-fluid' src={`/img/${each}_logo_color.png`} style={{filter: this.state.filter_actions[each] && !this.state.filter_actions[each][1].status ? "opacity(0.5)" : ''}}></img> 																	
												</div>
												<div className='countABS' style={{backgroundColor : this.state.filter_actions[each] && !this.state.filter_actions[each][1].status ? "lightgrey" : ''}}>													
													<label>{this.state.filter_actions[each] && this.state.filter_actions[each][0].count}</label>																																
												</div>
											</>										
									</div>																		
								)}
						</>
						}
						<Accordion.Toggle className = "col" as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("Account")}>
                            <BsChevronDown className = {this.state.show_table ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow"} size="18"/>
                        </Accordion.Toggle>
					</div>
						</div>	
					
									
					<div className="card-body no-padding">
					<Accordion.Collapse eventKey="0">
							<Card.Body>
						{/* <CommonReactTable showPag={false} defaultPageSize={50} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} resizable={false} defaultSorted={[{id:"lead_date",desc:true}]}/>	 */}

						 <BasicReactTable  row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} pagination={true} defaultSorted={[{id:"lead_date",desc:true}]}   default_page_size={50}   /> 

						</Card.Body>
						</Accordion.Collapse>
					</div>
					</Card>
            </Accordion>
				// </div>				
			// </div>
        	)

    }
}


export default PendingRMAllocList;
