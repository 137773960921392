import React, { useState, useEffect }  from 'react';
import FlowComponent from "../common/component/core/FlowComponent";
import {Modal, Button, ModalBody} from "react-bootstrap";
import {cancelOngoingRecovery, captureRecovery, checkOngoingRecovery, createRecoveryRequest} from "../../actions/loan_actions";
import DatePicker from 'react-datetime';
import {current_date, format_date_tbl, format_month_change, lbl_amt, title_case} from "../../helpers/common_helper";
import moment from "moment";
import ResendCode from "../common/component/ResendCode";
import {GrRefresh} from "@react-icons/all-files/gr/GrRefresh";

class Recovery extends FlowComponent{
    state={
        accountDiv: 'hide',
        disabled: true,
        ongoing: false,
        recovery_details: {}
    }
    recovery_txn ={};





    componentWillMount(){
        this.setState({modal: false})
        checkOngoingRecovery(this.req({loan_doc_id: this.props.loan_doc_id})).then( (response) =>{
            if(!response){return}
            else{
                this.setState({ongoing: response.data.ongoing})
                if(response.data.ongoing){
                    this.setState({recovery_details: response.data})
                }
                else{
                    this.setState({disabled: false})
                }
            }
        } )



    }


    captureRecovery = () => {
        const request = {rec_id: this.state.recovery_details.id}
        captureRecovery(this.req(request)).then((response) => {
            if(response){
                window.location.reload()
            }
        })
    }

    handleOpen = () => {
        this.componentWillMount()
        this.setState({modal: true})

}

    handleClose = () => {
        this.setState({modal: false})
    }

    handleSubmit = () => {
        this.setState({disabled: true})
     


        const loan_recovery = {...this.recovery_txn, loan_doc_id: this.props.loan_doc_id, cust_id: this.props.cust_id, biz_name: this.props.biz_name}

       createRecoveryRequest(this.req({loan_recovery: loan_recovery})).then((response) => {
           if (response){
               this.handleOpen()
           }
           else{
               this.setState({disabled: false})
           }
        })


    }


    cancelRecovery = (event) => {

        if(window.confirm("Are you sure you want to cancel the collection?")) {
            cancelOngoingRecovery(this.req({rec_id: this.state.recovery_details.id})).then((response) => {
                if (response) {
                    this.handleOpen()
                }

            })
        }
    }



    render(){
        return(
            <>
                <div className="rcvry_btn">
                <Button className={'collectionPro_actBtn'} variant={'primary'} onClick={this.handleOpen}>{this.props.name}</Button>
                    </div>
                <Modal dialogClassName= "rec-form-modal" show={this.state.modal} onHide={this.handleClose}>
                    <Modal.Header closeButton show={this.state.modal} onHide={this.handleClose}>
                        <Modal.Title>Cash Payment Request {!(this.state.ongoing) && "Form"} <span className="rec-title-right"><b>{this.state.ongoing && title_case(this.state.recovery_details.status) + ' - '}{this.getCurrentStep()} of 3</b></span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.ongoing && <Button variant='link' style={{fontSize:"30px", borderRadius:"50%", padding: 0,float:'right', width: "35px"}} onClick={this.handleOpen}><GrRefresh id="ref-icon" onClick={this.rotateIcon} className={this.state.rotate ? "rotate-icon " : ""}/></Button>}
                        <div className="mb-4">
                            <h4><b>{this.props.biz_name}</b></h4>
                            <ul>
                                {this.state.recovery_details.status === 'pending_customer_otp' && <><li><p className="text-danger">{this.state.recovery_details.otp_info[0]}</p></li> <li><p className='text-danger'>{this.state.recovery_details.otp_info[1]}</p></li></>}
                                {!this.state.ongoing && <><li><p className="text-danger">Use this form if and only if customer requests to make cash payment.</p></li><li><p className="text-danger">Submitting this form will send an OTP to the customer</p></li></>}
                                {(this.state.recovery_details.status !== 'ready_to_collect') && <li><p className="text-danger"> <span className="text-uppercase font-weight-bold">Do not collect cash</span> from the customer until the customer sends OTP<br/></p></li>}
                                </ul>
                        </div>

                        <div className={'container rec-form '+`${(this.state.ongoing) && 'hide'}`}>
                            <div className='row'>
                            <div className = 'col-6 row'>
                                <div className="col-12">
                                <label>Requested Cash Payment Amount</label><font className="text-danger">   *  </font>
                                </div>

                            </div>
                                <div className='col-6 d-flex align-items-center'>
                                 <input type={'text'} className={'form-control'} id={'amount'} onChange={this.handleSelfChange} placeholder='Enter Amount' required />
                           </div>
                        </div>

                        </div>

                        {this.state.ongoing &&
                        <div class='container rec-form'>
                            <div class='row'>
                                <div class='col-6'>
                                    <h5>Cash Amount</h5>
                                </div>
                                <div class='col-6>'>
                                    <h5><b>{lbl_amt(this.state.recovery_details.amount, this.currency_code)}</b></h5>
                                </div>
                            </div>


                            <div class='row'>
                                <div class='col-6'>
                                    Initiated On
                                </div>
                                <div class='col-6>'>
                                    {format_month_change(this.state.recovery_details.created_at)}
                                </div>
                            </div>

                            <div class='row'>
                                <div class='col-6'>
                                    Status
                                </div>
                                <div class='col-6>'>
                                    {title_case(this.state.recovery_details.status)}
                                </div>
                            </div>

                            <div class='row'>
                                <div class='col-6'>
                                    Initiated by
                                </div>
                                <div class='col-6>'>
                                    {this.state.recovery_details.rs_name}
                                </div>
                            </div>
                            {this.state.recovery_details.status === 'ready_to_collect' && <p className="text-primary"><span className="text-uppercase font-weight-bold">Now collect cash of {lbl_amt(this.state.recovery_details.amount, this.currency_code)}</span>  from customer and click the button below to record the collection</p>}
                        </div>}
                    </Modal.Body>
                    <Modal.Footer>

                        {!this.state.ongoing && <Button variant={"primary"} disabled={this.state.disabled} onClick={this.handleSubmit}>Send OTP</Button>}
                        {this.state.ongoing && this.state.recovery_details.status === 'pending_customer_otp' && <Button variant='danger' onClick={this.cancelRecovery}>Cancel Payment Request</Button>}
                        {this.state.ongoing && this.state.recovery_details.status === 'pending_customer_otp' && <ResendCode entity_id={this.state.recovery_details.id} otp_type="confirm_recovery" buttonName="Resend OTP"/>}
                        {this.state.ongoing && this.state.recovery_details.status === 'ready_to_collect' && <Button variant='primary' onClick={this.captureRecovery}>Record Collection</Button> }
                    </Modal.Footer>


                </Modal>
            </>
        )
    }

    getCurrentStep() {
        let step = ""
        if(this.state.ongoing){
            if(this.state.recovery_details.status === 'pending_customer_otp'){
                step = "STEP 2"
            }
            else if(this.state.recovery_details.status === 'ready_to_collect'){
                step = "STEP 3"
            }
        }
        else{
            step = "STEP 1"
        }
        return step
    }

    rotateIcon = (event) =>{
        this.setState({rotate: true})
        setTimeout(() => {
            this.setState({rotate: false})
        },1500)

    }

}

export default Recovery
