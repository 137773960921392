
import React, {useState, useEffect} from "react"
import { Accordion, Card } from "react-bootstrap"
import { BsChevronDown, BsDot } from "react-icons/bs"
import { capitalize_first_letter_string, find_notification_time } from "../../helpers/common_helper"
import { Link } from "react-router-dom"
import { get } from "../../helpers/storage_helper"
import { GetAllNotifications, UpdateReadStatus } from "../../actions/notification_action"



const ViewAllNotification = ()=>{

    const [notifications, setNotifications] = useState([])

    const [selected_accordion ,setSelectedAccordion]  = useState([])
    const [loader,setLoader] = useState(false)

    const[req,setReq] = useState(null)
    const[no_notification,setNoNotification] = useState(false)

    const [selected_notification, setSelectedNotification] = useState(null)



    useEffect(()=>{
       let market = get('market')
       let  req = {country_code:market.country_code,
                    time_zone :market.time_zone,
                    
                }
            setReq(req)


        if(req){
            getNotifications(req)
        }

    },[])


    const getNotifications = (req)=>{
        GetAllNotifications(req).then((response)=>{
            setLoader(true)
            if(!response){
              setLoader(false)
              setNoNotification(true)
              return
            }
            if(response.status=='success'){
              if(response.data.length==0){
                setLoader(false)
                setNoNotification(true)
                
                return
        
              }
              setLoader(false)
              setNotifications(response.data)
            }
          })

    }

    const change_read = (notification)=>{
        if(notification.read == false){
            setLoader(true)
           
        
            UpdateReadStatus({...req,notification_id:[notification.id]}).then(response=>{
              if(!response){
               setLoader(false)
                return
               };
              if(response.status === 'success'){
                getNotifications(req)
              }}
            )
          }
          else{
            return
          }


    }


    const change_selected_accordin =(idx,notification)=>{
        if(selected_accordion.includes(idx)){
            const updatedAccordion = selected_accordion.filter((item) => item !== idx);
            setSelectedAccordion(updatedAccordion);
            // change_read(selected_notification)

        }
        else{
            setSelectedNotification(notification)
            const updatedAccordion = [...selected_accordion, idx];
            setSelectedAccordion(updatedAccordion);
        }

    }


    const get_accordrin_body = (addl_data)=>{
        let person_type =   addl_data && addl_data.ph_level == 'tm' ? '(TM)' : ''
        let par_day = addl_data && addl_data.par_day
        let name = addl_data.ph_level == 'overall' ? addl_data && addl_data.market_name : addl_data && addl_data.ph_level == 'tm' ? capitalize_first_letter_string(addl_data.tm_name) : capitalize_first_letter_string(addl_data.rm_name)
        let inc_prec = addl_data && addl_data.par_increase_perc

        return <p>PAR {par_day} for {name} {person_type} has increased by {inc_prec}% from last week. Please take necessary action on the same.</p>

    }




    return(
        <div>
            <div className='row d-flex fs-4 mb-5' style={{color:'white', fontSize:'25px', fontWeight:400, backgroundColor:'#626262', marginTop:'50px', padding:'25px 25px 25px 45px', borderRadius:'12px'}}>
                All Notifications
            </div>

            {loader==true ? <div className="product_takeup_loader"></div>
            :
            no_notification==true ?
            
            <div style={{marginTop:'20%', color:'white',fontSize:'18px', textAlign:'center'}}>

                <p>No Notifications Available</p>

            </div>:
            notifications && notifications.length >0 ?
            <div>
                {notifications.map((notification,idx)=>{
                    let type = notification.addl_data && notification.addl_data.ph_level == 'tm' ? " (TM)" :''
                    return(
                        <>
                        
                            <Accordion >
                                <Card className='mt-2 mb-0'>
                                <div className='non_empty_display_list'>
						            <div className="card-header w-90 card-header-icon card-header-primary home_header_d-flex_at_response justify-space-between" > 
                                    <div className='home_header_d-flex_at_response p-2'>
                                                    <h4 className="card-title text-white tableHead_font m-0">{notification.message_content.title}{type}</h4>
                                                    
                                    </div>
                                    <Accordion.Toggle className = "col d-flex" as = {Card.Header} eventKey="0" onClick={()=>{setSelectedNotification(notification);change_selected_accordin(idx,notification)}}>
                                        
                                                    <div className="ml-auto">{find_notification_time(notification.created_at)}{notification.read == false && <BsDot color="red" size={25}/>}</div>
                                                    <BsChevronDown className = {selected_accordion.includes(idx) ? "arrow-icon accordin-arrow down " : "arrow-icon accordin-arrow"} size="18" />
                                    </Accordion.Toggle>

                                    
                                    </div>
                                </div>
                                <div className="card-body no-padding">
                                <Accordion.Collapse eventKey="0" >
											<Card.Body style={{padding:'1%'}}>	
                                            
                                                <div style={{backgroundColor:'#A8A8A8', borderRadius:'10px'}}>
                                                {(notification.type=="flagged_customers"  || notification.type=="duplicate_disbursal_alert")  ?
                                             <p style={{fontSize:'24px', fontWeight:'400', color:'black', lineHeight:'30px',padding:'1.5%'}} className="m-0 ">{notification.message_content.addl_data.msg} </p>

                                            :
                                                <p style={{fontSize:'24px', fontWeight:'400', color:'black', padding:'1.5%'}} className="m-0 ">{get_accordrin_body(notification.message_content.addl_data)} <span>For more Details Please <Link to={{pathname:'/report/par_health_report', state:{...notification,from_notification:true} }} style={{cursor:'pointer', color:'#1684EA', fontSize:'24px'}}> Click here.</Link></span></p>
                                                 }
                                                </div>
									        </Card.Body>
										</Accordion.Collapse>
                                </div>
                                </Card>
                            </Accordion>
                    
                        </>
                    )
                })}

            </div>
            :''
            }
        
        </div>)

}


export default ViewAllNotification

