import React, { useContext } from 'react';
import DatePicker from 'react-datetime';
import { Accordion, AccordionContext, Card, Dropdown, Form, useAccordionToggle } from 'react-bootstrap';
import moment from 'moment';
import { FcCalendar } from 'react-icons/fc';
import { Link } from 'react-router-dom';
import CommonReactTable from '../../helpers/commonReact_table';
import FlowComponent from '../common/component/core/FlowComponent';
import ViewTransactions from '../loan/component/ViewTransactions';
import { dd_value, listMarkets, scheduleList } from '../../actions/common_actions';
import { searchLoan } from '../../actions/loan_actions';
import { Homeacc_prvdrLogoselector, format_date_tbl, lbl_amt, lbl_date, rt_algn }from '../../helpers/common_helper';
import { Config, check_priv, get } from '../../helpers/storage_helper';
// import Flag from "react-flags";
import { BsChevronDown } from 'react-icons/bs';
import Toggle from '../common/component/Toggle';
import CustomFlag from '../common/component/CustomFlag';
import { BasicReactTable } from '../../helpers/react_table';

function AccordionArrow({ eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
      <BsChevronDown className = {isCurrentEventKey ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow" }
                     size="18" onClick={decoratedOnClick}
                     style={{marginTop: "-52%", marginRight:"-66%"}}/>
  );
}


class Schedulercomponent extends FlowComponent{
    state = {
        get_list:{},
        row_datas:[],
        column_data:[],
        copy:[],
        loader:false,
        dorp_down_value:"",
        market_list: [],
        schedulerData:[],
        country_code: null,
        }

        componentWillMount(){
          if(get('market_list')){
            this.setState({market_list:get('market_list')})
        }
        else{
            listMarkets().then((response)=>{
                this.resp(response)
                if(!response){return}
                if(response){
                    this.setState({market_list:response.data})
                }
            }) 
        }
        if (this.props.show === 'home') {
          this.setState({ country_code: 'UGA' }, () => {
            this.handleSearch();
            if(Config('schdeuler_refresh')){
              setInterval(() => {
                this.handleSearch();
              },Config('schdeuler_refresh'))
            }
        
          });
        }  else {
            this.setState({
              get_list: {
                from: moment().format('YYYY-MM-DD'),
                to: moment().format('YYYY-MM-DD')
              }
            },()=>this.handleSearch())
          }
        }
  
        handleSearch = () => {	
          this.setState({loader:true})
          this.setState({copy:[],row_datas:[],column_data:[],schedulerData:[]})
          const{from,to}=this.state.get_list
          scheduleList(this.req({start_date:from,end_date:to,country:this.state.country_code}))
                  .then((response) => {
                    this.resp(response)
                  if(!response){
                  this.setState({loader:false})
                    return
                   };    
                   if (Object.keys(response.data).length > 0) {
                    this.setState({
                        schedulerData: response.data,
                        column_data: this.columndatas(),
                        loader: false
                    });
                } else {
                    this.setState({
                        loader: false
                    });
                    if(this.props.show=='reports'){alert("No results found.")}
                }
                
                    }
                  );			        
        }
        columndatas= ()=> {
          return[
            {
              Header : "SI No", 
              id: "row", 
              width:40,             
              Cell: (cell) => {
                return <div>{cell.row.index+1}</div>;
              }
            },
            {
              Header : () => (<div className='ml-5'>Name</div>),
              id: "country_code",
              width:400,
              accessor:row => <p>
                {/* <Flag id="market" name={row.country_code} format="png" pngSize={24} shiny={false} alt={row.country_code} basePath="/img/flags"/> */}
                <CustomFlag country_code={row.country_code} height={30} width={30} alt={row.country_code}/>

                <span className='ml-2'>{dd_value(row.name)}</span></p> 
            },
            {
              Header : () => (<>Status</>),
              width:150,
              id: "type",
              accessor : row => row.type,
              Cell: (row) => {
                return <div><p>{dd_value(row.value,'schedule_status')}</p> </div>;
              }
            },
       
            {
              Header : () => (<>Schedule Time</>),
              id: "schedule_time",
              width:this.props.show=='home'?150:200,
              accessor:row => <p>{row.schedule_time}</p> 
            },
            {
              Header : () => (<div >Run Time</div>),
              id: "runtime",
              width:150,
              accessor:row => <p >{row.runtime?row.runtime +'s':'N/A'}</p> 
            },
            {
              Header : () => (<div >Scheduler Date</div>),
              id: "created_at",
              width:220,
              accessor:row =><p >{format_date_tbl(row.created_at,"MMM DD YYYY")}</p> ,
            },
            
            {
              Header :() => (<>Failed Reason</>) ,
              id: "failure_message",
              accessor:row =>   <div className="d-flex flex-wrap">
                                 <p className='text-wrap' >{row.failure_message ? row.failure_message : 'N/A'}</p>
                               </div>
            },
            {
              Header :() => (<div className='text-center'>Action</div>) ,
              id: "action",
              width:120,
              accessor : row => this.runScheduler(row) ,
              show:check_priv('action','schedule_rerun')
            }
            
              ]
          }
          runScheduler = (row) => {
            let value;
        
            if(row.type == 'failed'){
              value = <div className='text-center'> <button className='btn btn-primary'>RERUN</button></div>
            }
            return  value
          }
         
        handleFilter = (e) => {
          const id = e.target.id;
          const value = e.target.value;
          this.setState(prevState => ({
            ...prevState,getreports: {...prevState.getreports,[id]: value}
          }));
        }
        handleDateChange = (property, date) => {
          const formattedDate = moment(date).format('YYYY-MM-DD');
          this.setState(prevState => ({
            get_list: {
              ...prevState.get_list,
              [property]: formattedDate
            }
          }));
        }
        handleSubmit = (event) => {
          const { from, to } = this.state.get_list;
          if (from || to) {
            if(!from||!to){
              alert('Check the both from and to fields are filled');
              return;
            }
          }
        
          if (from > to) {
            alert("Please enter a date range in the correct order");
            return;
          }
          this.handleSearch();
        }
        
    handleDropDown=(country_code, country)=>{
        this.setState({country_code:country_code})
        this.setState(state => state.dorp_down_value = country)
    }
    handleTabClick = (tabValue) => {
      this.setState({ activeTab: tabValue });
    };
    handleToggle = (toggle_val) => {
      const newCountryCode = toggle_val ? "RWA" : "UGA";
      this.setState({ country_code: newCountryCode }, () => {
        this.handleSearch();
      });
    }
       
      render() { 
          return (<>
            {check_priv("report","scheduler_list_view")&&<div className='scheduler'>
            {this.props.show!='home' &&<div style={{ marginBottom: "100px"}}> <h4 className='px-1'>Schedulers List</h4>
                
              <div className='row mt-5 '>
                <div className='col-sm-12 col-md-3 col-lg-2 '>

                  <div className=' m-2 row pl-0'>
                      <label className='col p-0' >  Country </label>
                      <label className='col-2 p-0 product_textr' htmlFor=''></label>
                      <div className='mt-4'>
                      <Dropdown>
                              <Dropdown.Toggle style={{width:"12vw"}}>
                                  {this.state.dorp_down_value.length>0 ? this.state.dorp_down_value : "Global"}
                              </Dropdown.Toggle>
                              <Dropdown.Menu style={{width:"12vw"}}>
                                  <Dropdown.Item onClick={()=>this.handleDropDown(null, "Global")}>Global</Dropdown.Item>
                                  {this.state.market_list.map((each, index)=>{
                                              return(
                                                  <Dropdown.Item onClick={()=>this.handleDropDown(each.country_code, each.country)}> <div className='mr-5'>{each.country}</div> 
                                                  {/* <Flag id="market" name={each.country_code} format="png" pngSize={48} shiny={false} alt={each.country_code} basePath="/img/flags"/> */}
                                                  <CustomFlag country_code={each.country_code} height={30} width={30} alt={each.country_code}/>
                                                  </Dropdown.Item>
                                          )})}
                              </Dropdown.Menu>
                          </Dropdown>
                          </div>
                  </div>
                  </div>
                <div className='col-sm-12 col-md-3 col-lg-2 '>
                      <div className=' m-2 row pl-0'>
                      <label className='col p-0' >  From </label>
                      <label className='col-2 p-0 product_textr' htmlFor='start_date'><FcCalendar size={30}/></label>
                      <DatePicker className='col-12 pr-1 p-0'  inputProps={{ id: 'start_date', name: 'start_date',autoComplete:'off' }}  closeOnSelect={true} defaultValue={moment(this.state.get_list.from).format("DD MMM YYYY" )} onChange={(date) => this.handleDateChange('from', date)}   dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
                      
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-3 col-lg-2 '>
                    <div className=' m-2 row pl-0'>
                        <label className='col p-0' > To</label> 
                        <label className='col-2 p-0 product_textr' htmlFor='end_date'><FcCalendar size={30}/></label>
                        <DatePicker className='col-12 pr-1 p-0' inputProps={{ id: 'end_date', name: 'end_date' ,autoComplete:'off'}} defaultValue={moment(this.state.get_list.to).format("DD MMM YYYY" )}  onChange={(date) => this.handleDateChange('to', date)}  dateFormat="DD MMM YYYY" timeFormat={false} required="required" />  
                    </div>
                    </div>

                    <div className={'col-md-3 text-center mt-5'}>
                        <button onClick={this.handleSubmit} className='btn btn-md product_takeup_submit' disabled={this.state.calling_api}>Search</button>
                    </div>
                    </div></div>}
              <div  className='settlement-border p-2'>
             { this.props.show=='home'&&<div className="scheduler_tab">
              <Toggle id="schedulertab" width="" leftLabel= "UGANDA" rightLabel="RWANDA" fontSize="15"  val={this.state.country_code==="UGA"?false:true} aligntext="right" set_tog_val={(res) => this.handleToggle(res)} />
              </div>}
                    <div className="card bg-black text-white m-0" style={{height:"63px"}}>
                        <div className="card-body" style={{marginTop:"10px",fontSize:"20px"}}><b className='text-light'>Scheduler List</b></div>
                    </div>
              <div >
              <div className="card p-0 text-white m-0" >
                  <div className="card-body p-0 home_header_d-flex_at_response justify-space-between">
                      <Accordion.Toggle className = "col invisible" as = {Card.Header} eventKey="0" >
                          <BsChevronDown className = { "arrow-icon accordin-arrow"} size="18" style={{marginTop: "-38%", marginRight:"-66%"}}/>
                      </Accordion.Toggle>
                      <h4 className="card-title p-0 w-100 text-white  ">
                          <div className='d-flex justify-content-around p-0 row' style={{fontSize: "13px", alignItems: "center", marginTop: "10px"}}>
                              <div className='col-md-2' style={{textAlign:"center"}}>Type</div>
                              <div className='col-md-2' style={{textAlign:"center"}}>No Of Failed</div>
                          </div>
                      </h4>
                  </div>
              </div>
                        
             { this.state.schedulerData&&Object.keys(this.state.schedulerData).length > 0&&!this.state.loader&& 
                <div>
                        <Accordion>

                {Object.keys(this.state.schedulerData).map((groupType,index) => (
                    <div key={groupType}>
                        <Card className={`scheduler-${groupType}`}>
                            <div className="card-header mx-0 card-header-icon card-header-primary home_header_d-flex_at_response p-0">
                                <Accordion.Toggle className = "col" as = {Card.Header} eventKey={index + 1} >
                                        <div>
                                            <AccordionArrow eventKey={index + 1}/>
                                                        </div>
                                                    </Accordion.Toggle>
                                                    <Accordion.Toggle className="card-title w-100 text-white mt-2" as='h4' eventKey={index + 1} >
                                                    <div className='d-flex justify-content-around mt-1 py-2 mp-1 row' style={{fontSize: "13px", alignItems: "center"}}>
                                                            <div className='col-md-2' style={{textAlign:"center"}}>{groupType}</div>
                                                            <div className='col-md-2' style={{ textAlign: "center" }}>
                                                            <span className={`badge  ${this.state.schedulerData[groupType].filter(item => item.type === 'failed').length > 0? 'badge-danger': 'badge-success'}`} style={{ color: 'white' }}>
                                                                {this.state.schedulerData[groupType].filter(item => item.type === 'failed').length}
                                                            </span>
                                                        </div>
                                                        </div>
                                                    </Accordion.Toggle>
                                                </div>
                                                <div className="card-body no-padding justify-content-center">
                                                    <Accordion.Collapse eventKey={index + 1}>
							                            <Card.Body className='settl-card-background m-1'>
                                                            {/* <CommonReactTable showPag={this.state.schedulerData[groupType]&&this.state.schedulerData[groupType].length>10?true:false} defaultPageSize={10} minRow={0} row_data={this.state.schedulerData[groupType]} column_data={this.state.column_data} resizable={false} exportCSV={false} csvFile={`Table for ${groupType}`}   defaultSorted={[{id:"type",desc:false}]}  csvData={this.state.schedulerData[groupType]} /> */}
                                                            {this.state.schedulerData[groupType]&&this.state.schedulerData[groupType].length>0 &&

                                                              <BasicReactTable pagination={this.state.schedulerData[groupType].length> 9 ?true:false} row_data={this.state.schedulerData[groupType]} column_data={this.state.column_data} csv_file={false} defaultSorted={[{id:"type",desc:false}]} default_page_size={10}/>
                                                             } </Card.Body>
						                            </Accordion.Collapse>
                                                </div>
                                            </Card>

                    </div>
                ))}
                </Accordion>

            </div>
              } {Object.keys(this.state.schedulerData).length <= 0&&!this.state.loader&&<p style={{color:'rgb(101, 109, 131)'}} className='t text-center mt-4'>No Data Found</p>}
               </div>
               {this.state.loader&&<>
                  <p className='text-light mt-5 text-center' >Loading...</p>
                  <div className="product_takeup_loader "></div></>
                }
               </div>
              </div>}</>
  
  
               
              );
      }
  }

            
export default Schedulercomponent;
