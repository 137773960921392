import React from 'react';
import '../../../styles/flow.css';
import { Button} from 'react-bootstrap';
import { Link , Navigate} from 'react-router-dom';
import FlowComponent from '../../common/component/core/FlowComponent';
import {capitalize_first_letter,rt_algn,format_date, getValueFromLangJson} from '../../../helpers/common_helper';
import {lbl_repayment_amt,lbl_flow_fee_obj,lbl_amt} from './../../../helpers/product_helper';
import {generateAgreement, getExistingAgreements, updateAggrStatus} from '../../../actions/agreement_actions';
import {dd_value} from '../../../actions/common_actions';
import DatePicker from 'react-datetime';
import {viewCustomerDetails} from '../../../actions/loan_appl_actions'; 
import { check_priv, get } from '../../../helpers/storage_helper';
import withRouter from '../../../Router/Withrouter';
import { BasicReactTable } from '../../../helpers/react_table';

const $ = require('jquery');
$.dataTable = require('datatables.net');
class CreateAgreement extends FlowComponent {

    state = {
        productList: null,
        list: false,
        upload: false,
        create: false,
        toUpload: false,
        create_agreement: {aggr_doc_id: {}},
        prompt_inactivate: "hide_div",        
        rowdatas:[],        
        columndatas:[],                    
        copy:[]
    }

    
    handleProductCheckbox = (event) => {
        if(event.target.checked){
            var product_ids = this.product_ids.slice();
            product_ids.push(event.target.id);
            this.product_ids = product_ids;

        }else{

            this.product_ids = this.product_ids.filter(item => item != event.target.id);
            //this.product_ids = product_ids;         
        }
    }
    
    inactivateAggr = (event) => {
      const aggr_id = event.target.id
      const req = this.req({aggr_id, "cust_id": this.cust_id})
      
      updateAggrStatus(req)
        .then((response) => {
          if(!response){return };
            if(response.status === "success"){    
               alert(response.message);
              this.setState({agreement_tr: null, agreements: null, prompt_inactivate: false}); 
              this.onLoad();            
              //this.generateAgreementClick(null);
            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
            
          }
        );
    }

    onLoad() {

      var prompt_inactivate = false;
      const request = {"cust_id": this.cust_id};
      getExistingAgreements(this.req(request))
      .then((response) => {
          if(!response){return };
            if(response.status === "success"){    
                if(response.data.prompt_inactivate || this.props.mode == "list"){
                    prompt_inactivate = response.data.prompt_inactivate
                    var agreement_tr = null                                          
                    //  agreement_tr = response.data.agreements.map((agreement,i)=>
                    // <tr><td>{agreement.aggr_doc_id}</td>
                    //   <td>{capitalize_first_letter(agreement.aggr_type)}</td>
                    //   <td>{format_date(agreement.valid_from)}</td>
                    //   <td>{format_date(agreement.valid_upto)}</td>
                    //   <td> {dd_value(agreement.status)}</td>
                    //   <td> {agreement.status != 'inactive' ? 
                    //          <input type="button" id={agreement.id} value="Inactivate" onClick={this.inactivateAggr} className="btn btn-primary" />
                    //          :
                    //         <label>NA</label>}
                    //   </td>
                    //   <td> {agreement.aggr_file_rel_path ? 
                    //         <Link to={agreement.aggr_file_rel_path} target="_blank">View PDF</Link>
                    //         :
                    //         <label>NA</label>}
                    //   </td>
                    // </tr>
                    // );
                    this.setState(state=>state.columndatas = this.agreementcolumndatas())
                    this.setState({agreement_tr: agreement_tr,
                                   agreements: response.data.agreements, 
                                   rowdatas:response.data.agreements,
                                   copy:response.data.agreements,
                                   list: true, create: false,
                                   prompt_inactivate
                                 });                  
                      // $('#list_agreements').dataTable({
                      //     "order" : [],
                      //   "bAutoWidth": true,
                      //   "bDestroy": true
                      // });
                    
                }else{
                    this.setState({list: false, create: true});
                    //this.product_ids = [];
                    this.create_agreement = {};
                    this.get_product_list()
                }

            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
           
          }
        );
    }  
    agreementcolumndatas () {
      return [
        {
          Header : getValueFromLangJson("agreement_doc_id"),
          id: "aggr_doc_id",
          accessor:row => {return row.aggr_doc_id}
        },
        {
          Header : getValueFromLangJson("agreement_type"),
          id: "aggr_type",
          accessor:row => {return capitalize_first_letter(row.aggr_type)}
        },
        {
          Header : getValueFromLangJson("valid_from"),
          id: "valid_from",
          accessor:row => {return format_date(row.valid_from)}
        },
        {
          Header : getValueFromLangJson("valid_upto"),
          id: "valid_upto",
          accessor:row => {return format_date(row.valid_upto)}
        },
        {
          Header : getValueFromLangJson('status'),
          id: "status",
          accessor:row => {return dd_value(row.status)}
        },
        {
          Header :getValueFromLangJson('action'),
          id: "id",
          show:check_priv("cust_agreement", "inactive"),
          accessor:row => {return row.status != 'inactive' ? 
                            <input type="button" id={row.id} value="Inactivate" disabled={get("role_codes") == "loan_approver"} onClick={this.inactivateAggr} className="btn btn-primary" />
                            :
                          <label>NA</label>}
        },
        {
          Header : getValueFromLangJson("view"),
          id: "aggr_file_rel_path",
          accessor:row => {return row.aggr_file_rel_path ? 
                            <Link to={row.aggr_file_rel_path} target="_blank">{getValueFromLangJson("view_pdf")}</Link>
                            :
                          <label>NA</label>}
        },
      ]
    }  

    handleGenerateAggr = (event) => {
        
        var date = new Date(), new_date;
        date.setDate(date.getDate() + 180); 
        new_date = date.toISOString().substr(0,10);
        
        const resp = window.confirm("Are you sure to generate a new agreement?");
        if(resp == false){
          return;
        }
        if(this.create_agreement.valid_upto > new_date){
          alert("Agreement validity cannot be more than 180 days");
        }
        else {
          const cust_agreement = {"product_ids": this.product_ids, "cust_id": this.cust_id, "valid_from": this.create_agreement.valid_from, "valid_upto": this.create_agreement.valid_upto,"country_code":this.country_code};
          generateAgreement(this.req({cust_agreement}, true))
          .then((response) => {
            this.resp(response);
            if(!response){return };
              if(response.status === "success"){    
                
                this.aggr_doc_id = response.data.aggr_doc_id; // TODO
                this.setState({toUpload: true, btn_style: ""});
                
              }else{
                alert(response.message + "\n" + JSON.stringify(response.data));  
              }
              
            }
          );
        }
    }

    get_product_list(){
      const req_parameter = this.cust_id;
     viewCustomerDetails(this.req({req_parameter}, true))
          .then((response) => {
             this.resp(response);
            if(!response) {return;}
             
              const result = response.data;
              if(result.hasOwnProperty("borrower")){
                  this.productList(result);
              }else if(result.hasOwnProperty("borrowers")){
                 // this.borrowersList(result);    
             }
          });
    }


    componentWillMount(){    

      if(process.env.REACT_APP_USE_ROOT_ONLY_DEV === undefined){
         this.root = "";
      }else{
           this.root = "http://localhost:8000"; 
      }
      this.cust_id = this.props.params.cust_id
      this.product_ids = [];
      const comp_id = this.props.id;
      this[comp_id] = {};
      this.onLoad();
      
    }
    productcolumndatas () {
      return [
        {
          Header : "Select",
          id: "id",
          accessor:row => <input id={row.id} type="checkbox" onChange={this.handleProductCheckbox} defaultChecked/>
        },
        {
          Header : "Product Name",
          id: "product_name",
          accessor:row => {return row.product_name}
        },
        {
          Header : "Product Type",
          id: "product_type",
          accessor:row => {return capitalize_first_letter(row.product_type)}
        },
        {
          Header : () => (<div className='text-center'>FA Amount</div>),
          id: "max_loan_amount",
          accessor:row => {return rt_algn(lbl_amt(row.max_loan_amount, this.currency_code))}
        },
        {
          Header : "Duration",
          id: "duration",
          accessor:row => {return row.duration+'days'} 
        },
        {
          Header : "Flow Fee",
          id: "ids",
          accessor:row => {return rt_algn(lbl_flow_fee_obj(row, this.currency_code))}
        },
        {
          Header : "Repayment",
          id: "rep_amt",
          accessor:row => {return rt_algn(lbl_repayment_amt(row, this.currency_code))}
        },
      ]
    }

    productList(result){
        this.setState(state=>state.copy = result.loan_products)
        this.setState(state=>state.rowdatas = result.loan_products)
        this.setState(state=>state.columndatas = this.productcolumndatas())
        result.loan_products.map((loan_product, i) => 
            this.product_ids.push(loan_product.id)
            );
        
        // const productList =  result.loan_products.map((loan_product,i)=>
        //     <tr><td><input id={loan_product.id} type="checkbox" onChange={this.handleProductCheckbox} defaultChecked/></td>
        //     <td> {loan_product.product_name}</td>
        //     <td> {capitalize_first_letter(loan_product.product_type)}</td>
        //     <td> {rt_algn(lbl_amt(loan_product.max_loan_amount, this.currency_code))}</td>
        //     <td> {loan_product.duration} days</td>
        //     <td> {rt_algn(lbl_flow_fee_obj(loan_product, this.currency_code))}</td>     
        //     <td> {rt_algn(lbl_repayment_amt(loan_product, this.currency_code))}</td>
           
        //     </tr>

        // );
        this.setState({ list: false, create: true, upload: false});
        // this.setState({productList: productList});     
        //  $('#list_products').dataTable({
        //       "bAutoWidth": true,
        //       "bDestroy": true, 
        //       "searching": false,
        //       "pageLength": 10
        //     });
       
    }

  handleUploadSignedAgr = (event) => {
    this.setState({file_upload: true});
  }


listAgreements = () => (

    <div>
    {(this.state.agreement_tr != null || this.props.mode == "list") &&
                        <div>
                            {/* <table id ='list_agreements' className={"table"}>
                                <thead class="text-info">
                                    <tr>
                                      <th>Agreement Doc ID</th>
                                      <th>Agreement Type</th>
                                      <th>Valid From</th>
                                      <th>Valid Upto</th>
                                      <th>Status</th>
                                      <th>Action</th>
                                      <th>View</th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    {this.state.agreement_tr}
                                </tbody>
                             </table>  */}
                             <div className='d-flex justify-content-between align-items-center' style={{padding:5}}>
                                <p className='no-margin text-white'><b>{getValueFromLangJson("total_entries")}: {this.state.copy.length}</b></p>
                                <div style={{position:'relative'}}>
                                  <i class="fa-solid fa-magnifying-glass search_pos"></i>
                                  <input placeholder='Search here' className='search_posInput' onChange={(e)=>this.changeHandler(e.target.value)}></input>
                                  {/* <button className='btn-std btn btn-primary' onClick={()=>this.resetHandler()}>Reset</button> */}
                                </div>
                             </div>
                           
                              <BasicReactTable row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} pagination={this.state.copy && this.state.copy.length > 9 ? true : false} FName={"Agreements"} export_csv_datas={this.state.copy ? this.state.copy : []}  defaultSorted={[{id:"aggr_doc_id",desc:false}]}  default_page_size = {10} csv_file={true} searchData={[{title:'Ageements',cust_id:this.props.params.cust_id}]}/>  	              
                        </div>
                    }
    </div>

)

changeHandler = (e) => {      
  const toFilter = e
  const filterfrom = this.state.rowdatas  
  const filteredData = filterfrom.filter(value => {    
      if(this.props.mode === "list"){
        return(
        value.aggr_doc_id !== null && value.aggr_doc_id.toLowerCase().includes(toFilter.toLowerCase()) ||
        value.aggr_type !== null && value.aggr_type.toLowerCase().includes(toFilter.toLowerCase()) ||
        value.status !== null && value.status.toLowerCase().includes(toFilter.toLowerCase()) 
        )           
      }else{
        return(
          value.product_name !== null && value.product_name.toLowerCase().includes(toFilter.toLowerCase()) ||
          value.product_type !== null && value.product_type.toLowerCase().includes(toFilter.toLowerCase())
        )         
      }              
  })
  this.setState(state=>state.copy = filteredData)		
}


createAgreement = () => (
                  <div>
                    <div className="row no-margin">
                        <div className="col-md-5 no-padding">
                            <h3 className='headerTitle headerPadvert no-margin'>Customer: {this.cust_id}</h3>
                        </div>          
                    </div>                    
                    <div className="row no-margin">
                        <div className='col-md-2'></div>
                        <div className="col-md-4">                                                                                      
                          <label className='no-margin'>Valid From</label><font className="text-danger">   *  </font>
                          <DatePicker closeOnSelect={true} onChange={(value) => {this.handleSelfDateChange("valid_from", value)}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>                                                      
                        </div>                        
                        <div className="col-md-4">                            
                          <label className='no-margin'>Valid Upto</label>
                          <DatePicker closeOnSelect={true} onChange={(value) => {this.handleSelfDateChange("valid_upto", value)}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>                                
                        </div>
                        <div className='col-md-2'></div>
                    </div>
                    <br/>
        
                    {(this.state.rowdatas != null) &&
                        <div>
                            {/* <table id ='list_products' className="table">
                                <thead className={"text-info"}>
                                    <tr>
                                      <th>Select</th>
                                      <th>Product Name</th>
                                      <th>Product Type</th>
                                      <th>FA Amount</th>
                                      <th>Duration</th>
                                      <th>Flow Fee</th>                                 
                                      <th>Repayment</th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    {this.state.productList}
                                </tbody>
                             </table>   */}
                             <div className='d-flex justify-content-between align-items-center' style={{padding:5}}>
                                <p className='no-margin text-white'><b>Total Entries : {this.state.copy.length}</b></p>
                                <div style={{position:'relative'}}>
                                  <i class="fa-solid fa-magnifying-glass search_pos"></i>
                                  <input placeholder='Search here' className='search_posInput' onChange={(e)=>this.changeHandler(e.target.value)}></input>
                                  {/* <button className='btn-std btn btn-primary' onClick={()=>this.resetHandler()}>Reset</button> */}
                                </div>
                             </div>
                           
                              <BasicReactTable row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} pagination={this.state.copy && this.state.copy.length > 9 ? true : false} FName={"Product List"} export_csv_datas={this.state.copy ? this.state.copy : []}  defaultSorted={[{id:"product_name",desc:false}]}  default_page_size = {10} csv_file={true} searchData={[{title:'Ageements',cust_id:this.props.params.cust_id}]}/>                                          
                        </div>
                    }
                </div>
)






    render(){
        if (this.state.toUpload === true) { 
          return <Navigate to={`/agreement/upload/${this.cust_id}/${this.aggr_doc_id}`}/>
        }
        
        return(
          <div className="container max-width containerTopmargin no-padding">
            {/*<div className="row">
                  <Link to="#" onClick={this.generateAgreementClick} className="agreement">Generate New Agreement</Link>                        
                  <Link to={"/agreement/upload"} onClick={this.uploadAgreementClick} className="agreement" target="_blank">Sign / Upload Agreement</Link>
            </div>*/}
              <div className="row no-margin">
                <h3 className='no-margin headerTitle headerPadvert'>{this.state.create && "Select Products to create new Agreement"}</h3>
                <h3 className='no-margin headerTitle headerPadvert'>{this.state.list && getValueFromLangJson("existing_agreements")+ ":"  + this.cust_id}</h3>
                
               
              </div>
                {this.state.prompt_inactivate && this.props.mode != 'list' && !this.state.create &&
                      <div className="row">
                        <b>Below Agreements already attached to the Customer. You have to inactivate if they are no longer valid, to create new Agreement.</b>
                </div>}                       

                {this.state.create && this.createAgreement()}
                {this.state.list && this.listAgreements()}                
              
              <div className="row no-margin">                
                <div className="col-md-12 no-padding">                   
                  {this.state.create && <Button disabled={this.state.calling_api} variant="primary" onClick={this.handleGenerateAggr}>Generate Agreement</Button>}
                 {/* {this.state.list && <Button variant="primary" onClick={this.inactivateAllClick}>Inactivate All</Button>}*/}                  
                  
                </div>
              </div>
          
          </div>
            );
        }
    

}

export default withRouter(CreateAgreement) ;