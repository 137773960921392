import React from 'react';
import '../../../styles/flow.css';
import { } from 'react-router-dom';
import {listLoanTxn} from '../../../actions/loan_actions';
import FlowContainer from '../../common/container/core/FlowContainer';
import {reduce_character,format_date,capitalize_first_letter} from '../../../helpers/common_helper';
import {lbl_amt} from '../../../helpers/product_helper';
import {TooltipLabel} from '../../common/partial';
import {dd_value} from '../../../actions/common_actions';

const $ = require('jquery');
$.dataTable = require('datatables.net');

class ViewTxnContainer extends FlowContainer {

	state = {
		loanTxnList: null
	}

	componentWillMount(){
		
		const loan_doc_id = this.props.loan_doc_id;

		listLoanTxn(this.req({loan_doc_id}))
        .then((response) => {
          if(!response){return };

		          const loanTxnList = response.data.map((loan_txn,i)=>
		              <tr key={i}>
		                <td>{++i}</td>
			            <td>{dd_value(loan_txn.txn_type)}</td>
			            <td>{lbl_amt(loan_txn.amount, this.currency_code)}</td>
			            <td>{format_date(loan_txn.txn_date,true)}</td>
			            <td><TooltipLabel full_text={reduce_character(loan_txn.from_ac_text)} overlay={loan_txn.from_ac_text} /></td>
			            <td><TooltipLabel full_text={reduce_character(loan_txn.to_ac_text)} overlay={loan_txn.to_ac_text} /></td>
			            <td>{loan_txn.txn_id}</td>
			            <td>{dd_value(loan_txn.txn_mode)}</td>
			            <td>{capitalize_first_letter(loan_txn.txn_exec_by)}</td>
		              </tr>
		          );
	         
          		  this.setState({loanTxnList: loanTxnList});
         
        });

	}
	componentDidUpdate(){
	$('#list_txn').dataTable({
		  "order" : [],
	      "bAutoWidth": true,
	      "bDestroy": true, 
	      dom:'Bfrtip',
          buttons: [
            'csv','excel','print'
           ]
	    });
	}

	render(){
		return(
		<div className="container">
			{(this.state.loanTxnList != null) &&
				<div id='datatable' className={"table=responsive"}>
		            <table id ='list_txn' className={"table"}>
		                <thead className={"text-info"}>
		                  <tr><th>S.No</th>
		                      <th>Transaction Type</th>
		                      <th>Transaction Amount</th>
		                      <th>Transaction Date / Time</th>
		                      <th>From Account</th>
		                      <th>To Account</th>
		                      <th>Transaction ID</th>
		                      <th>Transaction Mode</th>
		                      <th>Txn Executed By</th>
		                      </tr></thead>
		                      <tbody> 
		                      {this.state.loanTxnList}
		                      </tbody>
		             </table>
		                    
		        </div>
		    }

		    </div>
		
			);
		}
	

}

export default ViewTxnContainer;