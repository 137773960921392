import { get_path } from '../helpers/common_helper';
import {call_fetch} from './../helpers/fetch_helper';

export const custFollowUpTask = request => {
    return call_fetch(get_path('app') + '/churn_management/cust_to_follow_up', request)
}
export const getCustToRetain = request => {
    return call_fetch(get_path('app') + '/churn_management/cust_to_retain', request)
}
export const getPrevContCust = request => {
    return call_fetch(get_path('app') + '/churn_management/prev_cont_cust', request)
}

export const getChurnCallLog = request => {
    return call_fetch(get_path('app') + '/churn_management/churn_call_log', request)
}

export const churnReportSearch = request => {
    return call_fetch(get_path('app') + '/churn_management/churn_report', request)

}

export const churnReportDetails = request => {
    return call_fetch(get_path('app') + '/churn_management/churn_call_details', request)

}

