import React from 'react';
import { Modal , Button,Badge, Col} from 'react-bootstrap';
import {dd_value,get_record_audits} from '../../../actions/common_actions';
import {format_date_tbl} from '../../../helpers/common_helper';
import FlowComponent from './core/FlowComponent';
import ReactTable from "react-table"; 
import { BasicReactTable } from '../../../helpers/react_table';


class RecordAudit extends FlowComponent {

state = {
			
		modalBox2 : false,
		status_history : [],
		audit_type: "status_change",

		columns :[]

	}

	StatusModalClose = () =>{
		this.setState({modalBox2:false})
	}

	audit_changes = (event) => {

		const request = { "record_code" : this.props.record_code, "audit_type" : this.props.audit_type }
		get_record_audits(this.req(request))
		.then((response) =>{
			if(!response){return };
				if(response.status === "success"){
					//this.setState({status})
					var columns = []
					columns = [
								{  
								Header: 'Change Time',  
								// accessor: 'created_at',
								id:"Date",
								accessor:row => format_date_tbl(row.created_at,true),
										     },
								{  
								Header:  this.props.title+ " " +'Before',  
								accessor: 'data_before',
								Cell : ({row})=>row.original.data_before
								
										     },
								{  
								Header: this.props.title+ " " +'After',
								

								accessor:'data_after',
								style: { whiteSpace: 'unset' },
                  						
								width: 400,
								Cell : ({row})=>row.original.data_after
								},
								{
									Header: "Initiated By",
								

									id:'initiated_by',
									accessor:row => <div style={{textTransform:'uppercase'}}>{row.initiated_by == null ? "NA" : row.initiated_by}</div>,
											
								},
								{  
								Header: 'Remarks',  
								accessor: 'remarks' ,
								style: { whiteSpace: 'unset' },
								Cell : ({row})=>row.original.remarks
								
								},


								]
					this.setState({status_history : response.data,columns, row_data :response.data&&response.data.filter(item => {
						const dataAfter = JSON.parse(item.data_after);
						return dataAfter.status !== null;
					  })}, ()=>this.setState({modalBox2:true}));

					
				}
		})

		this.setState({modalBox2:true})
	}




	render (){
		
		return (

			<Modal show={this.state.modalBox2} onHide={this.StatusModalClose} size="xl" className='recordAuditbox'> 
				<Modal.Header closeButton show={this.state.modalBox2} onHide={this.StatusModalClose}>
					<Modal.Title style={{fontWeight : "bold"}}> {this.props.title+ " "  + 'Change History'} {dd_value(this.props.cust_id)}</Modal.Title>
				</Modal.Header>				
				
				<BasicReactTable default_page_size={10}  row_data={this.state.row_data || []} column_data={this.state.columns} />

			</Modal>
		)

			}

}

export default RecordAudit;