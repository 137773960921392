import {getMasterData} from '../../../actions/common_actions';
import React from 'react'
import ReactDOM from 'react-dom'
import {Form} from 'react-bootstrap';
import DatePicker from 'react-datetime';
import FlowComponent from '../../common/component/core/FlowComponent';
import moment from 'moment';
import { format } from 'date-fns';
import {check_priv, get,get_item} from '../../../helpers/storage_helper';
import {getRefAccounts} from '../../../actions/account_txn_actions';
import Select from "react-select";

class ProductUpgrade extends FlowComponent{

    state ={
       
    }

    componentWillMount(){
        this.handleCurdate()
        this.load_flow_rel_mgr(null,"multiselect_rm_name");
        var associated_with =  "flow";
        this.setOptionsToStateFromApi("rel_mgr", {...this.def_req, associated_with}, null, "kyc");
        this.setOptionsListToStateFromAppApi('common', 'list', {...this.def_req ,status: "enabled", biz_account: true}, ['acc_prvdr_code', 'name'], "acc_prvdrs");

        const lender_code = this.lender_code; //event.target.value
    
        let acc_prvdr_code = get('acc_prvdr_code');
    
        const account = {lender_code, "country_code": this.country_code,to_recon : true, network_prvdr_code : acc_prvdr_code }
        
        if(acc_prvdr_code != undefined){
          getRefAccounts(this.req({account},true))
            .then((response) => {
              if(!response){return };
              if(response.status === "success"){
                if(response.data != "")
                {
                  this.getSelectOptionsFromResult(response.data, "lender_accounts", ['id','name']);
                }     
              }else{ 
                alert(response.message + "\n" + JSON.stringify(response.data));  
              }
            }
          );
        } 
    
    }

    handleCurdate(){
        const date = new Date()
        const cur_date = format(date, 'dd MMM y') 
        return cur_date
    }

    handleidChange = (id, value) => {
        var event = {};
        event.target = {id, value};

        this.handleChange(event);
      }


    render(){
        return(

        <div>
            
            <Form className="mb-3" >
	        	<Form.Row className = "my-4" style={{display:"flex",alignItems:"center"}}>
                    
                    <div className="col-3">
                        <span style={{color:"white"}}>Start Date</span><font className="text-danger"> * </font>
                        <DatePicker defaultValue={this.handleCurdate()} onChange={(value) => {this.handleDateChange("start_date", value)}}   closeOnSelect={true}     dateFormat="DD MMM YYYY" timeFormat={false}  />
                    </div>

                    <div className="col-3">
                        <span style={{color:"white"}}>End Date</span><font className="text-danger"> * </font>
                        <DatePicker defaultValue={this.handleCurdate()} onChange={(value) => {this.handleDateChange("end_date", value)}}   closeOnSelect={true}     dateFormat="DD MMM YYYY" timeFormat={false}  />
                    </div> 

                    <div className="col-md-3">
                        <span style={{color:"white"}}>Account Provider</span>
                            <select id="acc_prvdr_code"onChange={(e) => {this.handleChange(e)}} className="form-control" type="text" required="required">
                            {this.state.acc_prvdrs_dd}
                            </select>
                    </div> 
                    
                    <div className="col-3" style={{color:"white"}}>
                        <span style={{color:"white"}}> Status</span>
                            <Form.Control as="select" id = "status" onChange={(e)=>{this.handleChange(e);this.props.handleStatus(e)}}>
                            {this.getSelectOptions("fa_upgrade_status")}
                            </Form.Control>
                    </div>
                    

                    </Form.Row>

                    <Form.Row className='my-4'>
                   
                   

                    
                    <div className="col-3 mt-4" style={{color:"white"}}>
                    <Select onChange={(e) =>{this.props.handlePurposeChange(e,"rm_id");}}
                      value = {this.state.select_value}
                      options={this.state.flow_rel_mgr}
                      placeholder="Flow Relationship Manager"
                      styles={this.select_custom_styles()}
                      classNamePrefix="my-select" // Add a custom class prefix to target the components
                      isMulti/>
                    </div>

                    <div className="col-3" style={{color:"white"}}>
                        <span style={{color:"white"}}>Cust ID</span>
                        <Form.Control id="cust_id" onChange={this.handleChange}></Form.Control>  
                    </div>
                    {this.props.status == 'approved' && 
                    <div className= {`col-3 show_div`} style={{color:"white"}}>
                            <span style={{color:"white"}}>Amount Range For Upgraded Amount</span>
                            <Form.Control as= "select" id="amt_rang" onChange={this.handleChange} >
                                <option value = ''>choose</option>
                                <option value = '1M'>less than 1M {this.currency_code}</option>
                                <option value = '1M_to_2M'>1M {this.currency_code} to 2M {this.currency_code} </option>
                                <option value = '2M_to_3M'>2M {this.currency_code} to 3M{this.currency_code} </option>
                                <option value = '3M'>above 3M {this.currency_code} </option>
                                
                                
                        </Form.Control>  
                    </div>
                  }
                    
                     
                    </Form.Row>

                   
                </Form>
                
        </div>         
        )
    }
   

}

export default ProductUpgrade