import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import DatePicker from 'react-datetime';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { FcCalendar } from 'react-icons/fc';
import { searchLoan } from '../../../actions/loan_actions';
import { Homeacc_prvdrLogoselector, format_date_tbl, getValueFromLangJson, lbl_date, rt_algn } from '../../../helpers/common_helper';
import {lbl_amt} from './../../../helpers/product_helper';
import { dd_value } from '../../../actions/common_actions';
import { Link } from 'react-router-dom';
import ViewTransactions from '../../loan/component/ViewTransactions';
import CommonReactTable from '../../../helpers/commonReact_table';
import { check_priv } from '../../../helpers/storage_helper';
import { BasicReactTable } from '../../../helpers/react_table';

class Overduereport extends FlowComponent {
    state = {
      getreports:{"voided_fa" : "false",status:"Overdue"},
      row_datas:[],
      column_data:[],
      copy:[],
      loader:false

      }

      componentWillMount(){
        this.setOptionsListToStateFromAppApi('common', 'list', {...this.def_req ,status: "enabled", biz_account: true}, ['acc_prvdr_code', 'name'], "acc_prvdr");
        this.load_flow_rel_mgr();
      }

      handleSearch = () => {	
        this.setState({loader:true})
        this.setState({copy:[],row_datas:[],column_data:[]})
        var mode="searchbtn"
        const loan_search_criteria = this.state.getreports;
        loan_search_criteria.mode=mode;
        
          searchLoan(this.req({loan_search_criteria}, true))
                .then((response) => {
                  this.resp(response)
                  this.setState({search_btn_style : ""});
                if(!response){
                this.setState({loader:false})
                  return
                 };    
                  if(response.data.mode == 'search'){
								    this.setState(state=>state.copy = response.data.results)
                    this.setState(state=>state.row_datas = response.data.results)
                    this.setState(state=>state.column_data = this.columndatas())
                    this.setState({loader:false})              
    
                       }else{
                        this.setState({loader:false})
                        alert("No results found.");   		
                       }
                  }
                );			        
      }
      columndatas= ()=> {
        return[
          {
            Header : getValueFromLangJson('fa_id'), 
            id: "loan_doc_id",				       
            accessor: (row) => <Link className='link-doc-id' to={"/fa/view/"+row.loan_doc_id} target="_blank">{row.loan_doc_id}</Link>			
          },
          {
            Header : getValueFromLangJson('ac_number'),
            id: "acc_number",
            accessor:row => <div className='d-flex align-items-center'> <Homeacc_prvdrLogoselector logotype={row.acc_prvdr_code}/> <p className='no-margin' style={{paddingLeft:'20px'}}> {row.acc_number}</p>  </div> 
          },
          {
            Header : () => (<div className='text-center'>RM</div>),
            id: "flow_rel_mgr_name",
            accessor:row => <p className='text-center m-0'>{row.flow_rel_mgr_name}</p> 
          },
          {
            Header :()=> (<div className='text-center'>{getValueFromLangJson("bz_name")} | {getValueFromLangJson('cust_name')}</div>),
            id: "biz_name",
            accessor:row =>  <p className='text-center m-0'>
            {row.cust_name}
            <br />
            {row.biz_name}
          </p>
          },
          {
            Header : () => (<div className='text-center'>{getValueFromLangJson("reg_date")}</div>),
            id: "reg_date",
            accessor:row =><p className='text-center m-0'>{format_date_tbl(row.reg_date)}</p> 
          },
          {
            Header : () => (<div className='text-center'>{getValueFromLangJson("disb_date")}</div>),
            id: "disbursal_date",
            accessor:row =><p className='text-center m-0'>{moment(row.disbursal_date).format('DD MMM YYYY')}</p> 
          },
          {
            Header :() => (<div className='text-center'>{getValueFromLangJson("due_date")}</div>) ,
            id: "due_date",
            accessor:row => <p className='text-center m-0'>{format_date_tbl(row.due_date)}</p>
          },
          {
            Header : () => (<div className='text-center'>{getValueFromLangJson("last_visit_date")}</div>),
            id: "last_visit_date",
            accessor:row =><p className='text-center m-0'>{format_date_tbl(row.last_visit_date)}</p> 
          },
          {
            Header : () => (<div className='text-center'>{getValueFromLangJson("fa_amount")} ({this.currency_code})</div>), 
            id: "loan_principal",
            accessor:row => <div className='d-flex justify-content-center'>{ rt_algn(lbl_amt(row.loan_principal))}</div>,
          },
          {
            Header : () => (<div className='text-center'>{getValueFromLangJson("fee")} ({this.currency_code})</div>),
            id: "flow_fee",
            accessor:row => <div className='d-flex justify-content-center'>{ rt_algn(lbl_amt(row.flow_fee))},</div>
          },
          {
            Header : () => (<div className='text-center'>{getValueFromLangJson("paid_amount")} ({this.currency_code})</div>),
            id: "paid_amount",
            accessor:row => <div className='d-flex justify-content-center'>{ rt_algn(lbl_amt(row.paid_amount))}</div>,
          },
            {
              Header :() => (<div className='text-center'>{getValueFromLangJson("channel")}</div>) ,
              id: "channel",
              accessor:row => <div className='d-flex justify-content-center'>{dd_value(row.channel,'channel')}</div>,
              style : {textAlign:"center"}
              },
            {
              Header :() => (<div className='text-center'>{getValueFromLangJson('overdue_days')}</div>) ,
              id: "overdue_days",
              accessor:row => <div className='d-flex justify-content-center'>{ row.overdue_days}</div>,
              style : {textAlign:"center"}
            },
            {
              Header :() => (<div className='text-center'>{getValueFromLangJson('action')}</div>) ,
              id: "action",
              accessor:row => <div className='d-flex justify-content-center'>{<ViewTransactions parent="loan_search" loan_doc_id={row.loan_doc_id}/>}</div>,
              style : {textAlign:"center"}
            },
            ]
        }
      handleFilter = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        this.setState(prevState => ({
          ...prevState,getreports: {...prevState.getreports,[id]: value}
        }));
      }
      handleDateChange = (property, date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        this.setState(prevState => ({
          getreports: {
            ...prevState.getreports,
            [property]: formattedDate
          }
        }));
      }
      handleSubmit = (event) => {
        const { disbursal_date__from, disbursal_date__to } = this.state.getreports;
        if (!disbursal_date__from || !disbursal_date__to || disbursal_date__from === "Invalid date" || disbursal_date__to === "Invalid date") {
          alert(getValueFromLangJson("please_fill_all_the_mandatory_fields"));
          return;
        }
      
        if (disbursal_date__from > disbursal_date__to) {
          alert(getValueFromLangJson("please_enter_a_date_range_in_correct_order"));
          return;
        }
        this.handleSearch();
      }
      

     
    render() { 
        return (<>
        {check_priv("report","overdue_report")&&
          <div className='mt-5'>
          <h4 className='px-1'>{getValueFromLangJson('overdue_fas_report')}</h4>
        
        <div className='row mt-5 '>
        <div className='col-sm-12 col-md-3 col-lg-2 '>
              <div className=' m-2 row pl-0'>
              <label className='col p-0' >{getValueFromLangJson('disbursed_from')}<span className='product_red_star'>*</span></label>
              <label className='col-2 p-0 product_textr' htmlFor='start_date'><FcCalendar size={30}/></label>
              <DatePicker className='col-12 pr-1 p-0' inputProps={{ id: 'start_date', name: 'start_date',autoComplete:'off' }} defaultValue={this.state.disbursal_date__from} closeOnSelect={true}  onChange={(date) => this.handleDateChange('disbursal_date__from', date)}   dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
              
              </div>
            </div>
             <div className='col-sm-12 col-md-3 col-lg-2 '>
            <div className=' m-2 row pl-0'>
                <label className='col p-0' >{getValueFromLangJson("disbursed_to")}<span className='product_red_star'>*</span></label> 
                <label className='col-2 p-0 product_textr' htmlFor='end_date'><FcCalendar size={30}/></label>
                <DatePicker className='col-12 pr-1 p-0' inputProps={{ id: 'end_date', name: 'end_date' ,autoComplete:'off'}} defaultValue={this.state.disbursal_date__to}  closeOnSelect={true}   onChange={(date) => this.handleDateChange('disbursal_date__to', date)}  dateFormat="DD MMM YYYY" timeFormat={false} required="required" />  
            </div>
            </div>
            <div className={`col-sm-12 col-md-3 col-lg-2  `}>
             <div className=' m-2 row pl-0'>
                <label className='col-12 p-0' >{getValueFromLangJson('account_provider')}</label>
                <div className='w-100 col-12 m-0 p-0'>
                <Form.Control className='mt-3 col-12 p-0' as="select" id="acc_prvdr_code" defaultValue={this.state.dropdown_value}  onChange={(e) =>this.handleFilter(e)}>          
                  <option  value={""}>All</option>
                  {this.state.acc_prvdr_dd}
	              </Form.Control>
                </div>
              </div>
              
            </div>
            <div className={`col-sm-12 col-md-3 col-lg-2 `}>
             <div className=' m-2 row pl-0'>
                <label className='col-12 p-0' >{getValueFromLangJson("flow_rel_mgr")}</label>
                <div className='w-100 col-12 m-0 p-0'>
                <Form.Control className='mt-3 col-12 p-0' as="select" id="flow_rel_mgr_id" defaultValue={this.state.dropdown_value}  onChange={(e) =>this.handleFilter(e)}>          
                  <option  value={""}>All</option>
                  {this.state.flow_rel_mgr_dd} 
	              </Form.Control>
                </div>
              </div>
              
            </div>
            <div className={'col-md-3 text-center mt-5'}>
                <button onClick={this.handleSubmit} className='btn btn-md product_takeup_submit' disabled={this.state.calling_api}>{getValueFromLangJson("search")}</button>
            </div>
            </div>
           { this.state.row_datas&& this.state.row_datas.length>0&&!this.state.loader&&
           <div className='Overdue Report mt-5'>

            {/* <CommonReactTable showPag={true} defaultPageSize={10} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.column_data} resizable={false} defaultSorted={[{id:"disbursal_date",desc:true}]} exportCSV={true} csvFile={"Overdue Report"} csvData={this.state.copy} searchData={[{...this.state.getreports,title:'Search Float Advance'}]}/>  */}

            <BasicReactTable row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.column_data}  pagination={this.state.copy.length > 9 ? true : false} default_page_size = {10} csv_file={true} defaultSorted={[{id:"disbursal_date",desc:true}]} searchData={[{...this.state.getreports,title:'Search Float Advance'}]} FName={"Overdue Report"} /> 
            </div>
            }
             {this.state.loader&&<>
                <p className='text-light mt-5 text-center' >{getValueFromLangJson('loading')}...</p>
                <div className="product_takeup_loader "></div></>
              }
            


            </div>}</>


             
            );
    }
}
 
export default Overduereport;