import React from 'react';
import { Navigate } from 'react-router-dom';
import '../../../styles/flow.css';
import '../../market/container/datatables.css';
import {title_case} from '../../../helpers/common_helper';
import FlowContainer from '../../common/container/core/FlowContainer';
import _ from 'lodash';
import CommonReactTable from '../../../helpers/commonReact_table';
import {Card,Button} from 'react-bootstrap';
import { format } from 'date-fns';
import {getSMSReports} from '../../../actions/report_actions';
import SMSReport from '../component/SMSReport';
import { dd_value } from '../../../actions/common_actions';
import { BasicReactTable } from '../../../helpers/react_table';

class SMSReportContainer extends FlowContainer{

    state ={
        columns :[],
        report_list:[],
        sms_report_list:{},
        sms_report_table:"hide_div"

    }

    componentWillMount(){

        this.handleCurDate()
    }

    handleCurDate = () =>{

        const date = new Date()
        const cur_date = format(date, 'yyyy-MM-dd')
        const sms_report_list = this.state.sms_report_list
        sms_report_list['start_date'] = cur_date
        sms_report_list['end_date'] = cur_date
        this.setState({sms_report_list})
        this.handleSearch()

    }

    handleUpperCase = event => {
        const result = event.toUpperCase();
        const str_replace = result.replace(/_/g," ") 
        return str_replace
    }
    handleSearch =(event)=>{

        getSMSReports(this.req({sms_report_criteria: this.state.sms_report_list}))
        .then((response) => {
            this.resp(response)
            if(!response){return };
                if(response.status === "success"){
                        this.resp(response)
                        var columns = this.state.columns

                            columns = [{  
                                            Header: 'Vendor Code',  
                                            accessor: 'vendor_code',
                                           
                                        },{  
                                            Header: 'Direction',  
                                            id :'direction',
                                            accessor : row =>title_case(row.direction),
                                           
                                        },{  
                                            Header: 'Status',  
                                            id :'status',
                                            accessor : row =>title_case(row.status),
                                        },{  
                                            Header: 'Purpose',  
                                            id :'purpose',
                                            accessor: row => row.purpose == null ? "NA" : (dd_value(row.purpose)),                          
                                        },{  
                                            Header: 'Counts',  
                                            accessor :'sms_counts',
                                            
                                        },]
                    }
                    this.setState({columns,report_list:response.data,sms_report_table:"show_div"})           
        })

}


    render(){
        return(

            <div className="container containerTopmargin max-width no-padding">            
                <form className="mb-3" autocomplete="off">
                    <Card>
                        <Card.Header style = {{backgroundColor: "#01062c" , color: "white"}}><h4>SMS Reports</h4></Card.Header>
                        <Card.Body style={{padding:15}}>
                                <SMSReport  id="sms_report_list" onComponentChange={this.handleComponentChange} data = {this.state.sms_report_list}/>   
                                <div className="form-group row reset-margin justify-content-center py-4">
                                    <Button disabled={this.state.calling_api} className= {`col-md-2 mr-3 btn btn-primary`} onClick={this.handleSearch}>SUBMIT</Button>
                                </div>
                        </Card.Body>  
                    </Card>
                </form>
                {!_.isEmpty(this.state.sms_report_list) && 
                    <div className={`mt-3 ${this.state.sms_report_table}`} >						
                        {/* <CommonReactTable showPag={true} minRow={0} row_data={this.state.report_list !== undefined ? this.state.report_list : []} column_data={this.state.columns} resizable={false} exportCSV={true} csvFile={"sms"} csvData={this.state.report_list ? this.state.report_list : []} searchData={[{...this.state.sms_report_list,title:'Search SMS Reports'}]}/>   */}

                        <BasicReactTable row_data={this.state.report_list !== undefined ? this.state.report_list : []} column_data={this.state.columns} pagination={this.state.report_list && this.state.report_list.length > 9 ? true : false} FName={"sms"} export_csv_datas={this.state.report_list ? this.state.report_list : []} searchData={[{...this.state.sms_report_list,title:'Search SMS Reports'}]}  default_page_size = {10} csv_file={this.state.report_list && this.state.report_list.length > 0 ? true : false}/>  				
                    </div>
                }
            </div>
        )
    }
}export default SMSReportContainer 