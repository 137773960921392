import React from 'react';
import MarketContainer from '../UI/market/container/market_container';
import ListMarketContainer from '../UI/market/container/list_market_container';
import LenderContainer from '../UI/lender/container/lender_container';
import ListLenderContainer from '../UI/lender/container/list_lender_container';
import LenderAccountStmtTransactionsContainer from '../UI/lender/container/lender_acc_stmt_txns_container';
import WatchlistCustomers from '../UI/borrower/component/WatchlistCustomers';
import ListDataProviderContainer from '../UI/data_provider/container/list_data_provider_container';
import DataProviderContainer from '../UI/data_provider/container/data_provider_container';
import FieldVisitContainer from '../UI/field_visit/container/field_visit_container';
import LenderAccountStmtDisbursedFaTransactionsContainer from '../UI/lender/container/lender_acc_stmt_disb_fa_container';
import UnknownTransactions from '../UI/lender/container/UnknownTransactions';
import AccountProviderContainer from '../UI/account_provider/container/account_provider_container';
import ListAccProviderContainer from '../UI/account_provider/container/list_acc_prvdr_container';
import ProductContainer from '../UI/loan_product/container/product_container';
// import listProductContainer from '../UI/loan_product/container/list_product_container';
import ListProductContainer from '../UI/loan_product/container/list_product_container';
import searchProductContainer from '../UI/loan_product/container/search_loan_product';
import MasterDataKeyContainer from '../UI/master_data/container/master_data_key_container';
import ListMasterDataKeyContainer from '../UI/master_data/container/list_master_data_key_container';
import MasterDataContainer from '../UI/master_data/container/master_data_container';
import AppUserContainer from '../UI/user/container/app_user_container';
//import ListAppUserContainer from './../UI/user/container/list_app_user_container';
import CreateMasterAgreement from '../UI/agreement/component/CreateMasterAgreement';
import ListAgreementContainer from '../UI/agreement/container/list_agreement_container';
import ScoreModels from '../UI/score_models/component/ScoreModels';
import ScoreFactors from '../UI/score_models/component/ScoreFactors';
import CSWeightage from '../UI/score_models/component/CSWeightage';
import ScoreResultConfig from '../UI/score_models/component/ScoreResultConfig';
import KPIReportContainer from '../UI/reports/container/kpi_report_container';
import CommonReportContainer from '../UI/reports/container/common_report_container';
import CustomerLocations from '../UI/user/container/Customerslocation';
// import GrowthChart from './../UI/reports/component/GrowthChart';
import CreateAgreement from '../UI/borrower/component/CreateAgreement';
import SettlementComponent from '../UI/AccountSettlement/SettlementComponent'

//import SearchAccountStmtTxnContainer from './../UI/account/container/search_account_stmt_txn_container';
import ListAccountTxnContainer from '../UI/account/container/list_account_txn_container';

import ListTodayAccountStmtTxnContainer from '../UI/account/container/list_today_account_stmt_txn_container';
import CheckIn from '../UI/field_visit/component/CheckIn';
// import Schedule from './../UI/field_visit/component/Schedule';
import CallLog from '../UI/call_log/component/CallLog';
import CallLogContainer from '../UI/call_log/container/call_log_container';
import LeadContainer from '../UI/lead/container/lead_container';
import LeadSearchContainer from '../UI/lead/container/lead_search_container';
import LeadAuditContainer from '../UI/lead/container/lead_audit_container';

import PreApprovalContainer from '../UI/pre_approval/container/pre_approval_container';


import DisbursalReport from '../UI/reports/container/disburse_report_container';
import ManagementDashboard from '../UI/reports/container/management_dashboard';
import MonthlyReport from '../UI/reports/container/monthly_report';
import {ReportChartLive} from "../UI/reports/container/Report_chart_live";
import ComplaintLists from "../UI/borrower/component/ComplaintLists";


import CustomReportContainer from '../UI/reports/container/CustomReportContainer';
import StmtImportSearchContainer from '../UI/stmt_import/container/stmt_import_search_container';
import SMSLogsContainer from '../UI/lender/container/Sms_logs_container';
import SMSReportContainer from '../UI/reports/container/sms_report_container';
import switch_sms_vendors from '../UI/lender/container/Switch_sms_container';


import Whatsapp from '../UI/utility/Component/whatsapp';
import QrCodeModal from '../UI/utility/Component/QrCodeModal';
import Assign_container from '../UI/rm_target/container/Assign_container';
import GdsReport from '../UI/reports/container/ReportTables';
import CSRosters_container from '../UI/cs_management/container/cs_rosters_container';

import ListManualCaptureTxns from '../UI/lender/container/list_manual_capture_txns';
import BusinessOperations from '../UI/reports/container/Business_operations'
import ItOperation from '../UI/reports/container/It_operation'
import AddAccountStmtTxnsContainer from '../UI/lender/container/add_acc_stmt_container.js';
import ActiveCustWoFAContainer from '../UI/customer_filter/container/active_cust_wo_fa';
import AgreementRenewalContainer from '../UI/customer_filter/container/active_cust_wo_agg_renewal';
import DailyAgreementReportContainer from '../UI/reports/container/Daily_agree_report_container';
import Product_takeup_report from '../UI/reports/component/Product_takeup_report';
import InboundCallList from '../UI/call_log/container/inbound_call_list_container';
import Partial_payment_report from '../UI/reports/component/Partial_payment_report';
import Switch_sms_container from '../UI/lender/container/Switch_sms_container';
import CohortReport  from '../UI/reports/component/Cohort_report';
import SearchReKYCLeads from '../UI/lead/container/SearchReKYCLeads';
import OntimePaymentContainer from '../UI/reports/container/ontime_payment_container';
import ChrunManagementContainer from '../UI/customer_filter/container/chaurn_management';
import SearchProductUpgrades from '../UI/loan_product/container/search_product_upgrade';
import Overduereport from '../UI/reports/component/Overdue_report';
import LeadPipeline from '../UI/lead/container/LeadPipeline';
import ChurnReports from '../UI/reports/component/ChurnReports';
import SelfRegContainer from '../UI/lead/container/SelfRegContainer';
import SelfRegistrationReport from '../UI/reports/component/SelfRegistrationReport';
import PARHealthReport from '../UI/reports/component/PARHealthReport';
import Schedulercomponent from '../UI/Scheduler/Schedulercomponent';
import FSDUReport from '../UI/reports/component/FSDUReport';
import SwitchReportsContainer from '../UI/reports/container/FloatSwitchReports';
import OverdueCallTasks from '../UI/TaskRequests/Container/OverdueCallTasks';
import RecoveryFile from '../UI/TaskRequests/Component/RecoveryFile';
import PendingRmVisits from '../UI/TaskRequests/Container/PendingRmVisits';
// import CallLogReports from '../UI/reports/container/CallLogReports';
import RmRmsCallsReport from '../UI/reports/container/RmRmsCallsReport';
// import RmVisitReport from '../UI/reports/container/RmVisitReport';

import CallLogReports from '../UI/reports/container/CallLogReports';
import DisbAccountSwitch from '../UI/account/container/manual_account_switch.js';
import FlaggedCustomers from '../UI/lender/container/FlaggedCustomers';


import ViewAllNotification from '../UI/Notification/view_all.js';

import FreshChatChart from '../UI/reports/container/fresh_chat_chart.js';
import Coo_dashboard from '../UI/reports/container/Coo_dashboard';
import OtpView from '../UI/otp_view.js';
import CashbacksReports from '../UI/reports/component/CashbacksReports';
import Softphone from '../UI/cs_management/container/cs_soft_phone';
import CsRmsoActivityReport from '../UI/cs_management/container/CsRmsoActivityReport';
import DuplicateTransactions from '../UI/reports/duplicate_txns_report.js';

const admin_routes = [


    {path:'/market/create',element: <MarketContainer mode='create' title='Create Market'/>} ,
    {path:'/market/list', element:<ListMarketContainer />},
    {path:`/market/view/:country_code`,element: <MarketContainer mode='view' title="View/Edit Market" />},
  

    {path:'/lender/create',element: <LenderContainer mode='create' title="Create Lender"/>},
    {path:'/lender/list',element: <ListLenderContainer/>},
    {path:'/lender/view/:lender_code',element: <LenderContainer mode='view' title="View/Edit Lender"/>},
    {path:'/lender/accounts',element: <LenderAccountStmtTransactionsContainer/>},
    {path:'/unknown_txns',element: <UnknownTransactions />},
    {path:'/unknown_txns/:today',element: <UnknownTransactions today = 'true'/>},
    {path:'/unimported_txns',element: <UnknownTransactions unimported = 'true'  />},
    {path:'/unimported_txns/:today',element: <UnknownTransactions unimported = 'true' today = 'true' />},
    {path:'/lender/accounts/todayfa',element: <LenderAccountStmtDisbursedFaTransactionsContainer/>},
    {path:'/manual_capture_txns',element: <ListManualCaptureTxns />},
    {path:'/statement/add',element: <AddAccountStmtTxnsContainer />},
    {path:'/watchlist_customers',element: <WatchlistCustomers />},


    {path:'/data_provider/create',element: <ListDataProviderContainer mode='create' title="Create Data Provider" />},
    {path:'/data_provider/list',element: <ListDataProviderContainer />},
    {path:'/data_provider/view/:data_prvdr_code',element: <DataProviderContainer mode='view' title="View/Edit Data Provider" />},
    {path:'/account_provider/create',element: <AccountProviderContainer mode='create' title="Create Account Provider" />},
    {path:'/account_provider/list',element: <ListAccProviderContainer />},


    {path:'/fa_product/create',element: <ProductContainer mode='create' title="Create FA Product" />},
    {path:'/fa_product/view/:product_id',element: <ProductContainer mode='view' title="View/Edit FA Product"/>},
    {path:'/fa_product/list',element: <ListProductContainer/>},
    {path:'/fa_product/search_product',element: <searchProductContainer />},
  
    {path:'/master_data_key/add',element: <MasterDataKeyContainer />},
    {path:'/master_data_key/list',element: <ListMasterDataKeyContainer />},
    {path:'/master_data/add',element: <MasterDataContainer />},

    {path:'/settlement/list',element: <SettlementComponent />},
    {path:'/app_user/create',element: <AppUserContainer />},


    {path:'/agreement/create',element: <CreateMasterAgreement title="Create Master Agreement" id="master_agreement" />},
    {path:'/agreement/list',element: <ListAgreementContainer />},
    {path:'/agreement/list/:cust_id',element: <CreateAgreement mode="list" id="create_agreement"  />},


    {path:'/score_models',element: <ScoreModels id="score_model"  />},
    {path:'/score_models/weightage/:score_model?/:score_model_name?',element: <CSWeightage id="csw_comp" />},
    {path:'/score_models/result_config/:score_model?',element: <ScoreResultConfig id="result_config" />},
    {path:'/score_factors',element: <ScoreFactors />},


    {path:'/report/monthly_kpi_report',element: <KPIReportContainer type="monthly" />},
    {path:'/report/current_kpi_report',element: <KPIReportContainer type="current" />},
    {path:'/report/portfolio_quality',element: <CommonReportContainer report_type="portfolio_quality" with_date_range = "false" key={"report_portfolio_quality"}   />},
    {path:'/report/product_takeup',element: <Product_takeup_report />},
    {path:'/cohort_report',element: <CohortReport />},
    {path:'/overdue_report',element: <Overduereport />},
    {path:'/report/penalty_pending_report',element: <CommonReportContainer report_type="penalty_pending_report" with_date_range = "false"  key={"report_penalty_pending_report"} />},
  
    {path:'/report/daily_activity',element: <CommonReportContainer report_type="daily_activity" with_date_range = "true" check_rel_mgr = "true"   key={"report_daily_activity"}/>},
    // {path:'/report/growth_chart',element: <GrowthChart id="growth_chart" />},
    // {path:'/report/growth_chart_for/:cust_id',element: <GrowthChart id="growth_chart" />},
    {path:'/report/overdue_fa_repayments',element: <CommonReportContainer report_type="overdue_fa_repayments" with_date_range = "true" check_rel_mgr = "true"    key={"overdue_fa_repayments"}/>},

   

    {path:'/report/daily_agreements',element: <DailyAgreementReportContainer report_type="daily_agreements" />},
    {path:'/report/daily_visits',element: <CommonReportContainer key={"daily_visits"} report_type="daily_visits" with_date_range = "true" check_rel_mgr = "true" />},
    {path:'/report/capital_funds',element: <CommonReportContainer  key={"capital_funds"} report_type="capital_funds"  with_date_range = "false"  />},
    {path:'/report/risk_report',element: <CustomReportContainer report_type="risk_category_report"  with_date_range = "false" />},
    {path:'/report/lead_conversion',element: <CommonReportContainer  key={"lead_conversion"} report_type="lead_conversion"  with_date_range = "true" />},
    {path:'/report/self_registration_report',element: <SelfRegistrationReport  />},


    {path:'/report/par_health_report',element: <PARHealthReport  />},
    {path:'/report/rm_report',element: <CommonReportContainer report_type="rm_wise_repayment_rate"  with_date_range = "true"   key={"rm_wise_repayment_rate"}  />},
    {path:'/report/rm_productivity_report',element: <CommonReportContainer report_type="rm_productivity_report" page_render={true}   with_date_range = "true"  key={"rm_productivity_report"} />},
    {path:'/report/disbursal_report',element: <DisbursalReport  />},
    {path:'/management-dashboard',element: <ManagementDashboard  />},

    {path:'/cust_location',element: <CustomerLocations  />},
    {path:'/portfolio_at_risk',element: <GdsReport report_type="portfolio_at_risk"  />},
    {path:'/customer_performance',element: <GdsReport report_type="customer_performance"   />},
    {path:'/outstanding_advances',element: <GdsReport report_type="outstanding_advances"   />},

    {path:'/partial_payments',element: <Partial_payment_report report_type="partial_payments"   />},
    {path:'/duplicate-txns-report', element: <DuplicateTransactions/>},
    {path:'/monthly-report',element: <MonthlyReport  />},
    {path:'/rm_performance',element: <Coo_dashboard  />},
    {path:'/charts',element: <ReportChartLive  />},
    {path:'/complaint_lists',element: <ComplaintLists  />},

    {path:'/stmt_imports/search',element: <StmtImportSearchContainer  />},

    {path:'/business_operations/:type',element: <BusinessOperations  />},
    {path:'/it_operations/:type',element: <ItOperation  />},
    {path:'/report/rm_wise_report',element: <CommonReportContainer  key={"rm_wise_report"}   report_type="rm_wise_report"/>},

    {path:'/report/sms_report',element: <SMSReportContainer  />},
    {path:'/report/ontime_pay_report',element: <OntimePaymentContainer  />},

    {path:'/churn_reports',element: <ChurnReports  />},
    {path:'/report/fsdu',element: <FSDUReport  />},
    {path:'/report/call_log_reports',element: <CallLogReports  />},

    {path:'/rm_rms_call_report',element: <RmRmsCallsReport report_type="rm_rms_calls"  key={"rm_rms_calls"}  />},
    {path:'/rm_visits_report',element: <RmRmsCallsReport report_type="rm_visits"  key={"rm_visits"}  />},
    {path:'/switch_reports',element: <SwitchReportsContainer  />},

    {path:'/report/call_log_reports',element: <CallLogReports  />},
    {path:'/flagged_customers',element: <FlaggedCustomers  />},
    {path:'/fresh_chat_chart',element: <FreshChatChart  />},
    {path:'/cashbacks_report',element: <CashbacksReports  />},

    // {path:'/acc_txns/create:acc_id',element: <CreateAccountTxnContainer  />},
    {path:'/acc_txns/:entity/:entity_code/:acc_id',element: <ListAccountTxnContainer  />},
    {path:'/account/:acc_id/statement',element: <ListTodayAccountStmtTxnContainer  />},
    {path:'/switch/disb_account',element: <DisbAccountSwitch  />},
   

    {path:'/field_visit/search',element: <FieldVisitContainer  />},
    {path:'/field_visit/search/:cust_id',element: <FieldVisitContainer  />},
    {path:'/checkout/:cust_id/:id',element: <CheckIn  />},
    // {path:'/field_visit/reg_schedule',element: <Schedule id="schedule"  />},


    {path:'/call_log/search/:cust_id',element: <CallLogContainer  />},
    {path:'/call_log/search',element: <CallLogContainer  />},
    {path:'/compl_call_log/:cust_id/:id',element: <CallLog  />},


    {path:'/sms_logs',element: <SMSLogsContainer  />},


    {path:'/switch_sms_vendors',element: <Switch_sms_container  title="SMS Vendors"  />},
    {path:'/pre_appr/list',element: <PreApprovalContainer  />},

    {path:'/lead/create',element: <LeadContainer mode='create' title="Create Lead"   />},

    {path:'/lead/edit/:id',element: <LeadContainer mode='view' title="View/Edit Lead"   />},
    {path:'/lead/search',element: <LeadSearchContainer mode='search' title="Search Lead"    />},
    {path:'/lead/audit_kyc/:id',element: <LeadAuditContainer title="Audit KYC"  />},
    {path:'/whatsapp',element: <Whatsapp  />},
    {path:'/whatsapp/connect',element: <QrCodeModal  />},
    {path:'/search_rekyc_leads',element: <LeadSearchContainer search_rekyc={true} title="Search Re-KYC Profiles"   />},
    {path:'/lead/lead_pipeline',element: <LeadPipeline title="Lead Pipeline"  />},
    {path:'/lead/self_registration/:id',element: <SelfRegContainer  />},

  
    {path:'/management-dashboard/print',element: <ManagementDashboard  />},
    {path:'/assign/rm_target',element: <Assign_container  title="Monthly Target For RMs"  />},
    {path:'/monthly-report/print',element: <MonthlyReport  />},
    {path:'/business_operations/print',element: <BusinessOperations  />},


    {path:'/business_operations/print',element: <ItOperation  />},
    {path:'/cs_management/cs_rosters',element: <CSRosters_container  title="Soft Phone Rosters"  />},
    {path:'/activity_report',element: <CsRmsoActivityReport />},
    // {path:'/cs_managements/soft_phone',element: <Softphone  />},
    {path:'/cs_managements/soft_phone',element: <Softphone  type="cs_softphone" />},
    {path:'/cust_follow_up',element: <ChrunManagementContainer  />},
    {path:'/active_cust_wo_fa/list',element: <ActiveCustWoFAContainer  />},
    {path:'/aggrement_renewal/list',element: <AgreementRenewalContainer  />},
    {path:'/inbound_call_list',element: <InboundCallList title = "Toll Free Call List"  />},
    {path:'/search_product_upgrade',element: <SearchProductUpgrades title = "Search Product Upgrade"   />},
    {path:'/scheduler/list',element: <Schedulercomponent show='reports'  />},
  
    {path:'/call_tasks',element: <OverdueCallTasks  />},

        


    {path:'/recovery_file/:loan_doc_id',element: <RecoveryFile  />},
    {path:'/pending_rm_visits',element: <PendingRmVisits  />},
    {path:'/view_all_notifications',element: <ViewAllNotification  />},
    {path:'/otp/:acc_prvdr',element: <OtpView  />},









]


export default admin_routes;








          

