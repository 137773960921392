import React, { Component } from "react";
import ReactDOM from "react-dom";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <span style={{color:"#050404",fontSize:"13px"}}>{props.label}</span>
      </components.Option>
    </div>
  );
};

const customStyles = {
  option: (base, { data, isDisabled, isFocused, isSelected }) => {
  return {
    ...base,
    backgroundColor: isFocused || isSelected ? "#C0BDBD" : "white",
    ':hover': {
      backgroundColor: '#A4A1A1',
      color: 'white',
    },
  };
}
};



export default class MultiSelecter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionSelected: null
    };
  }


  handleChange = (selected) => {
    this.setState({
      optionSelected: selected
    });
   
  };

  componentDidMount(){
    this.setState({
      optionSelected: this.props.default_pardays
    });
  }

  componentDidUpdate(prevProps,prevState){
    if(prevProps.chart_view != this.props.chart_view && this.props.chart_view){
      this.setState({
        optionSelected: this.props.default_pardays[0]
      })}
      else if(prevProps.chart_view != this.props.chart_view && !this.props.chart_view){
        this.setState({
          optionSelected: this.props.default_pardays
        });
      
    }
  }
  

  render() {
    this.props.set_attr({pardays:this.state.optionSelected});
   
    return (
        <div className="parchart">
            <span className="text-white ml-4">Par Days</span>

            <span
                    class="d-inline-block"
                    data-toggle="popover"
                    data-trigger="focus"
                    data-content="Please selecet account(s)"
                    style={{width:"280px",color:'black',marginLeft:"15px"}}
                >
                    <ReactSelect
                    options={this.props.pardays}
                    styles={customStyles}
                    isMulti={this.props.isMulti}
                    closeMenuOnSelect={!this.props.isMulti}
                    hideSelectedOptions={false}
                    components={{
                        Option
                    }}
                    onChange={this.handleChange}
                    allowSelectAll={true}
                    value={this.state.optionSelected}
                    />
            </span>
        </div>
    
    );
  }
}
