import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import '../../../styles/flow.css';
import {createCSResultScore, getCSResultConfig} from '../../../actions/score_model_actions';
import {dd_value} from '../../../actions/common_actions';
import {check_priv} from '../../../helpers/storage_helper';
import withRouter from '../../../Router/Withrouter';

class ScoreResultConfig extends FlowComponent {
	state = {
		score_result_div : []
	}
	componentWillMount(){

		this.valid = false;
		this.result_config = {}
		this.score_results = {}
		this.score_model = this.props.params.score_model;
		

		const request = {"csf_model": this.score_model, "country_code": this.country_code};
		getCSResultConfig(this.req(request))
		.then((response) => {
          if(!response){return }; 
          if(response.status == "success"){
	          if(response.data.cs_result_config_details.length > 0){
	          	
	          	response.data.cs_result_config_details.map((result_config, index) => {
		          	const row = index + 1;
		          	this.score_results[row] = {}
		          	//this.result_config[row] = {}
		          	this.score_results[row].code = result_config.score_result_code;
		          	this.score_results[row].score_from = result_config.score_from;
		          	this.score_results[row].score_to = result_config.score_to;
		          	this.result_config['score_from_'+row] = result_config.score_from;
		          	this.result_config['score_to_'+row] = result_config.score_to;
	          	});
				this.mode = 'edit';	          	
	          }else{
	      		this.score_results = {'1' : {code: 'ineligible'}, 
	      							  '2' : {code: 'requires_flow_rm_approval'}, 
	      							  '3' : {code:'eligible'}
	      							};
	      		this.mode = 'create';    	
	          }
	      	  this.renderScoreResult(); 		    
      	  }
      	  
      
        });    

		
	}

	renderScoreResult = () => {
		const score_result_keys = Object.keys(this.score_results);
		const score_res_len = score_result_keys.length;
		const score_result_div = score_result_keys.map((key,i) =>
				<tr key={i}>
					<td className="width-30"><b>{dd_value(this.score_results[key].code)}</b></td>
					<td></td>
					<td className="width-15"><input id={`score_from_${key}`} type="text" readOnly
						value={(key == 1) ? ("0") : this.score_results[key].score_from} className="form-control width-50"/></td>
					<td className="width-15">To</td>
					<td className="width-15"> <input id={`score_to_${key}`} type="text" 
						value={(key == score_res_len) ? "1000" : this.score_results[key].score_to} 
						onChange={this.handleScoreChange} readOnly={key == score_res_len} 
						className="form-control width-50" /></td>
					<td className="width-25">Points</td>
				</tr>
			);

		this.setState({score_result_div});
	}
	
	handleScoreChange = (event) => {
		
		const id = event.target.id;
		var score = event.target.value;	

		const id_arr = id.split('_');
		
		var curr_row = id_arr[id_arr.length-1];

		var curr_score_from = "score_from_" + curr_row ;
		curr_score_from = document.getElementById(curr_score_from).value; // Create

		this.score_results[curr_row].score_to = score; // Edit
		this.renderScoreResult();

		if(Number(score) <= Number(curr_score_from)){
			
			this.valid = false
			return
		}else{
			
			this.valid = true;
		}

		const row_to_change = ++curr_row;
		
		var id_to_change = "score_from_" + row_to_change ;
		
		document.getElementById(id_to_change).value = ++score; // Create
		this.score_results[row_to_change].score_from = score;	// Edit

		this.handleSelfChange(event);
		
		this.renderScoreResult();
	}

	handleSaveClick = (event) => {
		
		const result_config_len = Object.keys(this.result_config).length;
		const score_results_len = Object.keys(this.score_results).length;
		
		if(this.valid == false || 
			(this.mode == 'create' && result_config_len != score_results_len-1) ||
			(this.mode == 'edit' && result_config_len == 0)) {
			alert("Please enter valid score range")
			return
		}
		//this.result_config.score_to_1 = document.getElementById('score_to_1').value;
		//this.result_config.score_to_2 = document.getElementById('score_to_2').value;
		const cs_result = {"country_code": this.country_code, 
							"csf_model": this.score_model, 
							"score_result_codes": this.score_results, 
							"score_details": this.result_config};
		createCSResultScore(this.req({cs_result}))
		.then((response) => {
          if(!response){return };     
          
          if(response.status == "success"){
	          alert("CS Result configured successfully");
	          window.location.reload();
      	  }
      
        });    
	}

	 

	render(){
		return(
			<>		
			<div className="border_style text-labelblue container containerTopmargin max-width" style={{padding:15}}>
				<div>					
					<h3 className='headerTitle'>Score Result Config</h3>
				</div>				
				{/*<div className="row">
					<div className="col-md-2">
					</div>
					<div className="col-md-6">
						<div className="row">
							<div className="col-md-5">
								<h5><b>Result</b></h5>
							</div>
							<div className="col-md-3">
								<h5><b>Score From</b></h5>
							</div>
							<div className="col-md-1">
							</div>
							<div className="col-md-3">
								<h5><b>Score To</b></h5>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-md-2">
					</div>
					<div className="col-md-6">
						
						<br/>
						
						{this.state.score_result_div}

						<div id="float_style" className="row">
							<input type="button" value="Save" onClick={this.handleSaveClick} className="btn btn-success" />
						</div>
					</div>
				</div>*/}

				<div>					
					<div className="table-responsive">
					<table className={"table"}>
						<thead className={"text-info"}>
							<tr>
							<th><h5 className='headerTitle no-margin'>Result</h5></th>
							<th></th>
							<th><h5 className='headerTitle no-margin'>Score From</h5></th>
							<th></th>
							<th><h5 className='headerTitle no-margin'>Score To</h5></th>
							<th></th>
							</tr>
						</thead>
						<tbody>
							{this.state.score_result_div}
						</tbody>
					</table>
					</div>
				</div>											
				{check_priv('score','result_config_create_edit') &&
					<div className='text-right'>
						<input type="button" value="Save" onClick={this.handleSaveClick} className="btn btn-success" />
					</div>
				}							
			</div>

			</>



			);
	}


}

export default withRouter(ScoreResultConfig) ;