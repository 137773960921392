import React, { useState,useEffect,} from 'react';
import { MdFileUpload } from "react-icons/md";
import{stmtUpload,stmtRemove,fileProcess,viewLead}from '../../../actions/lead_actions';
import { get } from '../../../helpers/storage_helper';
import{BsCheckLg,BsTrashFill}from "react-icons/bs";
import { IoMdCheckmark } from "react-icons/io";
import {useParams}from'react-router-dom';


const StmtUploader = ({lead_id}) => {
    const [token,setToken]=useState('')
    const [file_type, setfile_type] = useState('')
    const[file_json_datas,setfile_json_datas]=useState({
        file_json:'',
        files:'',
        account_num:'',
        month1:'',
        month2:'',
        month3:''
    
    })
    useEffect(() => {
  
        viewLead({"id":lead_id,"time_zone": get('market').time_zone,"country_code": get('market').country_code})
        .then((response) => {
            if(!response){return };
               
            if(response.data.file_json){
                setfile_json_datas({ ['file_json']:JSON.parse(response.data.file_json),["files"]:(JSON.parse(response.data.file_json)).files,['account_num']:response.data.account_num,['month1']:response.data.month1,['month2']:response.data.month2,['month3']:response.data.month3})
            }
        })
       
       }, [token])
    
    
    // const statement_upload=()=>{
     
    // }
    const UploadFile=(e,key)=>{

        const file= e.target.files[0]
        const file_name=file.name
        const current_file=file.type.split('/')[1] 

        var result_file_types={"csv":"csv","vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx","vnd.ms-excel":"xls","pdf":"pdf"}
        var current_file_type="application/"+result_file_types[current_file]

       getBase64(file)
        .then(result => {
          file["base64"] = result;
          uploadApiCall(lead_id,file_json_datas.files[key].file_of,key,file_name,current_file_type,result)
     
        })
        .catch(err => {
       
        });  
    
       
      
    } 
    
     const getBase64 = (file) => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
          // Convert the file to base64 text
          reader.readAsDataURL(file);
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object
           
            baseURL = reader.result;
            
            resolve(baseURL);
          };
         
        });
      };

    const upload_file=(key)=>{
    const orginalTypes =file_json_datas.files[key].file_type
    const types=[]
    const keys=[]
    orginalTypes.map((key,idx)=>{
        let type= ("."+key.split('/')[1])
        types.push(type);
        keys.push(key)
     
    })
    setfile_type(types)
   
  
}


const uploadApiCall = (lead_id,file_of,index,file_name,current_result_file_type,data) => {
 
    let request = {
        "entity": "lead",
        "entity_id": lead_id,
        "entity_code": lead_id,

        "file_of": file_of,
        "index" : index,
        "file_data": data,
        "file_name": file_name,
        "file_type": current_result_file_type,
        "time_zone": get('market').time_zone,
        "country_code": get('market').country_code   
    }
  
    stmtUpload({...request})
    .then((response)=>{
        if(!response){return };
        if(response.status == "success"){
            // window.location.reload(false);
            setToken(Math.floor(Math.random() * 100) + 1)
            }                  
    }); 
  
 }
 const fileRemoveHandler = async(file_of,file_path) => {
    let datas = {
        "entity": "lead",
        "entity_id": lead_id,
        "entity_code": lead_id,

        "file_of": file_of,
        "file_path" : file_path,
        "time_zone": get('market').time_zone,
        "country_code": get('market').country_code
    }
    
    stmtRemove({...datas})
    .then((res)=>{
        if(!res){return};
        if(res.status=="success"){
            setToken(Math.floor(Math.random() * 100) + 1)    
        }
      
    })
 }
 const file_process=()=>{
    fileProcess({"lead_id": lead_id,"time_zone": get('market').time_zone,"country_code": get('market').country_code})
    .then((response)=>{
        if(!response){return ""}
        if(response.status=="success"){ 
            alert(response.message)
            window.location.reload()
        }
       
    })
   
  
 }

 const monthReplaceHandler=(template_data,type)=>{
     if(type =="file_label"){
       return template_data.replace(':month1', file_json_datas.month1+" Month's").replace(':month2', file_json_datas.month2+" Month's").replace(':month3',  file_json_datas.month3+" Month's")
     }
     if(type =="file_desc"){
       return template_data.replace(':month1', file_json_datas.month1).replace(':month2', file_json_datas.month2).replace(':month3',  file_json_datas.month3).replace(':account_num',': '+file_json_datas.account_num)
    }
 
}

    return (
        <div>
             <div style={{background:'#202940'}}>
                    <h2 className='text-white text-center' style={{padding:'10px'}}>{file_json_datas.file_json.desc}</h2>
                    {Object.keys(file_json_datas.files).map((key,index)=>{return(
                        <div className='d-flex align-items-center justify-content-around' key={index}>
                                <div className='' style={{padding:'25px'}}>
                                    <p className='no-margin' style={{fontSize:'15px',color:file_json_datas.files[key]["file_name"] ? "#11b556" :"white" }}>{ monthReplaceHandler(file_json_datas.files[key]["file_label"],"file_label")}</p>
                                    <p className='no-margin'>{monthReplaceHandler(file_json_datas.files[key]["file_desc"],"file_desc")}</p>
                                </div>
                                {file_json_datas.files[key]["file_name"] ? 
                                <div className='d-flex justify-content-around'>
                                    <div>
                                        <IoMdCheckmark size={25} color={'#11b556'}/>
                                    </div>
                                    <div onClick={() => fileRemoveHandler(file_json_datas.files[key]["file_of"],file_json_datas.files[key]["file_path"])}>
                                     <BsTrashFill className='stmt_trash_icon' size={23} />
                                    </div>
                                </div>
                                 : 
                                <div onClick={()=>upload_file(key)} >
                                    <form>
                                        <label id="first" >
                                            <div id="wrapper">
                                            <input style={{display:'none'}} id="choose-file" type="file"  accept={file_type} onChange={(e)=>UploadFile(e,key)} />
                                             <MdFileUpload  size={25} /> 
                                            </div>
                                        </label>
                                    </form>
                                </div>
                                }
                            </div>
                    )})}

                <div className='d-flex justify-content-center'>
                 <button type="button" class="btn btn-primary btn-lg" onClick={()=>file_process()}>Process</button>
                </div>              
                </div>
        </div>
    );
};

export default StmtUploader;