import { get_path } from '../helpers/common_helper';
import {call_fetch, app_path} from './../helpers/fetch_helper';

export const listPreappr = request  => {
    return call_fetch(get_path('admin') + '/pre_appr/list', request);
}
export const removePreApproval = request  => {
    return call_fetch(get_path('admin') + '/pre_appr/remove', request);
}


