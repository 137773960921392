import { Navigate } from 'react-router-dom';
import React from 'react';
import {createLoanProduct, viewLoanProduct, listLoanProductTemplate} from '../../../actions/loan_product_actions';
import FlowContainer from '../../common/container/core/FlowContainer';
import ProductMapping from '../component/ProductMapping';
import LoanProduct from '../component/LoanProduct';
import '../../../styles/flow.css';
import  {lbl_flow_fee_obj, lbl_flow_fee_duration} from './../../../helpers/product_helper';
import $ from 'jquery';
import withRouter from '../../../Router/Withrouter';

class ProductContainer extends FlowContainer {
  constructor(props) {
    super(props);
    this.handleProdChange = this.handleProdChange.bind(this);
  }

  state = {
    toList: false,
    new_product_div_style: "show_div",
    template_product_div_style: "hide_div",
    templates_div_style:"hide_div",
    product_mapping_div_style: "show_div",
    templateList: null,  
    loan_product : {acc_prvdr_code:this.acc_prvdr_code},
    data_prvdr: {},
    product_template : {flow_fee_type: "Flat", flow_fee_duration: "entire_duration"},

    new_product : {flow_fee_type: "Flat", flow_fee_duration: "entire_duration"},
    // currency_code: cookie.load("currency_code"),
    // country_code: cookie.load("country_code")

  }
  componentWillMount() {
    if(this.props.mode === 'view'){
      this.setState({product_div_style:"show_div"});
      const product_id = this.props.params.product_id;
      const request = {product_id};
      viewLoanProduct(this.req(request))
        .then((response) => {
          if(!response){return };
            this.loan_product = response.data;     
            this.setState({loan_product: this.loan_product});
            this.setState({loan_product_template: this.loan_product});         
          }

        );
    }
  } 
 /* componentDidUpdate(){
    $('#list').dataTable({
     //"sPaginationType": "full numbers",
      "bAutoWidth": true,
      "bDestroy": true, 
    });//
  }*/
handleProdChange(name) {
  var loan_product = this.state.loan_product;
  loan_product.product_name = name;
  this.setState({loan_product});
}
onChooseTemplateClick = (event) => {
       
        const selectedTemplate = this.templates[event.target.id];
        this.setState({template_product_div_style:"show_div"}); 
        this.setState({product_mapping_div_style: "show_div"});
        this.setState({product_template: selectedTemplate}); 
    }

  handleSubmit = (event)=>{
    event.preventDefault();
    var loan_product_details = this.state.loan_product;
    var loan_product = {};


    var new_product = this.state.new_product;
    loan_product = {...new_product, ...loan_product_details};

   
    loan_product.status = "enabled";
    loan_product.country_code = this.country_code;
     
    createLoanProduct(this.req({loan_product}))
        .then((response) => {
          if(!response){return };
            if(response.status === "success"){
            
              this.setState({toList: true});
              alert("New Loan Product created successfully");
            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          }
        );
  }

    selectCreationPath = (event) => { 
      var prev_table = $('#product_temp').DataTable();
      prev_table.destroy();
    this.path = event.target.value;
    if(this.path === "from_template"){
      this.setState({new_product_div_style: "hide_div"});
      this.setState({template_product_div_style: "hide_div"});
      this.setState({product_mapping_div_style: "hide_div"});
      this.setState({templates_div_style: "show_div"});
      //const status = "enabled";
   listLoanProductTemplate(this.req(null))
        .then((response) => {
          if(!response){return };
              this.templates = response.data;
              const templateList = response.data.map((template,i)=>
              <tr key={i}>      
                <td data-sort={template.max_loan_amount}> {template.max_loan_amount} {this.state.currency_code}</td>
                <td> {template.duration} day(s)</td>                      
                <td> {template.flow_fee_type} </td>
                <td data-sort={template.flow_fee}> {lbl_flow_fee_obj(template, this.currency_code)} </td>
                <td> {lbl_flow_fee_duration(template.flow_fee_duration, template.duration)} </td>
                <td> <input id={i} type='radio'  name='products' onClick={this.onChooseTemplateClick} value='View / Edit' /></td>
              </tr>
              );
          this.setState({templateList: templateList});

               $('#product_temp').removeAttr('width').dataTable({
              
                  "columns": [
                       {"width" : "20%"}, 
                       {"width": "20%"},
                       {"width": "10%"},
                       {"width": "20%"},
                       {"width": "20%"},
                       {"width": "10%"}
                     ],

                  dom:'Bfrtip',
                  buttons: [
                  'csv','excel','print'
                  ]  
              });
        });
    }
    else{
      this.setState({template_product_div_style: "hide_div"});
      this.setState({templates_div_style: "hide_div"});

      this.setState({new_product_div_style: "show_div"});
      this.setState({product_mapping_div_style: "show_div"});
      //this.setState({new_product : {flow_fee_type: "Flat", flow_fee_duration: "entire_duration"}});
    }
    
    

   }


  render(){
    if (this.state.toList === true) {
      return <Navigate to={`/fa_product/list`}/>
      //return <Navigate to={`/borrower/indiv/view/${this.state.new_loan_product_id}`}/> 
    }

      return (
    
           <div id="">
           <div className="container mt-50"> 
              {/* <div className="row">
                <div className="col-md-6">
                    <h4>
                    <label className="radio-inline">
                    <input type="radio" name="option"  onClick={this.selectCreationPath} onChange={this.handleChange} value="new_product" />  Create New Product &nbsp;&nbsp;&nbsp;
                    </label>
                    <span id="">
                      <label className="radio-inline">
                    <input type="radio" name="option" onClick={this.selectCreationPath} onChange={this.handleChange} value="from_template" />  Create Product from Template 
                    </label>
                    </span>
                
                    </h4>
                    <br/> 
                </div>
              </div> */}

              <div id="" className={`${this.state.templates_div_style}`} >
                <h4>{"Select Product Template"}</h4>
                  <div id='datatable product_temp' className={"table-responsive"}>
                    <table id ="product_temp" className="table">
                              <thead className={"text-info"}>
                              <tr>        
                              <th>FA Amount</th>      
                              <th>FA Duration</th>       
                              <th>Flow Fee Type</th>
                              <th>Flow Fee</th>
                              <th>Fee For</th>
                              <th>Choose</th>
                              </tr>
                              </thead>
                              <tbody> 
                              {this.state.templateList}
                              </tbody>
                    </table>       
                  </div>
              </div>

              <div id="" className={`${this.state.template_product_div_style}`}>

                <LoanProduct title="Float Advance Product Template" id="product_template" 
                        mode="disabled" data={this.state.product_template} />
              </div>              

              <div id="" className={`${this.state.new_product_div_style}`}>

                  <LoanProduct title="Float Advance" id="new_product" 
                        mode="create"  data={this.state.new_product} onProdChange={this.handleProdChange} onComponentChange={this.handleComponentChange}/><br/>
              </div>              

              <div id="" className={`${this.state.product_mapping_div_style}`}>
                  <ProductMapping id="loan_product" data={this.state.loan_product} mode={this.props.mode} onComponentChange={this.handleComponentChange}/>
               </div>
              <input type="button" className={`btn btn-primary ${this.props.mode === "create" ? "show_button": "hide_button"}`} onClick={this.handleSubmit} value="Create Product" />
           </div>
           </div>
      );
  }
}

export default withRouter(ProductContainer) ;
