import { Accordion, Card, Button, Modal, Form } from "react-bootstrap"
import {BsChevronDown} from 'react-icons/bs';
import { BasicReactTable } from "../../helpers/react_table";
import { get } from "../../helpers/storage_helper";
import { listAccountProvider } from "../../actions/account_provider_actions";
import { useEffect, useState } from "react";
import { Homeacc_prvdrLogoselector, filter } from "../../helpers/common_helper";
import { Link } from "react-router-dom";
import { lbl_amt } from "../../helpers/product_helper";
import { getSelectOptions } from "../../helpers/common_helper";
import { uncapturedDuplicatPayment, captureDuplicatePayment } from "../../actions/report_actions";
import DisplayImage from "../common/component/DisplayImage";

const DuplicateTxns = () =>{

    const [allAccPrvdrs, setAllAccPrdrs] = useState([])

    const [columnLabel, setColumnLabel] = useState([
        {key:"acc_number", label:"A/C Number"},
        {key:"cust_id", label:"Customer ID"},
        {key:"biz_name", label:"Biz Name"},
        {key:"entity_id", label:"Loan Doc ID / Switch ID"},
        {key:"stmt_txn_id", label:"Txn ID"},
        {key:"action", label:"Action"}
    ])

    const [columnData, setColumnData] = useState([])
    const [showTable, setShowTable] = useState(false)

    const [allData, setallData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [eachAccData, setEachAccData] = useState({});
    const [selectedValue, setSelectValue] = useState("All")
    const [isModelOpen, setModelOpen] = useState(false)
    const [modelData, setModalData] = useState({})
    const [inputValue, setInputValues] = useState(null)


    useEffect(()=>{
        getAllAccountProvider();
        getColumnData();
        getDuplicateData();
    }, [])

    const getColumnData = () => {
        let columns = columnLabel.map((val, i)=>{return{
            Header:<p className="text-center">{val.label}</p>,
            id:val.key,
            accessor:row => <div>
                                {val.key == 'acc_number' ? 
                                 <div className='d-flex align-items-center justify-content-center'>  <Homeacc_prvdrLogoselector logotype={ row.acc_prvdr_code}/> <p className='text-white m-0 pl-2'>{row[val.key]}</p></div>
                                :
                                val.key == "entity_id" ? 
                                <p className="text-center">{row.type == "float_advance" ? <Link to={"/fa/view/"+row.loan_doc_id} target="_blank" className="on_going">{row.loan_doc_id}</Link> : row.sales_doc_id}</p>
                                :

                                val.key == "action" ? 
                                <div className="d-flex align-items-center justify-content-center">
                                    <Button variant="primary" className="ml-2" onClick={() => handleModal(true, row)} disabled={row.reason ? true : false}>Capture</Button>
                                </div>
                                :
                                val.key == 'cust_id' ? 
                                <div className="text-center"> <Link to={"/borrower/indiv/view/"+row.cust_id} target="_blank" className="flow_custid" >{row.cust_id}</Link>  </div>
                                :
                                <p className="text-center">{row[val.key]}</p>
                                }
                            </div>
        }})

        setColumnData(columns)
    }

    const getDuplicateData = () => {
        const ls_market = get('market')
        var req = {country_code: ls_market.country_code, time_zone: ls_market.time_zone, status: ""};
        uncapturedDuplicatPayment({...req})
        .then((response)=>{
            if(!response){return };
            if(response.status === "success"){
                setallData(response.data)
                filter_count(response.data)
                setFilterData(response.data)
            }
        });
    }

    const filter_count=(data)=>{
        let eachdata = data.reduce((result, item) => {
            if(!result[item.acc_prvdr_code]){
                result[item.acc_prvdr_code] = []
            }
            result[item.acc_prvdr_code].push(item)
            return result
        }, {})
        setEachAccData(eachdata)
	}
        
    const filter_acc=(acc)=>{
		if(acc=='All'){
			setFilterData(allData)
		}
		else{
            let filter =eachAccData[acc] ? eachAccData[acc] : []
            setFilterData(filter)
        }
        setSelectValue(acc)
	}

    const handleModal = (val, data = null) => {
        setModelOpen(val)
        if(data){
            setModalData(data)
        }
        if(val == false){
            setInputValues(null)
        }
    }

    const getAllAccountProvider = () =>{
        const ls_market = get('market')
		var req = {country_code: ls_market.country_code, time_zone: ls_market.time_zone, status: ""};		
		listAccountProvider({...req, status: 'enabled', biz_account: true})	
        .then((response)=>{
			if(!response){return };
			if(response.status === "success"){
				let acc_prvdrs = []            
				response.data.list.map(each=>
				acc_prvdrs.push(each.acc_prvdr_code)
				)  
				setAllAccPrdrs(acc_prvdrs)
			}
		});
    }

    const handleToggle = () => {
        setShowTable(!showTable)
    }

    const getInputValues = (val, key) => {
        setInputValues({...inputValue, [key]:val})
    }

    const handleSubmit = () => {
        if(inputValue && inputValue.reason && ((inputValue.reason == "incorrect_txn_id" && inputValue.correct_txn_id) || inputValue.reason != "incorrect_txn_id")){
            const ls_market = get('market')
            var data = {country_code: ls_market.country_code, time_zone: ls_market.time_zone, status: ""};
            let entity_id = modelData.type == 'float_advance' ? "loan_doc_id" : "sales_doc_id"
            data = {...data, txn_id:modelData.stmt_txn_id, [entity_id]:modelData[entity_id], type:modelData.type}
            let request = {...data, ...inputValue}
            captureDuplicatePayment({...request})
            .then((response)=>{
                if(!response){return };
                if(response.status === "success"){
                   alert("Proof captured successfully.")
                   handleModal(false)
                   getDuplicateData()
                }
            });
        }else{
            alert("Please enter the valid fields.")
        }
    }
    

    const paymentProofModal = () => {
        return(
            <Modal show={isModelOpen}>
                <Modal.Header closeButton show={isModelOpen} onHide={()=>handleModal(false)} style={{boxShadow:"0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}} className="pb-3">
                    <Modal.Title>Capture</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="row">
                            <div className="col-6">
                                <div className='m-0 d-flex justify-content-center text-light  h-100 w-100' >
                                    <DisplayImage image_path={modelData.file_path} style={{height:330,width:215}} file_name={modelData.file_name}/>
                                </div> 
                            </div>
                            <div className="col-6">
                                <div className="mt-5">
                                    <label>Account Number</label>
                                    <h6 className="dup_proof">{modelData.acc_number}</h6> 
                                </div>
                                <div className="mt-5">
                                    <label>Loan Doc ID / Switch ID</label>
                                    <h6 className="dup_proof">{modelData.type == 'float_advance' ? modelData.loan_doc_id: modelData.sales_doc_id}</h6>
                                </div>
                                <div className="mt-5">
                                    <label>Txn ID</label>
                                    <h6 className="dup_proof">{modelData.stmt_txn_id}</h6>
                                </div>
                                <div className="mt-5">
                                    <label>Amount to recover</label>
                                    <h6 className="dup_proof">{lbl_amt(modelData.amount_to_recover ? modelData.amount_to_recover : 0, get('market').currency_code)}</h6>
                                </div>
                                
                            </div>
                        </div>

                        <div className="dup_proof_type mt-5">
                            <select id="branch" className="form-control" type="text" required="required" onChange={(e) => getInputValues(e.target.value, 'reason')}>
                                <option key="" value="">Reasons</option>
                                {getSelectOptions("duplicate_fa_management_capture_reason", false)}
                            </select>
                        </div>
                        {inputValue && inputValue.reason && inputValue.reason == "incorrect_txn_id" && 
                        <div className="mt-5">
                            <Form.Control id="correct_txn_id" autoComplete='off' onChange={(e) => getInputValues(e.target.value,'correct_txn_id')} placeholder="Enter Txn ID"/>
                        </div>
                        }
                        <div className="form-group row reset-margin justify-content-center py-4 mt-5">
                            <Button className= "col-md-2 mr-3 btn btn-primary" onClick={() => handleSubmit()}>Submit</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }


    return(
        <>
        {paymentProofModal()}
            <Accordion>
                <Card>
					<div className={`${filterData && filterData.length==0 ?'empty_display_list': 'non_empty_display_list'}`}>
					<div className="card-header card-header-icon card-header-primary home_header_d-flex_at_response justify-space-between">
						<h4 className="card-title text-white tableHead_font title-bottom">Duplicate Transactions</h4>
						{allData.length > 0 &&
                        <>
								<div className='countREL card-title'>
									<button className={'filterBtns allBtn_size'} onClick={()=>filter_acc("All")} >ALL</button>
									<div className='countABS' style={{background:selectedValue == "All" ? "" : "lightgray"}}>
										<label>{allData.length}</label>
									</div>
								</div>						
								{allAccPrvdrs.map((each,idx)=>								
									<div key={idx} className='countREL  card-title'>	
											<>
												<div style={{width:60,height:30}} className="filterBtns" onClick={()=>filter_acc(each)}>													  
													<img className='img-fluid' src={`/img/${each}_logo_color.png`}></img> 																	
												</div>
												<div className='countABS'  style={{background:(selectedValue == "All" || selectedValue == each) ? "" : "lightgray"}}>													
													<label>{eachAccData[each] ? eachAccData[each].length : 0}</label>																																
												</div>
											</>										
									</div>																		
								)}
						</>
						}
						<Accordion.Toggle className = "col" as = {Card.Header} eventKey="0" onClick = {()=>handleToggle()}>
                            <BsChevronDown className = {showTable ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow"} size="18"/>
                        </Accordion.Toggle>
					</div>

					</div>
					{filterData && filterData.length > 0 && 				
					<div className="card-body no-padding">
						<Accordion.Collapse eventKey="0">
							<Card.Body>
							<BasicReactTable  row_data={filterData ? filterData : []} column_data={columnData} pagination={true}    default_page_size={50}/>  

							</Card.Body>
						</Accordion.Collapse>
					</div>
                    }
				</Card>
            </Accordion>
        </>
    )
}

export default DuplicateTxns