import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import { AiOutlineIdcard,AiOutlineMobile,AiFillShop ,AiFillEdit} from "react-icons/ai";
import{MdBusiness,MdPerson,MdAccountCircle,MdImage,MdPersonOutline,MdPhoto,MdLocationPin,MdArrowDropDown} from "react-icons/md";
import { BiRefresh,BiPhoneOff ,BiPhoneCall,BiXCircle,BiPhotoAlbum,BiEdit,BiMap,BiCheck} from "react-icons/bi";
import{GoVerified} from "react-icons/go";
import{FaHandsHelping} from "react-icons/fa";
import{HiOutlineDocumentAdd,HiPhoneOutgoing,HiArrowCircleRight,HiArrowCircleLeft}from"react-icons/hi";
import{ImCircleDown,ImCircleUp} from "react-icons/im";
import{ CgNotes}from'react-icons/cg';
import{BsClipboardData}from'react-icons/bs';
import FileUpload from '../../common/component/FileUpload';
import Alert from 'react-bootstrap/Alert';
import {get, Config} from '../../../helpers/storage_helper';
import { viewLead ,UpdateFlags,addComments,auditKycVerification,auditKyRetrievals,auditKycFileUpload, submitCallLog,RejectCallLog,auditkycModify,updateAccPrvdrCode} from '../../../actions/lead_actions';
import {capitalize_first_letter,getLabel,get_dir_path} from '../../../helpers/common_helper';
import DisplayImage from '../../common/component/DisplayImage';
import { approveKYC,updateLeadStatus} from '../../../actions/kyc_actions';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import isValid from 'date-fns/fp/isValid';
import {dd_value} from '../../../actions/common_actions';
import moment from 'moment';
import PDFtoImage from '../component/Pdf_to_image';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


class AuditKYCAgreementsVerification extends FlowComponent{
    
      constructor() {
        super();
        this.state={
          cust_reg_json:'',
          view_pdf:false,
          pdf_view_error:true,
          pdfMOdal:false
        }
        
      
    }
    

    componentDidUpdate(prevProps, prevState, snapshot){
      if(this.props.agreements_data.agreements_verify_status &&( this.state.cust_reg_json!=prevProps.agreements_data.cust_reg_json.agreements)){
        
        return this.setState({cust_reg_json:prevProps.agreements_data.cust_reg_json.agreements})
    
      }
     if(this.props.agreements_data && (this.state.view_pdf==false)){
      this.setState({view_pdf:true})
     }
    }
    pdf_viewer(link){

    const url = link;
    fetch(url, { method: 'HEAD' })
    .then(response => {
    const contentType = response.headers.get('Content-Type');

    if (contentType === 'application/pdf') {
      this.setState({pdf_view_error:true})
      console.log('The URL points to a PDF file.');
    } else {
      this.setState({pdf_view_error:false})

      console.log('The URL does not point to a PDF file.');
    }
  })
  .catch(error => {
    console.log('An error occurred:', error);
    this.setState({pdf_view_error:false})

  });    
    }
    
    handleModalClose(type){
      this.setState({pdfMOdal: type=="open" ? true :false})
    }

    


    Pdf_modal(props) {


      return (
        <>
  
          <div  className="w-100 h-100 p-0  " style={{position:'relative'}}  >
            {props.pdf_view_error ?                     
              <object id="pdf-object" type='application/pdf' className='bg-secondary' data={`${props.root}${props.modal_title}?#toolbar=0&navpanes=0&page=1`}  width={'100%'} height={'100%'} allowtransparency='true' onLoad={()=>props.pdf_viewer(`${props.root}${props.modal_title}`)}>  
              {/* {props.device&&<a href={`${props.root}${props.modal_title}`} className='btn btn-primary w-100 d-block'>View Pdf </a>} */}
              {props.device&& <PDFtoImage pdfUrl={`${props.root}${props.modal_title}`}/>}
              <p className='text-center'>NO DATA</p>
             </object> 
             :
              <div class="d-flex align-items-center">
              <p className='text-center float_acc_balance_view_bttn' >NO DATA</p>

            </div> }
             
          </div>
          <div className={`w-100 h-100 p-0 ${props.device?"":'position_ab'}`} style={{top:0}}  ></div>
        
          <Modal show={props.pdfMOdal} onHide={()=>props.handleModalClose("close")} size = 'lg' >
                     <div className="row d-flex justify-content-center align-items-center ml-0">
                     <h5 className="modal-title text-center mb-0 mt-5 " id="">{props.title}</h5>

                    </div>
                    <Modal.Body >
                    <div className="modal-body py-0" >
                <object type='application/pdf' className='forpdf'  data={`${props.root}${props.modal_title}?#toolbar=0&navpanes=0&page=1`} width={'100%'} height={'485vh'} allowtransparency='true'>
                {props.device&& <PDFtoImage pdfUrl={`${props.root}${props.modal_title}`}/>}
                </object>
                </div>
                <div className="modal-footer d-flex">
                <a href={`${props.root}${props.modal_title}`} className='btn btn-success btn-sm my-0 mx-3' target="_blank">Download Pdf</a>
                  <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal" onClick={()=>props.handleModalClose("close")}>Close</button>
                  
                </div>
                    </Modal.Body>
            </Modal>
        </>
      );
    }

    audit_section_spinner(){
        return(
          <div class="audit_spinner" style={{margin:'20vh 0px  30vh 45%'}}>
          <span class="level level-1"></span>
          <span class="level level-2"></span>
          <span class="level level-3"></span>
          <span class="level level-4"></span>
          <span class="level level-5"></span>
          <span class="level level-6"></span>
        </div>
        )
      }

      setAuditLeadState = (event) => {
        this.setChangeToState(event)
       }
    
       checkbox_spinner(){
        return(
          <div class="d-flex col-lg-10 py-2 px-5 mt-4 justify-content-center ml-5">
          <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
         </div>
        )
      }
      
    render(){
        return(
            <div>
      
           <>{this.props.agreements_data.View_lead_section_loader ? this.audit_section_spinner():
             
             <div className='row no-margin justify-content-center'>
             <div className='col-lg-12 py-3 mt-5 ' style={{background:' #01062c'}}>
               <h2 className='text-white text-center'>
               <b> {this.props.agreements_data.cust_reg_json.same_as_owner_person ? "8" :"9" } - Agreement Verification</b> 
   
              
               </h2>
               <h2 className=' d-flex mt-1 text-center justify-content-center align-items-center' ><img src='/img/pending_with_rm.png' height={'50'}/> <b className='ml-2 align-self-cente' style={{color:'#F3EA0B'}}> Pending with {this.props.agreements_data.active_section && (this.props.agreements_data.active_section[0].pending_with=="rm"?'RM':'YOU')}</b></h2>
 
              </div>
 
           

               < >
              
              <div className='col-sm-6 p-2'>
               <div className='m-2 text-light border h-100 w-100' >
 
                 {this.props.agreements_data.cust_reg_json&&
                  <div style={{height:'100%'}} onClick={()=> {!this.state.pdfMOdal ? this.handleModalClose('open'):null}}>
                 <this.Pdf_modal title={"Agreement PDF"} root={this.props.agreements_data.root} device={this.props.Ismobile()} modal_title={this.props.agreements_data.cust_reg_json.agreements[0].aggr_file_path} pdf_view_error={this.state.pdf_view_error} pdf_viewer={(a)=>{this.pdf_viewer(a)}} pdfMOdal={this.state.pdfMOdal}  handleModalClose ={(type)=>{this.handleModalClose(type)}}/>
                  
                 </div>
                  }
                  {this.props.agreements_data.active_section &&
                   <div>
                       {this.props.agreements_data.active_section[0].verify &&
                         <div className="closedStamp ">
                         <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
                         </div>   
                       }
                   </div>
                  }
                 
                 
                
               </div>
              </div>
              <div className='col-sm-6 p-2  '>
               <div className='m-2 text-light border h-100 w-100  px-4 pt-3' >
               {/* <p className='text-white  business_operations_text mt-3 '>BUSINESS NAME</p>
               <h2 className='text-light bold sizel mb-2 pb-1'>{capitalize_first_letter(this.props.agreements_data.cust_reg_json.license_proof.biz_name.value)}</h2>
               */}
               <p className='  text-white  business_operations_text '>CUSTOMER NAME</p>
               <h2 className='text-light bold sizel mb-2'>{capitalize_first_letter(this.props.agreements_data.cust_reg_json.id_proof.first_name.value)}&nbsp;{capitalize_first_letter(this.props.agreements_data.cust_reg_json.id_proof.last_name.value)}</h2>
              
               <p className=' text-white   business_operations_text '>FLOW CUST ID</p>
               <h2  className='text-light bold sizel mb-2 pb-1'>{this.props.agreements_data.cust_reg_json.cust_id}</h2>
              
               <p className='  text-white   business_operations_text '>{getLabel('national_id')}</p>
               <h2 className='text-light bold sizel mb-2 pb-1'>{this.props.agreements_data.cust_reg_json.id_proof.id_proof_num.value}</h2>
             
               <p className=' text-white   business_operations_text '>MOBILE NUMBER</p>
               <h2 className='text-light bold sizel mb-2'>{this.props.agreements_data.cust_reg_json.mobile_numbers.mobile_num.value} </h2>
             
               <p className=' text-white   business_operations_text '>ADDRESS</p>
             
               {   get('market').country_code =="UGA" &&
             
             <h2 className='text-light bold sizel mb-2 pb-1'> 
               {this.props.agreements_data.cust_reg_json.gps_address.biz_address.county.value ?capitalize_first_letter( this.props.agreements_data.cust_reg_json.gps_address.biz_address.county.value) +',':null }
               {this.props.agreements_data.cust_reg_json.gps_address.biz_address.district.value ?capitalize_first_letter(this.props.agreements_data.cust_reg_json.gps_address.biz_address.district.value) +',':null}
               {this.props.agreements_data.cust_reg_json.gps_address.biz_address.landmark.value ?capitalize_first_letter(this.props.agreements_data.cust_reg_json.gps_address.biz_address.landmark.value) +',':null}
               {this.props.agreements_data.cust_reg_json.gps_address.biz_address.location.value ?capitalize_first_letter(this.props.agreements_data.cust_reg_json.gps_address.biz_address.location.value) +',':null}
               {this.props.agreements_data.cust_reg_json.gps_address.biz_address.parish.value ? capitalize_first_letter(this.props.agreements_data.cust_reg_json.gps_address.biz_address.parish.value)+',':null}
               {this.props.agreements_data.cust_reg_json.gps_address.biz_address.region.value ? capitalize_first_letter(this.props.agreements_data.cust_reg_json.gps_address.biz_address.region.value)+',':null}
               {this.props.agreements_data.cust_reg_json.gps_address.biz_address.sub_county.value ? capitalize_first_letter(this.props.agreements_data.cust_reg_json.gps_address.biz_address.sub_county.value)+',':null}
               {capitalize_first_letter(this.props.agreements_data.cust_reg_json.gps_address.biz_address.village.value)}
               </h2>
               }
               {  get('market').country_code =="RWA" &&
                <h2 className='text-light bold sizel mb-2 pb-1'> 
                {this.props.agreements_data.cust_reg_json.gps_address.biz_address.village ?capitalize_first_letter(this.props.agreements_data.cust_reg_json.gps_address.biz_address.village.value):'-'}
                {this.props.agreements_data.cust_reg_json.gps_address.biz_address.location.value ?capitalize_first_letter(this.props.agreements_data.cust_reg_json.gps_address.biz_address.location.value) +',':null}
                {this.props.agreements_data.cust_reg_json.gps_address.biz_address.landmark.value ?capitalize_first_letter(this.props.agreements_data.cust_reg_json.gps_address.biz_address.landmark.value) +',':null}
                {this.props.agreements_data.cust_reg_json.gps_address.biz_address.district.value ?capitalize_first_letter(this.props.agreements_data.cust_reg_json.gps_address.biz_address.district.value) +',':null}
 
                </h2>
               }

              {  get('market').country_code =="MDG" &&
                <h2 className='text-light bold sizel mb-2 pb-1'> 
                {this.props.agreements_data.cust_reg_json.gps_address.biz_address.village ?capitalize_first_letter(this.props.agreements_data.cust_reg_json.gps_address.biz_address.village.value):'-'}
                {this.props.agreements_data.cust_reg_json.gps_address.biz_address.region.value ?capitalize_first_letter(this.props.agreements_data.cust_reg_json.gps_address.biz_address.region.value) +', ':null}
                {this.props.agreements_data.cust_reg_json.gps_address.biz_address.commune.value ?capitalize_first_letter(this.props.agreements_data.cust_reg_json.gps_address.biz_address.commune.value) +', ':null}
                {this.props.agreements_data.cust_reg_json.gps_address.biz_address.district.value ?capitalize_first_letter(this.props.agreements_data.cust_reg_json.gps_address.biz_address.district.value) :null}
 
                </h2>
               }
               <p className=' text-white  business_operations_text '>Date</p>
               <h2 className='text-light bold  sizel mb-2 '> {  moment(this.props.agreements_data.cust_reg_json.agreements[0].aggr_signed_date).format("DD-MMM-YYYY")  }</h2>
 
               <p className=' no-margin text-white   business_operations_text '>SIGNATURE REFERENCE</p>

               { get('market').country_code =="MDG" ? 
                  <>
                    <DisplayImage image_path={get_dir_path(this.props.agreements_data.cust_reg_json.id_proof.photo_id_proof_back_full_path.value,this.props.agreements_data.cust_reg_json.id_proof.photo_id_proof_back.value)} style={{height: '350px',width:'100%'}} file_name={this.props.agreements_data.cust_reg_json.id_proof.photo_id_proof_back.value} />
                      {this.props.agreements_data.cust_reg_json.agreements[0].photo_witness_national_id_back_full_path && 
                        <>
                          <p className=' no-margin text-white   business_operations_text '>WITNESS SIGNATURE REFERENCE</p>
                          <DisplayImage image_path={get_dir_path(this.props.agreements_data.cust_reg_json.agreements[0].photo_witness_national_id_back_full_path, this.props.agreements_data.cust_reg_json.agreements[0].photo_witness_national_id_back)} style={{height: '350px',width:'100%'}} file_name={this.props.agreements_data.cust_reg_json.agreements[0].photo_witness_national_id_back} />
                        </>
                      }
                  </>
               :
                  <DisplayImage image_path={get_dir_path(this.props.agreements_data.cust_reg_json.id_proof.photo_id_proof_full_path.value,this.props.agreements_data.cust_reg_json.id_proof.photo_id_proof.value)} style={{height: '350px',width:'100%'}} file_name={this.props.agreements_data.cust_reg_json.id_proof.photo_id_proof.value} />
               }
              </div>
              </div>
              
          
               </>
               <div className='col-lg-10 col-md-12 col-sm-12 my-3'>
               {(!this.props.agreements_data.agreements_verify_status ? this.props.agreements_data.cust_reg_json.agreements :(this.state.cust_reg_json.length>0 ? this.state.cust_reg_json:[])).map((item,idx)=>{return(

              
                <div className='third_party_bg'>
                <div className='col-lg-12 py-3 mt-5 ' style={{background:' #01062c'}}>
                 <h2 className='text-white text-center'>
                 <b> { `Witness Mobile Number Verification ` } </b> 
                 </h2>
               </div>
              <div className={`d-flex pt-3 third_party_bg no-margin mb-4 justify-content-around row `}>
                <div className='col-md-5  mt-3 mb-5 col-lg-5' >
                <div className='h-100'  style={{background: '#E4E7E9',minHeight:'295px'}}>
                  <p  className="no-margin audit_kyc_text py-3 px-4" style={{color:'black'}}>Name as entered by RM</p><hr className='no-margin' style={{border:'1px solid black'}}/>
                  <div className='p-3 '>

                  <p  className="no-margin py-2 mt-2 audit_kyc_text  px-3 pt-3 " style={{fontSize:'14px'}}>{` WITNESS NAME (as per ${getLabel('national_id')})`}</p>
                  <h4 className='my-1 px-3  audit_kyc_text mb-5 text_dark' style={{fontSize:'26px'}}><b>{capitalize_first_letter(item.witness_name)} </b> </h4>
                  
                 </div>
                  
                  </div>

                </div>
                
                <div className='col-md-2 mt-3 mb-5 col-lg-1' style={{alignSelf:'center'}}>
                {((!item.hasOwnProperty('holder_name'))) ? "" :  
                <div className='px-2 py-3' style={{background:` ${item.name_match ? '#22cd07':'red'}`,borderRadius:'30px'}}>
                  <p className='no-margin text-white  text-center audit_kyc_text '> <b style={{fontSize:'25px'}}> { item.name_match ? "=" : '≠'}</b></p>
                </div>}
                </div>
                
                <div className='col-md-5 mt-3 mb-5 col-lg-5'  >
                <div  style={{background: '#E4E7E9',minHeight:'295px'}}>
                <p  className="no-margin audit_kyc_text py-3 px-4" style={{color:'black'}}>Retrieved Name</p><hr className='no-margin' style={{border:'1px solid black'}}/>

              <div className='p-3 '>
              <div className='d-flex align-items-center  align-items-center my-2 mx-2 '>

                <div  className={`${(((this.props.agreements_data.responseData.audited_by== get("user_id")) || (this.props.agreements_data.responseData.audited_by==null ))  && this.props.agreements_data.active_section.pending_with !="rm")?"":'disable_div'}`}>
                  <OverlayTrigger  placement="top"  delay={{ show: 250, hide: 400 }} overlay={  <Tooltip id="button-tooltip">  click to change <br/> the Mobile Operator   </Tooltip>}>     
                    <AiFillEdit className='pointer mr-2' size={15} onClick={()=>{this.props.previous_comments_section(`witness_Mobile_Verification_${idx}`)}}/>
                  </OverlayTrigger>
                </div>

                <img src={`/img/${ item.witness_mob_num_serv_prvdr_code}_logo_color.png`} style={{width:'60px',height:'30px'}}/>
                      {/* <p className='no-margin text-white col-4 business_operations_text '>  COUNTRY</p> */}
                  <p className='col-8 pl-4 no-margin audit_kyc_text'> <b>{ item.witness_mobile_num}</b></p>
              </div>
              {(this.props.agreements_data[`witness_Mobile_Verification_${idx}`] ) && (this.props.agreements_data.mobile_operators)?
                  <div>
                    { this.props.operators_selection("witness_mob_num_serv_prvdr_code","witness_mobile_num",`witness_Mobile_Verification_${idx}`,"witness Mobile Number 1")}
                  </div>:''
              }
             
              {(this.props.agreements_data.seconds[idx+15]!=0 && (!item.hasOwnProperty('holder_name')) && get('app_config').audit_kyc_line[this.props.agreements_data.cust_reg_json.agreements[idx].witness_mob_num_serv_prvdr_code]=="ussd") ?
              this.checkbox_spinner()
              :
                <>
              {item.hasOwnProperty('holder_name') ?

              <>
              {item.name_match ?
              <>
                <p  className="no-margin  mt-4 mb-2 pt-3 " style={{fontSize:'14px'}}>{'Name as per '+ dd_value(item.witness_mob_num_serv_prvdr_code,'provider_list') +' records'}</p>
                <h4 className='my-1 mt-3 px-3 py-2 audit_kyc_text mb-3' style={{background:'rgba(0, 0, 0, 0.3)',color:'black'}}><b>{ item.holder_name}</b> </h4>
                </>
              :
              <div> 
                <h2 className='my-1 mt-3 px-3 py-2 audit_kyc_text '  style={{background:'rgba(0, 0, 0, 0.3)'}}><b>{item.holder_name}</b> </h2>
                <div className='mt-4' style={{color:'black'}} ><label className='audit_kyc_text-black no-margin'>Account Holder Name Mismatch Reason </label>
                {item.hasOwnProperty("mismatch_reason") ?  <b><p className='audit_kyc_text no-margin '>{dd_value(item.mismatch_reason)}</p></b>:
                  <select id="holder_name_mismatch_reason" className = "form-control col-md-12"  value={this.props.agreements_data[`agreements_witness_mismatch_reason_${idx}`] ? this.props.agreements_data[`agreements_witness_mismatch_reason_${idx}`]:''} type="text" required="required" onChange={(e)=>this.props.account_owner_name_handler(e.target.value,`agreements_witness_mismatch_reason_${idx}`,null,null,null,null,null,"agreements_verification")} style={{color:'black'}}>
                    {this.getSelectOptions("holder_name_mismatch_reason")}
                  </select>
                  }
                  </div>
                  { (this.props.agreements_data[`agreements_witness_mismatch_reason_${idx}`] =="retrieved_franchise_name" )&&
                     <div className='col-12 no-padding'>
                     <div class="form-group mt-4 mb-2">
                     <input type="text" className='p-3' id="lname" name="lname" style={{width:'100%',background:'#a0a2a3',color:'black',border:'hidden',fontSize:'14px'}} autocomplete="off" placeholder='ENTER HOLDER NAME' onChange={(e)=>this.props.account_owner_name_handler(e.target.value,`agreements_witness_name_${idx}`)}/>
                   </div>
                   
                   <FileUpload className="audit_kyc_file_upload" lead_id = {this.props.lead_id} id="account_holder_name_proof"  mode = "create" entity ='leads' type="image"          
                     onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e, "reload")}} onCreateUpload={this.props.setAuditLeadState} />
                   <div className='mt-2'>
                   <button type="button" class="btn btn-info btn-sm " disabled={(this.props.agreements_data.lead.account_holder_name_proof && this.props.agreements_data[`agreements_witness_name_${idx}`]) ? false :true} onClick={()=>this.props.file_upload_submit_handler(this.props.agreements_data[`agreements_witness_name_${idx}`],`witness_num_${idx}`,"account_holder_name_proof",item.witness_mobile_num)}>submit</button>
                   </div>
                   </div>
                   }
                  {/* {()=>this.props.account_owner_checkbox_handler()} */}
              </div>
              }

              {item.hasOwnProperty('holder_name_proof') &&(this.props.agreements_data[`agreements_witness_mismatch_reason_${idx}`] !="retrieved_franchise_name" )&&
              <div className='m-2'>
                <DisplayImage image_path={get_dir_path(item.holder_name_proof_path,item.holder_name_proof)} style={{height: '50px',width:'90px'}} file_name={item.holder_name_proof} />
              </div>
              }
              </>
              :
              <div className='col-12 no-padding'>
                <div class="form-group mt-3 mb-4">
                <input type="text" className='p-3' id="lname" name="lname" style={{width:'100%',background:'#a0a2a3',color:'black',border:'hidden',fontSize:'14px'}} autocomplete="off" placeholder='ENTER HOLDER NAME' onChange={(e)=>this.props.account_owner_name_handler(e.target.value,`agreements_witness_name_${idx}`)}/>
              </div>
              
              <FileUpload className="audit_kyc_file_upload" lead_id = {this.props.lead_id} id="account_holder_name_proof"  mode = "create" entity ='leads' type="image"          
                onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e, "reload")}}  onCreateUpload={(e)=>this.props.setAuditLeadState(e)} />
              <div className='mt-4'>
              <button type="button" class="btn btn-info btn-sm " disabled={(this.props.agreements_data.lead.account_holder_name_proof && this.props.agreements_data[`agreements_witness_name_${idx}`]) ? false :true} onClick={()=>this.props.file_upload_submit_handler(this.props.agreements_data[`agreements_witness_name_${idx}`],`witness_num_${idx}`,"account_holder_name_proof",item.witness_mobile_num)}>submit</button>
              </div>
              </div>

              }
                </>
                  } 
              </div>
              </div>
                </div>
              
              </div>
              { ( item.hasOwnProperty("name_match") && !(item.name_match ))&&
                  <div className='row  pb-5 justify-content-center no-margin pt-2  px-4' >
                  <div className='col-lg-12 p-3 d-flex align-items-center' style={{background:'#fe9f9f'}}>
                    <h2 className='d-flex '> <b>Note</b> <p className='no-margin audit_kyc_text pl-3' style={{color:'black'}}>The name of the customer doesn’t match either with customer name nor business name.</p></h2>
                  </div>
                  </div>
                  }
                </div>
              )})}
               </div>
                
              <div className='col-lg-10 col-md-12 col-sm-12 my-3'>
              {this.props.agreements_data.active_section &&
               <>
                {this.props.agreements_data.form_spinner ? 
                  this.checkbox_spinner()
                   :
              <form className={`col-sm-12 mt-3 mb-3 ${(this.props.agreements_data.active_section[0].verify || (this.props.agreements_data.active_section[0].to_reassign==true) )? "disable_div":''}   ${ ((this.props.agreements_data.responseData.audited_by== get("user_id")) || (this.props.agreements_data.responseData.audited_by==null )) ?"":'disable_div'}`}> 
                     <div className='d-flex align-items-center'>
                     <input type="checkbox" id="vehicle1" className='pointer' style={{height:'19px',width:'20px'}} checked={this.props.agreements_data.active_section[0].flags.agreement_data_match && true} onChange={(e)=>this.props.checkbox_verification(e.target.checked,"agreement_data_match")} />
                     <label className='no-margin pl-3 audit_kyc_text pointer' for="vehicle1"  >{`The business name, customer name, flow cust ID, ${getLabel('national_id')}, mobile number, address and date matches with the agreement form. `}</label><br/><br/>
                     </div>
 
                     {this.props.agreements_data.active_section[0].flags.hasOwnProperty("signature_match") &&
                     <div className='d-flex align-items-center'>
                     <input type="checkbox" id="vehicle2" style={{height:'19px',width:'20px'}} checked={this.props.agreements_data.active_section[0].flags.signature_match && true} onChange={(e)=>this.props.checkbox_verification(e.target.checked,"signature_match")} />
                     <label className='no-margin pl-3 audit_kyc_text' for="vehicle2">{`The signature of the customer in the Float Advance Agreement matches with the signature in the ${getLabel('national_id')}`}</label><br/><br/>
                     </div>
                     }

                    {this.props.agreements_data.active_section[0].flags.hasOwnProperty("witness_contact_verified") &&
                     <div className='d-flex'>
                     <input type="checkbox" id="vehicle3" style={{height:'19px',width:'25px'}} className='pointer mt-1' checked={this.props.agreements_data.active_section[0].flags.witness_contact_verified && true} onChange={(e)=>this.props.checkbox_verification(e.target.checked,"witness_contact_verified")} />
                     <label className='pointer no-margin pl-3 audit_kyc_text' for="vehicle3" style={{lineHeight:'25px'}}>The witness contact verified matches the name input by RM</label><br/><br/>
                     </div>
                     }
 
                 </form>
               }
               </>
               }
              </div>
             </div>
              }</>
           
            </div>

        )        
     }
}

export default AuditKYCAgreementsVerification;