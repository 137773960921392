import React from 'react';
import '../../../styles/flow.css';
import { Link} from 'react-router-dom';
import FlowContainer from '../../common/container/core/FlowContainer';
import {searchLoanAppl} from '../../../actions/loan_appl_actions';
import SearchLoanCriteria from '../component/SearchLoanCriteria';
import {dd_value} from '../../../actions/common_actions';
import {RoundOffSplitter, format_date_tbl, getValueFromLangJson, page_count, reduce_text_length} from '../../../helpers/common_helper';
import {CustID, Product} from '../../common/partial';
import $ from 'jquery';
import CommonReactTable from '../../../helpers/commonReact_table';
import Select from "react-select";
import { Config, get } from '../../../helpers/storage_helper';
import { BasicReactTable } from '../../../helpers/react_table';
import MUITable from '../../../MUI/Components/MUITable';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class LoanApplSearchContainer extends FlowContainer{
	constructor(props){
	    super(props);
	    this.state = {
	    	search_btn_style : "",
	        data_prvdr_name_list: [],
	        lender_name_list: [],
	        product_name_list: [],
	        searchLoanAppl: {},
	        datatable_div_style: "hide_div",
	        serach_loan_appl:"show_div",
	        modify_search:"hide_div",
	        viewLoanAppln: false,
	       	copy:[],
			rowdatas:[],
			columndatas:[],
			paginate : Config('paginate') ? Config('paginate') : null,
			export_data:[],
			search_criteria:null,
			total : 0,
			loader : false,
			language_json : get("language_json")
	    };
	}

	viewApplication = (event) =>{
		const loan_appl_doc_id = event.target.id;
		window.open("/fa_appl/view/"+loan_appl_doc_id,"_blank");	
	}

	handleModifySearch = (event) => {
	this.setState({
		serach_loan_appl:"show_div",
		modify_search:"hide_div"
	})
	if(this.state.paginate){
		this.setState({paginate: Config('paginate'), copy : [], rowdatas : [], total : 0})
	}
}

    handleSearch = ({nativeEvent}) => {
		
    	// var prev_table = $('#loan_list').DataTable();
		// prev_table.destroy();
		this.setState({copy : [], total : 0, loader : true})
		if(this.state.paginate){
			this.setState({paginate: Config('paginate')})
		}
    	this.setState({datatable_div_style: "hide_div",
    					search_btn_style : "disable_div"});

		if(!this.state.searchLoanAppl.status && !this.state.searchLoanAppl.req_parameter){
			window.alert("Please enter a valid search criteria")
			this.setState({loader:false})
		}else{
			this.LoanApplSearch()

		}

	}

	LoanApplSearch(){

		const loan_appl_search_criteria = this.state.searchLoanAppl;

		this.setState({loader : true})
    	if(Object.keys(loan_appl_search_criteria).length > 0){
			this.setState(state=>state.search_criteria = loan_appl_search_criteria)
			var req_json = this.state.paginate ? {loan_appl_search_criteria, paginate : this.state.paginate} : {loan_appl_search_criteria}
		  	searchLoanAppl(this.req(req_json,true))
		        .then((response) => {
		        	this.resp(response);
		        	this.setState({search_btn_style : ""});
	          if(!response){return this.setState({loader : false})};
						if(response.data.results.length == 0) this.setState({loader : false})
		           		var loanApplicationResults = []; 

						if(!this.state.paginate){
							response.data.results = response.data
						}    

		           		if(response.data.results.length > 0){
							this.setState(state=>state.rowdatas = response.data.results)
							// this.setState(state=>state.copy = response.data.results)
							this.setState(state=>state.columndatas = this.columndatas(this.state.language_json))
							
		           			//const result =  response.data;
		           			this.setState({search:true})
		                //   loanApplicationResults = response.data.map((loan_appl,i)=> <tr>
			            //    <td> <Link className="link-doc-id" to={"/fa_appl/view/"+loan_appl.loan_appl_doc_id} target="_blank">{loan_appl.loan_appl_doc_id}</Link></td>
			            //    <td>{loan_appl.loan_doc_id ? 
						// 	<Link to={"/fa/view/"+loan_appl.loan_doc_id} target="_blank" >{loan_appl.loan_doc_id}</Link>
						// 	: 'NA'}
						// 	</td>
                        //   <td> <CustID acc_number={loan_appl.acc_number} biz_name={loan_appl.biz_name} cust_name={loan_appl.cust_name}/> </td>
                        //   <td> {loan_appl.product_name} </td>
		                //   <td> <Product loan={loan_appl} currency_code={this.currency_code}/></td>
		                //   <td> {format_date_tbl(loan_appl.loan_appl_date,true)}</td>
		                //   <td> {dd_value(loan_appl.status)}</td>
		                //   <td> {loan_appl.cs_result_code ? dd_value(loan_appl.cs_result_code) : "NULL"}</td>
			            //    </tr>
			            //       ); 
		              
				         
				           		this.setState({loanApplicationResults: loanApplicationResults, 
				           								datatable_div_style: "show_div",serach_loan_appl:"hide_div",modify_search:"show_div"});
			           		// 	$('#loan_list').removeAttr('width').dataTable({
							// 	"order" : [],
							// 	"pageLength": 25,
							// 	"columns": [
							// 	{"width": "5%"}, 
							// 	{"width": "5%"}, 
							// 	{"width": "5%"},
							// 	{"width": "5%"},
							// 	{"width": "5%"},
							// 	{"width": "5%"},
							// 	{"width": "5%"},
							// 	{"width": "5%"},
		           			//  ],
						    //   dom:'Bfrtip',
						    //   buttons: [
                            //      'csv','excel','print'
						    //   ]  
						    // });
							if(this.state.paginate != null){								
								this.setState(state=>state.total=response.data.total_count)	
								this.page_count()
							}
							else{
								this.setState(state=>state.total=response.data.results.length)
							}
							this.setState((state) => {
								return { copy : response.data.results };
							}, ()=>this.setState({loader : false}));
			           	}
						else{
			          		alert(getValueFromLangJson('no_data_found'));   		
			           	}
		          }
		        );
		    }else{ 

		    	this.setState({search_btn_style : ""});
		    	alert(getValueFromLangJson('choose_atleast_one_filter_to_search_float_advance_applications'));

// 		    	this.setState({search_btn_style : "", loader:false});
// 		    	alert("Choose atleast one filter to search Float Advance Applications.");

		    }
		}			

		columndatas(language_json){
			return[				  
			{
				Header : language_json.fa_appl_id,
				id: "loan_appl_doc_id",
				accessor:row => <Link className="link-doc-id" to={"/fa_appl/view/"+row.loan_appl_doc_id} target="_blank">{row.loan_appl_doc_id}</Link>
			},
			{
				Header :  <p className='m-0 text-center'>{language_json.fa_id}</p>,
				id: "loan_doc_id",
				accessor:row => {return <p className='text-center m-0'>{row.loan_doc_id ? 
									<Link to={"/fa/view/"+row.loan_doc_id} target="_blank" >{row.loan_doc_id}</Link>
									: 'NA'}	</p>}
									
			},
			// {
			// 	Header : language_json.data_prvdr_cust_id,
			// 	id: "acc_number",
			// 	accessor:row => <CustID acc_number={row.acc_number} biz_name={row.biz_name} cust_name={row.cust_name}/>
			// },
			{
				Header : <p className='text-center m-0'>{language_json.product_code}</p> ,
				id: "product_name",
				accessor:row => {return <OverlayTrigger  placement="top"  delay={{ show: 250, hide: 400 }} overlay={  <Tooltip id="button-tooltip">  {row.product_name ?row.product_name :""}</Tooltip>}> 
				<p className='m-0 text-center'>{row.product_name ? reduce_text_length(row.product_name ):  ""}</p> 
				</OverlayTrigger>
				}
			},
			{
				Header : <p className='m-0 text-center'>{language_json.product_name}</p> ,
				id: "product",
				sortable : this.state.paginate ? false : true,
				accessor:row => <div className='d-flex align-items-center justify-content-center'><Product loan={row} currency_code={this.currency_code}/></div> ,
				width:"250"
			},
			{
				Header : language_json.submitted_on,
				id: "loan_appl_date",
				accessor:row => {return format_date_tbl(row.loan_appl_date,true)}
			},
			{
				Header : language_json.applied_by,
				id: "applied_by",
				sortable : this.state.paginate ? false : true,
				accessor:row => {return row.loan_applicant_name}
			},
			{
				Header : language_json.channel,
				id: "channel",
				accessor:row => dd_value(row.channel,'channel'),
			},
			{
				Header : language_json.status,
				id: "status",
				accessor:row => {return dd_value(row.status)}
			},
			{
				Header : language_json.action,
				id: "cs_result_code",
				accessor:row => {return row.cs_result_code ? dd_value(row.cs_result_code) : "NULL"}
			},			
			]
		}

	changeHandler = (e) => {		
		const toFilter = e
		const filterfrom = this.state.rowdatas
		const filteredData = filterfrom.filter(value => {
			return(
				value.loan_appl_doc_id !==null && value.loan_appl_doc_id.toLowerCase().includes(toFilter.toLowerCase()) ||				
				value.loan_doc_id !==null && value.loan_doc_id.toLowerCase().includes(toFilter.toLowerCase()) ||
				value.acc_number !==null && value.acc_number.includes(toFilter) ||
				value.product_name !==null && value.product_name.toLowerCase().includes(toFilter.toLowerCase()) ||
				value.status !==null && value.status.toLowerCase().includes(toFilter.toLowerCase()) ||
				value.cs_result_code !==null && value.cs_result_code.toLowerCase().includes(toFilter.toLowerCase())         				
			)
		})
		this.setState(state=>state.copy = filteredData)		
	}


	handlePurposeChange = (selected_options,id) =>{
		this.setMultiselectOptionOnState(selected_options,id,this.state.searchLoanAppl)

	}
	pagination = (page, count, sorted)=>{
			
		this.setState((state) => {
			return {
			  paginate: {
				...state.paginate,
				pagination_count: count,
				pagination_page:page,
				sorting : sorted,
				total_count : this.state.total
			  },
			};
		  }, ()=>this.LoanApplSearch());
	
}

		page_count = ()=>{
			
			const total = this.state.total
			const page_count_mod = (total%this.state.paginate.pagination_count)
			
			if(page_count_mod > 0){
				const page_count = (total/this.state.paginate.pagination_count)
				this.setState({'page_count' : Math.trunc(page_count)+1}, ()=>console.log(this.state.page_count))

			}else{
				const page_count = (total/this.state.paginate.pagination_count)
				this.setState({'page_count' : page_count})
			}
		}

		handleExportCSV = (event) => {

			let loan_appl = JSON.parse(JSON.stringify(this.state.search_criteria));
			let resp = null;
			if(event == 'email') {
				loan_appl.export = 'email'
				resp = window.confirm(`The current search exceeds ${Config("export_csv").limit} rows. So the exported data will be sent to your email shortly.`)
			}
			if(event == 'email' && !resp){
				return
			}else{
				this.handleCSVapi(loan_appl, event)
			}
		}

		handleCSVapi(loan_appl, action){
			let calling_api = true
			if (action == 'email') calling_api = false 
			var request={loan_appl_search_criteria:loan_appl, paginate : false}
			searchLoanAppl(this.req(request, true, null, calling_api), true)
				.then((response) => {
					this.resp(response)
					if(action == 'email'){
						return
					}
					this.setState(state=>state.export_data = response.data)
				})
		}

	render(){		
		return(
			<div className="container containerTopmargin max-width no-padding">
			<div className="floatAdvancetitleBox">
				<h5 className="preHeaderpad no-margin" style={{paddingLeft:15,fontSize:20}}>{this.state.language_json.search_fa_appl_title}</h5>
			</div>	
			<div className='text-right' style={{marginTop: this.state.modify_search === "show_div" ? 15 : 0}}>		
				<div className={this.state.modify_search}> 			
					<button type="button" className="btn btn-md btn-light" id="float_style"onClick={this.handleModifySearch} >{this.state.language_json.modify_search}</button>
				</div>
   		  	</div>
			<div className={this.state.serach_loan_appl}>	
		    <SearchLoanCriteria id="searchLoanAppl" mode="loan_appl" 
									onSearch={this.handleSearch} 
									search_btn_style={this.state.search_btn_style}
									onComponentChange={this.handleComponentChange} 
									onComponentReset={this.handleComponentReset}
									disable_btn = {this.state.calling_api} 
									handlePurposeChange={(a,b)=>{this.handlePurposeChange(a,b)}}
									onComponentElementChange={this.handleComponentElemetChange}/>
			</div>	
			 {this.state.rowdatas.length > 0 &&				
			<div className={`${this.state.datatable_div_style}`}>
                {/* <table id ='loan_list' className='list table no-footer'>
                  <thead className={"text-info"}>
                  <tr><th>Float Advance Application ID</th>
                 	  <th>FA ID</th> 
	                  <th>Data Provider Cust ID</th>
	                  <th>Product Code</th>
	                  <th>Product Name</th>
	                  <th>Submitted On</th>
	                  <th>Status</th>
					  <th>Action</th>
                  </tr>
                  </thead>
                  <tbody> 
                 {this.state.loanApplicationResults}
                  </tbody>
                </table> */}
				<div className={`${this.state.paginate ? 'd-flex justify-content-between align-items-center col-md-5' : 'd-flex justify-content-between align-items-center'}`} style={{padding:5, marginTop:15, top : `${this.state.paginate ? '48px' : '0px'}`}}>
					{!this.state.loader && <p className='no-margin text-white'><b>{this.state.language_json.total_entries} : {this.state.total}</b></p>}
					{!this.state.paginate && 
						<div style={{position:'relative'}}>
							<i class="fa-solid fa-magnifying-glass search_pos"></i>
							<input placeholder='Search here' className='search_posInput' onChange={(e)=>this.changeHandler(e.target.value)}></input>
							{/* <button className='btn-std btn btn-primary' onClick={()=>this.resetHandler()}>Reset</button> */}
						</div>
					}
				</div>

					

				<div style={{marginTop : `${this.state.paginate ? '18px' : '0px'}` }}> {this.state.copy.length >0 && <>
					<div style={{display:!this.state.loader ?'':'none'}}>
						{this.state.paginate == null ?
								// <CommonReactTable showPag={true} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} defaultPageSize={10} resizable={false} defaultSorted={[{id:"loan_appl_date",desc:true}]} exportCSV={true} csvFile={"FA applications"} csvData={this.state.copy !== undefined ? this.state.copy : []} searchData={[{...this.state.searchLoanAppl,title:'Search Float Applications'}]}/>

								<BasicReactTable pagination={true}  row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} default_page_size={10}  defaultSorted={[{id:"loan_appl_date",desc:true}]} csv_file={true} FName={"FA applications"} export_csv_datas={this.state.copy !== undefined ? this.state.copy : []} searchData={[{...this.state.searchLoanAppl,title:'Search Float Applications'}]}/>
							:

							<BasicReactTable pagination={this.state.copy !== undefined &&  this.state.copy.length > 9 ? true : false}  row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} default_page_size={10}  defaultSorted={[{id:"loan_appl_date",desc:true}]} csv_file={true} FName={"FA applications"} export_csv_datas={this.state.export_data !== undefined ? this.state.export_data : []} searchData={[{...this.state.searchLoanAppl,title:'Search Float Applications'}]} filter_data = {this.pagination} server_side_pagination = {this.state.paginate} total_page_count = {page_count(this.state.total, this.state.paginate.pagination_count)} handleExport = {this.handleExportCSV} csv_total_data = {this.state.total !== undefined ? this.state.total : 0} />
								// <CommonReactTable showPag={true} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} defaultPageSize={10} resizable={false} defaultSorted={[{id:"loan_appl_date",desc:true}]} exportCSV={true} csvFile={"FA applications"} csvData={this.state.export_data !== undefined ? this.state.export_data : []} searchData={[{...this.state.searchLoanAppl,title:'Search Float Applications'}]} filter_data = {this.pagination} pagination = {true} page_count = {this.state.page_count} handleExport = {this.handleExportCSV} csv_total_data = {this.state.total !== undefined ? this.state.total : 0}/>
						}
					</div> 
					</>}
                </div>
				                                            
			</div>	
	}		
	<div style={{display:this.state.loader ?'':'none'}}>
						<div style={{marginTop:"15%"}} > <p className='text-light mt-3 text-center'>Loading...</p>
                			<div className="product_takeup_loader"></div>
						</div>
					</div>		
			</div>

			);
	}
}
export default LoanApplSearchContainer;
