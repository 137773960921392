import React from 'react';
import _ from 'lodash';
import FlowContainer from '../../common/container/core/FlowContainer';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CustRetain from '../component/cust_retain';
import CustFollowUp from '../component/cust_follow_up';
import PrevContacCust from '../component/prev_contac_cust';
import {setValuesIntoRedux} from '../../../Redux/Slices/CommonSlice';
import { connect } from 'react-redux';



class ChrunManagementContainer extends FlowContainer {

    state={
   
        active:'to_retain',
    }

    tabCheck = (e) =>{
            
        this.setState({
            ...this.state,
            active:e,
  
            })}
    
    componentDidMount(){
        this.props.setValuesIntoRedux({screen_from:"churn_manangement"})

    }
            
    render() {
        return (
            <div>
                <h4 className='text-center mt-3'> <b>Churn Management</b></h4>
                    <div >
                    
                        <Tabs
                        id="controlled-tab-example"
                        className = 'cust_follow_up_tab'

                        onSelect={(e)=>this.tabCheck(e)} 
                        >
                        {/* <Tab eventKey="to_retain"   title={ this.state.active =='to_retain' ? <button type="button" class="btn btn-info no-margin repayment_tittle_button w-100" style={{padding:'8px 50px',background:'#4291da'}}> <b> New Churns</b></button> : <button type="button" class="zoom btn no-margin nobg_title_button w-100"  style={{fontSize:'13px'}}> New Churns</button>}  >
                        <CustRetain tab = {this.state.active} ComponentChange={this.handleComponentChange}/> 
                        </Tab> */}

                        <Tab eventKey="to_retain"   title={<div> <p className={`text-white text-center m-0   ${(this.state.active =='to_retain') ? "active_churn_fontSize" :"zoom"}`} > New Churns</p> <p className={`mx-0 mt-3 ${(this.state.active =='to_retain') ? "active_churn_text" :""}` } style={{borderBottom:'1px solid gray'}}></p> </div>}  >
                        {(this.state.active =='to_retain') &&
                         <CustRetain tab = {this.state.active} ComponentChange={this.handleComponentChange}/> 

                        }
                        </Tab>
                        
                        {/* <Tab eventKey="cust_follow_up"  title={ this.state.active =='cust_follow_up' ? <button type="button" class="btn btn-info no-margin repayment_tittle_button w-100" style={{padding:'8px 50px',background:'#4291da', marginLeft:50}}> <b>Previously Contacted</b></button> : <button class="zoom btn no-margin nobg_title_button w-100" style={{fontSize:'13px', marginLeft:50}}> Past Churns</button>}   >
                        <CustFollowUp  tab = {this.state.active} onComponentChange={this.handleComponentChange}/> 
                        </Tab> */}

                        <Tab eventKey="cust_follow_up"   title={<div> <p className={`text-white text-center m-0   ${(this.state.active =='cust_follow_up') ? "active_churn_fontSize past_churns" :"zoom"}`} > Past Churns</p> <p className={`mx-0 mt-3 past_churn_right_padding ${(this.state.active =='cust_follow_up') ? "active_churn_text" :""}` } style={{borderBottom:'1px solid gray'}}></p> </div>}  >
                      
                        { (this.state.active =='cust_follow_up') &&
                        <CustFollowUp  tab = {this.state.active } onComponentChange={this.handleComponentChange}/> 
                        }
                        </Tab>

                        
                        {/* <Tab eventKey="prev_cont_cust"  title={ this.state.active =='prev_cont_cust' ? <button type="button" class="btn btn-info no-margin repayment_tittle_button w-100" style={{padding:'8px 50px',background:'#4291da',marginLeft:50}}> <b>  Previously Contacted</b></button> : <button class="zoom btn no-margin nobg_title_button w-100" style={{fontSize:'13px', marginLeft:50}}> PREVIOUSLY CONTACTED</button>}  >
                        <PrevContacCust  tab = {this.state.active} onComponentChange={this.handleComponentChange}/> 
                        </Tab> */}
                        </Tabs>
                  </div>
            </div>
        )
      }
   

}




  const mapStateToProps = (state) => {
	return {
	  softPhoneState: state.cs_soft_phone,

	};
  };

const mapDispatchToProps ={
    setValuesIntoRedux
  
  };


export default connect(mapStateToProps, mapDispatchToProps)(ChrunManagementContainer);






