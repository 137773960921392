import React from 'react';
import { } from 'react-router-dom';
import '../../../styles/flow.css';
import {listAccountProvider} from '../../../actions/account_provider_actions';
import FlowContainer from '../../common/container/core/FlowContainer';
import StatusButton from '../../common/component/core/StatusButton';
import {dd_value} from '../../../actions/common_actions';
import $ from 'jquery';
import { Homeacc_prvdrLogoselector } from '../../../helpers/common_helper';
import { BasicReactTable } from '../../../helpers/react_table';

class ListAccProviderContainer extends FlowContainer {

  state = {
     accPrvdrList: null,
     columndatas:[]
  }

 
  componentWillMount(){
      
      const country_code = this.country_code;
      //const status = "enabled";
    listAccountProvider(this.req({country_code}))
        .then((response) => {
          if(!response){return };
          this.setState(state=>state.rowdatas = response.data.list)
          // const accPrvdrList = response.data.list.map((acc_prvdr,i)=>
          //   <tr key={i}>
          //     <td > {acc_prvdr.name}</td>
          //     <td > {acc_prvdr.account_types}</td>
          //     {/* <td >{acc_prvdr.acc_prvdr_code}</td>  */}
          //     <td > <Homeacc_prvdrLogoselector logotype={acc_prvdr.acc_prvdr_code}/></td>                
          //     <StatusButton id={acc_prvdr.id} entity="acc_providers" status={acc_prvdr.status}/>
          //   </tr>
          // );
          this.setState(state=>state.columndatas = this.columndatas())
          // this.setState({accPrvdrList: accPrvdrList});
            //  $('#list').removeAttr('width').dataTable({
            //       "order" : [],
            //       "columns": [
            //            {"width" : "5%"}, 
            //            {"width": "5%"},
            //            {"width": "5%"},
            //            {"width": "5%"},
            //            {"width": "5%"}
                      
                      
            //          ],

            //       dom:'Bfrtip',
            //       buttons: [
            //       'csv','excel','print'
            //       ]
            
            //   });
        });
  }

  columndatas () {
    return [
      {
        // Header: "Name",
        // accessor: 'name',


        Header : "name",
        // id: "loan_principal",
        accessor: "name", 
        maxWidth:150,    
        
        Cell : cell => {return <>{cell.row.original.name}</>}    
      },
      {
        Header: "ACC PRVDR Type",
        accessor: 'account_types',
        Cell : cell => {return <>{cell.row.original.account_types}</>}    
      },
      {
        Header: "ACC PRVDR",
        accessor: 'acc_prvdr_code',
        Cell : cell => {return <><Homeacc_prvdrLogoselector logotype={cell.row.original.acc_prvdr_code} /></>}    

      },
      {
        Header: "Status",
        accessor: 'status',
        Cell : cell => {return <>  <StatusButton 
          head='1' 
          id={cell.row.original.id} 
          entity="acc_providers" 
          status={cell.row.original.status} 
        /></>}    

   
      },
      {
        Header: "Action",
        accessor: 'action',
        Cell : cell => {return <><StatusButton 
          head='2' 
          id={cell.row.original.id} 
          entity="acc_providers" 
          status={cell.row.original.status} 
        /></>}    

        // Cell: ({ row }) => (
        //   <StatusButton 
        //     head='2' 
        //     id={row.original.id} 
        //     entity="acc_providers" 
        //     status={row.original.status} 
        //   />
        // )
      }
    ];
  }

  render(){  
     

   if (this.state.columndatas != null && (this.state.rowdatas&&this.state.rowdatas.length>0)) { 
      
    return( <div className='container containerTopmargin max-width no-padding'>
             <h5 className='preHeaderpad headerTitle no-margin'>{this.props.title || "Account Provider List"}</h5>    

            {/* <BasicReactTable  row_data ={this.state.rowdatas? this.state.rowdatas : []} column_data={this.state.columndatas}   /> */}
{console.log('this.state.rowdatas', this.state.rowdatas)}
            <BasicReactTable row_data={this.state.rowdatas} column_data={this.state.columndatas} csv_file={true} FName="Outstanding_Advances" pagination={true} default_page_size={10} /> 

                   
        </div>
     

      );

      
    }
   

      return (
        <div><p>No Account Providers Available ! </p></div>
        
      );
  }
}

export default ListAccProviderContainer;
