



export const input_field_variants = ((theme)=> {
    return { 
        // outlined
        "&.default_outlined_variant":{
            "& .MuiOutlinedInput-notchedOutline":{
                    borderColor:theme.palette.custom_colors.grey,
                    borderWidth:'1px'
            },
            "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.custom_colors.primary,
                  borderWidth: "3px",
                },
            },
              "&:hover:not(.Mui-focused)": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.custom_colors.primary,
                  borderWidth:'3px'
                },
              },
            
        }
    
                

}

})