import {} from 'react-router-dom';
import React from 'react';
import '../../../styles/flow.css';
import FlowComponent from '../../common/component/core/FlowComponent';
import { Modal , Button} from 'react-bootstrap';
import {title_case} from '../../../helpers/common_helper';
import { createMasterData , getAllDataKeys} from '../../../actions/master_data_actions';
import { getMasterData, dd_value } from '../../../actions/common_actions';
import $ from 'jquery';
import StatusButton from '../../common/component/core/StatusButton';
import { BasicReactTable } from '../../../helpers/react_table';

class MasterData extends FlowComponent{

	state = {
		dataKeyButtons: null,
		comp_name: "master_data",
		datatable_div_style: "hide_div",
		row_data:[],
		column_data:[]
	}

	componentWillMount() {
	   
	   const country_code = this.country_code;
	   const status = "enabled";

	   const request = {"country_code": country_code, "status": status, "add_no_parent": false}
	   getAllDataKeys(this.req(request))
	       .then((response) => {
          if(!response){return };

	            if(response.status === "success"){

	            	const global = [];
	            	const country_specific = [];
	            	
	            	response.data.list.map((item, i) => {
		            		if(item.country_code === "*") {
		            			global.push({"data_key": item.data_key,"data_type": item.data_type,  "data_group": item.data_group}) 
		            		}else{
		            			 country_specific.push({"data_key": item.data_key,"data_type": item.data_type, "data_group": item.data_group})
		            			}
	           			}
	            	);
	            	
                    const globalDatakeyButtons = global.map((item, i) => 

                    	//this.global = "*"

                    	<input type="button" key={i} id={item.data_key} name="*" className="btn_style btn btn-success" onClick={(e) => {this.handleClick(e,item.data_type)}} value={title_case(item.data_key) + " (" +item.data_group + ")"} />
                    	);
                    this.setState({globalDatakeyButtons: globalDatakeyButtons});

                    const countrySpecificDatakeys = country_specific.map((item, i)=> 
                    	
                    	<input type="button" key={i} id={item.data_key} name={this.country_code} className="btn_style btn btn-warning" onClick={(e) => {this.handleClick(e,item.data_type)}} value={title_case(item.data_key)+ " (" +item.data_group + ")"} />
                    	
                    	

                    	);
                    this.setState({countrySpecificDatakeys: countrySpecificDatakeys});
	              
	            }else{
	              alert(response.message + "\n" + JSON.stringify(response.data));  
	            }
	          });
		        
	}


	handleClick = (event, data_type) => {
		this.data_type = data_type;
		if(event.target.name === "*"){
			this.globalCheckboxChecked = true;
			this.countryCheckboxChecked = false;
						
			this.setState({country_code: "*", country_spcfc_div_style: "disable_div"});
		}else{
		
			this.setState({country_code: this.country_code});
		}
		//if(this.previous_table_id){
		// var prev_table = $('#table').DataTable();
		// prev_table.destroy();
	
		this.title_case_data_key =  title_case(event.target.id);
		this.data_key = event.target.id;
		this.listMasterData();
		

	}

	addButtonClick = (event) => {
		this.setState({modalBox: true});
		const country_code = this.country_code;
  		const data_key = this.data_key;
  		const status = "enabled";
  		const request = {country_code, data_key, status};
  		//this.setState({data_key: title_case(this.title_case_data_key)});
  		//alert(data_key);
		  		
  		

  		this.setOptionsListToStateFromAppApi('common/key', 'parent_data_codes', this.req(request), ['data_code', 'data_value']);
		
	}

	handleClose = (event) => {
		this.resetModalBox();
    	this.setState({ modalBox: false });
    	
    	
  	}


  	
  	resetModalBox(){
  		this.props.data.data_code = "";
	    this.props.data.data_value = "";
	    this.props.data.data_type = "";
		this.props.data.parent_data_code = "";
		
  	}

  	handleGlobalCheckbox = (event) => {
  		if(event.target.checked){
  			
  			this.setState({country_code: "*"});
  		}else{
  			this.setState({country_code: this.country_code, country_spcfc_div_style: "show_div"});
  		}
  	}


  	addMasterData = (event) =>{
  		event.preventDefault();
  	
		 const parent_data_code = this.props.data.parent_data_code;
		 const data_code = this.props.data.data_code;
		 const data_value = this.props.data.data_value;
		 const data_key = this.data_key;
		 const data_type = this.data_type;
		 const country_code = this.state.country_code;
		 const status = "enabled";
		 const master_data = {parent_data_code , data_code , data_value , data_key , data_type, country_code, status};
		createMasterData(this.req({master_data}))
	        .then((response) => {
   			   if(!response){return };
	            if(response.status === "success"){
	              alert("Master Data added successfully!");

	              this.setState({ modalBox: false });
	              this.resetModalBox();
	    	      const request = {"country_code": this.country_code, data_key: this.title_case_data_key}
				  const request_data = {"master_data": request};
			      //getMasterData(request_data);
			      $('#table').dataTable().fnDestroy();
	         	  this.listMasterData();
	              //this.setState({toList: true});

	            }else{
	              alert(response.message + "\n" + JSON.stringify(response.data));  
	            }
	          }
	        );
		 this.setState({ modalBox: true });   

  	}

  	listMasterData = () => {
  		
		//this.data_key = event.target.id;
		const request = {"country_code": this.state.country_code, data_key: this.data_key}
  		const request_data = {"master_data": request};

        getMasterData(this.req(request_data))
	        .then((response) => {
          if(!response){return };
	           
	            if(response.status === "success"){
	            
	            //   const masterDataList = response.data.map((master_data,i)=>
				// 			<tr key={i}>
			    //             <td> {master_data.data_value}</td>
			    //             <td> {master_data.data_code}</td>
			    //             <td> {master_data.parent_data_code == null ? "No Parent" : master_data.parent_data_code}</td>
			    //             <StatusButton id={master_data.id} parent="master_data" entity="master_data" status={master_data.status}/>
			    //           	</tr>
		                                              		
		        //       ); 
		        this.setState({datatable_div_style: "show_div"});
		        
           		// this.setState({masterDataList: masterDataList});
           		this.setState({parent_data_codes_dd : null});
				this.setState({row_data:  response.data, column_data:this.generate_column_data()})
			    // $('#table').removeAttr('width').dataTable({
			    // 		  "order" : [],
	            //    dom:'Bfrtip',
                //   buttons: [
                //   'csv','excel','print'
                //   ]
	            
	            // });

	            
	            
            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          }
        );
  	}


  handleChangeDataValue = (event) => {
    // Copy data from one text box to another
         this.handleChange(event);
         var value = event.target.value;
         value = value.replace(/ /g, "_").toLowerCase();
         document.getElementById('data_code').value = value;
         //this.setState({[targetId]: value});

         this.props.data['data_code'] = value;
  }

  generate_column_data = ()=>{
	return [
		{
			Header: <div className='text-center'>{this.title_case_data_key}</div>,
			id: "data_value",
			accessor: "data_value",
			Cell: (cell) => {
			  return <div className='text-center'>{cell.row.original.data_value}</div>;
			}
		},
		{
			Header: <div className='text-center'>{this.title_case_data_key} Code</div>,
			id: "data_code",
			accessor: "data_code",
			Cell: (cell) => {
			  return <div className='text-center'>{cell.row.original.data_code}</div>;
			}
		},
		{
			Header: <div className='text-center'>Parent Data Code</div>,
			id: "parent_data_code",
			accessor: "parent_data_code",
			Cell: (cell) => {
			  return <div className='text-center'>{cell.row.original.parent_data_code == null ? "No Parent" : cell.row.original.parent_data_code}</div>;
			}
		},
		{
			Header: <div className='text-center d-flex justify-content-center'>Status</div>,
			id: "status",
			accessor: "status",
			Cell: (cell) => {
			  return <div className='text-center'><p className={`m-0`}>{cell.row.original.status}</p></div>;
			}
		},
		{
			Header: <div className='text-center'>Action</div>,
			id: "action",
			accessor: "action",
			Cell: (cell) => {
			  return <div className='text-center '>{ <StatusButton id={cell.row.original.id} parent="master_data" entity="master_data" status={cell.row.original.status}/>}</div>;
			}
		},


	]
  }




  render(){
  	
    return (

	 <div id="" >
	 	<div className="container">
	 		
	 		<div className="row">
	 			<h5>Add Master Data</h5>
	 		</div>

	 		<div className="row">
	 			<h5><b>Global</b></h5>
	 		</div>
	 		{this.state.globalDatakeyButtons}
	 		<br/>

	 		<div className="row">
	 			<h5><b>{this.country_code}</b></h5>
	 		</div>
	 		{this.state.countrySpecificDatakeys}
	 		<br/>
	 		<br/>

	 		<div className={this.state.datatable_div_style}>			
	 			<div id='datatable'>
	 			<div className="d-flex align-items-center">


	 				<h4>{this.title_case_data_key}{this.state.country_code === "*" && " - Global"}</h4>&nbsp;&nbsp;

	 				<input type="button" className="btn btn-primary" onClick={this.addButtonClick} value="Add"/>

	 			</div>
	 			<br/>


	 			{/* <table id ="table" className="display strip row-border order-column list">
                      <thead><tr>
                      <th>{this.title_case_data_key}</th>
                      <th>{this.title_case_data_key} Code</th>
                      <th>Parent Data Code</th>
                      <th>Status</th>
                      <th>Action</th>
                      </tr></thead>
                      <tbody> 
                      {this.state.masterDataList}
                      </tbody>
             	</table>  */}
				<BasicReactTable row_data={this.state.row_data} column_data={this.state.column_data} csv_file={true} FName='master_data' default_page_size={10} pagination={true} export_csv_datas={this.state.row_data}/>

	 			<Modal show={this.state.modalBox} onHide={this.handleClose}>
			        <Modal.Header closeButton show={this.state.modalBox} onHide={this.handleClose}>
			            <Modal.Title>Add {this.title_case_data_key} </Modal.Title><br/>
			            
			        </Modal.Header>

			        <Modal.Body>
			        <h5>Data Scope : {this.state.country_code == "*" ? "Global" : this.state.country_code} </h5>
				        <div className="container">
				        <div className="row text-labelblue">
				        	<div className="col-md-6">
				        	<span>{this.title_case_data_key} Value</span><font className="text-danger"> * </font>
				        		<input type="text" id="data_value" value={title_case(this.props.data.data_value)} onChange={this.handleChangeDataValue} className="form-control" />
				        	</div>
				        </div>
						<br/>
				        <div className="row text-labelblue">
				        	<div className="col-md-6">
				        	 <span>{this.title_case_data_key} Code</span><font className="text-danger"> * </font>
				        		<input type="text" id="data_code" value={this.props.data.data_code} onChange={this.handleChange} className="form-control" />
				        	</div>
				        </div>
				        <br/>

				         {/*<div className="row">
				        	<div className="col-md-6">
				        		<label>Data Type</label><font className="text-danger"> * </font>
				        		<select type="text" id="data_type" value={this.props.data.data_type} onChange={this.handleChange} className="form-control">
				        		{this.getSelectOptions("data_type")}
				        		</select>
				        	</div>
				        </div>	
				    	<br/>*/}
				        {this.state.parent_data_codes_dd &&
				        <div className="row">
				        	<div className="col-md-6">
				        		<label>Parent Data Code</label>
				        		<select type="text" id="parent_data_code" value={this.props.data.parent_data_code} onChange={this.handleChange} className="form-control">
				        		{this.state.parent_data_codes_dd}
				        		</select>
				        	</div>
				        </div>}

				         

				        {this.state.show_data_scope &&
				        <div className="row">
				        	<div className="col-md-6">
				        		<label>Data Scope</label><br/>&nbsp;&nbsp;
				        		<label><input type="radio" name="data_scope" onClick={this.globalRadiobtn} defaultChecked={this.globalCheckboxChecked}/>&nbsp;&nbsp; Global</label>
				                <br/>&nbsp;&nbsp;
				                <label className={this.state.country_spcfc_div_style}><input type="radio" name="data_scope" onClick={this.countrySpcfcRadiobtn} defaultChecked={this.countryCheckboxChecked}/>&nbsp;&nbsp; {this.country_code}</label>
				                {/*<label className={this.state.country_spcfc_div_style}><input type="checkbox" onChange={this.handleCountryCheckbox} defaultChecked={this.countryCheckboxChecked}/>&nbsp;{this.country_code}</label>*/}
				        	</div>
				        	    <br/>
				        </div>
				    
				    }
				        </div>

			        </Modal.Body>

			        <Modal.Footer>
				        <Button variant="secondary" onClick={this.handleClose}>Close</Button>
				        <Button type="button" variant="primary" onClick={this.addMasterData}>Add</Button>
			        </Modal.Footer>
			    </Modal>

	 			 
        		</div>
	 		</div>
	 		
        </div>

    </div>
    );
  }
}


export default MasterData;

