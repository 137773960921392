import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


export const updateNotficationModalstate = createAsyncThunk(
    'header/updateNotficationModalstate',
    async (notification, { dispatch }) => {
      dispatch(changeNotificationState({
        portfolio_details: notification
      }));
      return notification;
    }
  );

const initialState = {
    notification:{
        un_read_notifications_count:0,
        show_notification:false,
        notification_arr : [],
        no_notification:false,
        loader:false,
        portfolio_modal : false,
        portfolio_details:null
      },
      header_client_tab_id : null,   
}
const HeaderSlice = createSlice({
    name: 'header',
    initialState,
    reducers:{
        changeNotificationState:(state, action)=>{
            state.notification = {
                ...state.notification,
                ...action.payload
            }
        },
        changeHeaderClientTabId : (state, action)=>{
          state.header_client_tab_id = action.payload
        },
    },
    extraReducers: (builder)=>{
        builder.addCase(updateNotficationModalstate.fulfilled, (state, action)=>{
        })
    }
})



export const {changeNotificationState, changeHeaderClientTabId} = HeaderSlice.actions;
export default HeaderSlice.reducer