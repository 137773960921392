import React from "react";
import FlowComponent from '../../common/component/core/FlowComponent';
import { AiOutlineIdcard,AiOutlineMobile,AiFillShop ,AiFillEdit} from "react-icons/ai";
import{MdBusiness,MdPerson,MdAccountCircle,MdImage,MdPersonOutline,MdPhoto,MdLocationPin,MdArrowDropDown} from "react-icons/md";
import { BiRefresh,BiPhoneOff ,BiPhoneCall,BiXCircle,BiPhotoAlbum,BiEdit,BiMap,BiCheck} from "react-icons/bi";
import{GoVerified} from "react-icons/go";
import{FaHandsHelping} from "react-icons/fa";
import{HiOutlineDocumentAdd,HiPhoneOutgoing,HiArrowCircleRight,HiArrowCircleLeft}from"react-icons/hi";
import{ImCircleDown,ImCircleUp} from "react-icons/im";
import{ CgNotes}from'react-icons/cg';
import{BsClipboardData}from'react-icons/bs';
import FileUpload from '../../common/component/FileUpload';
import Alert from 'react-bootstrap/Alert';
import {get, Config} from '../../../helpers/storage_helper';
import { viewLead ,UpdateFlags,addComments,auditKycVerification,auditKyRetrievals,auditKycFileUpload, submitCallLog,RejectCallLog,auditkycModify,updateAccPrvdrCode} from '../../../actions/lead_actions';
import {capitalize_first_letter,get_dir_path} from '../../../helpers/common_helper';
import { approveKYC,updateLeadStatus} from '../../../actions/kyc_actions';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import isValid from 'date-fns/fp/isValid';
import {dd_value} from '../../../actions/common_actions';
import GoogleMapSetup from '../../../helpers/googleMap';
import { Homeacc_prvdrLogoselector , convert_sec_to_min_sec} from '../../../helpers/common_helper';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Form from 'react-bootstrap/Form';

class AuditKycVerificationSections extends FlowComponent{
    constructor() {
        super();
      
    }

     

    render(){
        return(
          <>
          {this.props.verification_datas && this.props.verification_datas.active_section && 
            <div className={`${(((this.props.verification_datas.responseData.audited_by== get("user_id")) || (this.props.verification_datas.responseData.audited_by==null )) && this.props.verification_datas.active_section.pending_with !="rm") ?"":'disable_div'}`}>
            {this.props.verification_datas.audit_kyc_wizard_menus.id_proof &&
               <>
             {this.props.verification_datas.active_section && (!this.props.verification_datas.active_section.to_reassign)&&
             <>
              {this.props.verification_datas.active_section.verify ?
                <div className='d-flex  justify-content-center mt-4'>
                  <button type="button" class="btn btn-danger z_index" onClick={()=>this.props.unverify_handler()}> <b>Unverify</b></button>
                 </div>
                :
                <div >
                    <div className='d-flex  justify-content-center mt-4'>
                  <button type="button" class={`${(this.props.verification_datas.id_proof_verify  && (this.props.verification_datas.responseData.type=="re_kyc" ? this.props.verification_datas.verify_bttn_status :true)) ? "btn btn-success":"btn btn-secondary"}` } 
                  disabled={( this.props.verification_datas.id_proof_verify && (this.props.verification_datas.responseData.type=="re_kyc" ? this.props.verification_datas.verify_bttn_status :true) ) ?false:true } onClick={()=>this.props.verify_handler()}> <b>Verify</b></button>
                  </div>
                  <div className='d-flex  justify-content-center z_index'>
                  <p className='text-white no-margin' style={{fontSize:'15px'}}> Check all the items in the checklist to verify</p>
                  </div>
                </div>
            
              }
              </>
            }
              </>
            }  
            {/* {this.props.verification_datas.audit_kyc_wizard_menus.license_proof &&
                <>
                {this.props.verification_datas.active_section && (!this.props.verification_datas.active_section.to_reassign)&&
                <>
                 {this.props.verification_datas.active_section.verify ?
                   <div className='row  justify-content-center mt-4'>
                     <button type="button " class="btn btn-danger z_index" onClick={()=>this.props.unverify_handler()}> <b>Unverify</b></button>
                    </div>
                   :
            
                   <div >
                       <div className='row  justify-content-center mt-4'> 
                       <button type="button " class={`${this.props.verification_datas.lic_proof_verify ? "btn btn-success ": "btn btn-secondary" } z_index `} disabled={this.props.verification_datas.lic_proof_verify ? false:true} onClick={()=>this.props.verify_handler()}> <b>Verify</b></button>

                     </div>
                     <div className='row  justify-content-center'>
                     <p className='text-white no-margin' style={{fontSize:'15px'}}> Check all the items in the checklist to verify</p>
                     </div>
                   </div>
            
                 }
                 </>
                  }
            
                 
                 </>
             } */}
            
            {this.props.verification_datas.audit_kyc_wizard_menus.contact_persons &&
               <>
             {this.props.verification_datas.active_section && (!this.props.verification_datas.active_section[0].to_reassign)&&
             <>
              {this.props.verification_datas.active_section[0].verify ?
                <div className='d-flex  justify-content-center mt-4'>
                  <button type="button" class="btn btn-danger z_index" onClick={()=>this.props.unverify_handler()}> <b>Unverify</b></button>
                 </div>
                :
                <div >
                    <div className='d-flex  justify-content-center mt-4'>
                  <button type="button" class={`${(this.props.verification_datas.active_section[0].flags.clear_and_readable  &&  this.props.verification_datas.active_section[0].flags.face_recogonizable  &&  this.props.verification_datas.active_section[0].flags.original_national_id ) ? "btn btn-success":"btn btn-secondary"}` } disabled={(this.props.verification_datas.active_section[0].flags.clear_and_readable  &&  this.props.verification_datas.active_section[0].flags.face_recogonizable  &&  this.props.verification_datas.active_section[0].flags.original_national_id ) ?false:true } onClick={()=>this.props.verify_handler()}> <b>Verify</b></button>
                  </div>
                  <div className='d-flex  justify-content-center z_index'>
                  <p className='text-white no-margin' style={{fontSize:'15px'}}> Check all the items in the checklist to verify</p>
                  </div>
                </div>
            
              }
              </>
               }
            
              </>
            }  
            
             {this.props.verification_datas.audit_kyc_wizard_menus.biz_accounts &&
                <>
                {this.props.verification_datas.active_section && (!this.props.verification_datas.active_section.to_reassign) &&
                <>
                {this.props.verification_datas.active_section.verify ?
                  <div className='d-flex  justify-content-center mt-4'>
                    <button type="button" class="btn btn-danger z_index" onClick={()=>this.props.unverify_handler()}> <b>Unverify</b></button>
                    </div>
                  :
                  <div>

                      <div className='d-flex  justify-content-center mt-4'>
                    <button type="button" class={` z_index ${ ( this.props.verification_datas.acc_owner_verify )? " btn btn-success" : " btn btn-secondary" }`} disabled={( this.props.verification_datas.acc_owner_verify ) ? false :true }
                     onClick={()=>this.props.verify_handler("acc_owner",null,null,this.props.verification_datas.cust_reg_json)}> <b>Verify</b></button>
                    </div>
                    <div className='d-flex  justify-content-center'>
                    <p className='text-white no-margin' style={{fontSize:'15px'}}> Check all the items in the checklist to verify</p>
                    </div>
                  </div>
            
                }
                </>
                  }
                </>
              }
              
              {this.props.verification_datas.audit_kyc_wizard_menus.kyc_photos &&
                <>
                {this.props.verification_datas.active_section && (!this.props.verification_datas.active_section.to_reassign) &&
                <>
                 {this.props.verification_datas.active_section.verify ?
                   <div className='d-flex  justify-content-center mt-4'>
                     <button type="button" class="btn btn-danger z_index" onClick={()=>this.props.unverify_handler()}> <b>Unverify</b></button>
                    </div>
                   :
            
                   <div >
                       <div className='d-flex  justify-content-center mt-4'>
                     <button type="button" class={` z_index ${(this.props.verification_datas.active_section.flags.face_clear_nd_visible  &&  this.props.verification_datas.active_section.flags.passport_photo_match  &&  this.props.verification_datas.active_section.flags.portrait_mode &&  this.props.verification_datas.active_section.flags.selfie_photo_match) ? "btn btn-success":"btn btn-secondary"}` } disabled={(this.props.verification_datas.active_section.flags.face_clear_nd_visible && this.props.verification_datas.active_section.flags.passport_photo_match && this.props.verification_datas.active_section.flags.portrait_mode && this.props.verification_datas.active_section.flags.selfie_photo_match) ?false:true } onClick={()=>this.props.verify_handler()}> <b>Verify</b></button>
                     </div>
                     <div className='d-flex  justify-content-center'>
                     <p className='text-white no-margin' style={{fontSize:'15px'}}> Check all the items in the checklist to verify</p>
                     </div>
                   </div>
            
                 }
                 </>
                  }
            
                 
                 </>
               }
               {this.props.verification_datas.audit_kyc_wizard_menus.shop_photo &&
                   <>
                   {this.props.verification_datas.active_section && (!this.props.verification_datas.active_section.to_reassign) &&
                   <>
                    {this.props.verification_datas.active_section.verify ?
                      <div className='d-flex  justify-content-center mt-4'>
                        <button type="button" class="btn btn-danger z_index" onClick={()=>this.props.unverify_handler()}> <b>Unverify</b></button>
                       </div>
                      :
            
                      <div >
                          <div className='d-flex  justify-content-center mt-4'>
                        <button type="button" class={` z_index ${(this.props.verification_datas.active_section.flags.clear_nd_visible  &&  this.props.verification_datas.active_section.flags.covers_nearby_areas  &&  this.props.verification_datas.active_section.flags.covers_shop_name ) ? "btn btn-success":"btn btn-secondary"}` } disabled={(this.props.verification_datas.active_section.flags.clear_nd_visible  &&  this.props.verification_datas.active_section.flags.covers_nearby_areas  &&  this.props.verification_datas.active_section.flags.covers_shop_name ) ?false:true } onClick={()=>this.props.verify_handler()}> <b>Verify</b></button>
                        </div>
                        <div className='d-flex  justify-content-center'>
                        <p className='text-white no-margin' style={{fontSize:'15px'}}> Check all the items in the checklist to verify</p>
                        </div>
                      </div>
            
                    }
                    </>
                     }
            
                    
                    </>
               }
               {this.props.verification_datas.audit_kyc_wizard_menus.mobile_numbers &&
                   <>
                   {this.props.verification_datas.active_section && (!this.props.verification_datas.active_section.to_reassign) &&
                   <>
                    {this.props.verification_datas.active_section.verify ?
                      <div className='d-flex  justify-content-center mt-4'>
                        <button type="button" class="btn btn-danger z_index" onClick={()=>this.props.unverify_handler()}> <b>Unverify</b></button>
                       </div>
                      :
            
                      <div >
                        
                          <div className='d-flex  justify-content-center mt-4'>
                        <button type="button" className={`btn  z_index  ${(this.props.verification_datas.cust_mobile_verify && this.props.verification_datas.addl_mobile_verify) ? 'btn-success':'btn-secondary'}`} disabled={this.props.verification_datas.cust_mobile_verify && this.props.verification_datas.addl_mobile_verify ? false:true } onClick={()=>this.props.verify_handler()}> <b>Verify</b></button>
                        </div>
                        <div className='d-flex  justify-content-center'>
                        <p className='text-white no-margin' style={{fontSize:'15px'}}> Check all the items in the checklist to verify</p>
                        </div>
                      </div>
            
                    }
                    </>
                     }
            
                    
                    </>
               }


               
             
             {this.props.verification_datas.audit_kyc_wizard_menus.data_consent &&
               <>
             {this.props.verification_datas.active_section && (!this.props.verification_datas.active_section.to_reassign) &&
             <>
              {this.props.verification_datas.active_section.verify ?
                <div className='d-flex  justify-content-center mt-4'>
                  <button type="button" className="btn btn-danger z_index" onClick={()=>this.props.unverify_handler()}> <b>Unverify</b></button>
                 </div>
                :
                <>
              <div className='d-flex  justify-content-center mt-4'>
              <button type="button" className={`z_index ${(this.props.verification_datas.active_section.flags.consent_form_data_match && (!this.props.verification_datas.active_section.flags.hasOwnProperty('signature_match') || this.props.verification_datas.active_section.flags.signature_match ==true ) ) ? "btn btn-success":"btn btn-secondary"}` } disabled={(this.props.verification_datas.active_section.flags.consent_form_data_match && (!this.props.verification_datas.active_section.flags.hasOwnProperty('signature_match') || this.props.verification_datas.active_section.flags.signature_match ==true ) ) ?false:true } onClick={()=>this.props.verify_handler()} > <b>Verify</b></button>
              </div>
              <div className='d-flex  justify-content-center'>
              <p className='text-white no-margin' style={{fontSize:'15px'}}> Check all the items in the checklist to verify</p>
            
              </div>
              </>
              }
              </>
               }
            
              
               </>
              } 
                
              {this.props.verification_datas.audit_kyc_wizard_menus.agreements && 
                <>
              {this.props.verification_datas.active_section && (!this.props.verification_datas.active_section[0].to_reassign) &&
              <>
                {this.props.verification_datas.active_section[0].verify ?
                  <div className='d-flex  justify-content-center mt-4'>
                    <button type="button" className="btn btn-danger z_index" onClick={()=>this.props.unverify_handler()}> <b>Unverify</b></button>
                  </div>
                  :
                  <>
                <div className='d-flex  justify-content-center mt-4'>
                <button type="button" className={` z_index ${ (this.props.verification_datas.agreements_verify && (!this.props.verification_datas.active_section[0].flags.hasOwnProperty('witness_contact_verified') || this.props.verification_datas.active_section[0].flags.witness_contact_verified ==true)) ? "btn btn-success":"btn btn-secondary"}` } disabled={(this.props.verification_datas.agreements_verify && (!this.props.verification_datas.active_section[0].flags.hasOwnProperty('witness_contact_verified') || this.props.verification_datas.active_section[0].flags.witness_contact_verified ==true) ) ?false:true } onClick={()=>this.props.verify_handler("mobile_num_verify",null,null,"agreements_verify")}> <b>Verify</b></button>
                </div>
                <div className='d-flex  justify-content-center'>
                <p className='text-white no-margin' style={{fontSize:'15px'}}> Check all the items in the checklist to verify</p>
            
                </div>
                </>
                }
                </>
                }
            
                
                </>
               } 
              {this.props.verification_datas.audit_kyc_wizard_menus.gps_address &&
               <>
             {this.props.verification_datas.active_section && (!this.props.verification_datas.active_section.to_reassign)&&
             <>
              {this.props.verification_datas.active_section.verify ?
                <div className='d-flex  justify-content-center mt-4'>
                  <button type="button" class=" z_index btn btn-danger" onClick={()=>this.props.unverify_handler()}> <b>Unverify</b></button>
                 </div>
                :
                <div >
                    <div className='d-flex  justify-content-center mt-4'>
                  <button type="button" class={`z_index ${(this.props.verification_datas.active_section.flags.gps_address_match && ((this.props.verification_datas.responseData.type=="re_kyc" )? this.props.verification_datas.verify_bttn_status :true)  ) ? "btn btn-success":"btn btn-secondary"}` } disabled={(this.props.verification_datas.active_section.flags.gps_address_match && (this.props.verification_datas.responseData.type=="re_kyc" ? this.props.verification_datas.verify_bttn_status:true) ) ?false:true } onClick={()=>this.props.verify_handler()}> <b>Verify</b></button>
                  </div>
                  <div className='d-flex  justify-content-center'>
                  <p className='text-white no-margin' style={{fontSize:'15px'}}> Check all the items in the checklist to verify</p>
                  </div>
                </div>
            
              }
              </>
               }
            
              </>
              }  
            </div>
            }
            </>
        )        
     }
}
export default AuditKycVerificationSections;
