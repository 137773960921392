import React from 'react';
import FlowContainer from '../../common/container/core/FlowContainer';
import '../../../styles/flow.css';
import {getKPIReport} from '../../../actions/report_actions';
import {dd_value} from '../../../actions/common_actions';
import {rt_algn, lbl_amt, short} from '../../../helpers/common_helper';
import $ from 'jquery';



class KPIReportContainer extends FlowContainer {

state = {
  section_rows_arr : null,
  headers: null
}
format_kpi_metric(kpi_metric){
  var value = null;

  if (kpi_metric) 
  {

   if(kpi_metric.kpi_unit == "%") 
   {
      value = Number(kpi_metric.kpi_value).toFixed(2) + " %"
   }
   else if (kpi_metric.kpi_unit == "days")
   {
      value = Number(kpi_metric.kpi_value).toFixed(1) + " days"
   }
   else if (kpi_metric.kpi_unit == "")
   {
      value = Number(kpi_metric.kpi_value).toFixed(0)
   }
   else {
      if(['core_tot_disbursed_fa_a22', 'cumm_core_tot_disbursed_fa_a22'].includes(kpi_metric.kpi_metric)){
        value  = short(kpi_metric.kpi_value)  + " " + kpi_metric.kpi_unit;
      }else{
        value = lbl_amt(Math.round(kpi_metric.kpi_value), kpi_metric.kpi_unit)
      }

      
 }
}
   return value;
}

metric_in_usd(kpi_metric){
  if(kpi_metric)
  {
  if(["%", "days", "", "Custs", "FAs"].includes(kpi_metric.kpi_unit)) {
     return ""
  }
  else{
    return lbl_amt(Math.round(kpi_metric.usd), "USD");
  }
}
}

get_headers(report_arr){
  const report_values =  report_arr.map((this_rpt) => {
                return (
                      <> 
                          <th>{this_rpt.title}</th>
                          <th>USD Equivalent</th>
                      </>
                      );
                      });
                      
                        
                     report_values.unshift(<th>Metric</th>)
                      report_values.unshift(<th>No</th>)
  return report_values;
}

get_section_rows(section,report_arr)
{ 


    const report_values = section.items.map((kpi_metric,i)=>

      {
        
                  var kpi_metric_code = "";
                 if(kpi_metric.startsWith("cumm_")){
                    kpi_metric_code = kpi_metric.replace("cumm_", "");
                    kpi_metric_code = dd_value(kpi_metric_code) + " (Cummulative)"
                  }else{
                    kpi_metric_code = dd_value(kpi_metric);
                  }

                  if(kpi_metric.startsWith("core_")){
                    kpi_metric_code = <b>{kpi_metric_code}</b>
                  }
	 				      return (
                <tr key={i}>
                    <td className='report_left'>{this.index++}</td>
                    <td className='report_left'>{kpi_metric_code}</td>
                    {this.get_section_columns(kpi_metric, report_arr)}
            	   	</tr> 
                
                );
      });

      report_values.unshift(
        <tr>
            <td  className="report_title"></td>
            <td colSpan="1" className="report_title">{section.title}</td>
            {this.get_section_columns(null, report_arr)}
        </tr>);
     return report_values;
}

get_section_columns(kpi_metric, report_arr)
{
   const report_values =  report_arr.map((this_rpt) => {

      if(kpi_metric)
                return (
                      <>
                      <td>{rt_algn(this.format_kpi_metric(this_rpt[kpi_metric]))}</td>
                      <td style={{color: '#FA4616'}}>{rt_algn(this.metric_in_usd(this_rpt[kpi_metric]))}</td>
                      </>
                      );
      else{
          return (<>
                    <td></td>
                    <td></td>
                  </>);
      }                      
                      });
  return report_values;
}

  componentWillMount() {
 
     const country_code = this.country_code
     const acc_prvdr_code = this.acc_prvdr_code
    getKPIReport(this.req({current:'true',country_code:country_code,acc_prvdr_code:acc_prvdr_code, type: this.props.type}))
        .then((response) => {
          if(!response){return };
            if(response.status === "success"){
              this.index = 1;
              const reports_arr = response.data.reports;
              
              const headers = this.get_headers(reports_arr);
              
              const all_sections = response.data.all_sections;

              const section_rows_arr = all_sections.map((section)=>{
                return this.get_section_rows(section, reports_arr);
              });
              
              this.report_date = "" //reports[first_title];
              this.currency = response.data.currency_code;
              this.forex = response.data.forex;

            	this.setState({headers, section_rows_arr});
              
              $('#kpi_table').removeAttr('width').dataTable({
                    searching : false,
                    paging : false,
                    info : true,
                     dom:'Bfrtip',
                      buttons: [
                      {
                      title:"",
                      extend: 'print',
                      customize: function (win) {
             
                    $(win.document.body)
                        
                        .prepend(
                            '<img src="'+window.location.origin+'/logo_blue.png" alt = "FLOW" style="float:left;"/>'
                        );
 
                   
                }
                    },
                      {
                        extend:'csv',
                        text: 'Export csv',
                      }
                      ],
                      "order" : [],
                      
                      
              });
            
            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          
        }
        );     
  }

render(){

      return (
      <div className="container">
	      	<br/><h5>Flow KPI Report</h5>
          <h4>Forex : 1 USD = {this.forex + " "+ this.currency} </h4><br/>
          <div id='kpi_datatable' >
            <table id="kpi_table" className="display strip row-border order-column report">
                    <thead>
                      <tr>
                        {this.state.headers}
                      </tr> 
                    </thead>
                    <tbody>
                        {this.state.section_rows_arr}
                    </tbody>
            </table>
          </div>
      </div>
      );
  }
}

export default KPIReportContainer;
