import React from 'react'
import {Form,Col,checkbox} from 'react-bootstrap';
import DatePicker from 'react-datetime';
import FlowComponent from '../../common/component/core/FlowComponent';
import { format } from 'date-fns';
import { dd } from '../../../actions/common_actions';
import {getRefAccounts} from '../../../actions/account_txn_actions';
import {get} from '../../../helpers/storage_helper';

class OntimePaymentReport extends FlowComponent{

    state ={

    }
    componentWillMount(){
        this.handleCurdate()
        this.setOptionsListToStateFromAppApi('common', 'list', {...this.def_req ,status: "enabled", biz_account: true}, ['acc_prvdr_code', 'name'], "acc_prvdrs");

        const lender_code = this.lender_code; //event.target.value

        let acc_prvdr_code = get('acc_prvdr_code');
    
        const account = {lender_code, "country_code": this.country_code,to_recon : true, network_prvdr_code : acc_prvdr_code }
    
        if(acc_prvdr_code != undefined){
         
            getRefAccounts(this.req({account},true))
            .then((response) => {
              if(!response){return };
              if(response.status === "success"){
                if(response.data != "")
                {
                  this.getSelectOptionsFromResult(response.data, "lender_accounts", ['id','name']);
                }     
              }else{ 
                alert(response.message + "\n" + JSON.stringify(response.data));  
              }
            }
          );
        } 
    }

   

    handleCurdate(){  
        const date = new Date()
        const cur_date = format(date, 'dd MMM y') 
        return cur_date
    }


    render(){
        return(
          <Form className="mb-3" >
	        	<Form.Row className = "my-4" style={{display:"flex",alignItems:"center"}}>


                    <div className="col-3">
                        <span style={{color:"white"}}>Start Date</span><font className="text-danger"> * </font>
                        <DatePicker defaultValue={this.handleCurdate()} onChange={(value) => {this.handleDateChange("start_date", value)}}   closeOnSelect={true}     dateFormat="DD MMM YYYY" timeFormat={false}  />
                    </div>

                    <div className="col-3">
                        <span style={{color:"white"}}>End Date</span><font className="text-danger"> * </font>
                        <DatePicker defaultValue={this.handleCurdate()} onChange={(value) => {this.handleDateChange("end_date", value)}}   closeOnSelect={true}     dateFormat="DD MMM YYYY" timeFormat={false}  />
                    </div> 

                   	<div className="col-md-3 marT-5" >
                       
                       <div style={{display:'flex'}}>
                       
                    

                      <Form.Group as={Col}>
                      <Form.Label for="day_wise" style={{marginTop:45, marginLeft:20}} >Day Wise
                      <Form.Check  type="checkbox"  id = "day_wise"  className= "float-left mx-2 " style={{marginTop:2}} disabled= {this.props.ontime_pay_cri == 'acc_prvdr_wise'} onChange={(e) =>{this.props.onComponentCheckboxclick(e)}} />
                      </Form.Label>
                      </Form.Group>

                     
                        
                        <Form.Group as={Col}>
                          <Form.Label for="acc_prvdr_wise" style={{marginTop:45}}>Account Prvdr Wise
                            <Form.Check type="checkbox"   id = "acc_prvdr_wise"  className= "float-left mx-2 " style={{marginTop:2}} disabled= {this.props.ontime_pay_cri == 'day_wise'}onChange={(e) =>{this.props.onComponentCheckboxclick(e)}} />
                            </Form.Label>
                          </Form.Group> 
                        </div>
                         
                       
                    </div>	
        
            </Form.Row>

          </Form>

        )
    }

}export default OntimePaymentReport