import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';

import  {format_month_change,lbl_amt,Homeacc_prvdrLogoselector, title_case} from '../../../helpers/common_helper';
import { BasicReactTable } from '../../../helpers/react_table'
import { Link } from 'react-router-dom';
import { list_lender_txn_stmts } from '../../../actions/lender_actions';
import InputSelectionHeader from '../component/InputSelectionHeader';
import { check_priv, get } from "../../../helpers/storage_helper";
import { getReport } from "../../../actions/report_actions";
import moment from "moment";
import MultiSelecter from '../../../helpers/multiSelecter';
import ViewParFas from '../../loan/component/viewParFas';
import MonthPicker from '../../../helpers/monthPicker';
import DatePicker from 'react-datetime';

import Toggle from '../../common/component/Toggle';
import {getCurrencyDetails} from "../../../actions/report_actions";

import { FcCalendar } from 'react-icons/fc';

const pardays = [
  { value: "5", label: "5" },
  { value: "10", label: "10" },
  { value: "15", label: "15" },
  { value: "30", label: "30" },
  { value: "60", label: "60" },
  { value: "90", label: "90" },
  { value: "120", label: "120" },
  { value: "270", label: "270" }
];

const default_pardays = [
  { value: "5", label: "5" },
  { value: "10", label: "10" },
  { value: "15", label: "15" },
  { value: "30", label: "30" }
]


class ReportTables extends FlowComponent {

  state = {

    row_Datas:[],
    columndatas:[],
    rmwise_columndatas:[],
    rowdatas_cust_performance:[],
    rowdatas_outstanding:[],
    columndata_outstanding:[],
    original_row_datas:[],
    columndata_cust_performance:[],
    columndata_cust_performance2:[],
    country_code : "*",
    country : get('market').country,
    activeCurrency : get('market').currency_code,
    activeCurrencyVal : 1,
    active_acc_prvdr:null,
    gds_report_active_accPrvds:null,
    report_date:'',
    year_month:'',
    month:'',
    showlist: true,
    dropdownOpen: false,
    selectedItems: [],
    pardays :[],
    showaccList:true,
    default_month:null,
    setDate : moment().subtract('1', 'days').format("YYYY-MM-DD"),
    loader: false,
    no_data: false,

    active_tab: "par_report",
    rm_wise: false,
    selectedTab:true,
    calling_api:false,
    run_currency_details:false,
    hasRenderedOnce: false,
    isGettingReport: false, //
    rm_wise_export_csv_datas:[],

    portfolio_csv_data:[],
    outstanding_FAs_upto:moment().format("DD MMM YYYY"),
    global_view_access : ["it_admin","super_admin",'ops_anlyst'],
    lender : null  
}

  

  componentWillMount()
  {
    if(this.props.report_type=="portfolio_at_risk"){
      this.default_month()
      this.get_gds_report((this.state.active_tab== "rm_wise") ? "rm_wise" : null,"3")
      this.setState({ columndatas: this.columndatas_Portfolio()})
      this.set_year_month()

    }
    if(this.props.report_type=="customer_performance"){
      this. customerPerformance()
      this.setState(state=>state.columndata_cust_performance=this.columndatas_cust_performance())
      this.setState(state=>state.columndata_cust_performance2=this.columndatas_cust_performance2())
    }else if(this.props.report_type=="outstanding_advances"){
      this.outstanding_performance()
      this.setState(state=>state.columndata_outstanding=this.columndatas_outstanding())
    }
   
    this.get_currency_details()
  }

  get_currency_details(){
    const req = {"time_zone":get('market').time_zone}

    getCurrencyDetails(req)
    .then((response)=>{
        if(!response){return ""}
        if(response.status === "success"){        
            const currency_details = response.data
            this.setState({currencyDetails:currency_details})   
        }
    })
  }

  set_year_month(value){
    const dateStr = value;
    const dateObj = new Date(dateStr);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // add 1 to get month in 1-12 format, and pad with leading zero if necessary
    const formattedDate = year.toString() + month;
    this.setState(state => state.month = formattedDate)

  }


  handleDatepicker(value){
    value = moment(value).format("YYYY-MM-DD")
    this.setState(state => state.setDate = value)
  }

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  handleCheckboxClick = (e) => {
    const { selectedItems } = this.state;
    const { value } = e.target;

    if (selectedItems.includes(value)) {
      this.setState({
        selectedItems: selectedItems.filter(item => item !== value)
      });
    } else {
      this.setState({
        selectedItems: [...selectedItems, value]
      });
    }
  }


  get_gds_report=(rm_wise =null,a=null) =>{
    this.setState({loader:true,original_row_datas:[],rmwise_columndatas:[]},()=>{
    var req = {"report_type": 'get_portfolio_risk', "date": this.state.setDate, "time_zone": get('market').time_zone, "country_code": this.state.country_code, "acc_prvdr_code": this.state.lender ? null : this.state.gds_report_active_accPrvds }
    req.lender = this.state.lender
    if (rm_wise != null) { req["rm_wise_report"] = true }
     getReport(req)
      .then((response) => {
        if (!response) {
          this.setState({ no_data: true })
          return
        }
        if (response.status == "success") {
         
         this.setState({ rmwise_columndatas: this.rmwise_columndatas(), original_row_datas: response.data.report, report_date: response.data.report_date ,no_data: false,loader:false}, this.accprvds_handler)
 }
      })
    })
  }
  customerPerformance(){

    getReport({"report_type":'get_cust_performance',"time_zone":get('market').time_zone,"country_code":this.state.country_code})
      .then((response)=>{
        if(!response){return }
        if(response.status=="success")
        {
          this.setState({rowdatas_cust_performance:response.data.perf_list, original_row_datas:response.data.full_perf_list, report_date : response.data.report_date},this.accprvds_handler)
        }
      })
  }

  show_column(type){
   var a;
   var val= this.state.pardays.length >0 && this.state.pardays.filter((item,idx)=>{ return (item.value == type )})
   if(!val.length){
    a= false
   }else{
    a= true
   }
  return a;
  }



  columndatas_Portfolio() {
    const columns = [
      {
        Header : <div className='text-center'>SI No</div>,
        accessor: "account_id",
        maxWidth:100,

        show:true,
        Cell : cell =>{return <div className='text-center'>{cell.row.index+1}</div>}
      },
      {
        Header : "Account Provider",
        id: "acc_prvdr_code",
        maxWidth:150,

        accessor: "acc_prvdr_code",
        show: this.state.showlist && this.state.showaccList ? true : false,
        Cell : cell => {return <>   <Homeacc_prvdrLogoselector logotype={cell.row.original.acc_prvdr_code} /></>}    ,


      },
      {
        Header : <div className='text-center'>PAR Days</div>,
        id: "par_days",
        accessor: "par_days",
        maxWidth:180,

        show:true,
        Cell : cell => {return <><div className='text-center'>{cell.row.original.par_days}</div></>},
        // Footer:  this.state.showlist ? <></> :<>Grand Total</> 
      },
      {
        Header : "Portfolio quality",
        id: "country_code",
        accessor: "country_code",
        maxWidth:200,

        show: this.state.showlist ? true : false,
        Cell : cell => {return <>{cell.row.original.country_code}</>}  ,
        // Footer: "Grand Total"            

      },

      {

        Header : <div className='text-center'>Amount</div>,
        id: "par_loan_principal",
        maxWidth:200,
        width:150,
        show:true,
        accessor: "par_loan_principal",
        Cell : cell => {return <div className='text-right mr-5'>{this.amount_Usd_convertion(cell.row.original.par_loan_principal,"active_currencyConversion", 0, cell.row.original.country_code)}</div>}   ,
        // Footer: tot => {
        //   // Only calculate total visits if rows change
        //   const total = React.useMemo(
        //     () =>
        //     tot.rows.reduce((sum, row) => this.amount_Usd_convertion(row.values.par_loan_principal,"totalCalculation") + sum, 0),
        //     [tot]
        //   )

        //   return <div className='text-right mr-5'><b> {this.formatNumber(total) +" "+ this.state.activeCurrency}</b>  </div>
        // },   

      },
      {

        Header : <div className='text-center'>Percentage</div>,
        id: "percentage",
        maxWidth:150,
        width:150,
        accessor: "percentage",
        show:true,
        Cell : cell => {return <div className='text-right mr-5'>{(cell.row.original.percentage*100).toFixed(2)}%</div>}
      },
      {

        Header : <div className='text-center'>FAs List</div>,
        id: "fa_list",
        maxWidth:150,
        width:150,
        accessor: "fa_list",
        show:true,
        Cell : cell => {return <div className='text-center'><ViewParFas parday={cell.row.original.par_days} state_data={this.state} country_code={this.state.country_code} acc_prvdr_code={this.state.gds_report_active_accPrvds} date={this.state.setDate} lender={this.state.lender}/></div>}
      },


    ]
    return columns.filter((column) => column.show !== false);
  }

  rmwise_columndatas() {
    const columns = [
      {
        Header: <div className='text-center'>SI No</div>,
        accessor: "SI_No",
        maxWidth: 100,
        id: "SI_No",
        show: true,
        Cell: cell => { return <div className='text-center'>{cell.row.index + 1}</div> }
      },
      {
        Header: "Account Provider",
        id: "acc_prvdr_code",
        maxWidth: 150,

        accessor: "acc_prvdr_code",
        show: this.state.showlist && this.state.showaccList ? true : false,
        Cell: cell => { return <>   <Homeacc_prvdrLogoselector logotype={cell.row.original.acc_prvdr_code} /></> },


      },
      {
        Header: <div className='text-center'>RM Name </div>,
        id: "rm_name",
        accessor: "rm_name",
        maxWidth: 180,
        Cell: cell => { return <><div className='text-center'>{cell.row.original.rm_name}</div></> },
      },
      {
        Header: <div className='text-center'>PAR Days 5 </div>,
        id:this.state.selectedTab  ? "par_5_percentage": "par_5",
        accessor: row => row,
        maxWidth: 180,

        show:this.show_column("5"),
        Cell: cell => { return <><div className='text-center'>{ this.state.selectedTab  ? <>{(cell.row.original.par_5_percentage).toFixed(2)}%</> :this.amount_Usd_convertion(cell.row.original.par_5, "active_currencyConversion", 0)}</div></> },
      },
      {
        Header: <div className='text-center'>PAR Days 10</div>,
        id:this.state.selectedTab  ? "par_10_percentage": "par_10",
        accessor: row => row,
        maxWidth: 180,

        show:this.show_column("10"),
        Cell: cell => { return <><div className='text-center'>{this.state.selectedTab  ? <>{(cell.row.original.par_10_percentage).toFixed(2)}%</> :this.amount_Usd_convertion(cell.row.original.par_10, "active_currencyConversion", 0)}</div></> },
      },
      {
        Header: <div className='text-center'>PAR Days 15</div>,
        id: this.state.selectedTab  ? "par_15_percentage":"par_15",
        accessor: row => row,
        maxWidth: 180,

        show:this.show_column("15"),
        Cell: cell => { return <><div className='text-center'>{this.state.selectedTab  ? <>{(cell.row.original.par_15_percentage).toFixed(2)}%</> :this.amount_Usd_convertion(cell.row.original.par_15, "active_currencyConversion", 0)}</div></> },
      },
      {
        Header: <div className='text-center'>PAR Days 30</div>,
        id:this.state.selectedTab  ? "par_30_percentage": "par_30",
        accessor: row => row,
        maxWidth: 180,

        show:this.show_column("30"),
        Cell: cell => { return <><div className='text-center'>{this.state.selectedTab  ? <>{(cell.row.original.par_30_percentage).toFixed(2)}%</> :this.amount_Usd_convertion(cell.row.original.par_30, "active_currencyConversion", 0)}</div></> },
      },
      {
        Header: <div className='text-center'>PAR Days 60</div>,
        id: this.state.selectedTab  ? "par_60_percentage": "par_60",
        
        maxWidth: 180,
        accessor: row => row,
        show:this.show_column("60"),
        Cell: cell => { return <><div className='text-center'>{this.state.selectedTab  ? <>{(cell.value.par_60_percentage).toFixed(2)}%</> :this.amount_Usd_convertion(cell.value.par_60, "active_currencyConversion", 0)}</div></> },
      },
      {
        Header: <div className='text-center'>PAR Days 90</div>,
        id: this.state.selectedTab  ? "par_90_percentage":"par_90",
        accessor: row => row,
        maxWidth: 180,

        show:this.show_column("90"),
        Cell: cell => { return <><div className='text-center'>{this.state.selectedTab  ?<>{(cell.row.original.par_90_percentage).toFixed(2)}%</> :this.amount_Usd_convertion(cell.row.original.par_90, "active_currencyConversion", 0)}</div></> },
      },
      {
        Header: <div className='text-center'>PAR Days 120</div>,
        id:this.state.selectedTab  ? "par_120_percentage": "par_120",
        accessor: row => row,
        maxWidth: 180,

        show:this.show_column("120"),

        Cell: cell => { return <><div className='text-center'>{this.state.selectedTab  ? <>{(cell.row.original.par_120_percentage).toFixed(2)}%</> :this.amount_Usd_convertion(cell.row.original.par_120, "active_currencyConversion", 0)}</div></> },
      },
      {
        Header: <div className='text-center'>PAR Days 270</div>,
        id:this.state.selectedTab  ? "par_270_percentage": "par_270",
        accessor: row => row,
        maxWidth: 180,

        show:this.show_column("270"),
        Cell: cell => { return <><div className='text-center'>{this.state.selectedTab  ? <>{(cell.row.original.par_270_percentage).toFixed(2)}%</> :this.amount_Usd_convertion(cell.row.original.par_270, "active_currencyConversion", 0)}</div></> },
      },


      {
        Header: "Portfolio quality",
        id: "country_code",
        accessor: "country_code",
        maxWidth: 200,

        show: this.state.showlist ? true : false,
        Cell: cell => { return <p className='m-0 text-white text-center'>{cell.row.original.country_code}</p> },
        // Footer: "Grand Total"            

      },

      
      // {

      //   Header: <div className='text-center'>FAs List</div>,
      //   id: "fa_list",
      //   maxWidth: 150,
      //   width: 150,
      //   accessor: "fa_list",
      //   show: true,
      //   Cell: cell => { return <div className='text-center'><ViewParFas parday={cell.row.original.par_days} state_data={this.state} country_code={this.state.country_code} acc_prvdr_code={this.state.gds_report_active_accPrvds} date={this.state.setDate} /></div> }
      // },


    ]
    return columns.filter((column) => column.show !== false);
  }
  columndatas_outstanding () {
    return [
          {
            Header : <div className='text-center'>SI No</div>,
            accessor: "SI No",                
            Cell : cell =>{return <div className='text-center'>{cell.row.index+1}</div>}
        },
        {
            Header : "Partner ID",
            id: "partner_id",
            accessor: "partner_id",      
            Cell : cell => {return <>{cell.row.original.partner_id}</>}          
        },
        {
          Header : "Account Provider",
          id: "acc_prvdr_code",
          maxWidth:150,
          width:200, 
          accessor: "data_prvdr_code",  
          Cell : cell => {return <>  <Homeacc_prvdrLogoselector logotype={cell.row.original.data_prvdr_code} /></>}    ,
          Footer: "Grand Total"          
 
      },
        {
            Header : "Name",
            id: "biz_name",  
            accessor: "biz_name",  
            // maxWidth:200,  
            width:260,
            Cell : cell => {return <div>{cell.row.original.biz_name}</div>}            
   
        },
        {
            Header : "Mobile",
            id: "cust_mobile_num",
            accessor: "cust_mobile_num",  
            Cell : cell => {return <>{cell.row.original.cust_mobile_num}</>}              
   
        },
        {
            Header : "RM Name",
            id: "rm_name",
            accessor: "rm_name",
            width:200,
            Cell : cell => {return <>{cell.row.original.rm_name}</>}            
  
        },
        {
            Header : "Product",
            id: "product_name",
            accessor: "product_name",   
            Cell : cell => {return <>{cell.row.original.product_name}</>}      
    
        },
        {
        
            Header : "Disbursal date",
            id: "disbursal_date",
            accessor: "disbursal_date",     
            Cell : cell => {return <>{cell.row.original.disbursal_date ? moment(cell.row.original.disbursal_date).format('DD-MMM-YYYY') : 'N/A'}</>}      
        },
        {
        
          Header : "Due date",
          accessor: "due_date",     
          Cell : cell => {return <>{cell.row.original.due_date ? moment(cell.row.original.due_date).format('DD-MMM-YYYY') : 'N/A'}</>}      
        },
        {
          
          Header : "Overdue",
          // id: "loan_principal",
          accessor: "Overdue",     
          Cell : cell => {return <>{cell.row.original.overdue_days>0?"YES":"NO"}</>}      
      },
        {
            
          Header : "Overdue(days)",
          // id: "loan_principal",
          accessor: "overdue_days",     
          Cell : cell => {return <>{cell.row.original.overdue_days}</>}      
      }, 
      {
            
        Header : "Amount",
        // id: "loan_principal",
        accessor: "loan_principal",
        width: 80,     
        Cell : cell => {return <div className='text-right'>{this.amount_Usd_convertion(cell.row.original.loan_principal,"active_currencyConversion")}</div>}      
      },
      {
              
        Header : <div className='text-center'>Fee</div>,
        // id: "loan_principal",
        accessor: "flow_fee",    
        width:80, 
        Cell : cell => {return <div className='text-right'>{this.amount_Usd_convertion(cell.row.original.flow_fee,"active_currencyConversion")}</div>}      
      },
      {
              
        Header : <div className='text-center'>Total</div>,
     
        accessor: "Total",     
        Cell : cell => {return <div className='text-center'>{this.amount_Usd_convertion(cell.row.original.loan_principal + cell.row.original.flow_fee,"active_currencyConversion")}</div>}
      },
      {
              
        Header : "Paid amount",
        accessor: "paid_amount",    
        width: 100, 
        Cell : cell => {return <div className='text-right'>{this.amount_Usd_convertion(cell.row.original.paid_amount,"active_currencyConversion")}</div>}      
      },
      {
              
        Header : "OS amount",
        accessor: "os",    
        width: 100, 
        Cell : cell => {return <div className='text-right'>{this.amount_Usd_convertion(cell.row.original.os,"active_currencyConversion")}</div>}      
      },
       
       
    ]}
    
  columndatas_cust_performance () {
    return [
          {
            Header : "SI No",
            accessor: "account_id",  
            maxWidth:100,
            // width:25,            
            Cell : cell => {return <>{cell.row.index+1}</>}
          },{
              Header : "Gender",
              id: "gender",
              accessor: "gender",   
              maxWidth:200,    
              Cell : cell => {return <>{title_case(cell.row.original.gender)}</>}          
          },
          {
              Header : "Tot FAs disbursed",
              id: "total_FA",  
              accessor: "total_FA",   
              maxWidth:200, 
              Cell : cell => {return <>{cell.row.original.total_FA}</>}            
     
          },
          {
              Header : <div>{`Tot amt disbursed`} </div>,
              id: "total_Amt",
              maxWidth:200,
              accessor: "total_Amt",  
              Cell : cell => {return <>{this.amount_Usd_convertion(cell.row.original.total_Amt,"active_currencyConversion")}</>}              
     
          },
          // {
          //     Header : "Total fee(USD)",
          //     id: "total_Fee_usd",
          //     maxWidth:150,
          //     accessor: "total_Fee_usd",    
          //     Cell : cell => {return <>{cell.row.original.total_Fee_USD}</>}            
    
          // },
          {
              Header : "Assumed income of agent @1.5 a day",
              id: "assume_income",
              maxWidth:200,
              accessor: "assume_income",   
              Cell : cell => {return <>{cell.row.original.assume_income}</>}      
      
          },
          {
          
              Header : "Total fee",
              id: "total_Fee",
              maxWidth:200,
              accessor: "total_Fee",     
              Cell : cell => {return <>{this.amount_Usd_convertion(cell.row.original.total_Fee,"active_currencyConversion")}</>}      
          },
          // {
          
          //   Header :  "Total fee(USD)",
          //   accessor: "total_Fee_USD",  
          //   maxWidth:150,   
          //   Cell : cell => {return <>{cell.row.original.total_Fee_USD}</>}      
          // },
          {
          
            Header : "Flow fee paid as percentage of fee income",
            accessor: "fee_per_income",  
            maxWidth:200,   
            Cell : cell => {return <>{cell.row.original.fee_per_income}</>}      
          },
          {
              
            Header : "Per advance revenue",
            // id: "loan_principal",
            accessor: "per_adv_revenue",     
            maxWidth:200,
            Cell : cell => {return <>{this.amount_Usd_convertion(cell.row.original.per_adv_revenue,"active_currencyConversion")}</>}      
        }, 
        // {
              
        //   Header : "Average FA size",
        //   // id: "loan_principal",
        //   accessor: "avg_fa_size", 
        //   maxWidth:150,    
        //   Cell : cell => {return <>{cell.row.original.avg_fa_size}</>}      
        // },
        {
                
          Header : "Average FA size",
          // id: "loan_principal",
          accessor: "avg_fa_size", 
          maxWidth:170,    
          Cell : cell => {return <>{this.amount_Usd_convertion(cell.row.original.avg_fa_size,"active_currencyConversion")}</>}      
        },
       
      {
              
        Header : "Percentage of late repayments",
        maxWidth:150,
        accessor: "total_Late_FA_perc",     
        Cell : cell => {return <div className='text-center'>{(cell.row.original.total_Late_FA_perc*100).toFixed(2)}%</div>}      
      }
     
       
       
]}

  columndatas_cust_performance2 () {
    return [
          {
            Header : "SI No",
            accessor: "account_id",  
            maxWidth:60,
            // width:25,            
            Cell : cell =>  {return <>{cell.row.index+1}</>}
        },
        {
            Header : "Acc Prvdr",
            id: "acc_prvdr_code",
            accessor: "acc_prvdr_code",   
            maxWidth:90,    
            Cell : cell => {return <>  <Homeacc_prvdrLogoselector logotype={cell.row.original.acc_prvdr_code}/></>}          
        },
        {
            Header : "Ongoing FA",
            id: "ongoing_loan",  
            accessor: "ongoing_loan",   
            maxWidth:130, 
            Cell : cell => {return <>{cell.row.original.ongoing_loan}</>}            
   
        },
        {
            Header : "Customer",
            id: "biz_name",
            maxWidth:130,
            accessor: "biz_name",  
            Cell : cell => {return <>{cell.row.original.biz_name}</>}              
   
        },
        {
            Header : <div className='text-center'>Gender</div>,
            id: "gender",
            maxWidth:150,
            accessor: "gender",    
            Cell : cell => {return <div className='text-center'>{cell.row.original.gender}</div>}            
  
        },
        {
            Header : "Partner RM",
            id: "rel_mgr_name",
            maxWidth:120,
            accessor: "rel_mgr_name",   
            Cell : cell => {return <>{cell.row.original.rel_mgr_name}</>}      
    
        },
        {
          Header : "Tot FAs disbursed",
          // id: "biz_name",
          maxWidth:120,
          accessor: "total_FA",   
          Cell : cell => {return <div className='text-center'>{cell.row.original.total_FA}</div>}      
  
        },
        {
          Header : "Tot amt disbursed",
          id: "total_Amt",
          maxWidth:120,
          accessor: "total_Amt",   
          Cell : cell => {return <>{this.amount_Usd_convertion(cell.row.original.total_Amt,"active_currencyConversion")}</>}      
  
         },
        {
        
            Header : "Total fee",
            id: "loan_principal",
            maxWidth:120,
            accessor: "total_Fee",     
            Cell : cell => {return <>{this.amount_Usd_convertion(cell.row.original.total_Fee,"active_currencyConversion")}</>}      
        },
        {
        
          Header : "Assumed income of agent @1.5 a day",
          accessor: "assume_income",  
          maxWidth:150,   
          Cell : cell => {return <>{cell.row.original.assume_income}</>}      
        },
        {
          
          Header : "Flow fee paid as percentage of fee income",
          // id: "loan_principal",
          accessor: "fee_per_income", 
          maxWidth:150,    
          Cell : cell => {return <>{cell.row.original.fee_per_income}</>}      
      },
        {
            
          Header : "Per advance revenue",
          // id: "loan_principal",
          accessor: "per_adv_revenue",     
          maxWidth:150,
          Cell : cell => {return <>{this.amount_Usd_convertion(cell.row.original.per_adv_revenue,"active_currencyConversion")}</>}      
      }, 
      {
            
        Header : "Average FA size",
        // id: "loan_principal",
        accessor: "Amoavg_fa_sizeunt", 
        maxWidth:170,
        width:220,    
        Cell : cell => {return <>{this.amount_Usd_convertion(cell.row.original.avg_fa_size,"active_currencyConversion")}</>}      
      },
      {
              
        Header : "Average size duration",
        // id: "loan_principal",
        accessor: "avg_fa_duration", 
        maxWidth:150,    
        Cell : cell => {return <>{cell.row.original.avg_fa_duration}</>}      
      },
      {
              
        Header : "No of late Repayments ",
        // id: "loan_principal",
        accessor: "total_Late_FA", 
        maxWidth:150,    
        Cell : cell => {return <>{cell.row.original.total_Late_FA}</>}      
      },
      {
              
        Header : "Percentage of late repayments",
        maxWidth:150,
        accessor: "total_Late_FA_perc",     
        Cell : cell => {return <div>{(cell.row.original.total_Late_FA_perc*100).toFixed(2)}%</div>}      
      }
     
       
       
  ]}

  default_month() {
    const prevMonth = new Date();
    const pre_month = prevMonth.setMonth(prevMonth.getMonth() - 1)

    this.setState(state => state.default_month = pre_month)
  }

  componentDidMount() {
    this.setState({ hasRenderedOnce: true }, () => {
      this.setState({ initialRenderComplete: true });
    });
  }

  getExport_data (){
    var arr=[]
    const { rmwise_columndatas, dateRange} = this.state;
    const data = rmwise_columndatas;
    if(data.length >0){
    const data_keys =data.map((item,idx)=>{
      if((this.state.active_tab == "par_report") || ((this.state.active_tab == "rm_wise") &&((item.id!="SI_No") && 
      (item.id!="acc_prvdr_code") && (item.id!="country_code") &&  (item.id!="rm_name")))){
      arr.push(item.id)  
      }
    })

    this.setState({export_data:arr})
    return arr
    
  }

  }

 

  componentDidUpdate(prevProps, prevState) {

    if (
      this.state.initialRenderComplete &&
      ((prevState.gds_report_active_accPrvds !== this.state.gds_report_active_accPrvds) ||
      (prevState.country_code !== this.state.country_code) ||
      (prevProps.country_code !== this.props.country_code)) &&
      this.props.report_type === "portfolio_at_risk"
    ) {
      this.setState({active_tab: this.state.country_code=="*" ? "par_report" : this.state.active_tab},()=>{ this.get_gds_report(this.state.active_tab === "rm_wise" ? "rm_wise" : null, "2");    })
    }

    if(this.state.pardays && prevState.pardays ){

      if((this.state.pardays.length != prevState.pardays.length) ){

        this.setState({ rmwise_columndatas: this.rmwise_columndatas()},()=>{ 
          this.setState({rm_wise_export_csv_datas: this.convertExportCsvDatas(this.state.original_row_datas,this.state.activeCurrencyVal,this.state.activeCurrency, this.getExport_data() ),
          header_props:this.convert_header_props((this.state.active_tab=="rm_wise") ?this.rmwise_columndatas():this.columndatas_Portfolio(),this.state.activeCurrency, (this.state.active_tab=="rm_wise") ? this.getExport_data():["par_loan_principal"])})
        })

      }

    }
    if(prevState.lender != this.state.lender){
      this.get_gds_report((this.state.active_tab== "rm_wise") ? "rm_wise" : null)
    }

    if( (this.state.selectedTab !==prevState.selectedTab)){
    if(this.state.selectedTab){
      this.setState({ rmwise_columndatas: this.rmwise_columndatas()},()=>{ 
        this.setState({rm_wise_export_csv_datas: this.get_percentage_wise()})
      })
    }else{
      this.setState({ rmwise_columndatas: this.rmwise_columndatas()},()=>{ 
        this.setState({rm_wise_export_csv_datas: this.convertExportCsvDatas(this.state.original_row_datas,this.state.activeCurrencyVal,this.state.activeCurrency, this.getExport_data() ),
        header_props:this.convert_header_props((this.state.active_tab=="rm_wise") ?this.rmwise_columndatas():this.columndatas_Portfolio(),this.state.activeCurrency, (this.state.active_tab=="rm_wise") ? this.getExport_data():["par_loan_principal"])})
      })
    }

     

    }

  

    if (prevState.country_code != this.state.country_code) {
      if(this.props.report_type=="customer_performance"){
        this.customerPerformance();
      }
      if(this.props.report_type=="outstanding_advances"){
        this.outstanding_performance()
      }
    }
    if(prevState.activeCurrency !=this.state.activeCurrency){
      this.amount_Usd_convertion()
    }
    if(prevState.gds_report_active_accPrvds!=this.state.gds_report_active_accPrvds || prevState.pardays!=this.state.pardays){
      this.accprvds_handler(this.state.gds_report_active_accPrvds)

    }
    if(prevState.showlist != this.state.showlist || prevState.showaccList != this.state.showaccList || prevState.country_code != this.state.country_code || prevState.activeCurrencyVal != this.state.activeCurrencyVal){
      this.setState(state => state. columndatas= this. columndatas_Portfolio())     
    }

    if(((JSON.stringify(this.state.row_Datas) !== JSON.stringify(prevState.row_Datas))||((JSON.stringify(this.state.original_row_datas) !== JSON.stringify(prevState.original_row_datas)))) || (this.state.activeCurrency !== prevState.activeCurrency) ){
      if(this.state.active_tab=="rm_wise"){

        var export_data = this.getExport_data()
       this.setState({rm_wise_export_csv_datas: this.convertExportCsvDatas(this.state.original_row_datas,this.state.activeCurrencyVal,this.state.activeCurrency,export_data ),
       header_props:this.convert_header_props((this.state.active_tab=="rm_wise") ?this.rmwise_columndatas():this.columndatas_Portfolio(),this.state.activeCurrency, (this.state.active_tab=="rm_wise") ? this.getExport_data():["par_loan_principal"])})
 
      }else{
       this.setState({portfolio_csv_data: this.convertExportCsvDatas(this.state.row_Datas,this.state.activeCurrencyVal,this.state.activeCurrency,["par_loan_principal"]),
       header_props:this.convert_header_props((this.state.active_tab=="rm_wise") ?this.rmwise_columndatas():this.columndatas_Portfolio(),this.state.activeCurrency, (this.state.active_tab=="rm_wise") ? this.getExport_data():["par_loan_principal"])})
 
      }
 
     }

    
 
  }

  get_percentage_wise(){
    var csv_data_arr = [];
    for (let i = 0; i < this.state.original_row_datas.length; i++) {
      var csv_data_obj = { ... this.state.original_row_datas[i] }; 

      this.state.export_data.map((item,idx)=>{
          if (csv_data_obj.hasOwnProperty(item)) {
            var percentage_val = (item === "rm_name")? csv_data_obj[item] : ( csv_data_obj["percentage"]  * 100).toFixed(2) + "%";
            csv_data_obj[item] = percentage_val;
          }
    
        })
    
      csv_data_arr.push(csv_data_obj);
    }
    
  return csv_data_arr
  }

  formatNumber(n, decimal = 2) {
    var ranges = [
      { divider: 1e9 , suffix: 'B' },
      { divider: 1e6 , suffix: 'M' },
      { divider: 1e3 , suffix: 'K' }	
    ];

    for (var i = 0; i < ranges.length; i++) {
      if (n >= ranges[i].divider) {
        return (n / ranges[i].divider).toFixed(decimal) + " " + ranges[i].suffix;
      }
    }
    return Number(n).toFixed(0);

  }

  amount_Usd_convertion(a,type, decimal = 2, country_code = null){
    if(type=="totalCalculation"){

      if(!this.state.activeCurrencyVal){
      return a ;
      }
      if(this.state.activeCurrencyVal){
        
      return  a * Number(this.state.activeCurrencyVal);
      }
    }
    else if(country_code=='*' && this.props.report_type=="portfolio_at_risk"){
      let format_num = this.formatNumber(a, decimal)
      return (format_num+ " " + " "+this.state.activeCurrency)
    }
    else{
      if(!this.state.activeCurrencyVal){
        if(a>0){
          let format_num = this.formatNumber(a, decimal)
          return (format_num+ " " + " "+this.state.activeCurrency)
        }else{
          return this.formatNumber(a, decimal)
        }
     
      }
      else if(country_code=='*' && this.props.report_type=="portfolio_at_risk"){
        let format_num = this.formatNumber(a, decimal)
        return (format_num+ " " + " "+this.state.activeCurrency)
      }
      else{
       if(this.state.activeCurrencyVal){
         if(a>0){
          let value = a * this.state.activeCurrencyVal
          let format_num = this.formatNumber(value, decimal)
          return  (format_num+ " " + " "+this.state.activeCurrency);
         }
         else{
           return a;
         }
   
      
       }
      }
  }
  }

  set_attributes(array){
    
    if(this.state.country_code != array.country_code || this.state.activeCurrency != array.activeCurrency|| this.state.gds_report_active_accPrvds!=array.gds_report_active_accPrvds || this.state.activeCurrencyVal != array.activeCurrencyVal || this.state.lender != array.lender){
        this.setState(array)
        this.setState({run_currency_details:true})
        
    }
}

  set_pardays(array){
    if(this.state.pardays != array.pardays){
      this.setState(array)
    }
  }

  accprvds_handler(acc_prvdr){
    if(acc_prvdr!=null){
      if(this.props.report_type=="outstanding_advances"){
        const data= this.state.original_row_datas.filter(each=>each.data_prvdr_code == acc_prvdr)
        this.setState({row_Datas:data})
      }
      else if(this.props.report_type=="portfolio_at_risk"){
          if(this.state.pardays){
            const days = this.state.pardays.map((val)=>val.value)
            const data= this.state.original_row_datas.filter(each=>days.includes(`${each.par_days}`))
            this.setState({row_Datas:data})
          }
      }
      else{
      const data= this.state.original_row_datas.filter(each=>each.acc_prvdr_code == acc_prvdr)
      this.setState({row_Datas:data})
      }
    }
    else{
      if(this.props.report_type == "portfolio_at_risk"){
        if( this.state.pardays){
          const days = this.state.pardays.map((val)=>val.value)
          const data= this.state.original_row_datas.filter(each=>days.includes(`${each.par_days}`))
          this.setState({row_Datas:data})

        }
        
      }else{
        this.setState({row_Datas:this.state.original_row_datas})
      }

    }
  }

outstanding_performance(){
  this.setState({loader:true})
  getReport({"report_type":'get_outstanding',"time_zone":get('market').time_zone,"country_code":(!this.state.global_view_access.includes(get('role_codes')))?get('market').country_code:this.state.country_code,date:this.state.outstanding_FAs_upto})
  .then((response)=>{
    if(!response){}
    if(response.status=="success")
    { this.setState({loader:false})
      this.setState({original_row_datas:response.data.report, report_date : response.data.report_date},this.accprvds_handler )
   }
  })
}
  checkUser = (val) =>{
    if(this.props.report_type == "outstanding_advances") {
      if(this.state.global_view_access.includes(get('role_codes'))){
        return true
      }
      else return false
    }
    else return val
  }
getListTitle = () => {
  switch (this.props.report_type){
    case 'portfolio_at_risk':
      return "Historical PAR Report"
    case 'customer_performance':
      return "Customer Performance"
    case 'outstanding_advances':
      return "Outstanding FAs"
 
  }
}


  toggleHandler(type) {
    this.setState({ active_tab: type, original_row_datas:[],call_api:false },()=>{this.get_gds_report((type=="rm_wise") ?"rm_wise":null,'hii')})
    
    
  }

  percentageCalculation(value){
    if(this.state.selectedTab !== value){
      this.setState({selectedTab: value})
      
  }  }
  render() {
   const is_loading =  (this.state.loader || this.state.calling_api || (!this.state.run_currency_details))
    return (
      <div>
        {this.props.report_type &&
      <h2 className='text-white' style={{paddingLeft:'10px'}}>{this.getListTitle()}</h2>

        }

        {this.props.report_type && this.props.report_type == "portfolio_at_risk" &&
          <label className={'ml-3'}>[ Note: For monthly report , select the last date of the month . ]</label>

        }

        {this.state.report_date && this.props.report_type != "portfolio_at_risk" && this.props.report_type != "outstanding_advances" &&
          <p className='text-white no-margin' style={{padding:'10px 0px 0px 10px', fontSize : "14px"}}>Report Date : {moment(this.state.report_date, 'DD-MM-YYYY').format("DD MMM YYYY")}  </p>
        }



        {
          this.props.report_type && (this.props.report_type == "portfolio_at_risk")?
          
          <InputSelectionHeader set_attr = {(res)=>this.set_attributes(res)}  incl_prvdr={true} show_global={true} LenderCodes={this.state.country_code != '*' && get('role_codes')!=="investor" ? true : false} ></InputSelectionHeader>
          
      
          :
          <InputSelectionHeader set_attr = {(res)=>this.set_attributes(res)}  incl_prvdr={true} show_global={this.checkUser(false)} showCountry={this.checkUser(true)}></InputSelectionHeader>
        }

        {
          this.props.report_type && this.props.report_type == "portfolio_at_risk" &&
          <div className='mt-3'>
          <MultiSelecter pardays={pardays} default_pardays = {default_pardays} chart_view = {false} isMulti={true} set_attr = {(res)=>this.set_pardays(res)}></MultiSelecter>
          </div>
        }

        {this.props.report_type && this.props.report_type == "portfolio_at_risk" &&
          <div style={{width : "600px"}} className='row mt-4 ml-3'>
            <div className="col-2 m-0 p-0 mt-3">
              <span className='text-white'>As on Date</span>
            </div>
            <div className='col-6 m-0 p-0'>
               <DatePicker defaultValue = {moment(this.state.setDate, "YYYY-MM-DD")} closeOnSelect={true} onChange={(value) => { this.handleDatepicker(value)}} dateFormat="DD MMM YYYY" timeFormat={false} required="required" isValidDate={(current) => {
                return current.isBefore(moment().startOf('day'));
              }}/>

            </div>
            <div className='col-3 m-0 p-0'>
              <button type="button" class="btn btn-info align-items-center ml-5" onClick={() => {this.get_gds_report((this.state.active_tab== "rm_wise") ? "rm_wise" : null,"3")}} style={{ margin: '10px 18px' }}>Submit</button>

            </div>
          </div>}




        {this.props.report_type=="portfolio_at_risk" &&
          <div className='par_report'>
            <h2 className="text-center text-white"> { this.getListTitle()}</h2>
          {(this.state.country_code != "*") && check_priv("report","rm_wise_par_report") &&
            <div className='d-flex align-items-center justify-content-center row mt-4'>
              <div className={`d-flex align-items-center col-lg-3 col-md-12  px-0 py-0 past_churn_filter ${is_loading?"disable_div":""}`} style={{ background: "#4a4d57" }} >
                <p className={` my-0 p-2 py-3 col-6 text-center pointer  ${(this.state.active_tab == "par_report") ? "active_past_churn_tab" : ''}`} style={{ color: `${(this.state.active_tab == "par_report") ? "" : "white"}` }} onClick={() => { this.toggleHandler("par_report") }}>Overall</p>
                <p className={`  my-0 p-2 py-3 col-6 text-center pointer ${(this.state.active_tab == "rm_wise") ? "active_past_churn_tab" : ''}`} style={{ color: `${(this.state.active_tab == "rm_wise") ? "" : "white"}` }} onClick={() => { this.toggleHandler("rm_wise");  }}>By RM</p>

              </div>

            </div>
            }

            {(this.state.active_tab=="rm_wise") && 
            <div className='d-flex align-items-center justify-content-end'>
            <div className="btn-group btn-group-md" style={{ margin: 0, padding: 0 }}>
                  <Toggle title="Show figures as"  width="100" leftLabel= "Amount" rightLabel="Percentage" fontSize="15"  val={this.state.selectedTab} aligntext="left" set_tog_val={(res) => this.percentageCalculation(res)} />
               </div>
            </div>
            }
            {( this.state.loader || this.state.calling_api || (!this.state.run_currency_details)) ? <>
              <p className='text-light mt-5 text-center' >Loading...</p>
              <div className="product_takeup_loader "></div></>
             : this.state.no_data ?
                <p className='text-light mt-5 text-center' >No Data Found . </p>
                :
                <div className='card-body'>
                  {this.state.original_row_datas.length>0 ?
                  <>
                  {(this.state.active_tab == "rm_wise"&&this.state.rmwise_columndatas ) ?
                  
                    <BasicReactTable className='border' row_data={this.state.original_row_datas} export_csv_datas={this.state.rm_wise_export_csv_datas} column_data={this.state.rmwise_columndatas} csv_file={true} FName="Portfolio_at_risk_rm_wise" default_page_size={10} pagination={this.state.original_row_datas.length > 9 ? true : false} global_search={true} header_props={ ((this.state.active_tab=="rm_wise") && !this.state.selectedTab) ?  this.state.header_props?this.state.header_props:false :false} />
                    :
                    <BasicReactTable className='border' row_data={this.state.row_Datas} export_csv_datas={this.state.portfolio_csv_data} column_data={this.state.columndatas} csv_file={true} FName="Portfolio_at_risk" default_page_size={10} loader={this.state.rowdatas ? false : true} footer={true} pagination={this.state.row_Datas.length > 9 ? true : false} global_search={false} header_props={this.state.header_props?this.state.header_props:false} />

                  }
                  </>:<p className='text-light mt-5 text-center' >No Data Found . </p>
                }
                </div>
            }

          </div>
        }

      {this.props.report_type=="customer_performance" &&
       <div>
         <h2 className="text-center text-white">{this.getListTitle()}</h2>
         <div className='card-body'>
               <BasicReactTable row_data={this.state.gds_report_active_accPrvds!=null?this.state.row_Datas:this.state.original_row_datas} column_data={this.state.columndata_cust_performance2} csv_file={true} FName="Customer_Performance" global_search={true} pagination={this.state.gds_report_active_accPrvds!=null && this.state.row_Datas.length > 9 ? true :this.state.original_row_datas && this.state.original_row_datas.length > 9 ? true : false }   excess_time_action={false} default_page_size={5} loader={this.state.rowdatas ? false : true}/> 
                <div style={{marginTop:'30px'}}>
                <BasicReactTable row_data={this.state.rowdatas_cust_performance}  column_data={this.state.columndata_cust_performance} csv_file={true} FName="Customer_Performance" global_search={true} pagination={false} excess_time_action={false} default_page_size={5} loader={this.state.rowdatas ? false : true}/> 

                </div>
          </div>
       </div>
       } 
       {this.props.report_type=="outstanding_advances" &&
        <div>
         <div className=' m-2 row col-md-4 align-items-end'>
           <div className='col-md-8 p-0'>
           <label className='col-md-10 p-0' >As on</label>
               <label className='col-md-1 p-0 product_textr' htmlFor='outstanding_FAs_upto'><FcCalendar size={30}/></label>
               <DatePicker className='col-md-11 pr-1 p-0' inputProps={{ id: 'outstanding_FAs_upto', name: 'outstanding_FAs_upto',autoComplete:'off' }} defaultValue={this.state.outstanding_FAs_upto} closeOnSelect={true}  onChange={(e) => this.setState({ outstanding_FAs_upto: moment(e).format("DD MMM YYYY") })}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
           </div>
           <div className='col-md-3'>
           <button className='btn btn-md product_takeup_submit' onClick={()=>this.outstanding_performance()}>Search</button>
           </div>
               </div>
              
           {this.state.loader ? <>
           <p className='text-light mt-5 text-center' >Loading...</p>
           <div className="product_takeup_loader "></div></>
           :this.state.no_data ? 
           <p className='text-light mt-5 text-center' >No Data Found . </p> 
           :
          <div className='card-body'>
          <BasicReactTable row_data={this.state.row_Datas} column_data={this.state.columndata_outstanding} csv_file={true} FName="Outstanding_Advances" global_search={true} pagination={this.state.row_Datas && this.state.row_Datas.length > 9 ? true : false}  excess_time_action={false} default_page_size={10} loader={this.state.rowdatas ? false : true}/> 
 
           </div>}
        </div>
         }
       </div>
    )

  }}




export default ReportTables;