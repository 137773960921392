import React from 'react';
import { createBrowserRouter, useNavigate } from 'react-router-dom';
import admin_routes from '../Router/Admin_routes';
import user_routes from './User_routes';
import LoginLayout from './Layouts/LoginLayout';
import HomeLayOut from './Layouts/HomeLayout';
import LogoutLayout from './Layouts/LogoutLayout';
import AdminHome from '../UI/home/AdminHome';
import FoundersHome from '../UI/reports/container/founders_home';
import AdminMenu from '../UI/home/AdminMenu';
import Softphone from '../UI/cs_management/container/cs_soft_phone';

const Router = createBrowserRouter([
    
        {
                path:'/login',
                element:<LoginLayout/>
        },
        {
                path:'/logout',
                element:<LogoutLayout/>
        },
        {
                path: '/',
                element:<HomeLayOut/>,
                children:[
                        {
                           path:'/',
                           element:<AdminHome/> 
                        },
                        ...admin_routes,
                        ...user_routes
                ]
        },
        // {
        //         path : '/cs_managements/soft_phone',
        //         element : <Softphone/>
        // }

       

    
])

export default Router;