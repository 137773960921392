import React, { useRef } from 'react';
import { BiMenu, BiSolidMessageSquareDetail } from 'react-icons/bi';
import { IoCall, IoClose } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { resetState, setValuesIntoRedux, toggleMenu } from '../../../Redux/Slices/CommonSlice';
import { MdPhoneMissed } from 'react-icons/md';
import Softphone from './cs_soft_phone';
import CommonCallLogModal from '../components/CommonCallLogModal';
import { createClient, missedCallsList } from '../../../actions/cs_management_actions';
import { useState } from 'react';
import { useEffect } from 'react';
import Draggable from 'react-draggable';
import withRouter from '../../../Router/Withrouter';
import Router from '../../../Router/Router';
import { useLocation } from 'react-router-dom';
import { check_priv, get, set } from '../../../helpers/storage_helper';
import { store } from '../../../Redux/Store';
import { v4 as uuidv4 } from 'uuid';
import { show_softphone_icon } from '../../../helpers/common_helper';
import FreshChat from '../../utility/Component/FreshChat';


const CSSoftPhoneContainer = () => {
    const location = useLocation();

    const softPhone = useSelector((state) => state.cs_soft_phone)

    const dispatch = useDispatch()
    const [show_missed_calls, setshow_missed_calls] = useState(false)
    const cs_container_tab_id = useRef(uuidv4())

    const channel = new BroadcastChannel('cs_soft_phone')
    useEffect(() => {
        CallsList()
        dispatch(setValuesIntoRedux({path_name:window.location.pathname}))
        if(!get('at_token')){
            createAitClient()

        }
        if(location.pathname){
            dispatch(setValuesIntoRedux({showMenus:false,show_nums:false,missed_calls_view:false}))
            channel.postMessage({message:"save_cust_values",data:{showMenus:false,show_nums:false,missed_calls_view:false}})

        }
  
    }, [])

    useEffect(() => {
        const handleBeforeUnload = (event) => {

            if (get('cs_container_tab_id') && sessionStorage.getItem('cs_container_tab_id') && (get('cs_container_tab_id') == sessionStorage.getItem('cs_container_tab_id')) ) {
                    localStorage.removeItem('cs_container_tab_id')
                    sessionStorage.removeItem('cs_container_tab_id')
            }

        };


        window.addEventListener('beforeunload', handleBeforeUnload);


        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    

    useEffect(()=>{


        if(!get('cs_container_tab_id')){
            set('cs_container_tab_id', cs_container_tab_id.current)
        }

        if(!sessionStorage.getItem('client_tab_id')){
            sessionStorage.setItem('cs_container_tab_id', cs_container_tab_id.current)
        }        
        channel.onmessage = event => {

           let cs_data =  store.getState().cs_soft_phone;
            let message = event.data.message
            if (message == "show_incomingCall" || message == "callAccepted" || message =="outgoing_nextcall_false" || message =="outgoing_nextcall_true" || message == "CallHangup" || message == "submit_call_log" || message == "incomingCall_accepted" ||message == "incomingCall_hangup" || message == "end_call" || message == "CallLogModalClose" || message =="set_outgoing_call_details"  || message =="is_rm_call" || message == "pending_call_confirmation" || message =="save_cust_values") {
                dispatch(setValuesIntoRedux(event.data.data))
            }

            if(message == "new_tab"){
             
                if(sessionStorage.getItem('cs_container_tab_id') == get('cs_container_tab_id')){
                    channel.postMessage({message:"new_tab_opened",data:{state_data:cs_data }})

                }
            }

            if(message == "new_tab_opened"){
               
                dispatch(setValuesIntoRedux(event.data.data.state_data))   
            }

            if(message == 'client_destroyed'){
                window.location.reload()
                if(cs_data.callStatus != '' || cs_data.isIncomingCall){
                    dispatch(resetState())
                }
            }
        }

        channel.postMessage({message:"new_tab"})



        
        return ()=>{
            channel.close()
        }
        
    },[])


    const createAitClient = async () => {
        try {
            const config_data = await getWebrctConfigs();
            if (config_data.status === "success") {
                const response = await createClient(config_data.data);
                localStorage.setItem('at_token', JSON.stringify(response.token))
                
            } else {
                alert(config_data.message)
            }
            
        } catch (error) {
            console.error(error);
        }
    }

    const handleToggleMenu = (type = null) => {
        dispatch(toggleMenu(type));
        dispatch(setValuesIntoRedux({show_nums:true}))
    };

    const showMissedCalls = () => {
        var payload = { missed_calls_view: !show_missed_calls
        }
        dispatch(setValuesIntoRedux(payload))
        CallsList()
    }


    const CallsList = () => {
        missedCallsList()
            .then((response) => {
                if (!response) { return }
                if (response.status == "success") {
                    var payload = { missed_calls_history: response.data }
                    dispatch(setValuesIntoRedux(payload))
                }
            })
    }
    const close_modal = () => {
        dispatch(setValuesIntoRedux({ CallLogModal: !softPhone.CallLogModal }))
    }

    const show_icon = show_softphone_icon(location.pathname)

    const missed_call_list = (softPhone.missed_calls_history && softPhone.missed_calls_history.missed_calls.length > 0) ? softPhone.missed_calls_history.missed_calls.length : 0
    
    let call_log_modal = (softPhone.screen_from == "churn_management" || softPhone.screen_from == "delinquency" || location.pathname == "/pending_rm_visits" )  ? ( !softPhone.logData.next_call ? true:false) :(softPhone.callStatus == "bridged"  || softPhone.ModalIsOpen)

  

    return (
        <>
       
         { location.pathname !=="/cs_managements/soft_phone" &&
        <div className=''>
            {!softPhone.showMenus && (missed_call_list) &&
                <div className={`d-flex align-items-center missed_call_icon my-5 over_view_z_index  `} onClick={() => { showMissedCalls() }}>
                    <div className='soft_phone zoomIn_animation  pointer rel_position align-items-center '  >
                        <div className='missed_call_count '>
                            <p className={`m-0 text-danger text-center sizem  ${(missed_call_list > 9) ? "padding_up" : "less_padding"}`}> <b>{softPhone.missed_calls_history ? softPhone.missed_calls_history.missed_calls.length : " 0"}</b></p>
                        </div>
                        <div className='miss_call_icon'>
                            <MdPhoneMissed color='#ff0000' size={27} />
                        </div>
                    </div>
                </div>
            }

            {softPhone.showMenus &&
                <div className='menuslist bottom_divs freshchat_z_index '>
                { show_icon &&
                    <div className='d-flex  align-items-center my-5'>
                        <div className='menu_sub_label d-flex align-items-center '>
                            <p className='my-0  ml-3 soft_phone_text_clr'>Soft Phone</p>
                        </div>
                        <div className='soft_phone zoomIn_animation  pointer d-flex justify-content-center align-items-center ' onClick={() => { handleToggleMenu("show_menus") }} >
                            <IoCall color='#231184' size={25} />
                        </div>
                    </div>
                }

                {get('role_codes') && check_priv('user','chat_support') &&
                    <div className='d-flex align-items-center my-2'>
                        <div className={`menu_sub_label fresh_chat_label d-flex align-items-center ${show_icon ? "" :"chat_icon_margin_right"}`}>
                            <p className='my-0  ml-3 soft_phone_text_clr'>Chat with Tech Support</p>
                        </div>

                        <div className='soft_phone zoomIn_animation bottom_divs d-flex p-0 justify-content-center align-items-center pointer freshchat_bg '  >
                            {/* <BiSolidMessageSquareDetail color='#231184' size={29} /> */}
                          {/* {get('role_codes') && check_priv('user','chat_support') &&  */}

                          <FreshChat/>
                          {/* }  */}

                        </div>
                    </div>
                }


                </div>
            }



           
            <>

            {((softPhone.isIncomingCall || softPhone.outgoingCall)) ? (
                <div>
                <Draggable >
                    <div  className="genric_draggable_softphone  zoomIn_animation" >
                        <Softphone  /> 
                        
                    </div>
                </Draggable>
                </div>
            ) :
                <>
                { (softPhone.missed_calls_view && ((softPhone.screen_from != "churn_management" || softPhone.screen_from != "delinquency" && softPhone.screen_from != "pending_w_cust" ) && (!softPhone.isIncomingCall))) &&
                        <div className='Call_log_section zoomIn_animation over_view_z_index'>
                            <Softphone />
                        </div>
                    }
                </>
            }
            </>
            

            <div className=' zoomIn_animation fresh_chart_div bottom_divs over_view_z_index pointer d-flex justify-content-center align-items-center my-2' onClick={() => handleToggleMenu()}>
                {softPhone.showMenus ?
                    <IoClose color='white' size={25} /> :
                    <BiMenu color='white' size={25} />
                }
            </div> 
                

            {((softPhone.CallLogModal && (softPhone.call_log_details || ((softPhone.isIncomingCall ||  softPhone.incoming_call_details)) || ( softPhone.outgoingCall && (softPhone.callStatus == "bridged")) ) ) && call_log_modal)  && 
                <div>
                    <CommonCallLogModal cust_id={(softPhone.call_log_details && softPhone.call_log_details.cust_id) ? softPhone.call_log_details.cust_id : softPhone.cust_id ? softPhone.cust_id : (softPhone.incoming_call_details || softPhone.incomingcall_num) ?  "incoming_call" :softPhone.cust_id }
                     call_log={softPhone.call_log_details && softPhone.call_log_details.call_log_id ? softPhone.call_log_details : "outgoing"} close_modal={() => close_modal()} />
                </div>
            }
        </div>
         }
        </>
    );
};

export default  CSSoftPhoneContainer;