import React from "react"
import { Modal,Button } from "react-bootstrap"
import { useState } from "react"
import DatePicker from 'react-datetime';
import moment from "moment";
import { disburseReport_get } from "../../../helpers/fetch_helper";
import { FcCdLogo } from "react-icons/fc";
import { Accordion } from "react-bootstrap";

export default function DisbursalReport () {
    const [modalShowbySelect, setmodalShowbySelect] = useState(false)
    const [modalShowbyYear,setmodalShowbyYear] = useState (false)
    const [selectedYear,setSelectedyear] = useState("Select the year")
    const [date_range, setdate_range] = useState({
        "start_date": null,
        "end_date":null
    })       
    const api = process.env.REACT_APP_ADMIN_API_ROOT

    const clickHandler = (e) => {
        if(e === "openModalbySelect"){
            setmodalShowbySelect(true)
        }else if(e === "openModalbyYear"){
            setmodalShowbyYear(true)
        }else{
            setmodalShowbyYear(false)
            setmodalShowbySelect(false)
        }
    }
    const submitHandler = async () => {
        setmodalShowbySelect(false)        
        const data = disburseReport_get (api+'/report/disbursal_time_report',{date_range:date_range})

    }
    const onChangehandler = (e) => {
        setSelectedyear(e)
    }    

    const onChangeHandler = (date,key) => {                
        const value = moment(date).format("YYYY-MM-DD")                             
        setdate_range({...date_range,
            [key] : value
        })        
    }    

    return(
        <div className="container containerTopmargin max-width no-padding">
            <div class="btn-group" role="group" aria-label="Basic mixed styles example">
                <button type="button" class="btn btn-danger">Today</button>
                <button type="button" class="btn btn-warning">Yesterday</button>
                <button type="button" class="btn btn-success">By Weeks</button>
                <button type="button" class="btn btn-info">By Months</button>                
                <button type="button" class="btn btn-default" onClick={()=>clickHandler("openModalbyYear")}>By Year</button>                 
                <button type="button" class="btn btn-primary" onClick={()=>clickHandler("openModalbySelect")}>Select From</button>                
            </div>            
            <div className="table-responsive">
                {/* <ReactTable NoDataComponent={NullComponent} showPaginationBottom={false} minRows = {1}  resizable={false} /> */}
            </div>
            <Modal show={modalShowbySelect} onHide={()=>clickHandler("closeModal")}>
                <Modal.Header closeButton>
                    <Modal.Title><h3>Select the date range</h3></Modal.Title>
                </Modal.Header>              
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-6">
                                <label className="no-margin">From</label>
                                <DatePicker closeOnSelect={true} dateFormat="DD MMM YYYY"  timeFormat={false} required="required" onChange={(value) => onChangeHandler(value,"start_date")} />
                            </div>
                            <div className="col-6">
                                <label className="no-margin">To</label>
                                <DatePicker closeOnSelect={true} dateFormat="DD MMM YYYY"  timeFormat={false} required="required" onChange={(value) => onChangeHandler(value,"end_date")} />
                            </div>                                                        
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={()=>submitHandler()}>Search</Button>
                    <Button style={{marginLeft:10}} onClick={()=>clickHandler("closeModal")} variant="secondary">Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalShowbyYear} onHide={()=>clickHandler("closeModal")}>
                <Modal.Header closeButton>
                    <Modal.Title><h3>Select the year</h3></Modal.Title>
                </Modal.Header>              
                <Modal.Body>
                    <div className="container">
                        <div className="row justify-content-around">
                            <div className="col-6">
                                <select id="cs_model_code" className="form-control" required="required" onChange={(e)=>onChangehandler(e.target.value)} value={selectedYear}>                                                                        
                                    <option value="2020">2020</option>
                                    <option value="2021">2021</option>
                                    <option value="2022">2022</option>                                                        
                                </select>
                            </div>                                                                                    
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={()=>submitHandler()}>Search</Button>
                    <Button style={{marginLeft:10}} onClick={()=>clickHandler("closeModal")} variant="secondary">Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}