import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import '../../../styles/flow.css';
import {listScoreFactors} from '../../../actions/master_data_actions';
import $ from 'jquery';
import {dd_value} from '../../../actions/common_actions';
import { create_column } from '../../../helpers/common_helper';
import { BasicReactTable } from '../../../helpers/react_table';


class ScoreFactors extends FlowComponent {

	state = {
		ScoreFactorsList : null
	}

	column_datas(can_edit){
  

		const  column = [
		  {header:"Score Factor Type",id:"data_value"},
		  {header:"Score Factor Group",id:"parent_data_code" },
		 
		]
		  
		  let data =[... create_column(column)]
		  return data;
	  
	  
		}

	componentWillMount(){

		const request = {"data_key": "csf_type", "country_code": this.country_code}
		listScoreFactors(this.req(request))
        .then((response) => {
          if(!response){return };
		  this.setState({column_data:this.column_datas(),ScoreFactorsList:response.data})

        //   const ScoreFactorsList = response.data.map((score_factor,i)=>

        //       <tr key={i}>
        //         <td> {score_factor.data_value}</td>              
        //         <td> {score_factor.parent_data_code == null ? "No Parent" : dd_value(score_factor.parent_data_code)}</td>        
        //       </tr>
        //   );
          
        //   this.setState({ScoreFactorsList: ScoreFactorsList});
	    //    $('#table').dataTable({
	    //    	"bAutoWidth": true
	    //    	 });
          
           });
	}

	render(){
		return(
		<div className="container table-responsive">
		<br/>
			<div className="row">
				<h5><b>Score Factors</b></h5>
			</div>
			<div className='show_overflow'>
			{this.state.ScoreFactorsList  && this.state.ScoreFactorsList.length>0 ?
			 <BasicReactTable  row_data={this.state.ScoreFactorsList} column_data={this.state.column_data} default_page_size = {10} pagination={this.state.ScoreFactorsList.length > 9 ? true : false} csv_file={true}  global_search={true} export_csv_datas={this.state.ScoreFactorsList}/>
			 :
			 <p className='text-white m-0 text-center'>No Data</p>
			}
			</div>
			{/* <table id ="table" className="table">
				<thead className={"text-info"}><tr>
				<th>Score Factor Type</th>				
				<th>Score Factor Group</th>
				</tr></thead>
				<tbody> 
				{this.state.ScoreFactorsList}
				</tbody>
            </table>  */}
        </div>
		);
	}
}

export default ScoreFactors;