import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import {Button } from 'react-bootstrap';
import DatePicker from 'react-datetime';
import '../../../styles/flow.css';
import $ from 'jquery';
import { getValueFromLangJson } from '../../../helpers/common_helper';

class DateRange extends FlowComponent {
	state = {
    	comp_name: "date_range"
  	}

	render(){
		return(
			<div className="row date-range">				
				<div className="col-md-4">
					<span>{getValueFromLangJson("start_date")}</span> <span className='text-danger'>*</span>
					<DatePicker closeOnSelect={true} onChange={(value) => {this.handleDateChange("start_date", value)}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
				</div>  				
				<div className="col-md-4">
					<span>{getValueFromLangJson('end_date')}</span> <span className='text-danger'>*</span>
					<DatePicker closeOnSelect={true} onChange={(value) => {this.handleDateChange("end_date", value)}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
				</div>
				<div className="col-md-2" style={{display:'flex',alignItems:'center'}}>
					<input type="button" className="btn btn-primary" disabled = {this.props.disable_btn} onClick={this.props.onSubmitClick} value={getValueFromLangJson('submit')}/>
				</div>
			</div>
      )};


}

export default DateRange;