import React,{useState, useEffect, useCallback} from 'react'
import { GoogleMap, useLoadScript, Marker ,Circle,MarkerClusterer, InfoWindow, Polyline} from '@react-google-maps/api';
import Geocode from 'react-geocode';
import { auditKycVerification } from '../actions/lead_actions';
import {get, Config} from '../helpers/storage_helper';
import m_rm from './../../src/m_rm.png';
import f_rm from './../../src/f_rm.png';
import f_rm_in_cust_loc from './../../src/f_rm_in_cust_loc.png';
import m_rm_in_cust_loc from './../../src/m_rm_in_cust_loc.png';
import cust_location from './../../src/customer.png';
import punch_in from './../../src/punch_in.png';
import punch_out from './../../src/punch_out.png';


const containerstyle = {
    width: "100%",
    height:'80vh'
}


export default function GoogleMapSetup({ coordinates, height,address,clusterer_view,location_center,cust_coordinates,current_coordinates,color}) {



  const auditKyc_container_height_adjust = {
    height: height,
  };


const mapStyles = {
    width: '100%',
    height: '40vh'
  }
  const [selectedCustomer, setselectedCustomer] = useState(null);
  const [positioningVal, setPositioningVal] = useState({
    lat: 8.088306,
    lng: 77.538452 ,
  });

  const [newpositioningVal, setnewpositioningVal] = useState({
    lat:8.088306,
    lng:77.538452
})
const containerStyle = {
    width: '100%',
    height: '80vh',
  };

  const [owner_address_coordinates, setowner_address_coordinates] = useState({
    lat: null,
    lng: null,
  });

  const calculateCoordinates = (latitude, longitude) => {
    const paths = [];
    const radius = 500; // in meters
    const numPoints = 32;
    const center = { lat: latitude, lng: longitude };
    for (let i = 0; i < numPoints; i++) {
      const angle = (Math.PI / 16) * i;
      const offsetX = radius * Math.cos(angle);
      const offsetY = radius * Math.sin(angle);
      const point = {
        lat: center.lat + offsetY / 111319,
        lng: center.lng + offsetX / (111319 * Math.cos(center.lat)),
      };
      paths.push(point);
    }
    setowner_address_coordinates(paths);
  };


  useEffect(() => {
    if(coordinates&&current_coordinates){
        const [latstr, lngstr] = coordinates.split(',');
        const [new_latstr, new_lngstr] = current_coordinates.split(',');
        const lng = Number(lngstr);
        const lat = Number(latstr);  
        const new_lng = Number(new_lngstr);
        const new_lat = Number(new_latstr);           
        setPositioningVal({
                "lat":lat,
                "lng":lng, 
                              
        })
        setnewpositioningVal({
        "new_lat":new_lat,
                "new_lng":new_lng 
        })
    } 

 
   else if (coordinates) {

      const latstr = coordinates.split(',')[0];
      const lngstr = coordinates.split(',')[1];
      const lng = Number(lngstr);
      const lat = Number(latstr);
      setPositioningVal({
        lat: lat,
        lng: lng,
      });
    }else if(address){
     var Owner_address=""
      if(get('market').country_code =='UGA'){
         Owner_address=( get('market').country +","+ address.district.value +"," + address.village.value +"," + address.landmark.value +","+ address.location.value )
      }else if(get('market').country_code =='RWA'){
         Owner_address=(get('market').country +", "+ +address.province.value +","+ address.district.value +"," + address.sector.value +","  + address.sector.cell+","+ address.village.value +", "+ address.location.value +","+  address.landmark.value)
      }
         // Configure the Geocode library to use the same API key
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
        Geocode.fromAddress(`${Owner_address}`).then(
            (response) => {
            const { lat, lng } = response.results[0].geometry.location;
            setPositioningVal({lat,lng});
            calculateCoordinates(lat,lng)
            },
            (error) => {
            console.error(error);
    }
  );
        
    }
  }, [coordinates]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
  });

  const markerHandler = (val) => {
    console.log(val, 'value from marker');
  };

  const polygonStyle = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
  };
   
if (loadError) return "Error loading maps";
if(!isLoaded) return "Loading maps";

  return (

    <div>

        {(coordinates&&current_coordinates)?
            <GoogleMap 
            mapContainerStyle={mapStyles}
            zoom={color?4:5}
            center={positioningVal}
                                           
        > 


            
            <Marker icon={ color ? '/img/old_gps.png' :'/img/pin.png'} position={{lat:positioningVal.lat,lng:positioningVal.lng}} onClick={(val)=>markerHandler(val)}/> 
            <Marker icon={ color ? '/img/new_gps.png' :'/img/pin.png'} position={{lat:newpositioningVal.new_lat,lng:newpositioningVal.new_lng}} onClick={(val)=>markerHandler(val)}/>
               {color &&
                 <>  
                 <InfoWindow onCloseClick={() => {setselectedCustomer(null);}} position={{lat:positioningVal.lat,lng:positioningVal.lng}} >
                    <div>
                      <p style={{color:'#37b8d8'}}> <b>Existing Location</b></p>
                    </div>
                </InfoWindow>
                <InfoWindow onCloseClick={() => {setselectedCustomer(null);}} position={{lat:newpositioningVal.new_lat,lng:newpositioningVal.new_lng}} >
                    <div style={{padding:'0'}}>
                      <p style={{color:'#f39348'}}> <b>New location</b></p>
                       
                    </div>
                </InfoWindow>
                </>
         
                }
        </GoogleMap>
            :

          <GoogleMap
            mapContainerStyle={
              height ? auditKyc_container_height_adjust : containerStyle
            }
            zoom={cust_coordinates ? 8:15}
            center={ cust_coordinates ? location_center :positioningVal}>
              
            <Marker
              position={{ lat: positioningVal.lat, lng: positioningVal.lng }}
              onClick={(val) => markerHandler(val)}
            />
        
       

      {address &&
     <Circle
        center={{ lat: positioningVal.lat, lng: positioningVal.lng }}
        radius={200}
        options={{ ...polygonStyle }}
      />
      }


    {cust_coordinates && (clusterer_view==true ?
      <>     
      <MarkerClusterer>
        {(clusterer) =>
          cust_coordinates.map((marker,idx) => (
            <Marker
              key={idx} onClick={() => {setselectedCustomer(marker);}}
              position={{ lat: marker.lat, lng: marker.lng }}
              clusterer={clusterer}
            />
          ))
        }
      </MarkerClusterer>
     {selectedCustomer && <InfoWindow onCloseClick={() => {setselectedCustomer(null);}} position={{lat:selectedCustomer.lat,lng:selectedCustomer.lng}}>
                    <div>
                        <p>{selectedCustomer.cust_id}</p>
                        <p>{selectedCustomer.biz_name}</p>
                    </div>
                </InfoWindow>}
      </>

      :

      <>     

      {cust_coordinates.map((marker,idx) => (
            <Marker
              key={idx} onClick={() => {setselectedCustomer(marker);}}
              position={{ lat: marker.lat, lng: marker.lng }}
            />
          ))
        }
     {selectedCustomer && <InfoWindow onCloseClick={() => {setselectedCustomer(null);}} position={{lat:selectedCustomer.lat,lng:selectedCustomer.lng}}>
                    <div>

                        <p>{selectedCustomer.cust_id}</p>
                        <p>{selectedCustomer.biz_name}</p>

                    </div>
                </InfoWindow>}
      </>)
      }

      </GoogleMap>
}
    </div>
  );
}

export function MultipleMarkerGoogleMap (props) { 
      
    const [selectedRM, setSelectedRM] = useState(null);
    const [showRmDetails, setShowRmDetails] = useState(false);
    
    const { isLoaded, loadError } = useLoadScript({
         googleMapsApiKey:process.env.REACT_APP_GOOGLE_KEY   
    });

    if (loadError) return "Error loading maps";
    if(!isLoaded) return "Loading maps";

    return(  
        <div>
            <input type="button" style={{float: "right"}} onClick={() => {setShowRmDetails(!showRmDetails)}} value={"SHOW/HIDE RM NAMES"} />
            <GoogleMap 
                mapContainerStyle={containerstyle}
                zoom={8}
                center={props.data.center}                               
            > 
    
                {props.data.coordinates.map((d) => (<Marker position={{lat:d.lat,lng:d.lng}} onClick={() => {setSelectedRM(d);}}
                icon={{
                    url: ((d.gender.toLowerCase() == 'female' && d.cust_id) ? f_rm_in_cust_loc : (d.gender.toLowerCase() == 'female') ? f_rm : d.cust_id ? m_rm_in_cust_loc : m_rm),
                    }}
                >
                {showRmDetails && 
                <InfoWindow position={{lat:d.lat,lng:d.lng}}>
                <div>
                    <b><p>RM DETAILS</p></b>
                    <b><p>{d.name}</p></b>
                    {d.cust_id && <p>{d.cust_id}</p>}
                </div>
            </InfoWindow>}
                </Marker>))}
                {selectedRM && <InfoWindow onCloseClick={() => {setSelectedRM(null);}} position={{lat:selectedRM.lat,lng:selectedRM.lng}}>
                    <div>
                        <b><p>RM DETAILS</p></b>
                        <b><p>{selectedRM.name}</p></b>
                        {selectedRM.cust_id && <p>{selectedRM.cust_id}</p>}
                    </div>
                </InfoWindow>}
            </GoogleMap>          
        </div>      
    )
}


export function RmLiveRoutes (props) { 
      
    const [positioningVal, setpositioningVal] = useState({
        lat:1.6993811,
        lng:33.6139049
    })
    
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);

    const { isLoaded, loadError } = useLoadScript({
         googleMapsApiKey:process.env.REACT_APP_GOOGLE_KEY   
    });

    if (loadError) return "Error loading maps";
    if(!isLoaded) return "Loading maps";

    return(  
        <div>
            <GoogleMap 
                mapContainerStyle={containerstyle}
                zoom={11}
                center={props.center}                               
            >
                
            {props.cust_coordinates.map((d) => (<Marker position={{lat:d.lat,lng:d.lng}} onClick={() => {setSelectedCustomer(d);}}
                icon={{
                    url: cust_location,
                    }}
                >
                </Marker>))}
                {selectedCustomer && <InfoWindow onCloseClick={() => {setSelectedCustomer(null);}} position={{lat:selectedCustomer.lat,lng:selectedCustomer.lng}}>
                    <div>
                    <b><p>CUSTOMER DETAILS</p></b>
                    <p><b>Customer Name:</b> {selectedCustomer.cust_name}</p>
                    <p><b>Customer ID:</b> {selectedCustomer.cust_id}</p>
                    <p><b>Time:</b> {selectedCustomer.visit_time}</p>
                    </div>
                </InfoWindow>}

            <Polyline 
              path={props.path_coordinates}
              geodesic={true}
            />

            {props.punch_in &&
              <Marker position={{lat:props.punch_in.lat, lng:props.punch_in.lng}} onClick={() => {setSelectedAction(props.punch_in)}}
              icon={{
                url: punch_in,
              }}
              >
              </Marker>
            }
            
            {selectedAction && <InfoWindow  onCloseClick={() => {setSelectedAction(null);}} position={{lat:selectedAction.lat,lng:selectedAction.lng}}>
                <div>
                  <b><p>{selectedAction.action}</p></b>
                  <p>{selectedAction.time}</p>
                </div>

            </InfoWindow>}

            {props.punch_out &&
              <Marker position={{lat:props.punch_out.lat, lng:props.punch_out.lng}} onClick={() => {setSelectedAction(props.punch_out)}}
              icon={{
                url: punch_out,
              }}
              >
              </Marker>
            }

            </GoogleMap>          
        </div>      
    )

}