import { get_path } from '../helpers/common_helper';
import {call_fetch} from './../helpers/fetch_helper';

export const createDataProvider = request  => {
   return call_fetch(get_path('admin') + '/data_provider', request);
}

export const viewDataProvider = request  => {
   return call_fetch(get_path('admin') + '/data_provider/view', request);
}

export const listDataProvider = request  => {
   return call_fetch(get_path('admin') + '/data_provider/list', request);
}

export const listCCAPayments = request  => {
   return call_fetch(get_path('admin') + '/data_provider/cca/payments', request);
}