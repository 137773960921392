


export const button_variants = [
    
        {
            props: { variant: 'x_small'},
            style: { width : '150px', height:'32px'}
        },
        {
            props: { variant: 'small'},
            style: { width : '200px', height:'36px'}
        },
        {
            props: { variant: 'medium'},
            style: { width : '260px', height:'48px'}
        }
        
    
]