import React from 'react';
import {Form, Col , Row, Button, OverlayTrigger, Tooltip,checkbox } from 'react-bootstrap';

import Select from "react-select";
import FlowComponent from '../../common/component/core/FlowComponent';
import DatePicker from 'react-datetime';
import moment from 'moment';
import {check_priv} from '../../../helpers/storage_helper';
import '../../../styles/flow.css';
import { BasicReactTable } from '../../../helpers/react_table';
import { flaggedCustomers ,softEnable} from '../../../actions/borrower_actions';
import { dd_value } from '../../../actions/common_actions';
import { Homeacc_prvdrLogoselector } from '../../../helpers/common_helper';
import { Link } from 'react-router-dom';

class FlaggedCustomers extends FlowComponent{
	
	
constructor(props){
    super(props);
    this.state = {
         filters:[
            {key:"Customer",type:"input"},
            {key:"Initiated by",type:"select"},
            {key:"start_date",type:"date"},
            {key:"end_date",type:"date"},
    
        ],
        loader:false,
        column_datas:this.column_datas(),
        criteria_arr:{},
        soft_enable:false
    }; 
}

componentWillMount(){
    this.setOptionsListToStateFromAppApi("common","priv_users",this.req({priv_code:'',status:'enabled',role_codes:["operations_auditor", "risk_compl_officer"]}), ['id' , 'name']);
    this.flaggedCustomersList()
    
}

flaggedCustomersList(criteria_arr=null){
    this.setState({loader:true})

    var req={}
    if(criteria_arr!=null){
         req['criteria_arr']= criteria_arr
    }
    flaggedCustomers(this.req(req))
    .then((response)=>{
        if(!response){ 
        this.setState({loader:false})
         return ""}
        if(response.status=="success"){
            this.setState({row_datas:response.data,loader:false})
            console.log('response', response)
        }
    })
}
handleOnChange(val,type){
 
    var obj={...this.state.criteria_arr}
    obj[type]=val
    if(val==""){
        delete obj[type];
    }
    this.setState({criteria_arr:obj})
}

submitHandler(){
    this.setState({loader:true})
    this.flaggedCustomersList(this.state.criteria_arr)

}

softEnable(data,value){
 if(value && data.customer_status !="soft_enable" ){
    this.setState({[`soft_enable_${data.id}`]:true})
    var req={cust_id:data.cust_id,id:data.id ,cust_name :data.first_name +" " +data.last_name,flag_id:data.flag_id}
    if(window.confirm("By doing this action, " + data.first_name +" " +data.last_name  +" will be soft enabled for 2 FAs or 15 days")){
    softEnable(this.req(req))
    .then((response)=>{
        if(response && response.status=="success"){
            alert(response.message)
            this.setState({[`soft_enable_${data.id}`]:false})
            this.flaggedCustomersList()

        }
    })
  }

 }
 else{
    this.setState({[`soft_enable_${data.id}`]:false})

 }
}

column_datas () {
    const columns = [
          {
            Header : "Acc Number",
            accessor: "acc_number",  
                   
            Cell : cell =>  {return <div className='d-flex align-items-center'> <Homeacc_prvdrLogoselector logotype={cell.row.original.acc_prvdr_code}/> <span className='ml-2'>{cell.row.original.acc_number}</span></div>}
        },
        {
            Header :<p className='text-center m-0'>Customer ID </p> ,
            accessor: "cust_id",  
            Cell: (cell) => (<p className='cust_follow_text watch_list_cust_id m-0' style={{ textAlign: "center" ,color:""}}><Link to={"/borrower/indiv/view/" + cell.row.original.cust_id} target="_blank" >{cell.row.original.cust_id}</Link></p>),

        },
       
        {
            Header : "Initiated by",
            accessor: "initiated_by",  
                   
            Cell : cell =>  {return <>{cell.row.original.initiated_by}</>}
        },
        {
            Header : "Flagged on",
            accessor: "flagged_date",  
                   
            Cell : cell =>  {return <>{ moment(cell.row.original.flagged_date).format("DD MMM YYYY")}</>}

        },
        {
            Header : "Disablement in",
            accessor: "date_diff",  
                   
            Cell : cell =>  {return <> { (cell.row.original.status == "disabled") ? <b className='text-danger'>Disabled</b> : <span> {cell.row.original.date_diff} days</span>} </>}
        },
        {
            Header : "Reason",
            accessor: "reason", 
            width:200, 
            Cell : cell => {return <>{ (cell.row.original.flagging_reason && cell.row.original.flagging_reason.length>0) &&
                
                cell.row.original.flagging_reason.map((item, idx) => {
                return (
                  <OverlayTrigger key={idx} overlay={<Tooltip id={`tooltip-${idx}`}>{dd_value(item, "rnc_flag_reason")}</Tooltip>}>
                    <span style={{whiteSpace: 'pre-line'}}>{dd_value(item, "rnc_flag_reason")} {idx !== cell.row.original.flagging_reason.length - 1 && ", "}
                 <br /></span>
                  </OverlayTrigger>
                );
              })}</>}

        },
        {
            Header : <p className='text-center m-0'>Soft Enable</p> ,
            accessor: "Soft Enable",  
            show: check_priv("flagging", "soft_enable") ,   
            Cell : cell =>  {return <div className={`d-flex justify-content-center  `}>

                <button type="button" class={`btn  ${(cell.row.original.customer_status =="soft_enable" )?"btn-warning":"btn-primary"} ${(cell.row.original.status == "disabled") ? "": (cell.row.original.customer_status =="soft_enable") ? "":"disable_div"}`} onClick={(e)=> this.softEnable(cell.row.original, true)} >Soft Enable{(cell.row.original.customer_status =="soft_enable" )? "d":""}</button>

                  {/* <input type="checkbox" className='pointer' checked={this.state[`soft_enable_${cell.row.original.id}`] ?true:false } style={{height:'19px',width:'20px'}} onChange={(e)=>this.softEnable(cell.row.original, e.target.checked)} /> */}

            </div>}
        },

        
       
       
       
  ]
  return columns.filter((column) => column.show !== false);
}

render(){
	return(
        <div>
        <div className='floatAdvancetitleBox mt-4 p-3'>
        <h2 className='text-white m-0 py-2 '>Flagged Customers</h2>
        </div>
        <div className='py-4' style={{backgroundColor: 'rgb(32, 41, 64)'}}>

            <div className='row m-0 d-flex align-items-center'>

                {this.state.filters&& this.state.filters.map((item,idx)=>{return(
                    <div className='col-lg-3 col-md-12 col-sm-12 my-3'>
                    <fieldset className='border border-white p-2 my-2 ' style={{borderRadius:'5px'}}>
                        <legend className='w-auto text-light ml-4 px-2  mb-0'style={{fontSize:'13px'}}>{dd_value(item.key)}</legend>
                        <div className='flagged_cust'>
                     {(item.type=="input") &&
                        <Form.Group as={Col} className={` px-2 py-0 no-margin  `}>
                        
                        <Form.Control id="req_parameter" placeholder=' Name/Cust ID/Mobile No' className='p-0' autoComplete='off' onChange={(e) => {this.handleOnChange(e.target.value,"cust_data")}} >
                        </Form.Control>
                    </Form.Group>
                    }
                    
                    {item.type=="select" &&

                    <Form.Control as="select" id="audited_by" onChange={(e) =>{this.handleOnChange(e.target.value,"initiated_by")}}>
                    <option value="all">All</option>
                    {this.state.priv_users_dd }
                    </Form.Control>
                    // <Select onChange={(e) =>{this.handleOnChange(e,"territory")}}
                    // value = {this.state.select_value}
                    // options={this.getMultiSelectOptions('territory')} 
                    // placeholder="All"
                    // styles={this.select_custom_styles()}
                    // classNamePrefix="my-select" 
                    // isMulti/>
                    
                    }
                    {item.type=="date" && 
                    <div className='px-2'>
                    <DatePicker  closeOnSelect={true} value={this.state[item.key]?this.state[item.key]:''}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"
                    isValidDate={(current) => {
                        const today = moment().startOf('day');
                        return current.isSameOrBefore(today, 'day');  }} 
                    onChange={(value) => {this.handleOnChange( moment(value).format("YYYY-MM-DD"),item.key)}}  inputProps={{ placeholder: `DD MMM YYYY`,autoComplete:'off' }}  />
                    </div>
                    }
                    </div>
                    </fieldset>
                    </div>
                )})}
               
              
            </div>

            <div className='d-flex justify-content-center my-3 mb-5'>
                <div>
                <button type="button" class={`btn btn-primary btn-lg  ${((Object.keys(this.state.criteria_arr).length>0 ) )  ? "":"disable_div"} ${this.state.loader ? "disable_div":""} `} onClick={()=>{this.submitHandler()}}>Submit</button>

                </div>

               </div>
        </div>

        <div>
        
        {this.state.loader ? <>
           <p className='text-light mt-5 text-center' >Loading...</p>
           <div className="product_takeup_loader "></div></>:
        <div className='flagg_cust_table'>
        {this.state.row_datas && this.state.row_datas.length>0 ?

        <BasicReactTable className='border' row_data={this.state.row_datas} csv_file={true} FName="Flagged Customers"   column_data={this.state.column_datas} default_page_size={10} pagination={this.state.row_datas.length>9 ?true:false}  searchData={[{title:'Flagged Customers'}]}/>
       :
        <>
        {!this.state.loader  && this.state.row_datas && !this.state.row_datas.length>0 &&
         <p className='text-white text-center my-3'>NO DATA</p>
        }
        </>
        }
        </div>
        }
        </div>

    </div>

		);
    }
}
export default FlaggedCustomers;

