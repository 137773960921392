import React, { useState, useEffect } from 'react';
import '../../../styles/flow.css';
import Sessions from './Sessions';
import { Link } from 'react-router-dom';
import { get } from '../../../helpers/storage_helper';
import {call_await_fetch, app_path} from '../../../helpers/fetch_helper';
import ReactTable from 'react-table';
import QrCodeModal from './QrCodeModal';

function Whatsapp() {
    const market = get('market')
    const [result, setResult] = useState([{ 'id': 0, 'mobile_num': "" }])

    useEffect(() => {
        const req = { country_code: market.country_code, time_zone: market.time_zone}
        call_await_fetch(app_path + '/whatsapp/get_sessions', req).then((response) => {
            setResult(response.data.error)
        })
    }, [])

    function deleteSession(id) {
        setResult(prevResult => {
            return prevResult.filter((prevRes) => {
                return prevRes.id != id
            })
        })
    }

    return (
        <div>
            <div className="WhatsappLoggedPersondetailsBox">
                <h5>EXISTING SESSIONS</h5>
                <Sessions result={result} deleteSession={deleteSession} />
            </div>
            <div className="WhatsappLoggedPersondetailsBox">
                <Link to="/whatsapp/connect">
                    <button className='loginBtn'><h4>Add New Session</h4></button>
                </Link>
            </div>
        </div>
    );

}

export default Whatsapp;
