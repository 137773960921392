import React from 'react';
import { Navigate } from 'react-router-dom';
import '../../../styles/flow.css';
import '../../market/container/datatables.css';
import FlowContainer from '../../common/container/core/FlowContainer';
import _ from 'lodash';
import CommonReactTable from '../../../helpers/commonReact_table';
import {Card,Button} from 'react-bootstrap';
import { format } from 'date-fns';
import {ontimePaymentReport} from '../../../actions/report_actions';
import OntimePaymentReport from '../component/ontimePaymentReport';
import { dd_value } from '../../../actions/common_actions';
import {format_date_tbl,title_case,Homeacc_prvdrLogoselector} from '../../../helpers/common_helper';
import { BasicReactTable } from '../../../helpers/react_table';

class OntimePaymentContainer extends FlowContainer{

    state ={
        columns :[],
        report_list:[],
        ontime_pay_list:{},
        ontime_pay_table:"hide_div",
        ontime_pay_cri : "",
        ontime_pay_list : {
            group_wise : null
        } 

    }

    componentWillMount(){

        this.handleCurDate()
    }

    handleCurDate = () =>{

        const date = new Date()
        const cur_date = format(date, 'yyyy-MM-dd')
        const ontime_pay_list = this.state.ontime_pay_list
        ontime_pay_list['start_date'] = cur_date
        ontime_pay_list['end_date'] = cur_date
        this.setState({ontime_pay_list})
        // this.handleSearch()

    }

    handlecloseprofilechange =(event) =>{
        var value = null
        console.log(event.target.checked)
        if(event.target.id == 'day_wise' && event.target.checked == true){
             value = event.target.id
        }else if( event.target.id === 'acc_prvdr_wise' && event.target.checked == true){
            value = event.target.id 
        }
        this.setState({ontime_pay_list : {...this.state.ontime_pay_list, group_wise : value}, ontime_pay_cri : value})
        // this.setState({ontime_pay_cri : value})
        
    }

    handleUpperCase = event => {
        const result = event.toUpperCase();
        const str_replace = result.replace(/_/g," ") 
        return str_replace
    }
    handleSearch =(event)=>{

        ontimePaymentReport(this.req({ontime_pay_criteria: this.state.ontime_pay_list}))
        .then((response) => {
            this.resp(response)
            if(!response){return };
                if(response.status === "success"){
                        this.resp(response)
                        var columns = this.state.columns
                        this.setState({report_list:response.data})

                       
                      
                        if(this.state.report_list.length>0 && this.state.report_list[0].date){
                            columns = [

                                {
                                    Header:  <div >Date</div>, 
                                    id: 'date',
                                    accessor: row => format_date_tbl(row.date),
                                    // style : {fontSize:"13px"} ,


                                },
                                {  
                                    Header:  <div >Total FA due</div>,

                                    id :'total',
                                    accessor : row =>row.total,
                                    // style : {fontSize:"13px"} ,
                                },{  
                                    Header:  <div >FA Repaid On-time</div>, 
                                    id :'on_time_pay',
                                    accessor : row =>row.on_time_pay,
                                    // style : {fontSize:"13px"} ,

                                },{  
                                    Header:  <div >Percentage %</div>, 
                                    id :'percent',
                                    accessor: row =>  row.percent,   
                                    // style : {fontSize:"13px"} ,                       
                                },
                            ]

                        }else if(this.state.report_list.length>0 &&  this.state.report_list[0].acc_prvdr_code){
                            columns = [
                                {  
                                    Header : "Account Prvdr",
                                    id: "acc_prvdr_code",
                                    sortable:false,
                                    accessor:row => <div className='d-flex align-items-left'> <Homeacc_prvdrLogoselector logotype={row.acc_prvdr_code}/></div> ,

                                },

                                {  
                                    Header:  <div >Total FA due</div>,
                                    id :'total',
                                    accessor : row =>row.total,
                                    // style : {fontSize:"13px"} ,
                                },{  
                                    Header:  <div >FA Repaid On-time</div>, 
                                    id :'on_time_pay',
                                    accessor : row =>row.on_time_pay,
                                    // style : {fontSize:"13px"} ,

                                },{  
                                    Header:  <div >Percentage %</div>, 
                                    id :'percent',
                                    accessor: row =>  row.percent,   
                                    // style : {fontSize:"13px"} ,                       
                                },
                            ]

                        }else{

                            columns = [

                                {  
                                    Header:  <div >Total FA due</div>,
                                    id :'total',
                                    accessor : row =>row.total,
                                    // style : {fontSize:"13px"} ,
                                },{  
                                    Header:  <div >FA Repaid On-time</div>, 
                                    id :'on_time_pay',
                                    accessor : row =>row.on_time_pay,
                                    // style : {fontSize:"13px"} ,

                                },{  
                                    Header:  <div >Percentage %</div>, 
                                    id :'percent',
                                    accessor: row =>  row.percent,   
                                    // style : {fontSize:"13px"} ,                       
                                },
                            ]

                        
                    }
                }
                    this.setState({columns,ontime_pay_table:"show_div"})     
        })

}

    render(){
        return(

            <div className="container containerTopmargin max-width no-padding">            
                <form className="mb-3" autocomplete="off">
                    <Card>
                        <Card.Header style = {{backgroundColor: "#01062c" , color: "white"}}><h4>Ontime Payment Report</h4></Card.Header>
                        <Card.Body style={{padding:15}}>
                                <OntimePaymentReport  id="ontime_pay_list" onComponentChange={this.handleComponentChange} data = {this.state.ontime_pay_list} onComponentCheckboxclick= {this.handlecloseprofilechange} ontime_pay_cri = {this.state.ontime_pay_cri} />   
                                <div className="form-group row reset-margin justify-content-center py-4">
                                    <Button disabled={this.state.calling_api} className= {`col-md-2 mr-3 btn btn-primary`} onClick={this.handleSearch}>SUBMIT</Button>
                                </div>
                        </Card.Body>  
                    </Card>
                </form>
                {!_.isEmpty(this.state.report_list)  &&
                <div>
                
                    {this.state.calling_api ? 
                    <>
                    <p className='text-light mt-3 text-center'>Loading...</p>
                    <div className="product_takeup_loader"></div>
                    </> : 
                   
                    <div className={`mt-3 ${this.state.ontime_pay_list}`} >	
                  		
                        {/* <CommonReactTable showPag={true} minRow={0} row_data={this.state.report_list !== undefined ? this.state.report_list : []} column_data={this.state.columns} resizable={false} exportCSV={true} csvFile={"ontime payment"} csvData={this.state.report_list ? this.state.report_list : []} searchData={[{...this.state.ontime_pay_list,title:'Ontime Payment List'}]}/>    */}

                        <BasicReactTable row_data={this.state.report_list !== undefined ? this.state.report_list : []} column_data={this.state.columns}  pagination={this.state.report_list.length > 10} default_page_size = {10} export_csv={true} searchData={[{...this.state.ontime_pay_list,title:'Ontime Payment List'}]} FName={"ontime payment"} /> 
                    				
                    </div>
                 }
                </div>
                
            }
                
            </div>
        )
    }
}export default OntimePaymentContainer 