import React from 'react';
import {Card,Button} from 'react-bootstrap';
import SearchStmtImports from '../component/SearchStmtImports';
import {format_date_tbl} from '../../../helpers/common_helper';
import {dd_value, searchStmtImports} from '../../../actions/common_actions';
import FlowContainer from '../../common/container/core/FlowContainer';
import { Homeacc_prvdrLogoselector , convert_sec_to_min_sec} from '../../../helpers/common_helper';
import CommonReactTable from '../../../helpers/commonReact_table';
import { BasicReactTable } from '../../../helpers/react_table';

class StmtImportSearchContainer extends FlowContainer {
    state ={
        stmt_import :{},
        columns :[],
        stmt_imports_list:[],
        stmt_imports_table:"hide_div"
    }

	validate_search(stmt_import) {
		
		if(!stmt_import.account_id){
            alert("Please select accounts")
            return false  
        }

		if(stmt_import.start_time && stmt_import.end_time ){
		 	return true
  		}else{
  			alert("Please enter valid search criteria")
  			return false
  		}		
	}
	
    handleSearch =(event)=>{
		var is_valid = this.validate_search(this.state.stmt_import)
		if(is_valid){
			searchStmtImports(this.req({stmt_search_criteria: this.state.stmt_import}))
			.then((response) => {
				this.resp(response)
				if(!response){return };
				if(response.status === "success"){
					this.resp(response)
					var columns = this.state.columns
						columns = [
									{
										Header : "Network",
										id: 'network_prvdr_code',
										accessor:row => <Homeacc_prvdrLogoselector logotype={row.network_prvdr_code}/>
									},{  
										Header: 'Acc Number',
										id: 'acc_number',
										accessor: row => <div style={{height:30,width:60}}>			
															<img className="img-fluid" src={`/img/${row.acc_prvdr_code}_logo_color.png`} alt={row.acc_prvdr_code}/>
															<span className='ml-2' style={{color : "#17a2b8"}}>{row.acc_number}</span>			
														</div>
									},{  
										Header: 'Start Time',
										id: 'start_time', 
										accessor:row => format_date_tbl(row.start_time,true)  
									},{  
										Header: 'End Time',
										id: 'end_time', 
										accessor:row => format_date_tbl(row.end_time,true)  
									},{  
										Header: 'Duration',
										id: 'dur_secs', 
										accessor:row =><div> {row.end_time ? convert_sec_to_min_sec(row.dur_secs) : "NA" } </div>
									},{  
										Header: 'Status',
										id: 'status', 
										accessor: row => dd_value(row.status)
									}]
								}
								this.setState({columns,stmt_imports_list:response.data,stmt_imports_table:"show_div"})
			})
		}
       
    }

    render(){
        return(
            <div className="container containerTopmargin max-width no-padding">            
                <form className="mb-3" autocomplete="off">
                    <Card>
                        <Card.Header style = {{backgroundColor: "#01062c" , color: "white"}}><h4>Search Statement Imports</h4></Card.Header>
                            <Card.Body style={{padding:15}}>
                            	<SearchStmtImports id = "stmt_import" data = {this.state.stmt_import} onComponentChange={this.handleComponentChange} />
                                <div className="form-group row reset-margin justify-content-center py-4">
                                    <Button  disabled={this.state.calling_api} className= {`col-md-2 mr-3 btn btn-primary${this.state.submit_btn_style}`} onClick={this.handleSearch} >Search</Button>
                                </div>
                            </Card.Body>
                        </Card>
                </form>
                <div className={`mt-3 ${this.state.stmt_imports_table}`} >						
					{/* <CommonReactTable showPag={true} minRow={0} row_data={this.state.stmt_imports_list !== undefined ? this.state.stmt_imports_list : []} column_data={this.state.columns} resizable={false} exportCSV={true} csvFile={"stmt_imports"} csvData={this.state.stmt_imports_list ? this.state.stmt_imports_list : []} defaultSorted={[{id:"start_time"}]} searchData={[{...this.state.stmt_import,title:'Search Statement Imports'}]}/>   */}
					{this.state.stmt_imports_list && this.state.stmt_imports_list.length>0 ?
					<BasicReactTable row_data={this.state.stmt_imports_list !== undefined ? this.state.stmt_imports_list : []} column_data={this.state.columns} pagination={this.state.stmt_imports_list && this.state.stmt_imports_list.length > 9 ? true : false} csv_file={true} FName={'stmt_imports'} export_csv_datas={this.state.stmt_imports_list ? this.state.stmt_imports_list : []} searchData={[{...this.state.stmt_import,title:'Search Statement Imports'}]} default_page_size={10} defaultSorted={[{id:"start_time"}]}/>  	:
					<p className='m-0 text-center text-white'>No Data</p>
					}			
    			</div>
            </div>        
		)
    }
}

export default StmtImportSearchContainer;
