import React from 'react';
import FlowContainer from '../../common/container/core/FlowContainer';
import '../../../styles/flow.css';
import {getCustomReport, getKPIReport} from '../../../actions/report_actions';
import {dd_value} from '../../../actions/common_actions';
import {rt_algn, lbl_amt, short, title_case} from '../../../helpers/common_helper';
import $ from 'jquery';



class CustomReportContainer extends FlowContainer {

state = {
  section_rows_arr : null,
  headers: null,
}
format_metric(metric_obj){
      var value = null;

      if (metric_obj) {

          if (metric_obj.unit == "%") {
              value = Number(metric_obj.value).toFixed(2) + " %"
          } else if (metric_obj.unit == "days") {
              value = Number(metric_obj.value).toFixed(1) + " days"
          } else if (metric_obj.unit == "") {
              value = Number(metric_obj.value).toFixed(0)
          } else {
              value = lbl_amt(Math.round(metric_obj.value), metric_obj.unit)
          }
      }
       return value;
}


get_headers(report_arr){
  const report_values =  report_arr.map((this_rpt) => {
                return (
                      <> 
                          <th className="w-25" >{this_rpt.title}</th>
                      </>
                      );
                      });
  let metric = "Metric"                    
  if(this.props.type == 'risk_category_report'){
    metric = "Flow RM"
  }
                     report_values.unshift(<th>{metric}</th>)
                      report_values.unshift(<th>No</th>)
  return report_values;
}

get_section_rows(section,report_arr)
{ 

    const ref = section.ref
    const report_values = section.items.map((metric,i)=>

      {
          var metric_code = "";
          metric_code = dd_value(metric);
          let index = ref ? null : this.index++
          return (
            <tr key={i}>
                <td className='report_left'>{index}</td>
                <td className='report_left'>{metric_code}</td>
                {this.get_section_columns(metric, report_arr, ref)}
                </tr>
            );
      });
      let index = ref ? this.index++ : null
      report_values.unshift(
        <tr>
            <td  className="report_title">{index}</td>
            <td colSpan="1" className="report_title">{section.title}</td>
            {this.get_section_columns(null, report_arr)}
        </tr>);
     return report_values;
}



get_section_columns(metric, report_arr, ref = null)
{
   const report_values =  report_arr.map((this_rpt) => {
      let metric_obj = ref ? this_rpt[ref][metric] : this_rpt[metric]
      if(metric_obj)
                return (
                      <>
                      <td>{rt_algn(this.format_metric(metric_obj))}</td>
                      </>
                      );
      else{
          return (<>
                    <td></td>
                  </>);
      }
                      });
  return report_values;
}

  componentWillMount() {

     const country_code = this.country_code
     const acc_prvdr_code = this.acc_prvdr_code
    getCustomReport(this.req({current:'true',country_code:country_code,acc_prvdr_code:acc_prvdr_code, type: this.props.report_type}))
        .then((response) => {
        
          if(!response){return };
            if(response.status === "success"){
              this.index = 1;
              const reports_arr = response.data.reports;

              const headers = this.get_headers(reports_arr);

              const all_sections = response.data.all_sections;

              const section_rows_arr = all_sections.map((section)=>{
                return this.get_section_rows(section, reports_arr);
              });

              this.report_date = ""

            	this.setState({headers, section_rows_arr});
              const page_length = response.data.page_length
              $('#kpi_table').removeAttr('width').dataTable({
                    searching : false,
                    paging : true,
                    "pageLength": page_length,
                    info : true,
                     dom:'Bfrtip',
                      buttons: [
                      {
                      title:"",
                      extend: 'print',
                      customize: function (win) {
                     
                    $(win.document.body)

                        .prepend(
                            '<img src="'+window.location.origin+'/logo_blue.png" alt = "FLOW" style="float:left;"/>'
                        );


                }
                    },
                      {
                        extend:'csv',
                      }
                      ],
                      "order" : [],


              });

            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));
            }

        }
        );
  }

render(){

      return (
      <div className="container">
	      	<br/><h4>{title_case(this.props.report_type)}</h4>
          <div id='kpi_datatable' >
            <table id="kpi_table" className="display strip cust-rep row-border order-column report">
                    <thead>
                      <tr>
                        {this.state.headers}
                      </tr>
                    </thead>
                    <tbody>
                        {this.state.section_rows_arr}
                    </tbody>
            </table>
          </div>
      </div>
      );
  }

}

export default CustomReportContainer;
