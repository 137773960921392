import {  Navigate } from 'react-router-dom';
import React from 'react';
import {createLender, viewLender} from '../../../actions/lender_actions';
import FlowContainer from '../../common/container/core/FlowContainer';
import Lender from '../component/Lender';
import AddressInfo from '../../common/component/AddressInfo';
import Organisation from '../../common/component/Organisation';
import ChooseOrg from '../../common/component/ChooseOrg';
import Person from '../../common/component/Person';
import '../../../styles/flow.css';
import withRouter from '../../../Router/Withrouter';
class LenderContainer extends FlowContainer {
  state = {
    toList: false,
    org:{},
    reg_address: {},
    contact_person : {},
    lender: {},
  }
  componentWillMount() {
    if(this.props.mode === 'view'){
      const lender_code = this.props.params.lender_code;
      const request = {lender_code};
      viewLender(this.req(request))
        .then((response) => {
          if(!response){return };
            this.lender = response.data;
            this.setState({lender: this.lender,
                            org: this.lender.org,
                            contact_person: this.lender.contact_person,
                            reg_address: this.lender.org.reg_address
                           });
          }
        );
    }
  }
 componentDidMount() {

 }
  handleSubmit = (event)=>{
    event.preventDefault();
    var lender = this.state.lender;
    var org = this.state.org;
    org.reg_address = this.state.reg_address;
    org.country_code = this.country_code;
    lender.country_code = this.country_code;
    lender.org = org;
    lender.contact_person = this.state.contact_person;
    if('national_id' in this.state.contact_person){
      lender.contact_person.id_proof_num = this.state.contact_person.national_id
    }
    lender.contact_person.country_code = this.country_code;
    
    createLender(this.req({lender}))
        .then((response) => {
          if(!response){return };
            if(response.status === "success"){
              //alert(JSON.stringify(response.data));
              this.setState({new_lender_code: response.data});
              this.setState({toList: true});
              alert("New Lender created succesfully!");
            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          }
        );
  }


render(){
    
    if (this.state.toList === true) { 
      
      return <Navigate to={`/lender/view/${this.state.new_lender_code}`}/>
    }
      
      return (
 
      <div className="container">
         <h5>{this.props.title + (this.state.lender.name? " : " + this.state.lender.name : "")}</h5>
        <form id="lender_form" onSubmit={this.handleSubmit}>
            <div id="" className={"text-labelblue"}>
            <Lender id="lender" title="Lender Details" data={this.state.lender} mode={this.props.mode} 
                    onComponentChange={this.handleComponentChange}/>
            <br/>
            <hr/>
          
            {this.props.mode === "create" && 
            <ChooseOrg id="data_provider_choose_org" resetOrgNAddr={this.handleResetOrgNAddr} onChange={this.handleOrgChange} />
            }
            <div className={`${this.state.div_style}`} >
            <Organisation id="org" entity="lender" title="Organisation Details" data={this.state.org} mode={this.props.mode} 
                    onComponentChange={this.handleComponentChange}/>
            <br/>
            <hr/>
            <AddressInfo id="reg_address" entity="lender" title="Registered Address" data={this.state.reg_address} mode={this.props.mode} 
                    onComponentChange={this.handleComponentChange} onComponentReset={this.handleComponentReset}/>
            <br/>
            <hr/>
            </div>
            <Person id="contact_person" entity="lender" title="Contact Person" data={this.state.contact_person} 
                    mode={this.props.mode} 
                    onComponentChange={this.handleComponentChange}/>
            <br/>

            <input type="button" className={`btn btn-primary ${this.props.mode === "create" ? "show_button": "hide_button"}`} onClick={this.handleSubmit} value="Create New Lender"/>
          </div>

        </form>  
        <br/><br/>
      </div>

      );
  }
}

export default  withRouter(LenderContainer) ;
