import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import { Modal , Button} from 'react-bootstrap';
import moment from 'moment';
import {handleTransactionID, disburseLoan, getDisburseAttempt, release_loan} from '../../../actions/loan_actions';
import {getAppUsers} from '../../../actions/common_actions';

import '../../../styles/flow.css';
import {lbl_amt} from '../../../helpers/product_helper';
import {rt_algn, format_month_change} from '../../../helpers/common_helper';
//import {check_priv} from '../../../helpers/storage_helper';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

class CaptureFailedDisbursal extends FlowComponent {

state = {
    modalBox: false,
    disable: "disable_div",
    disbursal_txn: {},
    capture_btn: 'disable_div',
    txn_data : '',
}
attempt = {}

componentWillMount(){

  const request = {'loan_doc_id' : this.props.data.loan_doc_id}
  getDisburseAttempt(this.req(request)).then((response) => {
      this.resp(response)
      this.attempt = response.data
  })
}

handleDisburseClick = (event) => {

  const lender_code = this.props.data.lender_code;
  const network_prvdr_code = this.props.data.acc_prvdr_code;
  const status = "enabled";
  const cust_id = this.props.data.cust_id;

  const lender_account = {lender_code, network_prvdr_code, acc_purpose : "disbursement", status, cust_id};

  this.setOptionsListToStateFromAppApi("common","lender_accounts", this.req({lender_account}), ["account_id", "acc_num_disp_txt"]);

  this.disbursal_txn = {amount: this.attempt.disbursed_amount, loan_doc_id: this.props.data.loan_doc_id, from_ac_id: this.attempt.from_acc_id,
                        to_ac_id: this.attempt.to_acc_id, txn_date: moment(this.props.data.attempt_time).format("YYYY-MM-DD") };
  this.setState({modalBox: true});

}

handleClose = (event) => {

  this.setState({ modalBox: false });

}

handleTxnId(){

  let txn_id = this.disbursal_txn.txn_id;

  if(txn_id.length > 1){

    handleTransactionID(this.req({'loan_doc_id' : this.disbursal_txn.loan_doc_id, 'acc_id' : this.disbursal_txn.from_ac_id, 'txn_id' : txn_id, 'mode' : 'debit', 'txn_date' : this.disbursal_txn.txn_date}, true))
    .then((response) => {
      this.resp(response);
      if(!response){return };

        if(response.status === "success"){
            this.setState({capture_btn : 'disable_div', txn_data :'', txn_failed_msg: ''});

            if(response.data){
              if(response.data.hasOwnProperty('ignore_check')){
                this.setState({capture_btn : ''});
              }
              else if(response.data.hasOwnProperty('message')){
                this.setState({txn_failed_msg : response.data.message});
              }
              else if(response.data.total_amount == this.disbursal_txn.amount){
                this.setState({txn_data : response.data.acc_stmt_records, capture_btn : ''});
              }
              else if(response.data.total_amount != this.disbursal_txn.amount){
                this.setState({txn_data : response.data.acc_stmt_records});      
              }

            }
            else{
              this.setState({txn_failed_msg : 'Please check the transaction ID. No payment received to the account with this transaction ID.'});
            }

        }else{
          alert(response.message + "\n" + JSON.stringify(response.data));  
        }
      }
        );

      }
    }

enableTxnID = (event) => {

  const disbursal_txn = this.disbursal_txn;

  if(disbursal_txn.amount > 0 && disbursal_txn.from_ac_id != null && disbursal_txn.txn_date != null){
    this.setState({txn_id_style :'' });
  }

}

handleSubmit = (event)=>{

    event.preventDefault();

    if(!window.confirm("Have you verified in the Data Provider's A/C statement if you could locate debit transaction(s) on " + format_month_change(this.disbursal_txn.txn_date) + " for " + this.attempt.disbursed_amount + " " + this.currency_code + " related to this FA?")){return}

     

        const disbursal_txn = this.disbursal_txn;
        disbursal_txn.send_sms = true;
        disbursal_txn.txn_exec_by = 1;
        disbursal_txn.txn_mode = "instant_disbursal";
        disbursal_txn.capture_only = true;
        
        disburseLoan(this.req({"disbursal_txn":disbursal_txn}))
          .then((response) => {
            this.resp(response)
            if(!response){return };
            if(response.status === "success")
            {
              this.setState({ modalBox: false });
              window.location.reload();
            }
            else{
              alert(response.message + "\n" + JSON.stringify(response.data));
            }
          }
        );
}


  render(){

    return (
    <div className='text-center' >
        <div>
            <input type="button" className="btn btn-primary btn-std" value="Capture Disbursal" onClick={this.handleDisburseClick} />
        </div>
        <Modal show={this.state.modalBox} onHide={this.handleClose} size="xl">
              <Modal.Header closeButton show={this.state.modalBox} onHide={this.handleClose}>
                  <Modal.Title>FA Disbursal</Modal.Title>
              </Modal.Header>
              <Modal.Body>
               <div className="container">
                  <div className="row">

                    <div className="col-md-6">
                      <h4>Float Advance ID: {this.props.data.loan_doc_id} </h4>
                    </div>
                    <div className="col-md-6">
                      <h4>Float Advance Amount: {rt_algn(lbl_amt(this.attempt.disbursed_amount, this.currency_code))} </h4>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-5">
                          <label>Disburse From A/C<br/><font className="text-danger">   *  </font></label>
                        </div>
                        <div className="col-md-7">
                        <div className={`${this.state.disable}`}>
                        <select type="text" id="amount" onChange={this.handleSelfChange} value={this.attempt.from_acc_id} className="form-control">
                        <option value={this.attempt.from_acc_id} selected="true">{this.attempt.from_acc_id} - {this.attempt.from_acc_num}</option>
                        </select>
                        </div>
                        </div>
                      </div>
                      <br/>

                      <div className="row">
                        <div className="col-md-5">
                          <label>Amount Disbursed</label><font className="text-danger">   *  </font>
                        </div>
                        <div className="col-md-7">
                           <div id="" className={`${this.state.disable}`} >
                          <input type="text" id="amount" onChange={this.handleSelfChange} value={this.attempt.disbursed_amount} className="form-control" />
                          </div>
                        </div>
                      </div>
                      <br/>

                      <div className="row">
                        <div className="col-md-5">
                          <label>Disbursal Transaction ID</label><font className="text-danger">   *  </font>
                        </div>
                        <div className="col-md-7">
                          <input type="text" id="txn_id" onChange={(e) => { this.handleSelfChange(e);this.handleTxnId(e)}} className="form-control spacing" tabIndex = '-1' required="required"/>
                        </div>
                      </div>
                      <br/>

                        
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-5">
                          <label>Customer's A/C</label><font className="text-danger">   *  </font>
                        </div>
                        <div className="col-md-7">
                         <div className={`${this.state.disable}`}>
                         <select type="text" id="amount" onChange={this.handleSelfChange} value={this.attempt.from_acc_id} className="form-control">
                        <option value={this.attempt.to_acc_id} selected={true}>{this.attempt.to_acc_id} - {this.attempt.to_acc_num}</option>
                        </select>
                        </div>
                        </div>
                      </div>
                      <br/>

                      <div className="row">
                        <div className="col-md-5">
                          <label>Disbursal Date</label><font className="text-danger">   *  </font>
                        </div>
                        <div className="col-md-7">

                            <DatePicker defaultValue={format_month_change(this.props.data.attempt_time)} closeOnSelect={true} onChange={(value) => {this.handleSelfDateChange("txn_date", value);this.enableTxnID(value)}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
                          </div>
                      </div>
                      <br/>



                      <div className="row">
                        <div className="col-md-5">
                          <label>Remarks</label>
                        </div>
                        <div className="col-md-7">
                          <input type="text" id="remarks" onChange={this.handleSelfChange} className="form-control" required="required"/>
                        </div>
                      </div>
                      <br/>
                    </div>
                  </div>
                  <br/>
                      <div className="row">
                        {this.state.txn_data &&
                          <>
                            <br/>
                            <div class="alert alert-success">
                            {this.state.txn_data.map((data)  =>
                                <h6>Amount : {data.dr_amt} | Txn Date : {data.stmt_txn_date} | Description : {data.descr}</h6>
                              )
                            }
                            </div>
                          </>
                        }
                        {this.state.txn_failed_msg &&
                          <div class="alert alert-danger">
                            <h6>{this.state.txn_failed_msg}</h6>
                          </div>
                        }
                      </div>
                      <br/>

                </div>
              </Modal.Body>

              <Modal.Footer>

                <Button variant="secondary" onClick={this.handleClose}>Cancel</Button>
                 <input type="button" className={`btn btn-primary ${this.state.capture_btn}`} onClick={this.handleSubmit} value="Submit" disabled={this.state.calling_api}/>
                
              </Modal.Footer>
          </Modal>
    </div>

      );
  }
}
export default CaptureFailedDisbursal;
