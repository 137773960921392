import React from 'react';
import { } from 'react-router-dom';
import '../../../styles/flow.css';
import {viewAccount, updateAccount} from '../../../actions/account_actions';
import FlowComponent from '../../common/component/core/FlowComponent';
import AccountContainer from '../container/account_container';
import {dd_parent,getMasterData} from "../../../actions/common_actions";
import AccPurposeMultiSelect from '../../../helpers/accPurposemulti';
import { Homeacc_prvdrLogoselector } from '../../../helpers/common_helper';
import {Form} from 'react-bootstrap';
import {viewBorrower} from '../../../actions/borrower_actions';
import DatePicker from 'react-datetime';
import {check_priv} from '../../../helpers/storage_helper';
import FileUpload from '../../common/component/FileUpload';
import moment from "moment";
import {Button,Modal,Card} from 'react-bootstrap';
import DisplayImage from "../../common/component/DisplayImage";
import {capitalize_first_letter,format_date_tbl} from "../../../helpers/common_helper";



class EditAccount extends FlowComponent{
  state = {
    edit_account : {},
    acc_purpose_dd: {}, 
    initialAct_purpose:[],
    div_style : 'hide_div',
    person_div : 'hide_div',
    textract_data : {},
    refresh_btn : 'show_div',
    entity : "accounts",
    preview_modal : false,
    opacity :""
  }

componentWillMount(){ 

    this.getSelectOptionsFromResult(dd_parent('account_purpose', 'customer'), 'acc_purpose', ['data_code', 'data_value'])
	const request = {"account_id": this.props.account_id};
	viewAccount(this.req(request))
         .then((response) => {
             this.resp(response)
          if(!response){return };
              if(response.status === "success"){
              	
                this.setState({edit_account: response.data.account});
                this.setState({initialAct_purpose:response.data.account.acc_purpose})                
              }else{
                alert(response.message + "\n" + JSON.stringify(response.data));  
              }
            }
          ); 
}

handleTextract = (event) => {

  var textract_data = this.state.textract_data

  textract_data = Object.assign(textract_data ,event)
 
  textract_data.gender = (textract_data.gender && textract_data.gender == "F") ? "female" : "male"

  textract_data.type = 'third_party_owner'

  this.setState({textract_data: textract_data, person_div :'disable_div',refresh_btn : 'disable_div'})
}


handleFile = (id , file_name, action = null) => {

  var textract_data = this.state.textract_data
  var person_div = this.state.person_div
  var refresh_btn = this.state.refresh_btn
  var entity = this.state.entity

  if(action == "remove"){
    delete textract_data[id]
    if(id =='photo_national_id'){
      person_div = 'hide_div'
      refresh_btn = 'show_div'
      entity = 'accounts'
      textract_data.first_name = textract_data.last_name = textract_data.dob = textract_data.gender = textract_data.national_id =  ""
    }
   
  }else{
    textract_data[id] = file_name
    refresh_btn = 'disable_div'

  }

  this.setState({textract_data, person_div : person_div,refresh_btn : refresh_btn, entity : entity});

}

handleDateChange = (date) => {

  const value = moment(date).format("YYYY-MM-DD")
  var textract_data = this.state.textract_data
  textract_data["dob"] = value
  this.setState({textract_data});

}

handleRefresh = (event) => {

    var cust_id = this.state.edit_account.cust_id;
    var textract_data = this.state.textract_data
    const request = {cust_id: cust_id};
    viewBorrower(this.req(request))
      .then((response) => {
        this.resp(response)
        if(!response){return };
        if(response.data.allow_tp_ac_owner_manual_id_capture){
          alert("Biz Ops (App support) has now allowed you to enter the National ID details manually.")
          textract_data.first_name = textract_data.last_name = textract_data.dob = textract_data.gender = textract_data.national_id =  ""
          this.setState({entity : "",refresh_btn : "disable_div", person_div : 'show_div', allow_manual_capture : response.data.allow_tp_ac_owner_manual_id_capture , textract_data: textract_data})
        }else{
            alert("Biz Ops (App support) still not allowed you to enter the National ID details manually. \n Please wait until Biz Ops (App support) approve your request.")
        }
    });

}
  

handleSave = (event, btn_name =null) => {
  var is_update = true
  const account = this.state.edit_account
  if(account.acc_purpose == 'float_advance' && !account.assessment_type){
      alert("Select Assessment Type for Float Advance account")
      return
  }
  
  var person = this.state.textract_data  


  if(this.state.third_party_owner && (!person.photo_national_id || !person.photo_national_id_back || !person.photo_consent_letter)){
    alert("Please upload required photos")
    
    return
  }else if(this.state.third_party_owner && (!person.first_name || !person.last_name ||!person.dob || !person.gender)){
    alert("Please enter required fields")
    return
  }

  if(this.state.third_party_owner && btn_name != "submit"){
    this.setState({preview_modal :true, opacity :0.2})
    return
  }

  const request = {"account": this.state.edit_account, 'person' : person};

  updateAccount(this.req(request))
         .then((response) => {
             this.resp(response)
          if(!response){return };
              if(response.status === "success"){
                
                alert(response.message);
                this.props.handleBackClick()
                
              }else{
                alert(response.message + "\n" + JSON.stringify(response.data));  
              }
            }
          ); 
  
}

handleBackBtn = (event) => {
  this.props.handleBackClick(event);
}

handlePersonData = (event) =>{
  var textract_data = this.state.textract_data
  textract_data[event.target.id] = event.target.value
  this.setState({textract_data});
}

handleOwnerCheckboxClick = (event) => {
    var div_style = ""
    var third_party_owner = false
    if(event.target.checked){
        div_style = "show_div"
        third_party_owner = true
    }else{
        div_style = "hide_div";
        third_party_owner = false

    }

  this.setState({textract_data :{}, div_style : div_style , third_party_owner : third_party_owner, person_div : 'hide_div' , refresh_btn : 'show_div'})
}

handlePreviewModalClose = (event) =>{
  this.setState({preview_modal:false, opacity :""})
}

ViewPreviewModal = (data)=>{
           
  return(    
    <Modal show={this.state.preview_modal} onHide={this.handlePreviewModalClose} backdrop="static" size="xl" style={{backgroundColor: "#1a2035 !important", marginTop: "30px"}}>
      <Modal.Header closeButton className="modal-close-btn" show={this.state.pdf_modal} onHide={this.handlePreviewModalClose}>
      </Modal.Header>
      <Modal.Body>
            <Card style = {{width:"95%"}}>
            <Card.Header style = {{backgroundColor: "#01062c" , color: "white"}}><h4 style = {{fontSize : '1.7rem'}} className="no-margin">Third Party A/C Owner </h4></Card.Header>

                <Card.Body className='no-padding'>
                  <div className = "row" style={{ marginRight:0,marginLeft:0,marginBottom:10}}>
                      <div className="col-md-3">                                       
                          <p class="no-margin viewCus_label">First Name</p>
                          <p class="no-margin viewCus_labelVal">{data.first_name}</p>
                      </div>
                      <div className="col-md-3">                                       
                          <p class="no-margin viewCus_label">Last Name</p>
                          <p class="no-margin viewCus_labelVal">{data.last_name}</p>
                      </div>
                      <div className="col-md-3">                                       
                          <p class="no-margin viewCus_label">DOB</p>
                          <p class="no-margin viewCus_labelVal">{format_date_tbl(data.dob)}</p>
                      </div>
                      <div className="col-md-3">                                       
                          <p class="no-margin viewCus_label">Gender</p>
                          <p class="no-margin viewCus_labelVal">{capitalize_first_letter(data.gender)}</p>
                      </div>
                      <div className="col-md-3">                                       
                          <p class="no-margin viewCus_label">National ID</p>
                          <p class="no-margin viewCus_labelVal">{data.national_id}</p>
                      </div>                                                                                                                                    
                  </div>
                  <div className='row no-margin justify-content-center' style = {{marginBottom : "10px !important"}}>

                      <div className='col-md-3'>
                          <div className='d-flex align-items-center justify-content-around pAlign_mid'>                                    
                              <p class="no-margin viewCus_label no-padding">National ID Photo</p>
                              <DisplayImage className="img-fluid" image_path="/files//UGA/persons/tmp/photo_national_id" file_name={data.photo_national_id}></DisplayImage>
                          </div>
                      </div>
                      <div className='col-md-3'>
                          <div className='d-flex align-items-center justify-content-around pAlign_mid'>                                    
                              <p class="no-margin viewCus_label no-padding">National ID Back Photo</p>
                              <DisplayImage className="img-fluid" image_path="/files//UGA/persons/tmp/photo_national_id_back" file_name={data.photo_national_id_back}></DisplayImage>
                          </div>
                      </div>
                      <div className='col-md-3'>
                          <div className='d-flex align-items-center justify-content-around pAlign_mid'>                                    
                              <p class="no-margin viewCus_label no-padding">Consent Letter Photo</p>
                              <DisplayImage className="img-fluid" image_path="/files//UGA/borrowers/tmp/photo_consent_letter" file_name={data.photo_consent_letter}></DisplayImage>
                          </div>
                      </div>
                  </div>
              </Card.Body>
            </Card>
          </Modal.Body>
           <Modal.Footer className='justify-content-center'>
              <Button variant="primary" onClick = {this.handlePreviewModalClose}> Modify </Button>
              <Button variant="success" style={{marginLeft:20}} disabled = {this.state.calling_api} onClick = {(e) => {this.handleSave(e, "submit")}}> Submit </Button>
           </Modal.Footer>
        </Modal> 
       
  )
}

render(){
  if(this.state.toList){
    return(
      <AccountContainer entity={this.props.entity} entity_id={this.props.entity_id}/>
      
      );
  }
  return(
    <>
    {this.ViewPreviewModal(this.state.textract_data)}


      <div className="text-labelblue" style={{opacity:this.state.opacity}}>
        <div className="row">
            <div className="col-md-3">
              <span><b>Account Provider</b></span>&nbsp;&nbsp;&nbsp;
            </div>
            <div className="col-md-3">              
              <Homeacc_prvdrLogoselector logotype={this.state.edit_account.acc_prvdr_code}/>
              {/* <label>{this.state.edit_account.acc_prvdr_name}img</label> */}
            </div>
         
        </div>
        <div className="row">
          <div className="col-md-3">
            <span><b>Account Number</b></span>&nbsp;&nbsp;&nbsp;
          </div>
          <div className="col-md-3">
            <label>{this.state.edit_account.acc_number}</label>
          </div>
        </div>
        <br/><br/>

        

        <div className="row d-flex justify-content-between">
          {this.state.edit_account.branch &&
            <div className="col-md-3">
                <span>Branch</span><font className="text-danger"> </font>
                <select id="branch"  onChange={this.setChangeToState} className="form-control" type="text" required="required">
                  {this.getSelectOptions(this.state.edit_account.acc_prvdr_code+"_branches")}
                </select>
            </div>
          }
            <div className="col-md-3">            
              <span>Account Holder Name</span><font className="text-danger"> </font>
              <input type="text" id="holder_name" value={this.state.edit_account.holder_name} onChange={this.setChangeToState} className="form-control" required />
            </div>
            <div className="col-md-3">
              {/*<label>Balance</label>
              <input type="text" id="balance" value={this.state.edit_account.balance} onChange={this.setChangeToState} className="form-control" />*/}

              {/* <span>Account Purpose</span>
              <select id="acc_purpose" value={this.state.edit_account.acc_purpose} onChange={this.setChangeToState} className="form-control">
                {this.state.acc_purpose_dd}                            
              </select> */}                              
              <AccPurposeMultiSelect id="acc_purpose" onChange={this.setChangeToState} acc_purposes={this.state.acc_purpose_dd} initialAct_purpose={this.state.initialAct_purpose} must={false}/>                    
            </div>          
  
            {this.state.initialAct_purpose.includes("float_advance") &&

            <div className="col-md-3">
              <span>Assess By</span>
              <font className="text-danger"> </font>

              <select
                id="assessment_type"
                value={this.state.edit_account.assessment_type}
                onChange={this.setChangeToState}
                className="form-control"
                required
              >
                  <option value="">choose</option>
                  <option value='self'>Self Assessment</option>
              </select>
            </div>
          }

          <div className="col-md-3">
              <span>Alternate Account Number</span>
              <font className="text-danger"> </font>

              <input className="form-control" id="alt_acc_num" value={this.state.edit_account.alt_acc_num} onChange={this.setChangeToState} required></input>
          </div>
          {/* <Form.Row className='my-4'>
                          <div className="col-3" style={{color:"white"}}>
                              <span style={{color:"white"}}>Search Text</span>
                              <Form.Control id="search_text" onChange={this.handleChange}></Form.Control>  
                          </div>
            </Form.Row> */}


        
        </div>
      <div className='row'>
       {/* // {check_priv('customer', 'create_account') && this.state.edit_account.tp_acc_owner_id == null && */}
        {/* //  <div className = "col-md-4" style={{marginTop:'15px'}}> */}

        {check_priv('account', 'third_party_owner') && this.state.edit_account.tp_acc_owner_id == null && this.state.edit_account.acc_prvdr_code == 'UMTN' &&

          <div className='my-3 ml-4'>

            <div className="form-check">
              <label className="form-check-label no-margin">
                <input className="form-check-input" type="checkbox" onChange={(e) => {this.handleOwnerCheckboxClick(e)}}  id="do_not_have_score" /> Third Party A/C Owner
                <span className="form-check-sign">
                <span className="check"></span>
                </span>
              </label>
            </div>
          </div>
        }
      </div>



          
        
      <div className={this.state.div_style}>

        <Card className = "my-2" >
        <Card.Header style = {{backgroundColor: "#01062c" , color: "white"}}> <h5 className='no-margin' style={{fontSize : "20px"}}>Third Party A/C Owner <div className={`${this.state.refresh_btn} float-right`}><input type="button" value="Refresh" className="btn btn-primart float-right" onClick={this.handleRefresh} disabled={this.state.calling_api} />&nbsp;&nbsp;&nbsp;</div></h5>
        </Card.Header>

        <Card.Body>
        <div className="row no-margin labelTopgap align-items-center">
          <div className="col-md-6">
            <FileUpload for = "third_party_owner" cust_id = {this.state.edit_account.cust_id} entity = {this.state.entity} onSaveFile ={this.handleFile} id="photo_national_id" label="National ID Photo" type="image" person_type = "third_party_owner"  onTextract ={(e) => {this.handleTextract(e)}} onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e, "reload")}} onCreateUpload ={ (e)=>{this.handlePersonData(e)}}/>
          </div>
          <div className="col-md-6">
            <FileUpload for = "third_party_owner" id="photo_national_id_back"  entity ='accounts' label="National ID Back Photo" type="image"  
            onSaveFile ={this.handleFile} onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e, "reload")}} onCreateUpload={this.handlePersonData}/>
          
          </div>
        </div>
        <br/>
        <br/>
        <div className="row no-margin labelTopgap align-items-center">
          <div className="col-md-6">
            <FileUpload for = "third_party_owner"  id="photo_consent_letter" entity ='accounts' label="Consent Letter Photo" type="image"             
            onSaveFile ={this.handleFile} onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e, "reload")}} onCreateUpload={this.setChangeToState}/>
          </div>
        </div>
        <br/>
        <br/>

        <div className={this.state.person_div}>
          <div className="row no-margin labelTopgap align-items-end">
                  <div className="col-md-3">
                    <span>First Name</span><font className="text-danger">   *  </font>
                    <input id="first_name" autocomplete="off" value={this.state.textract_data.first_name}   onChange={(e) => {this.handlePersonData(e)}} type="text" className="upperCase form-control" required="required"/>
                  </div>
                  <div className="col-md-3">
                    <span>Last Name</span><font className="text-danger">   *  </font>
                    <input id="last_name" autocomplete="off" value={this.state.textract_data.last_name}   onChange={(e) => {this.handlePersonData(e)}} className="form-control" type="text" />
                  </div>
                  <div className="col-md-3">
                    <span>Date of birth</span><font className="text-danger">   *  </font>
                      <DatePicker id = "dob" value={this.state.textract_data.dob}   closeOnSelect={true} onChange={(value) => {this.handleDateChange(value, "dob")}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
                  </div>
                  
          </div>  
          <br/> 
      
          <div className="row no-margin labelTopgap align-items-end">             
            <div className="col-md-3">
                <span>Gender</span><font className="text-danger">   *  </font>
                  <select id="gender" value={this.state.textract_data.gender ? this.state.textract_data.gender : "" }    onChange={(e) => {this.handlePersonData(e)}} className="form-control" type="text" >
                  <option value="">Choose</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
            </div>
            <div className="col-md-3">
                <span>National ID</span><font className="text-danger">   *  </font>
                <input type="text" autocomplete="off" id="national_id" value={this.state.textract_data.national_id}  onChange={(e) => {this.handlePersonData(e)}} className="form-control" required="required"/>
            </div>
          </div>  
          <br/>     
            
          

          </div>
          </Card.Body>
          </Card>
          </div>
            
          <div className="">
              <br/>
              
              <input type="button" value={this.state.third_party_owner ? "Preview" : "Save"} className="btn btn-success" onClick={this.handleSave} disabled={this.state.calling_api} />&nbsp;&nbsp;&nbsp;
              <input type="button" value="Back" className="btn btn-primary" onClick={this.handleBackBtn} />
          </div>
          <br/>


 
      </div>
        
        </>
    );
 
}

}

export default EditAccount;
