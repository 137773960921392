import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import '../../../styles/flow.css';
import {getAccountTxns} from '../../../actions/account_txn_actions';
import FlowContainer from '../../common/container/core/FlowContainer';
import {AccountTransaction} from '../component/AccountTransaction';
import Transaction from '../../account/component/Transaction';
import {dd_value} from '../../../actions/common_actions';
import {rt_algn,lbl_amt} from '../../../helpers/common_helper';
import {Button,Badge } from 'react-bootstrap';
import DatePicker from 'react-datetime';
import {format_date_tbl} from '../../../helpers/common_helper';
import $ from 'jquery';
import withRouter from '../../../Router/Withrouter';
import { BasicReactTable } from '../../../helpers/react_table';

class ListAccountTxnContainer extends FlowContainer {
	state = {
		accountTxnList: null,
    transaction:{},
    account_result:"hide_div",
    columns:[],
    row_data:[]
	}

  componentWillMount()
  {
   this.acc_id = this.props.params.acc_id;
  }

  handleTransactionSearch = (event)=>{
    var prev_table = $('#transaction').DataTable();
    prev_table.destroy();
   var account_txn = this.state.transaction;
   account_txn.country_code = this.country_code;
   account_txn.acc_id =  this.acc_id;
   getAccountTxns(this.req({account_txn}))
  .then((response) => {
      if(!response){return };
      if(response.status === "success"){
        this.setState(state=>state.columns = this.columnData())
        this.setState(state=>state.row_data = response.data)
        this.setState({account_result:"show_div"});
      //  const accountTxnList = response.data.map((account_txn,i)=>
      //       <tr key={i}>
      //       <td>{++i}</td>

      //       <td > {dd_value(account_txn.acc_txn_type)}</td>
      //       <td > {dd_value(account_txn.txn_mode)}</td>
      //       <td > {account_txn.ref_acc_txt}</td>
      //       <td > {rt_algn(lbl_amt(account_txn.credit, this.currency_code))}</td>
      //       <td > {rt_algn(lbl_amt(account_txn.debit, this.currency_code))}</td>
      //       <td > {rt_algn(lbl_amt(account_txn.balance, this.currency_code))}</td>
      //       <td > {account_txn.txn_id}</td>
      //       <td > {account_txn.txn_exec_by}</td>
      //       <td > {format_date_tbl(account_txn.txn_date)}</td>
      //       <td > {account_txn.remarks}</td>

      //       </tr>
      //     );
      
      //     this.setState({accountTxnList,account_result:"show_div"});
      //        $('#transaction').removeAttr('width').dataTable({
      //           "order" : [],
      //               "columns": [
      //                 {"width" : "10%"}, 
      //                 {"width": "10%"},
      //                 {"width": "10%"},
      //                 {"width": "10%"},
      //                 {"width": "10%"},
      //                 {"width": "10%"},
      //                 {"width": "10%"},
      //                 {"width": "10%"},
      //                 {"width": "10%"},
      //                 {"width": "10%"},
      //                 {"width": "10%"}
      //               ],
      //               dom:'Bfrtip',
      //               buttons: [
      //               'csv','excel','print'
      //               ]
      //               });        
      }else{
        alert(response.message + "\n" + JSON.stringify(response.data));  
      }
    }
  );
  }

  columnData () {
    return [
      {
        Header : "SI No.", 
        id: "row",     
        accessor:"row"   ,    
        Cell: ({row}) => {
          return <div>{cell.row.index+1}</div>;
        }
      },
      {
        Header : "Transaction Type",
        accessor:"acc_txn_type"   ,    

        id: 'acc_txn_type',
        Cell: cell => {return dd_value(cell.row.original.acc_txn_type)}
      },
      {
        Header : "Transaction Mode",
        id: 'txn_mode',
        accessor:"txn_mode"   ,    

        Cell: cell => {return dd_value(cell.row.original.txn_mode)}
      },
      {
        Header : "Ref Account ID",
        id: 'loan_doc_id',
        accessor:"loan_doc_id"   ,    

        Cell: cell => {return cell.row.original.ref_acc_txt}
      },
      {
        Header : "Credit",
        id: 'credit',
        accessor:"credit"   ,    

        Cell: cell => {return rt_algn(lbl_amt(cell.row.original.credit, this.currency_code))}
      },
      {
        Header : "Debit",
        id: 'debit',
        accessor:""   ,    

        Cell: cell => {return rt_algn(lbl_amt(cell.row.original.debit, this.currency_code))}
      },
      {
        Header : "Balance",
        id: 'balance',
        accessor:"balance"   ,    

        Cell: cell => {return rt_algn(lbl_amt(cell.row.original.balance, this.currency_code))}
      },
      {
        Header : "Transaction ID",
        id: 'txn_id',
        accessor:"txn_id"   ,    

        Cell: cell => {return cell.row.original.txn_id}
      },
      {
        Header : "Txn Executed By",
        id: 'txn_exec_by',
        accessor:"txn_exec_by"   ,    

        Cell: cell => {return cell.row.original.txn_exec_by}
      },
      {
        Header : "Transaction Date",
        id: 'txn_date',
        accessor:"txn_date"   ,    

        Cell: cell => {return format_date_tbl(cell.row.original.txn_date)}
      },
      {
        Header : "Remarks",
        id: 'remarks',
        accessor:"remarks"   ,    

        Cell: cell => {return cell.row.original.remarks}
      }
    ]
  }

render(){
    return( 
        <div id="" className='container containerTopmargin max-width no-padding'>
          <div className="">
          <div className="row align-items-center">
              <div className="col-md-10 no-padding">
                <Transaction id="transaction" onComponentChange={this.handleComponentChange} />
              </div>
              <div className="col-md-2 no-padding">
                <Button className="btn btn-primary no-margin"  onClick={this.handleTransactionSearch}>Search</Button>
              </div>
         </div>
         </div>
    		<div>
    		<br/>
    		<div className="row" id="float_style">
    			{/*<AccountTransaction id="acc_txn" acc_id={this.acc_id} entity={this.entity} entity_code={this.entity_code} balance={this.balance} />*/}
    		</div>

        <div className={this.state.account_result}>
	    	<div id='datatable account_txn'>
	    		<h4>Account Transactions</h4> 
	             {/* <table id ="transaction" className="table">
	                      <thead className={"text-info"}>
	                      <tr>
	                      <th>S.No</th>
	                      <th></th>
	                      <th></th>
	                      <th></th>
	                      <th></th>
	                      <th></th> 
	                      <th></th>
	                      <th></th>
	                      <th></th> 
	                      <th></th> 
	                      <th></th>                  
	                      </tr></thead>
	                      <tbody> 
	                      {this.state.accountTxnList}
	                      </tbody>
                        
	             </table>           */}


            <BasicReactTable row_data={this.state.row_data !== undefined ? this.state.row_data : []} column_data={this.state.columns} csv_file={true} FName="Account TXN Lists"  pagination={true}  default_page_size={10} /> 

         
	        </div>
          </div>
          </div>
	        </div>
      );            
  }
}
export default withRouter(ListAccountTxnContainer) ;