import React, { Component } from "react";
import {userService} from "./../../../actions/auth_actions";
// import {withRouter}  from  'react-router-dom';
import {Navigate} from 'react-router-dom';

import {storageChange} from "./../../../helpers/storage_helper";

class Logout extends Component {
  constructor() {
      super();
       userService.logout()
       setTimeout(() => {
          window.location.reload();
        }, 1000);     
  }

     render() {
      return (
      <Navigate to={{ pathname: '/login' }} />
      );
     }
  }
export default Logout;