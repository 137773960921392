import { get_path } from '../helpers/common_helper';
import {call_fetch} from './../helpers/fetch_helper';

export const listMarket = request => {
  return call_fetch(get_path('admin') + '/market/list', request);
}

export const createMarket = request => {
  return call_fetch(get_path('admin') + '/market',request)
}

export const viewMarket = request => {
 return call_fetch(get_path('admin') + '/market/view',request)	
}

/*export const updateMarket = request => {
 return call_fetch(get_path('admin') + '/market/update',request)
}*/