import FlowContainer from '../../common/container/core/FlowContainer';
import React from 'react';
import ManualCaptureReports from '../component/ManualCaptureReports';
import '../../../styles/flow.css';
import {Link} from "react-router-dom";
import {list_mnl_cap_rep} from '../../../actions/loan_actions';
import {format_date,title_case,lbl_amt} from '../../../helpers/common_helper';
import CommonReactTable from '../../../helpers/commonReact_table';
import { BasicReactTable } from '../../../helpers/react_table';


class ListManualCaptureTxns extends FlowContainer {
    state = {
        manl_capture_search:{},
        columndatas:[],
        copy:[],
        rowdatas:[]

    }

    validate_search(manl_capture_search){
      if((manl_capture_search.txn_date__from && manl_capture_search.txn_date__to )||(manl_capture_search.txn_id ) || (manl_capture_search.loan_doc_id)){
        return true
      }else{
        alert("Please enter valid search criteria")
        return false
      }
    }

    handleTransactionlist = (event) => { 
     
      const manl_capture_search = this.state.manl_capture_search;  
      var is_valid = this.validate_search(manl_capture_search)

      if(is_valid ){
          list_mnl_cap_rep(this.req({manl_capture_search}))
          .then((response) => {
            this.resp(response)
            
            if(!response){return null;}

            if(response.status === "success"){
              if(response.data != ""){
                this.setState(state=>state.rowdatas = response.data)
                this.setState(state=>state.copy = response.data)
                this.setState(state=>state.columndatas=this.columndatas())    
              }else{
                alert("No record found for your search")
              }                       
            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          });
        }
      }   
      

    columndatas(){
        return[
          {
            Header : "SI No.", 
            id: "row",		      
            Cell: (cell) => {
              return <div> {cell.row.index+1}</div>;
            },
            width : 100
          },
          {
            Header : "FA ID",
            id: "loan_doc_id",
            accessor: row => <div>
                                <Link className='d-block' to={"/fa/view/"+row.loan_doc_id} target="_blank">{row.loan_doc_id}</Link>
                              </div>,
            width : 250

          },
          {
            Header : "Amount",
            id: "amount",
            accessor:row => {return (lbl_amt(row.amount, this.currency_code))},
            width : 200
            
          },
          {
            Header : "Txn Type",
            id: "txn_type",
            accessor:row => {return (title_case(row.txn_type))},
            width : 210 

          },
          {
            Header : "Txn ID",
            accessor: "txn_id",
            width : 350
           
          },
          // {
          //   Header : "Txn Mode",
          //   id: "txn_mode",
          //   maxWidth:100,
          //   accessor:row => {return (title_case(row.txn_mode))} 
          // },
          {
            Header : "Txn Exec By",
            accessor: "txn_exec_by",
            width : 250
            

          },
          {
            Header : "Txn Date",
            id: "txn_date",
            accessor:row => {return (format_date(row.txn_date))},
            width : 200
          },
          {
            Header : "Reason For Manual Capture",
            id: "reason_for_skip",
           
            accessor:row => {return ( row.reason_for_skip == null ? 'Auto Capture Failed' : title_case(row.reason_for_skip))} ,
            width : 250         
          },
        ]
      }

    render()
    {
        return (
            <div id="" className='container containerTopmargin max-width no-padding'>
  		
  		<div>
  			<div className="form-row no-margin">
  				<h5 className="lender_accounts no-margin headerTitle headerPadvert">Manual Capture Transactions</h5>
  			</div>
              <ManualCaptureReports
              id="manl_capture_search"
              onComponentChange={this.handleComponentChange}
              onlist={this.handleTransactionlist}
              disable_btn = {this.state.calling_api}
            />
        </div>

        <div className="container-fluid">
    		<br/>
    		<div className="row" id="float_style">
    		</div>
        </div>  
        <div>
        {this.state.rowdatas && this.state.rowdatas.length >0 &&
          <>
             <h4>Manual Capture Transactions Details</h4> 
             <div className='d-flex justify-content-between align-items-center' style={{padding:5}}>
             </div>  
             <div className="card-body m-auto" style={{width: '90%'}}>   
                {/* <CommonReactTable showPag={true} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} resizable={false} defaultSorted={[{id:"stmt_txn_date",desc:false}]} exportCSV={true} csvFile={"Manual_capture_reports"} csvData={this.state.copy} searchData={[{...this.state.manl_capture_search,title:'Manual Capture Transactions'}]}/>     */}

                <BasicReactTable row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} FName={"Manual_capture_reports"} csv_file={true} pagination={true} default_page_size = {15}   searchData={[{...this.state.manl_capture_search,title:'Manual Capture Transactions'}]}/> 

             </div>
          </>
        }
             </div>        
        </div>

        )
    }

}

export default ListManualCaptureTxns;