import { get_path } from '../helpers/common_helper';
import {call_fetch} from './../helpers/fetch_helper';

export const createLender = request  => {
   return call_fetch(get_path('admin') + '/lender', request);
}

export const viewLender = request  => {
   return call_fetch(get_path('admin') + '/lender/view', request);
}

export const listLender = request  => {
   return call_fetch(get_path('admin') + '/lender/list', request);
}
export const list_lender_txn_stmts = request => {
	return call_fetch(get_path('admin') + '/lender/account/stmt_search', request);
}
export const addAccStmt = request => {
	return call_fetch(get_path('admin') + '/lender/acc_stmt/add', request);
}
/*export const updateLender = request  => {
   return call_fetch(get_path('admin') + '/lender/update', request);
}*/