import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import '../../../styles/flow.css';
import {autofill} from '../../../helpers/common_helper';
import {Edit} from '../../common/partial';
//import cookie from 'react-cookies';
//import {updateRelationshipManager} from '../../../actions/relationship_manager_actions';


export class LoanProduct extends FlowComponent{
	  state = {
    comp_name: "loan_product_template",
    //max_loan_amount: null,
    //duration: null,
    //flow_fee: null,
    //flow_fee_duration: null
  }
  componentWillMount(){
    super.componentWillMount(); 
   }

handleFlowFeeTypeChange = changeEvent => {
  this.setState({
    flow_fee_type: changeEvent.target.value
  });
  this.handleChange(changeEvent);
};


handleFlowFeeDurationChange = changeEvent => {
  this.setState({
    flow_fee_duration : changeEvent.target.value
  });
  this.handleProdChange(changeEvent);
  //this.handleChange(changeEvent);
};

handleProdChange = (event) => {
if(this.props.id === "new_product"){
  
  const id = event.target.id;
  const value = event.target.value;
  this.setState({[id]: value}, () => {
   const product_name = autofill(this.state.max_loan_amount , this.state.duration, this.state.flow_fee, this.state.flow_fee_duration, this.currency_code);
  this.props.onProdChange(product_name);
  });
}
  this.handleChange(event);
  
}

render(){

      return (
     
             <div id="" className="col-md-12">
               <div className="card ">
                  <div className="card-header card-header-primary card-header-icon">
                    <h3 className="card-title">{this.props.title ? this.props.title : "Float Advance Product"}</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-5 form-group ">
                        <label for="max_loan_amount" className="md-label-floating"> Float Amount ( {this.currency_code} ) *</label>
                        <input id="max_loan_amount" value={this.props.data.max_loan_amount} onChange={this.handleProdChange} onClick={this.handleClick} className="form-control" type="text" required/>
                      </div>
                      <div className="col-md-5 form-group ">
                        <label for="duration" className="md-label-floating"> Repayment Duration *</label>
                        <select id="duration" value={this.props.data.duration}  onChange={this.handleProdChange} className="form-control" type="text" required>
                        <option value="">Choose</option>
                        <option value="1">1 day</option>
                        <option value="2">2 days</option>
                        <option value="3">3 days</option>
                        <option value="4">4 days</option>
                        <option value="5">5 days</option>
                        <option value="6">6 days</option>
                        <option value="7">7 days</option>
                        <option value="8">8 days</option>
                        <option value="9">9 days</option>
                        <option value="10">10 days</option> 
                      </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4 checkbox-radios">
                            <label className="md-label-floating"> Flow Fee Type *</label>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input className="form-check-input" type="radio" id="flow_fee_type" value="Flat" onChange={this.handleFlowFeeTypeChange} checked={this.props.data.flow_fee_type === "Flat"} /> Flat
                                <span className="circle">
                                  <span className="check"></span> 
                                </span>
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input className="form-check-input" type="radio" id="flow_fee_type" value="Percent" onChange={this.handleFlowFeeTypeChange} checked={this.props.data.flow_fee_type === "Percent"}/> Percentage
                                <span className="circle">
                                  <span className="check"></span> 
                                </span>
                              </label>
                            </div>
                        </div>
                      <div className="col-sm-4 checkbox-radios">
                            <span>{this.state.flow_fee_type === "Percent" ? "Fee in %" : "Flat Fee (" +this.currency_code + ")" }</span><font className="text-danger">   *  </font>
                            <input id="flow_fee" value={this.props.data.flow_fee} onChange={this.handleProdChange} className="form-control" type="text" required/>
                      </div>
                      <div className="col-sm-4 checkbox-radios">
                            <label className="md-label-floating"> Fee for *</label>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input className="form-check-input" type="radio" id="flow_fee_duration" value="entire_duration" onChange={this.handleFlowFeeDurationChange} checked={this.props.data.flow_fee_duration === "entire_duration"} /> Entire duration {this.state.duration? this.state.duration+" days": ""}
                                <span className="circle">
                                  <span className="check"></span> 
                                </span>
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input className="form-check-input" type="radio" id="flow_fee_duration" value="each_day" onChange={this.handleFlowFeeDurationChange} checked={this.props.data.flow_fee_duration === "each_day"}/> Each day
                                <span className="circle">
                                  <span className="check"></span> 
                                </span>
                              </label>
                            </div>
                        </div>
                  
                    </div>
                  </div>
                </div>
 
              <div className={`row ${this.state.button_style}`}>
               <div className={`col-md-1 ${this.state.edit_button_style}`} >
                  <Edit entity="template" onClick={this.handleEdit}/>
                </div>

                <div className={`col-md-1 ${this.state.save_button_style}`}>
                 <input  onClick={this.handleSave} type="button" className="btn btn-success" value="Save"/>
                </div>
              </div>
         </div>


);

}
}
export default LoanProduct;