export const dialog = [
    {
        props: { variant: 'small' },
        style: { 
            borderRadius: '25px',
            width: '500px',
            maxWidth: '25%',
            margin: 'auto',
        },
    },
    {
        props: { variant: 'medium' },
        style: { 
            borderRadius: '25px',
            width: '100%',
            maxWidth: '700px',
            margin: 'auto',
        },
    },
    {
        props: { variant: 'large' },
        style: { 
            borderRadius: '25px',
            width: '100%',
            maxWidth: '900px',
            margin: 'auto',
        },
    },
   
]