import {call_fetch, admin_path} from './../helpers/fetch_helper';
import { get } from '../helpers/storage_helper';
import { get_path } from '../helpers/common_helper';


export const createAgreement = request  => {
   return call_fetch(get_path("admin")  + '/agreement', request);
}

export const listAgreement = request  => {
   return call_fetch(get_path("kyc") + '/agreement/list', request);
}

export const generateAgreement = request  => {
   return call_fetch(get_path("admin")  + '/agreement/cust', request);
} 

export const getExistingAgreements = request  => {
   return call_fetch(get_path("kyc")  + '/agreement/aggrs', request);
}

export const updateAggrStatus = request  => {
   return call_fetch(get_path("kyc")  + '/agreement/status', request);
}

export const saveAgreement = request  => {
   return call_fetch(get_path("kyc")  + '/agreement/save', request);
}

export const loadAgreementDetails = request  => {
   return call_fetch(get_path("kyc")  + '/agreement/load', request);
}

export const listProducts = request  => {
   return call_fetch(get_path("admin")  + '/agreement/list_products', request);
}

export const getExpiredAgreements = request  => {
   return call_fetch(get_path("admin")  + '/agreement/expired_aggrs', request);
}
