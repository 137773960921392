import React from 'react';
import {Navigate} from 'react-router-dom';
import '../../../styles/flow.css';
import './datatables.css';
import {dd_value} from '../../../actions/common_actions';
import {listMarket} from '../../../actions/market_actions';
import FlowContainer from '../../common/container/core/FlowContainer';
import StatusButton from '../../common/component/core/StatusButton';
import $ from 'jquery';
import {ViewEdit} from '../../common/partial';

class ListMarketContainer extends FlowContainer {

  state = {
     marketList: null,
     toView: false
     
  }
 
 handleClick = (event) => {
       
        this.setState({country_code: event.target.id, toView: true});
        //this.props.history.push("/market/view");
    }
  
  componentWillMount(){

    listMarket(this.req(null))
        .then((response) => {
          if(!response){return };
          
          const marketList = response.data.map((market,i)=> <tr key={i}>
                 <td> {dd_value(market.country_code)}</td>
                <td> {market.name} <br/> {market.inc_name }</td>
                <td> {market.currency_code}</td>
                <td> {dd_value(market.time_zone)}</td>
                <StatusButton id={market.market_id} entity="markets" status={market.status}/>

                {/*<td key={i}> <input id={market.country_code} type='submit' onClick={this.handleClick} value='View / Edit'  className='btn btn-primary' /></td>*/}
                <td><ViewEdit id={market.country_code} entity="market"  onClick={this.handleClick}/></td>

              </tr>
          );
               
          this.setState({marketList: marketList});

              $('#marketTable').removeAttr('width').dataTable({
                    "order" : [],
                  "columns": [
                       {"width" : "10%"}, 
                       {"width": "10%"},
                       {"width": "10%"},
                       {"width": "10%"},
                       {"width": "10%"},
                       {"width": "10%"},
                       {"width": "10%"}
                     
                     ],

                  dom:'Bfrtip',
                  buttons: [
                  'csv','excel','print'
                  ]
            
              });
          
         
        });
  }




  render(){
    if (this.state.toView === true) { 
           return <Navigate to={`/market/view/${this.state.country_code}`} />
    }

   if (this.state.marketList != null) { 
      
    return( <div id='datatable'className={"table-responsive"}>
            <h5>{this.props.title || "Market List"}</h5>
             <table id ='marketTable' className="market-table table">
                      <thead className={"text-info"}>
                      <tr><th>Country</th>
                      <th>Market Name</th>
                      <th>Currency</th>
                      <th>Time Zone</th>
                      <th>Status</th>
                      <th>Action</th>
                      <th>Action</th></tr></thead>
                      <tbody> 
                      {this.state.marketList}
                      </tbody>
             </table>
                    
        </div>
     

      );

      
    }
   

      return (
        <div><p>No Markets Available ! </p></div>
        
      );
  }
}


export default ListMarketContainer;
