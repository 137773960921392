import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import { Modal , Button} from 'react-bootstrap';
import {instantDisburseLoan} from '../../../actions/loan_actions';
import {addDays,format_date} from '../../../helpers/common_helper';
import * as moment from "moment";
import {Row} from 'react-bootstrap'
import '../../../styles/flow.css';
import { Navigate } from 'react-router-dom';
import {lbl_amt} from '../../../helpers/product_helper';
import {title_case} from '../../../helpers/common_helper';

//import {check_priv} from '../../../helpers/storage_helper';

class InstantDisburseLoan extends FlowComponent {
  state = {
    view_loan: false,
     modalBox: false,
     send_sms: true,
      disburse_style: "disable_div",
      processing : "",
      reviewed : 0
  }

handleInstantDisburalClick = (event) => {
  
  
  //from_ac_id, txn_exec_by, txn_mode, 
  let {lender_code, acc_prvdr_code, loan_principal, loan_doc_id, cust_id, cust_acc_id, to_acc_num}  = this.props.data
  this.txn = {lender_code, acc_prvdr_code, amount: loan_principal, loan_doc_id, cust_id, to_ac_id : cust_acc_id, to_acc_num};  
  
  
  this.setState({modalBox: true});


}

handleClose = (event) => {
  
    this.setState({ modalBox: false });
}



handleSMSCheckboxClick = (event) => {
  if(event.target.checked){
    this.setState({send_sms: true});
  }else{
    this.setState({send_sms: false});
  }
}

handleSubmit = (event)=>{
    event.preventDefault();
    if(this.props.data.data_prvdr_cust_id != this.props.data.to_acc_num)
    {
      const resp = window.confirm("Data provider customer ID and A/C number are different. Are you sure to disburse the amount?");
      if(resp == false){
        return;
      }
    }
    if(this.state.reviewed === 5){
        const disbursal_txn = this.txn;
        disbursal_txn.send_sms = this.state.send_sms;
        disbursal_txn.instant_disbursal = true
       this.setState({disburse_style : "disable_div", processing : "Payment is being processes. please wait... it shall take upto 60 secs"})
         instantDisburseLoan(this.req({"instant_disbursal_txn":disbursal_txn}))
            .then((response) => {
              this.setState({disburse_style : "", processing : false})
              if(!response){return };
              
                if(response.status === "success"){
                  this.setState({view_loan: true}); 
                   this.setState({ modalBox: false });
                   window.location.reload();
                }
                else if(response.status === "warning"){
                  const resp = window.confirm(response.message);
                  if(resp == false){
                    return;
                  }
                  else{
                    
                  }
                }
                else{
                  
                  alert(response.message + "\n" + JSON.stringify(response.data));  
                }
              }
            );
    }else{
      alert("Please review all the data before disbursing");
    }
  }
  
   handleReviewChange = (event)=>{
    const target = event.target;
    const value = target.checked; 
    var reviewed = this.state.reviewed;
    var disburse_style = "disable_div"
    if(value){
      reviewed++
    }else{
      reviewed--
    }
    
    if(reviewed == 5){
      disburse_style = ""
    }
    
    this.setState({
     reviewed, disburse_style
    });
    
  }
  handleTxtModeChange = changeEvent => {
    this.setState({txn_mode : "(" + title_case(changeEvent.target.value)+ ")"});
    //this.handleSelfChange(changeEvent);
  };

  render(){
   
     if (this.state.view_loan === true) { 
        return (
           <Navigate to={`/fa/view/${this.props.data.loan_doc_id}#disbursed`} />
          );
    }
    
    return (
    <div id="" >
        <div className="row">
        
          <div>
            <input type="button" className="btn btn-primary disburse" value="Instant Disbursal" onClick={this.handleInstantDisburalClick} />
          </div>
       
        </div>
        <br/>
        <Modal className="instant_disbursal" show={this.state.modalBox} onHide={this.handleClose} size="l">
              <Modal.Header closeButton show={this.state.modalBox} onHide={this.handleClose}>
                  <Modal.Title>Instant FA Disbursal ({this.props.data.acc_number})</Modal.Title>
              </Modal.Header>
              <Modal.Body>        
                  <div className="container instant_disbursal">
                  <p>Review the below disbursal data by clicking over them. </p>
                  <form>
                      <div className="rule-form-group form-group row reset-margin">
                          <label for="" className="col-sm-4 label__name reset-padding">Customer</label>
                          <div className="col-sm-8 reset-padding">
                            <div>
                              <input type="checkbox" id="toggle_1" className="toggle visually-hidden"  onChange={this.handleReviewChange} value="1"/>
                              <label className="select_toogle" for="toggle_1">{this.props.data.cust_name} | {this.props.data.biz_name}</label>
                              <div className="control-me"></div>
                            </div>
                          </div>
                      </div>
                      <div className="rule-form-group form-group row reset-margin">
                          <label for="" className="col-sm-4 label__name reset-padding">A/C Number</label>
                          <div className="col-sm-8 reset-padding">
                            <div>
                              <input type="checkbox" id="toggle_2" className="toggle visually-hidden" onChange={this.handleReviewChange} value="2"/>
                              <label className="select_toogle" for="toggle_2">{this.props.data.to_acc_num || "NA"}</label>
                              <div className="control-me"></div>
                            </div>
                          </div>
                      </div>
                      <div className="rule-form-group form-group row reset-margin">
                          <label for="" className="col-sm-4 label__name reset-padding">Float amount</label>
                          <div className="col-sm-8 reset-padding">
                            <div>
                              <input type="checkbox" id="toggle_3" className="toggle visually-hidden" onChange={this.handleReviewChange} value="3"/>
                              <label className="select_toogle" for="toggle_3">{lbl_amt(this.props.data.loan_principal, this.props.data.currency_code)}</label>
                              <div className="control-me"></div>
                            </div>
                          </div>
                      </div>
                      <div className="rule-form-group form-group row reset-margin">
                          <label for="" className="col-sm-4 label__name reset-padding">Duration / Due date</label>
                          <div className="col-sm-8 reset-padding">
                            <div>
                              <input type="checkbox" id="toggle_4" className="toggle visually-hidden" onChange={this.handleReviewChange} value="4"/>
                              <label className="select_toogle" for="toggle_4">{this.props.data.duration} days / {this.props.data.due_date}</label>
                              
                              <div className="control-me"></div>
                            </div>
                          </div>
                      </div>
                      <div className="rule-form-group form-group row reset-margin">
                          <label for="" className="col-sm-4 label__name reset-padding">Repayment / Fee</label>
                          <div className="col-sm-8 reset-padding">
                            <div>
                              <input type="checkbox" id="toggle_5" className="toggle visually-hidden" onChange={this.handleReviewChange} value="5"/>
                              <label className="select_toogle" for="toggle_5">{lbl_amt(this.props.data.due_amount, this.props.data.currency_code)} / {lbl_amt(this.props.data.flow_fee, this.props.data.currency_code)}</label>
                              <div className="control-me"></div>
                            </div>
                          </div>
                      </div>
                       <div className="row">
                          <label><input type="checkbox" onChange={this.handleSMSCheckboxClick} defaultChecked/>&nbsp;
                          Send Disbursement confirmation notification SMS</label>
                        </div>

                        {/* <div className="rule-form-group form-group row reset-margin">
                          <label className="row label__name reset-padding text_size">Confirm Disbursal Amount</label>
                          <div className="row reset-padding" style={{'margin':'auto'}}>
                              <NumberFormat  displayType={'input'} thousandSeparator={true} inputmode="numeric" />
                              <input type="text" onChange={this.handleTxtModeChange} className="form-control input_text_size" style={{'color': '#257107','text-align': 'center','font-size':'28px'}}required></input>
                          </div>
                        </div>*/}

                      {/* <label for="" className="row label__name reset-padding text_size">Select Correct Disbursal Amount</label>
                      <div className="row">
                        <div className="col-sm-4 reset-padding">
                              
                                <input type="checkbox" id="toggle_6" className="toggle visually-hidden input_text_size"  onChange={this.handleReviewChange} value="6"/>
                                <label className="select_toogle apply_label" style={{'font-size': '22px'}} for="toggle_6">2,000,000</label>
                                <div className="control-me"></div>
                              
                        </div>
                        <div className="col-sm-4 reset-padding">
                              
                                <input type="checkbox" id="toggle_7" className="toggle visually-hidden input_text_size" onChange={this.handleReviewChange} value="7"/>
                                <label className="select_toogle apply_label" style={{'font-size': '22px'}} for="toggle_7">1,000,000</label>
                                <div className="control-me"></div>
                              
                        </div>
                        <div className="col-sm-4 reset-padding">
                             
                                <input type="checkbox" id="toggle_6" className="toggle visually-hidden input_text_size"  onChange={this.handleReviewChange} value="5"/>
                                <label className="select_toogle apply_label" style={{'font-size': '22px'}} for="toggle_6">3,000,000</label>
                                <div className="control-me"></div>
                              
                        </div>
                      </div> */}
                  </form>
                  </div>

                  
                 {this.state.processing && <p style={{color: "red",textAlign: "right",fontWeight: "bold"}}>{this.state.processing}</p>}
                
                
                </Modal.Body>

            
              <Modal.Footer>
              
                <div className="col-md-8 reset-margin reset-padding">
                      <button type="button" className= {`btn btn-primary float-right mr-1 ${this.state.disburse_style}`} onClick={this.handleSubmit} >Disburse {lbl_amt(this.props.data.loan_principal, this.props.data.currency_code)}</button>
                        <button disabled type="button" className="btn btn-outline-primary float-right mr-1" onClick={this.handleClose} data-dismiss="modal" aria-label="Close">Cancel</button>
                        
                        
                </div>
                
              
              </Modal.Footer>
              
          </Modal>
    </div>

      );
  }
}
export default InstantDisburseLoan;
