import { Outlet, useLocation, useNavigate } from "react-router-dom";  
import Header from "../../UI/CommonComponent/Header";
import AdminMenu from "../../UI/home/AdminMenu"
import { useDispatch, useSelector } from "react-redux";
import SelectMarket from "../../UI/home/SelectMarket";
import { check_priv, get } from "../../helpers/storage_helper";
import CSSoftPhoneContainer from "../../UI/cs_management/container/CSSoftPhoneContainer";
import { useTheme } from "@mui/material";
import { useEffect } from "react";
import { setValuesIntoRedux } from "../../Redux/Slices/CommonSlice";
import FreshChat from "../../UI/utility/Component/FreshChat";





const HomeLayOut = ()=>{

    const datas = useSelector(state=>state.Values)

    const role_codes = get('role_codes')

    const select_market = datas.admin_roles.includes(role_codes)

    const market = get('market')

    const acc_prvdr_code = get('acc_prvdr_code')

    const theme = useTheme()

    const location = useLocation();

    const dispatch = useDispatch();

    useEffect(()=>{
        if(!localStorage.getItem('bacgroundColor')){
          localStorage.setItem('backgroundColor', theme.palette.background.default)
          document.body.style.backgroundColor = theme.palette.background.default
        }
    
      },[datas && datas.mode])


      useEffect(()=>{
        // to close the call and fresh chat icons when  new path opened
        dispatch(setValuesIntoRedux({showMenus:false,show_nums:false,missed_calls_view:false}))
        
      }, [location.pathname])

    
    
    return (
        <>{get('access_token') && <div>

            {datas.update_market_details ? 
            <>
                <SelectMarket toChange = {datas.update_type} />

            </>
            :
             select_market && !market ? <SelectMarket toChange={'market'}/> : select_market && !acc_prvdr_code ? <SelectMarket toChange={'acc_prvdr'}/>  :
            
            <>
            <div>
                <div>
                    <Header/>
                </div>
                <AdminMenu/>

                <div className="main-panel">
                    <div className=" content container-fluid">
                        <Outlet/>
                    </div>
                </div>
            </div>

            {(get('market').country_code =='UGA') ? 
            <div>
                {get('role_codes') && check_priv('home','chat_icon') &&
                <CSSoftPhoneContainer/> 
                }
            </div>:
            <>
            {get('role_codes') && check_priv('user','chat_support') && <FreshChat/>} 

            </>
            }
            </>
            }
        </div>}
        </>
    )
}


export default HomeLayOut;