import React from 'react';
import { Navigate } from 'react-router-dom';
import '../../../styles/flow.css';
import '../../market/container/datatables.css';
import {dd_value} from '../../../actions/common_actions';
import {listDataProvider} from '../../../actions/data_provider_actions';
import FlowContainer from '../../common/container/core/FlowContainer';
import StatusButton from '../../common/component/core/StatusButton';
import  {check_priv} from '../../../helpers/storage_helper';
//import ViewEdit from '../../common/component/ViewEdit';
import {ViewEdit} from '../../common/partial';
import { Modal , Button} from 'react-bootstrap';
import AccountContainer from '../../account/container/account_container';
import $ from 'jquery';

class ListDataProviderContainer extends FlowContainer {

  state = {
     dataproviderList: null,
     toView: false,
     data_provider_code: null  
  }

  handleViewClick = (event) => {
       
        this.setState({data_provider_code: event.target.id, toView: true});
       
    }

  handleViewAccountsClick = (event) => {
    this.setState({data_prvdr_code: event.target.id});
    this.setState({modalBox: true});
    }
  handleModalClose = (event) => {
      this.setState({ modalBox: false });
  }

  componentWillMount(){

      const country_code = this.country_code;
      if(check_priv("data_prvdr" , 'list_account')){
          this.btn_style = "show_div";
      }else{
          this.btn_style = "disable_div";
      }
      //const status = "enabled";
    
    listDataProvider(this.req({country_code}))
        .then((response) => {
          if(!response){return };

           const dataproviderList = response.data.map((data_provider,i)=>

              <tr key={i}>
                <td> {data_provider.name}</td>
                <td> {dd_value(data_provider.provider_type)}</td>   
                <StatusButton id={data_provider.id} entity="data_prvdrs" status={data_provider.status}/>

                {/*<td key={i}> <input id={data_provider.data_prvdr_code} type='submit' onClick={this.handleViewClick} value='View / Edit'  className='btn btn-primary' /></td>*/}
                <td><ViewEdit id={data_provider.data_prvdr_code} entity="data_prvdr"  onClick={this.handleViewClick}/></td>
                <td key={i}> <input id={data_provider.data_prvdr_code} type='submit'  onClick={this.handleViewAccountsClick} value='Accounts'  className={`btn-sm btn-primary ${this.btn_style}`} /></td>

              </tr>
                                              );
      
          this.setState({dataproviderList: dataproviderList});

              $('#dp_list').removeAttr('width').dataTable({
                    "order" : [],
                  "columns": [
                       {"width" : "5%"}, 
                       {"width": "10%"},
                       {"width": "5%"},
                       {"width": "5%"},
                       {"width": "5%"},
                       {"width": "5%"}
                      
                     ],

                  dom:'Bfrtip',
                  buttons: [
                  'csv','excel','print'
                  ]
            
              });
        });
  }



  render(){
      
      if (this.state.toView === true) { 
          
           return <Navigate to={`/data_provider/view/${this.state.data_provider_code}`} />
    }
   if (this.state.dataproviderList != null) { 
      
    return( 
    <><div id='datatable' className={"table-responsive"}>
             <h5>{this.props.title || "Data Provider List"}</h5>
             <table id ='dp_list' className="list">
                      <thead className={"text-info"}>
                      <tr><th>Name</th>
                      <th>Data Provider Type</th> 
                      <th>Status</th>
                      <th>Action</th>
                      <th>Action</th>
                      <th>Account</th></tr></thead>
                      <tbody> 
                      {this.state.dataproviderList}
                      </tbody>
             </table>
                    
        </div>
     <Modal show={this.state.modalBox} onHide={this.handleModalClose} size="xl">
            <Modal.Header closeButton show={this.state.modalBox} onHide={this.handleModalClose}>
                <Modal.Title>Data Provider Accounts : {this.state.data_prvdr_code}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AccountContainer entity="data_prvdr" entity_id={this.state.data_prvdr_code}/>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleModalClose}>Close</Button>
            </Modal.Footer>
        </Modal>
</>
      );

      
    }
   

      return (
        <div><p>No Data Providers Available ! </p></div>
        
      );
  }
}

export default ListDataProviderContainer;
