import React from "react"

export const GlobalFilter = ({filter,setFilter}) => {
    return(
        <div  className="d-flex align-items-center justify-content-end mb-3 mr-3" style={{color:'white',float:'right'}}>
             <p className="m-0"> Search </p>
            <input   type="text" id="model_name"  value={filter || ""} onChange={(e)=>setFilter(e.target.value)} className="form-control ml-2 " />

        </div>
    )
}