import {call_fetch, admin_path, app_path} from './../helpers/fetch_helper';

export const updateFcmToken = request => {
    return call_fetch(app_path + '/fcm/update',request);
 }

export const GetLatestNotifications = request => {
    return call_fetch(app_path+'/fcm/latest_notifications',request)
}

export const GetAllNotifications = request => {
    return call_fetch(app_path+'/fcm/all_notifications',request)
}

// update read status of a FCM notification
export const UpdateReadStatus = request => {
    return call_fetch(app_path+'/fcm/read',request)
}