import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import '../../../styles/flow.css';
import { getCurrencyCode} from '../../../actions/common_actions';
import {LOADING_OPT} from '../../../constants';
import {Edit} from '../../common/partial';
class Market extends FlowComponent {
 
  state = {
    comp_name: "market"
  }


 componentWillMount() {
    super.componentWillMount();
    this.setOptionsListToStateFromAppApi("common","country");
    this.setOptionsListToStateFromAppApi("common","currency");
 }


  handleChange1 =  (event) => {

      //var currency_code={};
    
      const country_code = event.target.value;
      const request = {country_code}; 
      //window.country_code = event.target.value;
      //this.props.data.country_code = event.target.value;
      //this.setState({["country_code"] : event.target.value});
      //alert(this.props.data.country_code);
      //alert(this.state.country_code);
     getCurrencyCode(this.req(request))
        .then((response) => {
          if(!response){return };

            this.props.data["currency_code"] = response.data[0]["currency_code"];  
            this.setState({"currency_code" : response.data[0]["currency_code"]}); 

      
          }
        );
        
  }




  render(){
    return (
    <div id="" className='no-padding-input'>
      <div className="row">
        <div className="col-md-2"><h4>{this.props.title ? this.props.title : "Market Details"}</h4></div>
        <div className={this.state.button_style}>
          <div className={`col-md-1 ${this.state.edit_button_style}`} >
            <Edit entity="market" onClick={this.handleEdit}/>
            {/*<input type="button" onClick={this.handleEdit} value="Edit"/>*/}
           </div>
            <div className={`col-md-1 ${this.state.save_button_style}`}>
            <input  onClick={this.handleSave} type="button" className="btn btn-success" value="Save"/>
          </div>
        </div>
        
      </div>
      <div className={`${this.state.div_style} no-padding-input`}>
        <div className="row">

          <div className="col-md-3">
            <span>Country</span><font className="text-danger">   *  </font>
            <select id="country_code" value={this.props.data.country_code}  onChange={(e) => { this.handleChange(e); this.handleChange1(e) }} required="required" className="form-control">
              
              {this.state.country_dd || LOADING_OPT}
            </select>
          </div>
         
          <div className="col-md-3">
            <span>Currency</span><font className="text-danger">   *  </font>
            <select id="currency_code" value={this.props.data.currency_code}  onChange={this.handleChange} className="form-control" required="required">
            {this.state.currency_dd || LOADING_OPT}
            </select>
          </div>    

          <div className="col-md-3">
            <span>ISD Code</span><font className="text-danger">   *  </font>
            <input type="number" id="isd_code" value={this.props.data.isd_code} maxLength={5} onChange={this.handleChange}  required className="form-control" />
          </div>       

        </div>
      </div>
    </div>

      );
  }
}

export default Market;
