import React, { Component } from 'react'
import FlowComponent from '../common/component/core/FlowComponent'
import { Accordion, Button, Card, Modal } from 'react-bootstrap'
import { BsChevronDown } from 'react-icons/bs'
import CommonReactTable from '../../helpers/commonReact_table'
import { Homeacc_prvdrLogoselector, lbl_amt } from '../../helpers/common_helper'
import { Link } from 'react-router-dom'
import { ListSwitchProof, approveProofAction, handleTransactionID } from '../../actions/loan_actions'
import moment from 'moment'
import DisplayImage from '../common/component/DisplayImage'
import { get } from '../../helpers/storage_helper'
import { listAccountProvider } from '../../actions/account_provider_actions'
import { MdOutlineCheck } from 'react-icons/md';
import DatePicker from 'react-datetime';
import { BasicReactTable } from '../../helpers/react_table'

export default class SwitchPaymentProof extends FlowComponent {
    state={
        rowdata : [],
        columndata : [],
		lists : [],
        show_table:false,
        modal_data:{},
        txn_id : null,
        remarks : null,
        action : null,
        all_count:0,	
        each_count:[],
		copy:[],
		all_Acc_pvdrs:[],
        txn_failed_msg: '',
        acc_stmt_data : '',
        refresh: false
    }

    componentDidMount(){
        // this.listSwitchPaymentProof()
        this.drawTable()

    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.refresh != this.props.refresh ){
			
			this.listSwitchPaymentProof()
			
        }		
    }

    handleToggle = (e) => {

        this.setState(state => state.show_table = !this.state.show_table);
    }

    handleApprovalModal = (e, event) =>{


        this.setState({approval_modal: true, modal_data : event.original, sales_doc_id :event.original.sales_doc_id})


    }

    
    drawTable () {
		const ls_market = get('market')
		var req = {country_code: ls_market.country_code, time_zone: ls_market.time_zone, status: ""};		
		listAccountProvider({...req, status: 'enabled', biz_account: true})		
		.then((response)=>{
			if(!response){return };
			if(response.status === "success"){
				let acc_prvdrs = []            
				response.data.list.map(each=>
				    acc_prvdrs.push(each.acc_prvdr_code)
				)  
				this.setState({all_Acc_pvdrs : acc_prvdrs}, ()=>this.filter_count()) 
					           
			}
		});
		this.listSwitchPaymentProof()
	}

    listSwitchPaymentProof = () =>{
        ListSwitchProof()
        .then((response) => {
            this.resp(response)
            if(!response) return;
            if(response.status == 'success'){	
                
                this.setState(
                    { lists: response.data },
                    () => {
                      this.setState(
                        { columndata: this.column_datas(), refresh: false, all_count: response.data.length },
                        () => {
                          this.filter_acc('All');
                          this.filter_count();
                        }
                      );
                    }
                  );
            }else{
                alert(response.message);
            }
        })
    }

    getAction = () =>{

        const { remarks, action, sales_doc_id, txn_id } = this.state;

        if(!remarks || remarks.trim() === ""){
            alert("Please enter remarks.")
            return;
        }

        const userConfirmed = window.confirm(`Are you sure you want to ${action} the payment proof?`);

        if (!userConfirmed) {
            return; 
        }

        let request = { 
            "action" : action, 
            "sales_doc_id" : sales_doc_id, 
            "stmt_txn_id" : txn_id, 
            "remarks" : remarks,
            "cust_id" : this.state.modal_data.cust_id,
            "cr_amt" : this.state.modal_data.product_amt,
            'txn_date': this.state.txn_date ? moment(this.state.txn_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD') , 
            "acc_prvdr_code" : this.state.modal_data.acc_prvdr_code,
        }
        approveProofAction(this.req({data:request}))
        .then((response) => {
            this.resp(response)
            if(!response) return;
            if(response.status == 'success'){
                alert(`Payment proof has been ${action == 'approve' ? 'approved' : 'rejected'} successfully`);
                this.handleApprovalModalClose();
                this.listSwitchPaymentProof();
            }else{
                alert(response.message + "\n" + JSON.stringify(response.data));
            }
        })
    }

    handleChange =(val, type)=>{
        this.setState({[type] : val})
    }

    handleChange = (value, fieldName) => {
        if (fieldName == 'txn_id') {
            if(/\s/.test(value)){
                alert('Spaces are not allowed in the Transaction ID');
                return;
            }
            if(value === ''){
                this.setState({acc_stmt_data : '', txn_failed_msg : ''});
            }
        }
        
        this.setState({ [fieldName]: value });
    };
    

    column_datas(){
        let column
        column = [
            {
                Header : "Account Provider",
                id: 'acc_number',
                minWidth:100,
                accessor: row=> <div className='d-flex align-items-center'> 
                                    <Homeacc_prvdrLogoselector logotype={row.acc_prvdr_code}/> 
                                    <p className='no-margin w-100' style={{paddingLeft:'10px'}}> {row.acc_number}</p> 
                                </div> 
            },
            {
                Header : "Customer ID",
                id: 'cust_id',
                minWidth:100,
                accessor: row=><Link style={{color: "#17a2b8 !important"}} to={"/borrower/indiv/view/"+row.cust_id} target="_blank">{row.cust_id}</Link>
            },
            {
                Header : "Switch ID",
                id: 'sales_doc_id',
                minWidth:100,
                accessor: row=>row.sales_doc_id
            },
            {
                Header : "Customer Name",
                id: 'cust_name',
                minWidth:100,
                accessor: row=>row.cust_name
            },
            {
                Header : "Date",
                id: 'date',
                minWidth:100,
                sortMethod: (a,b) => this.sortTime(a,b),
				accessor:row => {return <div className='text-white'>{moment(row.created_at).format("DD MMM YYYY")}</div>}
            },
            {
                Header : "Time",
                id: 'time',
                sortMethod: (a,b) => this.sortTime(a,b),
				accessor:row => {return <div className='text-white'>{moment(row.created_at).format("hh.mm A")}</div>}
            },
            {
                Header : "Customer Needs",
                id: 'cust_need',
                minWidth:100,
                accessor: row=>row.cust_need
            },
            {
                Header : "Remarks",
                id: 'remark',
                minWidth:150,
                accessor: row => {return <div style={{ whiteSpace: 'pre-wrap' }}>
                                            <p className='m-0'>{row.remarks ? row.remarks : 'NA'}</p>
                                        </div>
                                 }
            },
            {
                Header : "Action",
                id: 'action',
               
                Cell: ({ row }) => (<Button variant="primary" onClick={e => this.handleApprovalModal(e ,row)}>view</Button> )
            }
        ]
        return column
    }

    filter_count=()=>{
        var count=[]
        const filter_each=(a)=>{
        let each=this.state.lists.filter((item)=>item.acc_prvdr_code==a)
            count.push(each.length)}
        this.state.all_Acc_pvdrs.map((item)=>filter_each(item))
        this.setState({each_count:[...count]})
    }

    filter_acc=(acc)=>{
        this.setState({selected_btn:acc})
        this.setState({copy:[]})
        if(acc=='All'){
            this.setState({copy:this.state.lists})
        }
        else{
        let easy =this.state.lists.filter((item,index)=>item.acc_prvdr_code==acc)
        this.setState({copy:easy})}
    }

    handleApprovalModalClose = () =>{
        this.setState({approval_modal: false})
        this.setState({
            action : null,
            txn_id : null,
            remarks : null,
            acc_stmt_data : '',
            txn_failed_msg : ''
        })
    }

    handleTxnId = (val) =>{
        if (val.length < 4) {
            alert('Transaction ID must be at least 4 characters');
            return;
        }

        this.setState({txn_failed_msg :"", acc_stmt_data : ''});
        var request={
            'loan_doc_id' :this.state.sales_doc_id, 
            'txn_id' : val, 
            'txn_date': this.state.txn_date ? moment(this.state.txn_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD') , 
            'mode' : 'credit', 
            "type": "switch_payment_capture",
            "lender_code" : this.state.modal_data.lender_code,
            "purpose" : "float_switch",
            "ap_code" : this.state.modal_data.acc_prvdr_code,
            "dr_amt" : this.state.modal_data.product_amt
        }

        handleTransactionID(this.req(request, true))
            .then((response) => {
                this.resp(response);
                if(!response){return };
                if(response.status === "success"){
                    if(response.data){
                        if(response.data.hasOwnProperty('message')){
                            let msg = response.data.message;
                            // if(msg == "No such transaction exist with above details. This may missed in the import or import may be failed or not configured"){
                            //     this.setState({acc_stmt_data : msg, txn_failed_msg: '' })
                            // }else{
                                this.setState({txn_failed_msg : msg})
                            // }
                            
                        }else{
                            if(response.data.hasOwnProperty('acc_stmt_records')){
                                this.setState({acc_stmt_data : response.data.acc_stmt_records, txn_failed_msg: ''})
                            }
                        }
                    }else{
                        this.setState({txn_failed_msg : ['Please check the transaction ID. No payment received to the account with this transaction ID.']});
                    }
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));  
                }
            }
        )
    }

    handleSpecificMessage = (message) =>{
        const pattern1 = /The transaction ID (\d+) is already matched with the ID ([A-Z]+-\d+-\d+). You can not manually capture the payment here./;
        const pattern2 = /This transaction ID (\d+) is already linked with the ID ([A-Z]+-\d+-\d+)./;

        let match;
        if ((match = message.match(pattern1))) {
            const [_, txn_id, loan_doc_id] = match;
            return (
                <p className='text-white px-3 m-0'>
                    The transaction ID {txn_id} is already matched with the ID <Link to={"fa/view/"+loan_doc_id} target="_blank" >{loan_doc_id}</Link>. You can not manually capture the payment here.
                </p>
            );
        } else if ((match = message.match(pattern2))) {
            const [_, txn_id, loan_doc_id] = match;
            return (
                <p className='text-white px-3 m-0'>
                    This transaction ID {txn_id} is already linked the ID <Link to={"fa/view/"+loan_doc_id} target="_blank" >{loan_doc_id}</Link>.
                </p>
            );
        } else {
            return <p className='text-white px-3 m-0'>{message}</p>;
        }
    }
  
    render() {
        return (
            <div>
                <Accordion>
                    <Card>		
                        <div className="card-header card-header-icon card-header-primary home_header_d-flex_at_response justify-space-between">
                            <h4 className="card-title text-white tableHead_font title-bottom">Float Switch Payment proofs</h4>
                            {this.state.lists.length > 0 &&<>
								<div className='countREL card-title' onClick={()=>this.filter_acc('All')}>
									<button className={'filterBtns allBtn_size'}  >ALL</button>
									<div className='countABS' >
										<label>{this.state.all_count}</label>
									</div>
								</div>						
								{this.state.all_Acc_pvdrs.map((each,idx)=>									
									<div key={idx} className='countREL  card-title'>																					
											<>
												<div style={{width:60,height:30}} className="filterBtns"  onClick={()=>this.filter_acc(each)}>													  
													<img className={`img-fluid ${this.state.selected_btn==each? 'low_opacity':''}`}src={`/img/${each}_logo_color.png`}></img> 																	
												</div>
												<div className='countABS' >													
													<label>{this.state.each_count[idx]}</label>																																
												</div>
											</>										
									</div>																		
								)}
						</>
						}

                            <Accordion.Toggle className = "col" as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle()}>
                                <BsChevronDown className = {this.state.show_table ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow"} size="18"/>
                            </Accordion.Toggle>	
                        </div>
                        <div className="card-body no-padding justify-content-center">
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    {/* <CommonReactTable 
                                        showPag={true} 
                                        defaultPageSize={10} 
                                        minRow={0} 
                                        row_data={this.state.copy !== undefined ? this.state.copy : []} 
                                        defaultSorted={[{id:"cust_name",desc:false}]} 
                                        column_data={this.state.columndata} 
                                        resizable={false} />                                 */}

                                        <BasicReactTable pagination={this.state.copy !== undefined && this.state.copy.length > 9 ? true : false} default_page_size={10} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndata} defaultSorted={[{id:"cust_name",desc:false}]} />
                                </Card.Body>
                            </Accordion.Collapse>
                        </div>
                    </Card>
                </Accordion>

                <Modal className='txn_modal switch_pymt_proof_model' show={this.state.approval_modal} onHide={this.handleApprovalModalClose}  backdrop="static" size="lg" style={{position: "fixed"}}>
                    <Modal.Header className='p-0 sms_for_close' closeButton>
                        <Modal.Title className='sms_modal_header p-2'><h4 className=' text-center py-2 m-0 text-light'>Payment Proof : {this.state.sales_doc_id}</h4></Modal.Title>
                    </Modal.Header>
                    <Modal.Body  >
						<div>
                        {(Object.keys(this.state.modal_data).length != 0) &&
                        
                            <>
                                <div className=' mt-4 row '>
                                    <div className='col-md-6 no-padding col-lg-6' >
                                        <div className='m-0 text-light  h-100 w-100' >
                                            <DisplayImage image_path={this.state.modal_data.photo_switch_proof_full_path} style={{width:'100%'}} file_name={this.state.modal_data.photo_switch_proof}/>
                                        </div> 
                                    </div> 
                                    <div className='col-md-6 col-lg-6 '>
                                        <div className='border'>
                                            <div className='d-flex flex-row p-3'>
                                                <div className='col-6'>
                                                    <p className='text-white re_kyc_label_text'>From</p>  
                                                    <p className='text-white switch_label' >{this.state.modal_data.from_acc_num}</p>
                                                </div>
                                                <div className='col-6'>
                                                    <p className='text-white re_kyc_label_text'>To</p>  
                                                    <p className='text-white switch_label' >{this.state.modal_data.to_acc_num}</p>
                                                </div>
                                            </div>

                                            <div className='d-flex flex-row p-3'>
                                                <div className='col-6'>
                                                    <p className='text-white re_kyc_label_text'>Customer Id</p>  
                                                    <p className='text-white switch_label' >{this.state.modal_data.cust_id}</p>
                                                </div>
                                                <div className='col-6'>
                                                    <p className='text-white re_kyc_label_text'>Switch Amount</p>  
                                                    <p className='text-white switch_label' >{lbl_amt(this.state.modal_data.product_amt, this.currency_code)}</p>
                                                </div>
                                            </div>

                                            <div className='d-flex flex-row p-3'>
                                                <div className='col-6'>
                                                    <p className='text-white re_kyc_label_text'>They get</p>  
                                                    <p className='text-white switch_label' >{lbl_amt(this.state.modal_data.transfer_amt, this.currency_code)}</p>
                                                </div>
                                                <div className='col-6'>
                                                    <p className='text-white re_kyc_label_text'>Fee</p>  
                                                    <p className='text-white switch_label' >{lbl_amt(this.state.modal_data.product_fee, this.currency_code)}</p>
                                                </div>
                                            </div>

                                            <div className='px-3 py-3 d-flex flex-row align-itens-center'>

                                                <p className='text-white re_kyc_label_text col-6 m-0'>Txn Date</p>  
                                                <div className='pymt col-6 pr-0'>
                                                    <DatePicker closeOnSelect={true} id = "txn_date" onChange={(value) => {this.handleChange(value, "txn_date")}} value={ this.state.txn_date ? this.state.txn_date: moment().format('DD-MM-YYYY')}     dateFormat="DD-MM-YYYY" timeFormat={false} required="required"/>
                                                </div>
                                            </div>

                                            <div className='p-3'>
                                                <p className='text-white re_kyc_label_text pl-3'>Remark</p>
                                                <p className='text-white switch_label pl-3' >{(this.state.modal_data && this.state.modal_data.remarks) ? this.state.modal_data.remarks : 'NA'}</p>
                                            </div>

                                            <div className='p-3 '>
                                                <p className='text-white re_kyc_label_text pl-3'>Transaction ID</p>
                                                <div className='px-3  position-relative row'>
                                                    <div className='col-10'>
                                                        <input className='txn_input_box w-100' value={this.state.txn_id} onChange={(e) => {this.handleChange(e.target.value, 'txn_id')}} placeholder='Enter txn ID here'/>
                                                        { this.state.acc_stmt_data && 
                                                            <MdOutlineCheck size={20} className='txn_verify' color='#39E80E'/>
                                                        }
                                                    </div>
                                                    
                                                    <div className='col-2'>
                                                        <p className='text-primary py-2 pointer' style={{fontSize:'14px'}} onClick={()=>this.handleTxnId(this.state.txn_id)}><u>Verify</u></p>
                                                    </div>
                                                    
                                                </div>
                                                { this.state.txn_failed_msg &&
                                                    <div className='py-2'>
                                                        {this.state.txn_failed_msg.map(msg  =>(
                                                            this.handleSpecificMessage(msg)
                                                        ))}
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        { this.state.action && (this.state.action == 'approve' || this.state.action == 'reject') ?
                                            <>
                                                <div className="my-3 mx-2">
                                                    <textarea type="text" id="remarks" className='payment_proof_textArea' onChange={(e) => this.handleChange(e.target.value, 'remarks')} value={this.state.remarks} placeholder='Remarks' required="required"  maxLength='150'/>
                                                    <p className=' text-white m-2' style={{textAlign:'right', fontSize : '14px'}}>{this.state.remarks ? this.state.remarks.length : 0}/150</p>
                                                </div>

                                                <div className="my-3 mx-2 text-end" >
                                                    <button className='btn btn-secondary btn-lg mx-3' type='button' onClick={()=>{this.setState({action : null, remarks: null})}} >Back</button>
                                                    <button className='btn btn-primary btn-lg mx-3' type='button' disabled={(this.state.action == 'approve' && !this.state.acc_stmt_data )} onClick={()=> this.getAction(this.state.action)} >Submit</button>
                                                </div>
                                            </>
                                        :
                                
                                        <div className='py-3 d-flex flex-column'>
                                            <button className='btn btn-success btn-lg mb-2 mx-3' type='button'  disabled={!this.state.acc_stmt_data} onClick={()=>{this.setState({action : 'approve'})}} >Aprrove</button>
                                            <button className='btn btn-danger btn-lg mx-3'  type='button' onClick={()=>{this.setState({action : 'reject'})}} >Reject</button>
                                        </div>
                                        }
                                    </div>

                                </div>
                            </>
                        }
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
