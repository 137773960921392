import {  Navigate } from 'react-router-dom';
import React from 'react';
import FlowContainer from '../../common/container/core/FlowContainer';
import '../../../styles/flow.css';
import UploadCustomerTransaction from '../../borrower/component/UploadCustomerTransaction';
import {createCreditScoreModel} from '../../../actions/score_model_actions';
import ListFactorValues from '../../borrower/component/ListFactorValues';
class UploadCustomerTransactionContainer extends FlowContainer {
    state = {
      customer_factor: {},
      list_screen : false,
      run_btn_style : "show_div"
    }

    handleSubmit = (event)=>{

    this.setState({list_screen: false});

/*
     this.result = {
     "run_id" : "1909271739",
     "score" : 1000.0,
     "result" : "eligible",
     "result_config" : [
       {
         "id": 129,
         "score_result_code": "ineligible",
         "score_from": 0,
         "score_to": 200
       },
       {
         "id": 130,
         "score_result_code": "requires_flow_rm_approval",
         "score_from": 201,
         "score_to": 500
       },
       {
         "id": 131,
         "score_result_code": "eligible",
         "score_from": 501,
         "score_to": 1000
       },
     ],
     "meta_data" : [
       {
         "key" : "meta_txn_start_date",
         "value" : "2019-05-09"
       },
       {
         "key" : "meta_txn_end_date",
         "value" : "2019-08-06"
       },
       {
         "key" : "meta_txn_days",
         "value" : "51"
       },
       {
         "key" : "meta_cal_days",
         "value" : "90"
       },
       ]
     ,
     "score_values" : 
      [
       {
         "factor" : "5_day_avg_roi",
         "normal_value" : "10.0",
         "gross_value" : "0.855141863299845"
       },
       {
         "factor" : "15_day_avg_roi",
         "normal_value" : "10.0",
         "gross_value" : "1.0007336195985828"
       },
       {
         "factor" : "30_day_avg_roi",
         "normal_value" : "10.0",
         "gross_value" : "2.004410880922123"
       },
       {
         "factor" : "30_day_avg_txns",
         "normal_value" : "10.0",
         "gross_value" : "1254.0"
       },
     ],
   };
            */ 
    


    event.preventDefault();
    this.setState({run_btn_style: "disable_div"});
    alert("It may take a while. Please wait.");
    var customer_factor = this.state.customer_factor;
    customer_factor.country_code = this.country_code;
    createCreditScoreModel(this.req({customer_factor}))
        .then((response) => {
          if(!response){
            this.setState({run_btn_style: "show_div"});
            return 
          };
            if(response.status === "success"){
             
              this.setState({factors_list: response.data, list_screen: true});
             
            }else{
              this.setState({run_btn_style: "show_div"});
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          }
        );
  }


  render(){

  return (<>
     <div className="containerTopmargin container text-labelblue">
       <form id="borrower_form" onSubmit={this.handleSubmit}>
        <div id="">
        
        <UploadCustomerTransaction id="customer_factor" mode={this.props.mode} title="Upload Customer Transactions"   onComponentChange={this.handleComponentChange}
                /> <br/>
                
        <input type="button" className={`btn btn-primary ${this.state.run_btn_style}`} onClick={this.handleSubmit} value="Run" />

        <br/> 
        <br/>
        {this.state.list_screen && 
          <>
          <hr/> 
        <ListFactorValues customer_factor={this.state.customer_factor} factors_list={this.state.factors_list} />
        </>
        }
                <br/>
                
      </div> 
      </form>          
      </div>
      </>);
    }
}
export default UploadCustomerTransactionContainer;