import { Outlet } from "react-router-dom"
import Login from "../../UI/auth/component/Login"


const LoginLayout = ()=>{
    return(
        <div>
            <Login/>
            <Outlet/>
        </div>
    )
}

export default LoginLayout;