import React from 'react'
import CommonReactTable from "../../../helpers/commonReact_table";
import FlowComponent from '../../common/component/core/FlowComponent';
import { getPrevContCust } from '../../../actions/churn_actions'
import moment from 'moment';
import { lbl_date, title_case, useDebounce } from '../../../helpers/common_helper';
import { Link } from 'react-router-dom';
import {dd_value} from '../../../actions/common_actions';
import { FcCalendar } from 'react-icons/fc';
import { Modal ,Form} from 'react-bootstrap';
import { getHolidayList } from '../../../actions/lead_actions';
import { custFollowUpTask ,getChurnCallLog} from '../../../actions/churn_actions';
import {format_date,Homeacc_prvdrLogoselector,person_name, lbl_amt,RoundOffSplitter} from '../../../helpers/common_helper';
import { Config } from '../../../helpers/storage_helper';
import { AiFillDollarCircle } from "react-icons/ai";
import { BiCalendar,BiSend } from "react-icons/bi";
import {BsClockFill,BsPlusCircle} from "react-icons/bs";
import {get} from '../../../helpers/storage_helper';
import {CgCloseO} from "react-icons/cg";
import {RiArrowDropDownLine} from "react-icons/ri"
import {check_priv,} from "../../../helpers/storage_helper";
import UpdateTime from './UpdateTime';
import Select from "react-select";
import { BasicReactTable } from '../../../helpers/react_table';
import { connect } from 'react-redux';
import { setValuesIntoRedux } from '../../../Redux/Slices/CommonSlice';
import Softphone from '../../cs_management/container/cs_soft_phone';
import ConfirmModal from '../../common/component/ConfirmModal';
import withRouter from '../../../Router/Withrouter';


class CustFollowUp extends FlowComponent { 
      constructor(props) {
        super(props);
       this. state={
          row_data:[],
          column_data:[],
          market_holidays:[],
          reason : null,
          remarks:null,
          active_tab:"all",
          selected_reason:false,
          customer_intent_datas:[
            {title:"Customer ID",key:'cust_id'},
            {title:"RM Name",key:'flow_rel_mgr_name'},
            {title:"Business Name",key:'biz_name'},
            {title:"Location",key:'location'},
            {title:"Mobile Number",key:'mobile_num'},
            {title:"Total FA(s)",key:'tot_loans'},
            {title:"Registration Date",key:'reg_date'},
            {title:"Last FA Paid",key:'last_fa_paid_date'},
        ],
        cust_follow_up_reasons:{},
        las_fa_paid_options:[
          {value:'today',label:'Today'},
          {value:'last_7_days',label:'Last 7 days'},
          {value:'last_month',label:'Last 30 days'},
          {value:'all',label:'All'}]
  
      }
      this.channel = new BroadcastChannel('cs_soft_phone')

        // Create a debounced function in the constructor
        this.debouncedFunction = useDebounce(this.handleDebounce.bind(this), 300);
      }
    column_data=()=>{
      return[
        {
          Header : "Acc Prvdr",
          id: "acc_prvdr_code",
          sortable:false,
          accessor:row => <div className='d-flex align-items-center' style={{paddingLeft:'20px'}}> <Homeacc_prvdrLogoselector logotype={row.acc_prvdr_code}/> <p className='no-margin text-white' style={{paddingLeft:'20px'}}> {row.acc_num}</p>  </div> ,
         

        },
        {
          Header: "Customer ID",
          id: "cust_id",
          accessor:row=>row.cust_id,
          Cell: (cell) =>(<p className='cust_follow_text m-0' style={{textAlign:"center"}}><Link to={"/borrower/indiv/view/"+cell.value} target="_blank" >{cell.value}</Link></p>),
        

        },{
          Header: "Business Name",
          id: "biz_name",
          accessor:row=>row.biz_name,
          Cell: (cell) =>(<p className='cust_follow_text' style={{textAlign:"center"}}>{title_case(cell.value)}</p>),
          

        },
        {
          Header: "Mobile Number",
          id: "mobile_num",
          accessor:row=>row.mobile_num,
          Cell: (cell) =>(<p className='cust_follow_text' style={{textAlign:"center"}}>{title_case(cell.value)}</p>),
        

        },
        {
          Header: "Last Follow-up Date",
          id: "last_loan_paid_date",
          accessor:row=>row.last_loan_paid_date,
          Cell: (cell) =>(<p className='cust_follow_text text-white' style={{textAlign:"center"}}>{format_date(cell.value)}</p>),
          

        },
        {
          Header: "Reason",
          id: "last_follow_up_reason",
          accessor:row=>row.last_follow_up_reason,
          Cell: (cell) =>(<p className='cust_follow_text text-white' style={{textAlign:"center"}}>{dd_value(cell.value,"cust_follow_up_reasons")}</p>),
          

        },
        {
          Header: "No of Follow-ups",
          id: "follow_up_count",
          accessor:row=>row.follow_up_count,
          Cell: (cell) =>(<p className='cust_follow_text text-white' style={{textAlign:"center"}}>{cell.value}</p>),
          

        },
        {
          Header: "Next Follow up Date",
          id: "next_follow_up_date",
          accessor:row=>row.next_follow_up_date ? row.next_follow_up_date : 'NA',
          sortable:true,

          Cell: (cell) =>(<p className='cust_follow_text' style={{textAlign:"center"}}>{cell.value != 'NA' ? format_date(cell.value) : 'NA'}</p>),
          

        },
        {
          Header : "Action",
          id: "action",
          accessor:row => {return <button className='btn product_takeup_submit px-3 py-2 text-white' onClick={()=>this.cust_follow_up_handler(row) } >Call Log</button>},
          style : {textAlign:"center", fontSize:"13px"} ,

      },
      ]
    }

    cust_follow_up_handler = (data) => {
      var req={"cust_id":data.cust_id,"type":"past_churn","id":data.id,"profile_status":"open"}

      getChurnCallLog(this.req(req))
      .then((response)=>{
          if(!response){return}
          if(response.status=="success"){
            this.props.setValuesIntoRedux({cust_id:data.cust_id})

              this.setState({next_follow_up_date:data.next_follow_up_date ,cust_follow_up:data,previous_fa_datas:response.data})

              if(get('market') && get('market').country_code == "UGA"){
                this.setState({setCallTypeConfirm:true})
              }
              else{
                this.toggle_modal("no")
              }
              this.props.setValuesIntoRedux({cust_id:data.cust_id})

          }
      })
   }

   last_follow_up_date_handler(value,type){
    this.setState({[type]:value})
    }
   
    componentWillMount(){
      let screen_width=window.screen.availWidth
      this.setState({screen_width})  
      this.setOptionsToStateFromApi("cs_roster", {...this.def_req})

    }
    componentDidUpdate(prevProps,prevState){
      var m = document.getElementById("past_churn_remarks_scroll_end");
    if(m!=null){
      m.scrollTop = m.scrollHeight
    }
      if((this.props.tab == 'cust_follow_up' && prevProps.tab != 'cust_follow_up')||(this.state.active_tab !=prevState.active_tab) || (this.state.customer_success_id!=prevState.customer_success_id)){
        
         this.loadtable(this.state.cust_follow_up_reasons["cust_follow_up_reasons"]?this.state.cust_follow_up_reasons["cust_follow_up_reasons"]:null)
 
       }
    }

    loadtable(follow_ups_reasons=null){
      this.setState({loader:true,row_data:[],no_data:false})
      var req={'status' : 'pending_follow', 'criteria' : 'cust_to_follow',"type":this.state.active_tab } 
       if( this.state.customer_success_id){
        req["cs_id"]=this.state.customer_success_id
       }
       if(follow_ups_reasons!=null){
        req["reason"]=follow_ups_reasons

       }
       if(this.state.last_follow_up_date){
        req["follow_up_days"] = this.state.last_follow_up_date

       }

      getPrevContCust(this.req({req}))
         .then((response)=>{
             this.resp(response)
             if(!response)return this.setState({loader:false,no_data:true});
             if(response && response.status=='success'){
                 this.setState({row_data:response.data,column_data:this.column_data(),loader:false})
                 if(response.data.length==0){
                   this.setState({no_data:true})
                 }
                }
         })  
    }
    // loadtable(prevProps){
     
       
    // }

    handleClose=()=>{
      this.setState({cust_follow_up_modal:false,selected_reason:false,select_reason_modal:false, setCallTypeConfirm:false})
      this.props.setValuesIntoRedux({show_nums:false,cust_id:null})

  }
  changeDaysForReason=(e)=>{
    var reason_with_day = Config('follow_up_intervals').second_follow_up
    this.setState({resp_day_for_reason:reason_with_day[this.state.reason]})
    this.minDateAfter(reason_with_day[this.state.reason])
 }

minDateAfter(number) {
  if(Number.isInteger(number)){
      const dates = [];
      const today = moment();
      var i=0;
      while(dates.length<=number) {
        const nextDate = moment(today).add(i, 'days');
        if (nextDate.day() !== 0 &&  !this.state.market_holidays.includes(nextDate.format('YYYY-MM-DD'))) {
          dates.push(moment(nextDate).format('YYYY-MM-DD'));
        }
        i++
      }
      this.setState({min_follow_up_date:dates[dates.length-1]},()=>this.setState({next_follow_up_date:moment(this.state.min_follow_up_date). format('YYYY-MM-DD')}))
  }
  else{
      this.setState({min_follow_up_date:false})
  }
}
changeHandler = (e) => {      
  const toFilter = e
  const filterfrom = this.state.cust_wo_fa_list    
  const filteredData = filterfrom.filter(value => { 
    return(    
    value.cust_id !== null && value.cust_id.toLowerCase().includes(toFilter.toLowerCase())     
    )
  })
  this.setState(state=>state.copy = filteredData)		
}
  get_holidays=()=>{
      var request={
          days:14
      }
      getHolidayList(this.req(request))
      .then((response)=>{	
          if(!response){return};
          if(response.status == "success"){
              this.setState({market_holidays:response.data.market_holidays})
          }
          })
  }

 

// Handle input change
// const handleChange = ((event) => {
//   debouncedUpdate(event.target.value); 
// // useDebounce((event)=>{dispatch(setValuesIntoRedux({call_log_remarks :event.target.value })); console.log("Debounce", event.target.value)}, 300)
// });

handleDebounce(newValue) {
  console.log('Debounced Value:', newValue);
}

// handleDebounce = useDebounce((value,type,addl_key)=>this.setState({[type]:value,select_reason_modal:(addl_key!=null)?false:this.state.select_reason_modal}), 300)

  handleRemarks = (value,type,addl_key=null) =>{

    // this.handleDebounce(value,type,addl_key)
    this.debouncedFunction(()=>    this.setState({[type]:value,select_reason_modal:(addl_key!=null)?false:this.state.select_reason_modal})
, 300    );

   if(type=="remarks"){
    const myArray = value.split(" ");
    const nonEmptyRemarks = myArray.filter((remark) => remark !== "");
    this.setState({remarksArr:nonEmptyRemarks,remarks:value});
   }
  

  



  }

  // refreshList = () => {		
		
  //       this.componentDidupdate()
	// }


  handleCustFollowUp(e){

    if(e == 'close' ){
      if(!window.confirm("Are you sure want to close this customer "+" ?")) return;
    }else if(e!=="apply_fa"){
      if(!window.confirm("Are you sure want to Follow up this customer "+" ?")) return;
    }

  

    
   
    var request={
        type:'past_churn',
        reason:this.state.reason,
        cust_id:this.state.cust_follow_up.cust_id,
        id : this.state.cust_follow_up.id,
        status :( this.state.reason=="rm_disabled" || (this.state.reason=="not_interested") || (this.state.reason=="business_closed")) ?"closed":"in_progress" ,
        next_follow_up_date: this.state.next_follow_up_date ? ( this.state.reason=="apply_fa" || (this.state.reason=="not_interested") || (this.state.reason=="business_closed")) ? null :this.state.next_follow_up_date :null,
        order : 'to_follow',
        remarks : this.state.remarks,
        

    }
    if((get('market').country_code =='UGA') && (!this.state.using_physicalphone )){
      request.call_log_id = this.props.softPhoneState.call_log_details.call_log_id ? this.props.softPhoneState.call_log_details.call_log_id :null

   }
   if(get('market').country_code =='UGA' && (this.state.using_physicalphone )){
    request.physical_phone = true
   }
    

    custFollowUpTask(this.req(request))
    .then((response)=>{
      this.resp(response)
      if(!response)return;
      if(response){
        
        this.setState({reason:'',cust_follow_up:[],selected_reason:false,select_reason_modal:false})
        alert(response.message)
        if(get('market').country_code == 'UGA' && (!this.state.using_physicalphone )){
          this.props.setValuesIntoRedux({ call_log_details: null,CallLogModal:false, ModalIsOpen: false,incoming_call_details:[],incomingcall_num:null,callNum:null ,pending_rms_details :null,pending_cust_details:null})

          this.channel.postMessage({message:"submit_call_log", data:{call_log_details: null,CallLogModal:false, ModalIsOpen: false,incoming_call_details:[],incomingcall_num:null,callNum:null ,pending_rms_details :null,pending_cust_details:null}})
        }
        if(e=="apply_fa"){
          // window.location.replace(
          //   `fa_appl_for/${this.state.previous_fa_datas.borr_details.cust_id}`,
          // );
          this.props.navigate(`/fa_appl_for/${this.state.cust_follow_up.cust_id}`)
          }
          else{
            this.refreshList()
          }
        // this.refreshList();
        this.setState({cust_follow_up_modal:false,reason:'',setCallTypeConfirm:false})
        
      }
    }
    )}

    refreshList = () => {		
      this.setState({refresh: true})
      this. loadtable()
         
    }
    // componentDidMount() {
    //   this.refreshInterval = setInterval(() => {
    //     this.refreshList(); 
    //   }, 300000); 
    // }
  
    componentWillUnmount() {
      clearInterval(this.refreshInterval);
    }

    selectReasonHandler = (value,type)=>{
     this.setState({[type]:value})
    }

    pastChurnHandler=(val)=>{
      this.setState({active_tab:val})

    }
    ChurnReasonHandler(e){
      this.setState({reason:e.target.value,min_follow_up_date:null},()=>this.changeDaysForReason())
      
      
    }

    handlePurposeChange = (selected_options,id) =>{
      console.log('first', selected_options,id)
      this.setMultiselectOptionOnState(selected_options,id,this.state.cust_follow_up_reasons)
   }

   pastchurn_reasonHandler(){
    this.loadtable(this.state.cust_follow_up_reasons["cust_follow_up_reasons"] )

   }

   cust_follow_up_select = (data,items_to_remove=[])=>{
      var select_options = this.getMultiSelectOptions(data)

      if(items_to_remove.length>0 &&select_options && select_options.length>=0 ){
        select_options = select_options.filter(item => !items_to_remove.includes(item.value));
      }

      return select_options;
    }

    toggle_modal(type){
      if(type=="yes"){
          this.setState({using_physicalphone:true,cust_follow_up_modal:true})      
      } else if(type =="no"){
          this.setState({using_physicalphone:false,cust_follow_up_modal:true})
      }

      this.setState({setCallTypeConfirm:false})

  }

    checkDisableStatus = () =>{

      if(get('market').country_code == "UGA" && !this.state.using_physicalphone){
        var a  = false
          if(this.props.softPhoneState.call_log_details && this.props.softPhoneState.call_log_details.call_log_id ){
            a = true
          }
          return a
      }else{
          return true
      }
    }
  render() {
    var submit_bttn_status = this.state.reason && this.state.remarks  && (this.state.remarksArr && this.state.remarksArr.length >=3 && this.checkDisableStatus())
    
    return (
      <div className='mx-3 mb-5'>
         {check_priv("churns","churn_tabs") &&
         <div className='d-flex align-items-center justify-content-center row'>
         <div className='d-flex align-items-center pointer col-lg-3 col-md-12  px-0 py-0 past_churn_filter' >
          <p  className={` my-0 p-2 py-3 col-4 text-center pointer  ${(this.state.active_tab=="all") ? "active_past_churn_tab":''}`}  onClick={()=>{this.pastChurnHandler("all")}}>All</p>
          <p className={`  my-0 p-2 py-3 col-4 text-center pointer ${(this.state.active_tab=="initiated_by") ? "active_past_churn_tab":''}`}  onClick={()=>{this.pastChurnHandler("initiated_by")}}>Initiated</p>
          <p className={` my-0 col-4 py-3 p-2 text-center pointer ${(this.state.active_tab=="involved_by") ? "active_past_churn_tab":''}`}  onClick={()=>{this.pastChurnHandler("involved_by")}}>Involved</p>
         </div>

        </div>
        }    {/* churn priv */}
       

        {(this.state.active_tab !="all" ) &&
        <div className='d-flex align-items-center mt-4 justify-content-center row'>
          <div className='d-flex align-items-center col-lg-3 col-md-12 row  px-0 py-0 '>
                   
                            <p className='m-0 text-white  col-4 p-0' >Customer Success</p>
                            <div className='col-8 px-0  pl-1'>
                            <Form.Control as= "select" id="duration" onChange={(e)=>{this.handleRemarks(e.target.value,"customer_success_id")}} >
                                {this.state.cs_roster_dd} 
                        </Form.Control>  
                        </div>
       
          </div>
        </div>
        }


        <div className='d-flex align-items-center mt-4 justify-content-center row '>
          <div className='col-lg-7 d-flex justify-content-around row m-0 py-4' style={{border:'1px solid gray',borderRadius:'10px'}}>
              
                  <div className='d-flex align-items-center col-lg-5 pr-0 col-md-12 col-sm-12 row m-0'>
                        <div className='d-flex align-items-center col-lg-12 col-md-12  row px-0 py-0'>

                                <label className='col-lg-4 p-0 m-0'> <b>Pending Followups </b></label>
                                <div className='col-lg-8  pl-0 date_select'>
                                    <Select
                                        onChange={(e) => { this.last_follow_up_date_handler(e.value, "last_follow_up_date"); }}
                                        value={this.state.select_value}
                                        options={this.state.las_fa_paid_options}
                                        placeholder="Days"
                                        styles={this.select_custom_styles()}
                                        classNamePrefix="my-select" // Add a custom class prefix to target the components
                                    />
                                </div>
                            
                        </div>
                    

                  </div>

                  <div className='d-flex align-items-center col-lg-7 col-md-12 col-sm-12  row  px-0 py-0 ' >
                   
                   <p className='m-0 text-white  col-2 p-0 text-center' > <b>Reason </b> </p>
                   <div className={` px-0  pl-1 ${((this.state.cust_follow_up_reasons["cust_follow_up_reasons"])|| (this.state.last_follow_up_date)) ? "col-7" :"col-9"}`}>
                  
                   <Select onChange={(e) =>{this.handlePurposeChange(e,"cust_follow_up_reasons");}}
                     value = {this.state.select_value}
                     options={this.cust_follow_up_select('cust_follow_up_reasons')} 
                     placeHolder="Choose"
                     styles={this.select_custom_styles()}
                     classNamePrefix="my-select" 
                     isMulti/> 
                   </div>
                   {((this.state.cust_follow_up_reasons["cust_follow_up_reasons"])|| (this.state.last_follow_up_date)) ?
                            <div className='col-1'>
                            <button type="button" class="btn btn-primary btn-sm " onClick={()=>{this.pastchurn_reasonHandler()}}>Submit</button>
                            {/* <p className='m-0 text-white pointer' ><BiFilterAlt size="20"/></p> */}

                            </div>
                            :""
                  }
                  
                </div>
                  
                 
          </div>
         
        </div>

  
        {this.state.cust_follow_up&&
                <Modal className='cust_follow_up' size={"xl"} show={this.state.cust_follow_up_modal} onHide={this.handleClose}  keyboard={false}>
                    <Modal.Header class="modal-header" >
                        <h4 class="modal-title py-2" id="exampleModalLabel">Progress Tracker</h4>
                        <div>
                            <button type="button" className="close d-flex align-items-center" onClick={this.handleClose}>
                                <span aria-hidden="true" className='close_call_log'>&times;</span>
                            </button>
                        </div>
                    </Modal.Header>
                    <Modal.Body class="modal-body">
                    <div className='card p-3'>

                      <div className='row mx-0 '>
                       <div className='col-md-12 col-lg-8 col-sm-12'>
                      <fieldset className='border border-white p-2 my-2 ' style={{borderRadius:'10px'}}>
                          <legend className='w-auto text-light ml-4 px-2'>{this.state.previous_fa_datas.borr_details.owner_name}</legend>

                          <div className='row m-0 d-flex justify-content-between'>
                            {this.state.customer_intent_datas.map((item,idx)=>{return(
                              
                                <div className='col-lg-6 px-4 py-3 col-md-6 px-0'>
                                      <div className='d-flex align-items-center'>
                                          <label className=" p-0 m-0 personalDetailsviewText" ><b>{item.title} : </b></label>
                                          <label className=" pb-0 m-0 pl-3 cust_follow_up_font" style={{fontSize:'12px'}}>{(item.key=="reg_date")?format_date(this.state.previous_fa_datas.borr_details[item.key]) :
                                          (item.key=="last_fa_paid_date")? <>{ this.state.previous_fa_datas.borr_details["last_fa_paid_diff"] } ago </> :
                                          <>
                                          {item.key=="location" ? dd_value(this.state.previous_fa_datas.borr_details[item.key]) :  this.state.previous_fa_datas.borr_details[item.key]}
                                          </>}</label>  
                                      </div>  
                                </div> 
                            
                            )})}
                          </div>

                      </fieldset>

                      <div className='row m-0 col-12 px-0 pt-4'>

                      {this.state.previous_fa_datas &&
                          <fieldset className={`border border-white p-2 my-2 ${(this.state.previous_fa_datas.hasOwnProperty("past_reasons") && this.state.previous_fa_datas.past_reasons.length>0) ?"col-lg-12 col-mg-12": "col-md-12"}`} style={{borderRadius:'10px'}}>
                              <legend className='w-auto text-light ml-4 px-3'> Previous FAs Details <span className='ml-1' style={{fontSize:'12px'}}> (Overall Performance : {this.state.previous_fa_datas.borr_details.cust_perff} %) </span></legend>
                          
                              <div className='row mx-0 my-3 mb-4 d-flex justify-content-center'>
                              { this.state.previous_fa_datas.last_five_fas.map((item,idx)=>{return(
                                  
                                  <div className={` ${(this.state.previous_fa_datas.hasOwnProperty("past_reasons") && this.state.previous_fa_datas.past_reasons.length>0) ? "col-lg-4 col-md-4 my-4" :"col-lg-2 p-0  col-md-4 "}`}>
                                          <div className='p-3 previous_fa_details_card'>
                                              
                                              <p className=" p-0 m-0 text-right " style={{fontSize:'16px'}} ><b> #{idx+1} </b></p>
                                                <div className='d-flex align-items-center' >
                                                  <p className='m-0'><AiFillDollarCircle size={20}/></p>
                                                  <span className='text-white pl-3 py-0 m-0 sizsm'  >{ lbl_amt(item.loan_principal,get('market').currency_code) }</span>
                                                </div>
                                                <div className='d-flex align-items-center my-3' >
                                                  <p className='m-0'><BiCalendar size={20}/></p>
                                                  <label className='text-white pl-3 sizsm m-0'  >{item.due_date?format_date(item.due_date):''}</label>
                                                </div>

                                                <div className='d-flex align-items-center my-3' >
                                                  <p className='m-0'><BsClockFill color={(item.delay_days=="0 days")?"#088b00":''} size={17}/></p>
                                                  <label className='text-white pl-3 sizsm m-0'  > {(item.delay_days=="0 days" )?<span style={{color:`#088b00`}}><b>Paid Ontime</b></span> : <>Delayed :{item.delay_days}</>}</label>
                                                </div>
                                                
                                          </div>  
                                  </div> 
                              
                              )})}
                              </div>

                          </fieldset>
                          }

                      </div>
                      </div>


                     
                      { this.state.previous_fa_datas && (this.state.previous_fa_datas.hasOwnProperty("past_reasons") && this.state.previous_fa_datas.past_reasons.length>0) &&
                        
                          <fieldset className={`border border-white p-2 my-2 col-md-12 col-lg-4 reason_remarks_bg_img `} style={{borderRadius:'10px',position:'relative'}}>
                              <legend className='w-auto text-light ml-4 px-3'> Reasons & Remarks</legend>
                              
        
                             
                              <div id="past_churn_remarks_scroll_end" className={`row col-lg-12 col-md-12 col-sm-12 m-0 p-0  ${this.state.previous_fa_datas.past_reasons.length>4 ? "past_churn_remarks_scroll":""}`}>
                              { this.state.previous_fa_datas.past_reasons.map((item,idx)=>{return(
                                  <div className={`col-lg-12   my-2 col-md-12  justify-content-start  d-flex ${(item.csm_id === get("user_id")) ? "px-4" : "px-4"}  `}>
                                      


                                      <div class= {`message_box ${(item.csm_id === get("user_id")) ? "sent_message_box":""}`}>
                                        {/* <div class= {`arrow ${"left_arrow"}`}>
                                          <div class={`outer ${"left_arrow_outer"} `}></div>
                                         
                                        </div> */}
                                        <div class={`message-body px-3 ${"left_message-body "}`}>
                                          <h2 className='text-white m-0 sizem'><b>{item.reason? dd_value(item.reason,"cust_follow_up_reasons"):"Will needed to when required"}</b></h2>
                                          <p className='text-white m-0'>{ item.remarks?dd_value(item.remarks):""}</p>
                                          <p className='text-right m-0 pt-2'>
                                          <span className='text-white text-right sizsm d-flex  justify-content-end align-items-center' style={{color:'#A8A8A8',fontSize:'10px'}}>{
                                            <UpdateTime key={idx} timestamp={item.day_diff } country_code={get('market').country_code} />

                                          } { (item.csm_id === get("user_id")) ? <p className='m-0 pl-2'> by YOU</p>: <p className='m-0 pl-2'> by {item.cs_name}</p>} </span>

                                       </p>
                                        </div>
                                      </div>
                                      
                                      
                                  </div>
                                  
                              )})}    
                              </div>
                              <div className={`col-12 p-0 ${this.state.screen_width > 778 ? "reason_remarks_past_churn" :"tab_remarks_past_churn"}`} >
                               
                              <div className='row m-0 col-12  pb-2 '  >
                               
                              <div className={`col-12 pl-0 `}>
                               
                              <div className='px-0 mx-0 pb-2 mb-2' style={{border:'1px solid gray',background:'#31364a',borderRadius:'15px'}}>
                              <div className='row m-0 p-0 w-100'>
                              <div className='col-md-12'>
                                  <div className='w-100 col-12 mt-2 row d-flex align-items-center justify-content-between mx-0 p-0'>
                                  <label className='col-3 p-0 font mb-0 ' >Reason  <span className='product_red_star'>*</span>  </label>
                                  <div className='col-9 px-0'>
                                  <select className='form-control col-12' onChange={(e)=>{this.setState({reason_selected:false},this.ChurnReasonHandler(e))}}>       
                                        {this.getSelectOptions('cust_follow_up_reasons')}
                                   </select>  
                                   </div>
                                     
                                  </div>
                                  <div className='col-12 w-100 p-0 mt-3 mb-3'>
                                  <label className='col-12 p-0 font mb-0 mt-2' >Remarks <span className='product_red_star'>*</span> </label>

                                  <textarea class="form-control w-100 pl-0 py-3" id="" rows="1" onChange={(e)=> this.handleRemarks(e.target.value,"remarks","addl_key")}  maxlength="150"   ></textarea>

                                  <p className='text-white text-right m-0 pr-4'> {this.state.remarks ? this.state.remarks.length :"0"}/150</p>
                                {( this.state.remarks &&this.state.remarksArr&& this.state.remarksArr.length < 3)&&
                                    <p className='m-0  churn_alert_text' >Please include more than 3 words in your remark for clarity</p>

                                }
                                <p className='churn_alert_text' >{ (this.state.remarks && this.state.remarks.length==150)?"You have reached your character limit":"" }</p>
                                  </div>
                              </div>
                             </div>
                              </div>

                              

                               </div>
                               {/* <div className='col-2 px-0' style={{alignSelf:'end',}}>
                                <div className={` msg_send_icon pointer ${(this.state.reason && this.state.remarks )?"":"disable_div"}`}  onClick={()=>this.handleCustFollowUp()} >
                                  <p className='m-0 text-center'><BiSend size="25" color={"white"}/></p>
                                </div>
                               </div> */}

                         
                              </div>
                              </div>



                             

                          </fieldset>
                          
                        } 
                      
                      

                      </div>

                      

                      <div className='mt-4 col d-flex w-100 justify-content-center'>
                        
                          {this.state.reason=="apply_fa" ?
                          <button className='btn btn-success product_takeup_submit ml-3' disabled={(this.state.reason && this.state.remarks )?false:true} onClick={()=>this.handleCustFollowUp("apply_fa")}  >APPLY FA</button>
                          : 
                         <button className='btn product_takeup_submit' onClick={()=>this.handleCustFollowUp()} disabled={ submit_bttn_status?false:true} >Submit</button>

                        }
                      </div>
                      </div>
                    </Modal.Body>
                </Modal>
        }
       
      
       <> 
        {this.state.row_data.length>0&&
        <div className={`cust_next_follow_up mt-5 ${this.state.cust_follow_up_modal ? "disable_div":''}`} style={{marginTop:5}}>
        <p className='m-0 text-white pl-3 pb-2'> <b> Total Entries : {this.state.row_data ? this.state.row_data.length:"" }</b> </p>

        {/* <CommonReactTable className='border' exportCSV={true} csvFile={"Churn Management - Past Churns"} csvData={this.state.row_data} row_data={this.state.row_data} background_header={true}   defaultSorted={[{ id: "next_follow_up_date", asc: true }]}  column_data={this.state.column_data}  FName="Customer Follow Up" global_search={false} showPag={this.state.row_data.length>9? true:false} resizable={false} minRow={0} default_page_size={10} addBackground={true} searchData={[{...this.state.row_data,title:'Churn Management'}]}/>  */}

        <BasicReactTable csv_file={true} row_data={this.state.row_data !== undefined ? this.state.row_data : []} column_data={this.state.column_data} FName={"Churn Management - Past Churns"}  pagination={this.state.row_data.length>9? true:false} default_page_size = {15}   defaultSorted={[{ id: "next_follow_up_date", asc: true }]}  searchData={[{...this.state.row_data,title:'Churn Management'}]} header_class='table_background_header'/>  

        </div>}
        {this.state.no_data&&<h6 className='text-center text-light p-5 m-5'>No Data Found</h6>}
        {this.state.row_data.length==0&&this.state.loader&&
        <>
        
        <>
          <p className='text-light  text-center' style={{marginTop:'35vh'}}>Loading...</p>
          <div className="product_takeup_loader"></div>
        </>
      
        </>}
        </>

        {this.state.setCallTypeConfirm &&
                  <ConfirmModal alert_message={"Please note that this activity will be logged.\nKindly confirm the device you used to initiate the call?"} is_toggle={(a) => { this.toggle_modal(a) }} 
                  SoftphoneCallOptions= {true}/>
                
                }


        {this.props.softPhoneState.softPhone && (!this.props.softPhoneState.missed_calls_view)   && (!this.props.softPhoneState.isIncomingCall) && (!this.props.softPhoneState.outgoingCall) && (this.state.cust_follow_up_modal && this.props.softPhoneState.show_nums) &&( get('market').country_code =='UGA' && (!this.state.using_physicalphone ) ) &&
				<div className='Call_log_section zoomIn_animation'>
					<Softphone screen_from ={"churn_management"} cust_id={this.state.cust_follow_up?this.state.cust_follow_up.cust_id:null}/>
             
				</div>


			}
      </div>
    )
  }
}




const mapStateToProps = (state) => {
	return {
	  softPhoneState: state.cs_soft_phone,

	};
  };

const mapDispatchToProps ={
    setValuesIntoRedux
  
  };


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustFollowUp));


