import React from 'react';


export const LOADING_MSG = "LOADING ADDRESS FORM. PLEASE WAIT";
export const NO_COUNTRY_MSG = "NO COUNTRY TO LOAD";

export const LOADING_OPT = [<option key="-2" value="-2">[ loading . . . ]</option>];
export const NO_RES_OPT = [<option value="">[ No Results ]</option>];
export const DEF_OPT = [<option key="" value="">choose</option>];
export const DEF_OBJ_OPT = [<option key="" value="{}">choose</option>];


