import { get_path } from '../helpers/common_helper';
import {call_fetch} from './../helpers/fetch_helper';

export const upload_file = request  => {
//  console.log(request);
   return call_fetch(get_path('file_upload') + '/file/upload', request);
}

export const remove_file = request  => {
//  console.log(request);
   return call_fetch(get_path('file_upload') + '/file/remove', request);
}

export const get_file_rel_path = request  => {
//  console.log(request);
   return call_fetch(get_path('file_upload') + '/file/rel_path', request);
}

export const upload_national_id = request  => {
      return call_fetch(get_path('file_upload') + '/file/id_textract', request);
   }



