import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import '../../../styles/flow.css';
import {lbl_repayment_amt, loan_amt, lbl_amt, lbl_flow_fee_obj, lbl_dur,is_per_day_loan, lbl_flow_fee_duration} from '../../../helpers/product_helper';
import {getValueFromLangJson, percent_rnd} from '../../../helpers/common_helper';
import {Alert,Row, Col } from 'react-bootstrap';
import {format_date,rt_algn} from '../../../helpers/common_helper';
class ProductPanel extends FlowComponent {
	state = {
		variant: "primary"
	};
	componentWillMount(){

		if(this.props.parent == 'apply'){
			this.setState({variant: 'dark'})
		}else if(this.props.parent == 'loan_appl'){
			this.setState({variant: 'success'})
		}else if(this.props.parent == 'loan'){
			this.setState({variant: 'primary'})
		}
	}



	FloatAdvance1 = (loan_status) => (
				<>
					
					
					<div className="card viewFloatApplication">
						<div className="row viewFloatApplicationTitle">
							<h3>{getValueFromLangJson('float_advance')}</h3>
						</div>
						<div className="card-body">
							<div className="row floatadvance-body">
							{loan_status != "pending_approval" &&
									<div className="col-6 row">
									<span className="col-sm-5">{getValueFromLangJson('approval_date')}</span>
									<div className="col-sm-7">
											<label>{format_date(this.props.product.loan_approved_date)}</label>
									</div>
								</div>
							}
							<div className="col-6 row">
								<span className="col-sm-5">{getValueFromLangJson('approver_name')}</span>
								<div className="col-sm-7">
										<label>{this.props.product.loan_approver_name}</label>
								</div>
							</div>

						{(loan_status != "pending_disbursal" && loan_status != "pending_approval")
						&&
						<>	
						<div className="col-6 row">
							<span className="col-sm-5">{getValueFromLangJson('fa_with_fee')}</span>
							<div className="col-sm-7">
									<label>{rt_algn(lbl_amt(this.props.product.due_amount, this.currency_code))}</label>
							</div>
						</div>	
							{(loan_status != "approved" && loan_status != "pending_approval")
							&&
							<div className="col-6 row">
								<span className="col-sm-5">{getValueFromLangJson('fa_repaid')}</span>
								<div className="col-sm-7">
										<label>{rt_algn(lbl_amt(this.props.product.paid_amount, this.currency_code))}</label>
								</div>
							</div>
							}
						</>
						}
					

					{loan_status != "pending_disbursal" && loan_status != "approved" && loan_status != "pending_approval"
					&&
					<>
						
						<div className="col-6 row">
							<span className="col-sm-5">{getValueFromLangJson('due_date')}</span>
							<div className="col-sm-7">
									<label>{format_date(this.props.product.due_date)}</label>
							</div>
						</div>
						{loan_status != "pending_disbursal" && loan_status != "approved"
							&&
						<div className="col-6 row">
							<span className="col-sm-5"> {getValueFromLangJson('disb_date')}</span>
							<div className="col-sm-7">
									<label>{format_date(this.props.product.disbursal_date)}</label>
							</div>
						</div>
						}	

						{this.props.product.provisional_penalty > 0 &&
						<>
							<div className="col-6 row">
								<span className="col-sm-5">{getValueFromLangJson("provisional_penalty")}</span>
								<div className="col-sm-7">
										<label>{rt_algn(lbl_amt(this.props.product.provisional_penalty, this.currency_code))}</label>
								</div>
							</div>	
							<div className="col-6 row">
								<span className="col-sm-5">{getValueFromLangJson('penalty_paid')}</span>
								<div className="col-sm-7">
										<label>{rt_algn(lbl_amt(this.props.product.penalty_collected, this.currency_code))}</label>
								</div>
							</div>
						</>
						}
						<div className="col-6 row">
							<span className="col-sm-5">{getValueFromLangJson('current_due_amount')}</span>
							<div className="col-sm-7">
									<label>{rt_algn(lbl_amt(this.props.product.current_os_amount, this.currency_code))}</label>
							</div>
						</div>	
					</>	
				}

						{ this.props.product.repayment_date ? 
						<div className="col-6 row">
							<span className="col-sm-5">{getValueFromLangJson('repayment_date')}</span>
							<div className="col-sm-7">
									<label>{format_date(this.props.product.repayment_date)}</label>
							</div>
						</div>
						:null}

						{ loan_status == "overdue" &&
						<div className="col-6 row">
							<span className="col-sm-5">{getValueFromLangJson('overdue_days')}</span>
							<div className="col-sm-7">
									<label>{this.props.product.days_overdue} {getValueFromLangJson('days')} ({this.props.product.penalty_days} {getValueFromLangJson('penalty_days')}) </label>
							</div>
						</div>
						}
						</div>
					</div>
					</div>
				</>
)


Product1 = (loan_status) => (
				<>
					
					<div className="floatAdvanceperiodHeaderBox">
									<h2 className="floatAdvanceperiodHeader no-margin">{getValueFromLangJson('product')}</h2>
								</div>
								<div className="row no-margin pastFloatAdvanceBox">
									<div className="col-lg-6 no-padding personalDetailsbox">
										<ul>
											<li>
												<div className="row no-margin">
													<div className="no-padding col-lg-5">
														<p className="personalDetailstext no-margin">{getValueFromLangJson('product_name')}</p>
													</div>
													<div className="col-lg-7 no-padding">
														<p className="personalDetailstextval no-margin">{this.props.product.product_name}</p>
													</div>                        
												</div>
											</li>
											<li>
												<div className="row no-margin">
													<div className="no-padding col-lg-5">
														<p className="personalDetailstext no-margin">{getValueFromLangJson('fa_amount')}</p>
													</div>
													<div className="col-lg-7 no-padding">
														<ul className="personalPastDetailstext">
															<li>{lbl_amt(loan_amt(this.props.product), this.currency_code)}</li>                          
														</ul>                            
													</div> 
												</div>
											</li> 
											<li>
												<div className="row no-margin">
													<div className="no-padding col-lg-5">
														<p className="personalDetailstext no-margin">{getValueFromLangJson('flow_fee')}</p>
													</div>
													<div className="col-lg-7 no-padding">
														<ul className="personalPastDetailstext">
															<li>{lbl_flow_fee_obj(this.props.product,this.currency_code)}</li>
														</ul>                            
													</div> 
												</div>
											</li>                            
										</ul>
									</div>
									<div className="col-lg-6 no-padding personalDetailsbox">
										<ul>
											<li>
												<div className="row no-margin">
													<div className="no-padding col-lg-5">
														<p className="personalDetailstext no-margin">{getValueFromLangJson('credit_score')}</p>
													</div>
													<div className="col-lg-7 no-padding">
														<ul className="personalPastDetailstext">
															<li>{this.props.credit_score} / 1000</li>                           
														</ul>
													</div>                        
												</div>
											</li>
											<li>
												<div className="row no-margin">
													<div className="no-padding col-lg-5">
														<p className="personalDetailstext no-margin">{getValueFromLangJson('duration')}</p>
													</div>
													<div className="col-lg-7 no-padding">
														<p className="personalDetailstextval no-margin">{lbl_dur(this.props.product.duration, this.props.product.flow_fee_duration, this.props.product.loan_purpose)}</p>                          
													</div> 
												</div>
											</li>   
											<li>
												<div className="row no-margin">
													<div className="no-padding col-lg-5">
														<p className="personalDetailstext no-margin">{getValueFromLangJson('repayment_amount')}</p>
													</div>
													<div className="col-lg-7 no-padding">
														<p className="personalDetailstextval no-margin">{lbl_repayment_amt(this.props.product, this.currency_code)}</p>
													</div> 
												</div>
											</li>                            
										</ul>
									</div>
								</div>
					
					
				{/* {is_per_day_loan(this.props.product.flow_fee_duration)  
						&&
						 <div>
							*<i>Total repayment if float is utilized for all the {this.props.product.duration} day duration</i>
						<br/></div>
					} */}
			</>
)

	
render(){


	return(
			<>
				
				{this.Product1()}
				{/*this.props.parent != 'loan' && this.PastLoans1()*/}
				
				{/*{ this.props.parent == 'loan' && this.FloatAdvance1(this.props.product.status)}*/}
				{ this.props.parent != 'apply' && this.FloatAdvance1(this.props.product.status)}
				
		</>
		);
	}

}

export default ProductPanel;