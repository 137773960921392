import {} from 'react-router-dom';
import React from 'react';
import '../../../styles/flow.css';
import FlowComponent from '../../common/component/core/FlowComponent';

//import { getDataKeys } from '../../../actions/master_data_actions';

class MasterDataKey extends FlowComponent{

	state = {
		data_keys : "",
		comp_name: "master_data_key",
		//country_spcfc_div_style: "disable_div"
	}

	componentWillMount() {

		const country_code = this.country_code;
		const status = "enabled";
		const add_no_parent = true;
	    this.setOptionsListToStateFromAppApi("common/key", "all_data_keys", this.req({country_code,status,add_no_parent}), ["data_key", "data_key"]);
	        
	}

	globalRadiobtn = (event) => {
		if(event.target.checked){
			//alert("checking");
			this.props.data['country_code'] = "*";
			//this.setState({country_spcfc_div_style: "disable_div"});
		}else{
			this.props.data['country_code'] = this.country_code;
		}
	}

	countrySpcfcRadiobtn = (event) => {
		if(event.target.checked){
			
			this.props.data['country_code'] = this.country_code;
		}
	}

	

  render(){
 
    return (


	 <div id="" >
	 	<div className="container text-labelblue">
	 		 <div className="row">
	 		 <h5>Add Master Data Key</h5>
	 		 </div>
	 		 <br/>
	 		 <div className="row">
			       	<div className="col-md-3">
			        		<span>Data Group</span><font className="text-danger"> * </font>
			        		<select type="text" id="data_group" value={this.props.data.data_group} onChange={this.handleChange} className="form-control">
			        		{this.getSelectOptions("data_group")}
			        		</select>
			       	</div>
			</div>	
			<br/>

		      <div className="row">
		       	<div className="col-md-3">
		        		<span>Data Type</span><font className="text-danger"> * </font>
		        		<select type="text" id="data_type" value={this.props.data.data_type} onChange={this.handleChange} className="form-control">
		        		{this.getSelectOptions("data_type")}
		        		</select>
		       	</div>
			</div>	
			<br/>

	         <div className="row">
	         <div className="col-md-3">
	           <span>Data Key</span><font className="text-danger">   *  </font>
	           <input id="data_key" value={this.props.data.data_key} onChange={this.handleChange} required="required" className="form-control" />            
	         </div>      
	         </div>
	         <br/>

	         <div className="row">
	         <div className="col-md-3">
	         	<span>Parent Data Key</span>
	         	<select id="parent_data_key" value={this.props.data.parent_data_key} onChange={this.handleChange} className="form-control">
         		{this.state.all_data_keys_dd}
             	</select>
	         </div>
	         </div>
	         <br/>

	   
			 

	         {/*<div className="row">
	         <div className="col-md-3">
	           <span>Data Type ( eg: common, address)</span><font className="text-danger">   *  </font>
	           <input id="data_type" value={this.props.data.data_type} onChange={this.handleChange} required="required" className="form-control" />            
	         </div>      
	         </div>
	         <br/>*/}

	         <div className="row">
	         <div className="col-md-3">
	           <span>Key Description</span>
	           <input id="key_desc" value={this.props.data.key_desc} onChange={this.handleChange} required="required" className="form-control" />            
	         </div>      
	         </div>
	         <br/>
	         
	         <div className="row">
	         <div className="col-md-3">
	         	<b><span>Data Scope</span></b>
	         	<br/>&nbsp;&nbsp;
	         		<label><input type="radio" name="data_scope" onClick={this.globalRadiobtn} defaultChecked/>&nbsp;&nbsp; Global</label>
                <br/>&nbsp;&nbsp;
                    <label className={this.state.country_spcfc_div_style}><input type="radio" name="data_scope" onClick={this.countrySpcfcRadiobtn} />&nbsp;&nbsp;{this.country_code}</label>
	         </div>
	         </div>

	        {/* <div className="row">
	         <div className="col-md-3">
	         	<input type="button" className="btn btn-primary" value="Save" />
	         </div>
	         </div>*/}
        </div>
    </div>
    );
  }
}


export default MasterDataKey;

