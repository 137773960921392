import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from 'react-icons/ai';
// import Flag from "react-flags";
import { Link } from 'react-router-dom';
import { get_path } from "../../../helpers/common_helper";
import CustomFlag from "../../common/component/CustomFlag";



const NotificationModal = ({notification, handle_modal, handle_bell_click, id})=>{

  useEffect(()=>{
    // handle_bell_click()
  },[])

    const portfolio_modal = ()=>{
        return(
            <Modal className='portfolio_modal' show={notification.portfolio_modal} onHide={()=>handle_modal(false)}  keyboard={false} >
                
               <div className=' p-2 d-flex justify-content-center  align-items-center' style={{backgroundColor:'#484848', color:'white', fontSize:'16px', fontWeight:500, position:'relative'}}>
                    <Modal.Header className='p-3 d-flex ' >
                      {(notification.portfolio_details.type=="flagged_customers" ) ? 
                      "Flagging Customers " :

                      (notification.portfolio_details.type=="duplicate_disbursal_alert") ? notification.portfolio_details.message_content.title :

                      <>
                      {`Portfolio Health Alert [${notification.portfolio_details.message_content.addl_data.type?notification.portfolio_details.message_content.addl_data.type:''}]`}
                      </>}
                    
                    </Modal.Header>
                    <div style={{position:'absolute', right:5, cursor:'pointer'}}>
                      <AiOutlineClose size={25} color='white' onClick={()=>handle_modal(false)}/>

                    </div>
                </div>

            
                
                <div>
                 
                  <Modal.Body className='py-2'>  
                    
                    {(notification.portfolio_details.type=="flagged_customers" ) ?
                     <p className="text-white m-0 sizel py-4 pb-5" style={{lineHeight:'35px'}}>
                      {notification.portfolio_details.message_content.addl_data.msg}
                     </p>:

                      (notification.portfolio_details.type=="duplicate_disbursal_alert" ) ?
                      <p className="text-white m-0 sizel py-4 pb-5" style={{lineHeight:'35px'}}>
                      {notification.portfolio_details.message_content.addl_data.msg}
                      </p>:

                      <div>
                      <div className='justify-content-center'>
                        <div className='p-2 d-flex justify-content-center'>
                          {notification.portfolio_details.message_content.addl_data.ph_level =='overall'? 
                          <CustomFlag country_code={notification.portfolio_details.message_content.addl_data.image_path} height={64} width={64}/>
                          :<img src={get_path("view_file") + notification.portfolio_details.message_content.addl_data.image_path} alt='image'/>}
                        </div>
                        
                        <div className='mt-2' style={{color:'white', fontSize:'20px', fontWeight:700,textAlign:'center'}}>{notification.portfolio_details.message_content.addl_data.ph_level=='rm'? notification.portfolio_details.message_content.addl_data.rm_name: notification.portfolio_details.message_content.addl_data.ph_level=='tm'? notification.portfolio_details.message_content.addl_data.tm_name:notification.portfolio_details.message_content.addl_data.ph_level=='overall'? notification.portfolio_details.message_content.addl_data.market_name:''}</div>


                      </div>
                      <div style={{fontSize:'14px', fontWeight:400, color:'white'}} className='p-5'>
                        {notification.portfolio_details.message_content.body} For More Details Please <Link to={"/report/par_health_report"} state = {{...notification.portfolio_details,from_notification:true}} 
                        onClick={(e)=>{() => e.preventDefault(); handle_modal(false);}}
                        >
                          Click here. </Link>
                      </div>
                      </div> 
                    
                    }
                 



              </Modal.Body>
               </div>

              </Modal>

        )
    }


    if(id == "portfolio_modal"){
       return   portfolio_modal()
    }


}

export default NotificationModal