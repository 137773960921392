import { get_path } from '../helpers/common_helper';
import {call_fetch} from './../helpers/fetch_helper';


export const createRelationshipManager = request => {
   return call_fetch(get_path('admin') + '/rel_mgr', request);
}

export const viewRelationshipManager = request => {
   return call_fetch(get_path('admin') + '/rel_mgr/view', request);
}

export const listRelationshipManager = request => {
   return call_fetch(get_path('admin') + '/rel_mgr/list', request);
}

export const rm_reassign = request => {
   return call_fetch(get_path('admin') + '/rm_assign/rm_details', request);
}
export const assign_rm_territories=request=>{
   return call_fetch(get_path('admin') + '/rm_assign', request);
}
export const cust_details=request=>{
   return call_fetch(get_path('admin') + '/rm_assign/cust_details', request);
}
export const viewRMTarget = request =>{
   return call_fetch(get_path('admin')+'/rm_targets/view',request)
}
export const updateRMTarget = request =>{
   return call_fetch(get_path('admin')+'/rm_targets/update',request)
}
export const getSMSDetails = request =>{
   return call_fetch(get_path('admin')+'/rm_assign/SMS_details',request)
}

export const getCusomerCount = request =>{
   return call_fetch(get_path('admin')+'/rm_assign/cust_count',request)
}


export const getParishwithCustCount = request =>{
   return call_fetch(get_path('admin')+'/rm_assign/parish_list',request)
}
export const getLiveCoordinates = request =>{
   return call_fetch(get_path('admin')+'/rm_targets/get_live_coordinates', request)
}

export const getLiveRoutes = request =>{
   return call_fetch(get_path('admin')+'/rm_targets/get_rm_routes', request)
}

export const temp_assign_details=request=>{
   return call_fetch(get_path('admin') + '/rm_assign/temp_rm_details', request);
}
/*export const updateRelationshipManager = request => {
   return call_fetch(get_path('admin') + '/rel_mgr/update', request);
}*/