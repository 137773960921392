import React from 'react';
import FlowContainer from '../../common/container/core/FlowContainer';
import moment from "moment";
import { BiAlarm ,BiUser,BiMoney,BiIdCard,BiTask,BiCommentAdd,BiDollarCircle} from "react-icons/bi";
import {RoundOffSplitter, convertMinutesToHours, get_cs_agent_names} from '../../../helpers/common_helper';
import{BsFillPersonCheckFill} from 'react-icons/bs';
import { listAccountProvider } from '../../../actions/account_provider_actions';
import { get } from '../../../helpers/storage_helper';
import { Biz_operation_live_reports,getCurrencyDetails, inbound_voice_call_report } from '../../../actions/report_actions';



class It_operation_live_results extends FlowContainer {
    state ={
      lowestBalanceByProvider:{},
        this_month:true,
        last_month:false,
        today:true,
        yester_day:false,
        active:'Today',
        acc_prvdrs:[],
        Fas_pending_1:[{"title":"Disbursal","icon":<BiUser/>,"value":0,"time":"25 " },
                     {"title":"Capture Disbursal","icon":<BiIdCard/>,"value":0,"time":"25" },],

        Fas_pending_2:[{"title":"Returned Disbursement","value":5},
                      {"title":"Duplicate Disbursement","value":0},
                      {"title":"Un-Returned Disbursement","value":0}],
       
       mobile_users:[{"title":"Newly Onboarded","icon":<BiUser/>,"value":100,"total":"500" ,"background":'#9b3e9a' },
                     {"title":"Installations","icon":<BiUser/>,"value":100,"total":"500 " ,"background":'#9b3e9a' },
                     {"title":"Disbursal","icon": <BsFillPersonCheckFill/>, "total":"400","value":250,"background":'#bfc95c' },
                     {"title":"FAs Applied","icon":<BiCommentAdd/>,"value":400 ,"background":'#c47630'},
                     {"title":"FAs Repaid","icon":<BiTask/>,"value":100,"background":'#3d9668' }],
       float_account_balance:'',
       repayment_view:'',
       activeAcc_prvdrs:null,
       all_network_prvdr:[],
       live_btn:true,
       mobile_users_view:'',
       fas_pending_view:'',
       fas_pending_disbursement:'',
       lead_pending_state:'',
       start_date: moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD"),
       end_date: moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD")   ,
       data:[],
       to_repayment_call:0,
       last_repayment_days:0,
       selected_val:5,
       loader:false,
       current_active_acc_prvdr:'',
       martket_countryCode : get('market').country_code,
       active_currency:'',
       repayment_loader:false,
       mobile_users_loader:false,
       active_acc_prvdr:null,
       lazy_loading:[],
       shouldRefresh: false,
       inbound_voice_chart_data: []


      
     }
     componentWillMount(){
       
       var request = {country_code: get('market').country_code, time_zone: get('market').time_zone, status: ""};      
       listAccountProvider({...request, status: 'enabled', biz_account: true})
         .then((response)=>{
             if(!response){return };
             if(response.status === "success"){
                 let acc_prvdrs = []
                 response.data.list.map(each=>
                     acc_prvdrs.push(each.acc_prvdr_code)
                 )
           this.setState({acc_prvdrs,active_acc_prvdr:acc_prvdrs[0] })     
          
 
             }
         })    
        this.biz_ops_live_reports()

        this.currency_details()
       this.setState({martket_countryCode : get('market').country_code,})
     }

    //  inbound calls report
    componentWillMount(){
      this.get_inbound_voice_call_data()

    }

     componentDidMount() {
      this.refreshTimer = setTimeout(() => {
        this.setState({ shouldRefresh: true });
      }, 300000); 
    }
  
    componentWillUnmount() {
      clearTimeout(this.refreshTimer);
    }

     currency_details() {
      getCurrencyDetails(this.req())
      .then((response)=>{
      if(response.status=="success"){
         this.setState({currency_details: response.data, active_currency:response.data[this.props.country_code].currency_code})
      }
      })
    }

     componentDidMount() {
       this.interval = setInterval(() => this.setState({ time: Date.now()}, this.biz_ops_live_reports()), 60000);
     }
     componentWillUnmount() {
       clearInterval(this.interval);
     }
     componentDidUpdate(prevProps, prevState, snapshot){
       if(this.state.to_repayment_call != this.state.last_repayment_days){
         
        this.setState({to_repayment_call:this.state.last_repayment_days,repayment_loader:true},() =>{
          this.biz_ops_live_reports('repayment_metrics',  moment().subtract(this.state.to_repayment_call, 'days').format('YYYY-MM-DD'), this.state.end_date)
          
        })
        

       }
        if(this.state.activeAcc_prvdrs!=this.props.active_acc_prvdr){
         this.setState({activeAcc_prvdrs:this.props.active_acc_prvdr,loader:true})
         this.biz_ops_live_reports()
         if(this.state.lazy_loading !=undefined && this.state.lazy_loading.length > 0){
          this.biz_ops_live_reports(this.state.lazy_loading)

        }

       }

       if(this.props.country_code!=this.state.martket_countryCode){
       
          var curreny_code=this.state.currency_details ? this.state.currency_details[this.props.country_code].currency_code:''
          this.setState({active_currency:curreny_code,martket_countryCode:this.props.country_code,loader:true },()=> this.biz_ops_live_reports())
     
       }
      
      

     }

     biz_ops_live_reports(criteria=null,start_date=this.state.start_date,end_date=this.state.end_date,acc_prvdr=this.props.active_acc_prvdr,country_code=this.state.martket_countryCode){
    
      Biz_operation_live_reports(this.req({"report_type":'operation_dashboard',"criteria":criteria,"start_date":start_date,"end_date":end_date,"acc_prvdr_code":acc_prvdr,"country_code":this.state.martket_countryCode}, false))
      .then((response)=>{
        if(!response){return };
        if(response.status=="success"){
        
          var resp_data=response.data
          var data = this.state.data
          data = {...data,...resp_data};
           this.setState({data:data,loader:false,repayment_loader:false})
           if(response.data.fas_disbursal){
         
            const fas_disbursal =response.data.fas_disbursal;
            const fas_pending_tittle_order = ['w_prvdr','cptr_disb'];
            const fas_pending_icon = [<BiMoney/>,<BiTask/>];
            const emptyarray=[];
            fas_pending_tittle_order.forEach((items,idx)=>{
              
              fas_disbursal[items]['icon'] = fas_pending_icon[idx]
              emptyarray[idx] = fas_disbursal[items]
             
            })
            this.setState({Fas_pending_1:emptyarray,fas_pending_view:response.data.fas_disbursal})
           }
           if(response.data.fas_pending_disbursement){
         
            const fas_pending_disbursement =response.data.fas_pending_disbursement;
            const fas_pending_tittle_order = ['rd_count','dd_count','un_rd_count'];
            const emptyarray=[];

            fas_pending_tittle_order.forEach((items,idx)=>{
              emptyarray[idx] = fas_pending_disbursement[items]
            })
            this.setState({Fas_pending_2:emptyarray,fas_pending_disbursement:response.data.fas_pending_disbursement})
           }
           if(response.data.mobile_users){
            
              var mobile_users =response.data.mobile_users;
              const mobile_users_tittle_order = ['newly_onboarded','registered','active','fas_applied','fas_repaid'];
              const mobile_users_background = ['#9b3e9a','#9b3e9a','#bfc95c','#c47630','#3d9668'];
              const mobile_users_icon = [<BiUser/>,<BiUser/>,<BsFillPersonCheckFill/>,<BiCommentAdd/>,<BiTask/>];
              var mob_emptyarray=[];
              mobile_users_tittle_order.forEach((items,idx)=>{
                mobile_users[items]['background'] = mobile_users_background[idx]
                mobile_users[items]['icon'] = mobile_users_icon[idx]
                mob_emptyarray[idx] = mobile_users[items]
              })
              this.setState({mobile_users:mob_emptyarray,mobile_users_view:response.data.mobile_users,mobile_users_loader:false})
            } 

             if(response.data.repayment_metrics){
               this.setState({repayment_view:response.data.repayment_metrics})
             
            }

            if (response.data.account_balance) {
              var active_acc_prvdrs = [];
              var lowestBalanceByProvider = {};
            
              for (var key in response.data.account_balance) {
                active_acc_prvdrs.push(key);
            
                const accounts = response.data.account_balance[key];
                const lowestBalanceProviderAccount = accounts.reduce((minAccount, currentAccount) => {
                  return currentAccount.balance < minAccount.balance ? currentAccount : minAccount;
                }, accounts[0]);
                lowestBalanceByProvider[key] = lowestBalanceProviderAccount.balance;
              }
              const current_active_acc_prvdrs = active_acc_prvdrs[0];
            
              this.setState({
                float_account_balance: response.data.account_balance,
                active_acc_prvdr: active_acc_prvdrs,
                current_active_acc_prvdr: current_active_acc_prvdrs,
                lowestBalanceByProvider: lowestBalanceByProvider,
              });
            }
            if (this.state.data.lead_pending){
              this.setState({lead_pending_state:this.state.data.lead_pending})
            }
            
          }
        
       
      })
     }

      get_inbound_voice_call_data = ()=>{
        var request ={report_type:'inbound_voice_calls_downtime',acc_prvdr:this.props.active_acc_prvdr,start_date:this.state.start_date,end_date:this.state.end_date,screen:'live'}

      inbound_voice_call_report(this.req({...request})).then((response)=>{
        if(!response) return;
        if(response.status =='success'){

          let x_axis_data = response.data.map((val)=>{
                let match = get_cs_agent_names(val.x);
                if (match) {
                    return match;
                }
                return val.x;
              })
          let y_axis_data_in_mins = response.data.map((val)=>val.y)

          let y_axis_data_in_hrs = response.data.map((val)=>{
            let formattedTime = convertMinutesToHours(val.y);
            return formattedTime;
          })

          this.setState({inbound_voice_chart_data:[{data:y_axis_data_in_mins,name:'Down Time In Minutes'},{data:y_axis_data_in_hrs,name:'Down Time In Hours'}],x_axis_inbound_voice_data:x_axis_data})
        }
        else{
          return
        }

      }).catch(error=>{
        alert(error)
      })


     }

 
 
     acquisition_targets(type){
      if(type=="this_month"){
        this.setState({this_month:true ,last_month:false})
      }
      else if(type=="today"){
       this.setState({yester_day:false ,today:true})
      }else if(type=="yester_day"){
 
       this.setState({yester_day:true ,today:false})
      }
      else {
       this.setState({this_month:false ,last_month:true})
      }
     }
 
     tabCheck = (e) =>{
            
       this.setState({
           ...this.state,
           active:e,
 
           })
         
           if(e=='Today'){
            this.setState({last_repayment_days:0,start_date:  moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD"),end_date: moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD")})
            // this.biz_ops_live_reports('repayment_metrics', moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD"),  moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD"))
           }else if(e=='Yesterday'){

            this.setState({start_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),end_date: moment().subtract(1, 'days').format("YYYY-MM-DD"),last_repayment_days :1})
            // this.biz_ops_live_reports('repayment_metrics',  moment().subtract(1, 'days').format('YYYY-MM-DD'),  moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD"))
           }
           else if(e=='Day_b_f_y_day'){

            this.setState({start_date: moment().subtract(2, 'days').format('YYYY-MM-DD'),end_date: moment().subtract(2, 'days').format('YYYY-MM-DD'),last_repayment_days :2})
            // this.biz_ops_live_reports('repayment_metrics',  moment().subtract(2, 'days').format('YYYY-MM-DD'),  moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD"))
           } 
           else if(e=='Last_5_days'){
             
            // this.last_fivedays_reapaymentHandler()
            this.setState({start_date: moment().subtract(5, 'days').format('DD-MM-YYYY'),end_date: moment(new Date(), 'DD-MM-YYYY').format("DD-MM-YYYY"),last_repayment_days : this.state.selected_val})
            // this.biz_ops_live_reports('repayment_metrics',  moment().subtract(this.state.last_repayment_days, 'days').format('YYYY-MM-DD'),  moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD"))

           }else{
          //  this.biz_ops_live_reports('repayment_metrics', moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD"),  moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD"))

           }
   }
 
   network_prvdr_handler(acc_prvdr){
     
     this.setState({current_active_acc_prvdr:acc_prvdr})
    
   }
 
   biz_operation_liveHandler(type){
     if(type=="live"){
       this.setState({live_btn:true, yester_day_btn:false, day_bf_btn:false,this_month_btn:false,last_month_btn:false})
     }
     else if(type=="yesterday"){
       this.setState({live_btn:false, yester_day_btn:true, day_bf_btn:false,this_month_btn:false,last_month_btn:false})
     }
   }
   common_percentage_cal(val,total){
     if(total==0){
      return "0 % ";
     }
     else if((val/total)*100 >=0){
      return (val/total*100).toFixed()+ "% ";
     }else{
      return "0 % ";
     }
   
   }
   last_fivedays_reapaymentHandler(val){
     this.setState({last_repayment_days:val, selected_val:val})
    //  this.biz_ops_live_reports('repayment_metrics',  moment().subtract(val, 'days').format('DD-MM-YYYY'),  moment(new Date(), 'DD-MM-YYYY').format("DD-MM-YYYY"))
   }
  
   float_acc_view_handler(type){
     this.setState({[type]:true})
   }
   lazy_loading_handler(val){
       
     
    var lazy_loading_array = this.state.lazy_loading
    lazy_loading_array.push(val)  
    this.setState({lazy_loading:lazy_loading_array})
   
    this.biz_ops_live_reports(lazy_loading_array.length >0 ? lazy_loading_array:null)

  }

  convertMinutesToHoursAndMinutes (minutes){
    
    const duration = moment.duration(minutes, 'minutes');
    var mins=duration.minutes()
    var hours=duration.hours()
    if((mins>60)&& (hours>0)){
      var str= (hours >1)? `${hours} hr ${mins} minutes` : `${hours} hours ${mins} minutes`
    }
    return str;
  };
 
    render(){
    return (

        <div style={{minHeight:'80vh'}}>
         
       
            <>
            <div className='row ' style={{background:'#4291da',padding:'15px'}}>
              <div className='d-flex align-items-center'>

              <p className='text-white no-margin business_operations_text biz_operation_font_size' style={{fontSize:'18px'}}><b>LIVE</b></p>
              <div className='d-flex align-items-center biz_operation_font_size'>
              <p className='no-margin biz_operation_font_size' ><b>{ moment(new Date(), 'DD-MM-YYYY').format("DD MMM ")} <span className='biz_operation_font_size' > | </span></b>  </p>
              <p className='no-margin biz_operation_font_size'  ><b>{ new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</b></p>
              </div>


              </div>

            </div>
            {this.state.loader ? 
              <div class="loader">
             
              <div class="cogs">
                <div class="cog cog0">
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                </div>
                <div class="cog cog1">
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                </div>
                <div class="cog cog2">
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                </div>
              </div>
              <div className='load_dot'>
              <div class="dot">F</div>
              <div class="dot">L</div>
              <div class="dot">O</div>
              <div class="dot">W</div>
              <div class="dot">I</div>
              <div class="dot">N</div>
              <div class="dot">G</div>
              </div>
            
            </div>
             
              :
            <>
              <div className='row d-flex align-items-center justify-content-around' style={{marginTop:'1%'}} >   
                <div className={`col-lg-7 col-md-12  col-sm-12  business_operations_border biz_operation_fas_pending  `} style={{position:'relative',minHeight:'250px'}} >
                    <div className='row'>
                      <div className='d-flex align-items-center '>
                        <div className='d-flex align-items-center biz_operation_font_size' style={{ margin: '10px 0px 0px 20px' }}>
                          <BiAlarm size={30} color={"white"} style={{ marginTop: '-10px' }} />
                          <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', fontSize: '18px' ,fontWeight:"bold" }}>FAs Pending</h2>
                        </div>
                      <div>
                      {this.state.fas_pending_view ? " " :
                            <div className='ml-4'> <button type="button" class="btn btn-outline-secondary btn-sm mb-0" style={{ color: 'white', borderRadius: '10px' }} onClick={() => this.lazy_loading_handler("fas_disbursal")}> <b>Show</b></button> 
                            </div>
                      }
                    </div>
                </div>
              </div>
              <div className='business_operations_bottom_border'></div>
                <div className='d-flex ' >
                      {this.state.Fas_pending_1.map((item, idx) => (
                          <div className='col-md-5 biz_operration_padding business_operations_border  biz_operation_font_size' style={{ margin: '5% 4%',background: "linear-gradient(81.76deg, rgb(61, 86, 120) -2.92%, rgba(61, 86, 120, 0) 97.75%)",border: "0.7px solid #7da6cb","border-radius": "25px"}}   key={idx}>
                            <p className='text-white no-margin text-center'style={{fontSize: '18px',fontWeight:"bold",paddingTop:"11"}}> {item.title} </p>
                            <div className='d-flex align-items-center justify-content-around' style={{ marginTop: '15px',paddingBottom:"8px"}}>
                                <p className={`fas_pending_icon_color no-margin ${this.state.fas_pending_view ? " " : 'float_account_balance_bgblur'}`} style={{ fontSize: '30px',fontWeight:"bold" ,color:"rgba(236, 148, 152, 1)" }}>{item.icon}</p>
                                <p className={`text-white no-margin biz_operation_font_size  ${this.state.fas_pending_view ? " " : 'float_account_balance_bgblur'}`}> <b className='biz_ops_count_fontsize'> {item.value} </b></p>
                                <p className={`no-margin fas_pending_icon_color business_operations_text  ${this.state.fas_pending_view ? " " : 'float_account_balance_bgblur'}`} style={{fontWeight:"bold",color:"rgba(236, 148, 152, 1)"}}>for {item.time == null ? '0' : item.time} mins</p>
                            </div>
                          </div>
                      ))}
                </div>
            </div>
            <div div className='col-lg-4 col-md-12  col-sm-12  business_operations_border biz_operation_fas_pending' style={{position:'relative',minHeight:'250px'}}>
            <div className='row'>
                <div className='d-flex align-items-center '>
                  <div className='d-flex align-items-center biz_operation_font_size' style={{ margin: '5px 0px 0px 10px' }}>
                    {/* <BiAlarm size={30} color={"white"} style={{ marginTop: '-10px' }} /> */}
                    <img className={`img-fluid `}src={`/img/Disbursal.png`} alt={""}  style={{ marginTop: '-10px',width:"25%" }}/> 

                    <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', fontSize: '18px',fontWeight:"bold" }}>Disbursal</h2>
                  </div>
                  <div>
                  {this.state.fas_pending_disbursement ? " " :
                          <div className='ml-4'> <button type="button" class="btn btn-outline-secondary btn-sm mb-0" style={{ color: 'white', borderRadius: '10px' }} onClick={() => this.lazy_loading_handler("fas_pending_disbursement")}> <b>Show</b></button> </div>
                          }
                  </div>
                </div>
              </div>
              <div className='business_operations_bottom_border'></div>
              <div className='d-flex  align-items-center row' style={{}}>
                      {this.state.Fas_pending_2.map((item, idx) => (
                      
                        <div className='col-3  business_operations_border biz_opr_fas_pending biz_operation_font_size' style={{ margin: '5% 4%', padding:'2% 0%'}} key={idx}>
                          <div style={{marginBottom:'10%'}}>
                            <p className='text-white no-margin text-center' style={{fontSize: '13px',fontWeight:"bold",paddingBottom:"33px"}}> {item.title} </p>
                          </div>
                          <div className='d-flex align-items-center justify-content-center' style={{ marginTop: '7%' ,paddingBottom:"28px"}}>
                              <p className={` no-margin   ${this.state.fas_pending_disbursement ? " " : 'float_account_balance_bgblur'}`}> <b className='' style={{color:"rgba(236, 148, 152, 1)",fontSize:"20px"}}> {item.value} </b></p>
                          </div>
                        </div>
                    ))}
                </div>
            </div>
           </div>

            <div className='row d-flex align-items-center justify-content-around' style={{marginTop:'1%'}}>
            <div className={`col-lg-7 col-md-12 col-sm-12 business_operations_border biz_operation_fas_pending `} style={{ position: 'relative', minHeight: '287px' }}>
    <div className=''>
        <div className='d-flex align-items-center biz_operation_font_size ' style={{ margin: '10px 0px 0px 20px' }}>
            <BiDollarCircle size={30} color={"white"} style={{ marginTop: '-3px' }} />
            <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', fontSize: '18px', fontWeight: "bold" }}>Float Account Balance</h2>
        </div>
        <div className='business_operations_bottom_border'></div>
        {this.state.float_account_balance ? "" :
            <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary" style={{ color: 'white' }} onClick={() => this.lazy_loading_handler('account_balance')} > <b>Show</b></button> </div>
        }
        <div className='' style={{ 'marginTop': '2%', overflowY: 'auto', maxHeight: '200px' }}>
            <table className={`table table-bordered ${this.state.float_account_balance ? "" : 'float_account_balance_bgblur'}`} style={{ marginBottom: '25px' }}>
                <thead>
                    <tr>
                        <th className='text-white' scope="col"> <b> Network Provider</b></th>
                        <th className='text-white' scope="col"><b>Balance</b></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.float_account_balance ?
                        <tr style={{ padding: '5px 0px 10px 5px' }}>
                            {this.state.active_acc_prvdr ?
                                <td className='d-flex align-items-center'>
                                    {this.state.active_acc_prvdr &&
                                        this.state.active_acc_prvdr.map((each, idx) =>
                                            <div className={`d-flex align-items-center justify-content-center  ${this.state.current_active_acc_prvdr === this.state.current_active_acc_prvdr[0] || this.state.current_active_acc_prvdr === each ? "" : "opac_50"}`} style={{ height: 45, width: 75, marginRight: 15, cursor: 'pointer', border: " 2px solid #424141", padding: "6px" }} key={idx} >
                                                <img className={`img-fluid `} src={`/img/${each}_logo_color.png`} alt={each} onClick={() => this.network_prvdr_handler(each)} />
                                                {this.state.current_active_acc_prvdr === each && (
                                                    this.state.lowestBalanceByProvider[this.state.current_active_acc_prvdr] < 10000000 ? (
                                                        <img className={`img-fluid `} src={`/img/down_Arrow_with_bg.png`} alt={""} style={{ width: "32%", marginLeft: "-16px", transform: "translate(14px, -22px)" }} />
                                                    ) : (
                                                        ""
                                                    )
                                                )}
                                            </div>
                                        )
                                    }
                                </td>
                                : <td>no-data</td>}
                            <td></td>
                        </tr>
                        : ''}
                    {this.state.float_account_balance ?
                        <>
                            {this.state.float_account_balance && this.state.float_account_balance[this.state.current_active_acc_prvdr].map((datas) =>
                                <tr style={{ height: '30px' }}>
                                    <td><p className='text-white no-margin' style={{ margin: '30px 0px' }}><img className={`img-fluid `} src={`/img/${datas.acc_prvdr_code}_logo_color.png`} alt={datas.acc_prvdr_code} width={50} height={12} />	 {datas.acc_number} ({datas.acc_prvdr_code})</p></td>
                                    <td className='round_offval_fontsize'>
                                        {datas.balance > 0 ? (
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <RoundOffSplitter val={datas.balance} unit={this.state.active_currency} align_center={true} position={"top"} />
                                                {datas.balance < 10000000 ?
                                                    (
                                                        <img className={`img-fluid `} src={`/img/down_arrow.png`} alt={""} style={{ "marginLeft": "9px" }} />
                                                    ) : (
                                                        <img className={`img-fluid `} src={`/img/up_arrow.png`} alt={""} style={{ "marginLeft": "9px" }} />
                                                    )
                                                }
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <p className='text-white no-margin text-center' style={{ margin: '5px 0px', fontSize: '10px' }}>(
                                            {datas.time &&
                                                <>
                                                    {(datas.time > 60) ?
                                                        (Math.floor((moment.duration(datas.time, 'minutes')).asHours())) + `${((Math.floor((moment.duration(datas.time, 'minutes')).asHours())) > 0) ? " hours" : " hour"} ` + ((moment.duration(datas.time, 'minutes')).minutes()) + ` ${(((moment.duration(datas.time, 'minutes')).minutes()) > 1) ? "mins ago" : "minute"}`
                                                        :
                                                        datas.time + " mins ago"}
                                                </>
                                            }
                                        )</p>
                                    </td>
                                </tr>
                            )
                            }
                        </>
                        :
                        <>
                            <tr style={{ height: '90px' }}>
                                <td><p className='text-white no-margin' style={{ margin: '30px 0px' }}><img className={`img-fluid `} src={`/img/"UZEM"}_logo_color.png`} alt={"UZEM"} width={50} height={12} />	 3687687568 </p></td>
                                <td><p className='text-white no-margin' style={{ margin: '30px 0px' }}>R837568356</p></td>
                                <td> <p className='text-white no-margin' style={{ margin: '30px 0px' }}>
                                    743 mins ago
                                </p></td>
                            </tr>
                            <tr style={{ height: '50px' }}>
                                <td><p className='text-white no-margin' style={{ margin: '30px 0px' }}><img className={`img-fluid `} src={`/img/"UZEM"}_logo_color.png`} alt={"UZEM"} width={50} height={12} />	 3687687568 </p></td>
                                <td><p className='text-white no-margin' style={{ margin: '30px 0px' }}>R837568356</p></td>
                                <td> <p className='text-white no-margin' style={{ margin: '30px 0px' }}>
                                    743 mins ago
                                </p></td>
                            </tr>
                        </>
                    }
                </tbody>
            </table>
        </div>
    </div>
</div>


            <div className='col-lg-4 col-md-12  col-sm-12  business_operations_border biz_operation_fas_pending' style={{position:'relative',minHeight:'287px'}} >
            <div className='row'>
                <div className='d-flex align-items-center '>
                  <div className='d-flex align-items-center biz_operation_font_size' style={{ margin: '18px 0px 0px 20px' }}>
                    <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', fontSize: '18px' ,fontWeight:"bold"}}>Leads Pending</h2>
                  </div>
                  <div>
                    {this.state.lead_pending_state ? " " :
                      <div className='ml-4'> <button type="button" class="btn btn-outline-secondary btn-sm mb-0" style={{ color: 'white', borderRadius: '10px' }} onClick={() => this.lazy_loading_handler("lead_pending")}> <b>Show</b></button> </div>
                    }
                  </div>
                </div>
              </div>
              <div>              
                <div className={`${this.state.data.lead_pending ? " " : 'float_account_balance_bgblur'}`} style={{padding:"12px"}}>
                <div className='d-flex justify-content-between' style={{marginTop:'25px'}}>
                    <label> With RMs</label>
                    <div className='d-flex align-items-center'>
                      <div style={{color:'#4bb6e6'}}>
                          <p className='text-white biz_operation_font_size no-margin no-padding'><b className='biz_ops_count_fontsize'>{this.state.data.lead_pending ? this.state.data.lead_pending.w_rm :''}</b></p>
                        </div>  
                        {/* <div style={{color:'#4bb6e6'}}>
                        <p className='text-white no-margin biz_ops_green_text' style={{paddingLeft:'10px'}}><b>{this.state.data ?this.common_percentage_cal(this.state.data.lead_pending.w_rm ,this.state.data.lead_pending.total):''}</b></p>
                        </div>  */}
                    </div>
                      
                  </div>
                  <div className="progress" style={{height:'8px'}}> 
                    <div className="progress-bar progress-bar-striped"  role="progressbar" style={{width:`${this.state.data.lead_pending ?this.common_percentage_cal(this.state.data.lead_pending.w_rm ,this.state.data.lead_pending.total):''}`,background:'rgba(66, 145, 218, 1)'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                      </div>
                  </div>
                  
                  <div className='d-flex justify-content-between' >
                    <label > With R&C</label>
                    <div className='d-flex align-items-center'>
                      <div style={{color:'#4bb6e6'}}>
                          <p className='text-white biz_operation_font_size no-margin no-padding'><b className='biz_ops_count_fontsize'>{this.state.data.lead_pending ? this.state.data.lead_pending.w_rc :''}</b></p>
                        </div>  
                      
                    </div>
                      
                  </div>
                  <div className="progress" style={{height:'8px'}}> 
                    <div className="progress-bar progress-bar-striped"  role="progressbar" style={{width:`${this.state.data.lead_pending ?this.common_percentage_cal(this.state.data.lead_pending.w_rc ,this.state.data.lead_pending.total):''}`,background:'rgba(66, 145, 218, 1)'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                      </div>
                  </div>

                  <div className='d-flex justify-content-between' >
                    <label > Without FAs</label>
                    <div className='d-flex align-items-center'>
                      <div style={{color:'#4bb6e6'}}>
                          <p className='text-white biz_operation_font_size no-margin no-padding'><b className='biz_ops_count_fontsize'>{this.state.data.lead_pending ? this.state.data.lead_pending.wo_fa :''}</b></p>
                        </div>  
                      
                    </div>
                      
                  </div>
                  <div className="progress" style={{height:'8px'}}> 
                    <div className="progress-bar progress-bar-striped"  role="progressbar" style={{width:`${this.state.data.lead_pending ?this.common_percentage_cal(this.state.data.lead_pending.wo_fa ,this.state.data.lead_pending.total):''}`,background:'rgba(66, 145, 218, 1)'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                      </div>
                  </div>
                  </div>
             </div>
            </div>
           </div>
            </>
             }
            </>
             
        </div>
    );
};
}
export default It_operation_live_results;