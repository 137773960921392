import React from 'react';
import { } from 'react-router-dom';
import '../../../styles/flow.css';
import '../../market/container/datatables.css';
import {dd_value} from '../../../actions/common_actions';
import {listMasterDataKeys,updateDataKeyStatus} from '../../../actions/master_data_actions';
import FlowContainer from '../../common/container/core/FlowContainer';
import StatusButton from '../../common/component/core/StatusButton';
import $ from 'jquery';

class ListMasterDataKeyContainer extends FlowContainer {

  state = {
     masterDataKeyList: null
  }

  handleStatusClick = (event) => {

    
    let status = event.target.value.toLowerCase()+"d";
   
    const request = {"id":event.target.id,"status":status};
    
    const request_data = {"master_data_key":request}
    
//alert(JSON.stringify(request_data));
      updateDataKeyStatus(request_data)
         .then((response) => {
          if(!response){return };
                
              if(response.status === "success"){
                window.location.reload();
              

              }else{
                alert(response.message + "\n" + JSON.stringify(response.data));  
              }
            }
          ); 
       
       
    }

  componentWillMount(){
    //const country_code = this.country_code;
    listMasterDataKeys(this.req(null))
        .then((response) => {
          if(!response){return };
          //this.setState({lenders: response.data});
          const masterDataKeyList = response.data.map((master_data_key,i)=>

              <tr key={i}>
                <td> {master_data_key.data_key}</td>
                <td> {master_data_key.country_code === "*" ? "Global" : master_data_key.country_code}</td>
                <td> {master_data_key.parent_data_key  || "<<No Parent>>"}</td>
                <td> {master_data_key.data_group === null ? "<<No Data Group>>" : master_data_key.data_group}</td>
                <td> {master_data_key.data_type === null ? "<<No Data Type>>" : master_data_key.data_type}</td>
                <td> {master_data_key.key_desc === null ? "<<No Description>>" : master_data_key.key_desc}</td>
                <StatusButton id={master_data_key.id} entity="master_data_keys" status={master_data_key.status}/>
              </tr>
              );
          this.setState({masterDataKeyList: masterDataKeyList});

            $('#list').removeAttr('width').dataTable({
                  "order" : [],
                  "columns": [

                       {"width" : "20%"}, 
                         {"width": "10%"},
                       {"width": "18%"},
                       {"width": "18%"},
                       {"width": "8%"},
                       {"width": "8%"},
                       {"width": "8%"},
                       {"width": "10%"}
                                                                
                     ],

                  dom:'Bfrtip',
                  buttons: [
                  'csv','excel','print'
                  ]
            
              });
        });
  }


  render(){

   if (this.state.masterDataKeyList != null) { 
      
    return( 
      <div id="">
      <div className="container">
            
            <div id='datatable' className={"table-responsive"}>
            <div className="row">
              <h5 className="fa_left">List Master Data Key</h5>
            </div>
            <br/>
             <table id ='list' className="table">
                      <thead className={"text-info"}>
                      <tr><th>Data Key</th>
                      <th>Data Scope</th>
                      <th>Parent Data Key</th>
                      <th>Data Group</th>
                      <th>Data Type</th>
                      <th>Key Description</th>
                      <th>Status</th>
                      <th>Action</th></tr></thead>
                      <tbody> 
                      {this.state.masterDataKeyList}
                      </tbody>
             </table>
                    
        </div>
        </div>
     </div>

      );

      
    }
   

      return (
        <div><p>No Master Data Keys Available ! </p></div>
        
      );
  }
}

export default ListMasterDataKeyContainer;
