import React, {useEffect, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import { getCustDetail, getPrevCallIntercations, process_call_log } from "../../../actions/cs_management_actions";
import { array_to_string, format_date, lbl_amt, select_custom_styles } from "../../../helpers/common_helper";
// import { dd_value, getMultiSelectOptions } from "../../../actions/common_actions";
import { dd_value, getMultiSelectOptions } from '../../../actions/common_actions';

import { get } from "../../../helpers/storage_helper";
import {FaPhoneAlt, FaLongArrowAltUp, FaAngleDown} from "react-icons/fa";
import Select from "react-select";
import { useSelector, useDispatch } from 'react-redux'
import moment from "moment";
import { RiQuestionFill } from "react-icons/ri";
import { BiMessageDetail } from "react-icons/bi";
import { setValuesIntoRedux } from "../../../Redux/Slices/CommonSlice";
import CustomSkeleton from "../../../MUI/Components/CustomSkeleton";


const CommonCallLogModal = ({cust_id=null,call_log=null,close_modal})=>{

    const channel = new BroadcastChannel('cs_soft_phone')
    const [show,setshow] = useState(false);
    const [cust_info, setcust_info] = useState(false)
    const dispatch = useDispatch()

    
    useEffect(()=>{
        
        if(cust_id !=="incoming_call"){
            getCustDetails()

        }else if (cust_id == "incoming_call"){
            if(datas.incoming_call_details && datas.incoming_call_details.length>0){
                setcust_info(datas.incoming_call_details[0])
                setloader(false)
            }else{
                setloader(false)
                setcust_info(null)  
            }
        }
        
    },[cust_id])
    const [loader,setloader] = useState(true);

    const [reason,setReason] = useState([]);
    const [remarks,setremarks] = useState(null);
    const [selected_options,setselected_options] = useState([]);
    const datas = useSelector((state) => state.cs_soft_phone)
    const [callInteractions,setcallInteractions] = useState([]);
    
    const [PrevInteractions,setPrevInteractions] = useState(false);

    const ToggleModal =(type=null)=>{
        setshow(!show)
        // close_modal()
        channel.postMessage({message:"CallLogModalClose", data:{CallLogModal:false}})

        dispatch(setValuesIntoRedux({CallLogModal:false}))

    }
    const getInputHandler =(value, type) => {
        setselected_options(value)
        var reason=[];
        for(var i=0;i<value.length;i++){
            reason.push(value[i].value);
        }
        setReason(reason)
        }

        useEffect(() => {
            const handleStorageChange = (event) => {
              if (event.key === 'persist:root') {
                const newValue = JSON.parse(event.newValue);
                Object.entries(newValue).map(([key, value]) => {
    
                    dispatch(setValuesIntoRedux({[key] : JSON.parse(value)}))
                 
                })
              }
            };
        
            window.addEventListener('storage', handleStorageChange);
        
            return () => {
              window.removeEventListener('storage', handleStorageChange);
            };
          }, [dispatch]);
    


    const customer_details = {
        cust_info:[
            {label:"Name",key:"cust_name"},
            {label:"Location",key:"location"},
            {label:"Mobile Number",key:"cust_mobile_num"},
            {label:"Flow Cust ID",key:"cust_id"},
            {label:"DOB",key:"cust_dob"},
            // {label:"Data Prvdr Cust ID",key:"cust_id"},

    ],
    fa_details:["loan_doc_id","status","paid_amount","due_date","current_os_amount","due_amount"],
    dup_txn_details :[
        {label:"Status",key:"status"},        
        {label:"Paid Amount",key:"paid_amount"},
        {label:"Transaction Date",key:"txn_date"},
        {label:"Transaction ID",key:"txn_id"},
        {label:"Transferred Amount",key:"transferred_amount"},
        {label:"Amount to Recover",key:"amount_to_recover"},
    ]
    };

    const getCustDetails = async () => {
        setloader(true)

        try {
            var req ={}
          
            if( datas.isIncomingCall && datas.incoming_call_details && datas.incoming_call_details.length <0 ){
              req.mobile_num = datas.incomingcall_num  ? datas.incomingcall_num : null
            }
           else{
                req.cust_id = cust_id
                if(datas && datas.screen_from && datas.screen_from == "dup_txn_report" && datas.cust_data && datas.cust_data.duplicate_txn_data){
                    req.dup_fa_call_details = {}
                    let entity_id = datas.cust_data.type == "float_advance" ? "loan_doc_id" : "sales_doc_id"
                    req.dup_fa_call_details = {type : datas.cust_data.type, [entity_id]:datas.cust_data[entity_id]}
                    dispatch(setValuesIntoRedux({dup_fa_call_details:req.dup_fa_call_details}))
                }
           }

            const response = await getCustDetail(req);
            if(response){
                setloader(false)

            if (response.status === 'success') {
                if(response.data.customer_list && response.data.customer_list.length>0){
                    setcust_info(response.data.customer_list[0]);
                    setcallInteractions(response.data.customer_list[0].prev_call_interactions)
                    dispatch(setValuesIntoRedux({cust_data:response.data.customer_list[0]}))
                    channel.postMessage({message:"save_cust_values", data:{cust_data:response.data.customer_list[0]}})
                    setshow(true)
                }else{
                    setcust_info(false)
                }
              

            } else {
                
                alert(response.data)
            }
        }

        } catch (error) {
            console.error(error);
        }
    };

    const callLogSubmit = ()=>{
        var req={}
        if(reason && reason.length>0 && remarks){
            req.reason =reason
            req.remarks =remarks
            req.call_log_id = call_log.call_log_id
            req.loan_doc_id = (cust_info && cust_info[fa_details] && cust_info[fa_details].loan_doc_id )? cust_info[fa_details].loan_doc_id:null
            if(datas && datas.screen_from && datas.screen_from == "dup_txn_report" && datas.dup_fa_call_details){
                let entity_id = datas.dup_fa_call_details.type == "float_advance" ? "loan_doc_id" : "sales_doc_id"
                req.duplicate_txn_call_details = {[entity_id]:datas.dup_fa_call_details[entity_id]}
            }
         }
        process_call_log(req)
        .then((response)=>{
            if(response&&response.status=="success"){
                setReason([])
                setremarks(null)
                setselected_options([])
                // ToggleModal("close")               
                dispatch(setValuesIntoRedux({call_log_details:null,cust_data:null,incoming_call_details:[],incomingcall_num:null,CallLogModal:false, duplicate_txn_data:null}))

                channel.postMessage({message:"submit_call_log", data:{call_log_details:null,cust_data:null,incoming_call_details:[],incomingcall_num:null,CallLogModal:false,pending_rms_details :null,pending_cust_details:null, duplicate_txn_data:null, cust_id:null
                }})
                // CallInteractions(cust_info.cust_id)
                // getMultiSelectOptions(`${datas.call_type}_call_purpose`)
            alert(response.message)
            }else{return}
        })
    }

    const checkDisableStatus = () => {
      var  a= false
        if(datas.call_log_details && datas.call_log_details.call_log_id){
           a= true
        }
        return a
    }
    const fa_details = (cust_info && cust_info.current_loan) ? "current_loan" :"last_loan"

    return(
  <div className="">
    <Modal  show={datas.CallLogModal} onHide={()=>ToggleModal(null)}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          >
      <Modal.Header className="acc_ownership_div py-3" closeButton>
        <Modal.Title id=" confirm_Modal">
       <b>Call Log</b> 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div>
        {
        (!loader) ?
        <div className="row col-12 mx-0 my-2 px-0 d-flex justify-content-center">
                    <div className="col-lg-11 mx-2 px-0 col-md-12 col-sm-12">
                        {(cust_info  )?
                        <>
                        <p className="text-white sizel mt-5 mx-5"> <b className="pl-2">Customer Info</b></p>
                        <div className="d-flex align-items-center justify-content-between row mx-5">
                            {customer_details.cust_info.map((cust_datas,idx)=>{return(
                                <div className="col-6 d-flex justify-content-center row mx-0 my-3 pl-0">
                                    <p className="m-0 sizem col-5 text_gray">{cust_datas.label}</p>
                                    <p className="mb-0 mt-2 col-7 font_weight_600 sizem text-white secondary_text_width">{cust_info[cust_datas.key] ?cust_info[cust_datas.key]:"-" }</p>

                                </div>
                            )})}
                        </div>
                        <div className="text_bottom_border my-3 mx-5"></div>
                        { 
                        datas && datas.screen_from && datas.screen_from=="dup_txn_report" && cust_info.dup_fa_details ? 
                        <>
                            <p className="text-white sizel mt-5 mx-5"> <b className="pl-2">Duplicate Transaction Details</b></p>
                            <div className="d-flex align-items-center justify-content-between row mx-5">
                                {customer_details.dup_txn_details.map((txns,idx)=>{return(
                                    <div className="col-6 d-flex justify-content-center row mx-0 my-3 pl-0">
                                        <p className="m-0 sizem col-5 text_gray">{txns.label}</p>
                                        <p className="mb-0 mt-2 col-7 font_weight_600 sizem text-white secondary_text_width">
                                            {cust_info.dup_fa_details[txns.key] ?
                                            ((txns.key == "amount_to_recover" || txns.key == "transferred_amount" || txns.key == "paid_amount") ? lbl_amt(cust_info.dup_fa_details[txns.key],get('market').currency_code) : cust_info.dup_fa_details[txns.key])
                                            :"-" }
                                        </p>

                                    </div>
                                )})}
                            </div>
                        </>
                        :
                        <>
                        <p className="text-white sizel mx-5 pl-2 mt-5"> <b>FA Details</b></p>
                        <div className="d-flex align-items-center row mx-5 justify-content-between">
                            {customer_details.fa_details.map((items,idx)=>{return(
                                <div className="col-6 d-flex justify-content-center row mx-0  my-3 pl-0">
                                    <p className="m-0 col-5  sizem text_gray">{ items=="loan_doc_id" ?"FA ID": dd_value(items,items)}</p>
                                    {/* <p className="m-0 col-7">{cust_info.fa_details[items] ?cust_info.fa_details[items]:"" }</p> */}

                                    <p className="mb-0 mt-2 col-7 font_weight_600 sizem text-white secondary_text_width">
                                        { ( cust_info && cust_info[fa_details] && cust_info[fa_details][items]) ?
                                   <p className="text-white font_weight_600 sizem">
                                    <>{items == "loan_doc_id" ? cust_info[fa_details][items]:
                                      items == "due_date" ? format_date(cust_info[fa_details][items]):
                                      items == "status" ?  dd_value(cust_info[fa_details][items]):

                                      lbl_amt(cust_info[fa_details][items],get('market').currency_code)
                                     }
                                   
                                    </>

                                   </p>
                                    
                                   :"-" }</p>

                                </div>
                            )})}
                        </div>
                        </>}

                        <div className="text_bottom_border my-2 mx-5 mt-3" ></div>
                        </>:
                        <>
                        {/* { datas.incoming_call_details &&  datas.incoming_call_details.length == 0 && */}
                        <div className="d-flex align-items-center row mx-5 col-12 px-0">
                           <div className="col-6 d-flex align-items-center justify-content-center row mx-0 my-3 pl-0">
                                    <p className="m-0 sizem col-5 text_gray">Customer Name</p>
                                    <p className="mb-0 mt-2 col-7 font_weight_600 sizem text-white secondary_text_width">Unknown Customer</p>

                            </div>
                             <div className="col-6 d-flex align-items-center justify-content-center row mx-0 my-3 pl-0">
                             <p className="m-0 sizem col-5 text_gray">Customer Number</p>
                             <p className="mb-0 mt-2 col-7 font_weight_600 sizem text-white secondary_text_width">{(datas.call_log_details && datas.call_log_details.mobile_num) ?  datas.call_log_details.mobile_num:
                             datas.incomingcall_num ? datas.incomingcall_num : datas.callNum ? datas.callNum:"-"}</p>

                     </div>
                     </div>
                    
                        </>
                       }
                        <>

                        <p className="text-white sizel mt-5 mx-5"> <b className="pl-2">Call Details</b></p>
                        {/* {datas && datas.call_log_details &&  */}
                        <div >
                                    <div className={`d-flex row col-12 mx-3 `}>
                                        <div className="col-6 px-0  row mx-0">
                                            
                                            <div className="col-12 px-0 d-flex align-items-center">
                                            <p className="m-0 col-5  sizem text_gray">Call Start Time</p>
                                            <p className="text-white m-0 font_weight_600 sizem">{ datas.call_log_details  &&datas.call_log_details.call_start_time ? moment(datas.call_log_details.call_start_time).format('DD MMM YYYY hh:mm a'): datas.isIncomingCall ?  moment().format('DD MMM YYYY hh:mm a'):'-'} </p>

                                            </div>
                                       
                                       <div  className="col-12 px-0 d-flex align-items-center ">
                                        <p className="m-0 col-5  sizem text_gray">Purpose *</p>
                                        <div className="col-7 px-0">
                                        <Select onChange={(e) => { getInputHandler(e,"call_log_reason") }}
                                            value={selected_options}
                                            options={ getMultiSelectOptions(`${ datas.call_log_details ? (call_log.call_type ?call_log.call_type : (datas.call_log_details && datas.call_log_details.call_type) ? datas.call_log_details.call_type:"outgoing" ): datas.isIncomingCall ? "incoming":call_log ? call_log: ""}_call_purpose`)}  
                                             styles={select_custom_styles()} classNamePrefix="my-select" isMulti/>
                                        </div>
                                            
                                        </div>
                                        </div>
                                     
                                        <div className="col-6">
                                            <div className={`col-12 w-100 h-100 p-0 mt-4  mx-5`}>
                                                    <textarea className='pl-3 pt-3 text-white call_log_textArea' id="w3review" name="w3review" maxlength="150" rows="5" cols="50" placeholder='Remarks' 
                                                    value={remarks?remarks:""}
                                                    onChange={(e) => setremarks(e.target.value)} style={{background:'#4f5167'}}></textarea>
                                                    <div className='d-flex align-items-center row m-0'>
                                                                
                                                        {remarks &&remarks.length==150 && 
                                                                    <p className='m-0 col-10 px-0' style={{color:'#FF0000',fontWeight:'500'}}>You have reached your character limit</p>
                                                        }
                                                        <p className={`text-white ${remarks &&remarks.length==150 ?'col-2' :'col-12'}  text-right m-0 px-0 d-flex justify-content-end `}>  {remarks ? remarks.length : "0"}/150</p>
                                                    </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className= {`d-flex align-items-center justify-content-center  my-3 col-12`}>
                                    <button type="button" class="btn btn-primary  " disabled={!(remarks && reason && checkDisableStatus() ) }    onClick={()=>callLogSubmit()}>submit</button>
                                    </div>
                        </div>
                     


                        </>

                    </div>
                    {cust_info &&datas && datas.call_log_details && 
                    <div className="prev_interction_div">
                    <div className={`bg_gray  px-3  ${PrevInteractions?"py-4" :"py-2"}`}>
                        <div className="d-flex align-items-center justify-content-between" onClick={()=>setPrevInteractions(!PrevInteractions)}>
                            <p className={`text-white sizel px-3 mb-0 pointer ${PrevInteractions ? "mb-3" :"my-2"}`} ><b>Previous Interaction</b> 
                             </p>
                             <p className="m-0 "><FaAngleDown size={20} /></p>
                             </div>
                             <>
                             {PrevInteractions &&
                            <>
                            {callInteractions && callInteractions.length > 0 ?
                                    <div id="past_churn_remarks_scroll_end  my-3" className={` col-lg-12 col-md-12 col-sm-12 m-0 p-0  ${callInteractions.length > 3 ? "overdue_call_tasks_scroll prev_interction_height" : ""}`}>
                                        {callInteractions&&callInteractions.map((item, idx) => {
                                            return (
                                                <div className='callLog_prev_interactions px-4 my-3 mx-4  py-3'>
                                                    <div className='row mx-0 mt-2' style={{position:'relative'}}>
                                                        <p className='m-0 col-1 p-0'>
                                                            <FaPhoneAlt className='call_log_icon_size ' size="20" color='white' />
                                                        </p>
                                                        <div className='col-10'>
                                                            <p className='text-white m-0 '> <b>{item.caller_name}</b></p>
                                                            <p className='text-white m-0 '> {moment(item.call_date).format("DD MMM YYYY")} | {item.call_dialed_time ? <>{item.call_dialed_time + " " + (moment(item.call_dialed_time, 'hh:mm:ss')).format('A')}</>:"-"} </p>
                                                        </div>
                                                    </div>

                                                    <div className='row mx-0 mt-2'>
                                                        <p className='m-0 col-1 p-0'>
                                                            <RiQuestionFill className='call_log_icon_size ' size="20" color='white' />
                                                        </p>
                                                        <div className='col-10'>
                                                            <p className='text-white m-0 '> {item.call_purpose ?item.call_purpose:"-" }</p>

                                                        </div>
                                                    </div>

                                                    <div className='row mx-0 mt-2'>
                                                        <p className='m-0 col-1 p-0'>
                                                            <BiMessageDetail className='call_log_icon_size mt-1' size="20" color='white' />
                                                        </p>
                                                        <div className='col-10'>
                                                            <p className='text-white m-0 '> {item.remarks ? dd_value(item.remarks):"-"}</p>

                                                        </div>
                                                    </div>
                                                </div>


                                            )
                                        })}
                                    </div>
                                    :
                                    <div>
                                        <p className='m-0 text-white text-center py-4'>No Previous Interactions</p>
                                    </div>
                            } 
                             </>}
                             </>
                        </div>
                    </div>}
                  

                    
                    
        </div>
        :
        <div>
            <CustomSkeleton/>
        </div>
         }

      </div>
      </Modal.Body>
     
    </Modal>
    </div>
    )

}


export default CommonCallLogModal