import React, { useEffect } from "react";
import { listAccountProvider } from "../../../actions/account_provider_actions";
import { get } from "../../../helpers/storage_helper";
import Select from "react-select";

export default function CustomChartFilter({
  onFilterChange,
  country_code,
  target_state,
  historical_state,
  disable_provider,
  acc_prvdr_changed,
  dateWiseFilter,
  lender,
  viewBy,
  acc_prvdr,filters
}) {
  const [selectedFilterType, setSelectedFilterType] = React.useState(filters);
  const [selectedAccountProvider, setSelectedAccountProvider] = React.useState(acc_prvdr);
  const [allAccPrvdr, setAllAccPrvdr] = React.useState([]);
  const [loadingAccPrvdr, setLoadingAccPrvdr] = React.useState(true);
  const [filter_type, setFilterType] = React.useState(filters);
  const [cursorPointerStatus, setCursorPointerStatus] = React.useState(true);
  const [accPrvdr, setAccPrvdr] = React.useState(null);
  const [activeAccPrvdr, setActiveAccPrvdr] = React.useState(null);
  const [showAccList, setShowAccList] = React.useState(true);

  useEffect(() => {
    // Fetch account providers when any of the dependencies change
    fetchAccountProvider();
    setSelectedFilterType(filters);
    setSelectedAccountProvider(acc_prvdr);
    onFilterChange(filters, acc_prvdr);
  }, [country_code, target_state, historical_state, dateWiseFilter, lender, viewBy, acc_prvdr,filters]);

  const prvdrSelectHandler = (accPrvdr) => {
    setCursorPointerStatus(false);
    setAccPrvdr(accPrvdr);
    if (acc_prvdr_changed) {
      acc_prvdr_changed(accPrvdr);
    }
    setActiveAccPrvdr(accPrvdr);
    setShowAccList(accPrvdr !== null);
  };

  const fetchAccountProvider = () => {
    const request = {
      country_code: country_code,
      time_zone: get("market").time_zone,
    };
    listAccountProvider({ ...request, status: "enabled", biz_account: true }).then((response) => {
      if (response && response.status === "success") {
        const accProviders = response.data.list.map((each) => each.acc_prvdr_code);
        setAllAccPrvdr(accProviders);
      }
      setLoadingAccPrvdr(false);
    });
  };

  const handleFilterTypeChange = (selectedOption) => {
    setSelectedFilterType(selectedOption);
    setFilterType(selectedOption);
    onFilterChange(selectedOption, selectedAccountProvider);
  };

  const handleOptionChange = (selectedOption) => {
    setSelectedAccountProvider(selectedOption);
    onFilterChange(filter_type, selectedOption);
    if (selectedOption === 'All') {
      selectedOption = null;
    }
    prvdrSelectHandler(selectedOption);
  };

  const getMultiSelectOptions = (type) => {
    switch (type) {
      case "*":
        return [
          { label: "Age", value: "age" },
          { label: "Gender", value: "gender" },
        ];
      case "RWA":
        return [
          { label: "Age", value: "age" },
          { label: "Gender", value: "gender" },
          { label: "Province", value: "region" },
        ];
      default:
        return [
          { label: "Age", value: "age" },
          { label: "Gender", value: "gender" },
          { label: "Region", value: "region" },
        ];
    }
  };

  const selectCustomStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#097afa" : "#202940",
      color: state.isSelected ? "red" : "white",
      padding: state.isFocused ? "3px 0px 3px 10px" : "3px 0px 3px 10px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? " " : "",
      borderTop: state.isFocused ? 0 : 0,
      borderLeft: state.isFocused ? 0 : 0,
      borderRight: state.isFocused ? 0 : 0,
      borderBottom: state.isFocused ? "2px solid  #5d616f" : "1px solid #5d616f",
      boxShadow: state.isFocused ? "none" : "",
    }),
    input: (provided) => ({
      ...provided,
      color: "white",
    }),
  };

  const renderAccountProviderButtons = () => (
    <div className="d-flex align-items-center">
      <label className="no-margin" style={{ paddingRight: 15 }}>
        Provider
      </label>
      <button
        className={`filterBtns allBtn_size pointer ${selectedAccountProvider === "All" ? "" : "opac_50"}`}
        style={{ height: 30, width: 60, cursor: "pointer" }}
        key={0}
        onClick={() => handleOptionChange("All")}
      >
        ALL
      </button>
      {allAccPrvdr.map((each, idx) => (
        <div
          className={`mx-1 pointer ${selectedAccountProvider === each ? "" : "opac_50"}`}
          style={{ height: 30, width: 60, cursor: "pointer" }}
          key={idx}
          onClick={() => handleOptionChange(each)}
        >
          <img className="img-fluid" src={`/img/${each}_logo_color.png`} alt={each} />
        </div>
      ))}
    </div>
  );

  const renderSelect = () => (
    <div className="d-flex align-items-center">
      <label className="no-margin" style={{ paddingRight: 15 }}></label>
      <div style={{ width: "" }}>
        <Select
          onChange={(selectedOption) => {
            const value = Array.isArray(selectedOption) && selectedOption.length > 0
            ? `(${selectedOption.map((option) => `'${option && option.value}'`).filter(Boolean).join(", ")})`
            : null;
            handleFilterTypeChange(value);
          }}
          value={selectedFilterType
            ? getMultiSelectOptions(country_code).filter(option => selectedFilterType.includes(option.value))
            : null
          }
          options={getMultiSelectOptions(country_code)}
          placeholder="Choose Filter"
          styles={selectCustomStyles}
          classNamePrefix="my-select"
          isMulti
        />
      </div>
    </div>
  );

  if ((historical_state === "get_portfolio_chart" || historical_state === "get_ontime_payments" || viewBy !== false) && country_code !== "*") {
    return loadingAccPrvdr ? (
      <div className="d-flex align-items-center">
        <div className="simple-spinner"></div>
      </div>
    ) : (
      <div className="custom-filter-switch" style={{marginLeft:"15px"}}>
        {renderAccountProviderButtons()}
      </div>
    );
  }

  return historical_state !== "get_portfolio_chart" &&
    historical_state !== "cohort_report" &&
    historical_state !== "get_ontime_payments" &&
    !dateWiseFilter ? (
     viewBy === false ?
    <div className="p-3 rounded mt-3">
      {country_code !== "*" && disable_provider !== null && loadingAccPrvdr ? (
        <div className="d-flex align-items-center">
          <div className="simple-spinner"></div>
        </div>
      ) : (
        <div className="custom-filter-switch">
          {country_code !== "*" && (disable_provider !== null ) && renderAccountProviderButtons()}
        </div>
      )}
      {renderSelect()}
    </div>:""
  ) : null;
}
