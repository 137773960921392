import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
//import {getAccountTxnsType} from '../../../actions/account_txn_actions';
import {getMasterData} from '../../../actions/common_actions';
import {Button } from 'react-bootstrap';
import DatePicker from 'react-datetime';
import moment from 'moment';
import '../../../styles/flow.css';
import {getRefAccounts} from '../../../actions/account_txn_actions';
import $ from 'jquery';
import {check_priv, get,get_item} from '../../../helpers/storage_helper';
class StatementSearch extends FlowComponent {

  state = {
    section1: "show_div", 
    account : {}
  }

componentWillMount()
{
        /*  const request = {data_key: "account_transaction_type"};
        getAccountTxnsType(this.req(request))
        .then((response) => {
          if(!response){return };
            if(response.status === "success"){
                this.getSelectOptionsFromResult(response.data.list, "account_txn_type", ["data_code", "data_value"]);
            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          }
        );*/
        this.setOptionsToStateFromApi("lender");
      this.setOptionsListToStateFromAppApi('common', 'list', {...this.def_req ,status: "enabled"}, ['acc_prvdr_code', 'name'], "acc_prvdrs");
      const request = {"status": 'enabled', data_key: "account_transaction_type"}
      const request_data = {"master_data": request};

      getMasterData(this.req(request_data))
         .then((response) => {
          if(!response){return };
            if(response.status === "success"){
                this.getSelectOptionsFromResult(response.data, "account_txn_type", ["data_code", "data_value"]);
            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          }
        );

        const lender_code = this.lender_code; //event.target.value
    
        let acc_prvdr_code = get('acc_prvdr_code');
    
        const account = {lender_code, "country_code": this.country_code,to_recon : true, network_prvdr_code : acc_prvdr_code }
        
        if(acc_prvdr_code != undefined){
          getRefAccounts(this.req({account},true))
            .then((response) => {
              if(!response){return };
              if(response.status === "success"){
                if(response.data != "")
                {
                  this.getSelectOptionsFromResult(response.data, "lender_accounts", ['id','name']);
                }     
              }else{ 
                alert(response.message + "\n" + JSON.stringify(response.data));  
              }
            }
          );
        } 
  }

  handleResetClick = (event) => {
    alert("called")
    this.handleReset();
  }


  handleSection2 = (value) =>{
      value = moment(value).format("YYYY-MM-DD");
      if(value.target){
        const event = value;
        value = event.target.value
      }
      if(value != "")
      {
        this.setState({section2: "disable_div"});
      }

      else{
        this.setState({section2:"show_div"});
      }
    }


  handleFields = (event) =>{
    
    if(event.target.value!= "")
    {
      this.setState({section1: "disable_div"});
    }
    else if(event.target.value == "")
    {
    this.setState({section1:"show_div"}); 
    }

    this.handleChange(event);
  }

  loadAccounts = (event) => {

    const lender_code = this.lender_code;
    var data = this.state.account;
    data[event.target.id] =  event.target.value;
    let acc_prvdr_code = get('acc_prvdr_code') ? get('acc_prvdr_code') : data.acc_prvdr_code;

    const account = {lender_code, "country_code": this.country_code,to_recon : true, network_prvdr_code : acc_prvdr_code, ...data }

    if(acc_prvdr_code != undefined){
      getRefAccounts(this.req({account},true))
        .then((response) => {
          if(!response){return };
          if(response.status === "success"){
            if(response.data != "")
            {
              this.getSelectOptionsFromResult(response.data, "lender_accounts", ['id','name']);
            }     
          }else{ 
            alert(response.message + "\n" + JSON.stringify(response.data));  
          }
        }
      );
    }
  }
 
    render(){
      return(
        <div className="date-range" style={{padding:15}}>          
          <div className = "form-row">
              {/* <div className = "form-group col-md-3">
                <span className = "form-label">Lender</span><font className="text-danger">   *  </font>
                  <select id="lender_code" defaultValue="UFLW" onChange={this.loadAccounts} className="form-control" type="text" required="required">
                    {this.state.lender_dd}
                  </select>
              </div>   */}
              {!this.admin_roles.includes(get('role_codes')) &&
                <div className="col-md-3 form-group ">
                    <span>Account Provider</span><font className="text-danger">   *  </font>
                    <select id="acc_prvdr_code"  value = {this.props.data.acc_prvdr_code} onChange={(e) => {this.handleChange(e); this.loadAccounts(e);}} className="form-control" type="text" required="required">
                    {this.state.acc_prvdrs_dd}
                    </select>
                </div> 
              }
              <div className = "form-group col-md-3">
                <span className = "form-label">Accounts</span><font className="text-danger">   *  </font>
                  <select id="account_id" className="form-control" required="required" onChange={this.handleChange} >
                      {this.state.lender_accounts_dd}                               
                  </select>
              </div>
              <div className = {`form-group col-md-3 ${this.state.section1}`}>
                <span className = "form-label">Start Date</span><font className="text-danger">   *  </font>
                <DatePicker closeOnSelect={true}  onChange={(value) => {this.handleDateChange("stmt_txn_date__from", value);this.handleSection2(value);}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>

              </div>
              <div className = {`form-group col-3 ${this.state.section1}`}>
                <span className = "form-label">End Date</span><font className="text-danger">   *  </font>
                <DatePicker closeOnSelect={true}  onChange={(value) => {this.handleDateChange("stmt_txn_date__to", value);this.handleSection2(value);}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
              </div>
              <div className = {`form-group col-md-3 ${this.state.section1}`}>
                  <span className = "form-label">Statement Transaction Type</span>
                    <select id="stmt_txn_type" className = "form-control" onChange={this.handleChange}>
                    {this.getSelectOptions("stmt_txn_type")}
                    </select>
              </div>
          </div>
          <div className = "form-row">           
              {check_priv('recon','recon_status')&&
                <div className = {`form-group col-md-3 ${this.state.section1}`}>
                    <span className = "form-label">Matching Status</span>
                      <select id="recon_status" className = "form-control" onChange={this.handleChange} >
                        {this.getSelectOptions("recon_status")}
                      </select>
                </div>
              }
              <div className = {`form-group col-md-3 ${this.state.section1}`}>
                  <span className = "form-label">Matching Status</span>
                    <select id="recon_status" className = "form-control" onChange={this.handleChange} >
                        <option value="">choose</option>
                        <option value = "10_capture_payment_pending">Capture Payment Pending</option>
                        <option value = "80_recon_done">Recon Done</option>
                        <option value = "cant_recon">Can't Recon</option>
                    </select>
              </div>
              <div className = {`form-group col-md-3 ${this.state.section2}`}>
                  <span className = "form-label">Statement Transaction ID</span>
                  <input id ="stmt_txn_id" className = "form-control" onChange={(e) => {this.handleFields(e);this.handleChange(e);}} required/>
              </div>
              <div className = {`form-group col-md-3 ${this.state.section2}`}>
                  <span className = "form-label">FA ID</span>
                  <input id ="loan_doc_id" className = "form-control" onChange={(e) => {this.handleFields(e);this.handleChange(e);}} required/>
              </div>
          </div>        
          <div className="form-row justify-content-md-center mt-3">
              <Button className="btn btn-secondory " onClick={this.props.onlist} disabled={this.props.disable_btn}  >Search</Button> &nbsp;
              {/*<Button type="reset" onClick={this.handleResetClick}>Reset</Button>*/}
          </div>
    </div> 
    )
    }

}
export default StatementSearch;
