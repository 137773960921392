import React from "react"
import Flag from 'react-world-flags'







const CustomFlag = ({country_code, height, width, alt = "", custom_flag = null})=>{


    const get_country_for_flag = (code) => {
        if(code == "MDG"){
            return "mg"
        }
        return code ? code.slice(0, -1) : '';
    };

    if(country_code){
        var flagCode = get_country_for_flag(country_code);
    }

    return (
        <>{!custom_flag ? 
            <Flag code={flagCode} height = {height} width = {width} fallback={ <span>{alt}</span> }/> 
            :
            <img src = {custom_flag}  height={height} width={width} alt={alt}/>
        }
        </>
    )

}


export default CustomFlag;