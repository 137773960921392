import React from 'react';
import {borrowerSearch} from '../../../actions/borrower_actions';
import {format_month_change} from '../../../helpers/common_helper';
import _ from 'lodash';
import FlowContainer from '../../common/container/core/FlowContainer';
import CommonReactTable from '../../../helpers/commonReact_table';
import moment from "moment";
import { Link} from 'react-router-dom';
import Select from "react-select";
import {Button} from 'react-bootstrap';
import { get } from '../../../helpers/storage_helper';
import { BasicReactTable } from '../../../helpers/react_table';

class AgreementRenewalContainer extends FlowContainer {

    state ={
        cust_wo_agg_renewal :{
		},
        columns :[],
        expi_agree_list:[],
        expi_agree_list_table:"hide_div",
		copy:[],
    loader:false
       
    }

    

    componentWillMount = async() => {
        this.search_borrower()
        this.load_flow_rel_mgr(null,"multiselect_rm_name")
    }

    search_borrower = () => {
      this.setState({loader:true})
        let search = {have_expired_agrmnt : "true"};
        if(this.state.flow_rel_mgr_id){
          search = {...search, flow_rel_mgr_id: this.state.flow_rel_mgr_id}
        }
		borrowerSearch(this.req({borrower_search_criteria:search}))
		.then((response) => {
      this.setState({loader:false})
			this.resp(response)
			if(!response){return };
			if(response.status === "success"){
				this.resp(response)
				var columns = this.state.columns
                columns =[
                    {
                      Header: "Account Number",
                      id: 'name',    
                      accessor: row => {return row.acc_number}
                    },
                    {
                      Header: "Cust Name",
                      id: 'first_name',    
                      accessor: row => {return row.first_name}
                    },
                    {
                      Header : "Cust ID",
                      id: "cust_id",
                      accessor:row => <Link to={"/borrower/indiv/view/"+row.cust_id} target="_blank" className="flow_custid" >{row.cust_id}</Link>,
                  },
                    {
                      Header: "Biz Name",
                      id: 'Biz Name',    
                      accessor:  row => {return row.biz_name}
                    },
                    {
                      Header: "RM Name",
                      id: 'flow_rel_mgr_name',  
                      sortable:true,   
                      accessor:  row => {return row.flow_rel_mgr_name}
                    },
                    {
                      Header: "Category",
                      id: 'Categoryame',    
                      accessor:  row => {return row.category}
                    },
                    {
                      Header: "Agreement Valid Upto",
                      id: 'Agreement Valid Upto',    
                      accessor:  row => {return row.aggr_valid_upto != null ? format_month_change(row.aggr_valid_upto) : "-NA-"}
                    },
                    {
                      Header: "Renewal Reason",
                      id: 'Renewal Reason',    
                      
                      accessor:  row => {return row.aggr_valid_upto >= moment().format("Y-MM-DD") ? "Agreement Will Expire" :"Agreement Expired"}
                    }] 
            }
            this.setState({columns,expi_agree_list:response.data.results,copy :response.data.results,expi_agree_list_table:"show_div"})
        
        })  
    }

    handleFilter = (event) => {
      let ids = event.map((e)=>e.value);
      this.setState({flow_rel_mgr_id:ids})
    }

    handleSearch = () =>{
      this.search_borrower()
    }

    changeHandler = (e) => {      
      const toFilter = e
      const filterfrom = this.state.expi_agree_list      
      const filteredData = filterfrom.filter(value => { 
        return(    
        value.cust_id !== null && value.cust_id.toLowerCase().includes(toFilter.toLowerCase()) 
              
        )
      })
      this.setState(state=>state.copy = filteredData)		
    }

    render(){
        return(
			<div className='container containerTopmargin max-width no-padding'>
			<div>
				<h5 className='no-margin headerTitle'>Agreement Renewal</h5> 
        {get("role_codes") == "relationship_manager" &&    
        <div className='row mt-4 mb-5'>
					<div className="col-md-3">
						<Select onChange={(e) => {this.handleFilter(e)}}
                        value = {this.state.select_value}
						options={this.state.flow_rel_mgr}
						styles={this.select_custom_styles()}
						classNamePrefix="my-select" 
						placeholder="Flow Relationship Manager"
						isMulti/>
                    </div>
					<div className='col-md-2'>
                        <Button disabled={this.state.flow_rel_mgr_id ? false : true} className= "mr-3 btn btn-primary" onClick={this.handleSearch}>SUBMIT</Button>
					</div>
				</div>  
        }        
				{this.state.expi_agree_list.length > 0 && this.state.loader == false?
					<>
						<div className='d-flex justify-content-between align-items-center' style={{padding:5}}>
							<p className='no-margin text-white'><b>Total Entries : {this.state.expi_agree_list.length}</b></p>
							<div style={{position:'relative'}}>
								<i class="fa-solid fa-magnifying-glass search_pos"></i>
								<input placeholder='Search here' className='search_posInput' onChange={(e)=>this.changeHandler(e.target.value)}></input>
								{/* <button className='btn-std btn btn-primary' onClick={()=>this.resetHandler()}>Reset</button> */}
							</div>
						</div>
						{/* <CommonReactTable showPag={true} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} resizable={false} defaultSorted={[{id:"name",desc:false}]} exportCSV={true} csvFile={"Agreement Renewal"} csvData={this.state.copy ? this.state.copy : []} searchData={[{title:' Agreement Renewal'}]}/> */}

            <BasicReactTable csv_file={true} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} FName={"Churn Management - New Churns"}  pagination={this.state.copy.length>9? true:false} default_page_size = {10} export_csv_datas={this.state.copy ? this.state.copy : []}  defaultSorted={[{id:"name",desc:false}]}  searchData={[{title:' Agreement Renewal'}]}/>  

					</>: this.state.loader ? 
					<div className='product_takeup_loader'></div> : <div className='text-center'>No results found</div>
				}
			</div>
		  </div>

			 )
    }

}

export default AgreementRenewalContainer