import React from 'react';
import { Navigate } from 'react-router-dom';
import '../../../styles/flow.css';
import '../../market/container/datatables.css';
import { Modal , Button,Badge} from 'react-bootstrap';
import {dd_value} from '../../../actions/common_actions';
import {listLender} from '../../../actions/lender_actions';
import FlowContainer from '../../common/container/core/FlowContainer';
import AccountContainer from '../../account/container/account_container';
import StatusButton from '../../common/component/core/StatusButton';
import {ViewEdit} from '../../common/partial';
import $ from 'jquery';
import  {check_priv} from '../../../helpers/storage_helper';
import CommonReactTable from '../../../helpers/commonReact_table';
import { BasicReactTable } from '../../../helpers/react_table';
class ListLenderContainer extends FlowContainer {

  state = {
     lenderList: null,
     toView: false,
     lender_code: null,
     modalBox: false,
     accountList: null,
     rowdatas:[],
     columndatas:[]      
  }

  handleClick = (event) => {
        this.setState({lender_code: event.target.id, toView: true});
    }

  componentWillMount(){

    if(check_priv("lender" , 'list_account')){
          this.btn_style = "show_div";
    }else{
          this.btn_style = "disable_div";
    }

    const country_code = this.country_code;
    listLender(this.req({country_code}))
        .then((response) => {
          if(!response){return };
          this.setState(state=>state.rowdatas = response.data)
          this.setState(state=>state.copy = response.data)
          this.setState(state=>state.columndatas = this.columndatas())
          // const lenderList = response.data.map((lender,i)=>

          //     <tr key={i}>

          //       <td > {lender.name}</td>
          //       <td > {dd_value(lender.lender_type)}</td>
          //       <StatusButton id={lender.id} entity="lenders" status={lender.status}/>
          //       <td><ViewEdit id={lender.lender_code} entity="lender"  onClick={this.handleClick}/></td>
          //       {/*<td > <input id={lender.lender_code} type='submit'  onClick={this.handleClick} value='View / Edit'  className='btn btn-primary' /></td>*/}
          //       <td > <input id={lender.lender_code} type='submit'  onClick={this.handleViewAccountsClick} value='Accounts'  className={`btn btn-primary ${this.btn_style}`} /></td>

          //     </tr>
          // );
          
          // this.setState({lenderList: lenderList});

          //  $('#list_lender').removeAttr('width').dataTable({
          //       "order" : [],
          //         "columns": [
          //              {"width" : "15%"}, 
          //              {"width": "15%"},
          //              {"width": "15%"},
          //              {"width": "15%"},
          //              {"width": "20%"},
          //              {"width": "20%"}
                      
          //            ],

          //         dom:'Bfrtip',
          //         buttons: [
          //         'csv','excel','print'
          //         ]
            
          //     });
        });
  }

  columndatas(){
    return[
      {
        Header : "Name", 
        id: "name",				       
        accessor : row => {return row.name}        
      },
      {
        Header : "Lender Type",
        id: "lender_type",
        accessor:row => {return dd_value(row.lender_type)}
      },
      {
        Header : "Status",
        id: "status",
        accessor:row => <StatusButton id={row.id} entity="lenders" status={row.status} head={1}/>
      },
      {
        Header : "Action",
        id: "action",
        accessor:row => <StatusButton id={row.id} entity="lenders" status={row.status} head={2}/>
      },
      {
        Header : "Action",
        id: "lender_code",
        accessor:row => <ViewEdit id={row.lender_code} entity="lender"  onClick={this.handleClick}/>
      },
      {
        Header : "Account",
        id: "ids",
        accessor:row => <input id={row.lender_code} type='submit'  onClick={this.handleViewAccountsClick} value='Accounts'  className={`btn btn-primary ${this.btn_style}`} />
      }           
    ]
  }



  handleViewAccountsClick = (event) => {
    this.setState({lender_code: event.target.id});
    this.setState({modalBox: true});
  }


  handleModalClose = (event) => {
      this.setState({ modalBox: false });
  }

  changeHandler = (e) => {		
		const toFilter = e
		const filterfrom = this.state.rowdatas
		const filteredData = filterfrom.filter(value => {
			return(
				value.name !==null && value.name.toLowerCase().includes(toFilter.toLowerCase()) ||
        value.lender_type !==null && value.lender_type.toLowerCase().includes(toFilter.toLowerCase()) 	                 				
			)
		})
		this.setState(state=>state.copy = filteredData)		
	}

  render(){
     if (this.state.toView === true) { 
           return <Navigate to={`/lender/view/${this.state.lender_code}`} />
    }

   if (this.state.copy != null) { 
      
    return( 
    <div className='container containerTopmargin max-width no-padding'>
      <div>
             <h4>{this.props.title || "Lender List"}</h4> 
               {/* <table id ='list_lender' className="table">
                        <thead className={"text-info"}>
                        <tr><th>Name</th>
                        <th>Lender Type</th>
                        <th>Status</th>
                        <th>Action</th>
                        <th>Action</th>
                        <th>Account</th></tr></thead>
                        <tbody> 
                        {this.state.lenderList}
                        </tbody>
               </table> */}
               <div className='d-flex justify-content-between align-items-center' style={{padding:5}}>
                  <p className='no-margin text-white'><b>Total Entries : {this.state.copy.length}</b></p>
                  <div style={{position:'relative'}}>
                    <i class="fa-solid fa-magnifying-glass search_pos"></i>
                    <input placeholder='Search here' className='search_posInput' onChange={(e)=>this.changeHandler(e.target.value)}></input>
                    {/* <button className='btn-std btn btn-primary' onClick={()=>this.resetHandler()}>Reset</button> */}
                  </div>
                </div>
               {/* <CommonReactTable showPag={true} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} resizable={false} defaultSorted={[{id:"name",desc:false}]} exportCSV={true} csvFile={"Lender Lists"} csvData={this.state.copy} searchData={[{title:'List Lenders'}]}/>                                          */}

               <BasicReactTable row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} FName={"Lender Lists"} csv_file={true} pagination={true} default_page_size = {15}  defaultSorted={[{id:"name",desc:false}]} searchData={[{title:'List Lenders'}]}/> 
                      
      </div>
      <Modal show={this.state.modalBox} onHide={this.handleModalClose} size="xl">
            <Modal.Header closeButton show={this.state.modalBox} onHide={this.handleModalClose}>
                <Modal.Title>Lender Accounts : {this.state.lender_code}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AccountContainer entity="lender" entity_id={this.state.lender_code}/>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleModalClose}>Close</Button>
            </Modal.Footer>
        </Modal>
      </div> 
      );
}
      return (
        <div><p>No Lenders Available ! </p></div>
        
      );
  }
}

export default ListLenderContainer;
