import React from 'react';
import FlowContainer from '../../common/container/core/FlowContainer';
import $ from 'jquery';	
import SearchFieldVisit from '../component/SearchFieldVisit';
import {getFieldVisitData} from '../../../actions/field_visit_actions';
import {format_date_tbl} from '../../../helpers/common_helper';
import {dd_value} from '../../../actions/common_actions';
import { Link} from 'react-router-dom';
import {get,get_item,check_priv, Config} from '../../../helpers/storage_helper';
// import 'react-table-6/react-table.css' ;
import moment from 'moment';
import CommonReactTable from '../../../helpers/commonReact_table';
import withRouter from '../../../Router/Withrouter';
import { BasicReactTable } from '../../../helpers/react_table';



class FieldVisitContainer extends FlowContainer{
	constructor(props){
    super(props)
		this.state = {
		field_visit : {'individual_visits' : false},
		customer_visit : "hide_div",
		visits: [],
      	loading:true,
		columns : [],
		from_to_dates :{},
		paginate : Config('paginate') ? Config('paginate') : null,
		load_table : false,
		export_data : [],
		pagination :false,
		total :0,
		loader:false,
		priv_users_datas:null,
		priv_users:null
		}
	}

	componentWillMount(){
		
		const field_visit = this.state.field_visit

		if(check_priv("field_visit","all_search")){
			//var associated_with =  "flow";
			//var associated_entity_code =  this.data_prvdr_code;
    		//this.setOptionsToStateFromApi("rel_mgr", {...this.def_req, associated_with, associated_entity_code});	
    		const status="enabled";
			const priv_code =  "field_visit/checkin"

		if(get("role_codes") == "relationship_manager"){
			this.load_flow_rel_mgr(null,"multiselect_rm_name");
		}else{
			this.setOptionsListToStateFromAppApi("common","priv_users",this.req({priv_code,status}), ['id' , 'name'],null,"multiselect");
		}
		}else if(check_priv("field_visit","self_search")){
			field_visit.search_type = "self_search"
		}

		if(this.props.params.cust_id){
			field_visit.visit_start_time__to = moment(new Date())
			field_visit.visit_start_time__from = moment().subtract(3, 'months')
			field_visit.individual_visits = true
			field_visit.cust_visit_history = true
			field_visit.cust_id = this.props.params.cust_id
		}
		this.setState({field_visit})

	}
	componentDidMount(){
		if(this.props.params.cust_id){
			var field_visit = this.state.field_visit
			field_visit.visit_start_time__to = field_visit.visit_start_time__to.format("YYYY-MM-DD")
			field_visit.visit_start_time__from = field_visit.visit_start_time__from.format("YYYY-MM-DD")
			this.handleSearch()
		}


	}
	validate_search(field_visit) {
		if(field_visit.visit_start_time__from && field_visit.visit_start_time__to ){
			return true
  		}else{
  			alert("Please enter valid search criteria")
			this.setState({loader : false})
  			return false
  		}
	}

	pagination = (page, count, sorted)=>{
			
		this.setState((state) => {
			return {
			  paginate: {
				...state.paginate,
				pagination_count: count,
				pagination_page:page,
				sorting:sorted,
				total_count : this.state.total
			  },
			};
		  },()=>this.handleSearch());
	
	}

	page_count = ()=>{
		
		const total = this.state.total
		const page_count_mod = (total%this.state.paginate.pagination_count)
		
		if(page_count_mod > 0){
			const page_count = (total/this.state.paginate.pagination_count)
			this.setState({'page_count' : Math.trunc(page_count)+1}, ()=>console.log(this.state.page_count))

		}else{
			const page_count = (total/this.state.paginate.pagination_count)
			this.setState({'page_count' : page_count})
		}
	}

	handleExportCSV = (event) => {
		let req = this.removeKey('paginate')
		let visit = JSON.parse(JSON.stringify(req));
		let resp = null;
		if(event == 'email') {
			visit.export = 'email'
			resp = window.confirm(`The current search exceeds ${Config("export_csv").limit} rows. So the exported data will be sent to your email shortly.`)
		}

		if(event == 'email' && !resp){
			return
		}
		else{
			this.handleCSVapi(visit, event)
		}

		
	}

	handleCSVapi(visit, action){
		let calling_api = true
		if (action == 'email') calling_api = false 
		getFieldVisitData(this.req(visit, true, null , calling_api))
			.then((response) => {
				this.resp(response)
				if(action == 'email'){
					return
				}
				this.setState(state => state.export_data = response.data.visits)
			})
	}

	removeKey = (key) => {
		const { [key]: deletedKey, ...updatedObject } = this.state.field_visit;
		this.setState({ field_visit: updatedObject }, ()=>console.log(this.state.field_visit,"#####"));
		return updatedObject
	  };


	handleSearch = (event) => {
		var request = this.state.field_visit
		this.setState({loader:true})
		if(this.state.field_visit.individual_visits == true && this.state.paginate){
			if(event == 'search'){
				this.setState({load_table : false,visits : [], total : 0})
				request.paginate =  Config('paginate')
			}
			else{
				request.paginate = this.state.paginate
			}
		}
		
		var is_valid = this.validate_search(request)
		if(is_valid){
			this.setState({search_criteria:request})
			getFieldVisitData(this.req(request))
			    .then((response) => {
					this.resp(response)
			        	if(!response){return this.setState({loader : false})};
	        				if(response.status === "success"){
	        					this.resp(response)
								if(this.state.paginate != null && response.data.individual_visits === true){								
									this.setState(state=>state.total=response.data.total_counts)	
									this.page_count()
								}
								else{
									this.setState(state=>state.total=response.data.visits.length)
								}

								var columns = []
	        					if(response.data.individual_visits === true){
										
									columns = [{  
										     		Header: 'Visitor Name',  
										     		accessor: 'visitor_name' ,
													sortable : true,
													width: 90,
												    Cell: e =><p className={`m-0 `}>{e.value ?  e.value.split(" ")[0] : "-"}</p> ,

										     	},{  
										     		Header: <p className='m-0 text-center'>Cust Name</p>,  
										      		accessor: 'cust_name' ,
													sortable : true,	
													width: 90,										     
													Cell: e =><p className={`m-0 text-center`}>{e.value ?  e.value.split(" ")[0] : "-"}</p> ,


										     	},{  
											      	Header: <p className='m-0 text-center'>Cust ID</p>,  
											      	accessor: 'cust_id',
													sortable : true,		
											      	Cell: e =>   <p className='m-0 text-center'> {e.value ?  <Link to={"/borrower/indiv/view/"+e.value} target="_blank" >{e.value}</Link>:"-"}</p> ,
										     	},{  
										     		Header: 'Visit Time',
													id: 'visit_start_time', 
													sortable : true,
											 		accessor:row => format_date_tbl(row.visit_start_time,true)  
										     	},{  
											    	Header: 'Purpose',  
											     	id: 'visit_purpose',
													sortable : true,
											     	
											     	style: { whiteSpace: 'unset' },
													accessor:row => row.visit_purpose.map(purpose=>
													dd_value(purpose)).join(", ")
										     	},{  
										     		Header: 'Remarks',  
										     		accessor: 'remarks',
										     		width: 400,
													sortable : true,
										     		style: { whiteSpace: 'unset' },
										     }]

	        					}
        						if(response.data.individual_visits === false) {          					  	
		        					columns = [{  
										      	Header: 'Visitor Name',  
										      	accessor: 'visitor_name' ,
												sortable : true
										     },{  
										     		Header: 'Visit date',
										     		id: 'visit', 
													sortable : true,
										     		accessor:row => format_date_tbl(row.visit_date) ,
										     },{  
										     		Header: 'Visits',  
										     		accessor: 'visits',
													sortable : true,
										     }]
	        					}	

								this.setState({customer_visit:"show_div"})
								this.setState({load_table : true})

								this.setState((state) => {
									return { columns,visits  : response.data.visits};
								}, ()=>this.setState({loader : false}));
	        				}
	        
	        			})

				}
	}

	handlevisitchange =(event) =>{
	 	var field_visit = this.state.field_visit
 		const value = event.target.id === 'individual_visits' ? event.target.checked : event.target.value;
 		field_visit[event.target.id] = value
 		this.setState({field_visit,customer_visit:"hide_div",visits: [], load_table : false})	
	}
	handleCheck=(e)=>{
		console.log(e.target.checked);
		this.setState({pagination:true, load_table : false})
		if(e.target.checked === false){
			let req_data = this.removeKey('paginate')
			this.setState({pagination:false})
		}
	}

	handlePurposeChange = (selected_options,id) =>{
		this.setState({load_table : false})
		this.setMultiselectOptionOnState(selected_options,id,this.state.field_visit)
    }
	render(){
    return(
    	<div className="container max-width containerTopmargin no-padding">
    		<div className="row no-margin">
	    		<h3 className="field_visit no-margin headerTitle headerPadvert">
	    		{ this.state.field_visit.cust_visit_history ? "Search Customer Visit" + " ("+this.state.field_visit.cust_id+")" 
	    		: "Search Customer Visit" }</h3>    		 
	    	</div>
	    	<SearchFieldVisit  id="searchfieldvisit"  
					onSearch={this.handleSearch} 
					onComponentChange={this.handlevisitchange} 
					onComponentReset={this.handleComponentReset} 
					search_btn = {this.state.calling_api}
					onComponentElementChange={this.handlevisitchange}
					priv_users 	= {get("role_codes") != "relationship_manager" ? this.state.priv_users : this.state.flow_rel_mgr}
					priv_users_dd	= {this.state.priv_users_datas}
					datas={this.state}
					field_visit = {this.state.field_visit}
					onCheck={this.handleCheck}
					handlePurposeChange={(options,id)=>{this.handlePurposeChange(options,id)}}

			/>
			{this.state.visits.length > 0 ? 
				<>
					<b className='text-white mb-2 mt-2'>Total Entries : {this.state.paginate ? this.state.total : this.state.visits.length}</b>
					{this.state.field_visit.individual_visits === true && this.state.load_table &&
					<div>{
						<div  className='flagg_cust_table' style={{display:!this.state.loader ?'':'none'}}>
							{this.state.paginate == null ?
									// <CommonReactTable showPag={true} minRow={0} row_data={this.state.visits !== undefined ? this.state.visits : []} column_data={this.state.columns} resizable={false} sortable={false} exportCSV={true} csvFile={"field_visits"} csvData={this.state.visits !== undefined ? this.state.visits : []} searchData={[{...this.state.field_visit,title:"Search Customer visit"}]}/>

									<BasicReactTable csv_file={true} row_data={this.state.visits !== undefined ? this.state.visits : []} column_data={this.state.columns} FName={"field_visits"}  pagination={this.state.visits.length>9? true:false} default_page_size = {10} export_csv_datas={this.state.visits ? this.state.visits : []}  searchData={[{...this.state.field_visit,title:"Search Customer visit"}]}/>  
								:
									<BasicReactTable csv_file={true} pagination={true}  row_data={this.state.visits !== undefined ? this.state.visits : []} column_data={this.state.columns}  FName={"field_visits"} export_csv_datas={this.state.export_data !== undefined ? this.state.export_data : []} searchData={[{...this.state.field_visit,title:"Search Customer visit"}]} filter_data = {this.pagination} server_side_pagination = {this.state.paginate} total_page_count = {this.state.page_count} defaultPageSize={10} handleExport = {this.handleExportCSV} />
							}
						</div> }

					</div>
					}
				</> 
			: 
				this.state.load_table &&
				<p className='text-white text-center' style={{marginTop:"15%"}}>NO DATA FOUND</p>
			}
			<div style={{display:this.state.loader ?'':'none'}}>
					<div style={{marginTop:"15%"}} > <p className='text-light mt-3 text-center'>Loading...</p>
						<div className="product_takeup_loader"></div>
					</div>
				</div>
			{this.state.visits.length > 0 && this.state.field_visit.individual_visits === false &&
			<div className={`${this.state.customer_visit} flagg_cust_table`} >	

				<BasicReactTable csv_file={true} row_data={this.state.visits !== undefined ? this.state.visits : []} column_data={this.state.columns} FName={"field_visits"}  pagination={this.state.visits.length>9? true:false} default_page_size = {10} export_csv_datas={this.state.visits ? this.state.visits : []}  searchData={[{...this.state.field_visit,title:"Search Customer visit"}]}/>  


				{/* <CommonReactTable showPag={true} minRow={0} row_data={this.state.visits !== undefined ? this.state.visits : []} column_data={this.state.columns} resizable={false} sortable={false} exportCSV={true} csvFile={"field_visits"} csvData={this.state.visits ? this.state.visits : []} searchData={[{...this.state.field_visit,title:"Search Customer visit"}]} defaultPageSize={10}/> */}
    		</div>
			}
    	</div>
    	)
	}
}

export default  withRouter (FieldVisitContainer);