import { get_path } from '../helpers/common_helper';
import {call_fetch} from './../helpers/fetch_helper';


/*export const getAllDataKeys = request => {
   return call_fetch(get_path('admin') + '/master_data/key/all_data_keys', request);
}*/
export const getAllDataKeys = request => {
   return call_fetch(get_path('app') + '/common/key/all_data_keys', request);
}

export const createMasterDataKey = request => {
   return call_fetch(get_path('admin') + '/master_data/key/create', request);
}

export const createMasterData = request => {
   return call_fetch(get_path('admin') + '/master_data/create', request);
} 

export const listMasterDataKeys = request => {
   return call_fetch(get_path('admin') + '/master_data/key/list', request);
}

export const updateDataKeyStatus = request => {
   return call_fetch(get_path('admin') + '/master_data/key/status', request);
}

/*export const getParentDataCode = request => {
   return call_fetch(get_path('admin') + '/master_data/key/data_code_name_list', request);
}*/

/*export const getCSDataCode = request => {
   return call_fetch(get_path('admin') + '/master_data/score_models/list', request);
}*/

export const listScoreFactors = request  => {
   return call_fetch(get_path('admin') + '/master_data/score_factors/list', request);
}