import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import '../../../styles/flow.css';
import {rt_algn,lbl_amt,format_date,to_amount} from '../../../helpers/common_helper';
import { Link } from 'react-router-dom';
import {check_priv} from '.././../../helpers/storage_helper';
import {OverlayTrigger,Popover} from 'react-bootstrap';


class CurrentOSFA extends FlowComponent {
    state ={
		show_waiver : true
	}
    componentWillMount(){

       if(this.props.hasOwnProperty('show_waiver')){
            this.setState({show_waiver : this.props.show_waiver});
       } 

    }
  render(){
      let summary = this.props.summary;
      
      return(
          <div>
              <div className = "row">
                <div className = "col-md-6">
                    <div className="form-group row reset-margin justify-content-center align-items-center">
                        <label className="col-md-4 reset-margin" ><b>Customer</b></label>
                        <label className="col-md-6 reset-margin">{summary.cust_name} | {summary.biz_name} ({summary.cust_mobile_num})  </label>  
                    </div>
                </div>
                <div className = "col-md-6">
                    <div className="form-group row justify-content-center align-items-center">
                        <label className="col-md-4 reset-margin" ><b>Status</b></label>
                        <label className="col-md-6 reset-margin">{summary.status}</label>  
                    </div>
                </div>
            </div>
            <div className ="row">
                <div className = "col-md-6">
                    <fieldset className = "border border-dark p-2">
                        <legend className="w-auto text-labelblue" style ={{fontSize : "15px"}}>Total Due</legend>
                        <div className="form-group row reset-margin justify-content-center align-items-center py-2">
                            <label className="col-md-5 reset-margin"><b>FA Due</b></label>
                            <label className="col-md-5 text-right reset-margin" >{lbl_amt(summary.loan_principal,this.currency_code)} </label>  
                        </div>
                        <div className="form-group row reset-margin justify-content-center py-2 align-items-center">
                            <label className="col-md-5 reset-margin"><b>Fee Due</b></label>
                            <label className="col-md-5 text-right reset-margin" >{lbl_amt(summary.flow_fee,this.currency_code)} </label>  
                        </div>
                        {summary.acc_prvdr_code == 'RMTN' &&
                            <div className="row reset-margin justify-content-center align-items-center py-2">
                                <label className="col-md-5 no-margin"><b>Charges Due</b></label>
                                <label className="col-md-5 no-margin text-right" >{lbl_amt(summary.charges,this.currency_code)} </label>  
                            </div>
                        }
                        
                        <div className="form-group row reset-margin justify-content-center align-items-center py-2">
                            <label className="col-md-5 reset-margin"><b>Penalty Due</b></label>
                            <label className="col-md-5 text-right reset-margin" >{lbl_amt(summary.to_capture.tot_prov_penalty,this.currency_code)} </label>  
                        </div>
                    </fieldset>
                </div>
                <div className = "col-md-6">
                    <fieldset className = "border border-dark p-2">
                        <legend className="w-auto text-labelblue" style ={{fontSize : "15px"}} >Already Paid</legend>
                        {(summary.paid_principal == null && summary.paid_fee == null && summary.paid_penalty == null) 
                            ? <lable className="text-labelblue reset-margin" style={{fontSize : '56px', margin : '185px'}}>Nil</lable> :
                            <>
                            <div className="form-group row reset-margin justify-content-center align-items-center py-2">
                                <label  className="col-md-5 reset-margin"><b>FA Paid</b></label>
                                <label className="col-md-5 text-right reset-margin" >{summary.paid_principal ? lbl_amt(summary.paid_principal,this.currency_code) : <b className='text-labelblue'>NIL</b>} </label>  
                            </div>
                            <div className="form-group row reset-margin justify-content-center align-items-center py-2">
                                <label  className="col-md-5 reset-margin"><b>Fee Paid</b></label>
                                <label className="col-md-5 text-right reset-margin" >{summary.paid_fee ? lbl_amt(summary.paid_fee,this.currency_code) : <b className='text-labelblue'>NIL</b>} </label>  
                            </div>
                            {summary.acc_prvdr_code == 'RMTN' &&
                                <div className="row reset-margin justify-content-center align-items-center py-2">
                                    <label className="col-md-5 no-margin"><b>Charges Paid</b></label>
                                    <label className="col-md-5 no-margin text-right" >{summary.paid_charges ? lbl_amt(summary.paid_charges,this.currency_code) : <b className='text-labelblue'>NIL</b>} </label>  
                                </div>
                            }
                            <div className="form-group row reset-margin justify-content-center align-items-center py-2">
                                <label  className="col-md-5 reset-margin"><b>Penalty (Paid + Waived)</b></label>
                                <label className="col-md-5 text-right reset-margin" >{summary.paid_penalty ? lbl_amt(summary.paid_penalty,this.currency_code) : <b className='text-labelblue'>NIL</b>} </label>  
                            </div>
                            </>

                           
                        }
                            {summary.paid_excess >0  && check_priv("loan","capture_excess") &&
                            <div className="row reset-margin justify-content-center align-items-center py-2">
                                    <label  className="col-md-5 no-margin"><b>Excess Paid</b></label>
                                    <label className="col-md-5 no-margin text-right" >{lbl_amt(summary.paid_excess,this.currency_code)} </label>
                                   
                                <>
                                    <h6 className = "text-center col-md-5"></h6>
                                    <h6 className = "col-md-5 text-right reset-margin py-2"><Link to = "#" onClick ={this.props.handleExcessModal}>Capture Excess Reversal</Link></h6>
                                </>
                               
                            </div>
                            
                        }
                        
                    </fieldset>
                </div>
            </div>
            <fieldset className = "border border-dark mt-2 p-2">
                <legend className="w-auto text-labelblue" style ={{fontSize : "15px"}} >Current Outstanding</legend>
                <div className = "row justify-content-around">
                    <div className = "col-md-3">
                        <div className="form-group row reset-margin justify-content-center">
                            <label  className="col-md-5 reset-padding"><b>OS Principal</b></label>
                            <label className="col-md-7 text-right" >{lbl_amt(summary.os_principal,this.currency_code)} </label>  
                        </div>
                    </div>
                    <div className = "col-md-3">
                        <div className="form-group row reset-margin justify-content-center">
                            <label  className="col-md-5 label__name reset-padding"><b>OS Fee</b></label>
                            <label className="col-md-7 text-right" >{lbl_amt(summary.os_fee,this.currency_code)} </label>
                            {/* {(summary.os_fee-summary.to_capture.fee)>0 && this.state.show_waiver && summary.can_waive_fee && summary.os_principal == 0 && */}
                                <>
                                    <h6 className = "text-center col-md-5"></h6>
                                    <h6 className = "text-center col-md-7"><Link to = "#" onClick ={()=>this.props.handleWaiverModal('fee')}>Waive Fee</Link></h6>
                                </>
                            {/* } */}
                        </div>
                    </div>
                    {summary.acc_prvdr_code == 'RMTN' &&
                        <div className = "col-md-3">
                            <div className="form-group row reset-margin justify-content-center">
                                <label  className="col-md-5 reset-padding"><b>OS Charges</b></label>
                                <label className="col-md-7 text-right" >{lbl_amt(summary.os_charges,this.currency_code)} </label>  
                            </div>
                        </div>
                    }
                    <div className = "col-md-3">
                        <div className="form-group row reset-margin justify-content-center">
                            <label  className="col-md-5 label__name reset-padding"><b>OS Penalty</b></label>
                            <label className="col-md-7 text-center" >{lbl_amt(summary.os_penalty,this.currency_code)} </label> 

                                {(summary.os_penalty-summary.to_capture.penalty)>0 && summary.request_waiver == false && this.state.show_waiver &&
                                       <>
                                    <h6 className = "text-center col-md-5"></h6>
                                    <h6 className = "text-center col-md-8"><Link to = "#" onClick ={()=>this.props.handleWaiverModal('penalty')}>Waive Penalty</Link></h6>
                                       </>
                                    } 

                                    {(summary.os_penalty-summary.to_capture.penalty)>0 && summary.request_waiver && this.state.show_waiver &&
                                       <>
                                       {summary.pending_with ?
                                          <OverlayTrigger placement='top' trigger={['hover', 'focus']} overlay={  <Popover style={{fontSize: "12px", padding: "15px", fontFamily: "Montserrat"}}>{`Pending with ${summary.pending_with}`}</Popover>}>
                                            <div>
                                                <h6 className = "text-center col-md-5"></h6>
                                                <h6 className = "text-center col-md-8"><Link to = "#" onClick = {this.props.handleReqWaiverModal}>Waiver Requested</Link></h6>
                                            </div>
                                          </OverlayTrigger>
                                          :
                                          <div>
                                            <h6 className = "text-center col-md-5"></h6>
                                            <h6 className = "text-center col-md-8"><Link to = "#" onClick = {this.props.handleReqWaiverModal}>Request Waiver</Link></h6>
                                          </div>}
                                        
                                       </>
                                    } 
                        </div>
                    </div>
                </div>                        
            </fieldset>
          </div>
      )
  }
}
export default CurrentOSFA;
