import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import {Alert, Row, Col } from 'react-bootstrap';
import '../../../styles/flow.css';
import {Link} from 'react-router-dom';
import {dd_value} from '../../../actions/common_actions';
import DisplayImage from '../../common/component/DisplayImage';
import moment from "moment";
import { getValueFromLangJson, title_case } from '../../../helpers/common_helper';

class PersonPanel extends FlowComponent {

	render(){
		
		return(

			<>
			<div className="floatAdvancetitleBox" style={{backgroundColor:"#202940"}}>
					<div className="floatAdvanceperiodHeaderBox">
						{this.props.title=="Third Party A/C Owner" &&
						<h2 className={"floatAdvanceperiodHeader padL_15 no-margin"}>{this.props.title}  <img  src={ `/img/${this.props.data.acc_prvdr_code}_logo_color.png `} style={{paddingLeft:'20px',height:30,width:85}} alt={this.props.data.acc_prvdr_code} /> <span style={{paddingLeft:'10px'}}>{this.props.data.acc_number} </span></h2>
						}
							{this.props.title=="Business Owner Person" &&
						<h2 className={"floatAdvanceperiodHeader padL_15 no-margin"}>{getValueFromLangJson("business_owner_details")}</h2>
						}
					</div>
					<div className="row no-margin">						
							<div className='col-md-3'>
								<div>
									<p className='no-margin viewCus_label'>{getValueFromLangJson("first_name")}</p>
									<p className='no-margin viewCus_labelVal'>{this.props.data.first_name}</p>
								</div>
								<div>
									<p className='no-margin viewCus_label'>{getValueFromLangJson("middle_name")}</p>
									<p className='no-margin viewCus_labelVal'>{this.props.data.middle_name}</p>
								</div>
								<div>
									<p className='no-margin viewCus_label'>{getValueFromLangJson("last_name")}</p>
									<p className='no-margin viewCus_labelVal'>{this.props.data.last_name}</p>
								</div>															 
							</div>
							<div className='col-md-3'>
								<div>
									<p className='no-margin viewCus_label'>{getValueFromLangJson("dob")}</p>
									<p className='no-margin viewCus_labelVal'> {moment(this.props.data.dob, 'YYYY-MM-DD').format("DD MMM YYYY")} </p>
								</div>
								<div>
									<p className='no-margin viewCus_label'>{getValueFromLangJson("gender")}</p>
									<p className='no-margin viewCus_labelVal'>{dd_value(this.props.data.gender)}</p>								
								</div>
								<div>
									<p className='no-margin viewCus_label'>{getValueFromLangJson("email_id")}</p>
									<p className='no-margin viewCus_labelVal'>{this.props.data.email_id}</p>
								</div>																								
							</div>
							<div className='col-md-3'>
								<div>
									<p className='no-margin viewCus_label'>{getValueFromLangJson("national_id")}</p>
									<p className='no-margin viewCus_labelVal'>{this.props.data.national_id}</p>
								</div>
								<div>
									<p className='no-margin viewCus_label'>{getValueFromLangJson("mobile_no")}</p>
									<p className='no-margin viewCus_labelVal'>{this.props.data.mobile_num}</p>								
								</div>
								<div>
									<p className='no-margin viewCus_label'>{getValueFromLangJson("whatsapp_number")}</p>
									<p className='no-margin viewCus_labelVal'>{this.props.data.whatsapp}</p>	
								</div>														          
							</div>
							{this.props.title=="Business Owner Person" &&
							<div className='col-md-3'>
								<div>
									<p className='no-margin viewCus_label'>{getValueFromLangJson("alt_biz_mobile_num")} 1</p>
									<p className='no-margin viewCus_labelVal'>{this.props.data.alt_biz_mobile_num_1}</p>								
								</div>
								<div>
									<p className='no-margin viewCus_label'>{getValueFromLangJson("alt_biz_mobile_num")} 2</p>
									<p className='no-margin viewCus_labelVal'>{this.props.data.alt_biz_mobile_num_2}</p>
								</div>
								<div>
									
								</div>
							</div>
							}
                         
							{/* <div className='row no-margin'>
								<Col>First Name</Col>
								<Col><b>{this.props.data.first_name}</b></Col>
								<Col>Middle Name</Col>
								<Col><b>{this.props.data.middle_name}</b></Col>
							</div>
							<div className='row no-margin'>
								<Col>Last Name</Col>
								<Col><b>{this.props.data.last_name}</b></Col>
								<Col>DOB</Col>
								<Col><b>{this.props.data.dob}</b></Col>
							</div> */}
							{/* <div className='row no-margin'>
								<Col>Gender</Col>
								<Col><b>{dd_value(this.props.data.gender)}</b></Col>
								<Col>Email ID</Col>
								<Col ><b>{this.props.data.email_id}</b></Col>
							</div>
							<div className='row no-margin'>
								<Col>Mobile Number</Col>
								<Col><b>{this.props.data.mobile_num}</b></Col>
								<Col>WhatsApp Number</Col>
								<Col><b>{this.props.data.whatsapp}</b></Col>
							</div> */}
							{/* <div className='row no-margin'>
								<Col>Alt Biz Mobile Num 1</Col>
								<Col><b>{this.props.data.alt_biz_mobile_num_1}</b></Col>
								<Col>Alt Biz Mobile Num 2</Col>
								<Col><b>{this.props.data.alt_biz_mobile_num_2}</b></Col>
							</div> */}
							{/* <div className='row no-margin'>
								<Col>National ID</Col>
								<Col><b>{this.props.data.national_id}</b></Col>
								<Col></Col>
								<Col></Col>
							</div>														 */}
						{/*this.props.data.photo_pps_path && 
							<DisplayImage title="Passport Size Photo" image_path={this.props.data.photo_pps_path} file_name={this.props.data.photo_pps} />
						*/}							
					</div>
					<div className ="row no-margin">
					{this.props.addl_mob_num && this.props.addl_mob_num.length > 0  && this.props.addl_mob_num.map((addl_num, i) => 
					
						<div className='col-md-3'>
							<p className='no-margin viewCus_label'>{getValueFromLangJson('addl_mobile_num')} {i+1}  </p>
							<p className='no-margin viewCus_labelVal'>{addl_num.mobile_num} ({title_case(addl_num.relation)})</p>								
						</div>
					)}
					</div>
					<div className ="row no-margin text-center pad_15">
						<div className='col-md-6 no-padding cusVisit_imgCen'>
							<DisplayImage title={getValueFromLangJson("national_id_photo")} image_path={this.props.data.photo_id_proof_path} file_name={this.props.data.photo_id_proof} />
						</div>
						<div className='col-md-6 no-padding cusVisit_imgCen'> 
							<DisplayImage title={getValueFromLangJson("national_id_back_photo")} image_path={this.props.data.photo_id_proof_back_path} file_name={this.props.data.photo_id_proof_back} />
						</div>	

						 {this.props.title=="Third Party A/C Owner" &&
							<div className=' col-md-6 no-padding cusVisit_imgCen' style={{margin:'20px 0px'}}>
								<DisplayImage title="Photo Consent Letter" image_path={this.props.data.photo_consent_letter_file_path} file_name={this.props.data.photo_consent}/>
							</div>
                	     }
					</div>
					
			</div>
			</>

			);
	}
}

export default PersonPanel;
