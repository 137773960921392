import {call_fetch} from './../helpers/fetch_helper';
import { get } from '../helpers/storage_helper';
import { get_path } from '../helpers/common_helper';



export const createAccountProvider = request  => {
   return call_fetch(get_path("admin") + '/acc_prvdr', request);
} 

export const listAccountProvider = (request)  => {
   return call_fetch(get_path("admin") + '/acc_prvdr/list', request);
}