import {getMasterData} from '../../../actions/common_actions';
import React from 'react'
import ReactDOM from 'react-dom'
import {Form} from 'react-bootstrap';
import DatePicker from 'react-datetime';
import FlowComponent from '../../common/component/core/FlowComponent';
import moment from 'moment';
import { format } from 'date-fns';

class SMSLogs extends FlowComponent{

    state ={
       
    }

    componentWillMount(){
        this.handleCurdate()
    }
    
    handleCurdate(){
        const date = new Date()
        const cur_date = format(date, 'dd MMM y') 
        return cur_date
    }

    render(){
        return(

        <div>
            
            <Form className="mb-3" >
	        	<Form.Row className = "my-4" style={{display:"flex",alignItems:"center"}}>
                    
                    <div className="col-3" style={{color:"white"}}>
                        <span style={{color:"white"}}>Vendor Code</span>
                        <Form.Control as ="select" id = 'vendor_code' onChange={this.handleChange} style={{padding:0}}>
                        {this.getSelectOptions("vendor")}
                        </Form.Control>
                    </div>

                    <div className="col-3" style={{color:"white"}}>
                        <span style={{color:"white"}}>Direction</span>
                        <Form.Control as ="select" id = 'direction' onChange={this.handleChange} style={{padding:0}}>
                        {this.getSelectOptions("direction")}
                        </Form.Control>
                    </div>

                    <div className="col-3 sms_date_picker">
                        <span style={{color:"white"}}>Start Date</span><font className="text-danger"> * </font>
                        <DatePicker defaultValue={this.handleCurdate()} onChange={(value) => {this.handleDateChange("start_date", value)}}   closeOnSelect={true}     dateFormat="DD MMM YYYY" timeFormat={false}  />
                    </div>

                    <div className="col-3 sms_date_picker">
                        <span style={{color:"white"}}>End Date</span><font className="text-danger"> * </font>
                        <DatePicker defaultValue={this.handleCurdate()} onChange={(value) => {this.handleDateChange("end_date", value)}}   closeOnSelect={true}     dateFormat="DD MMM YYYY" timeFormat={false}  />
                    </div> 

                    </Form.Row>

                    <Form.Row className='my-4'>
                   
                    <div className="col-3" style={{color:"white"}}>
                        <span style={{color:"white"}}> Status</span>
                            <Form.Control as="select" id = "status" onChange={this.handleChange} style={{padding:0}}>
                            {this.getSelectOptions("sms_status")}
                            </Form.Control>
                    </div>

                    <div className="col-3" style={{color:"white"}}>
                        <span style={{color:"white"}}> Purpose</span>
                            <Form.Control as= "select" id="purpose" onChange={this.handleChange} style={{padding:0}} >
                            {this.getSelectOptions("purpose",true,this.props.direction)}
                            </Form.Control>
                    </div>

                    <div className="col-3" style={{color:"white"}}>
                        <span style={{color:"white"}}>FA ID</span>
                        <Form.Control id="loan_doc_id" onChange={this.handleChange} style={{padding:0}}></Form.Control>  
                    </div>

                    <div className="col-3" style={{color:"white"}}>
                        <span style={{color:"white"}}> Mobile Number</span>
                            <Form.Control id="mobile_num" onChange={this.handleChange} style={{padding:0}}></Form.Control>
                    </div>

                    </Form.Row>

                    <Form.Row className='my-4'>
                        <div className="col-3" style={{color:"white"}}>
                            <span style={{color:"white"}}>Search Text</span>
                            <Form.Control id="search_text" onChange={this.handleChange} style={{padding:0}}></Form.Control>  
                        </div>
                    </Form.Row>
                </Form>
                
        </div>         
        )
    }
   

}

export default SMSLogs