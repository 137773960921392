import { get_path } from '../helpers/common_helper';
import {call_fetch} from './../helpers/fetch_helper';


export const getFieldVisitData = request  => {
   return call_fetch(get_path('admin') + '/field_visits/search', request);
}
export const forceCheckIn = request  => {
   return call_fetch(get_path('app') +'/task/get_force_checkin_requests',request);
}
export const submitCheckin = request  => {
   return call_fetch(get_path('admin') + '/field_visits/checkin', request);
}

export const submitCheckout = request  => {
   return call_fetch(get_path('admin') + '/field_visits/checkout', request);
}

export const checkLastVisit = request  => {
   return call_fetch(get_path('admin') + '/field_visits/validate', request);
}

export const fieldVisits = request  => {
   return call_fetch(get_path('admin') + '/field_visits/details', request);
}

export const createSchedule = request  => {
   return call_fetch(get_path('admin') + '/field_visits/reg_schedule', request);
}

export const allowForceCheckin = request  => {
   return call_fetch(get_path('admin') + '/field_visits/force_checkin', request);
}




