import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import {searchLoan} from '../../../actions/loan_actions';
import {format_date_tbl, capitalize_first_letter, title_case} from '../../../helpers/common_helper';
import {TooltipLabel} from '../../common/partial';
import {OverlayTrigger,Tooltip} from 'react-bootstrap';
import {dd_value} from '../../../actions/common_actions';
import {lbl_amt} from '../../../helpers/common_helper';
import {BiReceipt} from 'react-icons/bi';
class LastNFAs extends FlowComponent {

    state = {
        columns :[],
        loans : [],
        data :{}
    }
    componentWillMount(){
        const loan_search_criteria = {'cust_id' : this.props.cust_id, 'last_n_fas' : this.props.last_n_fas, 'mode' : 'search'};
        
        if(this.props.cust_id){
            searchLoan(this.req({loan_search_criteria}, true))
            .then((response) => {	
            
            if(!response){return };
            
            if(response.status === "success" && response.data.results.length > 0){
                //this.setState({data : response.data.results});
					this.setState({loans : response.data.results, length : response.data.results.length});
                }
            });
        }
        
    }

	
    render(){
        if(this.state.length > 0) {
            return (
                // <div>
                //     <h4>Last {this.props.last_n_fas} FAs</h4>
                //     <ReactTable  minRows = {3}  data={this.state.loans}  columns={this.state.columns} showPagination ={false} />
                // </div>
                <>
                    {this.state.loans.length > 0 &&
                        <div>
                            <div className={`${this.props.background ? "d-flex align-items-center":""}`}>
                                {this.props.background ?
                                <p className='m-0 pl-2'>
                                    <BiReceipt size="21" color={"white"}/>
                                </p>:""}
                                <h2 className={`floatAdvanceperiodViewheader no-margin ${this.props.background ? "text-white approval_request_txt px-3":""}`}>Recent FAs</h2>
                            </div>
                            <div className={`floatAdvanceperiodViewbox ${this.props.background?"apply_bg_on_last_fas":""}` }>
                                {/* <div className="row no-margin floatAdvanceperiodBox_align text-center">
                                    <div className="col-lg-3 no-padding">
                                        <div className="floatAdvanceperiodIconbox">
                                            <img className="img-fluid" src="/img/frame.png"></img>
                                            <span>#1</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 no-padding">
                                        <div className="floatAdvanceperiodIconbox">
                                            <img className="img-fluid" src="/img/frame.png"></img>
                                            <span>#2</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 no-padding">
                                        <div className="floatAdvanceperiodIconbox">
                                            <img className="img-fluid" src="/img/frame.png"></img>
                                            <span>#3</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 no-padding">
                                        <div className="floatAdvanceperiodIconbox">
                                            <img className="img-fluid" src="/img/frame.png"></img>
                                            <span>#4</span>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row no-margin">
                                    
                                    {this.state.loans.map((loan,i) => {
                                        return (<div className="col-lg-3">
                                            <div className="floatAdvanceperiodIconbox">
                                                <img className="img-fluid" src="/img/frame.png"></img>
                                                <span>#{i+1}</span>
                                            </div>
                                            <div
                                                className="floatAdvanceperiodDateiconBox floatAdvanceperiodDateiconRightborder">
                                                <div>
                                                    <img className="img-fluid" src="/img/dateicon.png"></img>
                                                    <span>{loan.disbursal_date}</span>
                                                </div>
                                            </div>
                                            <div
                                                className="floatAdvanceperiodDateiconBox floatAdvanceperiodDateiconRightborder">
                                                <div>
                                                    <img className="img-fluid" src="/img/ugxicon.png"></img>
                                                    <span>{lbl_amt(loan.loan_principal, this.currency_code)}</span>
                                                </div>
                                            </div>
                                            <div
                                                className="floatAdvanceperiodDateiconBox floatAdvanceperiodDateiconRightborder">
                                                <div>
                                                    <img className="img-fluid"
                                                        src={loan.overdue_days > 0 ? "/img/vectoricon.png" : "/img/ontimeicon.png"}></img>
                                                    <span className="text-red">{loan.overdue_days}</span>
                                                </div>
                                            </div>
                                            <div
                                                className="floatAdvanceperiodDateiconBox floatAdvanceperiodDateiconRightborder">
                                                <div>
                                                    <img className="img-fluid" src="/img/rmicon.png"></img>
                                                    <span>{title_case(loan.cs_result_code, true)}</span>
                                                </div>
                                            </div>
                                        </div>)
                                    })}
                                </div>
                            </div>
                        </div>
                    }
                </>
            )
        }
        else{
            return (
                <></>
            )
        }
    }
}

export default LastNFAs;