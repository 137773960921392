import React from 'react';
//import {updateStatus} from '../../../../actions/common_actions';
//import  {lbl_status, style_status} from '../../../../helpers/common_helper';
import FlowComponent from './core/FlowComponent';
//import {dd_value} from '../../../../actions/common_actions';
import {lbl_view_btn} from '../../../helpers/storage_helper';
import { Link } from 'react-router-dom';

class ViewEdit extends FlowComponent{
	componentWillMount(){
		
	}
	

	   render(){
	   	return(
	   		 <Link to={`/user/view/${this.props.person_id}`} className="btn btn-primary" >
				{lbl_view_btn(this.props.entity)} 
							  </Link>
			
	   		);
	   }
}

export default ViewEdit;