import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import '../../../styles/flow.css';
import FileUpload from '../../common/component/FileUpload';
import {listScoreModels} from '../../../actions/score_model_actions';
export class UploadCustomerTransaction extends FlowComponent{
	state = {
    comp_name: "customer_factor",
    cust_id_style: "show_div",
    dp_cust_id_style: "show_div",
    dp_style: "show_div"
  }
  
  componentWillMount() {
      this.setOptionsToStateFromApi("data_provider");
      const request = {country_code: this.country_code};
      listScoreModels(this.req(request))
      .then(response => this.fillSelect(response, "score_model", ["model_code", "model_name"]));
      }

  handleCustIDChange = (event) =>   {
    if(event.target.value == ""){
      this.setState({dp_cust_id_style: "show_div", cust_id_style: "show_div", dp_style: "show_div"});
    }else if(event.target.id == "cust_id"){
      this.setState({dp_cust_id_style: "disable_div", cust_id_style: "show_div", dp_style: "disable_div"});
    }else{
      this.setState({cust_id_style: "disable_div", dp_cust_id_style: "show_div", dp_style: "show_div"});
    }
    
    this.handleChange(event);
  }


render(){
      return (
   <div id="" >
       <h5>{this.props.title ? this.props.title : ""}</h5>


       <div className="row">
          <div className={`col-md-3 ${this.state.cust_id_style}`}>
              <span>Flow Customer ID</span>
              <input id="cust_id"  onChange={this.handleCustIDChange}  className="form-control" required />
            </div>
          <div className={`col-md-3 ${this.state.dp_cust_id_style}`}>
              <span>Account Number</span>
              <input id="acc_number" onChange={this.handleCustIDChange}  className="form-control" />
            </div>
              {/*<div className={`col-md-3 ${this.state.dp_style}`}>
              <span>Data Provider ( If New Customer )</span>
              <select id="data_prvdr_code" onChange={this.handleCustIDChange} className="form-control" type="text" required="required">
                  {this.state.data_provider_dd}
              </select>
            </div>*/}          
        </div>
        <br/>
        <div className="row">
          <div className="col-md-3">
            <span>Credit Score Model</span><font className="text-danger">   *  </font>
             <select id="cs_model_code" onChange={this.handleChange} className="form-control" type="text" required="required">
             {this.state.score_model_dd} 
            </select>
              </div>  
          <div className="col-md-6">
             <FileUpload id="cust_txn_file" label= "Transaction File" is_mandatory={true} mode={this.props.mode} onCreateUpload={this.handleChange}
            />
          </div>
        </div>








      {/*  <div className="row">
            <div className={`col-md-3 ${this.state.cust_id_style}`}>
              <span>Flow Customer ID</span>
              <input id="cust_id"  onChange={this.handleCustIDChange}  className="form-control" required />
            </div>
              </div><br/>
          <div className="row"> 
            <div className={`col-md-3 ${this.state.dp_cust_id_style}`}>
              <span>Data Provider Customer ID</span>
              <input id="data_prvdr_cust_id" onChange={this.handleCustIDChange}  className="form-control" />
            </div>
           </div><br/>
           <div className="row"> 
            <div className="col-md-3">
              <span>Data Provider Code</span><font className="text-danger">   *  </font>
              
              <select id="data_prvdr_code" onChange={this.handleChange} className="form-control" type="text" required="required">
                  {this.state.data_provider_dd}
              </select>
            </div>
           </div><br/>
           <div className="row">
            <div className="col-md-3">
            <span>Credit Score Modal</span><font className="text-danger">   *  </font>
             <select id="cs_model_code" onChange={this.handleChange} className="form-control" type="text" required="required">
             {this.state.score_model_dd} 
            </select>
              </div>  
          </div><br/>
          <div className="row"> 
          <div className="col-md-6">
             <FileUpload id="cust_txn_file" label= "Transaction File" is_mandatory={true} mode={this.props.mode} onCreateUpload={this.handleChange}
            />
          </div>
          </div>*/}
          
      </div>
   
);
}
}
export default UploadCustomerTransaction;
