import { get_path } from '../helpers/common_helper';
import {call_fetch} from './../helpers/fetch_helper';

export const createLoanProduct = request  => {
   return call_fetch(get_path('admin') + '/loan_product', request);
}


export const viewLoanProduct = request  => {
   return call_fetch(get_path('admin') + '/loan_product/view', request);
}

export const listLoanProductTemplate = request  => {
   return call_fetch(get_path('admin') + '/loan_product_template/list', request);
}

export const listLoanProduct = request  => {
   return call_fetch(get_path('admin') + '/loan_product/list', request);
}

export const updateLoanProduct = request  => {
   return call_fetch(get_path('admin') + '/loan_product/update', request);
}
export const getSuggestion = request  => {
   return call_fetch(get_path('admin') + '/loan_product/update', request);
}
export const getProductUpgrades = request =>{
   return call_fetch(get_path('admin') + '/loan_product/prdt_upgr_search', request);
}
export const getSalesDetails = request  => {
   return call_fetch(get_path("admin") + '/sales/search', request);
}
export const getSalesTxns = request  => {
   return call_fetch(get_path('admin') + '/sales/list_txns', request);
}
export const pendingFloatSwitch = request  => {
   return call_fetch(get_path("admin") + '/sales/search', request);
}
