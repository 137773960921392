import React from 'react';
import { CgNotes } from 'react-icons/cg';
import { dd_value } from '../../../actions/common_actions';
import FlowComponent from '../../common/component/core/FlowComponent';
import DisplayImage from '../../common/component/DisplayImage';
import { capitalize_first_letter, get_dir_path } from '../../../helpers/common_helper';
import { data } from 'jquery';
import { GoVerified } from "react-icons/go";
import { get, Config } from '../../../helpers/storage_helper';
import { Modal, Button } from 'react-bootstrap';
import Select from "react-select";
import { missingDocuments } from '../../../actions/lead_actions';

class RegistrationDocument extends FlowComponent {
  constructor() {
    super();
    this.uga_owner_address_alignment = ["parish", "sub_county", "county", "village", "district", "region", "location", "landmark"]
    this.rwa_owner_address_alignment = ["province", "district", "sector", "cell", "village", "location", "territory", "landmark"]
    this.national_id_details = ['first_name', 'last_name', 'national_id', 'gender', 'dob']

    this.national_id_details = ['first_name', 'last_name', 'id_proof_num', 'gender', 'dob']

    this.state = {
      view_modal: false,
      missing_documents:[]

    }

  }

  checkbox_spinner(){
    return(
      <div class="d-flex col-lg-12 py-2  mt-4 justify-content-center ">
      <div class="spinner-border text-light" role="status">
        <span class="sr-only">Loading...</span>
      </div>
     </div>
    )
  }

  display_image(proof_full_path, proof_path, rotate_file_name, height = null, addl_file_name = null, actual_file = null, index = null) {
    return (
      <div className=' h-100 w-100 no-padding'>

        <DisplayImage image_path={get_dir_path(proof_full_path, proof_path)} rotate={true} index={index} saved_file_name={rotate_file_name} lead_id={this.props.lead_id} style={{ height: '650px', width: '100%' }} file_name={proof_path} view_lead={() => this.props.view_lead()} actual_file={actual_file} addl_file_name={addl_file_name} />
      </div>
    )
  }
  componentWillMount() {
    this.collected_documents_lists()
  }

  collected_documents_lists() {

    var collected_documents =
      [
        { "label": "Self Declaration *", "check_box_key": "Self Declaration", "key": "self_declaration", "addl_key": "photo_self_declaration", "full_path": "photo_self_declaration_full_path" },
        { "label": "Third Party National ID (optional)", "check_box_key": "Third Party National ID", "key": "tp_national_id", "addl_key": "photo_id_proof", "full_path": "photo_id_proof_full_path" },
        { "label": "Consent Letter (optional) ", "check_box_key": "Consent Letter", "key": "consent_letter", "addl_key": "photo_consent_letter", "full_path": "photo_consent_letter_full_path" },
        { "label": "Sales Agreement (optional)", "check_box_key": "Sales Agreement ", "key": "sales_agreement", "addl_key": "photo_sales_agreement", "full_path": "photo_sales_agreement_full_path" },

      ]
    
    var collected_audit_documents;

    if (this.props.items.acc_ownership.value == "owned") {

      collected_documents.splice(1);
      
    } else if (this.props.items.acc_ownership.value == "bought") {

      collected_documents.splice(2, 1);
    }
    else if (this.props.items.acc_ownership.value == "rented") {

      collected_documents.splice(3, 1);

    }
    this.setState({ collected_audit_documents: collected_documents },()=>{this.missing_doc_options(collected_documents)})

  }

  romanize(num) {
    var lookup = { M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 }, roman = '', i;
    for (i in lookup) {
      while (num >= lookup[i]) {
        roman += i;
        num -= lookup[i];
      }
    }
    return roman;
  }

  handleClose = (event) => {

    this.setState({ view_modal: false,insufficientModal:false,isEdit:false ,select_value:[],selected_items:{}})

  }
  view_documents_modal(full_path, file_name, key) {
    this.setState({ view_modal: true, document_full_path: full_path, document_file_name: file_name, doc_key: key })
  }

  getMultiresons = (data)=>{
    var arr=[]
  
    let selectedOptions = data.map((item) => {
        arr.push(item.value)
       });
    this.setState({insufficient_documents:arr,select_value:arr})
}

missing_doc_options(docs) {

  const arr = docs.map(doc => ({ value: doc.key, label: doc.label }));
  if (this.props.items && this.props.items.biz_documents) {
    for (const key of Object.keys(this.props.items.biz_documents)) {
      arr.push({ value: key, label: dd_value(key, "biz_documents") });
    }
  }

  this.setState({ missing_documents: arr });
}

handleSubmit(data){
  var req={
    acc_prvdr : data.acc_prvdr_code.value,
    acc_num : data.acc_number.value,
    lead_id : this.props.lead_id,
    missing_documents : this.state.selected_items.insufficient_documents
  }
  missingDocuments(this.req(req))
  .then((response)=>{
    if(!response){return}
    if(response.status=="success"){
      this.resp(response)
      this.props.view_lead()
      this.setState({selected_items:'',insufficientModal:false})
    }
  })

}

handlePurposeChange = (selectedOptions, category) => {
  this.setState({
    select_value: selectedOptions
  });

  this.setState(prevState => ({
    selected_items: {
      ...prevState.selected_items,
      [category]: selectedOptions.map(option => option.value),
      ['data_label'] :selectedOptions.map(option => option.label)
    }
  }));
};

updateFilteredOptions = (inputValue) => {
  const { originalOptions, select_value } = this.state;
  if (inputValue.trim().length > 0) {
      this.setState({ filteredOptions: originalOptions.filter(option => option.label.toLowerCase().includes(inputValue.toLowerCase())) });
  }
};

 editMissingDocuments(datas) {

  var arr = [];
  if (datas.missing_documents && datas.missing_documents.length > 0) {
    for (var i = 0; i < datas.missing_documents.length; i++) {
      var obj = {};
      obj['label'] = dd_value(datas.missing_documents[i], 'biz_documents');
      obj['value'] = datas.missing_documents[i];
      arr.push(obj);
    }
  }
  this.setState({ insufficientModal: true, isEdit: true, select_value: arr });
}


  render() {

    const { select_value, filteredOptions } = this.state;

    return (
      <div className={this.state.insufficientModal ? "disable_div":''}>

     {this.props.account_ownership_datas &&
      <div>
        <Modal show={this.state.view_modal} size='lg' onHide={this.handleClose}>
          <Modal.Header className='py-2' closeButton show={this.state.view_modal} onHide={this.handleClose}>
            <Modal.Title className='pt-3'>  {dd_value(this.state.doc_key)}  </Modal.Title><br />
          </Modal.Header>

          <Modal.Body className='pt-1'>
            <div className="container text-labelblue" style={{ border: '1px solid gray' }}>
              <div className='col-md-12 mt-2 px-0'>
                {(this.state.doc_key == "self_declaration") ? 
                <div style={{height:'560px'}}>
                  <object id="pdf-object" type='application/pdf' className='bg-secondary' data={`${this.props.root}${this.props.items.self_declaration.photo_self_declaration_full_path.value}?#toolbar=0&navpanes=0&page=1`}  width={'100%'} height={'100%'} allowtransparency='true'>  
                  <p className='text-center'>NO DATA</p>
                 </object> 
                 <div className='d-flex justify-content-end'>
                 <a href={`${this.props.root}${this.props.items.self_declaration.photo_self_declaration_full_path.value}`} className='btn btn-success btn-sm my-0 mx-3' target="_blank">Download Pdf</a>
                 </div>
                 </div>
                : 
                <DisplayImage image_path={get_dir_path(this.state.document_full_path, this.state.document_file_name)} rotate={true} file_of={this.state.doc_key} lead_id={this.props.lead_id} style={{ height: '550px', width: '100%' }} file_name={this.state.document_file_name} view_lead={() => this.props.view_lead()} actual_file={"photo_" + this.state.doc_key} index={this.props.document_index} />
                }
              </div>
              {this.props.account_ownership_datas &&
              <div className='col-lg-12 py-2  pl-3 px-0 mt-4'>

                <form className={`${(this.props.account_ownership_datas.active_section.verify || (this.props.account_ownership_datas.active_section.to_reassign == true)) ? "disable_div" : ''}  ${((this.props.account_ownership_datas.responseData.audited_by == get("user_id")) || (this.props.account_ownership_datas.responseData.audited_by == null)) ? "" : 'disable_div'}`}>
                  <div className='d-flex align-items-center row m-0 col-12'>
                    <input type="checkbox" id={`${this.state.doc_key}_clear_and_readable_${this.props.document_index + 1}`} className='pointer col-1' checked={this.state[`${this.state.doc_key}_clear_and_readable_${this.props.document_index + 1}`] ? this.state[`${this.state.doc_key}_clear_and_readable_${this.props.document_index + 1}`] : this.props.account_ownership_datas.active_section.flags[`${this.state.doc_key}_clear_and_readable_${this.props.document_index + 1}`]} style={{ height: '19px', width: '20px' }} onChange={(e) => { this.setState({ [`${this.state.doc_key}_clear_and_readable_${this.props.document_index + 1}`]: e.target.checked }); this.props.checkbox_verification(e.target.checked, `${this.state.doc_key}_clear_and_readable_${this.props.document_index + 1}`) }} />
                    <label className='no-margin pl-3 audit_kyc_text pointer col-11' for={`${this.state.doc_key}_clear_and_readable_${this.props.document_index + 1}`}>
                      {(this.state.doc_key == "self_declaration") ? "Form is clear & readable" : "Document is clear & readable"}
                    </label><br /><br /><br />
                  </div>

                </form>

                <form className={`${(this.props.account_ownership_datas.active_section.verify || (this.props.account_ownership_datas.active_section.to_reassign == true)) ? "disable_div" : ''}  ${((this.props.account_ownership_datas.responseData.audited_by == get("user_id")) || (this.props.account_ownership_datas.responseData.audited_by == null)) ? "" : 'disable_div'}`}>
                  <div className='d-flex align-items-center m-0 row col-12'>
                    <input type="checkbox" id={`${this.state.doc_key}_authentic_${this.props.document_index + 1}`} className='pointer col-1' style={{ height: '19px', width: '20px' }} checked={this.state[`${this.state.doc_key}_authentic_${this.props.document_index + 1}`] ? this.state[`${this.state.doc_key}_authentic_${this.props.document_index + 1}`] : this.props.account_ownership_datas.active_section.flags[`${this.state.doc_key}_authentic_${this.props.document_index + 1}`]} name="vehicle6" onChange={(e) => { this.setState({ [`${this.state.doc_key}_authentic_${this.props.document_index + 1}`]: e.target.checked }); this.props.checkbox_verification(e.target.checked, `${this.state.doc_key}_authentic_${this.props.document_index + 1}`) }} />
                    <label className='no-margin pl-3 audit_kyc_text pointer col-11' for={`${this.state.doc_key}_authentic_${this.props.document_index + 1}`}>
                      {(this.state.doc_key == "self_declaration") ? "Form is authentic and verifies the customer’s usage of the account mentioned" : (this.state.doc_key == "consent_letter") ? "Document is authentic and account ownership is rightfully rented" : "Document is authentic and account ownership is rightfully transferred"}

                    </label><br /><br /><br />
                  </div>

                </form>


              </div>
              }
            </div>
          </Modal.Body>


        </Modal>
        <div className={this.state.view_modal ? "disable_div" : ""}>
        {!this.props.acc_ownership_box && !this.props.hasOwnProperty("acc_ownership_box")  &&

          <div className='d-flex justify-content-center mb-4'>
            <div className='col-lg-6   align-items-center py-4 px-3 audit_assessment_box' >
              <div className=" row m-0 col-12 d-flex justify-content-center align-items-center">

                <h2 className='text-white col-5 m-0 loggedPersondetails'>Account Ownership</h2>
                <h2 className='text-white m-0 col-5 pr-0 audit_kyc_text'> <b>{this.props.items.acc_ownership.value ? <span> {dd_value(this.props.items.acc_ownership.value, "acc_ownership_rm_app")}</span> : "-"}</b></h2>
              </div>

              <div className=" row m-0 col-12 d-flex pt-4  justify-content-center align-items-center">

                <h2 className='text-white col-5 m-0 loggedPersondetails'>A/C is in the name of</h2>
                <h2 className='text-white m-0 col-5  pr-0 audit_kyc_text'> <b>{this.props.items.biz_type.value ? <b> {dd_value(this.props.items.biz_type.value)}</b> : "-"}</b></h2>
              </div>


            </div>



          </div>
          }
          <div className='col-lg-12 py-3 registration_documents ' >
            <div className='row d-flex align-items-center col-12'>

              <div className='col-8'> <h2 className='text-white  ml-5 col-10'> <b>Documents</b></h2></div>
              <div className='col-2'></div>
              <h2 className='text-white  text-center col-2'><b>Verified</b> </h2>

            </div>
            {this.state.collected_audit_documents && this.state.collected_audit_documents.map((item, idx) => {
              return (
                <div className='row mx-0 col-12 py-3 mb-3' style={{ background: `${(this.props.items.hasOwnProperty(item.key) && (this.props.items[item.key][item.addl_key].value != null)) ? "#1b6186" : '#d9d9d98f'}` }}>
                  <h2 className='text-white pr-0 m-0 col-1 text-center'>{this.romanize(idx + 1)}</h2>
                  <h2 className='text-white pl-0  m-0  col-9'>{item.label} </h2>
                  <p className='col-2 m-0 text-center d-flex align-items-center justify-content-center'> {(this.props.items.hasOwnProperty(item.key) && (this.props.items[item.key][item.addl_key].value != null)) ?
                    <>
                      {this.props.account_ownership_datas.active_section.flags[`${item.key}_authentic_${this.props.document_index + 1}`] && this.props.account_ownership_datas.active_section.flags[`${item.key}_clear_and_readable_${this.props.document_index + 1}`] ?
                        <p className='text-center pointer '><GoVerified size={25} color={"#5aff5a"} onClick={() => { this.view_documents_modal(this.props.items[item.key][item.full_path].value, this.props.items[item.key][item.addl_key].value, item.key) }} /></p>
                        :
                        <>
                        {item.key!=="tp_national_id" &&
                        <p className='m-0 view_doc_link pointer pl-2' onClick={() => { this.view_documents_modal(this.props.items[item.key][item.full_path].value, this.props.items[item.key][item.addl_key].value, item.key) }}>View Document</p>
                        }</>
                      }

                    </> : <p className="text-white m-0">-</p>} </p>
                </div>
              )
            })}

            <>
              {this.props.items && this.props.items.hasOwnProperty("biz_documents") &&
                <div className='row d-flex align-items-center col-12'>

                  <div className='col-8 '> <h2 className='text-white ml-5 col-10'> <b> Business Documents</b></h2></div>
                </div>
              }
              {this.props.items && this.props.items.hasOwnProperty("biz_documents") && Object.entries(this.props.items.biz_documents).map(([key, values], index) => {
             
                return (
                <>
                  <div className='row mx-0 col-12 py-3 mb-3 align-items-center' style={{ background: `${(this.props.items.biz_documents.hasOwnProperty(key) && (this.props.items.biz_documents[key]['photo_' + key + '_full_path'].value)) ? '#1b6186' : "#d9d9d98f"}` }}>
                    <h2 className='text-white pr-0 m-0 col-1 text-center'>{this.romanize(index + 1)}</h2>
                    <h2 className='text-white pl-0  m-0  col-9'>{dd_value(key, "biz_documents")}<span className='pl-1'>(optional)</span> </h2>

                    {Object.entries(values).map(([inside_key, inside_value], idx) => {

                      const backKey = `${inside_key}_back`;
                      const hasBack = values.hasOwnProperty(backKey);
                      const doc_key = inside_key.includes('back') ? `${key}_back` : key
                      
                      return (
                        <>
                        
                          {inside_key.substr(-9) != "full_path" && ( 

                          <div className={`${hasBack || inside_key.includes('back') ? "col-12" :"col-2"}`}>
                            {(hasBack || inside_key.includes('back')) ? (
                                <div className='row pl-5 mt-2'>
                                    <div className='col-10 align-items-center justify-content-between px-3'>
                                      <p className='text-white' style={{fontSize:'14px'}}>{hasBack ? dd_value(key, "biz_documents") + ' Front Side Photo' : dd_value(key, "biz_documents") + " Back Side photo"}</p>
                                    </div>  
                                    {inside_value.value != null ?
                                    <div className='col-2'>
                                        {this.props.account_ownership_datas.active_section.flags[`${doc_key}_authentic_${this.props.document_index + 1}`] && this.props.account_ownership_datas.active_section.flags[`${doc_key}_clear_and_readable_${this.props.document_index + 1}`] ?
                                            <p className='text-center pointer '>
                                                <GoVerified size={25} color={"#5aff5a"} onClick={() => { this.view_documents_modal(values[inside_key + '_full_path'].value, inside_value.value,  doc_key ) }} />
                                            </p>
                                            :
                                            <p className='m-0 view_doc_link pointer' onClick={() => { this.view_documents_modal(values[inside_key + '_full_path'].value, inside_value.value, doc_key) }}>View Document</p>
                                        }
                                    </div>
                                    : 
                                    <div>
                                      <p className="text-white m-0">-</p>
                                    </div>
                                    }
                                </div>
                                
                            ) : (
                                <p className='col-12 m-0 text-center'>
                                    {inside_value.value != null ?
                                        <>
                                            {this.props.account_ownership_datas.active_section.flags[`${key}_authentic_${this.props.document_index + 1}`] && this.props.account_ownership_datas.active_section.flags[`${key}_clear_and_readable_${this.props.document_index + 1}`] ?
                                                <p className='text-center pointer '>
                                                    <GoVerified size={25} color={"#5aff5a"} onClick={() => { this.view_documents_modal(values['photo_' + key + '_full_path'].value, values['photo_' + key].value, key) }} />
                                                </p>
                                                :
                                                <p className='m-0 view_doc_link pointer' onClick={() => { this.view_documents_modal(values['photo_' + key + '_full_path'].value, values['photo_' + key].value, key) }}>View Document</p>
                                            }
                                        </>
                                        : <p className="text-white m-0">-</p>}
                                </p>
                            )}
                          </div>
                          )}
                        </>
                      )
                    })}


                  </div>
                </>
                )

              })


              }
            </>


          </div>



          <div className='col-lg-12  '>
            {this.props.items.hasOwnProperty("tp_national_id") && this.props.items.tp_national_id.photo_id_proof.value != null &&
              <div className='col-12'>

                <div className=' col-lg-12 py-3 my-4 ' style={{ background: '#4a4c51' }}>
                  <h2 className='text-white text-center'><b>Collected Documents</b></h2>

                </div>

                <h2 className='text-white m-0 text-center pb-2'>Third Party National ID</h2>

                <div className='d-flex  row justify-content-evenly'>
                  <div className='col-6'>
                    <DisplayImage image_path={get_dir_path(this.props.items.tp_national_id.photo_id_proof_full_path.value, this.props.items.tp_national_id.photo_id_proof.value)} rotate={true} third_party={true} index={this.props.document_index} file_of={"national_id"} lead_id={this.props.lead_id} style={{ height: '700px', width: '100%' }} file_name={this.props.items.tp_national_id.photo_id_proof.value} view_lead={() => this.props.view_lead()} actual_file={"photo_id_proof"} addl_file_name={"tp_national_id"} />
                  </div>

                  <div className='col-md-12 col-lg-6 px-0 text-light border ' >
                    <h2 className='px-0 py-2 pl-4 text-white' style={{ background: '#0086B0' }}> <b>RETRIEVED DETAILS</b></h2>

                    <div className=' py-4' >

                      {this.national_id_details.map((national_id_data, idx) => {
                        return (
                          <div className={`align-items-center col-12  mb-3 my-4 `}>
                            <div className='row no-margin py-2'>
                              <p className='no-margin text-white business_operations_text col-4'>  {dd_value(national_id_data.toUpperCase())}   </p>
                              {
                                <h2 className='text-white audit_kyc_text col-8 '> <b>{this.props.items.tp_national_id[national_id_data] ? this.props.items.tp_national_id[national_id_data].value : "-"}</b></h2>
                              }
                            </div>
                          </div>
                        )
                      })}


                      <br />

                    </div>







                  </div>
                </div>
              </div>
            }

          
          </div>
          <div className='col-lg-12 mt-3 '>
            {this.props.account_ownership_datas.doc_loder ? 
                    this.checkbox_spinner()
                        :
            <div className={` my-5 pointer mb-5 col-12  d-flex justify-content-center align-items-center ${(this.props.account_ownership_datas.active_section.verify || (this.props.account_ownership_datas.active_section.to_reassign == true)) ? "disable_div" : ''}   ${((this.props.account_ownership_datas.responseData.audited_by == get("user_id")) || (this.props.account_ownership_datas.responseData.audited_by == null)) ? "" : 'disable_div'}`}>
              <label className='audit_kyc_text col-md-6  m-0 ' id={`documents_${this.props.document_index + 1}`} > Does the account has sufficient documents ? <b style={{color:'red'}}>*</b></label>
              <div className='d-flex align-items-center'>
                
                <input type="radio" className="pl-2 pointer" id={`sufficient_document_yes_${this.props.document_index + 1}`} name={`sufficient_document_yes_${this.props.document_index + 1}`} checked={(this.props.account_ownership_datas.cust_reg_json.biz_accounts.accounts[this.props.document_index].document_status === "sufficient_documents")?true:false} style={{ height: '21px', width: '23px' }} onClick={(e) => { this.props.update_mismatch_reason(this.props.document_index, "document_status", "sufficient_documents", true) }} />

                <label className='m-0 pl-1 audit_kyc_text pointer' for={`sufficient_document_yes_${this.props.document_index + 1}`} name={`sufficient_document_yes_${this.props.document_index + 1}`} style={{ fontSize: '17px' }}>Yes</label>
              </div>

              <div className='d-flex align-items-center pl-2'>
                <input type="radio" className="pl-2 pointer" id={`sufficient_document_no_${this.props.document_index + 1}`} name={`sufficient_document_yes_${this.props.document_index + 1}`} 
                checked={
                  ((this.props.account_ownership_datas.cust_reg_json.biz_accounts.accounts[this.props.document_index].missing_documents) && (this.props.account_ownership_datas.cust_reg_json.biz_accounts.accounts[this.props.document_index].missing_documents.length>0)  )
                }
                defaultChecked={(this.props.account_ownership_datas.cust_reg_json.biz_accounts.accounts[this.props.document_index].missing_documents)}
                  
                  onClick={(e) => {this.props.update_mismatch_reason(this.props.document_index, "document_status", "insufficient_documents", false);this.setState({insufficientModal:true}) }} style={{ height: '21px', width: '23px' }}/>

                <label className='m-0 pl-1 audit_kyc_text pointer' for={`sufficient_document_no_${this.props.document_index + 1}`} name={`sufficient_document_yes_${this.props.document_index + 1}`} style={{ fontSize: '17px' }}>No</label>
              </div>



            </div>
             } 
             {this.state.insufficientModal &&
                    
                    <Modal
                    show={this.state.insufficientModal} onHide={this.handleClose}
                   
                    aria-labelledby="contained-modal-title-vcenter"
                    
                    centered
                  >
                    <Modal.Header className='py-3' closeButton style={{background:'#1d243b'}}>
                      <Modal.Title id="contained-modal-title-vcenter">
                     <b> Missing Document </b>              
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p className='text-tenter m-0 text-white sizem px-4' >Which document is missing from this customer?</p>
                      <div className='my-3 row mx-0 '>
                        <div className='col-12 '>
                        <Select
                onChange={(selectedOptions) => this.handlePurposeChange(selectedOptions, "insufficient_documents")}
                value={select_value}
                options={this.state.missing_documents}
                styles={this.select_custom_styles()}
                classNamePrefix="my-select"
                isClearable={true}
                hideSelectedOptions={false}
                isMulti
                onInputChange={(inputValue) => {
                    if (inputValue.trim().length > 0) {
                        this.setState({ showPlaceholder: false });
                    } else {
                        this.setState({ showPlaceholder: true });
                    }
                    this.updateFilteredOptions(inputValue);
                }}
            />
                      {/* <Select onChange={(e) =>{this.getMultiresons(e)}}
                          value = {this.state.select_value}
                          options= {this.state.missing_documents }
                          maxMenuHeight={120}
                          placeholder={"Reason"}
                          styles={this.select_custom_styles()}
                          classNamePrefix="my-select" 
                          isMulti/> */}
                          </div>
                      </div>

                      <div className={`d-flex align-items-center justify-content-center mt-5 `}>
                         <button type="button" class="btn btn-primary" disabled={ this.state.calling_api ?true: (this.state.selected_items&&this.state.selected_items.insufficient_documents ) ?false:true} onClick={()=>{this.handleSubmit(this.props.items)}}>submit</button>

                      </div>
                    </Modal.Body>
                   
                  </Modal>
              }

            {(this.props.items.missing_documents && this.props.items.missing_documents.length>0)  &&
            <div className='col-lg-12 py-3 registration_documents  mb-4' >
            <div className='row d-flex align-items-center col-12'>
              <div className='col-8'> <h2 className='text-white  ml-3 col-10'> <b>Missing Documents</b></h2></div>
            </div>
            <div className=' d-flex align-items-center col-12 mx-0' style={{background:'rgb(27, 97, 134)'}}>

            <div className='col-8'> <p className='text-white my-0 agreementDateHeaderBoxdate py-4 col-10'> <b>
              {this.props.items.missing_documents.map((items,idx)=>{return(
                <span>{dd_value(items,"biz_documents")} {idx == this.props.items.missing_documents.length-1 ? "":" | " }</span>
              )})}
              </b></p></div>
            <div className='col-2'></div>
            
            <button type="button" class="btn btn-info  px-4 py-3 mb-0" onClick={()=>{this.editMissingDocuments(this.props.items)}}> <b>edit</b></button>
            </div>
          </div>
          }

            <div className='col-lg-12 p-3 mt-2 d-flex align-items-center' style={{ background: '#fe9f9f' }}>
              <p className='no-margin  pl-3' style={{ color: 'black', fontSize: '15px' }}> <b style={{ color: 'red' }}>Note : </b>Choosing “No” will help the RM and Audit team to followup with the customer to collect the required documents later. Also this will add the profile to auditor’s watchlist.</p>
            </div>
          </div>

        </div>
      </div>
     }
    </div>

    )
  }
}

export default RegistrationDocument;