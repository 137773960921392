import FlowComponent from '../common/component/core/FlowComponent';
import React from 'react';
import {Link} from "react-router-dom";
import {Button} from 'react-bootstrap';
import {listWriteOff} from '../../actions/write_off_action';
import {dd_value} from "../../actions/common_actions";
import {lbl_amt} from '../../helpers/common_helper';
import $ from 'jquery';
import CommonReactTable from '../../helpers/commonReact_table';
import { sortAmtcommon,sortDatecommon } from '../../helpers/sortingHelpers';
import Accordion from 'react-bootstrap/Accordion';
import {Card} from 'react-bootstrap'
import {BsChevronDown} from 'react-icons/bs';
import { BasicReactTable } from '../../helpers/react_table';

class WriteOffList extends FlowComponent {
    state = {
		columns :[],
		list : [],
        show_table: false
	}

    handleToggle = (e) => {

        switch(e){
            case("Account"):
                this.setState(state => state.show_table = !this.state.show_table);
                break;
        }
    }

    componentWillMount(){
        const write_off = {write_off_status : "requested"};
        listWriteOff(this.req(write_off, true))
        .then((response) => {
            if(!response){return};
            if(response.status === "success"){

                let columns 
                columns = [
                    {
                        Header : "Acc Prvdr Code",
                        id: 'acc_prvdr_code', 
                        sortable: false,
                        accessor: 'acc_prvdr_code' ,
                    },
                    {
                        Header : "FA ID",
                        sortable: false,
                        accessor: 'loan_doc_id' ,
                        Cell: e =>  <Link to={"/fa/view/"+e.value} target="_blank" >{e.value}</Link>,
                    
                    },
                    {
                        Header : "Account Number",
                        sortable: false,
                        accessor: 'acc_number' ,
                        Cell: e =>  <Link to={"/borrower/indiv/view/"+e.value} target="_blank" >{e.value}</Link>,
                    
                    },
                    {
                        Header : "Write-Off Status",
                        sortable: false,
                        id: 'write_off_status', 
                        accessor: 'write_off_status',
                    },
                    {
                        Header : "Write-Off Amount",
                        id: 'write_off_amount', 
                        sortMethod: (a,b) => this.sortAmt(a,b),
                        accessor:row =><div className='tableAmount_rightAlign'>{lbl_amt(row.write_off_amount,this.currency_code)}</div>,              // format_date_tbl(row.lead_date),
                    },
                    
                    {
                        Header : "Requested By",
                        sortable: false,
                        id: 'req_name', 
                        accessor: 'req_name' ,
                    },
                    {
                        Header : "Requested Date",                        
                        id: 'req_date', 
                        accessor: 'req_date' ,
                    }
                
                ]

                this.setState({columns,lists:response.data})
                }
        });

    }

    sortAmt(a,b){          
        const curr_Amt = a.props.children
        const prev_Amt = b.props.children     
        return sortAmtcommon(curr_Amt,prev_Amt)      
    }
    sortDate (a,b) {	        	
		const current_Date = a.props.children[1]
		const prev_Date = b.props.children[1]		
		return sortDatecommon(current_Date,prev_Date)        
	}
    render(){
        return (
                <Accordion>
                <Card>	
                    <div className="card-header card-header-icon card-header-primary home_header_d-flex_at_response justify-space-between">
                        <h4 className="card-title text-white d-inline-block title-bottom" style={{padding:'15px 0px'}}>Write-Off Pending Approval</h4>
                        <Accordion.Toggle className = "col" as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("Account")}>
                            <BsChevronDown className = {this.state.show_table ? "arrow-icon down accordin-arrow " : "arrow-icon accordin-arrow"} size="18"/>
                        </Accordion.Toggle>
                    </div>
                    <div className="card-body no-padding">
                        <Accordion.Collapse eventKey="0">
							<Card.Body>      

                                
                                     <BasicReactTable  row_data={this.state.list !== undefined ? this.state.list : []} column_data={this.state.columns} pagination={true}    default_page_size={50}   defaultSorted={[{id:"req_date",desc:true}]}  /> 
                  
                                {/* <CommonReactTable showPag={false} defaultPageSize={50} minRow={0} row_data={this.state.lists !== undefined ? this.state.lists : []} column_data={this.state.columns} resizable={false} defaultSorted={[{id:"req_date",desc:true}]}/> */}
                            </Card.Body>
						</Accordion.Collapse>
                    </div>
                    </Card>
            </Accordion>
                
        );

}


}
export default WriteOffList;