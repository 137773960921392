import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import moment from 'moment';
import DatePicker from 'react-datetime';
import { BiCalendar, BiCheckCircle, BiGlobe } from "react-icons/bi";
import { BasicReactTable } from '../../../helpers/react_table'
import Select from "react-select";
import { getTollfreeSuccessReport } from '../../../actions/report_actions';
import { getAgentNumber } from '../../../actions/call_log_actions';
import { title_case } from '../../../helpers/common_helper';



class CallLogReports extends FlowComponent {

    constructor(props) {
        super(props);
        this.state = {
           callSuccessRating:[
            {label:'5 mins',value:"5" },
            {label:'10 mins',value:"10" },
            {label:'15 mins',value:"15" },
            {label:'1 hour',value:"60" },
            {label:'3 hours',value:"180" },
            {label:'6 hours',value:"360"},
            {label:'12 hours',value:"720" },
        
        ],
       cal_log_reports:[]
        };
       
    }

    componentWillMount() {
       this.getAgentSelectOptions()
    }

    componentDidUpdate(prevState,prevProps){
     
    }

    getSelectValue(options,type){
        this.setState({[type]:options.value})
    }

    reset_all_filters(){
         this.setState({
             from_date:"",
             to_date:'',
             duration:"",
             numbers:"",
         })
    }

    

    getAgentSelectOptions(){

        getAgentNumber(this.req())
    .then((response) => {
        if (!response) {
            return;
        }
        if (response.status === "success") {
            this.setState({ multiselectOptions: response.data }, () => {

                var arr = [];
                arr.push({ label: 'All', value: 'all' });
                for (var i = 0; i < this.state.multiselectOptions.length; i++) {
                    var obj = {
                        label: this.state.multiselectOptions[i],
                        value: this.state.multiselectOptions[i]
                    };
                    arr.push(obj);
                }
                this.setState({ agentNumbers: arr });
            });
        }
    })
    }

    get_input_handler(value,type){

        this.setState({[type]:value})
    }

    SuccessReport(req){

        if(!this.state.numbers){
            alert("please select the Agent Number")
        
        }else if(!this.state.from_date){

            alert("please select the start date ")

        }else if(!this.state.to_date){
            alert("please select the end date ")
        }
        else{

            req = {'number' : this.state.numbers, 'start_date' : this.state.from_date, 'end_date' : this.state.to_date }
        getTollfreeSuccessReport(this.req(req))
        .then((response)=>{
            if(!response){return}
            if(response.status=="success"){
                this.setState({cal_log_reports:response.data,column_data:this.column_data(),no_data:true,loader:false})
            }
        })

        }

        

    }
 
 
    column_data = () => {
        return [
            
      
           {
              Header : <div >Phone Number</div>,
              id: "number",
              accessor: "number",   
              Cell : cell => {return <>{cell.row.original.number}</>},
              width: 250,    
          },
          {
            Header : <div >Device </div>,
            id: "type",
            accessor: "type",   
            Cell : cell => {return <>{title_case(cell.row.original.type)}</>}     
        },
          {
            
            Header : <div className='text-center'>Total Calls</div>,
            id: "total_calls",
            accessor: "total_calls",   
            Cell : cell =>{return <div className='text-center'>{cell.row.original.total_calls}</div>}
            
          },
          {
            Header :<div className='text-center'>Success Calls</div> ,
            id: "success_calls",
            accessor: "success_calls",   
            Cell : cell => {return <> <p className=' m-0 text-center' >{cell.row.original.success_calls}</p>  </>}    
            
          },
          {
            Header :<div className='text-center'>Hangedup Calls</div> ,
            id: "hangedup_calls",
            accessor: "hangedup_calls",   
            Cell : cell => {return <> <p className=' m-0 text-center' >{cell.row.original.hangedup_calls}</p>  </>}    
            
          },{
            Header :<div className='text-center'>Percentage</div> ,
            id: "percentage",
            accessor: "percentage",   
            Cell : cell => {return <> <p className=' m-0 text-center' >{cell.row.original.percentage}%</p>  </>}    
            
          },
         
  ]
      
    }
   
   
    render() {
        return (
            <div>
              
                <h2 className='text-white mb-4 ml-4 mt-5' > Toll Free Success Rate Report</h2>
                <div className={``}>
                <div className='my-3 mt-2  px-2 churn_report_card'>
                    <div className='row m-0 d-flex align-items-center col-12  justify-content-between'>
                        <div className='col-md-12 col-lg-12 col-sm-12 p-0'>
                            <div className=' py-4  my-3'>
                              

                                <div className=' row col-12 mx-0 mt-2 p-0'>
                                    <div className='col-md-12  col-lg-3 pl-0 d-flex align-items-center row m-0'>
                                        <p className='text-white m-0 col-lg-4 text-center pr-0 col-sm-6'>Start Date <span className="text-danger"> * </span></p>
                                        <div className="form-group mt-0 col-lg-8 col-md-8 col-sm-6 px-2 py-0 d-flex align-items-center  churn_report_date_picker" >

                                            <label className='col-1.5 no-margin d-flex p-0 ' style={{ position: 'static' }} htmlFor='churn_report_start_date'>
                                                <p className='m-0 pr-1'><BiCalendar size={20} /></p>
                                                <div className="churn_report_vl pr-2" > </div>
                                            </label>

                                            <div className='col  p-0 py-1 pr-0'>
                                               
                                            <DatePicker closeOnSelect={true} 
                                                isValidDate={(current) => {
                                                const today = moment().startOf('day');
                                                return current.isSameOrBefore(today, 'day');  }} 
                                                value={ this.state.from_date ?  moment(this.state.from_date).format('DD MMM YYYY'): moment().format('MMMM YYYY')}  onChange={(value)=>this.get_input_handler(  moment(value).format("YYYY-MM-DD"),"from_date")}  inputProps={{ id: 'start_date',placeholder: 'Start date', name: 'Referral_from',autoComplete:'off'}} dateFormat= {"DD MM YYYY" }
                                                
                                                
                                                showMonthYearPicker timeFormat={false} required="required" onKeyDown={(value) => value.preventDefault()}/>

                                            </div>
                                        </div>
                                    </div>

                                    <div className= {`col-md-12 pl-0 col-lg-3  d-flex align-items-center  m-0 ${this.state.from_date ? "":"disable_div"}`}>
                                    <p className='text-white m-0 col-lg-4 text-center pr-0 col-sm-6'>End Date <span className="text-danger"> * </span></p>
                                        <div className="form-group mt-0 col-lg-8 col-md-8 col-sm-6 px-2  py-0 d-flex align-items-center  churn_report_date_picker" >

                                            <label className='col-1.5 no-margin d-flex p-0 ' style={{ position: 'static' }} htmlFor='report_end_date'>
                                                <p className='m-0 pr-1'><BiCalendar size={20} /></p>
                                                <div className="churn_report_vl pr-2" > </div>
                                            </label>

                                            <div className='col  p-0 py-1 pr-0'>
                                            <DatePicker closeOnSelect={true} 
                                                isValidDate={(current) => {
                                                const today = moment().startOf('day');
                                                return current.isSameOrBefore(today, 'day');  }} 
                                                value={ this.state.to_date ?  moment(this.state.to_date).format('DD MMM YYYY'): moment().format('MMMM YYYY')}  onChange={(value)=>this.get_input_handler(  moment(value).format("YYYY-MM-DD"),"to_date")}  inputProps={{ id: 'end_date',placeholder: 'End date', name: 'Referral_from',autoComplete:'off'}} dateFormat= { "DD MM YYYY" }
                                                
                                                
                                                showMonthYearPicker timeFormat={false} required="required" onKeyDown={(value) => value.preventDefault()}/>
                                               
                                            </div>
                                        </div>
                                    </div>
                                
                                    

                                    <div className='col-md-12 col-lg-3 col-sm-12 col-sm-12 '>
                                   
                                    <div className="call_log_select">
                                        <Select onChange={(e) =>{this.getSelectValue(e,"numbers");}}
                                                options={this.state.agentNumbers ? this.state.agentNumbers:""} 
                                                placeholder="Agent Numbers"
                                                styles={this.select_custom_styles()}
                                                classNamePrefix="my-select" // Add a custom class prefix to target the components
                                                />

                                          
                                        </div>

                                    </div>

                                    <div className='col-md-12 col-lg-3 col-sm-12 col-sm-12 ' >
                                   
                                   <div className={`call_log_select ${(moment().format('DD-MM-YYYY') === (this.state.from_date ? moment(this.state.from_date).format('DD-MM-YYYY'):(moment().format('DD-MM-YYYY')))) ?"" : "disable_div"}`} >
                                       <Select onChange={(e) =>{this.getSelectValue(e,"duration");}}
                                               options={this.state.callSuccessRating ? this.state.callSuccessRating:""} 
                                               placeholder="Call Success Rating for"
                                               styles={this.select_custom_styles()}
                                               classNamePrefix="my-select" // Add a custom class prefix to target the components
                                               />

                              
                                       </div>

                                   </div>
                          

                                   
                                </div>

                            </div>
                        </div>
                       

                       
                        
                        

                    </div>

                    <div className=' my-3  d-flex align-items-center justify-content-center'>
                  
                    
                    <button type="button" class="btn btn-primary mb-3" style={{borderRadius:'10px'}} disabled={(this.state.from_date || (this.state.to_date) || (this.state.duration )) ? false:true} onClick={()=>{this.SuccessReport()}}>search</button>

                     {/* <button type="button" class="btn btn-secondary ml-3" style={{borderRadius:'10px'}} disabled={(this.state.from_date || (this.state.to_date) || (this.state.numbers || this.state.duration )) ? false:true} onClick={()=>{this.reset_all_filters()}}>reset</button> */}

                

                    </div>
                </div>
                
                {this.state.loader ? 
                <div class="d-flex justify-content-center mt-5">
                <div class="spinner-border text-info" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                </div>
               :
                <div className='mt-3'>


                    {this.state.cal_log_reports && this.state.cal_log_reports.length > 0 ?
                        <div className={`cust_next_follow_up mt-5  }`} >
                            <div className='d-flex justify-content-between align-items-center' style={{padding:5}}>
									<p className='no-margin text-white'><b>Total Entries : {this.state.cal_log_reports.length}</b></p>
								</div>

                                <BasicReactTable row_data={this.state.cal_log_reports} column_data={this.state.column_data}     csv_file={true} FName="Call Log Reports" pagination={true}  excess_time_action={false} default_page_size={10} loader={this.state.cal_log_reports ? false : true}/> 


                        </div>
                        :
                        <>
                         {this.state.no_data&&<h6 className='text-center text-light p-5 m-5'>No Data Found</h6>}

                        </>
                    }

                </div>
                }
                </div>


            </div>

        )
    }

}




export default CallLogReports;