import React,{useState,useEffect} from "react"
import { dd_value } from "../actions/common_actions"

export default function AccPurposeMultiSelect ({id,acc_purposes, initialAct_purpose, must, onChange}) {      

    const [accPur,setaccPur] = useState([])    

    useEffect(() => {
        if(initialAct_purpose){       
            setaccPur(initialAct_purpose)
        }
    },[initialAct_purpose])    

    const [dropShow, setdropShow] = useState(false)       

    const clickHandle = () => {        
        setdropShow(!dropShow)
    }                  

    const onChangehandler = (key,val) => {                                                 
        if(key){            
            if(val !== accPur[0]){
                accPur.push(val)
                setaccPur([...accPur])               
            }                        
        }else if(!key){
            var index = accPur.indexOf(val)                    
            accPur.splice(index,1)
            setaccPur([...accPur])           
        }      
        let event = {target: {id: id, value: accPur}}
        onChange(event)                 
    }    
   
    return(        
        <div className={`accPur_box  text-light`}>
            {id!='under_watch'&&     <>                
            <span>Account Purpose</span>{must && <font className="text-danger">&nbsp;* </font>}     
            </>}                                                       
            <div type="text"  onClick={()=>clickHandle()}  style={{cursor:'pointer',textAlign:'left'}} >  
                    
            {accPur.length === 0 ?
                <div style={{display:'inline-flex'}}>
                   {id!='under_watch'? <span>choose</span>
                   :<span>Choose Reasons <span className='product_red_star'>*</span></span>}                     
                </div>
            :        
                accPur.map((item,index) =>            
                    <div key={index} style={{display:'inline-flex',fontSize:'9px'}}>
                        <span>{dd_value(item,"account_purpose")}</span> 
                        <i className="fa-solid fa-xmark accPur_close text-danger" onClick={()=>onChangehandler(false,item)}></i>
                    </div>
                ) 
            }            
            <i className={`fa-solid fa-angle-down text-white dropdownAccpur_Pos ${id=='under_watch'?'watchlist_margin_top':''}`} onClick={()=>clickHandle()} style={{cursor:"pointer"}}></i>

            </div>           
            <hr style={{width:'100%',marginTop:0,marginBottom:0}}/>  
            {dropShow &&
                <div className='drop_style' 
                onMouseLeave={()=>setdropShow(false)}
                >
                    {acc_purposes.map((each,idx)=>{
                        return each.props.children !== "choose" &&
                            <div key={idx} className="row m-0 p-0 d-flex align-items-center">                            
                                <input type="checkbox" className="col-1 m-2" checked={accPur.includes(each.props.value) ? true : false} onChange={(e)=>onChangehandler(e.target.checked,each.props.value)}/>                            
                                <p className="no-margin text-white watchlist_font col p-0 text-left" style={{cursor:'pointer',width:200}} onClick={()=>onChangehandler(accPur.includes(each.props.value) ? false : true,each.props.value)}>{dd_value(each.props.children)}</p>
                            </div>                                           
                    })}
                </div>
            }
        </div>
    )
}