import React, { useEffect, useMemo, useState } from "react";

import { get } from "../../../helpers/storage_helper";
import { IoIosGlobe } from "react-icons/io";
import Select, { components } from "react-select";
import DateRange from "../../common/component/DateRange";
import DatePicker from 'react-datetime';
import moment from "moment";
import { IoCalendarOutline } from "react-icons/io5";
// import Flag from "react-flags";
import { Form } from "react-bootstrap";
import CustomFlag from "../../common/component/CustomFlag";

const FreshChatChartHeader = (props) => {
  const market_list = get("market_list");
  console.log(market_list);

  const [Fullcountry, setFullcountry] = useState(null);

  

  let {
    country,
    start_date,
    end_date,
    selected_agents,
    selected_support_group,
    selected_issue_channels,
    support_groups,
    support_members,
    issue_channels,
    group_checked
  } = props.data;


  const get_fresh_chat_report = props.get_fresh_chat_report

  const handleChange = props.handleChange;

  const getMultiSelectOptions = (data) => {
    if (Array.isArray(data)) {
      return data.map((item) => ({
        ...item,
        value: item.data_value,
        label: item.data_code,
        id: item.data_value,
      }));
    } 
    else if (typeof data === "object" && data !== null) {

      const updatedObject = Object.fromEntries(
        Object.entries(data).map(([key, value]) => [
          key,
          value.map(obj => ({ ...obj, value:obj.data_value, label:obj.data_code, id:obj.data_value }))
        ])
      );

      

      return Object.keys(updatedObject).map((group) => ({
        label: (
          <div className="row d-felx justify-content-start">
            <div className="col-2 d-flex">
              <input
                id={group}
                type="checkbox"
                onChange={(event) =>
                  handleChange("selected_agents", updatedObject[group], event, true)
                }
                checked={updatedObject[group].some((obj1) =>
                  selected_agents.some((obj2) => obj1.label === obj2.label)
                )}
              />
            </div>
            <div className="mr-auto text-white" onClick={()=>{document.getElementById(group).click()}}>{group}</div>
          </div>
        ),
        options: updatedObject[group].map((item) =>({
          ...item,
          value: item.value,
          label: item.label,
          id: item.value,
        })),
      }));
    }
  };

  const CustomOption = (props) => {
    let is_radio = props.is_radio ? true :false
    return (
      <div>
        <div className="pl-3 d-flex align-items-center" style={{ width: "97%" }}>
          <components.Option {...props}>
            <input
              type={is_radio ? "radio":"checkbox"}
              checked={selected_agents.some(
                (v) => v.label === props.label 
              ) || props.isSelected}
            />{" "}
            <label>{props.label}</label>
          </components.Option>
        </div>
      </div>
    );
  };

  const optionStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#1A2035",
      background:
        state.isSelected ||
        selected_agents.some((v) => v.data_code === state.label) 
          ? "#017EFA4D"
          : "",

        ':hover':{
          backgroundColor:'#017EFA4D'
        }
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: "25px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#1A2035",
      color:'#fff'
    }),
    control: (provided)=>({
      ...provided,
      border:'1px solid white',
      borderRadius:'10px',
      backgroundColor:'#303E5266',

    }),
    container:(provided)=>({
      ...provided,
      width:'100%'
    }),
    singleValue:(provided)=>({
      ...provided,
      color:'#ffff'
    })
  };

  const MoreSelectedBadge = ({ items }) => {
    const style = {
      paddingLeft:'1px',
      background: "rgba(251, 251, 251, 0.9)",
      border:'1px solid rgba(251, 251, 251, 0.67)',
      borderRadius: "25px",
      fontSize: "10px",
      padding: "2px",
    };
  
    const title = items.join(", ");
    const length = items.length;
    const label = `+ ...${length}`;
  
    return (
      <div style={style} title={title}>
        {label}
      </div>
    );
  };
  
  const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = 1;
    const overflow = getValue()
      .slice(maxToShow)
      .map((x) => x.label);
  
    return index < maxToShow ? (
      <components.MultiValue {...props} />
    ) : index === maxToShow ? (
      <MoreSelectedBadge items={overflow} />
    ) : null;
  };

  return (
    <>
      <div className="row no-margin justify-content-start">
        <div className={`${"col-md-2 col-lg-2 col-sm-12  align-items-center"}`}>
          {/* <label className="no-margin">Market</label> */}

          <div style={{width:'100%'}}>
          <div className={`dropdown country_dropDownbg dark_theme_bg  p-0 `} style={{backgroundColor:'#303E5266', width:'100%'}}>
            <div
              className="dropdown-toggle d-flex align-items-center dropdown_trig justify-content-between"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{border:'1px solid white', borderRadius:'10px', height:'40px', }}
            >
              {country.country_code ? (
                <>
                  {country.country_code == "global" ? (
                    <>
                      <IoIosGlobe size={20} style={{ margin: "4px 0px" }} />
                      <span style={{ paddingLeft: 15 }}>Global</span>

                      <div className="ml-auto">
                        <span>
                          <i className="fa-solid fa-chevron-down"></i>
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* <Flag
                        id="market"
                        name={country.country_code}
                        format="png"
                        pngSize={24}
                        shiny={false}
                        alt={country.country_code}
                        basePath="/img/flags"
                      /> */}

                      <CustomFlag country_code={country.country_code} height={30} width={30} alt={country.country_code}/>

                      <span style={{ paddingLeft:20 }}>
                        {country.country_code}
                      </span>
                      <div className="ml-auto">
                        <span>
                          <i className="fa-solid fa-chevron-down"></i>
                        </span>
                      </div>
                    </>
                  )}
                </>
              ) : (
                ""
              )}
            </div>
            <ul
              className="dropdown-menu"
              aria-labelledby="dropdownMenuButton1"
              style={{
                width: '100%',
                backgroundColor: "#1A2035",
                color: "white",
              }}
            >
              <li
                key={"global"}
                className="country_selector d-flex align-items-center mb-2 freshchat_dropdown"
                onClick={() => {
                  handleChange("country", { country_code: "global" });
                  document.getElementById(`country_choose`).click();
                }}
              >
                <input
                  className="form-check m-0"
                  type="radio"
                  id="country_choose"
                  style={{ borderRadius: "80%" }}
                  name="country"
                  checked={`${country.country_code == "global" ? true : ""}`}
                />
                <span
                  style={{ paddingLeft: 5, paddingRight: 5 }}
                  for="country_choose"
                >
                  Global
                </span>
                <div className="ml-auto">
                <IoIosGlobe size={25} for="country_choose" />
                </div>
              </li>
              {market_list &&
                market_list.map((market, idx) => (
                  <li
                    key={idx}
                    className="country_selector d-flex mb-2 freshchat_dropdown align-items-center"
                    onClick={() => {
                      handleChange("country", market);
                      document.getElementById(`country_choose${idx}`).click();
                    }}
                    htmlFor={`country_choose` + idx}
                  >
                    <input
                      type="radio"
                      id={`country_choose` + idx}
                      style={{ borderRadius: "80%" }}
                      name="country"
                      checked={`${
                        country.country_code == market.country_code ? true : ""
                      }`}
                    />
                    <span style={{ paddingLeft: 5,paddingRight:10 }}>{market.country}</span>
                   <div className="ml-auto">
                   {/* <Flag
                      id="market"
                      name={market.country_code}
                      format="png"
                      pngSize={48}
                      shiny={false}
                      alt={market.country_code}
                      basePath="/img/flags"
                    /> */}
                   </div>
                  </li>
                ))}
            </ul>
          </div>
          </div>
        </div>

        {
          <div className="col-md-2 col-lg-2 col-sm-12  d-flex justify-content-start nested_select">
            <Select 
              options={support_groups ? support_groups.map((val)=>({label:val.data_code, value:val.data_value})) : []}
              onChange={(option)=>handleChange('selected_support_group', option)}
              value = {selected_support_group}
              components={{ Option: (props)=><CustomOption {...props} is_radio/> }}
              styles={optionStyles}
              hideSelectedOptions={false}
              placeholder="Support Group"
              isMulti = {false}
            />

          </div>
        }
        {/*support members  */}
        <div className="col-md-2 col-lg-2  d-flex justify-content-start nested_select" >
          {(
            <Select
              options={ support_members ? getMultiSelectOptions(support_members):[]}
              isMulti
              components={{ Option: CustomOption, MultiValue:MultiValue }}
              onChange={(option) => handleChange("selected_agents", option)}
              // menuIsOpen={true}
              styles={optionStyles}
              value={selected_agents}
              hideSelectedOptions={false}
              placeholder="Support Members"
              closeMenuOnSelect={false}
            />
          )}
        </div>

        {/* issue channels */}
        <div className="col-md-2 col-lg-2  d-flex justify-content-start nested_select">
          {(
            <Select
              options={issue_channels ? getMultiSelectOptions(issue_channels):[]}
              isMulti
              components={{ Option: CustomOption,MultiValue:MultiValue }}
              onChange={(option) => handleChange("selected_issue_channels", option)}
              styles={optionStyles}
              value={selected_issue_channels}
              hideSelectedOptions={false}
              placeholder="Channel"
              closeMenuOnSelect={false}
            />
          )}
        </div>

        {/* date */}
        <div className="col-md-4 col-lg-4  col-sm-12  " style={{maxHeight:'40px'}}>
        <div className="row d-flex justify-content-start " style={{border:'1px solid white', borderRadius:'10px',backgroundColor:'#303E5266'}}>
          <div className="col-md-auto col-sm-4  d-flex align-items-center ">
           
            <span className="mr-2"><IoCalendarOutline color="white" size={20}/></span>
            <DatePicker 
                  closeOnSelect={true}   
                  dateFormat="DD MMM YYYY" 
                  timeFormat={false} 
                  value={start_date} 
                  onChange={(date)=>handleChange('start_date',date)} 
                  selected={start_date}  
                  isValidDate={(current) => {
                                        const today = moment().startOf('day');
                                        const min_date = new Date(2023,11,8);
                                        return  current >= min_date && current<=today;  }} />
          </div>  				
          <div className="col-md-auto col-sm-4  d-flex align-items-center " >
          <span className="mr-2"><IoCalendarOutline color="white" size={20}/></span>
            <DatePicker 
              closeOnSelect={true}   
              dateFormat="DD MMM YYYY" 
              timeFormat={false} 
              value={end_date} 
              onChange={(date)=>handleChange('end_date',date)} 
              maxDate={new Date()} 
              selected={end_date}
              isValidDate={(current) => {
                const today = moment().endOf('day');
                const min_date = new Date(start_date)
                  return  current <= today || current>=min_date }}
            
            />
          </div>
          <div className="col-md-auto col-sm-2   d-flex align-items-center ml-2"  >
            <button type="button" className="btn btn-sm" value="Submit" style={{borderRadius:'10px', backgroundColor:'#017EFA'}} onClick={()=>get_fresh_chat_report()}>Submit</button>
          </div>

        </div>
          
        </div>

        
      </div>
    </>
  );
};

export default FreshChatChartHeader;
