import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import '../../../styles/flow.css';
import {listScoreModels} from '../../../actions/score_model_actions';
import {get} from '../../../helpers/storage_helper';
export class ProductMapping extends FlowComponent{
	  state = {
      templateList: null,
      comp_name: "loan_product",
      value: 0
  }
  componentWillMount(){
    super.componentWillMount();
    this.setOptionsToStateFromApi("lender");
    this.setOptionsToStateFromApi("acc_prvdr");
    const request = {country_code: this.country_code};
    listScoreModels(this.req(request))
    .then((response) => {
      if(!response){return };
        if(response.status === "success"){
            this.getSelectOptionsFromResult(response.data, "score_model", ["model_code", "model_name"]);
        }else{
          alert(response.message + "\n" + JSON.stringify(response.data));  
        }
      }
    );
}

handleChange1 = (event) => { 
        if(this.props.data.data_provider_id)
        {
          this.setState({option_div_style: "show_div"});
        }
} 

handleProdNameChange = (event) => {
  this.props.data['product_name'] = event.target.value;
  this.handleChange(event);
}

handleCSChange = (event) => {
  let {value, min, max} = event.target;
  value = Math.max(Number(min), Math.min(Number(max), Number(value)));
  value = isNaN(value) ? 0 : value;
  this.props.data.min_credit_score = value;
}

render(){
    
      return (
        <div id="" className="col-md-12">
               <div className="card ">
                  <div className="card-header card-header-primary card-header-icon">
                    <h3 className="card-title">{this.props.title ? this.props.title : "Product Details"}</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4 form-group md-form-group">
                        <span for="product_name" className="md-label"> Product Name *</span>
                        <input id="product_name" value={this.props.data.product_name} onChange={(e) => { this.handleProdNameChange(e); this.handleChange1(e); this.toUpperHandleChange(e) }} className="form-control" type="text" required="required" /> 
                      </div>
                      <div className="col-md-4 form-group md-form-group">
                        <span for="product_code" className="md-label"> Product Code *</span>
                        <input id="product_code" value={this.props.data.product_code} onChange={this.handleChange} className="form-control" type="text" required="required" />  
                      </div>
                      <div className="col-md-4 form-group md-form-group">
                        <span for="product_name" className="md-label"> Product Type *</span>
                        <select id="product_type" value={this.props.data.product_type} onChange={this.handleChange} className="form-control" type="text" required="required">
                          {this.getSelectOptions('product_type')}
                         </select>
                      </div>
                    </div>
                    <div className="row mt-50">
                    {!this.admin_roles.includes(get('role_codes')) &&
                      <div className="col-md-3 form-group md-form-group">
                        <span for="acc_prvdr_code" className="md-label"> Account Provider *</span>
                        <select id="acc_prvdr_code" value={this.props.data.acc_prvdr_code} onChange={this.handleChange} className="form-control" type="text" required="required">
                         {this.state.acc_prvdr_dd}
                        </select>
                      </div>
                    }
                      <div className="col-md-3 form-group md-form-group">
                        <span for="lender_code" className="md-label"> Lender *</span>
                        <select id="lender_code" value={this.props.data.lender_code} onChange={this.handleChange} className="form-control" type="text" required="required">
                          {this.state.lender_dd}
                         </select>
                      </div>

                      <div className="col-md-3 form-group md-form-group">
                        <span for="cs_model_code" className="md-label"> Credit Score Model *</span>
                        <select id="cs_model_code" value={this.props.data.model_code} onChange={this.handleChange} className="form-control" required="required" >
                         {this.state.score_model_dd}                     
                        </select>
                      </div>
                      <div className="col-md-3 form-group md-form-group">
                        <span for="penalty_amount" className="md-label"> Penalty Amount Per Day ({this.currency_code}) *</span>
                        <input id="penalty_amount" value={this.props.data.penalty_amount} onChange={this.handleChange} className="form-control" type="text" required="required" />   
                      </div>

                    </div>
                  </div> 
                </div>
        </div>           
);

}
}
export default ProductMapping;
