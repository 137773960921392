import React, {Component} from 'react';

class Toggle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: false
        };


        this.toggleState = this.toggleState.bind(this);

    }

    toggleState() {
        this.setState({
            toggle: !this.state.toggle
        },() => this.props.set_tog_val(this.state.toggle));
    }

    render() {
        return (
            <form className={`${this.props.chartfield ?  'chart-switch-field' : 'switch-field'} ${this.props.perWidth?'w-100':''}`} style={{padding : this.props.paddingSize ? this.props.paddingSize+'px' :'10px' }}>
                <p  className={`d-flex justify-content-${this.props.aligntext || 'center'}`} style={{color: this.props.fromChart ? '#1a2035' : 'white', fontSize : this.props.fontSize+"px",marginBottom: this.props.marginsize ? this.props.marginsize+'px' : '10px'}} >{this.props.icon}{this.props.title}</p> 
                <input
                    type="radio"
                    id={this.props.id ? `${this.props.id}_left` : "switch_left"}
                    name="switchToggle"
                    value={this.props.leftLabel}
                    onClick={this.toggleState}
                    checked={!this.props.val}
                />
                <label htmlFor={this.props.id ? `${this.props.id}_left` : "switch_left"} style={{width : this.props.perWidth? this.props.perWidth : this.props.width+"px",borderRadius : this.props.fromChart ?  '15px 0px 0px 15px' : '',fontSize:this.props.fromChart ? '12px':'', fontFamily:this.props.fontFamily ? this.props.fontFamily : ''}}>{this.props.leftLabel}</label>
            
                <input
                    type="radio"
                    id={this.props.id ? `${this.props.id}_right` : "switch_right"}
                    name="switchToggle"
                    value={this.props.rightLabel}
                    onClick={this.toggleState}
                    checked={this.props.val}
                />
                <label htmlFor={this.props.id ? `${this.props.id}_right` : "switch_right"} style={{width : this.props.perWidth? this.props.perWidth : this.props.width+"px",borderRadius : this.props.fromChart ? '0px 15px 15px 0px' : "",fontSize:this.props.fromChart ? '12px':'', fontFamily:this.props.fontFamily ? this.props.fontFamily : ''}}>{this.props.rightLabel}</label>
            </form>
        );
    }
}
export default Toggle;