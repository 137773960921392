import React, { Component } from 'react';
import './../../../styles/flow.css';
import { get_path } from '../../../helpers/common_helper';

export class Logo extends Component{


	render(){
		 if(process.env.REACT_APP_USE_ROOT_ONLY_DEV == undefined){
		     this.root = "";
		    }else{
		     this.root = "http://13.234.42.35:8000";
		    }
		var entity = null;
		var root_folder = null;
		if(this.props.entity == 'acc_prvdr'){
			root_folder = 'acc_providers'


		}else if(this.props.entity == 'lender'){
			root_folder = 'lenders'
		}
		var logo_path = get_path("view_file")+"/files/"+ this.props.country_code + "/" + root_folder + "/" ;
		logo_path += this.props.entity_code + "/" + this.props.entity +"_logo/" + this.props.file_name;
		return(
			<>
			<img className={`${this.props.color} logo_border`} src={logo_path} alt={this.props.entity_code}/>
		</>);
	}

}

export default Logo;