import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import {Form, Col , Button ,Checkbox} from 'react-bootstrap';
import {get,get_item,check_priv} from '../../../helpers/storage_helper';
import DatePicker from 'react-datetime';
import moment from 'moment';
import { Link} from 'react-router-dom';


class SearchCallLog extends FlowComponent{
	state = {
			purpose : true  ,
			flow_rel_mgr_dd:'',                                                   

	}
	componentWillMount(){
		this.load_flow_rel_mgr();
	
	}


	render(){
		var call_type = this.props.call_log.call_type
		if(call_type == "incoming"){
			var purpose = "incoming_call_purpose"
		}else if (call_type == "outgoing"){
			var purpose = "outgoing_call_purpose"
		}
		
		return( 
			<div className="mb-3">
			{/*
				<Form.Row>
					<Link className="py-3" to = '#' onClick={this.props.overdueSearch}>Overdue Customers</Link>
	        	</Form.Row>
	        */}
	        	<div className='row no-margin'>
	        		{this.props.call_log.cust_call_history && check_priv("call_log","self_search") &&
			      		<div className="col-1" style={{paddingBottom:15}}>
				       		<div>
			           			<label>Call Logger</label>
			           		</div>
			           		<label className = "ml-3"><h5>All</h5></label>
			       		</div>
			       	}
				    <div className="col-3" style={{paddingBottom:15}}>
				    	<label>From   </label><font className="text-danger">   *  </font>
				    	<DatePicker closeOnSelect={true} defaultValue={this.props.call_log.call_start_time}  dateFormat="DD MMM YYYY" timeFormat={false} required="required" onChange={(value) => {this.handleDateChange("call_start_time", value)}}/>
				    </div>
				    <div className="col-3" style={{paddingBottom:15}}>
				    	<label>To </label><font className="text-danger">   *  </font>
				    	<DatePicker closeOnSelect={true} defaultValue={this.props.call_log.call_end_time}  dateFormat="DD MMM YYYY" timeFormat={false} required="required" onChange={(value) => {this.handleDateChange("call_end_time", value)}} />
				    </div>
				    {check_priv("call_log","all_search") &&
			        	 <div className="col-3" style={{paddingBottom:15}}>
					    	<label>Call Logger</label>
					      	<Form.Control as="select" id="call_logger" onChange={(e) =>{this.props.onComponentElementChange(e)}}>
								{ this.props.priv_users}

								{/* {this.state.flow_rel_mgr_dd}               */}
			                </Form.Control>
				       	</div>
			       	}
			       	
				    <div className="col-3" style={{paddingBottom:15}}>
				    	<label>Call Type</label>
				      	<Form.Control as="select" id="call_type" onChange={(e) =>{this.props.onComponentElementChange(e)}}>
				      		<option value= ""> Choose </option>
				      		<option value = "all">All</option>
				      		<option value ="incoming"> Incoming </option>
				      		<option value = "outgoing" selected ={this.props.call_log.call_type_select}> Outgoing </option>
		                </Form.Control>
			       	</div>
				    {this.props.purpose_field &&
					    <div className="col-3" style={{paddingBottom:15}}>
					    	<label>Purpose</label>
					      	<Form.Control as="select" id="call_purpose" value={this.props.call_log.call_purpose}  onChange={(e) =>{this.props.onComponentElementChange(e)}}>
					      		{this.getSelectOptions(purpose)}
			                </Form.Control>
				       	</div>
			       	}  
		       	<div className="col-3 align-items-center d-flex" style={{height:60}}>					   
					<label for="individual_logs" className='no-margin'>
				   		<>
					   		<Form.Check type="checkbox" checked ={this.props.call_log.individual_logs}  id = "individual_logs"  className= "float-left no-margin" onChange={(e) =>{this.props.onComponentElementChange(e)}} />
			    			Individual Call Logs
						</>	
					</label>								      		    
		       	</div>  	
	        </div>
	        <div className=" d-flex no-margin justify-content-md-center">
	        	<button type="button" className="btn btn-primary " id="searchbtn" disabled = {this.props.search_btn} onClick={()=>this.props.onSearch('search')} >Search</button>
	        </div>
	        
	    </div >)
	}
}

export default SearchCallLog;

