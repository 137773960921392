import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import { Modal } from 'react-bootstrap';

class AlerModal extends FlowComponent{

    handleAlertModal = (val) => {
        this.props.handleAlert(val)
    }

    render(){
        return(
            <Modal show={this.props.show_alert} onHide={()=>this.handleAlertModal(false)} size="lg" centered>
                <Modal.Header closeButton style={{backgroundColor:"#203344"}} show={this.props.show_alert} onHide={()=>this.handleAlertModal(false)}>
                    <Modal.Title><b>{this.props.header}</b></Modal.Title>
                </Modal.Header>
                <Modal.Body closeButton style={{backgroundColor:"#203344"}}>
                    <p style={{color:"white", fontSize:"20px"}}>{this.props.alert_message}</p>
                </Modal.Body>
                <Modal.Footer closeButton style={{backgroundColor:"#203344"}}>
                    <p style={{color:"white", fontSize:"20px", cursor:"pointer"}} className='text-right' onClick={()=>this.handleAlertModal(false)}><b>Ok</b></p>
                </Modal.Footer>
            </Modal>
        )
    }
   
}

export default AlerModal