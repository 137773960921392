import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FiPhoneCall } from 'react-icons/fi';
import { BiMessageDetail, BiCalendar } from 'react-icons/bi';
import { RiQuestionFill } from 'react-icons/ri';
import moment from 'moment';
import Select from "react-select";
import DatePicker from 'react-datetime';
import { MdPhone, MdOutlinePhoneDisabled } from "react-icons/md";
import { ImPhoneHangUp } from "react-icons/im";
import { FiPhoneMissed, FiPhone, FiPhoneOff } from "react-icons/fi";
import {recoveryHistory} from '../../../actions/delinquency_actions';
import { lbl_amt } from '../../../helpers/common_helper';
import { get, Config } from '../../../helpers/storage_helper';
import { dd_value } from '../../../actions/common_actions';
import { GoVerified } from "react-icons/go";
import { IoDocumentTextOutline } from "react-icons/io5";
import {MdLocationOn} from "react-icons/md"
import withRouter from '../../../Router/Withrouter';
class RecoveryFile extends FlowComponent {

    state = {
        menu_index: 0,
        show_document:false,
        active_day_limit: "Day 1-4",
        loader:false,
        wizard_keys: {
            "Day 1-4": true,
            "Day 5-10": false,
            "Day 11-20": false,
            "Day 30-40": false,
            "Day >40": false,
        },
        recovery_file_menus: [
            { label: "First Recovery Report", day_limit: "Day 1-4", key: "first_recovery_report",  },
            { label: "Warning Letter", day_limit: "Day 5-10", key: "warning_letter", },
            { label: "Demand Notice", day_limit: "Day 11-20", key: "demand_notice",  },
            { label: "Notice of Intension to Sue", day_limit: "Day 30-40", key: "noticeof_intension", },
            { label: "Legal", day_limit: "Day >40", key: "legal",},
        ],
        fa_status_name : Config("fa_recovery_status")
        
        


    }

    showDivatTop(elementId) {
        const element = document.getElementById(elementId);
    
        const targetElement = document.getElementById(elementId);
    
        if (targetElement) {
          const position = targetElement.offsetTop;
          window.scrollTo({
            top: 50,
            behavior: 'smooth',
          });
          this.setState({scrollIsNotWorking:false})
        }
    }

    componentWillMount() {
        let screen_width = window.screen.availWidth
        this.setState({ screen_width })
       this.getRecoveryHistory()
    }

    getRecoveryHistory(){
        this.setState({loader:true})

        var req={loan_doc_id : this.props.params.loan_doc_id}
        recoveryHistory(this.req(req))
        .then((response)=>{
            if(!response){return}
            if(response.status==="success"){
                if(response.data.recov_hist){
                let keys = Object.keys(response.data.recov_hist);
                var lastKey = keys[keys.length - 1];
                }
                this.setState({recoveryData:response.data,pending_status:lastKey ? lastKey:null,recovery_file_menus:this.get_left_menus(lastKey),loader:false})

                }
        })
    }

    get_left_menus(pendingKey) {

        var recovery_file_menus = this.state.recovery_file_menus
        for (let i = 0; i < recovery_file_menus.length; i++) {
            if (recovery_file_menus[i].day_limit === pendingKey) {
              recovery_file_menus[i].status = false;
              recovery_file_menus[i].pending = true ;

              
              break;
            } else {
              recovery_file_menus[i].status = true;
            }
          }

          return recovery_file_menus;
 
      }


    update_active_menu(items, index) {
        var state_value = this.state.wizard_keys
        var active_menu;
        var menu_index = this.state.menu_index
        var current_key = items.day_limit
        var active_day_limit 
        for (const [key, value] of Object.entries(this.state.wizard_keys)) {
            if (current_key == key) {
                state_value[key] = true
                active_menu = key
                menu_index = index
                active_day_limit = items.day_limit
            } else {
                state_value[key] = false
                active_day_limit = items.day_limit

            }
        }

        this.setState({ wizard_keys: state_value, menu_index, active_day_limit })
    }

    handleScroll(){
        const parentDiv = document.getElementById('ParentDivId');
      alert(!this.state.scrollIsNotWorking)
        if (parentDiv && (!this.state.scrollIsNotWorking)) {
            alert(!this.state.scrollIsNotWorking)

          const childDivs = parentDiv.querySelectorAll('.ChildDivClass');
          childDivs.forEach((childDiv) => {
            const rect = childDiv.getBoundingClientRect();
            if(rect.top <= 0 && rect.bottom <= window.innerHeight){
                
                this.setActiveSideMenu(childDiv.id )
            }
            else if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
                this.setActiveSideMenu(childDiv.id )
              return;
            }
          });
        }    }

        setActiveSideMenu(id){
            
            var state_value = this.state.wizard_keys
            var active_menu;
            var active_day_limit;
            for (const [key, value] of Object.entries(this.state.wizard_keys)) {
                if (id == key) {
                    state_value[key] = true
                    active_menu = key
                    active_day_limit = id
                } else {
                    state_value[key] = false
    
                }
            }
            this.setState({ wizard_keys: state_value,  active_day_limit })

        }

        handleClose(){
            this.setState({ view_details_modal: false,show_document:false})

        }

    render() {


        return (
            <div>
                {this.state.loader ? 

                <div className='d-flex justify-content-center my-5' style={{position:'absolute',top:'30%',left:'50%'}}>
                <div >
                <p className='text-light mt-3 text-center'>Loading...</p>
                <div className="product_takeup_loader"></div>
                </div>
                </div>
                
                :
                <div className={`row m-0 ${this.state.view_details_modal?"disable_div":""}`}>
                    <div className='col-lg-9 col-md-12 col-sm-12 row mx-0 mt-5 ' style={{border:'1px solid'}}>

                    <div className='col-lg-4  recovery_file_bg   col-md-12 col-sm-12 '>
                        {this.state.recovery_file_menus &&this.state.recovery_file_menus.map((item, idx) => {
                            return (
                                <a className='vertical_side_bar' href={`#${this.state.active_day_limit}`} onClick={() => this.showDivatTop(`${this.state.active_day_limit}`)}>
                                    <div className='d-flex justify-content-around my-5 row m-0 pointer' key={idx} onClick={() => this.update_active_menu(item,idx)}>
                                        <div id={idx} className={`${(this.state.active_day_limit === item.day_limit ) ? "active_recovery_menu" : "unactive_menu"} h-100 px-4 py-3 col-8`}>
                                            <p className={`m-0 sizem ${(this.state.active_day_limit === item.day_limit )? "text-bold" : ""}`}>{item.label}</p>
                                            <p className='m-0 pt-2'>{item.day_limit}</p>
                                        </div>

                                        <div className='col-1'>
                                            <div className='relative_position'>
                                                {item.pending &&
                                                    <div className={`${item.status ? "active_circle rounded_circle p-3" : "inactive_circle rounded_circle p-3"}  ${item.pending ? "pending_animation pending_status " : ""} `}></div>
                                                }
                                                <div className={`${item.status ? "active_circle rounded_circle p-3" : "inactive_circle rounded_circle p-3"}  ${item.pending ? "pending_status" : ""} `}></div>
                                                {idx !== this.state.recovery_file_menus.length - 1 && (
                                                    <div className={`${item.status ? "active_border recovery_vertical_line_animation recovery_vertical_line" : "inactive_border recovery_vertical_line"}`} ></div>
                                                )}
                                                {idx === this.state.recovery_file_menus.length - 1 && (
                                                    <div className='hiding_lines_with_box'></div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </a>
                            );
                        })}



                    </div>
                    {this.state.recoveryData &&
                    <div className='col-lg-8 theme_bg col-md-12 mt-5 col-sm-12'>
                        <div className='recovery_file_scroll relative_position yourParentDivClass '  id="ParentDivId" >

                        {/* <div className='recovery_file_scroll relative_position yourParentDivClass '  id="ParentDivId" onScroll={()=>{this.setState({scrollIsNotWorking:false},()=>{ this.handleScroll()}) }}> */}
                            <div >
                                {Object.keys(this.state.recoveryData.recov_hist).map((dayLimit, id) => (
                                    <div  id={dayLimit} className='ChildDivClass'>
                                    <div 
                                        key={dayLimit}
                                        className={dayLimit === this.state.active_day_limit ? 'active pt-3' : ''}
                                    >
                                        
                                        <div className='recovery_file_content_div mt-3 relative_position mb-5'>
                                            <p className='m-0 text-white text-center mx-3 px-4 py-2 active_menu_bg'> {dayLimit} </p>

                                            {this.state.recoveryData.recov_hist[dayLimit].map((items, idx) => {
                                                return (
                                                    <div className='my-2'>
                                                        <div className=' d-flex justify-content-center align-items-center my-3 mt-5 mx-0'>
                                                            <div className='col-5 p-0'>
                                                                <hr className='m-0 hr_line' />
                                                            </div>
                                                            <p className='m-0 px-2 col-2  text-center recovery_menu_date' >{items.date}</p>
                                                            <div className='col-5 p-0'>
                                                                <hr className='m-0 hr_line' />

                                                            </div>
                                                        </div>
                                                        {items.data.map((values, index) => {
                                                            return (

                                                               
                                                                <div className='recovery_file_contents  my-4 mx-5 py-3 px-4 relative_position'>
                                                                     
                                                                    {((values.task_type === "frr_visit") || (values.show_doc) || (values.task_type==="fa_recovered" ) ||(values.commitment_date != null) )&&
                                                                    <div className='d-flex justify-content-end align-items-center'>
                                                                        <div>

                                                                       
                                                                        {(values.show_doc) &&
                                                                        <p className='m-0 text-white pointer' style={{textDecoration:"underline",fontSize:"14px"}} onClick={()=>{this.setState({show_document:true,document_data:values.show_doc})}}><b>{values.show_doc.label}</b> <IoDocumentTextOutline size={"15"}/>
                                                                        </p>
                                                                        }
                                                                        {(values.task_type==="fa_recovered" || values.task_type==="partially_paid") &&  (values.paid === true ) &&
                                                                           <p className='m-0 text-white' style={{fontSize:"14px"}}> <b>FA Recovered  </b> {(values.paid === true )&&<GoVerified size={"20"} className="text-success mx-2" />} </p>

                                                                        }
                                                                        <p className='m-0 text-white' style={{fontSize:"14px"}}> <b>{(values.task_type === "frr_visit") ? "Understand Overdue Reason":""}</b></p>

                                                                        {values.commitment_date &&
                                                                            <p className='m-0 text-white' style={{fontSize:"12px"}}>
                                                                                <b>Commitment Date : {moment(values.commitment_date).format("DD-MMM-YYYY")}</b>
                                                                            </p>
                                                                        }


                                                                        </div>
                                                                       
                                                                    </div>
                                                                    }
                                                                    
                                                                    
                                                                    <>
                                                                    {(values.task_type==="fa_recovered" || values.task_type==="penalty_pending" || values.task_type==="partially_paid") ? 
                                                                    <>
                                                                    <p className='m-0'>
                                                                        
                                                                        <img src='/img/frame.png' style={{ width: "30px", height: "30px" }} alt='paid' />
                                                                       
                                                                    <b className='pl-3 text-white reassignment_Label'>Paid</b>
                                                                   </p>
                                                                   <p className='m-0 text-white recovery_file_text pl-5 pb-2 pt-1'> <b>{values.paid_amt?lbl_amt(values.paid_amt,get('market').currency_code):""}</b></p>
                                                                    {values.paid !== true &&
                                                                   <p className='mx-0 my-1 text-white recovery_file_text  pl-5 pb-2'>  <b>{values.os_amt?lbl_amt(values.os_amt,get('market').currency_code):""}</b> 
                                                                   <span style={{fontSize:'12px'}}> (Outstanding Amount )</span></p>
                                                                    }
                                                                    {values.penalty_amt !== 0 && 
                                                                    <p className='m-0 text-white recovery_file_text pl-5'> <b>{values.penalty_amt?lbl_amt(values.penalty_amt,get('market').currency_code):""}</b>
                                                                    <span style={{fontSize:'12px'}}> (Penalty Amount )</span></p>
                                                                    }
                                                                    

                                                                  </>
                                                                    :
                                                                    <>
                                                                    <p className='m-0'>
                                                                        {(values.task_type).includes("_visit") ?
                                                                         <MdLocationOn size="20" color='white' />
                                                                        :
                                                                        <FiPhoneCall size="20" color='white' />}
                                                                        <b className='pl-3 text-white reassignment_Label'>{values.person_name} ({dd_value(values.role_code,values.role_code)})</b>
                                                                    </p>
                                                                    <p className='text-white mx-0 mb-0 mt-2 '> 
                                                                    {(values.cont_arr && values.cont_arr.length>0 )?values.cont_arr.map((val,idx)=>{return(
                                                                        <span className='pl-2 pt-2' key={idx}> {val} <span> {(values.cont_arr.length-1 ==idx ) ? "":" | "} </span> </span>
                                                                    )}) :" " } {values.label ? "(" + dd_value(values.label) +")" :""} </p>
                                                                    <p className='mb-0 pl-2 pt-2 sizem text-white'>{dd_value(values.reason, 'any')}</p>
                                                                    <p className='mb-0 pl-2 pt-2 sizem text-white'>{values.remarks}</p>
                                                                    </>
                                                                    }
                                                                    
                                   
                                                                    <div className='d-flex justify-content-end '>
                                                                        <p className='m-0 text-white'>{items.date} | {moment(values.time).format("HH:mm a")} </p>
                                                                    </div>
                                                                    </>
                                                                </div>
                                                            )
                                                        })}

                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>
                                    </div>
                                ))}
                            </div>


                        </div>
                    </div>
                    }
                    </div>
                    {this.state.recoveryData && 
                    <div className='col-lg-3 recovery_file_bg mt-5 py-2 col-md-12 col-sm-12'>
                     <h2 className='text-white mx-5 mb-0 mt-5 text-center'> <b>Recovery File</b></h2>
                        
                        <div className='d-flex align-items-center justify-content-center'>
                            <div className='col-lg-8 d-flex align-items-center justify-content-center col-md-12 col-sm-12 cust_image'>
                           
                            {/* <img className='recovery_file_img my-5' src={` http://192.168.68.147:8000${this.state.recoveryData.cust_details.cust_photo_pps}`}/> */}

                             <img className='recovery_file_img my-5' src={`${this.root}${this.state.recoveryData.cust_details.cust_photo_pps}`}/>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center'>
                        <div className='mx-5'>
                        <div className='mt-3'>
                          <p className='m-0 text-white sizem'> <b>{this.state.recoveryData.cust_details.cust_name}</b></p>
                        </div>

                        <div className='recovery_file_cust_datas'>
                        <div className='mt-3'>
                          <p className='m-0 gray_text'>Customer ID</p>
                          <p className='m-0 text-white sizem'> {this.state.recoveryData.cust_details.cust_id}</p>
                        </div>
                        <div className='mt-3'>
                          <p className='m-0 gray_text'>Address</p>
                          <p className='m-0 text-white sizem'> {this.state.recoveryData.cust_details.location?dd_value(this.state.recoveryData.cust_details.location):''}</p>
                        </div>
                        <div className='mt-3'>
                          <p className='m-0 gray_text'>Mobile Number</p>
                          <p className='m-0 text-white sizem'> {this.state.recoveryData.cust_details.cust_mob_num}</p>
                        </div>

                        </div>

                        <div className='recovery_file_cust_datas'>
                        <div className='mt-3'>
                          <p className='m-0 gray_text'>RM Name</p>
                          <p className='m-0 text-white sizem'>{this.state.recoveryData.cust_details.rm_name}</p>
                        </div>
                        <div className='mt-3'>
                          <p className='m-0 gray_text'>Outstanding Amount</p>
                          <p className='m-0 text-white sizem'> {lbl_amt(this.state.recoveryData.cust_details.os_fa_amt,get('market').currency_code)}</p>
                        </div>
                        <div className='mt-3'>
                          <p className='m-0 gray_text '>Overdue Days</p>
                          <p className='m-0 text-white sizem' > {this.state.recoveryData.cust_details.od_days}</p>
                        </div>

                        </div>
                        
                       
                        <div className='d-flex justify-content-center'>
                        <div className='mt-3 w-100 recovery_file_cust_datas'>
                            <div className='my-3'>
                            <button className='btn product_takeup_submit px-4 py-3' onClick={()=>{this.setState({view_details_modal:true})}}>View Details</button>
                            </div>
                            <div className='mt-3 mb-3'>
                            <button type="button" class="btn btn-light px-4" style={{borderRadius:"10px"}} onClick={()=>window.location.replace(`${this.root}${this.state.recoveryData.agreement.aggr_file_path}`)}>Agreement</button>

                            {/* <button type="button" class="btn btn-light px-4" style={{borderRadius:"10px"}} onClick={()=>window.location.replace(`http://192.168.68.147:8000${this.state.recoveryData.agreement.aggr_file_path}`)}>Agreement</button> */}

                            </div>
                               
                               <div className='mb-4'>
                               <a className=' text-info py-3 mb-3' style={{textDecoration:"underlined"}} href={`${this.root}${this.state.recoveryData.recov_hist_pdf}`}>Download as PDF</a>

                               {/* <a className=' text-info py-3 mb-3' style={{textDecoration:"underlined"}} href={`http://192.168.68.147:8000${this.state.recoveryData.recov_hist_pdf}`}>Download as PDF</a> */}

                       </div> 
                       </div> 

                       </div>
                       
                        </div>
                        </div>
                       
                      
                              
                    </div>
                    }
                </div>
                }

                {(this.state.view_details_modal  || this.state.show_document) &&

                    <Modal size={ this.state.show_document ? "":"lg"} show={this.state.view_details_modal || this.state.show_document} onHide={()=>this.handleClose()}  keyboard={false}>
                                        
                    <Modal.Header className='py-3 modal_header_bg modal-header' closeButton>
                        <Modal.Title className='p-0'> <h2 className='text-white '> <b> { this.state.show_document ? `${this.state.document_data.label }`:"Overdue FA Details"}</b></h2></Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ background: "#0F273F" }}>
                    {this.state.show_document ? 
                    <div>
                    {this.state.document_data&& 
                    <div>
                        <img src={`${this.root}${this.state.document_data.value}`} alt='doc' style={{width:"451px",height:'455px'}}/>
                    </div>
                    }
                    </div>
                    
                    
                    :
                    <div className=' overdue_details_modal p-5'>
                     <div className='d-flex align-items-center row m-0'>	
                     <div className='col-1 p-0'>
                     <img  className="img-fluid" src={`/img/${this.state.recoveryData.fa_details.acc_prvdr_code}_logo_color.png`} width={"50"} height={"30"} />	

                     </div>
                        <p className='m-0 col-10   text-white sizem'>{this.state.recoveryData.fa_details.acc_number}</p>
                     </div>

                     <div className='d-flex my-3 align-items-center'>
                        <div className='col-1 p-0'>
                            <BiCalendar size={"30"} color='white'/>
                        </div>
                        <div className=' col-3'>
                        <p className='m-0  text-white sizem'>Overdue Days</p>
                        <p className='m-0 pt-1  text-white ' ><font size="3">{this.state.recoveryData.fa_details.od_days} Days</font> </p>
                        </div>
                        <div className=' col-3'>
                        <p className='m-0  text-white sizem'>Status</p>
                        <p className='m-0 pt-1  text-white ' ><font size="3">{this.state.fa_status_name[this.state.recoveryData.fa_details.od_fa_detail.status]}</font> </p>
                        </div>
                     </div>

                     <div className='px-2 mt-5 row mx-0'>
                     <p className='m-0 pt-1  text-white ' ><font size="4"><b>FA Details</b></font> </p>
                        
                        <div className='my-4 col-lg-12 col-md-12'>
                        <table class="table table-bordered  overdue_details_table table-hover">
                            <thead>
                                <tr>
                                <th scope="col"> <b>Disbursed Date</b></th>
                                <th scope="col"><b>Disbursed Amount</b></th>
                                <th scope="col"><b>Fee</b></th>
                                <th scope="col"><b>Penalty</b></th>
                                <th scope="col"><b>Outstanding FA</b></th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td  className="text-white" scope="row">{this.state.recoveryData.fa_details.od_fa_detail.disbursal_date? moment(this.state.recoveryData.fa_details.od_fa_detail.disbursal_date).format("DD MMM YYYY "):""}</td>
                                <td className="text-white">{this.state.recoveryData.fa_details.od_fa_detail.FA_disbursal_amount? lbl_amt(this.state.recoveryData.fa_details.od_fa_detail.FA_disbursal_amount,get('market').currency_code):""}</td>
                                <td className="text-white">{this.state.recoveryData.fa_details.od_fa_detail.fee? lbl_amt(this.state.recoveryData.fa_details.od_fa_detail.fee,get('market').currency_code):""}</td>
                                <td className="text-white">{this.state.recoveryData.fa_details.od_fa_detail.penalty? lbl_amt(this.state.recoveryData.fa_details.od_fa_detail.penalty,get('market').currency_code):""}</td>
                                <td className="text-white">{(this.state.recoveryData.fa_details.od_fa_detail.os_fa && (typeof(this.state.recoveryData.fa_details.od_fa_detail.os_fa)) == 'number') ? lbl_amt(this.state.recoveryData.fa_details.od_fa_detail.os_fa,get('market').currency_code): dd_value(this.state.recoveryData.fa_details.od_fa_detail.os_fa)}</td>

                                </tr>
                                
                                
                            </tbody>
                            </table>
                        </div>
                     </div>

                     <div className='px-2 mt-5 row m-0'>
                     <p className='m-0 pt-1  text-white ' ><font size="4"><b>Transaction Details</b></font> </p>
                        
                        <div className='my-4 col-md-12 col-lg-12'>
                        <table class="table table-bordered overdue_details_table table-hover">
                            <thead>
                                <tr>
                                <th scope="col"> <b>Transaction Date</b></th>
                                <th scope="col"><b>Transaction ID</b></th>
                                <th scope="col"><b>Account No</b></th>
                                <th scope="col"><b>Transaction Type</b></th>
                                <th scope="col"><b> Amount</b></th>

                                </tr>
                            </thead>
                            <tbody>
                           { this.state.recoveryData.fa_details.txn_details &&
                           <>
                                {Object.keys(this.state.recoveryData.fa_details.txn_details).map((key, id) =>{return (
                                    <tr>

                                    <td  className="text-white" scope="row">{this.state.recoveryData.fa_details.txn_details[key].transaction_date ? moment(this.state.recoveryData.fa_details.txn_details[key].transaction_date).format("DD MMM YYYY"):""}</td>
                                    <td className="text-white">{this.state.recoveryData.fa_details.txn_details[key].transaction_id}</td>
                                    <td className="text-white">{this.state.recoveryData.fa_details.txn_details[key].account_number}</td>
                                    <td className="text-white">{this.state.recoveryData.fa_details.txn_details[key].transaction_type ?dd_value(this.state.recoveryData.fa_details.txn_details[key].transaction_type):""}</td>
                                    <td className="text-white">{(this.state.recoveryData.fa_details.txn_details[key].outstanding_amount && typeof(this.state.recoveryData.fa_details.txn_details[key].outstanding_amount )=='number') ?lbl_amt(this.state.recoveryData.fa_details.txn_details[key].outstanding_amount,get('market').currency_code):  dd_value(this.state.recoveryData.fa_details.txn_details[key].outstanding_amount)}  </td>

                                </tr>
                                )})}
                         </>
                                
                             }
                                
                            </tbody>
                            </table>
                        </div>
                     </div>
 

                    </div>}

                    </Modal.Body>
                    </Modal>
                }
            </div>
        );

    }
}



export default withRouter (RecoveryFile);
