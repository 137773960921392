import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
//import {getAccountTxnsType} from '../../../actions/account_txn_actions';
import {getMasterData} from '../../../actions/common_actions';
import {Button } from 'react-bootstrap';
import DatePicker from 'react-datetime';
import '../../../styles/flow.css';
import $ from 'jquery';

class Transaction extends FlowComponent {
  state = {
    comp_name: "transaction"
  }
componentWillMount()
{
        /*  const request = {data_key: "account_transaction_type"};
        getAccountTxnsType(this.req(request))
        .then((response) => {
          if(!response){return };
            if(response.status === "success"){
                this.getSelectOptionsFromResult(response.data.list, "account_txn_type", ["data_code", "data_value"]);
            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          }
        );*/
      const request = {"status": 'enabled', data_key: "account_transaction_type"}
      const request_data = {"master_data": request};

      getMasterData(this.req(request_data))
         .then((response) => {
          if(!response){return };
            if(response.status === "success"){
                this.getSelectOptionsFromResult(response.data, "account_txn_type", ["data_code", "data_value"]);
            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          }
        );

  }
  render(){ 
    return (
      <div className="row no-margin account_left date-range">
          <div className="col-md-4">
            <span>Start Date</span>
             <DatePicker closeOnSelect={true} onChange={(value) => {this.handleDateChange("start_date", value)}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
            </div>
          <div className="col-md-4">
            <span>End Date</span>
             <DatePicker closeOnSelect={true} onChange={(value) => {this.handleDateChange("end_date", value)}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
             </div>
          <div className="col-md-4">
            <span>Transaction Type</span>
                <select id="acc_txn_type" onChange={this.handleChange} className="form-control"> 
                {this.state.account_txn_type_dd}       
                </select>

             </div>
          </div>
      );
  }
}
export default Transaction;