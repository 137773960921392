import React, { useEffect, useState } from 'react';
import SessionsRow from './SessionsRow'

function Sessions(props){
  
    const [isTable, setIsTable] = useState(true)
    const [showTable, setShowTable] = useState(false)
    const [isEmpty, setIsEmpty] = useState(true)

    useEffect(() => {
        if (!props.result[0].mobile_num){
            setTimeout(function () {
                setIsEmpty("")
            },5000)
            return;
        }
        else if (props.result[0].mobile_num === "No session found!") {
           
            setIsTable(false)
            setIsEmpty(false)
        }
        else {
            setShowTable(true)
        }
    },[isEmpty])
    
    function create_session_elements (res) {
        return (
            <SessionsRow id = {res.id} key = {res.id} mobile_num = {res.mobile_num} userId = {res.userId} deleteSession = {props.deleteSession}/>
        )
    }
    
    function render_table() {
        return (
            <div>
            <table className='whatsapp-table whatsapp-border'>
                <tbody>
                    <tr className='whatsapp-tr'>
                        <th className='whatsapp-border whatsapp-padding'>MOBILE NUMBER</th>
                        <th className='whatsapp-border whatsapp-padding'>USER ID</th>
                        <th className='whatsapp-border whatsapp-padding'>LOGOUT</th>
                    </tr>
                        {props.result.map(create_session_elements)}
                </tbody>
            </table>
        </div>
        )
    }
    return(
        (!isEmpty&&((isTable&&showTable)?render_table():<h4>{props.result[0].mobile_num}</h4>))
       
    )
}
export default Sessions;