import React from 'react';
import './../../styles/flow.css';
import DatePicker from 'react-datetime';
import moment from 'moment';
import {Card} from 'react-bootstrap';
import CommonReactTable from '../../helpers/commonReact_table';
import FlowComponent from '../common/component/core/FlowComponent';
import { ListStmtImports} from '../../actions/common_actions';
import {get_time_diff ,Homeacc_prvdrLogoselector,convert_sec_to_min_sec,title_case} from '../../helpers/common_helper';
import { Link } from 'react-router-dom';
import {lbl_amt}from '../../helpers/product_helper';
import { BasicReactTable } from '../../helpers/react_table';


class AppSupportDashBoard extends FlowComponent{
    state = {
        rowdatas :[],
        copy : [],
        columns : [],
        show_table: false,
        last_import : 10,
        refresh: false,							
        date : null

    }
   
    componentWillMount() {

        if(this.props.refresh){
            setInterval(() => this.refreshList(),this.props.refresh)
        }
        this.drawTable()
    }

    refreshList = () => {		
		this.setState({refresh: true})
        this.drawTable()
	}

	/*componentDidUpdate(prevProps) {
        if(prevProps.refresh != this.props.refresh){
            this.refreshList()
        }		
    }*/


    drawTable(value, key){

        var request = {}

        if(key == 'date' && value){
            this.setState({date:value})
        }

        request.date = key == "date" ? value : this.state.date;
        request.last_limit = key == "last_import" ? value : " ";
        request.home_table = true;
        this.server_time(this.time_zone)			

        ListStmtImports(this.req(request))
        .then((response) => {
            this.resp(response)
            if(!response){return}
            if(response){
                this.setState({refresh: false});
                this.setState(state => state.rowdatas = response.data)
                this.setState(state => state.copy = response.data)
                this.setState(state => state.columns =  this.columndatas())
            }
        });
    }

    handleLastImport = (value) => {
        this.setState({last_import : value})
        this.drawTable(value, 'last_import')
    }

    handleDateChange = (event) =>{
        var value = moment(event).format("YYYY-MM-DD")
        this.drawTable(value, 'date')        
    }

    columndatas(){
        return[
            {
                Header : "Network",
                id: 'network_prvdr_code',
                minWidth:80,
				accessor:row => <Homeacc_prvdrLogoselector logotype={row.network_prvdr_code}/>
            },
            {
                Header : "Acc Number",
                id: 'acc_number',
                minWidth:120,
                accessor: row=><div style={{height:30,width:60}}>			
                                    <img className="img-fluid" src={`/img/${row.acc_prvdr_code}_logo_color.png`} alt={row.acc_prvdr_code}/>
                                  <Link to={`/account/${row.account_id}/statement`} ><span className='ml-2' style={{color : "#17a2b8"}}>{row.acc_number} {row.branch == null ? " " : " ("+title_case(row.branch)+")"}</span>	</Link>  		
                                </div> 
            },
            {
                Header : "Today's Imports",
                id: 'total_imports',
                minWidth:100,
                accessor: row=> <span><p style={{color : "#17a2b8"}}>{row.total_success} Imports / Avg {row.avg_time > 60 ? convert_sec_to_min_sec(row.avg_time) : `${row.avg_time} Sec`}</p></span>
            },
            {
                Header : "Today's Failures",
                id: 'total_failures',
                minWidth:100,
                accessor: row=> <p className={'ml-5'} style={{color : "#17a2b8"}}>{row.total_failures}</p>
            },
            {
                Header : "Today's Success %",
                id: 'success_perc',
                minWidth:100,
                accessor: row=> <p className={'ml-5'} style={{color : "#17a2b8"}}>{row.success_perc} %</p>
            },
            {
                Header : `Last ${this.state.last_import} Imports`,
                id: 'last_import',
                minWidth:100,
                accessor: row=> <p className={'ml-5'} style={{color : "#17a2b8"}}>{row.last_import} / {this.state.last_import}</p>
                
            },
            {
                Header : "Last Import",
                id: 'start_time',
                minWidth:100,
                accessor: row=> <p style={{color : "#17a2b8"}}>{get_time_diff(this.state.server_time.server_time_db , row.start_time)}</p>
            },{
                Header : "Balance",
                id: 'balance',
                minWidth:80,
                accessor: row=> <p style={row.lower_limit > row.balance ? {color:'#ff0000'} : {color : "#17a2b8"}}>{lbl_amt(row.balance,this.currency_code)}</p>
            }
                
        ]
    }        

    render(){
        return(
            <Card className='w-100'>
                <Card.Header style = {{backgroundColor: "#01062c" , color: "white"}}><h4>Today's Statement Imports</h4></Card.Header>
                <Card.Body>
                    <div className="col-md-2 m-3">
                        <span>Date</span>
                        <DatePicker onChange={(e) => {this.handleDateChange(e)}}   closeOnSelect={true}     dateFormat="DD MMM YYYY" timeFormat={false}  />
                    </div>
                    <div className='col-md-12 ' >
                        <div className='col-md-10  no-padding' >
                            <ul className='manDash_currencySelector mr-5 my-0 no-padding  justify-content-end align-items-center'>
                                <li onClick={() => this.handleLastImport(5)} style={{backgroundColor: this.state.last_import === 5 ? "lightgray" :  ""}} className='border_left' >
                                    <p className='no-margin'>5</p>                                    
                                </li>
                                <li onClick={() => this.handleLastImport(10)} style={{backgroundColor: this.state.last_import === 10 ? "lightgray" :  ""}}>
                                    <p className='no-margin'>10</p>                                    
                                </li>
                                <li onClick={() => this.handleLastImport(15)} style={{backgroundColor: this.state.last_import === 15 ? "lightgray" :  ""}} >                                    
                                    <p className='no-margin'>15</p>                                    
                                </li>
                                <li onClick={() => this.handleLastImport(20)} style={{backgroundColor: this.state.last_import === 20 ? "lightgray" :  ""}} >                                    
                                    <p className='no-margin'>20</p>                                    
                                </li>
                                <li onClick={() => this.handleLastImport(25)} style={{backgroundColor: this.state.last_import === 25 ? "lightgray" :  ""}} >                                    
                                    <p className='no-margin'>25</p>                                    
                                </li>
                                <li onClick={() => this.handleLastImport(30)} className='border_right' style={{backgroundColor: this.state.last_import === 30 ? "lightgray" :  ""}}>                                    
                                    <p className='no-margin'>30</p>                                    
                                </li>
                            </ul>
                        </div> 
                        <BasicReactTable row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} FName={"field_visits"}  pagination={false} default_page_size = {10}   defaultSorted={[{id:"acc_prvdr_code"}, {id:"network_prvdr_code"}]} />  
{/*                         
                        <CommonReactTable showPag={false} defaultPageSize={20} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} resizable={false} defaultSorted={[{id:"acc_prvdr_code"}, {id:"network_prvdr_code"}]}/>                                 */}
                    </div>
                </Card.Body>
            </Card>
           
        );
    }
}
export default AppSupportDashBoard;