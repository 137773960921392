import { get_path } from '../helpers/common_helper';
import {call_fetch} from './../helpers/fetch_helper';

export const reqWriteOff = request => {
   return call_fetch(get_path('app') + '/write_off/req_write_off', request)
}

export const getWriteOff = request => {
   return call_fetch(get_path('app') + '/write_off', request)
}

export const listWriteOff = request => {
   return call_fetch(get_path('app') + '/write_off/list', request)
}

export const appr_reject_writeoff = request => {
   return call_fetch(get_path('app') + '/write_off/appr_reject', request)
}

export const getRecoveryAmount = request => {
   return call_fetch(get_path('app') + '/write_off/recover_amt', request)
}