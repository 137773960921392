import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';



class ChooseOrg extends FlowComponent {
   state = {
    
    dropdown_div_style: "disable_div"
   
    /*name: [],
    inc_name: [],
    inc_number: [],
    inc_date: [],
    tax_id: []*/
  }


handleClick = (event) => {
    if(this.state.dropdown_div_style === "disable_div"){
      
      this.setOptionsToStateFromApi("org");
      this.setState({dropdown_div_style: "show_div"});
      
    }else{
      
      this.setState({dropdown_div_style: "disable_div"});	
      this.props.resetOrgNAddr(event);
    }
    
  }
	render(){
		return(
	        <div className="row mt-3">
	          <div className="col-md-3 mt-3">
	              <span>
	              <h4><input type="checkbox" id="checkbox" onClick={this.handleClick} />&nbsp;&nbsp;{"Choose Organisation"}</h4>
	              </span>
	          </div>
	          <div className="col-md-3">
	            <span>
	              <div id="" className={`${this.state.dropdown_div_style}`} >
	                <select id="name_list" onChange={this.props.onChange}  className="form-control" type="text" required="required">
	                  {this.state.org_dd}

	                </select>
	              </div>
	            </span>
	          </div>
	        </div>  
		       
		);

	}

}


export default ChooseOrg;
