import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import {Form} from 'react-bootstrap';
import DateTimePicker from 'react-datetime';
import {getRefAccounts} from '../../../actions/account_txn_actions';


class SearchStmtImports extends FlowComponent{

    state ={
        account : {}
    }

    componentWillMount(){
        this.loadAccounts()
    }

    loadAccounts = (event) => {
        var account = {"country_code": this.country_code, to_recon : true};    
          getRefAccounts(this.req({account},true))
            .then((response) => {
              if(!response){return };
              if(response.status === "success"){
                if(response.data != "")
                {
                  this.getSelectOptionsFromResult(response.data, "lender_accounts", ['id','name']);
                }     
              }else{ 
                alert(response.message + "\n" + JSON.stringify(response.data));  
              }
            }
          );
        }
      

    render(){
        return(
            <Form className="mb-3">
	        	<Form.Row className = "my-4 justify-content-center" style={{display:"flex",alignItems:"center"}}>
                    <div className = "col-3">
                        <span className = "form-label">Accounts</span><font className="text-danger">   *  </font>
                        <select id="account_id" className="form-control" required="required" onChange={this.handleChange} >
                            {this.state.lender_accounts_dd}                               
                        </select>
                    </div>
                    <div className="col-3">
                        <span>Start Time</span><font className="text-danger"> * </font>
                        <DateTimePicker showTimeInput onChange={(value) => {this.handleDateTimeChange("start_time", value)}}   closeOnSelect={true}     dateFormat="DD MMM YYYY" />
                    </div>
                    <div className="col-3">
                        <span>End time</span><font className="text-danger"> * </font>
                        <DateTimePicker  showTimeInput onChange={(value) => {this.handleDateTimeChange("end_time", value)}}   closeOnSelect={true}     dateFormat="DD MMM YYYY" />
                    </div>
                    <div className = "col-3">
                        <span className = "form-label">Status</span>
                        <select id="status" className="form-control" required="required" onChange={this.handleChange} >
                            <option value="">Choose</option>
                            <option value="started">Started</option>
                            <option value="imported">Imported</option>
                            <option value="failed">Failed</option>
                        </select>
                    </div>
                </Form.Row>
            </Form>
        )
    }
}
export default SearchStmtImports;
