import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import CommonReducer, { rehydrate } from './Slices/CommonSlice';
import HeaderSlice from './Slices/HeaderSlice';
const persistConfig = {
  key: 'root',
  storage,
  whitelist:['isIncomingCall','cust_data','call_duration','callStatus','call_type','showMenus','outgoingCall','isCallInProcess','call_log_details','screen_from','cust_id','CallLogModal','ModalIsOpen','WsStatus','incomingcall_num']
};

const rootReducer = {
  Values: CommonReducer,
  HeaderSlice,
  cs_soft_phone: CommonReducer
};

const store = configureStore({
  reducer: rootReducer,
  
});



// const persistor = persistStore(store);
export { store };



