import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get } from '../../../helpers/storage_helper';
import {call_await_fetch, app_path} from '../../../helpers/fetch_helper';

function QrCodeModal() {
    let qrCode = true
    const [result, setResult] = useState("")
    const [sessionId, setSessionId] = useState("")
    const [sessionResult, setSessionResult] = useState("")
    const [needFetching, setNeedFetching] = useState(true)

    const market = get('market')
    async function getqrCode() {
        const response = await call_await_fetch(app_path + '/whatsapp/connect', { country_code: market.country_code, time_zone: market.time_zone });
        setResult(response.data.qrCode)
        setSessionId(response.data.sessionId)
        setNeedFetching(false);
    }

    async function check_status() {
        const response = await call_await_fetch(app_path + '/whatsapp/check_session_status', { country_code: market.country_code, time_zone: market.time_zone, sessionId: sessionId });
        if (response.data.error === "You have been logged in successfully!") {
            return setSessionResult(response.data.error)
        }
        else {
            return setNeedFetching(true)
        }
    }


    const obtainQr = () => {
        getqrCode()
        const new_interval = setTimeout(function async() {
            check_status()
        }, 10000)
    }


    useEffect(() => {
        if (!needFetching) return;
        obtainQr()
    }, [needFetching])

    useEffect(() => {
        if (needFetching) return;
        else if (needFetching && sessionResult === "You have been logged in successfully!") {
            clearInterval(interval)
        };
        const interval = setTimeout(function async() {
            check_status()
        },7500)
        return () => clearInterval(interval)
    },[needFetching])
    if (sessionResult === "You have been logged in successfully!"){
        qrCode = false
    }
    return (
        <div className="QrCodeModal">
            <div className="QrCodeModalContent">
                {qrCode && <div className="QrCodeModalBody">
                    <img src={result} />
                </div>}
                {!qrCode && <h4 style={{ color: "black", padding: "20px" }}>{sessionResult}</h4>}
                <div className="QrCodeModalFooter">
                    <Link to="/whatsapp">
                        <button className='qrCodeCloseBtn'>Close</button>
                    </Link>
                </div>
            </div>
        </div>

    )
}

export default QrCodeModal;