import FlowComponent from './FlowComponent';
import React from 'react';

class ReconfirmBoxes extends FlowComponent{
    handlePaste = (event) =>{
        event.preventDefault();
        return false;
    }
    render(){
        return(
            <>
            
                <div className={`form-group ${this.props.className}`}>
                    <span>{this.props.label}</span><font className="text-danger"> * </font>

                    <input id={this.props.id}  style={{textSecurity: "disc", webkitTextSecurity:"disc"}} onPaste={this.handlePaste} onChange={this.props.onChange}  className="form-control" required />

                </div>
                <div className={`form-group ${this.props.className}`}>
                    <span>Re-Enter {this.props.label}</span><font className="text-danger"> * </font>

                    <input id={"reconfirm_"+this.props.id}  onPaste={this.handlePaste} onChange={this.props.onChange} className="form-control" required/>

                </div>
                
            </>
        );
    }
}
export default ReconfirmBoxes;
