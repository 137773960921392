import { useState, useEffect } from "react";
import { Homeacc_prvdrLogoselector, getSelectOptions } from "../../helpers/common_helper";
import DatePicker from 'react-datetime';
import { Form, Button, Card, Modal } from "react-bootstrap";
import { BiCalendar} from "react-icons/bi";
import { BasicReactTable } from "../../helpers/react_table";
import { Config, get } from "../../helpers/storage_helper";
import { dd_value } from "../../actions/common_actions";
import { Link } from "react-router-dom";
import { lbl_amt } from "../../helpers/product_helper";
import { BsUpload } from "react-icons/bs";
import { Biz_operation_live_reports, uploadDupFAProof } from "../../actions/report_actions";
import { useDispatch, useSelector } from "react-redux";
import { setValuesIntoRedux } from "../../Redux/Slices/CommonSlice";
import Softphone from "../cs_management/container/cs_soft_phone";
import moment from "moment";
import CallLog from "../call_log/component/CallLog";
import { StartCallLog } from "../../actions/call_log_actions";

const DuplicateTransactions = () => {
    const [filterLabels, setFilterLabels] = useState([
        {key:"status", label:"Status", type:"duplicate_fa_management_status"},
        {key:"txn_type", label:"Txn Type", type:"duplicate_fa_management_txn_status"},
        {key:"start_date", label:"Start Date", type:"date"},
        {key:"end_date", label:"End Date", type:"date"},
        {key:"search_by", label:"Search By", type:"cust_field"}
    ]) 

    const [columnValues, setColumnValues] = useState([
        {key:"from_acc", label:"A/C Number", show:"all"},
        {key:"cust_id", label:"Customer ID", show:"all"},
        {key:"entity_id", label:"Loan Doc ID / Switch ID", show:"all"},
        {key:"type", label:"Txn Type", show:"all"},
        {key:"txn_date", label:"Txn Date", show:"all"},
        {key:"transferred_amt", label:"Transferred Amt", show:"all"},
        {key:"action", label:"Action", show:"cs"},
        {key:"partially_paid", label:"Partially Paid", show:"csm"},
        {key:"amt_to_recover", label:"Amt to Recover", show:"csm"},
        {key:"status_value", label:"Status", show:"csm"}

    ])

    const [columns, setColumns] = useState([]);

    const [rowData, setRowData] = useState([]);
    
    const [inputValues, setInputValues] = useState({
        start_date :  moment().subtract(1, 'days').format('YYYY-MM-DD'),
        end_date : moment().format('YYYY-MM-DD')
    })

    const [loader, setLoader] = useState(false)
    const [showUploadProof, setShowuploadProof] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState({});
    const [currency_code, setCurrencyCode] = useState("")
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [file, setFile] = useState(null);
    const [uploadDetails, setUploadDeatails] = useState(null)
    const dispatch = useDispatch();
    const datas = useSelector((state) => state.cs_soft_phone)
    const [callPropsData, setcallPropsData] = useState(null)
    const [callLogData, setCallLogData] = useState(null)
    const [openModel, setOpenmodel] = useState(false)

    useEffect(()=>{
        getColumnData();
        setCurrencyCode(get('market').currency_code)
        getData()
        dispatch(setValuesIntoRedux({screen_from:"dup_txn_report"}))
    }, [])

    const getInputValues = (value, key, type = null) => {
        
        if(type == "paymentProof"){
            setUploadDeatails({...uploadDetails, [key]:value})
        }else{
            setInputValues({...inputValues, [key]:value})
        }
    }

    const uploadProof = (val, data = null) => {
        setShowuploadProof(val)
        if(data){
            setPaymentDetails(data)
        }
        if(val == false){
            setUploadDeatails(null)
            setImagePreviewUrl(null)
        }
    }

    const handleImageClick = () => {
        document.getElementById('upload_file').click();
    }

    const onfileChange = (event) => {
        const file = event.target.files[0];
        if(file){
            setFile(file);
        
            const reader = new FileReader();
            reader.onloadend = () => {
            setImagePreviewUrl(reader.result);
            let imagedata = {
                file_type: file['type'],
                file_name: file['name'],
                file_data_type: "data-url",
                file_data:reader.result,
                file_of:"dup_payment_reversal_proof"
            }
            setUploadDeatails({...uploadDetails, ...imagedata})

            };
            reader.readAsDataURL(file);
        }
        
    }

    const getData = () => {
        setLoader(true)
        const ls_market = get('market')
        var req = {country_code: ls_market.country_code, time_zone: ls_market.time_zone, status: ""};
        let data = {report_type:"get_dup_txn_report"}
        if(inputValues){
            data = {...data, ...inputValues}
        }
        Biz_operation_live_reports({...req, ...data})
        .then((response)=>{
            setLoader(false)
			if(!response){return };
			if(response.status === "success"){
				setRowData(response.data)
			}
		});
    }

    const call_handler = (row) => {
        if(get("market").country_code == "UGA"){
            row = {...row, cust_mobile_num :row.mobile_num, duplicate_txn_data:true}
            dispatch(setValuesIntoRedux({cust_id : row.cust_id, outgoing_call_data : row, duplicate_txn_data : row}))
        }else{
            setcallPropsData(row)
            handleCallLog(row.cust_id)
        }
        

    }

    const handleCallLog = (cust_id) =>{
            let target = "outgoing"
            let call_log = {cust_id: cust_id, call_type: target}
            const ls_market = get('market')
            var req = {country_code: ls_market.country_code, time_zone: ls_market.time_zone, status: ""};
            StartCallLog({...req, ...call_log})
                .then((response)=> {
                    if(!response){return };
                    if(response.status === "success"){
                        const  log = response.data
                        var resp = true;
                        if(log.action == "lastcall"){
                            resp = window.confirm(log.message);
                        }
                        if(resp && log.call_log_id){
                            setOpenmodel(true)
                            setCallLogData({cust_id:log.cust_id, call_log_id:log.call_log_id})
                        }
                        }else{
                            alert(response.message  +"\n" + JSON.stringify(response.data));  
                        }
            })
  }


    const getColumnData = () => {
        let role = get("role_codes") == "customer_success_officer" ? "cs" : "csm"
        let filter_folumn = columnValues.filter((val, i) => val.show == 'all' || val.show == role)
        let columns = filter_folumn.map((val, i)=>{return{
            Header:<p className="text-center">{val.label}</p>,
            id:val.key,
            minWidth:(val.key == 'action' || val.key == 'entity_id') ? 200 :"",
            accessor:row => <div>
                                {val.key == 'from_acc' ? 
                                 <div className='d-flex align-items-center justify-content-center'>  <Homeacc_prvdrLogoselector logotype={ row.acc_prvdr_code}/> <p className='text-white m-0 pl-2'>{row[val.key]}</p></div>
                                :
                                val.key == "entity_id" ? 
                                <p className="text-center">{row.type == "float_advance" ? <Link to={"/fa/view/"+row.loan_doc_id} target="_blank" className="on_going">{row.loan_doc_id}</Link> : row.sales_doc_id}</p>
                                :

                                val.key == "action" ? 
                                <div className='d-flex align-items-center justify-content-center'>  
                                    <Button variant="primary" onClick={()=>call_handler(row)}>Log Call</Button>
                                    <Button variant="primary" className="ml-2" onClick={()=>uploadProof(true, row)}>Upload Proof</Button>
                                </div>
                                :
                                val.key == 'cust_id' ? 
                                <div className="text-center"> <Link to={"/borrower/indiv/view/"+row.cust_id} target="_blank" className="flow_custid" >{row.cust_id}</Link>  </div>
                                :
                                val.key == "txn_date" ?
                                <p className="text-center">{moment(row.txn_date).format("DD MMM YYYY")}</p>
                                :
                                (val.key == "transferred_amt" || val.key == "amt_to_recover" || val.key == "partially_paid") ? 
                                <p className="text-center">{lbl_amt(row.transferred_amt ? row.transferred_amt : 0, currency_code)}</p>
                                :
                                <p className="text-center">{val.key == "type" ? dd_value(row.type, 'any') : row[val.key] } </p>
                                }
                            </div>
        }})

        setColumns(columns)
    }

    const handelUploadProofSubmit = () => {
        const ls_market = get('market')
        var req = {country_code: ls_market.country_code, time_zone: ls_market.time_zone, status: ""};
        let entity_id = paymentDetails.type == "float_advance" ? "loan_doc_id" : "sales_doc_id"
        let data = {cust_id : paymentDetails.cust_id, type:paymentDetails.type, entity_code:paymentDetails[entity_id], [entity_id]:paymentDetails[entity_id]}
        if(uploadDetails){
            data = {...data, ...uploadDetails}
        }
        uploadDupFAProof({...req, ...data})
        .then((response)=>{
            setLoader(false)
			if(!response){return };
			if(response.status === "success"){
                alert("Payment uploaded successfully.")
                uploadProof(false)
			}
		});
        
    }

    const handleModal = () => {
        setOpenmodel(false)
    }

    const callModel = () => {
        return(
            <Modal dialogClassName= "rec-form-modal" show={openModel} onHide={()=>handleModal()}>
                <Modal.Body closeButton>
					<CallLog is_duplicate match={{params: {id: callLogData.call_log_id, cust_id: callLogData.cust_id}}} callPropsData = {callPropsData} call_type = {"outgoing"} not_redirect ={true} onCancel={()=>handleModal()}/>
				</Modal.Body>
		    </Modal>
        )
    }

    const updateProofModal = () => {
        return(
            <Modal show={showUploadProof}>
              <Modal.Header closeButton show={showUploadProof} onHide={()=>uploadProof(false)} style={{boxShadow:"0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}} className="pb-3">
                  <Modal.Title>Upload Payment Proof</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row mt-3">
                    <div className="col-6" style={{borderRight:"1px solid #FFFFFF"}}>
                        <span className="dup_txn_text">No of Duplicate FAs <b className="ml-2">{paymentDetails.dup_fa_count ? paymentDetails.dup_fa_count : 0}</b></span>
                    </div>
                    <div className="col-6">
                        <span className="dup_txn_text">No of Duplicate Switches <b className="ml-2">{paymentDetails.dup_switch_count ? paymentDetails.dup_switch_count : 0}</b></span>
                    </div>
                </div>
                <div className="mt-4">
                    <span className="dup_txn_text">Amount to recover <b className="ml-2">{lbl_amt(paymentDetails.amt_to_recover ? paymentDetails.amt_to_recover : 0, currency_code)}</b></span>
                </div>
                
                <div className="mt-4">
                    <label>Enter Txn ID<span style={{color:"red"}} className="ml-1">*</span></label>
                    <Form.Control id="txn_id" autoComplete='off' onChange={(e) => getInputValues(e.target.value,'txn_id', "paymentProof")} />
                </div>
                <div className="centered-content mt-4">
                    <div style={{width:"60%", height:"150px", background:"white", borderRadius:"12px"}} onClick={() => handleImageClick()}>
                        {imagePreviewUrl ? 
                        <img src={imagePreviewUrl} alt="Selected"  style={{width:"100%", height:"100%", borderRadius:"12px"}}/> : 
                        <div style={{width:"100%", height:"100%"}}>
                            <div style={{height:"50%"}} className="centered-content"><BsUpload style={{width:"30px", height:"30px", color:"gray"}}/></div>
                            <div style={{height:"50%"}} className="d-flex justify-content-center"><p style={{color:"gray"}}>Upload the proof of the payment</p></div>
                        </div>}
                        <input type="file" id="upload_file" style={{display:"none"}} onChange={(e)=>onfileChange(e)}/>
                    </div>
                    
                </div>
               

                <div className="form-group row reset-margin justify-content-center py-4">
                    <Button className= "col-md-2 mr-3 btn btn-primary" onClick={()=>handelUploadProofSubmit()} disabled={uploadDetails && uploadDetails.txn_id && uploadDetails.file_name ? false : true}>Submit</Button>
                </div>
                
              </Modal.Body>
          </Modal>
        )
    }

    return (
       <div className="container containerTopmargin max-width no-padding">
            <div className="mx-3">
                {callLogData && callModel()}
                {updateProofModal()}
                <Card>
                    <Card.Header style={{color:"#FFFFFF", background:"#3D3E53", fontSize:"28px"}} className="px-4">Duplicate Transactions</Card.Header>
                    <Card.Body className="ml-3">
                        <div>
                            <div className="row">
                                {filterLabels.map((val, i) =>(
                                    (val.key == "status" && get('role_codes') == 'customer_success_officer') ? null :
                                    <div className={`col-md-6 col-sm-12 ${val.type == "cust_field" ? 'col-lg-6' : 'col-lg-3'} my-3`}>
                                        <div style={{width:"93%"}}>
                                            <fieldset className='h-100 py-0 px-3 new_re_kyc_text_bg_color' style={{ border: '1px solid white', background: "#202940", borderRadius: '10px' }}>
                                                <legend className={`add_watchlist_text float-none `}>
                                                    <b> {val.label} </b>
                                                </legend>
                                                {val.type == 'date' ? 
                                                <div style={{position:"relative", display: 'inline-block', width:"100%" }}>
                                                    <DatePicker placeholderText="select" closeOnSelect={true} onChange={(value) => getInputValues( moment(value).format('YYYY-MM-DD'), val.key)} defaultValue={ moment(inputValues[val.key]).format('DD MMM YYYY')} inputProps={{id:val.key, placeholder:"select"}} dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
                                                    <label className='col-1.5 no-margin d-flex p-0 ' style={{ position: 'absolute', right:"0px", top:"8px" }} htmlFor={val.key}>
                                                        <p className='m-0 pr-1'><BiCalendar size={20} /></p>
                                                    </label>
                                                </div>
                                                : 
                                                val.type == "cust_field" ? 
                                                <Form.Group className="pb-0">
                                                    <Form.Control id={val.key} placeholder='Customer ID, Account Number, Loan Doc ID, Switch ID' autoComplete='off' onChange={(e) => getInputValues(e.target.value,val.key)} >
                                                    </Form.Control>
                                                </Form.Group>
                                                :
                                                <select onChange={(e) => getInputValues(e.target.value, val.key)} className="form-control  w-100 col-12 px-0">
                                                    {getSelectOptions(val.type)}
                                                </select>
                                                }
                                            </fieldset>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="form-group row reset-margin justify-content-center py-4">
                                <Button className= "col-md-1 mr-3 btn btn-primary" onClick={()=>getData()}>Submit</Button>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                {rowData.length > 0 ? 
                <div className="mt-5" style={{display:!loader ?'':'none'}}>	
                    <p className='no-margin text-white mb-2'><b>Total Entries : {rowData.length}</b></p>
                    <div>
                        <div>
                            <BasicReactTable pagination={rowData && rowData > 9 ? true : false} row_data={rowData ? rowData : []} column_data={columns} default_page_size={10}/> 
                        </div>
                    </div>					
                     				
                </div>
                :
                !loader && 
                <p className='text-center m-0 text-white my-2'>NO DATA</p>
                }

                <div style={{display:loader ?'':'none'}}>
                    <div style={{marginTop:"15%"}} > <p className='text-light mt-3 text-center'>Loading...</p>
                        <div className="product_takeup_loader"></div>
					</div>
                </div>
                {datas.duplicate_txn_data && 
                <Softphone screen_from ={"dup_txn_report"} is_direct_outgoing = {true} cust_id={datas.duplicate_txn_data.cust_id ?datas.duplicate_txn_data.cust_id:null} outgoing_call_data = {datas.duplicate_txn_data}/>
                }
                
            </div>
       </div>
    )
}

export default DuplicateTransactions;