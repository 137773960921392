import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { AdminHome} from './AdminHome';
import { GiClick } from 'react-icons/gi';
import {get,get_item,check_priv} from './../../helpers/storage_helper';
import './../../styles/flow.css';
import {MarketRoutes, LenderRoutes, DataPrvdrRoutes, AccPrvdrRoutes, CSFRoutes,ProductRoutes, MasterDataRoutes,settlement} from "./../../routes/admin_routes";
import {CustomerRoutes, FLOWRelMgrRoutes, DPRelMgrRoutes, LoanApplRoutes, LoanRoutes} from "./../../routes/user_routes";
import SelectMarket from './SelectMarket';
import Logo from './../common/partials/logo';
import {touch} from "../../actions/common_actions";
import CommonComponent from "../common/CommonComponent";
import { Homeacc_prvdrLogoselector, getValueFromLangJson } from '../../helpers/common_helper';
import { getAgentDetails, getRetrevelAccs } from '../../actions/account_actions';
import { updateCSDetails } from '../cs_management/container/cs_soft_phone';
import CommonModal from '../common/component/CommonModal';
import { MdRemoveRedEye } from "react-icons/md";
import { Modal } from 'react-bootstrap';
export class AdminMenu extends CommonComponent {
  state = {
    toSelectMarket:false,
    error_data:'',
    retrieved_name:'',
    loader:false,
    mobile_num:'',
    retrvl_acc:'',
    showModal:false

  }

  server_time(time_zone) {
      return touch({country_code: this.country_code, time_zone: time_zone})
          .then((response) => {
                  if (!response) {
                      return false
                  }
                  ;

                  if (response.status === "success") {
                      let date = new Date(response.server_time_db)
                      this.setState({date: date})
                  }
              }
          );
  }


  componentWillMount(){
    
    // this.selectMarket = this.selectMarket.bind(this);
    const market = get('market');
    this.country_code = market.country_code; 
    this.acc_prvdr_code = get_item('acc_prvdr_code')
    this.app_version = get('app_version');
    this.user_name = get('user_name');
    this.prv = get('privileges');
    this.roles = get('role_codes');
    console.log(' this.prv',  this.prv)

    this.acc_prvdr_logo = get_item('acc_prvdr_logo');

    Object.keys(this.prv).map((key) => {
      const priv_tokens = this.prv[key].split(',')
      this[key] = {};      
      
      priv_tokens.map((priv) => {
        this[key][priv] = true;
      });

    });
    // this.server_time(market.time_zone)
    // this.run_clock()

    this.get_retrieval_accs()
  }
  Data
  //
  //     if(get('role_codes') ==="super_admin"){
  //       this.toChange = "market";
  //       this.setState({toSelectMarket:true});
  //     }
  //   }
  //
  //
  //
  handlenameSearch=()=>{
    var request={
      data:{
        acc_prvdr_code:this.state.retrvl_acc,
        acc_number:this.state.mobile_num
      }
    }
    if(this.state.mobile_num[0]==0){
      alert('Please remove the zero in the prefix')
    }
    else{
      this.setState({loader:true,error_data:'',retrieved_name:''})
      getAgentDetails(this.req(request))
      .then((response)=>{
        if(!response){this.setState({error_data:'No Data Found',loader:false})
        };
        if(response && response.status=="success"){
          if(response.data.status=='failed'){
            this.setState({error_data:response.data.error,loader:false})
          }
          else{
            this.setState({retrieved_name:response.data.full_name,loader:false})
          }
        }
    })
    }
   
  }

  get_retrieval_accs = ()=>{
    this.setOptionsListToStateFromAppApi('common', 'retrieval_accounts', {country_code : this.country_code}, ['acc_prvdr_code', 'name'], "retrievel_accs");
  }
  handleclose=()=>{
    this.setState({mobile_num:'',error_data:'',retrieved_name:'',name_verification:false})}

    toggleModal = () => {
      this.setState({ showModal: !this.state.showModal });
    }
  render(){
  //       if(this.state.toSelectMarket){
  //        return (<SelectMarket toChange={this.toChange} action="change"/>);
  //       }

    const apply_fa_menu = ["customer_success", "customer_success_officer"]

    return (
    
      <>


    <div className="sidebar sidebar-rewidth" data-color="purple" data-background-color="default" >
      <div className="logo">
        <Link to={"/"} className="simple-text logo-mini">
          <img src="/icon_new.png" width="34" />
        </Link>
        <Link to={"/"} className="simple-text logo-normal" ><img src="/logo_white_new.png" width="100"  /></Link>
      </div>
      <div className="sidebar-wrapper sidebar-wrapper-rewidth">
        <div className="user">
          <div className="photo">
            <img src="/avatar.png" />
          </div>
          <div className="user-info">
            <a data-toggle="collapse" href="#collapseExample" className="username">
              <span>
              &nbsp;&nbsp;{this.user_name}<br/>
                &nbsp;&nbsp;{this.roles}
                {/* <b className="caret"></b> */}
              </span>
            </a>
            {/* <div className="collapse" id="collapseExample">
              <ul className="nav">
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    <span className="sidebar-mini"> MP </span>
                    <span className="sidebar-normal"> My Profile </span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    <span className="sidebar-mini"> EP </span>
                    <span className="sidebar-normal"> Edit Profile </span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    <span className="sidebar-mini"> S </span>
                    <span className="sidebar-normal"> Settings </span>
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
        <ul className="nav">
          {this.roles != "investor" &&
            <li className="nav-item">
            <Link to="/" className="nav-link">
            <i className="material-icons">home</i>
            <p> {getValueFromLangJson('home')} </p>
            </Link>
            </li>
          }
          {this.mgmt &&
              <>
              {check_priv("mgmt", "live_dashboard") &&
                  <li className="nav-item ">
                    <Link to="/management-dashboard" className="nav-link">
                      <i style={{"marginTop" : "-5px", "marginLeft" : "0px", "padding" : ""}}><img style={{"width" : "30px", "height" : "35px"}} src={"/img/live-white.png"}/></i>
                      <p> Live Dashboard </p>
                    </Link>
                  </li>
          }
          {check_priv("mgmt","monthly_dashboard")  &&
              <li className="nav-item ">
                <Link to="/monthly-report" className="nav-link">
                  <i className="material-icons">calendar_month</i>
                  <p> Monthly Dashboard </p>
                </Link>
              </li>
          }

          {check_priv("mgmt","charts")  &&
              <li className="nav-item ">
                <Link to="/charts" className="nav-link">
                  <i className="material-icons">bar_chart</i>
                  <p> Charts </p>
                </Link>
              </li>
          }
          {/* <li className='nav-item'>
            <Link to='/fresh_chat_chart' className="nav-link">
            <i className="material-icons">data_exploration</i>
                  <p> Freschat Chart </p>
            </Link>

          </li> */}
          </>
          }
          {check_priv("report","coo_dashboard")  &&
          <li className="nav-item ">
            <Link to="/rm_performance" className="nav-link">
              <i style={{"marginTop" : "-5px", "marginLeft" : "0px", "padding" : ""}}><img style={{"width" : "30px", "height" : "30px"}}  src="/img/Personal Growth.png" width="34" /></i>
              <p> RM Performance </p>
            </Link>
          </li>
          }
          {check_priv('application', 'apply') && apply_fa_menu.includes(this.roles) &&
            <li className="nav-item active ">
              <Link to="/fa_appl" className="nav-link">
                <i className="material-icons">touch_app</i>
                <p> Apply FA </p>
              </Link>
            </li>
          }
           {check_priv('customer', 'location') &&
            <li className="nav-item  ">
              <Link to="/cust_location" className="nav-link">
                <i className="material-icons">location_on</i>
                <p>Customer Locations </p>
              </Link>
            </li>
           }

          {check_priv('customer', 'flag_report') &&
            <li className="nav-item  ">
              <Link to="/flagged_customers" className="nav-link">
                <i className="material-icons">flag</i>
                <p>Flagged Customers </p>
              </Link>
            </li>
           }

          { check_priv('customer', 'watchlist_reports') &&
            <li className="nav-item  ">
              <Link to="/watchlist_customers" className="nav-link">
                <i className="material-icons">remove_red_eye</i>
                <p>Watchlist Customers </p>
              </Link>
            </li>
           }
          {/* {check_priv('customer', 'watchlist_reports') &&
            <li className="nav-item  ">
              <Link to="/watchlist_customers" className="nav-link">
                <i className="material-icons m-0 mr-4"><MdRemoveRedEye color="white" /></i>
                <p className=''>Watchlist Customers </p>
              </Link>
                
            </li>
           } */}

          <li className="nav-item ">
            <a className="nav-link" data-toggle="collapse" href="#search">
              <i className="material-icons">search</i>
              <p> {getValueFromLangJson('search')}
                <b className="caret"></b>
              </p>
            </a>
            <div className="collapse" id="search">
              <ul className="nav">
              {this.customer && this.customer.search_list_view && 
                <li className="nav-item ">
                  {/* <a className="nav-link" href="/borrower/search"> */}
                   <Link  className='nav-link' to={'/borrower/search'}> <span className="sidebar-normal">  {getValueFromLangJson('search_customer')} </span> </Link> 
                  {/* </a> */}
                </li>
              }
             
              {this.loan && this.loan.search_list_view &&
                <li className="nav-item ">
                  {/* <a className="nav-link" href="/fa/search"> */}
                    <Link className='nav-link' to={"/fa/search"}><span className="sidebar-normal"> {getValueFromLangJson('search_float_advance')} </span></Link>
                  {/* </a> */}
                </li>
              }
              {this.application && this.application.search_list_view &&
                <li className="nav-item ">
                  {/* <a className="nav-link" href="/fa_appl/search"> */}
                  <Link className='nav-link' to={"/fa_appl/search"}><span className="sidebar-normal"> {getValueFromLangJson('search_fa_appilcations')}  </span></Link>
                    
                  {/* </a> */}
                </li>
              }
              {this.field_visit && (this.field_visit.all_search || this.field_visit.self_search)  &&
                <li className="nav-item ">
                  {/* <a className="nav-link" href="/field_visit/search"> */}
                  <Link className='nav-link' to={"/field_visit/search"}><span className="sidebar-normal"> Search Customer Visit </span></Link>
                    
                  {/* </a> */}
                </li>
              }
              {this.call_log && (this.call_log.all_search || this.call_log.self_search)  &&
                <li className="nav-item ">
                  {/* <a className="nav-link" href="/call_log/search"> */}

                  <Link className='nav-link' to={"/call_log/search"}><span className="sidebar-normal"> Search Call Log </span></Link>
                    
                  {/* </a> */}
                </li>
              }
              {check_priv("lead","search")  &&
                <li className="nav-item ">
                  {/* <a className="nav-link" href="/lead/search"> */}
                  <Link className='nav-link' to={"/lead/search"}><span className="sidebar-normal"> Search Lead </span></Link>
                    
                  {/* </a> */}
                </li>
              }
              <>

              {(get("role_codes") != 'loan_approver' && check_priv("toll_free_call", "search")) &&
              <li className="nav-item ">
                    {/* <a className="nav-link" href="/inbound_call_list"> */}
                    <Link className='nav-link' to={"/inbound_call_list"}>
                      <span className="sidebar-normal">Search Toll Free Call List </span>
                    </Link>
                    {/* </a> */}
              </li>
              }
              {(get("role_codes") != 'loan_approver' && check_priv("re_kyc", "search")) &&

              <li className="nav-item ">
                    {/* <a className="nav-link" href="/search_rekyc_leads"> */}
                      <Link className='nav-link' to={"/search_rekyc_leads"}><span className="sidebar-normal">Search Re-KYC </span></Link>
                    {/* </a> */}
              </li>
              }

              {/* {check_priv("report","mgmt_dashboard_monthly")  && */}
              {(get("role_codes") != 'loan_approver' && check_priv("churns", "search")) &&
              <li className="nav-item ">
                    {/* <a className="nav-link" href="/churn_reports"> */}
                      <Link className='nav-link' to={"/churn_reports"}><span className="sidebar-normal"> Search Churns </span></Link>
                    {/* </a> */}
                  </li>
              }
                {/* } */}
              
              </>
              

 
              {check_priv("prdt_upgrade","search")  &&
              <li className="nav-item ">
                {/* <a className="nav-link" href="/search_product_upgrade"> */}
                  <Link className='nav-link' to={"/search_product_upgrade"}><span className="sidebar-normal">Search Product Upgrades </span></Link>
                {/* </a> */}
              </li>
              }
              
              </ul>
            </div>
          </li>

          
          {check_priv('delinquency','recovery_task') &&
            <li className="nav-item ">
                <a className="nav-link" data-toggle="collapse" href="#Overdue_recoveries">
                  <i className="material-icons"></i>
                  <p> Recovery Tasks
                    <b className="caret"></b>
                  </p>
                </a>
                <div className="collapse" id="Overdue_recoveries">
                  <ul className="nav">
                    <li className="nav-item ">
                      {/* <a className="nav-link" href="/call_tasks"> */}
                        <Link className='nav-link' to={"/call_tasks"}><span className="sidebar-normal"> Overdue Recoveries </span></Link>
                      {/* </a> */}
                    </li>
                    
                    
                      <li className="nav-item ">
                        {/* <a className="nav-link" href="/pending_rm_visits"> */}
                          <Link className='nav-link' to={"/pending_rm_visits"}><span className="sidebar-normal"> Pending RM Visits</span></Link>
                        {/* </a> */}
                      </li>
                    
                    
                  </ul>
                </div>
              </li>
            }
         

          {check_priv('sales','search_list_view') &&
          <li className="nav-item ">
              <a className="nav-link" data-toggle="collapse" href="#switch_report">
                <i className="material-icons"></i>
                <p> Switch
                  <b className="caret"></b>
                </p>
              </a>
              <div className="collapse" id="switch_report">
                <ul className="nav">
                  {check_priv('report','switch_report') &&

                      <li className="nav-item ">
                      {/* <a className="nav-link" href="/switch_reports"> */}
                        <Link className='nav-link' to={"/switch_reports"}><span className="sidebar-normal"> Float Switch Reports </span></Link>
                      {/* </a> */}
                      </li>

                  }

                   
                 
                    <li className="nav-item ">
                      {/* <a className="nav-link" href={`/sales/report`}> */}
                       <Link className='nav-link' to={"/sales/report"}><span className="sidebar-normal"> Search Float Switch </span></Link>
                      {/* </a> */}
                    </li>
                  
                </ul>
              </div>
            </li>
            }
          
          {check_priv('swap','swap_accounts') &&
          <li className="nav-item ">
                <Link to="/switch/disb_account" className="nav-link">
                  <p>  Swap Account Lines </p>
                </Link>
              </li>

          }

          {check_priv("biz_ops","live_dashboard")  &&
              <li className="nav-item ">
                <a className="nav-link" data-toggle="collapse" href="#business_op">
                  {/* <Link to="/business_operations" className="nav-link"> */}
                  {/* <i className="material-icons">business_center</i> */}
                    <p> Business Operations 
                      <b className="caret"></b>
                    </p>
                  {/* </Link> */}
                </a>
                <div className="collapse" id="business_op">
                  <ul className="nav">
                    <li className="nav-item ">
                       {/* <a className='nav-link' href='/business_operations/live'> */}
                        <Link className='nav-link' to={"/business_operations/live"}><span className="sidebar-normal">Live</span></Link>
                       {/* </a> */}
                    </li>
                    <li className="nav-item ">
                      {/* <a className='nav-link' href='/business_operations/past'> */}
                        <Link className='nav-link' to={"/business_operations/past"}><span className="sidebar-normal">Past</span></Link>
                      {/* </a> */}
                    </li>
                  </ul>
                </div>
              </li>
              }

            {check_priv("churn_mgnt", "churns") && this.country_code == 'UGA' &&
              <li className="nav-item ">
                <Link to="/cust_follow_up" className="nav-link">
                  <i className="material-icons"></i>
                  <p> Churn Management </p>
                </Link>
              </li>
            }

    {check_priv("biz_ops","live_dashboard")  &&
              <li className="nav-item ">
                <a className="nav-link" data-toggle="collapse" href="#it_op">
                  {/* <Link to="/business_operations" className="nav-link"> */}
                  {/* <i className="material-icons">business_center</i> */}
                    <p> IT Operations 
                      <b className="caret"></b>
                    </p>
                  {/* </Link> */}
                </a>
                <div className="collapse" id="it_op">
                  <ul className="nav">
                    <li className="nav-item ">
                       {/* <a className='nav-link' href='/it_operations/live'> */}
                        <Link className='nav-link' to={"/it_operations/live"}><span className="sidebar-normal">Live</span></Link>
                       {/* </a> */}
                    </li>
                    <li className="nav-item ">
                      {/* <a className='nav-link' href='/it_operations/past'> */}
                        <Link className='nav-link' to={"/it_operations/past"}><span className="sidebar-normal">Past</span></Link>
                      {/* </a> */}
                    </li>
                  </ul>
                </div>
              </li>
              }

           

          {check_priv("cs_mgnt", "softphone_mngt") && this.country_code == 'UGA' &&
          <li className="nav-item ">
            <a className="nav-link" data-toggle="collapse" href="#cs_management">
              <p> Softphone Management
                <b className="caret"></b>
              </p>
            </a>
            <div className="collapse" id="cs_management">
              <ul className="nav">
              
              {check_priv("cs_mgnt", "cs_rostets") && this.country_code == 'UGA' &&

              <li className="nav-item ">
                  {/* <a className="nav-link" href="/cs_management/cs_rosters"> */}
                   <Link className='nav-link' to={"/cs_management/cs_rosters"}> <span className="sidebar-normal"> Rosters </span> </Link> 
                  {/* </a> */}
                </li>
              }
              {check_priv("cs_mgnt", "cs_rmso_call_logs") && this.country_code == 'UGA' &&

                  <li className="nav-item ">
                      {/* <a className="nav-link" href="/inbound_call_list"> */}
                        <Link className='nav-link' to={"/inbound_call_list"}><span className="sidebar-normal">CS & RMSO Call Logs </span></Link>
                      {/* </a> */}
                  </li>
              }

                <li className="nav-item ">
                      <Link className='nav-link' to={"/activity_report"}><span className="sidebar-normal">CS Activity Report </span></Link>
                   
                </li>
             
                </ul>
            </div>
          </li>
          }
          {check_priv("cs_mgnt", "soft_phone")  && 
           <li className="nav-item ">
           <Link to="/cs_managements/soft_phone" className="nav-link" onClick={async()=>{await updateCSDetails({logout:false})}}>
             <i className="material-icons"></i>
             <p> Soft Phone </p>
           </Link>
         </li>
            
          }

          {check_priv("biz_ops","reports")  && 
          <li className="nav-item ">
            <a className="nav-link" data-toggle="collapse" href="#biz_ops">
              <i className="material-icons"></i>
              <p> Biz Ops
                <b className="caret"></b>
              </p>
            </a>
            <div className="collapse" id="biz_ops">
              <ul className="nav">
              
                <li className="nav-item ">
                  {/* <a className="nav-link" href="/stmt_imports/search"> */}
                    <Link className='nav-link' to={"/stmt_imports/search"}><span className="sidebar-normal"> Search Statement Imports </span></Link>
                  {/* </a> */}
                </li>
              
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/sms_logs"> */}
                      <Link className='nav-link' to={"/sms_logs"}><span className="sidebar-normal"> Search SMS logs</span></Link>
                    {/* </a> */}
                  </li>    
                <li className="nav-item ">
                    {/* <a className="nav-link" href="/report/sms_report"> */}
                      <Link className='nav-link' to={"/report/sms_report"}><span className="sidebar-normal"> SMS Report </span></Link>
                    {/* </a> */}
                  </li>
          <li className="nav-item ">
                      {/* <a className="nav-link" href="/switch_sms_vendors"> */}
                       <Link className='nav-link' to={"/switch_sms_vendors"}><span className="sidebar-normal"> SMS Vendors </span></Link> 
                      {/* </a> */}
                    </li>
                </ul>
              </div>
            </li>
          }  

     
          {this.report &&
          <li className="nav-item ">
            <a className="nav-link" data-toggle="collapse" href="#reports">
              <i className="material-icons"></i>
              <p> {getValueFromLangJson('reports')} 
                <b className="caret"></b>
              </p>
            </a>
            <div className="collapse" id="reports">
              <ul className="nav">

              {this.report.par_health_report && <li className="nav-item ">
                    {/* <a className="nav-link" href="/report/par_health_report"> */}
                      <Link className='nav-link' to={"/report/par_health_report"}><span className="sidebar-normal"> Portfolio Health Report</span></Link>
                    {/* </a> */}
                  </li>}

                {this.report.flow_kpi_dashboard && 
                <>
                 
                 

                  {/* <li className="nav-item ">
                    <a className="nav-link" href="/report/monthly_kpi_report">
                      <span className="sidebar-normal"> Monthly KPI Report </span>
                    </a>
                  </li>
                  <li className="nav-item ">
                    <a className="nav-link" href="/report/current_kpi_report">
                      <span className="sidebar-normal"> Current KPI Report </span>
                    </a>
                  </li> */}
                  {this.report.risk_category &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/report/risk_report"> */}
                      <Link className='nav-link' to={"/report/risk_report"}><span className="sidebar-normal"> Risk Category Report </span></Link>
                    {/* </a> */}
                  </li>
                  }
                  {this.report.lead_conversion &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/report/lead_conversion"> */}
                      <Link className='nav-link' to={"/report/lead_conversion"}><span className="sidebar-normal"> Lead Conversion Report</span></Link>
                    {/* </a> */}
                  </li>
                  }

                </>
                }
                {check_priv("report","self_reg_report") &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/report/self_registration_report"> */}
                      <Link className='nav-link' to={"/report/self_registration_report"}><span className="sidebar-normal"> Self Registration Report  </span></Link>
                    {/* </a> */}
                  </li>}

                { get("role_codes") != "loan_approver" &&
                  <>
                 
                  {check_priv("report","success_rate_report")  &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/report/call_log_reports"> */}
                      <Link className='nav-link' to={"/report/call_log_reports"}><span className="sidebar-normal"> Toll Free Success Report </span></Link>
                    {/* </a> */}
                  </li>
                  }
                  </>
                }
                  
                {this.report.product_takeup &&  
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/report/product_takeup"> */}
                      <Link className='nav-link' to={"/report/product_takeup"}><span className="sidebar-normal"> Product Takeup Report </span></Link>
                    {/* </a> */}
                  </li>
                } 
                {check_priv("report","penalty_pending_rpt")  && get('role_codes') == "app_support"  &&
                  <li className="nav-item ">
                      {/* <a className="nav-link" href="/report/penalty_pending_report"> */}
                        <Link className='nav-link' to={"/report/penalty_pending_report"}><span className="sidebar-normal"> Penalty Pending Report </span></Link>
                      {/* </a> */}
                  </li>
                }
                {this.report.daily_activity && 
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/report/daily_activity"> */}
                     <Link className='nav-link' to={"/report/daily_activity"}><span className="sidebar-normal"> Daily Activity Report </span></Link> 
                    {/* </a> */}
                  </li>
                }
                {this.report.portfolio_quality && 
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/report/portfolio_quality"> */}
                      <Link className='nav-link' to={"/report/portfolio_quality"}><span className="sidebar-normal"> Portfolio Quality </span></Link>
                    {/* </a> */}
                  </li>
                }
                  {check_priv("report","cashbacks_report")  &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/cashbacks_report"> */}
                      <Link className='nav-link' to={"/cashbacks_report"}><span className="sidebar-normal">  Cashbacks Reports </span></Link>
                    {/* </a> */}
                  </li>
                }
                {/* {this.report.growth_chart && 
                  <li className="nav-item ">
                    <a className="nav-link" href="/report/growth_chart">
                      <span className="sidebar-normal"> Growth Chart </span>
                    </a>
                  </li>
                } */}
                {this.report.capital_funds &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/report/capital_funds"> */}
                      <Link className='nav-link' to={"/report/capital_funds"}><span className="sidebar-normal"> Capital Funds </span></Link>
                    {/* </a> */}
                  </li>
                }
               
                {this.report.risk_portfolio&&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/portfolio_at_risk"> */}
                     <Link className='nav-link' to={"/portfolio_at_risk"}><span className="sidebar-normal"> Historical PAR Report </span></Link> 
                    {/* </a> */}
                  </li>
                }
                  {this.report.cust_performance &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/customer_performance"> */}
                      <Link className='nav-link' to={"/customer_performance"}><span className="sidebar-normal"> Customer Performance </span></Link>
                    {/* </a> */}
                  </li>
                }
                  {this.report.os_advance &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/outstanding_advances"> */}
                      <Link className='nav-link' to={"/outstanding_advances"}><span className="sidebar-normal"> Outstanding FAs </span></Link>
                    {/* </a> */}
                  </li>
                }
                 {check_priv("report","partial_payment") &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/partial_payments"> */}
                      <Link className='nav-link' to={"/partial_payments"}><span className="sidebar-normal"> Partial Payments</span></Link>
                      {/* </a> */}
                  </li>
                }
                
                {check_priv("report","cohort_report")&&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/cohort_report"> */}
                      <Link className='nav-link' to={"/cohort_report"}><span className="sidebar-normal"> Recovery Cohort Report </span></Link>
                    {/* </a> */}
                  </li>
                }
                {check_priv("report","ontime_pay_report")&&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/report/ontime_pay_report"> */}
                      <Link className='nav-link' to={"/report/ontime_pay_report"}><span className="sidebar-normal"> Ontime Payment  </span></Link>
                    {/* </a> */}
                  </li>
                }   
                
                {check_priv("report","overdue_report")&&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/overdue_report"> */}
                      <Link className='nav-link' to={"/overdue_report"}><span className="sidebar-normal">{getValueFromLangJson('overdue_fas')} </span></Link>
                    {/* </a> */}
                  </li>
                }

                {check_priv("report","fsdu")&&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/report/fsdu"> */}
                     <Link className='nav-link' to={"/report/fsdu"}><span className="sidebar-normal">Lender Report</span></Link> 
                    {/* </a> */}
                  </li>
                }
                  {
                    check_priv("report","scheduler_list_view")  &&
                      <li className="nav-item ">
                        <Link to="/scheduler/list" className="nav-link">
                        <span className="sidebar-normal"> Schedulers</span>
                        </Link>
                      </li>
                  }

               { check_priv("report","rm_rms_calls")  &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/rm_rms_call_report"> */}
                      <Link className='nav-link' to={"/rm_rms_call_report"}><span className="sidebar-normal"> RM / RMS Overdue Calls </span></Link>
                    {/* </a> */}
                  </li>
                }
                { check_priv("report","rm_visit")  &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/rm_visits_report"> */}
                      <Link className='nav-link' to={"/rm_visits_report"}><span className="sidebar-normal"> RM Overdue Visits </span></Link>
                    {/* </a> */}
                  </li>
                 }
                
                  {/* <li className="nav-item ">
                  <a className="nav-link" href="/report/disbursal_report">
                    <span className="sidebar-normal"> Disbursal Report </span>
                  </a>
                </li>                 */}
                {check_priv("report","mgmt_dashboard_live")  &&
                    <>
                      <li className="nav-item ">
                        {/* <a className="nav-link" href="/management-dashboard"> */}
                          <Link className='nav-link' to={"/management-dashboard"}><span className="sidebar-normal"> Management Dashboard </span></Link>
                        {/* </a> */}
                      </li>
                      <li className="nav-item ">
                        {/* <a className="nav-link" href="/charts"> */}
                          <Link className='nav-link' to={"/charts"}><span className="sidebar-normal"> Charts </span></Link>
                        {/* </a> */}
                      </li>
                    </>
                }
                {check_priv("report","dup_txn_report")  &&
                 <li className="nav-item ">
                    {/* <a className="nav-link" href="/monthly-report"> */}
                      <Link className='nav-link' to={"/duplicate-txns-report"}><span className="sidebar-normal"> Duplicate Transactions </span></Link>
                    {/* </a> */}
                  </li>
                  }
                  {check_priv("report","mgmt_dashboard_monthly")  &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/monthly-report"> */}
                      <Link className='nav-link' to={"/monthly-report"}><span className="sidebar-normal"> Monthly Report </span></Link>
                    {/* </a> */}
                  </li>
                }

                



                    
              </ul>
            </div>
          </li>
          }
          {
            check_priv("settlement","summary")  &&
              <li className="nav-item ">
                <Link to="/settlement/list" className="nav-link">
                  <p>  Account Settlement </p>
                </Link>
              </li>
          }
        

          {check_priv("cust","raised_complaints")  &&
              <li className="nav-item ">
                <Link to="/complaint_lists" className="nav-link">
                  <p>  Complaints </p>
                </Link>
              </li>
          }

          

      {check_priv("cust","name_verify")  &&
        <li className="nav-item ">
                <a type='button' className="nav-link"  data-toggle="modal" data-target="#ccaNameVerificationModal" onClick={()=>{this.setState({name_verification:true})}}> 
                <p>Name Verification</p> 
                </a>
        </li>
        }
           {(check_priv("rm_mgnt","rm_target") || check_priv('rm_mgnt','rm_assign')) &&
          <li className="nav-item ">
            <a className="nav-link" data-toggle="collapse" href="#assign">
              <i className="material-icons"></i>
              <p> RM Management
                <b className="caret"></b>
              </p>
            </a>
            <div className="collapse" id="assign">
              <ul className="nav">
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/assign/rm_target"> */}
                      <Link className='nav-link' to={"/assign/rm_target"}><span className="sidebar-normal"> RM Targets </span></Link>
                    {/* </a> */}
                  </li>
              </ul>
              <ul className="nav">
              {this.report.rm_report &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/report/rm_productivity_report"> */}
                      <Link className='nav-link'  to={"/report/rm_productivity_report"}><span className="sidebar-normal"> RM Productivity Report</span></Link>
                    {/* </a> */}
                  </li>
               }
              </ul>
              <ul className="nav">
              {this.report.rm_report &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/report/rm_report"> */}
                      <Link className='nav-link' to={"/report/rm_report"}><span className="sidebar-normal">RM Wise Repayment Report</span></Link>
                    {/* </a> */}
                  </li>
                }
              </ul>
              {check_priv('rm_mgnt','rm_assign') &&
              <ul className="nav">
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/reassign"> */}
                      <Link className='nav-link' to={"/reassign"}><span className="sidebar-normal"> RM Reassignment </span></Link>
                    {/* </a> */}
                  </li>    
              </ul>
              }

              {check_priv('rm', 'routes') &&
              <ul className="nav">
              <li className="nav-item ">
                    <Link to="/rm_live_locations" className="nav-link">
                      <span className="sidebar-normal">RM Live Locations</span>
                    </Link>
              </li>
              </ul>}
                
              {check_priv('rm', 'routes') &&
              <ul className="nav">
              <li className="nav-item ">
                    <Link to="/rm_live_routes" className="nav-link">
                      <span className="sidebar-normal">RM Live Routes</span>
                    </Link>
              </li>
              </ul>}
            </div>
          </li>   
         }
          { ((this.report && this.report.daily_activity_sipem) || (this.report && this.report.daily_activity_admin)) &&
            <li className="nav-item ">
              <a className="nav-link" data-toggle="collapse" href="#operations">
                <i className="material-icons"></i>
                <p> {getValueFromLangJson('daily_operations')}
                  <b className="caret"></b>
                </p>
              </a>
              <div className="collapse" id="operations">
                <ul className="nav">
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/report/overdue_fa_repayments"> */}
                     <Link className='nav-link' to={"/report/overdue_fa_repayments"}><span className="sidebar-normal">{getValueFromLangJson('overdue_fa_repayments')}</span></Link> 
                    {/* </a> */}
                  </li>
                  {  this.report.daily_activity_admin &&
                   <li className="nav-item ">
                   {/* <a className="nav-link" href="/report/daily_agreements"> */}
                     <Link className='nav-link' to={"/report/daily_visits"}><span className="sidebar-normal">Daily Visits </span></Link>
                   {/* </a> */}
                 </li>
                 }
                

                 {  this.report.daily_activity_admin &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/report/daily_agreements"> */}
                      <Link className='nav-link' to={"/report/daily_agreements"}><span className="sidebar-normal"> Daily Agreements </span></Link>
                    {/* </a> */}
                  </li>
                  }
                </ul>
              </div>
            </li>
          
          }
          
      {check_priv("application","list_pre_appr") &&
          <li className="nav-item ">
            <a className="nav-link" data-toggle="collapse" href="#pre_appr">
              <i className="material-icons"></i>
              <p> Customer Filters 
                <b className="caret"></b>
              </p>
            </a>
            <div className="collapse" id="pre_appr">
              <ul className="nav">
                <li className="nav-item ">
                  {/* <a className="nav-link" href="/pre_appr/list"> */}
                    <Link className='nav-link' to={"/pre_appr/list"}><span className="sidebar-normal"> Pre-approved Customers </span></Link>
                  {/* </a> */}
                </li>
              </ul>
            </div>
            <div className="collapse" id="pre_appr">
              <ul className="nav">
                <li className="nav-item ">
                  {/* <a className="nav-link" href="/active_cust_wo_fa/list"> */}
                    <Link className='nav-link' to={"/active_cust_wo_fa/list"}><span className="sidebar-normal"> Active Customers without FA </span></Link>
                  {/* </a> */}
                </li>
              </ul>
            </div>
            <div className="collapse" id="pre_appr">
              <ul className="nav">
                <li className="nav-item ">
                  {/* <a className="nav-link" href="/aggrement_renewal/list"> */}
                    <Link className='nav-link' to={"/aggrement_renewal/list"}><span className="sidebar-normal">  Agreement  Renewal </span></Link>
                  {/* </a> */}
                </li>
              </ul>
            </div>
          </li>
            }
          {(check_priv("lead","create") || check_priv('lead','lead_pipeline')) && 
            <li className="nav-item ">
              <a className="nav-link" data-toggle="collapse" href="#create_lead">
                <i className="material-icons"></i>
                <p> Lead
                  <b className="caret"></b>
                </p>
              </a>
              <div className="collapse" id="create_lead">
                <ul className="nav">
                  {check_priv('lead','create') && 
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/lead/create"> */}
                      <Link className='nav-link' to={"/lead/create"}><span className="sidebar-normal"> Create Lead </span></Link>
                    {/* </a> */}
                  </li>
                  }
                {check_priv('lead','lead_pipeline')&& 
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/lead/lead_pipeline"> */}
                      <Link className='nav-link' to={"/lead/lead_pipeline"}><span className="sidebar-normal"> Lead Pipeline </span></Link>
                    {/* </a> */}
                  </li>
                  }
                </ul>
              </div>
            </li>
          }
          
          {this.recon &&
            <li className="nav-item ">
              <a className="nav-link" data-toggle="collapse" href="#recon">
                <i className="material-icons"></i>
                <p> Flow's Float Accounts
                  <b className="caret"></b>
                </p>
              </a>
              <div className="collapse" id="recon">
                <ul className="nav">

               

                {this.recon.search &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/lender/accounts"> */}
                      <Link className='nav-link' to={"/lender/accounts"}><span className="sidebar-normal"> Statement </span></Link>
                    {/* </a> */}
                  </li>    
                }
                {this.recon.add_stmt &&
                <li className="nav-item ">
                  {/* <a className="nav-link" href="/statement/add"> */}
                    <Link className='nav-link' to={"/statement/add"}><span className="sidebar-normal">Add Transaction</span></Link>
                  {/* </a> */}
                </li>   
                } 
                {this.recon.search &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/unknown_txns"> */}
                      <Link className='nav-link' to={"/unknown_txns"}><span className="sidebar-normal"> Unknown Transactions </span></Link>
                    {/* </a> */}
                  </li>    
                }
                {this.recon.search &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/unimported_txns"> */}
                      <Link className='nav-link' to={"/unimported_txns"}><span className="sidebar-normal"> UnImported Transactions </span></Link>
                    {/* </a> */}
                  </li>    
                }                
                 {this.recon.manual_capture_txns &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/manual_capture_txns"> */}
                      <Link className='nav-link' to={"/manual_capture_txns"}><span className="sidebar-normal"> Manual Capture Txns </span></Link>
                    {/* </a> */}
                  </li>    
                }

                
                 </ul>
              </div>
            </li>
           }
            {/* {check_priv('rm_mgnt','rm_assign') &&
            <li className="nav-item ">
              <a className="nav-link" data-toggle="collapse" href="#rm_reassignment">
                <i className="material-icons"></i>
                <p> RM Reassignment
                  <b className="caret"></b>
                </p>
              </a>
              <div className="collapse" id="rm_reassignment">
                <ul className="nav">
                
                  <li className="nav-item ">
                    <a className="nav-link" href="/reassign">
                      <span className="sidebar-normal"> RMReassignment </span>
                    </a>
                  </li>    
                
                 </ul>

                 {this.recon.search &&
                  <li className="nav-item ">
                    <a className="nav-link" href="/unknown_txns">
                      <span className="sidebar-normal"> Unknown Txns </span>
                    </a>
                  </li>    
                }
                

              </div>
            </li>
           } */}
           {this.product &&
              <li className="nav-item ">
                <a className="nav-link" data-toggle="collapse" href="#product">
                  <i className="material-icons"></i>
                  <p> Product
                    <b className="caret"></b>
                  </p>
                </a>
              <div className="collapse" id="product">
                <ul className="nav">
                {this.product.create &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/fa_product/create"> */}
                     <Link className='nav-link' to={"/fa_product/create"}><span className="sidebar-normal"> Add Product </span></Link> 
                    {/* </a> */}
                  </li>
                }
                {this.product.search_list_view &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/fa_product/list"> */}
                      <Link className='nav-link' to={"/fa_product/list"}><span className="sidebar-normal"> Search Products </span></Link>
                    {/* </a> */}
                  </li>
                }
                
                </ul>
              </div>
            </li>
           }
           {this.score &&
              <li className="nav-item ">
                <a className="nav-link" data-toggle="collapse" href="#score">
                  <i className="material-icons"></i>
                  <p> Score
                    <b className="caret"></b>
                  </p>
                </a>
              <div className="collapse" id="score">
                <ul className="nav">
                {this.score.list &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/score_models"> */}
                      <Link className='nav-link' to={"/score_models"}><span className="sidebar-normal"> Score Models </span></Link>
                    {/* </a> */}
                  </li>
                }
               {this.score.factor_list &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/score_factors"> */}
                      <Link className='nav-link' to={"/score_factors"}><span className="sidebar-normal"> Score Factors </span></Link>
                    {/* </a> */}
                  </li>
                }
                 {this.score.cust_txn &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/upload_cust_txns"> */}
                      <Link className='nav-link' to={"/upload_cust_txns"}><span className="sidebar-normal"> Upload Customer Transaction </span></Link>
                    {/* </a> */}
                  </li>
                }
                </ul>
              </div>
            </li>
           }
      {check_priv('app', 'config') &&
        <li className="nav-item">
          <a type="button" className="nav-link" onClick={()=>this.toggleModal()} >
          <p>Configuration</p>
          </a>
        {(this.state.showModal) && <CommonModal showModal={this.state.showModal}  toggleModal={(a)=>{this.toggleModal(a)}}  modal_title={"Auto Retry Configuration"} />}
         </li>
      }

           {this.master_agreement &&
              <li className="nav-item ">
                <a className="nav-link" data-toggle="collapse" href="#agreement">
                  <i className="material-icons"></i>
                  <p> Agreement
                    <b className="caret"></b>
                  </p>
                </a>
              <div className="collapse" id="agreement">
                <ul className="nav">
                {this.master_agreement.create &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/agreement/create"> */}
                     <Link className='nav-link' to={"/agreement/create"}><span className="sidebar-normal"> Create Master Agreement </span></Link> 
                    {/* </a> */}
                  </li>
                }
                {this.master_agreement.list_view &&
                  <li className="nav-item ">
                    {/* <a className="nav-link" href="/agreement/list"> */}
                      <Link className='nav-link' to={"/agreement/list"}><span className="sidebar-normal"> List Master Agreement</span></Link>
                    {/* </a> */}
                  </li>
                }
                </ul>
              </div>
            </li>
           }
            {/* {(this.data_prvdr || this.data_prvdr_rel_mgr )&&
              <li className="nav-item ">
                <a className="nav-link" data-toggle="collapse" href="#data_prvdr">
                  <i className="material-icons"></i>
                  <p> Account Provider
                    <b className="caret"></b>
                  </p>
                </a>
              <div className="collapse" id="data_prvdr">
              
              {this.data_prvdr_rel_mgr &&
                <ul className="nav">
                {this.data_prvdr_rel_mgr.create && 
                  <li className="nav-item ">
                    <a className="nav-link" href="/relationship_manager/dprlspmanager/create">
                      <span className="sidebar-normal"> Add  Rel. Manager </span>
                    </a>
                  </li>
                }
                {this.data_prvdr_rel_mgr.list_view && 
                  <li className="nav-item ">
                    <a className="nav-link" href="/relationship_manager/dprlspmanager/list">
                      <span className="sidebar-normal"> List Rel. Managers</span>
                    </a>
                  </li>
                }
                </ul>
              }
              </div>
            </li>
           } */}

           {(this.market || this.acc_prvdr || this.flow_rel_mgr || this.lender) &&
              <li className="nav-item ">
                <a className="nav-link" data-toggle="collapse" href="#market">
                  <i className="material-icons"></i>
                  <p>  FLOW (Market)
                    <b className="caret"></b>
                  </p>
                </a>
              <div className="collapse" id="market">
                  <ul className="nav">
                    {this.market && this.market.create &&
                      <li className="nav-item ">
                        {/* <a className="nav-link" href="/market/create"> */}
                          <Link className='nav-link' to={"/market/create"}><span className="sidebar-normal"> Create Market </span></Link>
                        {/* </a> */}
                      </li>
                    }
                  {this.market && this.market.list_view &&
                      <li className="nav-item ">
                        {/* <a className="nav-link" href="/market/list"> */}
                          <Link className='nav-link' to={"/market/list"}><span className="sidebar-normal"> List Markets</span></Link>
                        {/* </a> */}
                      </li>
                    }
                    {this.user && this.user.create &&
                      <li className="nav-item ">
                        {/* <a className="nav-link" href="/user/create"> */}
                         <Link className='nav-link' to={"/user/create"}><span className="sidebar-normal"> Create FLOW User</span></Link> 
                        {/* </a> */}
                      </li>
                    }
                    {this.user && this.user.list_view &&
                      <li className="nav-item ">
                        {/* <a className="nav-link" href="/user/list"> */}
                          <Link className='nav-link' to={"/user/list"}><span className="sidebar-normal"> List FLOW Users</span></Link>
                        {/* </a> */}
                      </li>
                    }
                    {this.lender && this.lender.create &&
                      <li className="nav-item ">
                        {/* <a className="nav-link" href="/lender/create"> */}
                         <Link className='nav-link' to={"/lender/create"}><span className="sidebar-normal"> Create Lender</span></Link> 
                        {/* </a> */}
                      </li>
                    }
                    {this.lender &&this.lender.list_view &&
                      <li className="nav-item ">
                        {/* <a className="nav-link" href="/lender/list"> */}
                          <Link className='nav-link' to={"/lender/list"}><span className="sidebar-normal"> List Lenders</span></Link>
                        {/* </a> */}
                      </li>
                    }
                  {this.acc_prvdr && this.acc_prvdr.create &&
                      <li className="nav-item ">
                        {/* <a className="nav-link" href="/account_provider/create"> */}
                          <Link className='nav-link' to={"/account_provider/create"}><span className="sidebar-normal"> Create Account Provider</span></Link>
                        {/* </a> */}
                      </li>
                    }
                    {this.acc_prvdr && this.acc_prvdr.list_view &&
                      <li className="nav-item ">
                        {/* <a className="nav-link" href="/account_provider/list"> */}
                          <Link className='nav-link' to={"/account_provider/list"}><span className="sidebar-normal"> List Account Providers</span></Link>
                        {/* </a> */}
                      </li>
                    }
                    {this.acc_prvdr && this.acc_prvdr.list_view && (get('role_codes').includes('super_admin') || get('role_codes').includes('it_admin')) &&
                      <li className="nav-item ">
                        {/* <a className="nav-link" href="/investor/create"> */}
                          <Link className='nav-link' to={"/investor/create"}><span className="sidebar-normal"> Create Investor </span></Link>
                        {/* </a> */}
                      </li>
                    }
                  </ul>
                </div>
              </li>
            }
            { get('role_codes') == "ops_analyst" &&
              <li className='nav-item'>
               <a className="nav-link" data-toggle="collapse" href="#otp">
                  <i className="material-icons"></i>
                  <p>  Otp
                    <b className="caret"></b>
                  </p>
                </a>
                <div className="collapse" id="otp">
                  <ul className="nav">
                  {this.country_code === "RWA" &&
                    <li className="nav-item " >
                      {/* <a className="nav-link" href="/otp/rbok"> */}
                       <Link className='nav-link' to={"/otp/rbok"}> <span className="sidebar-normal">RBOK</span></Link>
                      {/* </a> */}
                    </li>
                  }                  
                  </ul>
                </div>

              </li>
            } 
            

                
            {this.master_data &&
              <li className="nav-item ">
                <a className="nav-link" data-toggle="collapse" href="#master_data">
                  <i className="material-icons"></i>
                  <p> Master Data
                    <b className="caret"></b>
                  </p>
                </a>
                <div className="collapse" id="master_data">
                  <ul className="nav">
                  {this.master_data.create &&
                    <li className="nav-item ">
                      {/* <a className="nav-link" href="/master_data/add"> */}
                       <Link className='nav-link' to={"/master_data/add"}><span className="sidebar-normal"> Add Master Data </span></Link> 
                      {/* </a> */}
                    </li>
                  }
                  {this.data_key.create &&
                    <li className="nav-item ">
                      {/* <a className="nav-link" href="/master_data_key/add"> */}
                        <Link className='nav-link' to={"/master_data_key/add"}><span className="sidebar-normal"> Add Master Data Key</span></Link>
                      {/* </a> */}
                    </li>
                  }
                  {this.data_key.list_view &&
                    <li className="nav-item ">
                      {/* <a className="nav-link" href="/master_data_key/list"> */}
                       <Link className='nav-link' to={"/master_data_key/list"}><span className="sidebar-normal"> List Master Data Keys</span></Link> 
                      {/* </a> */}
                    </li>
                  }                    
                  </ul>
                </div>
              </li>
            }                             
            <li className="nav-item">
              <Link to="/logout" className="nav-link">
                <i className="fa-solid fa-arrow-right-from-bracket"></i>
                <p>{getValueFromLangJson('logout')}</p>
              </Link>
            </li>
            {['it_admin','app_support','super_admin'].includes(this.roles) &&
            <li className="nav-item">
              <Link to="/whatsapp" className="nav-link">
                <i className="fab fa-whatsapp" ></i>
                <p> Whatsapp Services </p>
              </Link>
          </li>
          } 
        </ul>
      </div>
    </div>
    
   

   {this.state.name_verification &&
        <Modal
          show={this.state.name_verification}
          onHide={() =>this.handleclose()}
          className='modal_background_blur'
          size='md'
      >
          <Modal.Header className='p-0' closeButton >
              <Modal.Title className='py-3' style={{background:'#484848'}}>
              <h4 className="modal-title text-light" id="ccaNameVerificationModalLabel">Name Verification</h4>

                  </Modal.Title>
          </Modal.Header>
          <Modal.Body className='px-4 pt-0'>
            
          <div className='d-flex align-items-center'>
         
         <div className='col-md-6 mt-3'>
             <label htmlFor="acc_prvdr_code">Account Provider<span className='product_red_star star_font'>*</span></label>
             <select className="form-control" id="acc_prvdr_code" onChange={(e)=>this.setState({retrvl_acc : e.target.value})}>
                 {this.state.retrievel_accs_dd}
             </select>
         </div>
         <div className='col-md-6 mt-3'>
           <div className="form-group remove_spinner_number_input">
             <label htmlFor="mobile_num">Registered Mobile Number <span className='product_red_star star_font'>*</span></label>
             <input type="number"value={this.state.mobile_num} className="form-control " id='mobile_num' onChange={(e)=>this.setState({mobile_num:e.target.value})}  placeholder="Mobile Number" required/>
           </div>
         </div>
       </div>
       
       <div className='d-flex  justify-content-center mt-3'>
         <button className='btn cca_retrieve_btn'disabled={(this.state.mobile_num!==''&&this.state.loader===false)?false:true} onClick={()=>this.handlenameSearch()}>Submit</button>
       </div>
       {this.state.loader&&<><div className="product_takeup_loader mt-2"></div>
       <p className='text-center text-light'>Retrieving...</p></>}
       {this.state.error_data && 
         <div className='p-3 m-3 row error_msg_cca'>
           <p className=' text-center col-md-6 py-2 m-0 success_msg_cca_text font'>Error : </p>
           <p className=' text-center col-md-6 py-2 m-0 success_msg_cca_text font'>{this.state.error_data}</p>
         </div>
         }
       {this.state.retrieved_name &&
        <div className='row p-3 success_msg_cca my-3'>
         <p className='text-center col-md-6 py-2 m-0 success_msg_cca_text font'>Retrieved Name : </p>
         <p className='text-center col-md-6 py-2 m-0 success_msg_cca_text font'>{this.state.retrieved_name}</p>
         </div>
         }           </Modal.Body>
          <Modal.Footer>
          <button type="button" className="btn cca_retrieve_btn btn-sm"onClick={()=>this.handleclose()} data-dismiss="modal">Close</button>

          </Modal.Footer>
         </Modal>
   }
   
    </>
      );
  }
}


export default AdminMenu;
