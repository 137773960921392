import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import { Modal, Button } from 'react-bootstrap';
import { set, set_logout } from '../../../helpers/storage_helper';
import { GrRotateRight } from "react-icons/gr";
import{BiSave} from "react-icons/bi";
import {upload_file, remove_file, get_file_rel_path, upload_national_id} from '../../../actions/file_actions';
import {RotatedImageUpload} from '../../../actions/lead_actions'
import { getValueFromLangJson, get_path } from '../../../helpers/common_helper';
class DisplayImage extends FlowComponent {
	state = { 
		modalBox: false,
		img_height:'',
		img_width:'100',
		rotation :0,
		rotate_img_data_url:'',
		save_btn:false
	 };
	handleImage = (event) => {
		
		this.setState({modalBox: true});

	}
	handleClose = (event) => {
		this.setState({modalBox: false});
	}	

	image_rotate_handler(){
		var img_id= this.props.index !== null ? (this.props.actual_file+"_"+this.props.index) :this.props.actual_file
		const URL = document.getElementById(img_id).src;
		var image = new Image();

		image.src = URL;
	
		image.crossOrigin = "Anonymous";
		var dataURL ;

		image.onload = () => {
	
		var canvas = document.createElement("canvas");
		const ctx    = canvas.getContext('2d');
		canvas.width  = 90 % 180 === 0 ? image.width : image.height;
		canvas.height = 90 % 180 === 0 ? image.height : image.width;
	
		ctx.translate(canvas.width / 2, canvas.height / 2);
		ctx.rotate(90 * Math.PI / 180);
		ctx.drawImage(image, image.width / -2, image.height / -2);
	    dataURL = canvas.toDataURL("image/png");
		var a=document.getElementById(img_id)
		a.src=dataURL
		this.setState({rotate_img_data_url : dataURL})		

		};
	 
		this.setState({save_btn : true})	
	
	}

	image_save_handler(index=null){
		var addl_key= ""  
		if(this.props.third_party){
			var third_party_key="third_party"
			var third_party_val=true
		}
			var request={
				"file_of" : "photo_"+this.props.file_of ,
				"file_data" : this.state.rotate_img_data_url,
				"file_name" : this.props.file_name,
				"file_type" : "image/jpeg",
				"file_data_type":"data-url",
				"actual_file":this.props.actual_file ?this.props.actual_file:this.props.file_of ,
				"entity_code" :this.props.lead_id,
				[third_party_key]:third_party_val,
				"index":index,
				
			}	
		RotatedImageUpload(this.req(request))
		.then((response)=>{
			if(!response){return}
			if(response.status=="success"){
				alert(response.data.resp_msg)
				this.props.view_lead()
				// window.location.reload()
			}
			
		})

		this.setState({save_btn : false})	
	}


	render(){		
		const onImgLoad = ({ target: img }) => {
			const { offsetHeight, offsetWidth } = img;
			const ratio = offsetWidth/offsetHeight
			const height = 100/ratio
			this.setState(state=>state.img_height = height)						
		  };
		
		return(
			<>
				{this.props.title &&
					<p className='text-white no-margin'>{this.props.title}</p>
				}				 
				<>
					<div style={this.props.style ? this.props.style : {height:this.state.img_height,width:this.state.imgWidth,margin:'auto'} } className="text-center   " >
						{this.props.file_name ?
						<div className='w-100 h-100' >
							<a className= { this.props.rotate ? `image_hover` :''} > 
						{this.props.rotate &&
						<div className='rotate_icon rotate_my_icon justify-content-end align-items-center mt-3'>
						<div className='background-none'>
						 <GrRotateRight className='pointer text-white rotate_icon_margin' onClick={()=>this.image_rotate_handler()} size={20} /> 
						<p  class="text-white no-margin  pointer pr-4 " onClick={()=>this.image_rotate_handler()}> <b>{getValueFromLangJson("roate")}</b>  </p>
						</div>
 						{this.state.save_btn &&
						<div className='background-none'>
						 <BiSave className='text-white pointer' onClick={()=>this.image_save_handler(this.props.index)} size={20} />
						 <p  class="text-white no-margin pointer pl-2 pr-3 " onClick={()=>this.image_save_handler(this.props.index)}> <b > {getValueFromLangJson("save")}</b></p>
						</div>
	                     }
						</div>
						}
							
						
							<img   id={ this.props.index !== null ? (this.props.actual_file+"_"+this.props.index) :this.props.actual_file} style={this.props.style ? this.props.style : {height:this.state.img_height,width:this.state.imgWidth,position:'relative'}} src={get_path("view_file") + this.props.image_path + "/l_" + this.props.file_name}
									onLoad={onImgLoad} alt={"/l_" + this.props.file_name} onClick={(e) => this.handleImage(e)}/>
							<div  className='hoverd_style' style={{}}></div>
							</a>
						</div>
						: <p className='no-margin viewCus_labelVal'>{getValueFromLangJson("not_uploaded")}</p>}
						{/* {this.props.file_name ? 
							<img src={get_path("view_file")+ this.props.image_path + "/l_" + this.props.file_name} 
										alt={"/l_" + this.props.file_name} onClick={(e) => this.handleImage} />
									
						: <>Not uploaded</>} */}
					</div>
				</>
				<Modal show={this.state.modalBox} onHide={this.handleClose} className="model_window">
					<Modal.Header closeButton className="modal-close-btn" show={this.state.modalBox} onHide={this.handleClose}>
						<Modal.Title>{this.props.title}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<img src={get_path("view_file") + this.props.image_path + "/l_" + this.props.file_name}
									alt={"/l_" + this.props.file_name} />
					</Modal.Body>
					<Modal.Footer>
						{/* <Button variant="secondary" onClick={this.handleClose}>Close</Button> */}
					</Modal.Footer>   
          		</Modal>
			</>
			);
	}
}

export default DisplayImage;
