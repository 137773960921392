import React, { Fragment, useEffect, useMemo, useRef, useState} from 'react'
import { get_otps } from '../actions/common_actions'
import { get } from '../helpers/storage_helper';
import { copy_text, create_column } from '../helpers/common_helper';
import moment from 'moment';
import ReactSelect from 'react-select'
import withRouter from '../Router/Withrouter';
import { BasicReactTable } from '../helpers/react_table';



const OtpView = (props)=>{

    
    const market = get('market')

    const acc_prvdr = props.params.acc_prvdr


    useEffect(()=>{
        getOtp()
    },[])


    const [otps, setOtps ] = useState([])
    const [copied, setCopied] = useState(null)

    const copy_otp = (otp)=>{
        copy_text(otp).then((copied_text)=>{
            setCopied(copied_text)
            setTimeout(()=>{
                setCopied(null)
            }, 4000)

        })
        
    }

    const format_date = (date)=>{
        return moment(date).format('DD MMM YYYY hh:mm:ss')
    }
    
    const  column = [
        {header:"Si No",id:"si_no"},
        {header:"Otp",id:"otp"},
        {header:"Created Time",id:"created_at",is_date:true},
        {header:"Entity Id",id:"entity"},
        {header:"Entity Id",id:"entity_id"},
        {header:"Status",id:"status"}]
    

    const column_datas = useMemo(()=>{
        
    let data =[... create_column(column),  
        {
                id:'action',
                Header:<div className='text-center font-for-otp-table'>Copy OTP</div>,
                accessor:row=>row.value,
                Cell:(cell)=>{
                   return <div className='text-center font-for-otp-table'><button className={`btn ${cell.row.original.otp == copied ? 'btn-success': 'btn-primary'} ` }style={{borderRadius:'10px'}} onClick={()=>copy_otp(cell.row.original.otp)}>{cell.row.original.otp == copied ? 'OTP Copied' : 'Copy OTP'}</button></div>
                }
        }  
        ]
        return data;
    })


    const getOtp = ()=>{
        get_otps({country_code : market.country_code, time_zone: market.time_zone, acc_prvdr:acc_prvdr.toUpperCase()}).then((response)=>{
            if(!response) return;
            if(response.status === 'success'){
                setOtps(response.data)
            }
        })


    }

    return(
            <>
            <div className='otp_table_header'>{acc_prvdr.toUpperCase()} RECENT OTPS</div>
            <div className='d-flex justify-content-end' style={{width:'95%', margin:'0,auto'}}>
            </div>
            <div className='otp_table'>
                
                <BasicReactTable  row_data ={otps.length>0 ? otps :[]} column_data={column_datas} pagination={otps.length>10 ? true : false} default_page_size={10} />

            </div>
            </>
        
    )

}

export default withRouter(OtpView)