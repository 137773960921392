import React, { Component } from 'react'
import FlowComponent from '../../common/component/core/FlowComponent'
import { getLoanSearchCriteria } from '../../../actions/common_actions';
import Select from "react-select";

export default class LenderCode extends FlowComponent {
    state={
        lender_code : null
    }

componentDidMount(){
    this.handleApiCall(this.props.country_code)
}
componentDidUpdate(prevProps,prevState){
    if(prevProps.country_code != this.props.country_code){
        this.handleApiCall(this.props.country_code)
    }
}
handleApiCall = (country_code,screen) => {
   var acc_prvdr_code = this.acc_prvdr_code;
   country_code=this.props.country_code
   screen='borrower_search'
   this.setState({flow_rel_mgr_dd:[],sub_lender_code_dd:[],region_dd:[],lender:null})
   getLoanSearchCriteria(this.req({country_code, screen, acc_prvdr_code, "status": "enabled",'is_selected_contry':true}))
               .then((response) => {
                 if(!response){return };
               var result = response.data;
               for (var dd_name in result) {
                   var name_list = result[dd_name];
                   if(dd_name == "sub_lender_code"){
                       name_list.unshift({ data_code: null, data_value: 'All Lender' });
                       var lender_codes = this.convertKeys(name_list,'data_code','data_value')
                       this.setState({sub_lender_code_dd:lender_codes})
                   }	
               }
          });
       }

       convertKeys = (arrayOfObjects,id,name)=> {
           return arrayOfObjects.map(obj => ({
             value: obj[id],
             label: obj[name],
             ...obj
           }));
         }

        
        dropdownStyles = ( ) => {
            const dropdownStyles = {
                control: (provided, state) => ({
                    ...provided,
                    color:this.props.theme ?'black':'white',
                    backgroundColor: this.props.theme ?'white':'#333b50',   
                    border: '#333b50',
                    borderRadius:'5px',
                    }),
                option: (provided) => ({
                  ...provided,
                  color: this.props.theme ?'black':'white', 
                  backgroundColor:this.props.theme ?'white':'#333b50'
                }),
                input: (provided) => ({
                    ...provided,
                    color: this.props.theme ?'black':'white', 
                  }),
                  placeholder: (provided,state) => ({
                    ...provided,
                    color: this.props.theme ?'black':'white',
                }),  
                singleValue: (provided) => ({
                    ...provided,
                    color: this.props.theme ?'black':'white',
                }),
              };
              return dropdownStyles;
         }
          
  render() {

    return (
        <div className={`dropdown ${this.props.theme ? this.props.theme :' dark_theme_bg '}  p-0`} style={{width:this.props.width ? this.props.width : '190px'}}>
        <Select
            onChange={(e)=>{this.props.onChange(e);this.setState({lender:e})}} 
                value = {this.state.lender}
                options={this.state.sub_lender_code_dd} 
                placeholder="Lender"
                styles={this.dropdownStyles()}
                classNamePrefix="my-select"
        />
    </div>
    )
  }
}
