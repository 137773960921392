import FlowComponent from '../common/component/core/FlowComponent';
import React from 'react';
import {searchRecoveries} from '../../actions/loan_actions';
import {format_date_tbl, lbl_amt, title_case} from '../../helpers/common_helper';
import '../../styles/flow.css';
import $ from 'jquery';
import {Link} from "react-router-dom";

import Recovery from "../recovery/Recovery";
import CommonReactTable from '../../helpers/commonReact_table';
import { sortAmtcommon, sortBizcommon, sortDatecommon } from '../../helpers/sortingHelpers';
import Accordion from 'react-bootstrap/Accordion';
import {Card} from 'react-bootstrap'
import {BsChevronDown} from 'react-icons/bs';
import { BasicReactTable } from '../../helpers/react_table';

class OngoingRecoveryList extends FlowComponent {
    
    state = {
        rowdatas :[],
        copy : [],
        columns : [],
        show_table: false
    }

	handleToggle = (e) => {

        switch(e){
            case("Account"):
                this.setState(state => state.show_table = !this.state.show_table);
                break;
        }
    }

    componentWillMount() {
        this.drawTable()
    }

    sortDate (a,b) {	        	
		const current_Date = a.props.children[1]
		const prev_Date = b.props.children[1]		
		return sortDatecommon(current_Date,prev_Date)        
	}
    sortBiz (a,b) {		        
		sortBizcommon(a,b)        
	}
    sortAmount (a,b) {        
        sortAmtcommon(a.props.children,b.props.children)
    }

    drawTable(){
        searchRecoveries(this.req())
        .then((response) => {
            this.resp(response)
            if(!response){return}
            if(response){
                // var recoveries = [];
                // recoveries = response.data.results.map((rec)=> <tr>
                //         <td><Link to={"/borrower/indiv/view/"+rec.cust_id} target="_blank">{rec.cust_id}</Link></td>
                //         <td><Link to={"/fa/view/"+rec.loan_doc_id} target="_blank">{rec.loan_doc_id}</Link></td>
                //         <td>{lbl_amt(rec.amount, this.currency_code)}</td>
                //         <td>{rec.biz_name}</td>
                //         <td>{format_date_tbl(rec.created_at)}</td>
                //         <td>{(rec.rs_name)}</td>
                //         <td>{title_case(rec.status)}</td>
                //         <td><Recovery id='recovery_txn' name="Take Action" loan_doc_id={rec.loan_doc_id} cust_id={rec.cust_id} biz_name={rec.biz_name}/></td>


                //     </tr>
                //     );
                let columns
                columns = [
                    {
                        Header : "Cust ID",
                        id: 'cust_id', 
                        sortable: false,
                        accessor: row => <Link to={"/borrower/indiv/view/"+row.cust_id} target="_blank">{row.cust_id}</Link>
                    },
                    {
                        Header : "FA ID",
                        id: 'loan_doc_id', 
                        sortable: false,
                        accessor: row => <Link to={"/fa/view/"+row.loan_doc_id} target="_blank">{row.loan_doc_id}</Link>
                    },
                    {
                        Header : ()=>(<div className={'tableAmount_centerAlign'}>Cash Amount</div>),
                        id: 'amount', 
                        sortMethod : (a,b) => this.sortAmount(a,b),
                        accessor: row => <div className={'tableAmount_rightAlign'}>{lbl_amt(row.amount,this.currency_code)}</div>
                    },
                    {
                        Header : "Cust Name",                        
                        id: 'biz_name',
                        sortMethod: (a,b) => this.sortBiz(a,b), 
                        accessor: row => {return row.biz_name}
                    },
                    {
                        Header : "Initiated On",
                        id: 'created_at', 
                        sortMethod: (a,b) => this.sortDate(a,b),
                        accessor: row => {return format_date_tbl(row.created_at)}
                    },
                    {
                        Header : "Initiated By",
                        sortable: false,
                        id: 'rs_name',                         
                        accessor: row => {return (row.rs_name)}
                    },
                    {
                        Header : "Status",
                        sortable: false,
                        id: 'status', 
                        accessor: row => {return title_case(row.status)}
                    },
                    {
                        Header : "Action",
                        sortable: false,
                        id: 'action', 
                        accessor: row => <Recovery id='recovery_txn' name="Take Action" loan_doc_id={row.loan_doc_id} cust_id={row.cust_id} biz_name={row.biz_name}/>
                    }
                ]

                    this.setState({refresh: false});
                    this.setState(state => state.rowdatas = response.data.results)
                    this.setState(state => state.copy = response.data.results)
                    this.setState(state => state.columns = columns)

                    //     $('#rec-list-a').removeAttr('width').dataTable({
                    //         "order" : [[4,'desc']],
                    //         "columns": [
                    //             {"width": "5%"},
                    //             {"width": "5%"},
                    //             {"width": "5%"},
                    //             {"width": "5%"},
                    //             {"width": "5%"},
                    //             {"width": "5%"},
                    //             {"width": "5%"},
                    //             {"width": "5%"}
                    //          ],
                    //         "searching": false,
                    //         "paging": false,
                    //         "info": false,
                    // });
                }
        });
    }


    render(){        
        return (
            <Accordion>
                <Card>
                        <div className="card-header card-header-icon card-header-primary home_header_d-flex_at_response justify-space-between">
                            <h4 className="card-title text-white tableHead_font title-bottom ">Collections In Progress</h4>
                            <Accordion.Toggle className = "col" as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("Account")}>
                            <BsChevronDown className = {this.state.show_table ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow"} size="18"/>
                        </Accordion.Toggle>
                        </div>
                        
                        <div className="card-body no-padding">
                        <Accordion.Collapse eventKey="0">
							<Card.Body>
                            {/* <CommonReactTable showPag={false} defaultPageSize={50} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} resizable={false} defaultSorted={[{id:"created_at",desc:true}]}/>    */}

                               <BasicReactTable  row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} pagination={true}    default_page_size={50}  defaultSorted={[{id:"created_at",desc:true}]} />  

                            </Card.Body>
						</Accordion.Collapse>
                        </div>
                </Card>
            </Accordion>
        );

}


}
export default OngoingRecoveryList;