import React, { useState, useEffect } from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import '../../../styles/flow.css';
import DatePicker from 'react-datetime';
import moment from 'moment';
import {Button } from 'react-bootstrap';
import GoogleMapSetup, {MultipleMarkerGoogleMap,RmLiveRoutes} from '../../../helpers/googleMap';
import{getLiveCoordinates, getLiveRoutes}from '../../../actions/relationship_manager_actions';
import {get} from '../../../helpers/storage_helper';



class RMRoutesContainer extends FlowComponent{

    constructor(props){
        super(props);
        this.state = {
            plot_data: {},
            fetching_data: true,
            show_rm_details: false,
            type: props.type,
            flow_rel_mgr_dd: "",
            rm_id: "",
            date: "",
        }; 
    }

    componentWillMount(){

        this.load_flow_rel_mgr();
        if(this.state.type == 'live_locations'){
            this.get_rm_live_coordinates();
        }
        // else if(this.state.type == 'live_routes'){
        //     this.get_rm_live_routes();
        // }
    }

    get_rm_live_coordinates = async () => {
        var req = {time_zone: get('market').time_zone, country_code: get('market').country_code}
        getLiveCoordinates(req)
            .then((response)=>{
                if(!response){return  };
                this.setState({plot_data: response.data, fetching_data: false})
            })
    }

    get_rm_live_routes = async (rm_id, date = null) => {

        var req = {time_zone: get('market').time_zone, country_code: get('market').country_code, rm_id: rm_id, date : date}
        getLiveRoutes(req)
            .then((response)=>{
                if(!response){return  };
                
                this.setState({plot_data: response.data, fetching_data: false})
            })
    }

    getRMData(e){

        this.setState({rm_id: e.target.value})
        this.get_rm_live_routes(e.target.value, this.state.date)
                
    }

    handleDate(date){

        this.setState({date: moment(date).format("YYYY-MM-DD")})
        if(!this.state.rm_id){
            alert("Please select an RM from the dropdown")
            return
        }
        this.get_rm_live_routes(this.state.rm_id, moment(date).format("YYYY-MM-DD"))
    }

    render(){
        return(
            <> 
            {this.state.type == 'live_locations' &&
                <h2 className='text-white text-center'> RM LIVE LOCATIONS</h2>
            }
            {!this.state.fetching_data && this.state.type == 'live_locations' &&        
            <div>
                <MultipleMarkerGoogleMap data= {this.state.plot_data} show_details= {this.state.show_rm_details}/>
            </div>}
            {this.state.type == 'live_routes' &&
            <div>
                <div className = "form-row">
                    <div className = {`form-group col-md-3`}>
                            <label>Choose RM</label><font className="text-danger">*</font>
                            <select id="from_acc_id" className="form-control" required="required" onChange={(e)=>this.getRMData(e)}>
                                {this.state.flow_rel_mgr_dd}                     
                            </select>
                    </div>
                    <div className = {`form-group col-md-5`}>
                        <h2 className='text-white text-center'> RM LIVE ROUTE</h2>            
                    </div>
                    <div className = {`form-group col-md-3`}>
                        <label>Start Date   </label>
                        <DatePicker closeOnSelect={true}  defaultValue={moment.now()} onChange={(value) => {this.handleDate(value)}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
                    </div>
                </div>
            </div>}
            {!this.state.fetching_data && this.state.type == 'live_routes' && this.state.plot_data &&
            <div>
            <RmLiveRoutes path_coordinates= {this.state.plot_data.coordinates} cust_coordinates = {this.state.plot_data.cust_coordinates} center= {this.state.plot_data.center} punch_in = {this.state.plot_data.punch_in} punch_out = {this.state.plot_data.punch_out}/>
            </div>}
            </>
 
        );
    }
    

}

export default RMRoutesContainer;