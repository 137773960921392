import FlowComponent from "../../common/component/core/FlowComponent";
import React from 'react';
import DatePicker from 'react-datetime';
import {FcCalendar} from "react-icons/fc";
import '../../../styles/flow.css';
import {Form, Col , Row, Button,checkbox } from 'react-bootstrap';
import { PartialPaymentReport } from "../../../actions/report_actions";
import CommonComponent from "../../common/CommonComponent";
import { TooltipLabel } from "../../common/partial";
import { dd_value } from "../../../actions/common_actions";
import CommonReactTable from "../../../helpers/commonReact_table";
import { BasicReactTable } from "../../../helpers/react_table";
import { Link } from "react-router-dom";
import ViewTransactions from "../../loan/component/ViewTransactions";
import { Homeacc_prvdrLogoselector, format_date, lbl_amt, rt_algn } from "../../../helpers/common_helper";


class Partial_payment_report extends FlowComponent{
   state ={
        start_date:'',
        end_date:'',
        acc_number:'',
        biz_name:'',
        cust_mobile_num:'',
        loan_doc_id:'',
        rowdatas:[],
        column_data:[],
        calling_api:false
   }

   columnDatas=()=>{
    return [
     {
				Header : "SI No.", 
				id: "row",	
        maxWidth:50,   
				Cell: cell =>{return <p>{cell.row.index+1}</p>}
			},
      {
        Header : "FA ID",
        id: "loan_doc_id",
        minWidth:160,
        sortable:false,
        accessor:row => row.loan_doc_id,
        Cell: (cell) => {return <Link className='link-doc-id' to={"/fa/view/"+cell.value} target="_blank">{cell.value}</Link>},

      },
      {
        Header : "Biz Name",
        id: "biz_name",
        maxWidth:100,
        sortMethod : (a,b) => this.sortBiz(a,b),
        accessor:row => row.biz_name,
        Cell: (cell) => {return<TooltipLabel full_text={cell.value}  />},
      }, 
      {
        Header : "Mobile Number",
        id: "cust_mobile_num",
        maxWidth:100,
        sortable:false,
        accessor:row => row.cust_mobile_num,
        Cell: (cell) => {return<p className='text-center'>{cell.value}</p>},

      },    
      {
        Header :<p className="text-center">Partial Payment Amount</p>,
        id: "amount",
        maxWidth:120,
        sortable:false,
        accessor:row => row.amount,
        Cell: (cell) => {return <p>{rt_algn(lbl_amt(cell.value, this.currency_code))}</p>}
      },
      {
        Header : "Paid A/C Number",
        id: "acc_number",
        sortable:false,
        accessor: row =>`${row.acc_prvdr_code} ${row.acc_number}`,
        Cell: (cell) => {
        let acc_prvdr_code = cell.row.original.acc_prvdr_code;
        let acc_number = cell.row.original.acc_number;
         return <div className="row mr-1">
        <div className="col-6"><Homeacc_prvdrLogoselector logotype={acc_prvdr_code} /></div>
        <div className="col-6 p-0 my-2"> {acc_number}</div>
      </div> }
        
      },  
      {
        Header : <p className="text-center">Current OS Amount</p>,
        id: "current_os_amount",
        maxWidth:100,
        sortable:false,
        accessor:row => row.current_os_amount,
        Cell: (cell) => {return <p>{rt_algn(lbl_amt(cell.value, this.currency_code))}</p>},
      },  
      {
        Header : "Total Payments",
        id: "total_txns",
        maxWidth:100,
        sortable:false,
        accessor:row => row.total_txns,
        Cell: (cell) => {return<p className='text-center'>{cell.value}</p>},
      },  
      {
        Header : <p className="text-center">Current FA Status</p>,
        id: "status",
        maxWidth:150,
        sortable:false,
        accessor:row => row.status,
        Cell: (cell) => {return<p className='text-center'> {dd_value(cell.value)}</p>}

      },
      {
        Header : "Paid Date",
        id: "txn_date",
        maxWidth:100,
        sortable:false,
        accessor:row => row.txn_date,
        Cell: (cell) => {return format_date(cell.value)},
      },
      {
        Header : "View Transactions",
        sortable:false,
        id: "View_txns",
        accessor:row => row.loan_doc_id,
         Cell: (cell) =>{return <ViewTransactions parent="view_loan" partial={true} loan_doc_id={cell.value} btn={"btn"}/>}
    }, 
    ];

    }

    handlePartialPayment=()=>{
        if(!this.state.start_date || !this.state.end_date){
            alert('Please fill all the * mandatory fields')
        }else{
            this.setState({loader:true,row_datas:[],no_data:false,calling_api:true})
          let request = {
              'start_date':this.state.start_date,
              'end_date':this.state.end_date,
              'acc_number':this.state.acc_number,
              'biz_name':this.state.biz_name,
              'cust_mobile_num':this.state.cust_mobile_num,
              'loan_doc_id':this.state.loan_doc_id
          }
          this.setState({loader:true,rowdatas:[]})
          PartialPaymentReport(this.req({search:request}))
            .then((response) => {
              this.resp(response)
              if(!response){this.setState({no_data:true,loader:false,calling_api:false} )}
              if(response && response.status == "success"){
                this.setState({loader:false,calling_api:false})
                this.setState(state=>state.column_data=this.columnDatas())
                this.setState(state=>state.rowdatas=response.data)
                if(response.data.length==0){
                  this.setState({no_data:true})
                }
              }
          }) 
        }
    }
   
    handleResetClick =() => {
      this.setState({acc_number:"",biz_name:"",cust_mobile_num:"",loan_doc_id:""})
    }

    render(){
        return(<div className="container containerTopmargin max-width p-3">
            <div className="floatAdvancetitleBox">
                <h5 className='no-margin preHeaderpad' style={{paddingLeft:15,fontSize:20}}>Partial Payments </h5>
            </div>
            <div className="row p-2">
              <div className='col-sm-12 col-md-6 col-lg-3 p-3'>
                <label className='col p-0 mt-3' style={{color:"white"}}>Start Date<font className="text-danger"> * </font></label>
                <i className='material-icons m-0 calender_position' style={{position: 'absolute',color:'white', top: '50%', transform: 'translateY(-50%)', right: '10px',zIndex:'1'}}>calendar_month</i>
                <DatePicker className='col-12 pr-1 p-0' inputProps={{ id: 'start_date', name: 'start_date',autoComplete:'off' }}  defaultValue={this.state.start_date} closeOnSelect={true}  onChange={(value)=>this.setState({start_date:moment(value).format('YYYY-MM-DD')})} dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
              </div>
              <div className='col-sm-12 col-md-6 col-lg-3 p-3' style={{position: 'relative'}}>
                <label className='col p-0 mt-3 text-white'>End Date <span className='product_red_star'>*</span></label>
                <i className='material-icons m-0' style={{position: 'absolute',color:'white', top: '50%', transform: 'translateY(-50%)', right: '10px',zIndex:'1'}}>calendar_month</i>
                <DatePicker className='col-12 pr-1 p-0' inputProps={{ id: 'end_date', name: 'end_date',autoComplete:'off' }} defaultValue={this.state.end_date} closeOnSelect={true}  onChange={(value)=>this.setState({end_date:moment(value).format('YYYY-MM-DD')})} dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
              </div>
              <div className='col-sm-12 col-md-6 col-lg-3 p-3'>
                <div className='row p-3'>
                  <label className='col p-0 partial-input-width' >Customer A/C Number</label>
                  <div className="w-100 mr-2">
                    <input id="acc_number" class="form-control" value={this.state.acc_number} onChange={(e)=>this.setState({acc_number:e.target.value})}></input>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-6 col-lg-3 p-3'>
                <div className='row p-3'>
                  <label className='col p-0 partial-input-width' >Mobile Number</label>
                  <div className="w-100 mr-2">
                    <input id="mobile_num" class="form-control" value={this.state.cust_mobile_num} onChange={(e)=>this.setState({cust_mobile_num:e.target.value})}></input>
                  </div> 
                </div>
              </div>
              <div className='col-sm-12 col-md-6 col-lg-3 p-3'>
                <div className='row p-3'>
                  <label className='col p-0 partial-input-width' >FA ID</label>
                  <div className="w-100 mr-2">
                    <input id="fa_id" class="form-control" value={this.state.loan_doc_id} onChange={(e)=>this.setState({loan_doc_id:e.target.value})}></input>
                  </div>
                </div>
              </div>
            </div>
        <>
          <div className="col-12 text-center" style={{paddingBottom:15,paddingTop:15,marginBottom:15}}>						
				    <Button type="reset" variant="secondary" onClick={this.handleResetClick} >Reset</Button>&nbsp;&nbsp;&nbsp;
				    <Button id="searchbtn" varient="info" onClick={this.handlePartialPayment} disabled={this.state.calling_api} className={`btn-primary`} >Search</Button> &nbsp;										
			    </div>
        </>
        {this.state.rowdatas &&
          <div className={this.state.datatable_div_style + 'card'}>
            <div id=''>
              <div className="customer_list_reactTable">
                {this.state.rowdatas.length > 0 &&
                  <>
                    <div className='d-flex justify-content-between align-items-center' style={{padding:5}}>
                      <p className='no-margin text-white'><b>Total Entries : {this.state.rowdatas.length}</b></p>
                    </div>
                    <BasicReactTable className='border' row_data={this.state.rowdatas?this.state.rowdatas:[]}  column_data={this.state.column_data} csv_file={true} FName="Partial Payments" global_search={false} pagination={true} excess_time_action={false} default_page_size={10} loader={this.state.rowdatas ? false : true} searchData={[{title:'Partial Payment Report',start_date:this.state.start_date ? moment(this.state.start_date).format('YYYY-MM-DD') : this.state.start_date,end_date:this.state.end_date ? moment(this.state.end_date).format('YYYY-MM-DD') : this.state.end_date,loan_doc_id:this.state.loan_doc_id,cust_acc_number:this.state.acc_number,biz_name:this.state.biz_name,mobile_num:this.state.cust_mobile_num }]}/> 
                  </>
                }			
                {this.state.no_data &&<h6 className='text-center text-light p-5 m-5'>No Data Found</h6>}
                {this.state.rowdatas.length==0&&this.state.loader&&<>
                  <p className='text-light mt-3 text-center'>Loading...</p>
                  <div className="product_takeup_loader"></div>
                </>}			  						  
              </div>
            </div>
          </div> 
        }
            
    </div>)
    }
}
export default Partial_payment_report;