import { get_path } from '../helpers/common_helper';
import {call_fetch, app_path} from './../helpers/fetch_helper';

export const listSmsLogs = request  => {
    return call_fetch(get_path('admin') + '/sms/search', request);
}

export const GetSMSVendors = request =>{
    return call_fetch(get_path('admin') + '/sms/switch', request);
}
export const SwitchSMSVendor = request =>{
    return call_fetch(get_path('admin') + '/sms/switch', request);
}
