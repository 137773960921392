import {  Navigate } from 'react-router-dom';
import React from 'react';
import {createDataProvider, viewDataProvider} from '../../../actions/data_provider_actions';
import FlowContainer from '../../common/container/core/FlowContainer';
import DataProvider from '../component/DataProvider';
import AddressInfo from '../../common/component/AddressInfo';
import ChooseOrg from '../../common/component/ChooseOrg';
import Organisation from '../../common/component/Organisation';
import Person from '../../common/component/Person';
import '../../../styles/flow.css';
import withRouter from '../../../Router/Withrouter';


class DataProviderContainer extends FlowContainer {

  state = {
    toList: false,
    org:{},
    reg_address: {},
    contact_person : {},
    data_provider: {}
   
  }

  componentWillMount() {
   
    if(this.props.mode === 'view'){
     
      const data_prvdr_code = this.props.params.data_prvdr_code;
      const request = {data_prvdr_code, country_code: this.country_code};
      
   
      viewDataProvider(this.req(request))
        .then((response) => {
          if(!response){return };
             this.data_provider = response.data;
            this.setState({data_provider: this.data_provider,
                            org: this.data_provider.org,
                            contact_person: this.data_provider.contact_person,
                            reg_address: this.data_provider.org.reg_address
                           });
          }
        );
    } else if(this.props.mode  === 'create'){
      
    }
  }


  handleSubmit = (event)=>{
    event.preventDefault();
    
    var data_prvdr = this.state.data_provider;
    var org = this.state.org;
    org.reg_address = this.state.reg_address;
    org.country_code = this.country_code;
    data_prvdr.country_code = this.country_code;
    data_prvdr.org = org;
    data_prvdr.contact_person = this.state.contact_person;
    data_prvdr.contact_person.country_code = this.country_code;
    
    createDataProvider(this.req({data_prvdr}))
        .then((response) => {
          if(!response){return };
            
            if(response.status === "success"){

              this.setState({new_data_prvdr_code: response.data});
              this.setState({toList: true});
              alert("New Data Provider created successfully");
            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          }
        );
  }


render(){
   
     if (this.state.toList === true) { 
      
      return <Navigate to={`/data_provider/view/${this.state.new_data_prvdr_code}`}/>
    }

      return (
 
      <div className="container">
         <h5>{this.props.title + (this.state.data_provider.name? " : " + this.state.data_provider.name : "")}</h5>
        <form id="data_provider_form" onSubmit={this.handleSubmit}>
            <div id="" className={"text-labelblue"}>
            <DataProvider id="data_provider" title="Data Provider" data={this.state.data_provider} mode={this.props.mode} 
                    onComponentChange={this.handleComponentChange}/>
            
            <hr/>

            {this.props.mode === "create" && 
            <ChooseOrg id="data_provider_choose_org" resetOrgNAddr={this.handleResetOrgNAddr} onChange={this.handleOrgChange} />
            }

            <div className={`${this.state.div_style}`} >
            <Organisation id="org" title="Organisation Details" entity="data_prvdr" data={this.state.org} mode={this.props.mode} 
                    onComponentChange={this.handleComponentChange}/>
            
            <br/>
            
             <AddressInfo id="reg_address" title="Registered Address" entity="data_prvdr" data={this.state.reg_address} mode={this.props.mode} 
                    onComponentChange={this.handleComponentChange} onComponentReset={this.handleComponentReset}/>

            <hr/>
            </div>
          
            <Person id="contact_person" title="Contact Person" entity="data_prvdr" data={this.state.contact_person} 
                    mode={this.props.mode} 
                    onComponentChange={this.handleComponentChange}/>
            <br/>

            <input type="button" className={`btn btn-primary ${this.props.mode === "create" ? "show_button": "hide_button"}`} onClick={this.handleSubmit} value="Create New Data Provider"/>
         
          </div>

        </form>  
        <br/><br/>
      </div>

      );
  }
}

export default withRouter(DataProviderContainer) ;
