import {dd_value, getMasterData} from '../../../actions/common_actions';
import React from 'react'
import ReactDOM from 'react-dom'
import {Button, Form,Col} from 'react-bootstrap';
import DatePicker from 'react-datetime';
import FlowComponent from '../../common/component/core/FlowComponent';
import moment from 'moment';
import { format } from 'date-fns';
import { Config, get } from '../../../helpers/storage_helper';

class InboundCallLog extends FlowComponent{

    state ={
       call_list_filters : [
        {label:"Start date",key :"start_date"},
        {label:"End date ",key :"end_date"},
        {label:"Caller",key :"tollfree_caller"},
        {label:"Caller Name",key :"caller_name"},
        {label:"Call Direction",key :"tollfree_direction"},
        {label:"Call Purpose",key :"tollfree_purpose"},
        {label:"Status",key :"tollfree_status"},
        {label:"Language",key :"tollfree_language"},
        // {label:"Calls Made In",key :"tollfree_duration"},
        {label:"Customer",key :"cust_id"},
    ],
    search_criteria:{
        start_date :  moment().format('YYYY-MM-DD') ,
        end_date :  moment().format('YYYY-MM-DD'),
        caller_name:null
    },

    language : Config('toll_fee_languages'),
    
    
    }


    getLanguage = () => {
      let country_code = get('market').country_code
      let languages = this.state.language[country_code]
      return (
        <>
        <option value={""}>choose</option>
        {languages.map((val, i) => (
          <option key={i} value={val}>{dd_value(val)}</option>
        ))}
        </>)
    }

    componentWillMount(){
        this.handleCurdate()    
    }
    
    handleCurdate(){
        const date = new Date()
        const cur_date = format(date, 'dd MMM y')
        return cur_date
    }

    getInputValues = (value,id) => {

       var search_criteria = {...this.state.search_criteria}
       search_criteria[id] = value
       if(id === "tollfree_caller"){
        search_criteria.caller_name = ""
       }
       this.setState({search_criteria},()=>{this.checkdisable()})
       
       if(id === "tollfree_caller" && value){
        this.getSelect_options(value)
       }
       var event = {};
       event.target = {id, value};
       this.handleChange(event);

      }

      getSelect_options(val){
        this.setOptionsToStateFromApi(val, {...this.def_req})
      }

      handleSearch(type){
        
        this.props.handleSearch(type,this.state.search_criteria)

      }

    checkdisable(key){
       var a= ""
        if(key=="caller_name"){
           a = this.state.search_criteria['tollfree_caller'] ? "" :"disable_div"
        }
        // if(key=="tollfree_duration"){
        //    a =  ((this.state.search_criteria['start_date'] == this.state.search_criteria['end_date'] ) || ( this.state.search_criteria['start_date'] ==  moment().format('YYYY-MM-DD'))) ? false :"disable_div"
        //   if(a=="disable_div" && this.state.search_criteria['tollfree_duration'] ){
        //     delete this.state.search_criteria.tollfree_duration;  
        //     this.setState({search_criteria:this.state.search_criteria})
        //     this.getSelectOptions("tollfree_duration")

        //   }
        // }

        return a
      }
    render(){
        return(

        <div>
            <div className='row m-0'>
            {this.state.call_list_filters.map((items,idx)=>{return(
                <div className='col-lg-3 my-3 col-md-6 col-sm-12'>
                  <div style={{width:"90%", justifyContent:"center", alignItems:"center"}} className='d-flex'>
                 <fieldset className={` h-100 py-0 px-3 ${"new_re_kyc_text_bg_color"} ${this.checkdisable(items.key)} `} style={{ border: '1px solid white', background: "#202940", borderRadius: '10px' , width:"100%"}}>
                  <legend className={`add_watchlist_text float-none `}>
                     <b> {items.label} </b>
                 </legend> 

                 {(items.key =="start_date" || items.key =="end_date" ) ?
                    <div className="col-12 w-100 p-0">
                     <DatePicker defaultValue={this.handleCurdate()} closeOnSelect={true}   isValidDate={(current) => {
                                            const today = moment().startOf('day');
                                            return current.isSameOrBefore(today, 'day');
                                        }}
                      onChange={(value) => {this.getInputValues( moment(value).format('YYYY-MM-DD'), items.key)}} id={items.key} dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
                    </div>
                    :
                    

                 <div className='d-flex align-items-center justify-content-between addWishList col-12 px-0'>

                    {(items.key=="cust_id") ? 

                        <Form.Group as={Col} className={` px-2 py-0 no-margin  `} >
                        <Form.Control id={items.key} placeholder=' Name/Cust ID/Mobile No' className='p-0' autoComplete='off' onChange={(e) => {this.getInputValues(e.target.value,items.key)}} >
                        </Form.Control>
                        </Form.Group>
                    
                   :

                     <select id={items.key} className={`form-control  w-100 col-12 px-0`} value={this.state.search_criteria[items.key]}  onChange={(e) =>this.getInputValues(e.target.value,items.key)} >
                       {
                                  (items.key == "caller_name") ? (
                                        this.state.search_criteria['tollfree_caller'] ? 
                                        this.state[`${this.state.search_criteria['tollfree_caller']}_dd`] : 
                                        this.state.cs_roster_dd
                                    ) : (items.key == 'tollfree_language') ? (
                                      this.getLanguage()
                                    ) : (
                                        (this.checkdisable(items.key) =="disable_div") ?
                                        <option>choose</option>:
                                        this.getSelectOptions(items.key)
                                    )
                        }

                       

                     </select>
                    }

                 </div>
               }
            

             </fieldset>
             </div>
             </div>
            )})}
            </div>

            <div className="form-group row reset-margin justify-content-center py-4">
            <Button disabled={this.state.calling_api} className= {`col-md-2 mr-3 btn btn-primary`} onClick={()=>this.handleSearch('search')}>Search</Button>
                            </div>

           
                
        </div>         
        )
    }
   

}

export default InboundCallLog