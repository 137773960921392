import {  Navigate } from 'react-router-dom';
import React from 'react';
import FlowContainer from '../../common/container/core/FlowContainer';

import Person from '../../common/component/Person';
import '../../../styles/flow.css';


class AppUserContainer extends FlowContainer {

  componentWillMount() {

  } 


 
  render(){
    if (this.state.toList === true) { 
      
      return <Navigate to={`/app_user/list}`}/>
    }
   
    
      return (
 
      <div className="container">
         <h4>{this.props.title + (this.state.market.name? " : " + this.state.market.name : "")}</h4> 
        <form id="market_form" onSubmit={this.handleSubmit}>

            <Person id="head_person" title="Market Head" data={this.state.head_person} 
                    mode={this.props.mode} 
                    onComponentChange={this.handleComponentChange}/>


            <input type="button" className={`btn btn-primary ${this.props.mode === "create" ? "show_button": "hide_button"}`} onClick={this.handleSubmit} value="Create New Market"/>
         
        </form>  
        <br/><br/>
      </div>

      );
  }
}

export default AppUserContainer;
