import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import { AiOutlineIdcard,AiOutlineMobile,AiFillShop ,AiFillEdit} from "react-icons/ai";
import{MdBusiness,MdPerson,MdAccountCircle,MdImage,MdPersonOutline,MdPhoto,MdLocationPin,MdArrowDropDown} from "react-icons/md";
import { BiRefresh,BiPhoneOff ,BiPhoneCall,BiXCircle,BiPhotoAlbum,BiEdit,BiMap,BiCheck} from "react-icons/bi";
import{GoVerified} from "react-icons/go";
import{FaHandsHelping} from "react-icons/fa";
import{HiOutlineDocumentAdd,HiPhoneOutgoing,HiArrowCircleRight,HiArrowCircleLeft}from"react-icons/hi";
import{ImCircleDown,ImCircleUp} from "react-icons/im";
import{ CgNotes}from'react-icons/cg';
import{BsClipboardData}from'react-icons/bs';
import FileUpload from '../../common/component/FileUpload';
import Alert from 'react-bootstrap/Alert';
import {get, Config} from '../../../helpers/storage_helper';
import { viewLead ,UpdateFlags,addComments,auditKycVerification,auditKyRetrievals,auditKycFileUpload, submitCallLog,RejectCallLog,auditkycModify,updateAccPrvdrCode} from '../../../actions/lead_actions';
import {capitalize_first_letter,getLabel,get_dir_path} from '../../../helpers/common_helper';
import DisplayImage from '../../common/component/DisplayImage';
import { approveKYC,updateLeadStatus} from '../../../actions/kyc_actions';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import isValid from 'date-fns/fp/isValid';
import {dd_value} from '../../../actions/common_actions';
import GoogleMapSetup from '../../../helpers/googleMap';
import moment from 'moment';

class AuditKYCMapandHandlerComponent extends FlowComponent{
    constructor() {
        super();
        this.uga_owner_address_alignment=["parish","sub_county","county","village","district","region","location","landmark"]
        this.rwa_owner_address_alignment=["province","district","sector","cell","village","location","territory","landmark"]
      
    }

    checkbox_spinner(){
        return(
          <div class="d-flex col-lg-10 py-2 px-5 mt-4 justify-content-center ml-5">
          <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
         </div>
        )
      }
      audit_section_spinner(){
        return(
          <div class="audit_spinner" style={{margin:'20vh 0px  30vh 45%'}}>
          <span class="level level-1"></span>
          <span class="level level-2"></span>
          <span class="level level-3"></span>
          <span class="level level-4"></span>
          <span class="level level-5"></span>
          <span class="level level-6"></span>
        </div>
        )
      }
      
      rekyc_modify_state_handler(json_type,json_key,key){
        if((this.props.data[json_type][json_key][key].hasOwnProperty("modified")) && (this.props.data[json_type][json_key][key].modified)){
          return 'new_kyc_json';
        }else{
          return "";
          }

      }


    render(){
        return(
            <div>
             {this.props.handler_and_maplocation_data.audit_kyc_wizard_menus.contact_persons && 
             <> {this.props.handler_and_maplocation_data.View_lead_section_loader ? this.audit_section_spinner():

            
              <div className='row no-margin justify-content-center'>
              <div className='col-lg-12 py-3 mt-5 ' style={{background:' #01062c'}}>
                <h2 className='text-white text-center'>
               <b>2 - Handler Verification  </b> 
                </h2>
                <h2 className=' d-flex mt-1 text-center justify-content-center align-items-center' ><img src='/img/pending_with_rm.png' height={'50'}/> <b className='ml-2 align-self-cente' style={{color:'#F3EA0B'}}> Pending with {((this.props.handler_and_maplocation_data.active_section && (this.props.handler_and_maplocation_data.active_section[0].pending_with==="rm" ) ? 'RM':'YOU'))}</b></h2>

              </div>
              <>
             
              <div className='col-lg-10 py-5 mt-5 px-5' style={{background:'#223b56'}}>
              <h2 className='text-white'>
              <b> Handler {getLabel('national_id')} Front Side Verification</b>
              </h2>
              {this.props.handler_and_maplocation_data.cust_reg_json &&
              <>
              <div className=' mt-4 row '>

              <div className='col-md-6 no-padding col-lg-6' >
              <div className='m-0 text-light  h-100 w-100' >
                <DisplayImage image_path={get_dir_path(this.props.handler_and_maplocation_data.cust_reg_json.contact_persons[0].photo_id_proof_full_path.value,this.props.handler_and_maplocation_data.cust_reg_json.contact_persons[0].photo_id_proof.value)} style={{height:'550px',width:'100%'}} file_name={this.props.handler_and_maplocation_data.cust_reg_json.contact_persons[0].photo_id_proof.value} rotate={false}  saved_file_name={"photo_national_id"} lead_id={this.props.lead_id} addl_file_name={null} view_lead={()=>this.props.view_lead()} actual_file={"handler_national_id"} />
              </div>
              </div>
              <div className='col-md-6 col-lg-6 ' >
              <div className='m-0 text-light border h-100 w-100' >
                    
                  <div className=' py-4' >
                      
                      <div className='align-items-center my-4 py-4 mx-2'>
                      <p className='no-margin text-white pl-4 business_operations_text'>  FIRST NAME  </p>
                              <h2 className='text-white pl-4 audit_kyc_text'> <b>{ capitalize_first_letter(this.props.handler_and_maplocation_data.cust_reg_json.contact_persons[0].first_name.value)}</b></h2>
                      </div>

                      <div className='align-items-center my-4 py-4 mx-2'>
                              <p className='no-margin text-white col-4 business_operations_text '>  LAST NAME </p>
                            <h2 className='text-white  pl-4 audit_kyc_text'> <b> { this.props.handler_and_maplocation_data.cust_reg_json.contact_persons[0].last_name.value}</b></h2>
                      </div>

                        <div className='d-flex row align-items-center py-4 my-4 mx-2'>
                        <div className='col-4'>
                        <p className='no-margin text-white  business_operations_text '>  NATIONALITY </p>
                            <h2 className='text-white  audit_kyc_text'><b>{(get('market').country_code)}</b></h2>
                        </div>

                        <div className='col-4'>
                        <p className='no-margin text-white  business_operations_text'>   GENDER</p>
                      <h2 className='text-white  audit_kyc_text'><b> { capitalize_first_letter(this.props.handler_and_maplocation_data.cust_reg_json.contact_persons[0].gender.value)}</b></h2>
                      
                        </div>

                        <div className='col-4'>
                        <p className='no-margin text-white  business_operations_text '>  DOB </p>
                        <h2 className='text-white  audit_kyc_text' ><b>{ moment(this.props.handler_and_maplocation_data.cust_reg_json.contact_persons[0].dob.value).format("DD/MM/YYYY")}</b></h2>
                        </div>


                      </div>
                      <div className='align-items-center py-4 my-4 mx-2'>
                      <p className='no-margin text-white col-4 business_operations_text'> {getLabel('national_id')} </p>
                      <h2 className='text-white pl-4 col-7 audit_kyc_text'><b>{this.props.handler_and_maplocation_data.cust_reg_json.contact_persons[0].id_proof_num.value}</b></h2>
                      </div>
                        <br/>
                
                  </div>
                
              { this.props.handler_and_maplocation_data.active_section &&
                  <div>
                      {this.props.handler_and_maplocation_data.active_section[0].verify &&
                        <div className="closedStamp ">
                        <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
                        </div>   
                      }
                  </div>
                }   
              </div>
              
            
              </div>
              </div>

              <div className='row'>
                {this.props.handler_and_maplocation_data.form_spinner ? 
                  <div class="d-flex col-lg-10 py-2 px-5 mt-4 justify-content-center ml-5">
                  <div class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                  :
                  <div className='col-lg-10 py-2 px-5 mt-4'>
                {this.props.handler_and_maplocation_data.active_section &&
                  <form className={`${(this.props.handler_and_maplocation_data.active_section[0].verify || (this.props.handler_and_maplocation_data.active_section[0].to_reassign==true) )? "disable_div":''}  ${ ((this.props.handler_and_maplocation_data.responseData.audited_by== get("user_id")) || (this.props.handler_and_maplocation_data.responseData.audited_by==null )) ?"":'disable_div'}`}>
                      <div className='d-flex align-items-center'>
                      <input type="checkbox" id="vehicle1" className='pointer' style={{height:'19px',width:'20px'}} checked={this.props.handler_and_maplocation_data.active_section[0].flags.original_national_id && true} name="vehicle1" value="Bike" onChange={(e)=>this.props.checkbox_verification(e.target.checked,"original_national_id")}/>
                      <label className='no-margin pl-3 audit_kyc_text pointer'  for="vehicle1">The {getLabel('national_id')} details matches with the details entered by the RM</label><br/><br/><br/>
                      </div>
                      <div className='d-flex align-items-center'>
                      <input type="checkbox" id="vehicle2" name="vehicle2" className='pointer' value="Car" checked={this.props.handler_and_maplocation_data.active_section[0].flags.clear_and_readable && true} style={{height:'18px',width:'20px'}} onChange={(e)=>this.props.checkbox_verification(e.target.checked,"clear_and_readable")}/>
                      <label  className='no-margin pl-3 audit_kyc_text pointer'  for="vehicle2"> The text in the {getLabel('national_id')} is in a clear & readable state</label><br/><br/>
                      </div>

                      <div className='d-flex align-items-center mt-3'>
                      <input type="checkbox" id="vehicle3" name="vehicle3" className='pointer' checked={this.props.handler_and_maplocation_data.active_section[0].flags.face_recogonizable && true} style={{height:'18px',width:'20px'}} onChange={(e)=>this.props.checkbox_verification(e.target.checked,"face_recogonizable")}/>
                      <label  className='no-margin pl-3 audit_kyc_text pointer'  for="vehicle3"> The face of the customer in the {getLabel('national_id')} is recognizable</label><br/>
                      </div>
                  </form>
                  }
                  
              </div>
                }
              </div>
              </>
                }
              </div>

                {this.props.handler_and_maplocation_data && get('market').country_code =="UGA" &&
              <div className='col-lg-10 py-5 mt-5 px-5' style={{background:'#223b56'}}>
              <h2 className='text-white'>
              <b>Handler {getLabel('national_id')} Back Side Verification</b> </h2>
            
              {get('market').country_code =="UGA" &&
                <div className='d-flex mt-4 row justify-content-evenly '>
                <div className='col-md-12 col-lg-6 ' >
                <div className='h-100 w-100' style={{border:'1px solid #D9D9D9'}} >
                    <h2 className='text-white audit_kyc_text no-margin px-4 py-3'> <b>Home Address  </b>  <span style={{fontSize:'13px'}}>(as entered by RM)</span> </h2>
                    <hr className='no-margin'/>
                    <div className=' p-4' >
                    {Object.entries(this.props.handler_and_maplocation_data.cust_reg_json.contact_persons[0].contact_address).map(([key, value]) => {
                      return(
                        <div className='d-flex row align-items-center my-3 mx-2 align-items-center'>
                        <p className='no-margin text-white col-4 business_operations_text'> { dd_value(key.toUpperCase())} </p>
                        <h2 className='text-white pl-4 col-8 audit_kyc_text'> <b>{value.value? dd_value(capitalize_first_letter(value.value)):<p className='text-white text-left no-margin'>-</p>} </b></h2>
                        </div>
                      )
                      
                      })}
                    </div>   
                  </div>
                </div>
                <div className='col-md-12 col-lg-6'  >
                <DisplayImage image_path={get_dir_path(this.props.handler_and_maplocation_data.cust_reg_json.contact_persons[0].photo_id_proof_back_full_path.value,this.props.handler_and_maplocation_data.cust_reg_json.contact_persons[0].photo_id_proof_back.value)} rotate={false} saved_file_name={"photo_national_id_back"} lead_id={this.props.lead_id} style={{height: '500px',width:'100%'}} file_name={this.props.handler_and_maplocation_data.cust_reg_json.contact_persons[0].photo_id_proof_back.value}  view_lead={()=>this.props.view_lead()} />
                </div>
                </div>
              }

              </div>
              }

              {this.props.handler_and_maplocation_data.cust_reg_json && get('market').country_code =="UGA" &&
              <div className='col-lg-10 py-5 mt-5 px-5' style={{background:'#223b56'}}>
              
            
                {get('market').country_code =="UGA" &&
                  <div className='d-flex justify-content-around mt-5'>
                  <div className='col-md-12 my-3 col-lg-5 no-padding'>
                    <p className='no-margin text-white text-center py-3 audit_kyc_text'>HANDLER PHOTO</p>
                    <div className='col p-0'>
                    <DisplayImage rotate={false} saved_file_name={"photo_pps"} lead_id={this.props.lead_id} image_path={get_dir_path(this.props.handler_and_maplocation_data.cust_reg_json.contact_persons[0].photo_pps_full_path.value,this.props.handler_and_maplocation_data.cust_reg_json.contact_persons[0].photo_pps.value)} style={{height: '100%',width:'100%'}} file_name={this.props.handler_and_maplocation_data.cust_reg_json.contact_persons[0].photo_pps.value} view_lead={()=>this.props.view_lead()} />
                    </div>
                  
                  </div>
                  <div className='col-md-12 my-3 col-lg-5 no-padding'>
                  <p className='no-margin text-white text-center py-3 audit_kyc_text'>SELFIE PHOTO</p>
                  <div className='col p-0'>
                  <DisplayImage  rotate={false} saved_file_name={"photo_selfie"} view_lead={()=>this.props.view_lead()} lead_id={this.props.lead_id} image_path={get_dir_path(this.props.handler_and_maplocation_data.cust_reg_json.contact_persons[0].photo_selfie_full_path.value,this.props.handler_and_maplocation_data.cust_reg_json.contact_persons[0].photo_selfie.value)} style={{height: '100%',width:'100%'}} file_name={this.props.handler_and_maplocation_data.cust_reg_json.contact_persons[0].photo_selfie.value} />
                  </div>
                
                  </div>
                </div>
                 }

              </div>
              }
              </>
              </div>
             } </>
            }

            {this.props.handler_and_maplocation_data.audit_kyc_wizard_menus.gps_address &&
             <> 
             {this.props.handler_and_maplocation_data.View_lead_section_loader ? this.audit_section_spinner():

              <div className='row no-margin justify-content-center'>
              <div className='col-lg-12 py-3 mt-5 ' style={{background:' #01062c'}}>
                <h2 className='text-white text-center'>
               <b> {this.props.handler_and_maplocation_data.cust_reg_json.same_as_owner_person ? "2" :"3"} - GPS & Address</b> 
                </h2>
                <h2 className=' d-flex mt-1 text-center justify-content-center align-items-center' ><img src='/img/pending_with_rm.png' height={'50'}/> <b className='ml-2 align-self-cente' style={{color:'#F3EA0B'}}> Pending with {this.props.handler_and_maplocation_data.active_section && (this.props.handler_and_maplocation_data.active_section.pending_with=="rm"?'RM':'YOU')}</b></h2>

              </div>
              {this.props.handler_and_maplocation_data.View_lead_section_loader ? this.audit_section_spinner():
              <>
              
              <div className='col-lg-10  px-5' >

              <div className=' mt-4 row '>
              {this.props.handler_and_maplocation_data.cust_reg_json &&
              <div className='col-md-6 col-lg-6 no-padding 'style={{border:'1px solid #D9D9D9'}}   >
               <div >
                <h2 className='text-white audit_kyc_text no-margin px-4 py-3'> <b>Business Details</b>  <span>(captured by RM)</span> </h2>
                <hr className='no-margin'/>
                <div className=' p-4' >

                {/* <div className='d-flex row align-items-center mb-5 mx-2 align-items-center'>
                        <p className='no-margin text-white col-4 business_operations_text'> BUSINESS NAME </p>
                        <h2 className='text-white pl-4 col-8 audit_kyc_text'> <b>{capitalize_first_letter(this.props.handler_and_maplocation_data.cust_reg_json.license_proof.biz_name.value)}</b></h2>
                </div> */}

               { this.props.handler_and_maplocation_data.cust_reg_json.id_proof.first_name &&<div className='d-flex row align-items-center my-5 mx-2 align-items-center'>
                        <p className='no-margin text-white col-4 business_operations_text'> CUSTOMER NAME </p>
                        <h2 className='text-white pl-4 col-8 audit_kyc_text'> <b>{ capitalize_first_letter(this.props.handler_and_maplocation_data.cust_reg_json.id_proof.first_name.value)}</b> <b className='pl-3'>{ capitalize_first_letter(this.props.handler_and_maplocation_data.cust_reg_json.id_proof.last_name.value)}</b></h2>
                </div>}
                {get('market').country_code =="UGA" &&
                <>
                
                {this.props.handler_and_maplocation_data.cust_reg_json.gps_address.biz_address.parish.value &&
                <div className='d-flex row align-items-center  my-5 mx-2'>
                <p className='no-margin text-white col-4 business_operations_text'>  PARISH </p>
                <h2 className='text-white pl-4 col-8 audit_kyc_text'> {this.props.handler_and_maplocation_data.cust_reg_json.gps_address.biz_address.parish.value ?<b>{capitalize_first_letter(this.props.handler_and_maplocation_data.cust_reg_json.gps_address.biz_address.parish.value)} </b> : <p className='no-margin text-white'>-</p>}</h2>
                </div>
               }
               
                <div className='d-flex row align-items-center  my-5 mx-2'>
                <p className='no-margin text-white col-4 business_operations_text'> SUB COUNTY</p>
                <h2 className='text-white pl-4 col-8 audit_kyc_text'><b>{capitalize_first_letter(this.props.handler_and_maplocation_data.cust_reg_json.gps_address.biz_address.sub_county.value)} </b></h2>
                </div>

                <div className='d-flex row align-items-center  my-5 mx-2'>
                        <p className='no-margin text-white  col-4 business_operations_text '> COUNTY</p>
                        <h2 className='text-white col-8 pl-4 audit_kyc_text'><b> {this.props.handler_and_maplocation_data.cust_reg_json.gps_address.biz_address.county ? (capitalize_first_letter(this.props.handler_and_maplocation_data.cust_reg_json.gps_address.biz_address.county && this.props.handler_and_maplocation_data.cust_reg_json.gps_address.biz_address.county.value)):<p className='text-white no-margin'> - </p>}</b></h2>
                </div>
              
                </>
                }


                {this.props.handler_and_maplocation_data.cust_reg_json.gps_address.biz_address.sector && (get('market').country_code =="RWA") &&
                <div className='d-flex row align-items-center  my-5 mx-2'>
                <p className='no-margin text-white col-4 business_operations_text'>  SECTOR </p>
                <h2 className='text-white pl-4 col-8 audit_kyc_text'> {this.props.handler_and_maplocation_data.cust_reg_json.gps_address.biz_address.sector.value ?<b>{capitalize_first_letter(this.props.handler_and_maplocation_data.cust_reg_json.gps_address.biz_address.sector.value)}</b>:<p className='text-white no-margin'> - </p> }</h2>
                </div>
                }

               {this.props.handler_and_maplocation_data.cust_reg_json.gps_address.biz_address.village &&
                <div className='d-flex row align-items-center  my-5 mx-2'>
                <p className='no-margin text-white col-4 business_operations_text'>  VILLAGE </p>
                <h2 className='text-white pl-4 col-8 audit_kyc_text'> {this.props.handler_and_maplocation_data.cust_reg_json.gps_address.biz_address.village.value ?<b>{capitalize_first_letter(this.props.handler_and_maplocation_data.cust_reg_json.gps_address.biz_address.village.value)}</b>:<p className='text-white no-margin'> - </p> }</h2>
                </div>
                }
            
                <div className='d-flex row align-items-center  my-5 mx-2'>
                <p className='no-margin text-white col-4 business_operations_text'>  DISTRICT </p>
                <h2 className='text-white pl-4 col-8 audit_kyc_text'><b>{capitalize_first_letter(this.props.handler_and_maplocation_data.cust_reg_json.gps_address.biz_address.district.value)} </b></h2>
                </div>

                {  get('market').country_code === "MDG" && this.props.handler_and_maplocation_data.cust_reg_json.gps_address.biz_address.commune &&
                <div className='d-flex row align-items-center  my-5 mx-2'>
                <p className='no-margin text-white col-4 business_operations_text'>  COMMUNE </p>
                <h2 className='text-white pl-4 col-8 audit_kyc_text'><b>{capitalize_first_letter(this.props.handler_and_maplocation_data.cust_reg_json.gps_address.biz_address.commune.value)} </b></h2>
                </div>
                }
                
                { get('market').country_code !== "MDG" &&
                <div className='d-flex  row align-items-center my-5 mx-2'>
                        <p className='no-margin text-white col-4 business_operations_text '> LANDMARK</p>
                    <h2 className='text-white col-8 pl-4 audit_kyc_text'> {this.props.handler_and_maplocation_data.cust_reg_json.gps_address.biz_address.landmark.value ?<b>{capitalize_first_letter(this.props.handler_and_maplocation_data.cust_reg_json.gps_address.biz_address.landmark.value)}</b>:''} </h2>
                </div>
                } 

                <div className='d-flex  row align-items-center my-5 mx-2'>
                        <p className='no-margin text-white col-4 business_operations_text '>  COUNTRY</p>
                    <h2 className='text-white col-8 pl-4 audit_kyc_text'> <b>{(get('market').country)}</b></h2>
                </div>
                 
               </div>
              </div>
                { this.props.handler_and_maplocation_data.active_section &&
                  <div>
                      {this.props.handler_and_maplocation_data.active_section.verify &&
                        <div class="closedStamp ">
                        <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
                        </div>   
                      }
                  </div>
                }   
              </div>
              }
                <div className='col-md-6 col-lg-6' >
                <GoogleMapSetup coordinates={this.props.handler_and_maplocation_data.cust_reg_json.gps_address.gps.value} height={get('market').country_code == "UGA" ? '677px' : '556px'} />
                </div>
                <p className='no-margin text-white pt-3' style={{fontSize:'13px'}}><b style={{color:'red'}}>Note :</b> Zoom In/Zoom Out to verify if the gps location pointed by the RM matches with the business address captured</p>

              </div>
              </div>
              
              {this.props.handler_and_maplocation_data.active_section &&
              <div className='col-lg-10 py-2 px-5 mt-4'>
                {this.props.handler_and_maplocation_data.form_spinner ? 
                this.checkbox_spinner()
                :
                  <form className={`${(this.props.handler_and_maplocation_data.active_section.verify || (this.props.handler_and_maplocation_data.active_section.to_reassign==true) )? "disable_div":''}   ${ ((this.props.handler_and_maplocation_data.responseData.audited_by== get("user_id")) || (this.props.handler_and_maplocation_data.responseData.audited_by==null )) ?"":'disable_div'}`}>
                      <div className='d-flex align-items-center'>
                      <input type="checkbox" id="vehicle1"  className='pointer' style={{height:'19px',width:'20px'}} checked={this.props.handler_and_maplocation_data.active_section.flags.gps_address_match && true}  onChange={(e)=>this.props.checkbox_verification(e.target.checked,"gps_address_match")}/>
                      <label className='no-margin pl-3 audit_kyc_text pointer'  for="vehicle1">The location in the Business Address matches with the gps location  </label><br/><br/>
                      </div>
                      <br/>
                   
                  </form>
                  }  
              </div>
              }
              </>}
              </div>
             }
             </>
            }
            </div>

        )        
     }
}



export default AuditKYCMapandHandlerComponent;




