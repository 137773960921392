import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import {dd_value} from '../../../actions/common_actions';
import {get_repayment_summary,create_capture_payment,unlink_payment,update_waived_penalty} from '../../../actions/loan_actions';
import {rt_algn,lbl_amt,format_date} from '../../../helpers/common_helper';
import {get} from '.././../../helpers/storage_helper';
import { Link} from 'react-router-dom';
import {Button,Modal,Card,OverlayTrigger,Popover} from 'react-bootstrap';
import {TooltipWithElement,Remarks} from '../../common/partial';
import Logo from '../../common/partials/logo';
import { AiFillInfoCircle } from "react-icons/ai";
import SyncPayment from './SyncPayment';
import {check_priv} from '../../../helpers/storage_helper'


class ReconStatus extends FlowComponent{
   state = {
      repayment_style: "disable_div",
      unlink_payment:{},
      reviewed : 0,
      modalBox : false,
      modalBox2 : false,
      modal: null,
      manual_recon:{},
      opacity : "",
       
   }
   componentWillMount(){
      this.setState({data:this.props.data})
   }
   componentWillReceiveProps(props){

      //  const recon_status = props.record.recon_status
        //const record = props.record
      this.setState({data:props.data})
   }
   
    recon_status(data){
        const recon_status = data.recon_status
        const stmt_txn_type = data.stmt_txn_type
        if(this.props.show_btn == true && recon_status == "10_capture_payment_pending" ){
            return <SyncPayment id = "manual_recon"   mode="recon" title="Review & Sync" data={data} onSync={this.props.onSync}/>
        }
        else if(this.props.show_btn == false && recon_status == "80_recon_done"){
            return <span className="font-weight-bold" style = {{color:"#007bff"}}>{dd_value(recon_status)}</span> 
        }else if(this.props.show_btn == true && check_priv('recon', 'reinitiate_recon') && (recon_status == "31_paid_to_different_acc" || recon_status == "60_non_fa_credit" || recon_status == '11_capture_disb_rvrsl_pending' || recon_status == '90_payment_reversed' ||recon_status=="71_pending_manual_recon"||
                                                (stmt_txn_type == 'debit' && recon_status == "30_no_matching_fa" ) || (stmt_txn_type == 'credit' && recon_status == "30_no_matching_fa" ) || 
                                                (stmt_txn_type == 'debit' && recon_status == "75_redemption_int_transfer") || (stmt_txn_type == 'credit' && recon_status == '20_no_match_customers'))){
            return <SyncPayment id = "manual_recon" parent = {this.props.parent} mode="manual_recon" title="Manual Recon" data={data} onSync={this.props.onSync}/>
        }else if (this.props.show_btn == false){
            return dd_value(recon_status)
        }
    }


    recon_desc(data){
        const recon_status =data.recon_status
        const recon_desc = data.recon_desc
        const stmt_txn_type = data.stmt_txn_type
    
        if(recon_status == "70_incorrect_amount_in_fa"){
            return dd_value(recon_desc)
        }else if(recon_status == "50_multiple_fas_matched" || recon_status == "05_multiple_fas_captured"){
            var loan_doc_id = recon_desc.split(',')
            return <span><Link to={"/fa/view/"+loan_doc_id[0]} style = {{color:"grey"}} target="_blank" >{loan_doc_id[0]}</Link>&nbsp;|&nbsp;
                <Link to={"/fa/view/"+loan_doc_id[1]} style = {{color:"grey"}} target="_blank" >{loan_doc_id[1]}</Link></span>
        }else if(recon_status == "30_no_matching_fa" ||recon_status == "20_no_match_customers"){
            return dd_value(recon_desc)

        }else if(recon_status == "40_multi_match_customers"){
            var cust_id = recon_desc.split(',')
            return <span> <Link to={"/borrower/indiv/view/"+cust_id[0]} target="_blank">{cust_id[0]}</Link>&nbsp;|&nbsp;
                    <Link to={"/borrower/indiv/view/"+cust_id[1]} target="_blank" >{cust_id[1]}</Link></span>
        }


    }
    
     render(){
         return(
             <>
            {this.props.show_btn == false ?

               <td><TooltipWithElement base={this.recon_status(this.state.data)} overlay={this.recon_desc(this.state.data)}/></td>    
               
               :
               this.recon_status(this.state.data)
             
            }
             </>
             
             );
     }
    
}
export default ReconStatus;