export const typography = [
    {
        props: { variant: 'h1' },
        style: { fontSize: "2rem"}
    },
    {
        props: { variant: 'h2' },
        style: { fontSize: "1.5rem" ,fontWeight:'600'}
    }, 
    {
        props: { variant: 'h3' },
        style: { fontSize: "1.5rem" }
    }, 
    {
        props: { variant: 'h4' },
        style: { fontSize: "1rem" }
    }, 
    {
        props: { variant: 'h5' },
        style: { fontSize: "0.875rem" }
    }, 
    {
        props: { variant: 'h6' },
        style: { fontSize: "0.75rem" }
    }, 
    {
        props: { variant: 'sub_head' },
        style: { fontSize: "1.125rem " }
    }
]