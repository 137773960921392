import React from 'react';
import '../../../styles/flow.css';
import FlowContainer from '../../common/container/core/FlowContainer';
import $ from 'jquery';
import {listAgreement} from '../../../actions/agreement_actions';
import {dd_value} from '../../../actions/common_actions';
import StatusButton from '../../common/component/core/StatusButton';
import { format_date_tbl, capitalize_first_letter} from '../../../helpers/common_helper';
import { Link } from 'react-router-dom';
import { BasicReactTable } from '../../../helpers/react_table';

class ListAgreementContainer extends FlowContainer {

	state = {
     agreementList: null,
     rowdatas:[],
     columndatas:[],
     copy:[]
  	}

  	componentWillMount(){
  		this.setOptionsListToStateFromAppApi('common', 'list', this.def_req , ['acc_prvdr_code', 'name'], "acc_prvdrs");
  		listAgreement(this.req(null, true))
        .then((response) => {
          if(!response){return };
          if(response){          
          this.setState(state=>state.rowdatas = response.data)
          this.setState(state=>state.copy = response.data)
          this.setState(state => state.columndatas = this.columndatas())                   
          }                        

        
           });

  	}

    columndatas () {
      return [
          {
              Header : "Name",
              id: "name",
              accessor:row => {return row.name}
          },
          {
            Header : "Lender",
            id: "lender_code",
            accessor:row => {return row.lender_code}
          },
          {
            Header : "Agreement Type",
            id: "aggr_type",
            accessor:row => {return capitalize_first_letter(row.aggr_type)}
          },
          {
            Header : "Agreement Doc ID",
            id: "aggr_doc_id",
            accessor:row => {return row.aggr_doc_id}
          },
          {
            Header : "Duration",
            id: "aggr_duration",
            accessor:row => {return row.aggr_duration}
          },
          {
            Header : "Status",
            id: "status",
            accessor:row => <StatusButton id={row.id} entity="master_agreements" status={row.status} head='1'/>
          },
          {
            Header : "Action",
            id: "action",
            accessor:row => <StatusButton id={row.id} entity="master_agreements" status={row.status} head='2'/>
          },
          {
            Header : "View",
            id: "aggr_file_rel_path",
            accessor:row => {return row.aggr_file_rel_path ? 
                              <Link to={row.aggr_file_rel_path} target="_blank">View PDF</Link>
                              :
                              <span>NA</span>
                            }
          },
      ]
    }

    changeHandler = (e) => {      
      const toFilter = e
      const filterfrom = this.state.rowdatas      
      const filteredData = filterfrom.filter(value => {
        return(          
          value.name !== null && value.name.toLowerCase().includes(toFilter.toLowerCase()) ||
          value.lender_code !== null && value.lender_code.toLowerCase().includes(toFilter.toLowerCase()) ||
          value.aggr_type !== null && value.aggr_type.toLowerCase().includes(toFilter.toLowerCase()) ||
          value.aggr_doc_id !== null && value.aggr_doc_id.toLowerCase().includes(toFilter.toLowerCase())        
        )
      })
      this.setState(state=>state.copy = filteredData)		
    }

  	render(){
  		return(
        <div className='container containerTopmargin max-width no-padding'>
          <div>
              <h5 className='no-margin headerTitle'>Agreement List</h5>              
              {this.state.rowdatas.length > 0 &&
						  	<>
								<div className='d-flex justify-content-between align-items-center' style={{padding:5}}>
									<p className='no-margin text-white'><b>Total Entries : {this.state.copy.length}</b></p>
									<div style={{position:'relative'}}>
										<i class="fa-solid fa-magnifying-glass search_pos"></i>
										<input placeholder='Search here' className='search_posInput' onChange={(e)=>this.changeHandler(e.target.value)}></input>
										{/* <button className='btn-std btn btn-primary' onClick={()=>this.resetHandler()}>Reset</button> */}
									</div>
								</div>

                <BasicReactTable row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} pagination={this.state.copy && this.state.copy.length > 9 ? true : false} FName={"Agreement Lists"} export_csv_datas={this.state.copy ? this.state.copy : []}  defaultSorted={[{id:"name",desc:false}]}  default_page_size = {10} csv_file={true} searchData={[{title:'List Master Agreement'}]}/>  	

							
							</>
						  }
          </div>
        </div>

  			);
  	}

}

export default ListAgreementContainer;