import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import {check_priv} from '../../../helpers/storage_helper';
import '../../../styles/flow.css';
import FileUpload from '../../common/component/FileUpload';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import {format_date} from '../../../helpers/common_helper';
import {Edit} from '../../common/partial';

import ReconfirmBoxes from '../../common/component/core/ReconfirmBoxes';
export class Borrower extends FlowComponent{
	  state = {
    comp_name: "borrower",   
    biz_type : 'individual',
    cus_type: "show_div",
  
  }


  componentDidMount()
  {
    this.handleChange({target : {id : "use_as_ac_num" , value: true}}); 

    /*if(this.state.use_as_ac_num)
    {
      this.props.onCheck(this.state.use_as_ac_num);
    }*/
    
  }
  componentWillMount(){
    
    super.componentWillMount();

    //this.setOptionsToStateFromApi("data_provider");
    this.setOptionsToStateFromApi("lender");   
    this.load_dp_rel_mgr();
    
    /*var associated_with =  "data_prvdr";
    const associated_entity_code = 'dp_code';
    this.setOptionsToStateFromApi("rel_mgr", {...this.def_req, associated_with, associated_entity_code})
    */

    var associated_with =  "flow";
    // this.setOptionsToStateFromApi("rel_mgr", {...this.def_req, associated_with});
    this.setOptionsToStateFromApi("rel_mgr", {...this.def_req, associated_with}, null, "kyc");
    
    if(this.props.mode == "view"){
      this.setState({cus_type: "disable_div"})
    }
    
    
  }



 handleCustTypeChange = (e) => {
    
   
   const biz_type = e.target.value
    if(
        (biz_type == 'flow_rm' && check_priv('flow_rel_mgr', 'create')) ||
        (biz_type == 'data_prvdr_rm' && check_priv('data_prvdr_rel_mgr', 'create')) ||
        biz_type == 'individual'
      ){  
          this.handleChange(e); 
          this.props.onCustTypeChange(e); 
          this.setState({biz_type})
          
            
   }else{
     alert('You can not create a ' + biz_type + " customer")
     window.location.reload();
   }
   
 }

 handleCheck = (event) => {
  if(event.target.checked)
  {
    this.handleChange({target : {id : "use_as_ac_num" , value: true}}); 
  }
  else
  {
    this.handleChange({target : {id : "use_as_ac_num" , value: false}}); 

  }
}

 /* handleCheck = (e)=>{
    var use_as_ac_num = e.target.checked ? true : false
    this.setState({use_as_ac_num});
    
 }*/


render(){
      
      return (
      <div id="" >
       <div className="row no-margin align-items-center floatAdvancetitleBox agreementDateHeaderBox">
        <div className="col-md-4"><h4 className='no-margin headerTitle'>{this.props.title ? this.props.title : "Customer Profile"}</h4></div>
        <div className={`col-md-1 ${this.state.edit_button_style}`} >
          <Edit entity="customer" onClick={this.handleEdit}/>
          {/*<input type="button" onClick={this.handleEdit} value="Edit"/>*/}
         </div>
          <div className={`col-md-1 ${this.state.save_button_style}`}>
          <input  onClick={this.handleSave} type="button" className="btn btn-success btn-std" value="Save"/>
        </div>
        
      </div>
      
      <div className={this.state.div_style}>
        <div className="row no-margin labelTopgap align-items-end">
            <div className="col-md-3">
              <span className='viewCus_label'>Business Name</span><font className="text-danger">   *  </font>
              <input id="biz_name" value={this.props.data.biz_name} onChange={this.toUpperHandleChange}  className="form-control" required />
            </div>
          

          
            <div className={`col-md-3 ${this.state.cus_type}`} >
                <span className='viewCus_label'>Customer Type</span><font className="text-danger">   *  </font>
                <select id="biz_type" value={this.props.data.biz_type}  
                onChange={(e) => { this.handleCustTypeChange(e)}} className="form-control" type="text" required="required">
                   {this.getSelectOptions("borrower_type")}
                </select>
            </div>
          
          
             <div className="col-md-3">
                <span className='viewCus_label'>Business Address Type</span><font className="text-danger">   *  </font>
                <select id="biz_addr_prop_type" value={this.props.data.biz_addr_prop_type}  onChange={this.handleChange} className="form-control" type="text" required="required">
                   {this.getSelectOptions("biz_addr_prop_type")}
                </select>
            </div>

            <div className="col-md-3" >	
							<span className='viewCus_label'>Ownership </span><font className="text-danger">   *  </font> <br/><br/>
							<label><input id="ownership" type="radio" name="ownership" value="owned" onChange={this.handleChange} checked = {this.props.data.ownership === 'owned'} />  Owned &nbsp;&nbsp;&nbsp;</label>
							<label><input id="ownership" type="radio" name="ownership" value="rented" onChange={this.handleChange} checked = {this.props.data.ownership === 'rented'} />  Rented &nbsp;&nbsp;&nbsp;</label>
					</div>

        </div>        

          {/*<div className="col-md-3">
            <span>Data Provider</span><font className="text-danger">   *  </font>

            <select id="data_prvdr_code" value={this.props.data.data_prvdr_code}  onChange={(e) => { this.handleChange(e); this.handleDPChange(e) }} className="form-control" type="text" required="required">
               {this.state.data_provider_dd}
            </select>
          </div>
            <div className="col-md-3">
             <span>Run ID</span>
            <input type="text" id="csf_run_id" value={this.props.data.csf_run_id} onChange={this.handleChange}  className="form-control" />
          </div>
          
          */}
         
         
          
          <div className="row no-margin labelTopgap align-items-end">
            <div className="col-md-3">
              <span className='viewCus_label'>Business distance from Home</span><font className="text-danger">   *  </font>
              <select id="business_distance" value={this.props.data.business_distance}  onChange={this.handleChange} className="form-control" type="text" required="required">
                  {this.getSelectOptions("business_distance")}
              </select>
            </div>
            {this.props.mode !== "view" &&
            <>
              <ReconfirmBoxes className="col-md-3" label="Data Provider Customer ID" id="data_prvdr_cust_id" onChange={this.handleChange}/>
              <div className="col-md-3" >              
                <label className="checkbox-inline">
                  <input id="use_as_ac_num" type="checkbox" defaultChecked 
                    onChange = {(e) => {this.handleCheck(e)}}/>
                  <span> Use this DP cust ID as A/C number for disbursal credit</span>
                </label>
              </div>              
            </> 
            }
          </div> 

          <div className="row labelTopgap no-margin align-items-end">
            <div className="col-md-3">
              <span className='viewCus_label'>Lender</span><font className="text-danger">   *  </font>
              <select id="lender_code" value={this.props.data.lender_code} onChange={this.handleChange} className="form-control" type="text" required="required">
                {this.state.lender_dd}                
              </select>
            </div>
            <div className="col-md-3">
              <span className='viewCus_label'>Account Provider's Relationship Manager</span><font className="text-danger">   *  </font>
              <select id="dp_rel_mgr_id" value={this.props.data.dp_rel_mgr_id}  onChange={this.handleChange} className="form-control" type="text" required="required">
                  {this.state.acc_prvdr_rel_mgr_dd}
              </select>
            </div>

            <div className="col-md-3">
              <span className='viewCus_label'>Flow Relationship Manager</span><font className="text-danger">   *  </font>
              <select id="flow_rel_mgr_id" value={this.props.data.flow_rel_mgr_id}  onChange={this.handleChange} className="form-control" type="text" required="required">
                {this.state.flow_rel_mgr_dd}
              </select>
            </div>
            <div className="col-md-3">
              <span className='viewCus_label'>Registered Date</span><font className="text-danger">   *  </font>
              <DatePicker  value={format_date(this.props.data.reg_date)} closeOnSelect={true} onChange={(value) => {this.handleDateChange("reg_date", value)}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
            </div>  
          </div>                      
          <div className="row">                       
            {(this.props.title === "Customer Details") &&
              <div className="col-md-3">
                <span>Number of Tills</span>
                <select id="number_of_tills" value={this.props.data.number_of_tills} onChange={this.handleChange} className="form-control" required >
                  <option value="">Choose</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
            }  
        </div>        
        {(this.state.biz_type =='individual' || this.state.biz_type =='institutional') && 
          <>
            <div className="d-flex labelTopgap no-margin">
              <div className="col-md-6">
                <FileUpload id="photo_shop" label="Establishment Photo" borrower_id={this.props.data.id}
                cust_id={this.props.data.cust_id} db_file_name={this.props.data.photo_shop} 

                type="image" mode={this.props.mode} editField={(e)=>this.setEditedFields(e)} onRemove={()=> window.location.reload()} onCreateUpload={this.handleChange}
                onEditUpload={(e) => {this.handleChangeForEdit(e)}} 
                />
              </div>                     
            </div>            
            <div className="d-flex labelTopgap no-margin">
              <div className="col-md-6">
                <FileUpload id="photo_biz_lic" label= "Business Licence" borrower_id={this.props.data.id} 
                cust_id={this.props.data.cust_id} db_file_name={this.props.data.photo_biz_lic} 
                type="image" mode={this.props.mode} editField={(e)=>this.setEditedFields(e)} onRemove={()=> window.location.reload()} onCreateUpload={this.handleChange}
                onEditUpload={(e) => {this.handleChangeForEdit(e)}} />
              </div>
            </div>       
          </>
        }
        <div className="row labelTopgap no-margin" style={{paddingBottom:23}}>
          <div className="col-md-6">
            <span className='viewCus_label'>Remarks</span>
            <textarea id="remarks" value={this.props.data.remarks} onChange={this.handleChange}  className="form-control" required ></textarea>
          </div>         
        </div>      
      </div>
      </div>
);

}
}
export default Borrower;
