import React from 'react';
import Select from "react-select";
import FlowComponent from '../../common/component/core/FlowComponent';
import ConfirmModal from '../../common/component/ConfirmationModal';
import { Modal, Button } from 'react-bootstrap';
import * as moment from "moment";

import { addWatchLIst,viewWatchHistory,unWatchProfile } from '../../../actions/borrower_actions';
import { format_date_tbl, lbl_amt } from '../../../helpers/common_helper';
import { check_priv, get } from '../../../helpers/storage_helper';
import { dd_value } from '../../../actions/common_actions';

class WatchlistProfile extends FlowComponent {


    constructor(props) {
        super(props);
        this.state = {
            watch_history_datas: [],
            openModalKeys:{},
            show_reasons_prev : ['risk_manager','verification_officer']
        };
    }

    componentWillMount() {
    }


    handleOnChange(val, type) {

        var obj = { ...this.state.criteria_arr }
        obj[type] = val
        if (val == "") {
            delete obj[type];
        }
        this.setState({ criteria_arr: obj })
    }

    submitHandler() {
        this.setState({ loader: true })
        // this.flaggedCustomersList(this.state.criteria_arr)

    }

    toggle_modal(type = null) {
        if (type == "confirm") {

            if(this.state.openModalKeys.add_watch_list){
                this.handleAddWatchLIst("confirm", this.state.unwatch ? "unwatch" : "addtoWatch")

            }
            else{
                this.un_watchlist("confirm")
            }
        }

        if(type=="cancel"){
            this.setState({remarksArr:[],watchList_reason:"",watchList_remarks:""})

        }
        
        
        this.setState({ confimation_message: !this.state.confimation_message },()=>{this.props.showModal()
        })
    }

    handleModalClose = (event) => {
        this.setState({
            modalBox1: false,
            modalBox2: false, mapShow: false,
           
        });
        this.props.showModal()

    }

    handleAddWatchLIst(type = null, Watchlist = null) {
        var req = { cust_id: this.props.customer.cust_id, reason: [this.state.watchList_reason], remarks: this.state.watchList_remarks }
        if (type == "confirm") {
            addWatchLIst(this.req(req))
                .then((response) => {
                    if (!response) {
                        return
                    }

                    if (response.status == "success") {
                        this.setState({ is_addedToWatchList: true ,remarksArr:[],watchList_reason:"",watchList_remarks:""})
                        this.props.view_borrower()
                        alert(response.message)
                    }

                })
        }
    }

    setWatchlistRemarks(value) {
        const myArray = value.split(" ");
        const nonEmptyRemarks = myArray.filter((remark) => remark !== "");

        this.setState({
            remarksArr: nonEmptyRemarks, watchList_remarks: value
        });
    }

    getWatchHistory (){
        this.setState({loader:true})

      var req={cust_id:this.props.customer.cust_id}
      viewWatchHistory(this.req(req))
      .then((response)=>{
        if(!response){return   this.setState({loader:false})
    }
        if(response.status=="success"){
            this.setState({ watch_history_datas :response.data,loader:false})
            
        }
      })
    }

    un_watchlist(type=null){

        var req={watchlist_id:this.props.customer.watchlist_id,cust_id:this.props.customer.cust_id}
        if (type == "confirm") {
        unWatchProfile((this.req(req)))
        .then((response)=>{
            if(!response){return}
            if(response.status=="success"){
            this.props.view_borrower()

                alert(response.message)
            }
        })
    }
    }

   openModal(key) {

 // this.setState({ modalBox1: true, unpicked_calls: false, profile_summary: false, add_watch_list: (( (this.props.customer.watchlist_id)) ? false : true), onwatchModal: ((this.props.customer.watchlist_id)) ? true : false })

    var arr = ["unpicked_calls", "profile_summary", "add_watch_list", "onwatchModal","watch_history"];
    var obj={}
    for (var i = 0; i < arr.length; i++) {
        if (key === arr[i]) {

            if(key=="add_watch_list"){
                obj[key] = this.props.customer.watchlist_id ? false : true
            }else{
                obj[arr[i]] = true

            }
            this.props.showModal()

        }else{
            if(key=="add_watch_list"){
                obj["onwatchModal"] = this.props.customer.watchlist_id ? true : false 
            }else{
                obj[arr[i]] = false

            }

        }

    }
      this.setState({openModalKeys:obj,modalBox1:true})
   
        }

    getHistory(data_type,addl_data=[]) {
        var a = [];
        a.push(
            <div class={` w-100 my-4 late_loan_parent `} >

                <div className={`row d-flex align-items-center w-100 m-0 py-4 late_loans_table `}>
                    <p className='m-0 col-4 text-center viewCus_labelVal left_border text-white'> {(data_type == "call_records") ? "Calls Made" : (data_type == "late_loans" ? "Amount" : '')} </p>
                    <p className='m-0 col-4 text-center viewCus_labelVal left_border text-white'> {(data_type == "call_records") ? "Unpicked Calls" : (data_type == "late_loans" ? "Due Date" : '')} </p>
                    <p className='m-0 col-4 text-center viewCus_labelVal sizem right_border text-white'>
                        {(data_type == "call_records") ? "Last Call On" : (data_type == "late_loans" ? "Settled Date" : '')}
                    </p>

                </div>

                <div className={`w-100 ${(data_type == "late_loans" && this.props.customer[data_type] && this.props.customer[data_type].length > 6) ? "scroll_table" : ""} `}>
                    {data_type == "call_records" ?
                        <>{this.props.customer[data_type] &&

                            <div className={`row d-flex my-3 mx-0 align-items-center`} >
                                <p className='text-white p-0 col-4 text-center'>{this.props.customer[data_type].total_calls ? this.props.customer[data_type].total_calls : "0"}</p>
                                <p className='text-white p-0 col-4 text-center'>{this.props.customer[data_type].unpicked_calls ? this.props.customer[data_type].unpicked_calls : "0"}</p>
                                <p className='text-white p-0 col-4 text-center'>{this.props.customer[data_type].last_call_date ? format_date_tbl(this.props.customer[data_type].last_call_date) : "0"}</p>
                            </div>

                        }  </>

                        :
                        <>
                        { (addl_data.length >0 ? true:  (this.props.customer[data_type] && this.props.customer[data_type].length > 0)) && 
                        <>
                            { ( addl_data.length >0 ? addl_data :this.props.customer[data_type]).map((item, idx) => {
                                return (
                                    <div className={`row d-flex my-3 mx-0 align-items-center`} key={idx}>
                                        <p className='text-white p-0 col-4 text-center'>{lbl_amt(item.loan_principal, get('market').currency_code)}</p>
                                        <p className='text-white p-0 col-4 text-center'>{format_date_tbl(item.due_date)}</p>
                                        <p className='text-white p-0 col-4 text-center'>{format_date_tbl(item.paid_date)}</p>
                                    </div>
                                )
                            })}
                        </>}
                        </>
                    }
                </div>
            </div>

        )
        return a;
    }

  
  
    render() {
        const row_position = ((this.props.customer.late_loans && !this.props.customer.late_loans.length >0) && ((this.props.customer.call_records&& !this.props.customer.call_records.unpicked_calls>0))) ;
        return (
            <div className='w-100'>
                <div className={`no-padding  col-lg-10 w-100 d-flex align-items-center text-center ${row_position ? " adjusted_top_pos":""}`}>
                   {/* {(this.props.customer.late_loans && this.props.customer.late_loans.length)>0 && */}
                   
                   <>
                    <h2 className="my-0 text-white ml-3 agreementDateHeaderBoxdate">Total late repayments  </h2>
                    <p className='my-0 ml-4 text-white viewCus_labelVal sizexm'> {this.props.customer.repay_perc ? this.props.customer.repay_perc + " %" : "0%"} ({(this.props.customer.late_loans && this.props.customer.late_loans.length >0) ? this.props.customer.late_loans.length : 0})</p>
                    <p className='my-0 mx-3  statusStyle text_decoration pointer' onClick={() => {
                        this.openModal("profile_summary")
                     }}>Details</p>
                     {(this.props.customer.call_records&&this.props.customer.call_records.unpicked_calls >0) && 
                        <p className='my-0 mx-4 sizel text-white '> |</p>}

                    </>
                    {/* } */}

                    <div className={`no-padding   d-flex align-items-center text-center `}>
                      {/* {(this.props.customer.call_records&&this.props.customer.call_records.unpicked_calls >0) &&  */}
                       <>
                        <h2 className="my-0 text-white agreementDateHeaderBoxdate">Unpicked Calls </h2>
                        <p className='my-0 ml-4 text-white sizexm viewCus_labelVal'> {this.props.customer.call_records ?(  this.props.customer.call_records.call_perc ?this.props.customer.call_records.call_perc:0) + "% " + `(${this.props.customer.call_records.unpicked_calls ?this.props.customer.call_records.unpicked_calls:0})` : ""} </p>
                        <p className='my-0 mx-3  pointer statusStyle text_decoration' onClick={() => {
                            this.openModal("unpicked_calls")
                            //  this.setState({ modalBox1: true, unpicked_calls: true, profile_summary: false, add_watch_list: false })
                              }}>Details</p>
                        </>
                        {/* // } */}
                        <div className={`d-flex align-items-center`}>
                          {check_priv("customer","watchlist") &&
                            <button className={`btn btn-sm sm_bttn_padding mx-3 ${( (this.props.customer.watchlist_id)) ? "btn-warning" : "btn-primary "}`} onClick={() => { 
                            this.openModal("add_watch_list")} }>
                                 {( (this.props.customer.watchlist_id)) ? "On Watch" : "ADD TO WATCHLIST"}
                            </button>
                             }

                            <button className='btn btn-primary btn-sm sm_bttn_padding mx-3' onClick={() => {  this.openModal("watch_history");this.getWatchHistory(); 
                                // this.setState({ modalBox1: true, unpicked_calls: false, profile_summary: false, add_watch_list: false, onwatchModal: false, watch_history: true })
                                
                                }} >VIEW WATCH HISTORY</button>

                        </div>
                    </div>

                </div>
                {this.state.confimation_message &&
                    <ConfirmModal alert_message={this.state.unwatch ? "Are your sure you want to remove this customer from the watchlist?" : "Are your sure you want to add this customer in the watchlist? "} is_toggle={(a) => { this.toggle_modal(a) }}  />
                }

                <Modal show={this.state.modalBox1} onHide={this.handleModalClose} size={(this.state.openModalKeys.profile_summary || (this.state.openModalKeys.unpicked_calls || this.state.openModalKeys.add_watch_list || this.state.openModalKeys.onwatchModal) || (this.state.openModalKeys.watch_history)) ? "" : "xl"}>

                    <Modal.Header closeButton className=" py-0 d-flex align-items-center profile_summary_modal" show={this.state.modalBox1} onHide={this.handleModalClose}>
                        {(this.state.openModalKeys.profile_summary || this.state.openModalKeys.add_watch_list || this.state.openModalKeys.unpicked_calls || this.state.openModalKeys.onwatchModal || this.state.openModalKeys.watch_history) &&
                            <div className='w-100'>
                                <p className='m-0 text-white w-100 text-center viewCus_labelVal sizexm'> <b>
                                    {this.state.openModalKeys.profile_summary ? " Late Repayments" : this.state.openModalKeys.unpicked_calls ?
                                        "Unpicked Calls" : this.state.openModalKeys.add_watch_list ? "Add to Watchlist" : this.state.openModalKeys.onwatchModal ? "On Watch" :
                                            this.state.openModalKeys.watch_history ? "Watch History" : ""}
                                </b></p>
                            </div>

                        }

                    </Modal.Header>

                    <Modal.Body>
                        {!this.state.loader ?
                        <>

                        {(this.state.openModalKeys.profile_summary || this.state.openModalKeys.unpicked_calls) ?
                            <div>
                                <p className='text-white m-0 viewCus_labelVal text-center sizexm '> {this.state.openModalKeys.profile_summary ? `Total late repayments : ${(this.props.customer.late_loans && this.props.customer.late_loans.length >0) ? this.props.customer.late_loans.length: 0}` : `Unpicked Calls :  ${this.props.customer.call_records ?( this.props.customer.call_records.unpicked_calls ?  this.props.customer.call_records.unpicked_calls :0 ): null}`} </p>

                                {this.props.customer.late_loans && this.props.customer.late_loans.length > 0 &&
                                    <div>
                                        {this.getHistory(this.state.openModalKeys.unpicked_calls ? 'call_records' : this.state.openModalKeys.profile_summary ? 'late_loans' : null)}
                                    </div>
                                }
                            </div>

                            :
                            <>
                                {
                                    this.state.openModalKeys.add_watch_list ?
                                        <div>
                                            <fieldset className={` h-100 py-0 px-3 ${"new_re_kyc_text_bg_color"} `} style={{ border: '1px solid white', background: "#202940", borderRadius: '10px' }}>
                                                {this.state.watchList_reason ? <legend className={`add_watchlist_text  `}>
                                                    <b> Reason </b>
                                                </legend> : ""}


                                                <div className='d-flex align-items-center justify-content-between addWishList col-12 px-0'>


                                                    <select id="visit_purpose" className={`form-control ${this.state.watchList_reason ? "" : "my-2"} w-100 col-12 px-0`} onChange={(e) => { this.setState({ watchList_reason: e.target.value }) }} >
                                                        <option value="">Reason</option>
                                                      
                                                     
                                                        {this.state.show_reasons_prev.includes(get('role_codes')) ?
                                                        this.getSelectOptions("under_watch",true,null,null,false,"remove_choose") 
                                                        :
                                                        this.getSelectOptions("rms_watchlist",true,null,null,false,"remove_choose")
                                                      }

                                                      
                                                    </select>

                                                </div>
                                            </fieldset>


                                            <div className='col-lg-12 addwatchlistdiv p-0 my-3'>
                                                <textarea rows="4" placeholder='Remarks' className='w-100 p-3 addwatchListTextarea' onChange={(e) => { this.setWatchlistRemarks(e.target.value) }} maxLength='260'>
                                                </textarea>
                                                <div className={`d-flex align-items-center  ${(this.state.watchList_remarks && this.state.watchList_remarks.length > 0) ? "justify-content-between" : "justify-content-end"}`}>

                                                    {(this.state.watchList_remarks && (this.state.watchList_remarks.length ==260)) ? 
                                                    <p className=' m-0 d-flex text-danger justify-content-start'>Maximum level reached</p>:
                                                
                                                    <p className=' m-0 d-flex text-danger justify-content-start'> {(this.state.watchList_remarks && this.state.remarksArr && this.state.watchList_remarks.length > 0 && this.state.remarksArr.length < 5) ? "Minimum 5 words required" : ""}</p>
                                                    }
                                                    <p className='text-white m-0 d-flex justify-content-end'>{this.state.watchList_remarks ? this.state.watchList_remarks.length : 0}/260</p>

                                                </div>
                                            </div>

                                            <div className='d-flex justify-content-center my-2'>
                                                <button className={`btn btn-primary `}  disabled={(this.state.watchList_remarks && this.state.watchList_reason &&  ( this.state.remarksArr &&this.state.remarksArr.length > 4)) ? false : true} onClick={() => { this.setState({ confimation_message: true, modalBox1: false, unwatch: false }) }}>Submit</button>

                                            </div>
                                        </div>
                                        :

                                        this.state.openModalKeys.onwatchModal ? <div>
                                            <p className='m-0 text-white text-center viewCus_labelVal sizexm'>Total late repayments : {(this.props.customer.late_loans && this.props.customer.late_loans.length >0) ? this.props.customer.late_loans.length : 0}</p>

                                            {this.props.customer.watchlist_rec &&<>
                                            <p className='m-0 text-white sizexm mt-4'> Reason</p>
                                            <p className='mb-0 mt-2 text-white viewCus_labelVal sizexm' mt-2 > {(this.props.customer.watchlist_rec.reason && this.props.customer.watchlist_rec.reason.length>0) ? 
                                            <>{
                                            this.props.customer.watchlist_rec.reason.map((item,idx)=>{return(
                                                <span>{dd_value(item,["rms_watchlist","under_watch"])} {(this.props.customer.watchlist_rec.reason.length-1) !=idx ? ",":""} </span>
                                            )})
                                             } </>
                                            :""}</p>

                                            <p className='m-0 text-white sizexm mt-4'> Remarks</p>
                                            <p className='mb-0  mt-2 text-white viewCus_labelVal sizexm mt-2'> {this.props.customer.watchlist_rec.remarks ? this.props.customer.watchlist_rec.remarks :""}</p>

                                            <div className='d-flex align-items-center my-3 mt-5 justify-content-center'>
                                                <Button variant="primary" onClick={() => {this.un_watchlist(); this.setState({ confimation_message: true, unwatch: true, modalBox1: false }) }}>unwatch</Button>

                                            </div>
                                            </>}
                                        </div>
                                            :

                                            this.state.openModalKeys.watch_history ?
                                                <div className={`${this.state.watch_history_datas.length >4 ? "past_churn_remarks_scroll":""}`}>
                                                   
                                                   {this.state.watch_history_datas.length >0 ?
                                                    <div>
                                                    { this.state.watch_history_datas.length >0 && this.state.watch_history_datas.map((item, idx) => {
                                                        return (
                                                            <div className={` py-2 px-3 my-2 mb-4 ${(!item.released_on)
                                                             ? "warning-border" : "border-light"} `}>
                                                                <div className='d-felx justify-content-end'>
                                                                    <p className='d-felx justify-content-end  text-white m-0 ' style={{ float: 'right' }}> {format_date_tbl(item.initiated_on)} to {(!item.released_on
                                                                    ) ? <span className='text-warning  ' > <b>till now</b></span> : moment(item.released_on).format('DD MMM YYYY')} </p>
                                                                </div>
                                                                <p className='viewCus_labelVal sizexm mb-0 mt-3 '> {(item.reason && item.reason.length>0)?
                                                                
                                                                  <>{
                                                                   item.reason.map((val,id)=>{return(
                                                                        <span>{dd_value(val,["rms_watchlist","under_watch"])} {(item.reason.length-1) !=id ? ",":""} </span>
                                                                    )})
                                                                    } </>
                                                                :"-"}</p>

                                                                <p className='text-white  d-flex  my-3 '>  Initiated By  <b className='ml-2'> {item.initiated_by} </b>{item.released_by ? <span className='ml-3'> | Released by  <b> {item.released_by}</b></span> : ""}</p>

                                                                <p className='text-white m-0'>Total Late Repayment <b className='ml-2'>{(item.late_loans && item.late_loans.length>0) ? item.late_loans.length:"0"} </b>  </p>
                                                                {!this.state[`read_more_${idx}`] && item.late_loans.length>0 &&
                                                                    <p className='m-0 text-white pointer d-flex justify-content-end' onClick={() => { this.setState({ [`read_more_${idx}`]: !this.state[`read_more_${idx}`] }) }}> Read more</p>}

                                                                {this.state[`read_more_${idx}`] ?

                                                                    <div className='my-3'>{this.getHistory("late_loans",item.late_loans)}
                                                                        {this.state[`read_more_${idx}`] &&

                                                                            <p className='mb-0 text-white pointer d-flex justify-content-end' onClick={() => { this.setState({[`read_more_${idx}`] : !this.state[`read_more_${idx}`] }) }}>Read less</p>}
                                                                    </div> :
                                                                    ""
                                                                }
                                                            </div>)
                                                    })}
                                                    </div>:
                                                    <p className='my-5 text-white text-center'>NO DATA</p>
                                                    }
                                                </div>

                                                :

                                                ""}</>

                        }
                        </>:
                        
                        <div style={{margin:"15% 0px"}} > <p className='text-light mt-3 text-center'>Loading...</p>
                        <div className="product_takeup_loader"></div>
                        </div>
                        
                        }
                    </Modal.Body>

                </Modal>


            </div>

        );
    }
}
export default WatchlistProfile;
