import React from 'react';
import { Button} from 'react-bootstrap';
import {checkExistingOtp, resend_OTP} from "../../../actions/loan_actions";
import { check_priv } from '../../../helpers/storage_helper';
import FlowComponent from "./core/FlowComponent";


class ResendCode extends FlowComponent {
    state = {
        disabled: true,
        timer_min: -1,
        timer_sec: null,
        timer: null
    }

    componentDidMount(){
        
        const request = {'entity_id': this.props.entity_id, 'otp_type': this.props.otp_type}
        checkExistingOtp(this.req(request)).then( (response) => {
            if(!response){return}
            else{
                if(response.data.status == true){
                    let admin_priv = check_priv("loan","view_fa_capture_disbursal")
                    if(response.data.expired){
                        if(admin_priv)
                            this.setState({expired: true, disabled: false, timer_min: 0, timer_sec:0})
                        else
                            this.setState({expired: true, timer_min: 0, timer_sec:0})
                    return
                    }
                    const timer_min = ~~(response.data.time_left/60);
                    const timer_sec = response.data.time_left - (timer_min * 60)
                    this.setState({timer_min, timer_sec })
                    this.runTimer()
                    if(!admin_priv){
                        this.disableButton()
                        setTimeout(() => {
                            this.enableButton()
                        },response.data.time_left * 1000)
                    }else{
                        this.enableButton()
                    }
                }else{
                    this.enableButton()
                }
            }   
        })
    }

    disableButton = () => this.setState({disabled: true})
    enableButton = () => this.setState({disabled: false})


    resendOTP = (event) => {
        const request = {"entity_id" : this.props.entity_id, 'otp_type': this.props.otp_type};
        this.disableButton()
        resend_OTP(this.req(request)).then((response) => {
            if(!response){
                this.enableButton()
            }
            else{
                this.componentDidMount()
                alert(response.message)
            }
    });
    }

    runTimer = () =>{
        clearInterval(this.state.timer)
        let timer = setInterval(() => this.tick(),1000)
        this.setState({timer: timer}) 
    }

    tick() {

        if(this.state.timer_min < 0)
        {
            return
        }
     
        if(this.state.timer_sec == 0 || this.state.timer_sec != 0 )
        {
            this.setState({timer_sec: this.state.timer_sec - 1});
            if(this.state.timer_sec < 0)
            {
                this.setState({
                    timer_min: this.state.timer_min - 1,
                    timer_sec : 59
                 })
            }
        }
    }



    render(){
        return(
            <div>

            <div>
            <Button variant="primary"  disabled={this.state.disabled} onClick={() => {
                if(window.confirm("At the time of FA application, the app had sent the confirmation code to the customer as an SMS . Click 'OK' if you are sure if the customer has not received the SMS with confirmation code and would like to send the confirmation code SMS with confirmation code again.")){this.resendOTP()}
            }}>{this.props.buttonName}</Button>
             </div>

            <div className="text-labelblue">
            {((this.state.timer_min * 60) + this.state.timer_sec) > 0 &&

            <span> {this.state.timer_min}m {this.state.timer_sec}s to resend</span>}
            {this.state.expired &&
            <span> Resend Limit Exceeded</span>}
            </div>
            </div>
        )
    }



}

ResendCode.defaultProps = {
                            buttonName: "Resend Code"
                          }

export default ResendCode;
