import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import '../../../styles/flow.css';
import {Edit} from '../../common/partial';
//import {updateRelationshipManager} from '../../../actions/relationship_manager_actions';


export class RelationshipManager extends FlowComponent{
    state = {
    comp_name: "rel_mgr",
    list: "disable_div"
  }
  componentWillMount(){
    super.componentWillMount();
    this.setOptionsToStateFromApi("acc_prvdr");

  }


 /* handleSelectboxChange = (event) => {
      const request = {"country_code": this.country_code,"associated_entity_id":this.state.data_prvdr};
    listRelationshipManager(request)
        .then((response) => {
          if(!response){return };
          //alert(JSON.stringify(response.data));
          //this.setState({relationshipManager: response.data});
 
                    
          
              const relationshipManagerList = response.data.map((relationshipManager,i)=>
             
          
              <tr>
                <td key={i}> {relationshipManager.id}</td>
                <td key={i}> {relationshipManager.first_name}</td>
                <td key={i}> {relationshipManager.whatsapp}</td>
                <td key={i}> {relationshipManager.mobile_num}</td>
                <td key={i}> {relationshipManager.email_id}</td>
                <td key={i}> {relationshipManager.designation}</td>
                <td key={i}> <input id={relationshipManager.id} type='submit' className='' onClick={this.handleClick} value='View / Edit'  class='btn btn-primary' /></td>
              </tr>
                                              );
          

          this.setState({relationshipManagerList: relationshipManagerList});
        });

  }*/

render(){
    
      return (
      <div id="" >
      <div className={this.props.mode === "view" ? "hide_div": "show_div"}>
       <div className="row">
        <div className="col-md-6"><h4>{this.props.title ? this.props.title : ""}</h4></div>

        <div className={`col-md-1 ${this.state.edit_button_style}`} >
          <Edit entity={this.props.entity} onClick={this.handleEdit}/>
          {/*<input type="button" onClick={this.handleEdit} value="Edit"/>*/}
         </div>
          <div className={`col-md-1 ${this.state.save_button_style}`}>
          <input  onClick={this.handleSave} type="button" className="btn btn-success" value="Save"/>
        </div>
        
      </div>
     {/* <div className={this.state.div_style}>
        <div className="row">
            <div className="col-md-3">
              <span>Data Provider</span><font className="text-danger">   *  </font>
              <select id="associated_entity_code" value={this.props.data.associated_entity_code} onChange={ this.props.mode === "list" ? (e) => { this.handleChange(e); this.props.loanOnChange(e); } : this.handleChange}  className="form-control" type="text" required="required">
                 {this.state.data_provider_dd}
              </select>
            </div>
        </div>    
         <br/>
        </div>
        <hr/>*/}
      </div>

    </div>
);

}
}
export default RelationshipManager;