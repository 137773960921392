
import React, { useState} from "react";
import moment from "moment";
import { BiAlarm} from "react-icons/bi";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FlowContainer from '../../common/container/core/FlowContainer';
import ReportChart from '../component/ReportChart';
import BarChart from '../component/BarChart';
import {convertMinutesToHours, get_cs_agent_names} from '../../../helpers/common_helper';
import { getCurrencyDetails, inbound_voice_call_report } from '../../../actions/report_actions';
import { get } from '../../../helpers/storage_helper';
import { AiOutlineUserSwitch,AiOutlineSchedule, AiOutlineFieldTime } from "react-icons/ai";
import { MdAlarm } from "react-icons/md";
import { FaUserNurse} from "react-icons/fa";
import { getFloatSwitchAccounts } from '../../../actions/account_txn_actions';
import { GiBackwardTime} from "react-icons/gi";
import ReactApexChart from 'react-apexcharts';


class It_opertations_yesterday_results extends FlowContainer {
  constructor(props) {
    super(props);

    this.state={
      Disbursement_count:[{"title":"Returned Disbursement","value":5},
        {"title":"Duplicate Disbursement","value":0},
        {"title":"Un-Returned Disbursement","value":0}],
        ChurnManagementCount:[{"title":"Churn Count","value":5},
      {"title":"Calls made by CS","value":0},
      {"title":"FAs taken over CS call","value":0}],
        active:'Today',
        totalValue:[],
        TotalAndOverdueFA:[],
        Faapplied_by_count:[],
        activeCurrencyVal:'',
        startDate:moment().subtract(1, 'days').format('YYYY-MM-DD'),
        endDate:moment(new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD"),
        FAApplications:[],
        total_fas:null,
        country_code: get('market').country_code,
        history_datas:'',
        active_currency:'',
        penalty_view:false,
        view_bttn:false,
        acc_prvdr:null,
        DelayReasons:[],
        Delay_total:[],
        Disbursement_delay:null,
        DisbursementDelayMinutes:60,
        Disbursement_time_x_axis:[],
        Disbursement_time_Y_axis:[],
        Disbursement_total:[],
        apply_to_disb_time_chart:[],
        disb_time_all_bttn:true,
        repayment_time_x_axis:[],repayment_time_y_axis:[],repayment_total:[],
        repayment_time:{
          manual_capture:true,
          auto_capture:true,
        },
        lazy_loading:[],
        disbursement_spin_loader:false,
        disbursement_checkbox:
            {
             manual_approval :true,
             auto_approval:true, 
             auto_capture:true,
             manual_capture:true,
             multi_attempt:true,   
             single_attempt:true, 
             otp:true,
             not_otp:true,
             manual_disbursal:true,
             auto_disbursal:true,
             },
             loader:false,
      inbound_voice_chart_data: []
    };
  }

  componentWillMount() {
    this.setState({ startDate: this.props.data.start_date == "" ? this.state.startDate : this.props.data.start_date, endDate: this.props.data.end_date == "" ? this.state.endDate : this.props.data.end_date, country_code: this.props.data.country_code, history_datas: this.props.data.history_data })

    this.props.report_history()
    this.props.set_attr({ lazy_loading: this.state.lazy_loading })
    this.currency_details()
    this.loadAccounts()

  }
  currency_details() {
    getCurrencyDetails(this.req())
      .then((response) => {
        if (response.status == "success") {
          this.setState({ currency_details: response.data, active_currency: response.data[this.props.data.country_code].currency_code })
        }
      })
  }
  tabCheck = (e) => {

    this.setState({
      ...this.state,
      active: e,

    })
  }

  float_switch_accounts_options(accounts) {
    var arr = [];
    if (accounts.length > 0) {
      for (var i = 0; i < accounts.length; i++) {
        for (var j = 0; j < accounts.length; j++) {
          if (accounts[i] !== accounts[j]) {
            var obj = {
              from: accounts[i],
              to: accounts[j],
            };

            arr.push(obj);
          }
        }
      }
    }
    this.setState({ switch_acc_options: arr })
  }

  loadAccounts = () => {
    var account = { "country_code": this.country_code };
    getFloatSwitchAccounts(this.req({ account }, true))
      .then((response) => {
        this.resp(response)
        if (!response) { return };
        if (response.status === "success") {
          if (response.data != "") {
            this.setState({ accounts: response.data.acc_prvdrs })
            this.float_switch_accounts_options(response.data.acc_prvdrs)

          }
        } else {
          alert(response.message + "\n" + JSON.stringify(response.data));
        }
      }
      );

  }

  get_switch_accounts_options = (accounts, all = false) => {

    let acc_prvdr = all ? "ALL" : accounts.to
    this.setState({
      selected_options: accounts, switch_perf_data: [{ data: this.state.switch_perf_report.data[acc_prvdr], name: this.state.switch_perf_report.name }],
      both_switchs: all, switch_perf_data_label: this.state.switch_perf_report.data_label[acc_prvdr]
    })

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps == undefined || prevProps.data.start_date != this.props.data.start_date) {
      this.props.report_history()
      if (this.state.lazy_loading != undefined && this.state.lazy_loading.length > 0) {
        this.props.report_history(this.state.lazy_loading)

      }
    }
    if (this.props.data.country_code != this.state.country_code) {

      var curreny_code = this.state.currency_details ? this.state.currency_details[this.props.data.country_code].currency_code : ''
      this.setState({ active_currency: curreny_code, country_code: this.props.data.country_code, loader: true }, () => this.props.report_history(this.state.lazy_loading != undefined && this.state.lazy_loading.length > 0 ? this.state.lazy_loading : null),
        this.props.set_attr({ loader: true })

      )
    }
    if (this.props.data.gds_report_active_accPrvds != this.state.acc_prvdr) {
      if (this.props.data.gds_report_active_accPrvds != null) {
        this.setState({ acc_prvdr: this.props.data.gds_report_active_accPrvds }, () => {
          this.props.report_history()
          this.props.set_attr({ loader: true })
          if (this.state.lazy_loading != undefined && this.state.lazy_loading.length > 0) {
            this.props.report_history(this.state.lazy_loading)

          }
        }
        )
      }

        
    }
    if (JSON.stringify(this.props.data.history_data) !== JSON.stringify(this.state.history_datas) || prevState.DisbursementDelayMinutes != this.state.DisbursementDelayMinutes) {

      this.setState({ history_datas: this.props.data.history_data, loader: this.props.data.loader ? false : false, disbursement_spin_loader: false })

      if (this.props.data.history_data.appl_approvals) {
        const FAApplications = [{ data: this.props.data.history_data.appl_approvals.data, name: 'No. of FAs' }]
        const data = this.props.data.history_data.appl_approvals.category
        const total_fas = this.props.data.history_data.appl_approvals.total
        this.setState({ data, FAApplications, total_fas })
      }

      if (this.props.data.history_data.disb_delay_reason) {
        const DelayReasons = this.props.data.history_data.disb_delay_reason.category
        const Disbursement_delay = this.props.data.history_data.disb_delay_reason.data[0]
        const Delay_total = this.props.data.history_data.disb_delay_reason.data[0][this.state.DisbursementDelayMinutes].total
        const delay_chart = Object.values(Disbursement_delay[Object.keys(Disbursement_delay).filter((key) => key == this.state.DisbursementDelayMinutes)])
        const popped = delay_chart.pop()
        const delay_chart_data = [{ data: delay_chart, name: 'No. of FAs' }]
        this.setState({ DelayReasons, Delay_total, Disbursement_delay, delay_chart_data })
      }

      if (this.props.data.history_data.rm_visit_chart) {

        const TotalAndOverdueFARaw = this.props.data.history_data.rm_visit_chart;
        const TotalAndOverdueFA = this.make_series_data(TotalAndOverdueFARaw);
        this.setState({ TotalAndOverdueFARaw, TotalAndOverdueFA, TotalAndOverdueFADiv: true })

      }
      if (this.props.data.history_data.fa_applied_by_count){
        const Faapplied_by_countFARaw = this.props.data.history_data.fa_applied_by_count;
        const Faapplied_by_count = this.make_series_data(Faapplied_by_countFARaw);
        this.setState({Faapplied_by_countFARaw, Faapplied_by_count, Faapplied_by_countDiv: true })
      }
      if (this.props.data.history_data.float_switch_report_day_wise) {

        const sales_report_raw = this.props.data.history_data.float_switch_report_day_wise;
        const sales_report = this.make_series_data(sales_report_raw);
        this.setState({ sales_report_raw, sales_report, sales_report_rawDiv: true })

      }
      if (this.props.data.history_data.apply_to_disb_time_chart) {
        const Disbursement_time_x_axis = [{ data: this.props.data.history_data.apply_to_disb_time_chart.data, name: 'No. of FAs' }]
        const Disbursement_time_Y_axis = this.props.data.history_data.apply_to_disb_time_chart.category
        const Disbursement_total = this.props.data.history_data.apply_to_disb_time_chart.total
        this.setState({ Disbursement_time_x_axis, Disbursement_time_Y_axis, Disbursement_total, disbursement_spin_loader: false })

      }
      if (this.props.data.history_data.repay_to_settle_time_chart) {
        const repayment_time_x_axis = [{ data: this.props.data.history_data.repay_to_settle_time_chart.data, name: 'No. of FAs' }]
        const repayment_time_y_axis = this.props.data.history_data.repay_to_settle_time_chart.category
        const repayment_total = this.props.data.history_data.repay_to_settle_time_chart.total
        this.setState({ repayment_time_x_axis, repayment_time_y_axis, repayment_total })
      }

      if (this.props.data.history_data.switch_perf_report) {
        const switch_perf_report = this.props.data.history_data.switch_perf_report
        const switch_perf_data = [{ data: switch_perf_report.data.ALL , name: switch_perf_report.name }]
        const switch_perf_data_label = switch_perf_report.data_label.ALL
        this.setState({ switch_perf_report, switch_perf_data, switch_perf_data_label });
      }

      if (this.props.data.history_data.disb_perf_report) {
        const disb_perf_report = this.props.data.history_data.disb_perf_report
        const disb_perf_data = [{ data: disb_perf_report.data, name: disb_perf_report.name }]
        const disb_perf_data_label = this.props.data.history_data.disb_perf_report.data_label
        this.setState({ disb_perf_report, disb_perf_data, disb_perf_data_label });
      }
      if (this.props.data.start_date !== this.state.startDate) {
        this.setState({ startDate: this.props.data.start_date })
      }
      if (this.props.data.history_data && this.props.data.history_data.disb_dup_n_rtn_count) {
        const { return_disb, dup_disb } = this.props.data.history_data.disb_dup_n_rtn_count;
    
        if (return_disb !== undefined && dup_disb !== undefined) {
          const totalValue = return_disb + dup_disb;
          const series = [return_disb, dup_disb];
    
          this.setState({TotalAndOverdueFARaw: this.props.data.history_data.disb_dup_n_rtn_count,series,TotalAndOverdueFADiv: true});
          this.setState({TotalAndOverdueFARaw: this.props.data.history_data.disb_dup_n_rtn_count,totalValue,TotalAndOverdueFADiv: true});
        }
      }
      if(this.props.data.history_data.fas_pending_disbursement){
         
        const fas_pending_disbursement = this.props.data.history_data.fas_pending_disbursement;
        const fas_pending_tittle_order = ['rd_count','dd_count','un_rd_count'];
        const emptyarray=[];

        fas_pending_tittle_order.forEach((items,idx)=>{
          emptyarray[idx] = fas_pending_disbursement[items]
        })
        this.setState({Disbursement_count:emptyarray,fas_pending_disbursement:this.props.data.history_data.fas_pending_disbursement})
       }
       if(this.props.data.history_data.churn_management_count){
         
        const ChurnManagementCountRaw =this.props.data.history_data.churn_management_count;
        const ChurnManagementCountRaw_order = ['chu_count','cmd_cs_count','tk_cs_rd_count'];
        const emptyarray=[];

        ChurnManagementCountRaw_order.forEach((items,idx)=>{
          emptyarray[idx] = ChurnManagementCountRaw[items]
        })
        this.setState({ChurnManagementCount:emptyarray,churn_management_count:this.props.data.history_data.churn_management_count})
       }

    }


  }



  make_series_data = (array, type) => {

    var series = [];
    const curVal = this.state.activeCurrencyVal == '' ? 1 : this.state.activeCurrencyVal
    var orginal_data = JSON.parse(JSON.stringify(array));
    var data_arr = []
    var startDate = this.state.startDate ? this.state.startDate : ""
    var endDate = this.state.endDate ? this.state.endDate : ""
    if (startDate !== "" || endDate !== "") {

      Object.keys(orginal_data).map(function (key) {
        data_arr[key] = [];
        orginal_data[key].map(function (each, idx) {
          let crnt_date = moment(orginal_data[key][idx]['x'])
          data_arr[key].push(orginal_data[key][idx])
        })
      })
    }
    else {
      data_arr = orginal_data
    }

    Object.keys(data_arr).map(function (key) {


      var series_data = {};
      if (key != "*") {
        series_data['name'] = key
      } else {
        series_data['name'] = "Global"
      }

      if (type == 'currency' && key != '*') {
        data_arr[key].map(function (each, idx) {
          if (idx != "type") {
            data_arr[key][idx]['y'] = data_arr[key][idx]['y'] * curVal
          }
        })
      }
      series_data['data'] = data_arr[key]
      if ("type" in data_arr[key]) {
        series_data['type'] = data_arr[key]["type"];
      }
      series.push(series_data)
    });
    return series;
  }

  common_percentage_cal(val, total) {
    if (total == 0) {
      return "0 % ";
    }
    else if ((val / total) * 100 >= 0) {
      return (val / total * 100).toFixed() + "% ";
    } else {
      return "0 % ";
    }

  }

  disbursement_time_charts(type, val) {

    var disbursement_checkbox = this.state.disbursement_checkbox
    disbursement_checkbox[type] = !val
    this.setState({ disbursement_checkbox }, () => {

      const datas = this.state.disbursement_checkbox
      var flg = true;
      for (const key in datas) {

        if (datas[key] == false) {
          flg = false
          this.setState({ disb_time_all_bttn: false })
          break;
        }

      }
      if (flg) {
        this.setState({ disb_time_all_bttn: true })
      }

      this.props.report_history("apply_to_disb_time_chart", this.props.data.start_date, this.props.data.end_date, this.props.data.gds_report_active_accPrvds, this.props.data.country_code, { "apply_to_disb_time_chart": this.state.disbursement_checkbox })

    })

  }
  disb_time_all_handler() {

    const datas = this.state.disbursement_checkbox
    var disb_val = this.state.disbursement_checkbox;
    for (const key in datas) {
      disb_val[key] = true

    }
    this.setState({ disbursement_checkbox: disb_val, disb_time_all_bttn: true })
    this.props.report_history("apply_to_disb_time_chart", this.props.data.start_date, this.props.data.end_date, this.props.data.gds_report_active_accPrvds, this.props.data.country_code, { "apply_to_disb_time_chart": this.state.disbursement_checkbox })
  }

  repayment_time_charts(type, val) {
    var repayment_time = this.state.repayment_time
    repayment_time[type] = !val
    this.setState({ repayment_time }, () => {

      this.props.report_history("repay_to_settle_time_chart", this.props.data.start_date, this.props.data.end_date, this.props.data.gds_report_active_accPrvds, this.props.data.country_code, { "repay_to_settle_time_chart": this.state.repayment_time })
    })
  }

  lazy_loading_handler(val) {


    var lazy_loading_array = this.state.lazy_loading
    lazy_loading_array.push(val)
    this.setState({ lazy_loading: lazy_loading_array, val: true })

    this.props.set_attr({ lazy_loading: lazy_loading_array })
    this.props.report_history(lazy_loading_array.length > 0 ? lazy_loading_array : null)
    this.setState({ val: false })
  }
  handleDelay = (e) => {
    this.setState({ DisbursementDelayMinutes: e })
  }
      get_inbound_voice_call_data = ()=>{
        var request ={report_type:'inbound_voice_calls_downtime',acc_prvdr:this.props.active_acc_prvdr,start_date:this.props.data.start_date,end_date:this.props.data.end_date,screen:'past'}

        inbound_voice_call_report(this.req({...request})).then((response)=>{
          if(!response) return;
          if(response.status =='success'){
            if(response.data){
              let x_axis_data = response.data.map((val)=>{
                let match = get_cs_agent_names(val.x);
                if (match) {
                    return match;
                }
                return val.x;
              })
              let y_axis_data_in_mins = response.data.map((val)=>val.y)

              let y_axis_data_in_hrs = response.data.map((val)=>{
                let formattedTime = convertMinutesToHours(val.y);
                return formattedTime;
              })

              this.setState({inbound_voice_chart_data:[{data:y_axis_data_in_mins,name:'Down Time In Minutes'},{data:y_axis_data_in_hrs,name:'Down Time In Hours'}],x_axis_inbound_voice_data:x_axis_data})
            }
          }
          else{
            return;
          }

        }).catch(error=>{
          alert(error)
        })
      }
      

    render(){
  
    return (
      <div>

        <div className='row ' style={{ background: '#4291da', padding: '15px' }}>

          <div className='d-flex align-items-center'>
            {this.props.data.start_date_view_btn ? "" :
              <p className='text-white no-margin business_operations_text biz_operation_font_size' style={{ fontSize: '18px' }}><b>{this.props.data.today_btn ? "Today" : this.props.data.yester_day_btn ? "Yesterday" : this.props.data.day_bf_btn ? "Day b/f y/day" : this.props.data.this_month_btn ? "This Month" : this.props.data.last_month_btn ? "Last Month" : ""}</b></p>
            }

            {this.props.data.start_date_view_btn ?

              <div className='d-flex align-items-center'>
                <p className='no-margin biz_operation_font_size' ><b>{`${moment(this.props.data.start_date).format('DD MMM YYYY')} - ${moment(this.props.data.end_date).format('DD MMM YYYY')}`}</b>  </p>

              </div>
              :
              <div className='d-flex align-items-center biz_operation_font_size'>
                {this.props.data.this_month_btn ?
                  <>
                    <p className='no-margin biz_operation_font_size' ><b>{`${moment(this.props.data.start_date).format('DD MMM YYYY')} - ${moment(this.props.data.end_date).format('DD MMM YYYY')}`}</b>  </p>
                  </>
                  :
                  <div className='d-flex align-items-center'>
                    {this.props.data.last_month_btn ?
                      <>
                        <p className='no-margin biz_operation_font_size' ><b>{`${moment(this.props.data.start_date).format('DD MMM YYYY')} - ${moment(this.props.data.end_date).format('DD MMM YYYY')}`}</b>  </p>
                      </> :
                      <>
                        {this.props.data.last_days_and_month_btn ?
                          <p className='no-margin biz_operation_font_size' ><b>{`${moment(this.props.data.start_date).format('DD MMM YYYY')} - ${moment(this.props.data.end_date).format('DD MMM YYYY')}`}</b>  </p>

                          :
                          <>
                            <p className='no-margin biz_operation_font_size' ><b>{this.state.startDate == moment().subtract(1, 'days').format('dddd') ? moment().subtract(1, 'days').format('dddd') : moment(this.state.startDate).format('dddd')} <span className='biz_operation_font_size' > | </span></b>  </p>
                            <p className='no-margin biz_operation_font_size'  ><b>{this.state.startDate == moment().subtract(1, 'days').format('dddd') ? moment().subtract(1, 'days').format('DD MMM YYYY') : moment(this.state.startDate).format('DD MMM YYYY')}</b></p>
                          </>
                        }

                      </>
                    }

                  </div>

                }

              </div>

            }
          </div>
        </div>
        <div style={{ minHeight: '80vh' }}>
          {this.props.data.loader ?
            <div class="loader">

              <div class="cogs">
                <div class="cog cog0">
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                </div>
                <div class="cog cog1">
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                </div>
                <div class="cog cog2">
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                </div>
              </div>
              <div className='load_dot'>
                <div class="dot">F</div>
                <div class="dot">L</div>
                <div class="dot">O</div>
                <div class="dot">W</div>
                <div class="dot">I</div>
                <div class="dot">N</div>
                <div class="dot">G</div>
              </div>

            </div>
            :
            <>
              <div className='d-flex align-items-center justify-content-between row'>
                <div className='col-md-12  col-lg-6 col-sm-12'>
                <div div className=' business_operations_border biz_operation_fas_pending' style={{position:'relative',minHeight:'341px'}}>
            <div className='row'>
                <div className='d-flex align-items-center '>
                  <div className='d-flex align-items-center biz_operation_font_size' style={{ margin: '5px 0px 0px 10px' }}>
                    {/* <BiAlarm size={30} color={"white"} style={{ marginTop: '-10px' }} /> */}
                    <img className={`img-fluid `}src={`/img/Disbursal.png`} alt={""}  style={{ marginTop: '-10px',width:"25%" }}/> 

                    <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', fontSize: '18px',fontWeight:"bold" }}>Disbursal</h2>
                  </div>
                  <div>
                  {this.state.fas_pending_disbursement ? " " :
                          <div className='ml-4'> <button type="button" class="btn btn-outline-secondary btn-sm mb-0" style={{ color: 'white', borderRadius: '10px' }} onClick={() => this.lazy_loading_handler("fas_pending_disbursement")}> <b>Show</b></button> </div>
                          }
                  </div>
                </div>
              </div>
              <div className='business_operations_bottom_border'></div>
              <div className='d-flex  align-items-center row justify-content-around' style={{marginTop:"69px"}}>
                      {this.state.Disbursement_count.map((item, idx) => (
                      
                        <div className='col-3  business_operations_border biz_opr_fas_pending biz_operation_font_size' style={{background:"linear-gradient(166.76deg, #182747 0.08%, #7DA6CB 181.75%)"}} key={idx}>
                          <div style={{marginBottom:'10%'}}>
                            <p className='text-white no-margin text-center' style={{fontSize: '16px',fontWeight:"bold",paddingBottom:"33px",paddingTop:"31px"}}> {item.title} </p>
                          </div>
                          <div className='d-flex align-items-center justify-content-center' style={{ marginTop: '7%' ,paddingBottom:"28px"}}>
                              <p className={` no-margin   ${this.state.fas_pending_disbursement ? " " : 'float_account_balance_bgblur'}`}> <b className='' style={{color:"rgba(236, 148, 152, 1)",fontSize:"23px"}}> {item.value} </b></p>
                          </div>
                        </div>
                    ))}
                </div>
            </div>

                </div>


                <div className='col-md-12  col-lg-6 col-sm-12' >
                <div className='business_operations_border biz_operation_fas_pending' style={{ height: '340px' }}>
                    <div className='row'>
                      <div className='d-flex align-items-center biz_operation_font_size ' style={{ margin: '10px 0px 0px 10px' }}>
                        <AiOutlineUserSwitch size={25} color={"white"} style={{ marginTop: '-4px' }} />
                        <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', color: '#FFFFFF', fontSize: '18px', fontWeight: 'bold' }}  >RM Visit</h2>
                      </div>
                    </div>
                    <div className='business_operations_bottom_border' ></div>
                    {this.state.history_datas.rm_metrics ? "" :
                      <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary " style={{ color: 'white' }} onClick={() => this.lazy_loading_handler('rm_metrics')} > <b>Show</b></button> </div>
                    }
                   <div className={`${this.state.history_datas.rm_metrics ? "" : 'float_account_balance_bgblur'}`}>
                      <Tabs
                        defaultActiveKey={this.state.active}
                        id="justify-tab-example"
                        className={`mb-3`}
                        justify style={{ marginTop: '10px' }}
                        onSelect={(e) => this.tabCheck(e)}
                      >

                        <Tab eventKey="Today" title={this.state.active == 'Today' ? <button type="button" class="btn  no-margin " style={{ padding: '5px 112px', "background-color": "rgba(255, 255, 255, 0.83)",color:"rgba(0, 0, 0, 1)","font-size" : "16px ","border-radius" : "7px","font-weight":"700"}}> <b> Check-In</b></button> : <p className='text-white no-margin' style={{fontWeight:"bold"}}> Check-In</p>}>

                          <div className={`row d-flex justify-content-around align-items-center `} style={{  marginTop: '23px',marginRight:"14px",marginLeft:"1px" }}>
                            <div className='col h-100' >
                              <div className='biz_operration_padding  biz_opr_fas_pending  regular_checkin' style={{height: "173px",border: "1px solid rgba(125, 166, 203, 1)",background:"linear-gradient(180deg, rgba(43, 72, 101, 1), rgba(43, 72, 101, 0)"}}>
                                <p className='text-white no-margin text-center' style={{ "padding-top": "16px" ,fontSize: '16px', fontWeight: 'bold'}}>Regular Check-In</p>
                                <div className=" d-flex justify-content-around align-items-center" style={{"margin-top": "50px"}}>
                                  <p className=' no-margin text-center fas_pending_icon_color yesterday_penalty_text_color' style={{ padding: '6px 0px',color:"rgba(235, 199, 232, 1)" }}> <b style={{ fontSize: '20px', fontWeight: 'bold'}}>{this.state.history_datas.rm_metrics ? this.state.history_datas.rm_metrics.field_visits.regular_checkin_count : ''}</b></p>
                                  <h2 className=' no-margin text-center biz_ops_green_text'> <b style={{ fontSize: '20px', fontWeight: 'bold'}}>{this.state.history_datas.rm_metrics ? this.common_percentage_cal(this.state.history_datas.rm_metrics.field_visits.regular_checkin_count, this.state.history_datas.rm_metrics.field_visits.total) : ''}</b></h2>
                                </div>
                              </div>

                            </div>

                            <div className='col h-100' style={{}}>
                              <div className='biz_operration_padding  biz_opr_fas_pending ' style={{ height: "173px",border: "1px solid rgba(125, 166, 203, 1)",background:"linear-gradient(180deg, rgba(43, 72, 101, 1), rgba(43, 72, 101, 0)" }}>
                                <p className='text-white no-margin text-center' style={{ "padding-top": "16px" ,fontSize: '16px', fontWeight: 'bold'}}>Force Check-In <br /> <span style={{ fontSize: '16px' }}>(Inside circle)</span></p>
                                <div className="d-flex justify-content-around align-items-center" style={{"margin-top": "35px"}}>
                                <p className=' no-margin text-center fas_pending_icon_color yesterday_penalty_text_color' style={{ padding: '6px 0px',color:"rgba(235, 199, 232, 1)" }}> <b style={{ fontSize: '20px', fontWeight: 'bold'}}>{this.state.history_datas.rm_metrics ? this.state.history_datas.rm_metrics.field_visits.force_checkin_in_count : ''}</b></p>
                                <p className='text-white no-margin text-center biz_ops_green_text'> <b style={{ fontSize: '20px', fontWeight: 'bold'}}>{this.state.history_datas.rm_metrics ? this.common_percentage_cal(this.state.history_datas.rm_metrics.field_visits.force_checkin_in_count, this.state.history_datas.rm_metrics.field_visits.total) : ''}</b></p>
                              </div>
                              </div>
                            </div>
                            <div className='col h-100' style={{}}>
                              <div className='biz_operration_padding  biz_opr_fas_pending ' style={{ height: "173px",border: "1px solid rgba(125, 166, 203, 1)",background:"linear-gradient(180deg, rgba(43, 72, 101, 1), rgba(43, 72, 101, 0)" }}>
                                <p className='text-white no-margin text-center' style={{ "padding-top": "16px" ,fontSize: '16px', fontWeight: 'bold'}}>Force Check-In <br /> <span style={{ fontSize: '16px' }}>(Outside circle)</span></p>
                                <div className=" d-flex justify-content-around align-items-center" style={{"margin-top": "35px"}}>
                                <p className=' no-margin text-center fas_pending_icon_color yesterday_penalty_text_color' style={{ padding: '6px 0px' ,color:"rgba(235, 199, 232, 1)"}}> <b style={{ fontSize: '20px', fontWeight: 'bold'}}>{this.state.history_datas.rm_metrics ? this.state.history_datas.rm_metrics.field_visits.force_checkin_out_count : ''}</b></p>
                                <h2 className='text-white no-margin text-center biz_ops_green_text'> <b style={{ fontSize: '20px', fontWeight: 'bold'}}>{this.state.history_datas.rm_metrics ? this.common_percentage_cal(this.state.history_datas.rm_metrics.field_visits.force_checkin_out_count, this.state.history_datas.rm_metrics.field_visits.total) : ''}</b></h2>
                              </div>
                              </div>
                            </div>
                          </div>

                        </Tab>
                        <Tab eventKey="YesterDay" title={this.state.active === 'YesterDay' ? 
                              <button type="button" class="btn  no-margin " style={{ padding: '5px 112px', "background-color": "rgba(255, 255, 255, 0.83)",color:"rgba(0, 0, 0, 1)","font-size" : "16px ","border-radius" : "7px","font-weight":"700"}}> <b> Check-Out</b></button> : 
                              <p className='text-white no-margin' style={{fontWeight:"bold"}}> Check-Out</p>}>

                            <div className={`row d-flex justify-content-around align-items-center `} style={{  marginTop: '23px',marginRight:"14px",marginLeft:"1px" }}>
                              <div className='col h-100' >
                                <div className='biz_operration_padding  biz_opr_fas_pending  regular_checkin' style={{ height: "173px", border: "1px solid rgba(125, 166, 203, 1)",background:"linear-gradient(180deg, rgba(43, 72, 101, 1), rgba(43, 72, 101, 0)" }}>
                                  <p className='text-white no-margin text-center' style={{ paddingTop: "16px", fontSize: '16px', fontWeight: 'bold' }}>Regular Check-Out</p>
                                  <div className=" d-flex justify-content-around align-items-center" style={{ marginTop: "39px" }}>
                                    <p className='no-margin text-center fas_pending_icon_color yesterday_penalty_text_color' style={{ padding: '6px 0px',color:"rgba(235, 199, 232, 1)" }}> <b style={{ fontSize: '20px', fontWeight: 'bold' }}>{this.state.history_datas.rm_metrics ? this.state.history_datas.rm_metrics.field_visits.regular_checkout_count : ''}</b></p>
                                    <h2 className='no-margin text-center biz_ops_green_text'> <b style={{ fontSize: '20px', fontWeight: 'bold' }}>{this.state.history_datas.rm_metrics ? this.common_percentage_cal(this.state.history_datas.rm_metrics.field_visits.regular_checkout_count, this.state.history_datas.rm_metrics.field_visits.total) : ''}</b></h2>
                                  </div>
                                </div>
                              </div>

                              <div className='col h-100' style={{}}>
                                <div className='biz_operration_padding  biz_opr_fas_pending ' style={{ height: "173px", border: "1px solid rgba(125, 166, 203, 1)" ,background:"linear-gradient(180deg, rgba(43, 72, 101, 1), rgba(43, 72, 101, 0)"}}>
                                  <p className='text-white no-margin text-center' style={{ paddingTop: "16px", fontSize: '16px', fontWeight: 'bold' }}>Force Check-Out<br /> <span style={{ fontSize: '16px' }}>(Inside circle)</span></p>
                                  <div className=" d-flex justify-content-around align-items-center" style={{ marginTop: "35px" }}>
                                    <p className='no-margin text-center fas_pending_icon_color yesterday_penalty_text_color' style={{ padding: '6px 0px',color:"rgba(235, 199, 232, 1)" }}> <b style={{ fontSize: '20px', fontWeight: 'bold' }}>{this.state.history_datas.rm_metrics ? this.state.history_datas.rm_metrics.field_visits.force_checkout_in_count : ''}</b></p>
                                    <h2 className='text-white no-margin text-center biz_ops_green_text'> <b style={{ fontSize: '20px', fontWeight: 'bold' }}>{this.state.history_datas.rm_metrics ? this.common_percentage_cal(this.state.history_datas.rm_metrics.field_visits.force_checkout_in_count, this.state.history_datas.rm_metrics.field_visits.total) : ''}</b></h2>
                                  </div>
                                </div>
                              </div>
                              <div className='col h-100' style={{}}>
                                <div className='biz_operration_padding  biz_opr_fas_pending ' style={{ height: "173px", border: "1px solid rgba(125, 166, 203, 1)",background:"linear-gradient(180deg, rgba(43, 72, 101, 1), rgba(43, 72, 101, 0)" }}>
                                  <p className='text-white no-margin text-center' style={{ paddingTop: "16px", fontSize: '16px', fontWeight: 'bold' }}>Force Check-out <br /> <span style={{ fontSize: '13px' }}>(Outside circle)</span></p>
                                  <div className=" d-flex justify-content-around align-items-center" style={{ marginTop: "35px" }}>
                                    <p className='no-margin text-center fas_pending_icon_color yesterday_penalty_text_color' style={{ padding: '6px 0px' ,color:"rgba(235, 199, 232, 1)"}}> <b style={{ fontSize: '20px', fontWeight: 'bold' }}>{this.state.history_datas.rm_metrics ? this.state.history_datas.rm_metrics.field_visits.force_checkout_out_count : ''}</b></p>
                                    <h2 className='text-white no-margin text-center biz_ops_green_text'> <b style={{ fontSize: '20px', fontWeight: 'bold' }}>{this.state.history_datas.rm_metrics ? this.common_percentage_cal(this.state.history_datas.rm_metrics.field_visits.force_checkout_out_count, this.state.history_datas.rm_metrics.field_visits.total) : ''}</b></h2>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </Tab>


                      </Tabs>
                    </div>
                  </div>
                </div>

              </div>
              <div className='d-flex align-items-center justify-content-between row'>
                <div className='col-md-12 col-lg-6 col-sm-12'>
                <div className='business_operations_border biz_operation_fas_pending' style={{ height: '340px' }}>

                    <div className='row'>
                        
                        <div className='d-flex align-items-center'>
                            
                            <div className='d-flex align-items-center biz_operation_font_size' style={{ margin: '10px 0px 0px 10px' }}>
                                <img className={`img-fluid `}src={`/img/Disbursal.png`} alt={""}  style={{ marginTop: '-10px',width:"12%" }}/> 
                                    <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', color: '#FFFFFF', fontSize: '18px', fontWeight: 'bold' }} >Churn Management</h2>
                            </div>

                            
                            <div>
                                {this.state.history_datas.churn_management_count ? " " :
                                <div className='ml-4'> <button type="button" class="btn btn-outline-secondary btn-sm mb-0" style={{ color: 'white', borderRadius: '10px' }} onClick={() => this.lazy_loading_handler("churn_management_count")}> <b>Show</b></button> </div>
                                }
                            </div>

                        </div>

                    </div>
                    
                    <div className='business_operations_bottom_border'></div>

                    <div className='d-flex justify-content-between align-items-center row ' style={{padding: '5%' ,marginTop:"14px"}}>

                        {this.state.ChurnManagementCount.map((item, idx) => (

                            <div className='col-4' key={idx} >

                                <div className=' biz_operration_padding yesterday_penalty business_operations_border  ' >
                                {item.title === "Churn Count" && (
                                      <div style={{height:"150px"}}>
                                        <div className='align-items-center justify-content-center' style={{marginTop:"38px"}} >
                                          <p className='text-white no-margin text-center' style={{ fontSize: '16px', fontWeight: 'bold' ,padding: "0px 35px"}}> {item.title} </p>
                                        </div>
                                        <div className='align-items-center justify-content-center ' style={{marginTop:"66px"}}>  
                                          <p className={`text-white no-margin  text-center ${this.state.history_datas.churn_management_count ? " " : 'float_account_balance_bgblur'}`}>
                                            <b className=''  style={{color:"rgba(223,140, 148, 1)", fontSize: '23px', fontWeight: 'bold'}}> {item.value} </b>
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  {item.title === "Calls made by CS" && (
                                    <div style={{height:"150px"}}>
                                      <div className='align-items-center justify-content-center ' style={{marginTop:"38px"}}>
                                        <p className='text-white no-margin text-center' style={{ fontSize: '16px', fontWeight: 'bold',padding: "0px 32px"}}> {item.title} </p>
                                      </div>
                                      <div className='align-items-center justify-content-center ' style={{marginTop:"47px"}}> 
                                        <p className={`text-white no-margin  text-center ${this.state.history_datas.churn_management_count ? " " : 'float_account_balance_bgblur'}`}>
                                          <b className=''  style={{ color:"rgba(223, 140, 148, 1)",fontSize: '23px', fontWeight: 'bold'}}> {item.value} </b>
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                  {item.title === "FAs taken over CS call" && (
                                    <div style={{height:"150px"}}>
                                      <div className='align-items-center justify-content-center ' style={{marginTop:"38px"}}>
                                        <p className='text-white no-margin text-center' style={{ fontSize: '16px', fontWeight: 'bold',padding: "0px 26px"}}> {item.title} </p>
                                      </div>
                                      <div className='align-items-center justify-content-center ' style={{marginTop:"47px"}}> 
                                        <p className={`text-white no-margin  text-center ${this.state.history_datas.churn_management_count ? " " : 'float_account_balance_bgblur'}`}>
                                          <b className=''  style={{ color:"rgba(223, 140, 148, 1)",fontSize: '23px', fontWeight: 'bold'}}> {item.value} </b>
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                
                                    
                                </div>

                            </div>

                        ))}

                    </div>

                </div>
         
                </div>  
                <div className='col-md-12 col-lg-6 col-sm-12'>
                  <div className='business_operations_border biz_operation_fas_pending' style={{ height: '340px' }}>
                    <div className='row'>
                      <div className='d-flex align-items-center biz_operation_font_size ' style={{ margin: '10px 0px 0px 10px' }}>
                        <FaUserNurse size={20} color={"white"} style={{ marginTop: '-4px' }} />
                        <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', fontSize: '18px' }} >Visits</h2>
                      </div>
                    </div>
                    <div className='business_operations_bottom_border' ></div>
                    {this.state.history_datas.rm_visit_chart ? "" :
                      <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary " style={{ color: 'white' }} onClick={() => this.lazy_loading_handler('rm_visit_chart')} > <b>Show</b></button> </div>
                    }
                    {this.state.TotalAndOverdueFA.length > 0 ?
                      <div className={`row d-flex justify-content-between align-items-center disbursement_chart ${this.state.history_datas.rm_metrics ? "" : 'float_account_balance_bgblur'} `} style={{ padding: ' 0px 52px' }}>
                        <div className='col-12'>
                        <BarChart data={this.state.TotalAndOverdueFA ? this.state.TotalAndOverdueFA : ''}type="bar" xaxistype='category' show_toolbar={false} axisBorder={true} grid={false} width={700} height={280} colors={['rgba(12, 205, 255, 1)', 'rgba(255, 87, 141, 1)', 'rgba(240, 243, 79, 1)', '#ff8cc6']} layout="horizontal" y_axis_format={true}/>
    
                        </div>
                      </div>
                      :
                      <p className='text-white text-center' style={{ marginTop: '13%', fontSize: '15px' }}>No Data</p>
                    }
                  </div>
                </div>

              </div>

              <div className='d-flex align-items-center justify-content-between row'>
                <div className='col-md-12 col-lg-6'>
                  <div className='business_operations_border biz_operation_fas_pending' style={{ height: '400px' }}>
                    <div className='row'>
                      <div className='d-flex align-items-center biz_operation_font_size ' style={{ margin: '10px 0px 0px 10px' }}>
                        <AiOutlineSchedule size={25} color={"white"} style={{ marginTop: '-4px' }} />
                        <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', fontSize: '18px' }}>FA Applications</h2>
                      </div>
                    </div>
                    <div className='business_operations_bottom_border' ></div>


                    {this.state.history_datas.appl_approvals ? "" :
                      <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary " style={{ color: 'white' }} onClick={() => this.lazy_loading_handler('appl_approvals')} > <b>Show</b></button> </div>
                    }
                    <div className={`total_fa_count_biz_operation ${this.state.history_datas.appl_approvals ? "" : 'float_account_balance_bgblur'}`} style={{ padding: '5px' }}><p className='text-white no-margin'>Total : {this.state.history_datas.appl_approvals ? this.state.history_datas.appl_approvals.total : ''}</p></div>

                    <div className={`row d-flex justify-content-between align-items-center `} style={{ padding: ' 30px 52px' }}>
                      <div className='col-md-12 disbursement_chart'>
                        <ReportChart data={this.state.FAApplications ? this.state.FAApplications : ''} type="bar" xaxistype='category' show_toolbar={false} axisBorder={true} grid={false}
                          x_axis_format={false} total_fas={this.state.total_fas ? this.state.total_fas : ''} y_axis_tittle={"No. of FAs"} legend={false} categories={this.state.data ? this.state.data : ''} dataLabel={true} distributed={true} colors={['rgba(49, 225, 137, 1)', 'rgba(12, 205, 255, 1)', 'rgba(255, 87, 141, 1)', 'rgba(240, 243, 79, 1)']} biz_ops_chart_height={300} biz_ops_colunm_width={'25%'} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-12 col-lg-6'>
                  <div className='business_operations_border biz_operation_fas_pending' style={{ height: '400px' }}>
                    <div className='row'>
                      <div className='d-flex align-items-center biz_operation_font_size ' style={{ margin: '10px 0px 0px 10px' }}>
                        <AiOutlineFieldTime size={25} color={"white"} style={{ marginTop: '-4px' }} />
                        <h2 className='text-white no-margin' style={{ padding: '3px 0px 10px 10px', fontSize: '18px' }} >Disbursement Delay Reasons</h2>
                      </div>
                    </div>
                    <div className='business_operations_bottom_border' ></div>
                    {this.state.history_datas.disb_delay_reason ? "" :
                      <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary " style={{ color: 'white' }} onClick={() => this.lazy_loading_handler('disb_delay_reason')} > <b>Show</b></button> </div>
                    }
                    <div className='total_fa_count_biz_operation' style={{ padding: '5px' }}>
                      <select className='form-control delayMin' id='delay_minutes' value={this.state.DisbursementDelayMinutes} onChange={(e) => this.handleDelay(e.currentTarget.value)} required>
                        {this.state.Disbursement_delay && Object.keys(this.state.Disbursement_delay).map((key, index) => {
                          return (
                            <option value={key}>Above {moment.utc(1000 * key).format('m')} minute</option>
                          )
                        })}
                      </select>
                    </div>
                    <div className={`row d-flex justify-content-between align-items-center ${this.state.history_datas.disb_delay_reason ? "" : 'float_account_balance_bgblur'}  `} style={{ padding: '30px' }}>
                      <div className='col-md-12 disbursement_chart'>
                        {this.state.delay_chart_data && <ReportChart data={this.state.delay_chart_data ? this.state.delay_chart_data : ''} type="bar" xaxistype='category' show_toolbar={false} axisBorder={true} grid={false}
                          x_axis_format={false} total_fas={this.state.Delay_total ? this.state.Delay_total : ''} y_axis_tittle={"No. of FAs"} legend={false} categories={this.state.DelayReasons ? this.state.DelayReasons : ''} dataLabel={true} distributed={true}colors={['rgba(49, 225, 137, 1)', 'rgba(12, 205, 255, 1)', 'rgba(255, 87, 141, 1)', 'rgba(240, 243, 79, 1)']} biz_ops_chart_height={300} biz_ops_colunm_width={'25%'} />

                        }    </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex align-items-center row'>
              <div className='col-md-12 col-lg-6' style={{height:'710px'}}>
                <div className='business_operations_border biz_operation_fas_pending' >
                        <div className='row'>
                            <div className='d-flex align-items-center biz_operation_font_size ' style={{margin:'10px 0px 0px 10px'}}>
                                <MdAlarm size={25} color={"white"} style={{marginTop:'-4px'}}/>
                                <h2 className='text-white no-margin' style={{padding:'3px 0px 10px 10px',fontSize:'18px'}} >Disbursement Time</h2>
                            </div>
                        </div>
                    <div className='business_operations_bottom_border' ></div> 
                    {this.state.history_datas.apply_to_disb_time_chart ? "" :
                    <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary " style={{color:'white'}} onClick={()=>this.lazy_loading_handler('apply_to_disb_time_chart')} > <b>Show</b></button> </div>
                     }
                    <div className={`row align-items-center   ${this.state.history_datas.apply_to_disb_time_chart ? "" : 'float_account_balance_bgblur'} `} style={{padding:' 23px 52px'}}>
                       
                    <div className='col-md-12 disbursement_chart' style={{minHeight:315}}>
                    {this.props.data.disbursement_spin_loader &&
                       <div class="d-flex justify-content-center float_acc_balance_view_bttn" >
                       <div class="spinner-border text-light" role="status">
                         <span class="sr-only">Loading...</span>
                       </div>
                       </div>
                         }
                    <ReportChart data={this.state.Disbursement_time_x_axis?this.state.Disbursement_time_x_axis:''} type="bar" xaxistype ='category' show_toolbar={false} axisBorder={true} grid={false} 
                       x_axis_format={false} total_fas={this.state.Disbursement_total?this.state.Disbursement_total:''}  y_axis_tittle={"No. of FAs"} legend={false} categories={this.state.Disbursement_time_Y_axis?this.state.Disbursement_time_Y_axis:''}   dataLabel={true} distributed={true} colors={['rgba(26, 187, 130, 1)','rgba(26, 187, 130, 1)']} biz_ops_chart_height={300} title={"Application to Disbursement"}/>
                  </div>       
                    <div className=' d-flex justify-content-center' style={{marginTop:'24px'}}>
                        <div className='' style={{marginRight:"15px"}}>
                            <div className='business_operations_border' style={{padding:'10px'}} > 
                              <div className='d-flex align-items-center'>
                                <input type="checkbox" id="rm_manual_approval" name="rm_manual_approval" value="rm_manual_approval" onChange={(e)=>this.disbursement_time_charts("manual_approval",this.state.disbursement_checkbox.manual_approval)} checked={this.state.disbursement_checkbox.manual_approval} style={{display:'none'}}/>
                                <label style={{width: '15px',height: '17px',position: 'relative',cursor: 'pointer',backgroundColor: this.state.disbursement_checkbox.manual_approval ? 'rgba(26, 187, 130, 1)' : 'rgba(255, 255, 255, 1)',border: '1px solid #000',borderRadius: '3px',color: this.state.disbursement_checkbox.manual_approval ? '#000' : 'rgba(255, 255, 255, 1)', fontSize:"16px"}} htmlFor="rm_manual_approval">{this.state.disbursement_checkbox.manual_approval ? '✔' : '' } </label>

                                <label className='no-margin' htmlFor="rm_manual_approval" style={{paddingLeft:'10px',paddingBottom:"4px"}}> With RM Manual Approval</label><br/>
                              </div>
                              <div className='d-flex align-items-center'>
                                  <input type="checkbox" id="rm_pre_approval" name="rm_pre_approval" value="rm_pre_approval"  onChange={() => this.disbursement_time_charts("auto_approval", this.state.disbursement_checkbox.auto_approval)}  style={{ display: 'none' }} checked={this.state.disbursement_checkbox.auto_approval}/>
                                  <label style={{width: '15px',height: '17px',position: 'relative',cursor: 'pointer',backgroundColor: this.state.disbursement_checkbox.auto_approval ? 'rgba(26, 187, 130, 1)' : 'rgba(255, 255, 255, 1)',border: '1px solid #000',borderRadius: '3px',color: this.state.disbursement_checkbox.auto_approval ? '#000' : 'rgba(255, 255, 255, 1)', fontSize:"16px"}} htmlFor="rm_pre_approval">{this.state.disbursement_checkbox.auto_approval ? '✔' : ''}</label>
                                  <label className='no-margin' htmlFor="rm_pre_approval" style={{paddingLeft:'10px',paddingBottom:"4px"}}>With RM Pre-Approval</label>
                              </div>
                            </div>
                        </div>
                        <div className='' style={{marginRight:"14px"}}>
                            <div className='business_operations_border' style={{padding:'10px'}} >
                              <div className='d-flex align-items-center'>
                                <input type="checkbox" id="With_Manual_Capture" name="With_Manual_Capture" value="With_Manual_Capture"  onChange={()=>this.disbursement_time_charts("manual_capture",this.state.disbursement_checkbox.manual_capture)} style={{ display: 'none' }}   checked={this.state.disbursement_checkbox.manual_capture} />
                                <label style={{width: '15px',height: '17px',position: 'relative',cursor: 'pointer',backgroundColor: this.state.disbursement_checkbox.manual_capture ? 'rgba(26, 187, 130, 1)' : 'rgba(255, 255, 255, 1)',border: '1px solid #000',borderRadius: '3px',color: this.state.disbursement_checkbox.manual_capture ? '#000' : 'rgba(255, 255, 255, 1)', fontSize:"16px"}} htmlFor="With_Manual_Capture">{this.state.disbursement_checkbox.manual_capture ? '✔' : ''} </label>

                                <label className='no-margin' htmlFor="With_Manual_Capture" style={{paddingLeft:'10px',paddingBottom:"4px"}}> With Manual Capture</label><br/>
                              </div>
                              <div className='d-flex align-items-center'> 
                                <input type="checkbox" id="With_Auto_Capture" name="With_Auto_Capture" value="With_Auto_Capture"  onChange={()=>this.disbursement_time_charts("auto_capture",this.state.disbursement_checkbox.auto_capture)} style={{ display: 'none' }}   checked={this.state.disbursement_checkbox.auto_capture}/>
                                <label style={{width: '15px',height: '17px',position: 'relative',cursor: 'pointer',backgroundColor: this.state.disbursement_checkbox.auto_capture ? 'rgba(26, 187, 130, 1)' : 'rgba(255, 255, 255, 1)',border: '1px solid #000',borderRadius: '3px',color: this.state.disbursement_checkbox.auto_capture ? '#000' : 'rgba(255, 255, 255, 1)', fontSize:"16px"}} htmlFor="With_Auto_Capture">{this.state.disbursement_checkbox.auto_capture ? '✔' : ''} </label>

                                <label className='no-margin' htmlFor="With_Auto_Capture" style={{paddingLeft:'10px',paddingBottom:"4px"}}> With Auto Capture</label><br/>
                              </div>
                            </div>
                        </div>
                        <div className='' style={{marginRight:"14px"}}>
                            <div className='business_operations_border' style={{padding:'10px'}} >
                              <div className='d-flex align-items-center'>
                                <input type="checkbox" id="With_Multiple_Attempts" name="With_Multiple_Attempts" value="With_Multiple_Attempts"  onChange={()=>this.disbursement_time_charts("multi_attempt",this.state.disbursement_checkbox.multi_attempt)} style={{ display: 'none' }}   checked={this.state.disbursement_checkbox.multi_attempt} />
                                <label style={{width: '15px',height: '17px',position: 'relative',cursor: 'pointer',backgroundColor: this.state.disbursement_checkbox.multi_attempt ? 'rgba(26, 187, 130, 1)' : 'rgba(255, 255, 255, 1)',border: '1px solid #000',borderRadius: '3px',color: this.state.disbursement_checkbox.multi_attempt ? '#000' : 'rgba(255, 255, 255, 1)', fontSize:"16px"}} htmlFor="With_Multiple_Attempts">{this.state.disbursement_checkbox.multi_attempt ? '✔' : ''} </label>
                                <label className='no-margin' htmlFor="With_Multiple_Attempts" style={{paddingLeft:'10px',paddingBottom:"4px"}}> With Multiple Attempts</label><br/>
                              </div>
                              <div className='d-flex align-items-center'>
                                <input type="checkbox" id="With_Single_Attempt" name="With_Single_Attempt" value="With_Single_Attempt"  onChange={()=>this.disbursement_time_charts("single_attempt",this.state.disbursement_checkbox.single_attempt)} style={{ display: 'none' }}   checked={this.state.disbursement_checkbox.single_attempt} />
                                <label style={{width: '15px',height: '17px',position: 'relative',cursor: 'pointer',backgroundColor: this.state.disbursement_checkbox.single_attempt ? 'rgba(26, 187, 130, 1)' : 'rgba(255, 255, 255, 1)',border: '1px solid #000',borderRadius: '3px',color: this.state.disbursement_checkbox.single_attempt ? '#000' : 'rgba(255, 255, 255, 1)', fontSize:"16px"}} htmlFor="With_Single_Attempt">{this.state.disbursement_checkbox.single_attempt ? '✔' : ''} </label>

                                <label className='no-margin' htmlFor="With_Single_Attempt" style={{paddingLeft:'10px',paddingBottom:"4px"}}> With Single Attemptl</label><br/>
                              </div>
                            </div>
                        </div>
                        <div className="" style={{marginTop:"13px"}}>
                          <button type="button" class={`btn btn-outline-info btn-sm `}  onClick={()=>this.disb_time_all_handler()} style={{color:'white',"border-radius": "50%",width: "100", height: "40px",fontWeight:"bold"}}> All</button>
                        </div>
                    </div>
                    <div className='  d-flex justify-content-center' style={{marginTop:'5px'}}>
                        <div className='' >
                            <div className='business_operations_border' style={{padding:'10px'}} >
                            <div className='d-flex align-items-center'>
                            <input type="checkbox" id="With_OTP_Confirmation" name="With_OTP_Confirmation" value="With_OTP_Confirmation"  onChange={()=>this.disbursement_time_charts("otp",this.state.disbursement_checkbox.otp)}  style={{ display: 'none' }}  checked={this.state.disbursement_checkbox.otp}  />
                            <label style={{width: '15px',height: '17px',position: 'relative',cursor: 'pointer',backgroundColor: this.state.disbursement_checkbox.otp ? 'rgba(26, 187, 130, 1)' : 'rgba(255, 255, 255, 1)',border: '1px solid #000',borderRadius: '3px',color: this.state.disbursement_checkbox.otp ? '#000' : 'rgba(255, 255, 255, 1)', fontSize:"16px"}} htmlFor="With_OTP_Confirmation" >{this.state.disbursement_checkbox.otp ? '✔' : ''}</label>

                            <label className='no-margin' htmlFor="With_OTP_Confirmation"  style={{paddingLeft:'10px',paddingBottom:"4px"}}> With OTP Confirmation</label><br/>
                            </div>
                            <div className='d-flex align-items-center'>
                            <input type="checkbox" id="Without_OTP_Confirmation" name="Without_OTP_Confirmation" value="Without_OTP_Confirmation"  onChange={()=>this.disbursement_time_charts("not_otp",this.state.disbursement_checkbox.not_otp)} style={{ display: 'none' }}   checked={this.state.disbursement_checkbox.not_otp}/>
                            <label style={{width: '15px',height: '17px',position: 'relative',cursor: 'pointer',backgroundColor: this.state.disbursement_checkbox.not_otp ? 'rgba(26, 187, 130, 1)' : 'rgba(255, 255, 255, 1)',border: '1px solid #000',borderRadius: '3px',color: this.state.disbursement_checkbox.not_otp ? '#000' : 'rgba(255, 255, 255, 1)', fontSize:"16px"}} htmlFor="Without_OTP_Confirmation">{this.state.disbursement_checkbox.not_otp ? '✔' : ''}</label>

                            <label className='no-margin' htmlFor="Without_OTP_Confirmation" style={{paddingLeft:'10px',paddingBottom:"4px"}}> Without OTP Confirmation</label><br/>
                            </div>
                            </div>
                        </div>
                        <div className=''style={{marginLeft:"13px"}} >
                            <div className='business_operations_border' style={{padding:'10px'}} >
                            <div className='d-flex align-items-center'>
                            <input type="checkbox" id="With_Auto_Disbursal" name="With_Auto_Disbursal" value="With_Auto_Disbursal"  onChange={()=>this.disbursement_time_charts("auto_disbursal",this.state.disbursement_checkbox.auto_disbursal)}  style={{ display: 'none' }}  checked={this.state.disbursement_checkbox.auto_disbursal}/>
                            <label style={{width: '15px',height: '17px',position: 'relative',cursor: 'pointer',backgroundColor: this.state.disbursement_checkbox.auto_disbursal ? 'rgba(26, 187, 130, 1)' : 'rgba(255, 255, 255, 1)',border: '1px solid #000',borderRadius: '3px',color: this.state.disbursement_checkbox.auto_disbursal ? '#000' : 'rgba(255, 255, 255, 1)', fontSize:"16px"}} htmlFor="With_Auto_Disbursal">{this.state.disbursement_checkbox.auto_disbursal ? '✔' : ''} </label>

                            <label className='no-margin' htmlFor="With_Auto_Disbursal" style={{paddingLeft:'10px',paddingBottom:"4px"}}>With Auto Disbursal</label><br/>
                            </div>
                            <div className='d-flex align-items-center'>
                            <input type="checkbox" id="With_Manual_Disbursal" name="With_Manual_Disbursal" value="With_Manual_Disbursal"  onChange={()=>this.disbursement_time_charts("manual_disbursal",this.state.disbursement_checkbox.manual_disbursal)}  style={{ display: 'none' }}  checked={this.state.disbursement_checkbox.manual_disbursal}/>
                            <label style={{width: '15px',height: '17px',position: 'relative',cursor: 'pointer',backgroundColor: this.state.disbursement_checkbox.manual_disbursal ? 'rgba(26, 187, 130, 1)' : 'rgba(255, 255, 255, 1)',border: '1px solid #000',borderRadius: '3px',color: this.state.disbursement_checkbox.manual_disbursal ? '#000' : 'rgba(255, 255, 255, 1)', fontSize:"16px"}} htmlFor="With_Manual_Disbursal">{this.state.disbursement_checkbox.manual_disbursal ? '✔' : ''} </label>

                            <label className='no-margin' htmlFor="With_Manual_Disbursal" style={{paddingLeft:'10px',paddingBottom:"4px"}}> With Manual Disbursal</label><br/>
                            </div>
                            </div>
                            
                            
                        </div>

                      
                    </div>
                    </div>
        
                </div>
              </div>

              <div className='col-md-12 col-lg-6' style={{height:'711px'}}>
                <div className='business_operations_border biz_operation_fas_pending ' >
                        <div className='row'>
                            <div className='d-flex align-items-center biz_operation_font_size ' style={{margin:'10px 0px 0px 10px'}}>
                                <GiBackwardTime size={25} color={"white"} style={{marginTop:'-4px'}}/>
                                <h2 className='text-white no-margin' style={{padding:'3px 0px 10px 10px',fontSize:'18px'}} >Repayment Time</h2>
                            </div>
                        </div>
                    <div className='business_operations_bottom_border' ></div> 
                    {this.state.history_datas.repay_to_settle_time_chart ? "" :
                    <div className='float_acc_balance_view_bttn'> <button type="button" class="btn btn-outline-secondary " style={{color:'white'}} onClick={()=>this.lazy_loading_handler('repay_to_settle_time_chart')} > <b>Show</b></button> </div>
                     }
                    <div className={`row align-items-center  disbursement_chart  ${this.state.history_datas.repay_to_settle_time_chart ? "" : 'float_account_balance_bgblur'} `} style={{margin:'16px 20px'}}>

                        <div className='col-md-12 disbursement_chart' style={{minHeight:465}}>
                        {this.props.data.repayment_spin_loader &&
                       <div class="d-flex justify-content-center float_acc_balance_view_bttn" >
                       <div class="spinner-border text-light" role="status">
                         <label class="sr-only">Loading...</label>
                       </div>
                       </div>
                         }
                        <ReportChart data={this.state.repayment_time_x_axis?this.state.repayment_time_x_axis:''} type="bar" xaxistype ='category' show_toolbar={false} axisBorder={true} grid={false} 
                       x_axis_format={false} total_fas={this.state.repayment_total?this.state.repayment_total:''}  y_axis_tittle={"No. of FAs"} legend={false} categories={this.state.repayment_time_y_axis?this.state.repayment_time_y_axis:''}   dataLabel={true} distributed={true} colors={['rgba(26, 187, 130, 1)']} biz_ops_chart_height={450} title={"Repayment to Settlement"} />
                  
                      </div>
                    <div className='d-flex align-items-center justify-content-center' >
                    
                        <div className='align-items-center' >
                            <div className='d-flex justify-content-center business_operations_border' style={{padding:'10px'}} >
                              <div className='d-flex align-items-center'>
                                <input type="checkbox" id="repayment_Manual_Capture" name="repayment_Manual_Capture" value="repayment_Manual_Capture"  onChange={()=>this.repayment_time_charts("manual_capture",this.state.repayment_time.manual_capture)}  checked={this.state.repayment_time.manual_capture} style={{ display: 'none' }}  />
                                <label style={{width: '15px',height: '17px',position: 'relative',cursor: 'pointer',backgroundColor: this.state.repayment_time.manual_capture ? 'rgba(26, 187, 130, 1)' : 'rgba(255, 255, 255, 1)',border: '1px solid #000',borderRadius: '3px',color: this.state.repayment_time.manual_capture? '#000' : 'rgba(255, 255, 255, 1)', fontSize:"16px"}} htmlFor="repayment_Manual_Capture">{this.state.repayment_time.manual_capture ? '✔' : ''} </label>

                                <label className='no-margin' htmlFor="repayment_Manual_Capture" style={{paddingLeft:'10px',paddingBottom:"4px"}}> With Manual Capture</label><br/>
                              </div>
                              <div className='d-flex align-items-center' style={{"margin-left": "25px"}}>
                                <input type="checkbox" id="repayment_auto_Capture" name="repayment_auto_Capture" value="repayment_auto_Capture"  onChange={()=>this.repayment_time_charts("auto_capture",this.state.repayment_time.auto_capture)}  checked={this.state.repayment_time.auto_capture} style={{ display: 'none' }}  />
                                <label style={{width: '15px',height: '17px',position: 'relative',cursor: 'pointer',backgroundColor: this.state.repayment_time.auto_capture? 'rgba(26, 187, 130, 1)' : 'rgba(255, 255, 255, 1)',border: '1px solid #000',borderRadius: '3px',color: this.state.repayment_time.auto_capture? '#000' : 'rgba(255, 255, 255, 1)', fontSize:"16px"}} htmlFor="repayment_auto_Capture" >{this.state.repayment_time.auto_capture ? '✔' : ''}  </label>

                                <label className='no-margin' htmlFor="repayment_auto_Capture" style={{paddingLeft:'10px',paddingBottom:"4px"}}> With Auto Capture</label><br/>
                              </div>
                            </div>
                        </div>
                      
                      
                    </div>
                    </div>
        
                </div>
              </div>
            </div>
            
            </>
          }
        </div>

      </div>
    );
  }
};

export default It_opertations_yesterday_results;