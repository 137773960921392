import React from "react"
import { Outlet } from "react-router-dom"
import Logout from "../../UI/auth/component/Logout"





const LogoutLayout = ()=>{


    return (
        <div>
            <Logout/>
            <Outlet/>
        </div>
    )
}

export default LogoutLayout