import React, {useEffect, useState} from "react";
import {get} from "../../../helpers/storage_helper";
import {getCurrencyDetails, getForexRates, getManagementDashboardReport} from "../../../actions/report_actions";
import {listAccountProvider} from "../../../actions/account_provider_actions";
import {DateFormatter} from "../../../helpers/common_helper";
import {Link} from "react-router-dom";
// import Flag from "react-flags";
import {Button, Form} from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { IoIosGlobe } from "react-icons/io";
import moment from "moment";
import {listMarkets} from '../../../actions/common_actions';
import DatePicker from 'react-datetime';
import { BiCalendar } from "react-icons/bi";
import Toggle from "../../common/component/Toggle";
import LenderCode from "./LenderCode";
import CustomFlag from "../../common/component/CustomFlag";



export default function InputSelectionHeader({set_attr,incl_prvdr, title, showMonthRange, default_start_date="" , default_end_date="" ,show_currency=true ,live_dashbaord_reduce_space ,show_global=true,dataProjections,isactive, showSubmitbtn,onsubmit,submitbtnenable,fromChart,select_multiple_prvdrs=false,acc_prvdr_changed,showCountry=true, par_chart = false, LenderCodes = null}) {

   
    const [data, setdata] = useState([])
    const [startDate, setStartDate] = useState( default_start_date)
    const [endDate, setEndDate] = useState(default_end_date)
    const [cursorPointerStatus,setcursorPointerStatus] = useState(true)
    const [market_list,setmarket_list]=useState([])
    const [current_country,setcurrent_country] = useState(null)
    const [filteredCountrydata, setfilteredCountrydata] = useState([]) 
    const [country, setcountry] = useState({
        
        country: show_global ? 'Global' : get('market').country,
        country_code: show_global ? "*":  get('market').country_code,
        showlist:show_global ? false : true 
    
    })
    const [currentCountryactiveCurrency, setcurrentCountryactiveCurrency] = useState({
        active_currency:''
    })
    const [activeCurrency, setactiveCurrency] = useState({
        currency_change:false,
        active_currency:'',
        active_currency_val:''
    })
    const [global, setglobal] = useState({
        globalval:false,
        global_name:'',
        global_countryCode:null
    })
    const [showaccList,setshow] = useState(true)
    const [currencyDetails,setcurrencyDetails] = useState({})
    const [accPrvdr,setaccPrvdr] = useState(null)
    const [accPurpose,setaccPurpose] = useState('Float Advance')
    const [allAccPrvdr,setallAccPrvdr] = useState([])
    const[active_acc_prvdr,setactive_acc_prvdr]=useState(null)
    const[gds_report_active_accPrvds,setgds_report_active_accPrvds]=useState(null)
    const[token,settoken]=useState()
    const[dateWiseFilter,setdateWiseFilter]=useState(false)
    const[lender,setLender] = useState(null)
    
    
    const acc_purpose = ["Float Advance","Terminal Financing"]
    useEffect(() => {
        setStartDate(default_start_date);
        setEndDate(default_end_date);
      }, [default_end_date,default_start_date]);
      
    useEffect(() => {
        setcountry({

            ["country"] :show_global ? "Global" : get('market').country ,
            ["country_code"] :  show_global ? "*" : get('market').country_code ,
            ['showlist'] : show_global ? false :true

        })
        setactiveCurrency({
            ["currency_change"] : get('market').country_code === "UGA"  || get('market').country_code === "RWA" ? false : true ,
            ["active_currency"] : get('market').currency_code,
            ["active_currency_val"] : ''
        })
        const req = {"time_zone":get('market').time_zone}
    
    
        getCurrencyDetails(req)
            .then((response)=>{
                if(!response){return ""}
                if(response.status === "success"){
                    setdata(response.data)
                    const currency_details = response.data
                    setcurrencyDetails(currency_details)
                    settoken(Math.floor(Math.random() * 100) + 1)
                    setcurrentCountryactiveCurrency({
                        ["active_currency"] :currency_details[get('market').country_code].currency_code
                    })
                }
            })

        var request = {country_code: country.country_code, time_zone: get('market').time_zone, status: ""};
        listAccountProvider({...request, status: 'enabled', biz_account: true})
            .then((response)=>{
                if(!response){return };
                if(response.status === "success"){
                    let acc_prvdrs = []
                    response.data.list.map(each=>
                        acc_prvdrs.push(each.acc_prvdr_code)
                    )
                    setallAccPrvdr(acc_prvdrs)
                }
            });
          
    }, [setdata,listAccountProvider])
    
    useEffect(()=>{
        if(token){
        setCurrencyHandler("USD")
        }

        if(get('market_list')){
            setmarket_list(get('market_list'))


        }else{
            listMarkets().then((response)=>{
                if(!response){return}
                if(response){
                 setmarket_list(response.data)
                 get_country_name(response.data)
                }
            }) 
        }
    },[token, country])
    
    

    const get_country_name = (data) => {
      const current_country = data.filter((item,idx)=>{ return item.country_code == country.country_code})
       setcurrent_country(current_country)
    }
    
    const countrySelecthandler = (countryCode,countryName) => {
        if(countryCode == "*"){
            setcountry({["country"] : countryName,["country_code"] : countryCode,['showlist']:false})
        }else{
            setcountry({["country"] : countryName,["country_code"] : countryCode,['showlist']:true})
        }

        setshow(false)
        setLender(null)
        setcursorPointerStatus(true)
        setaccPrvdr(null)
        setactive_acc_prvdr(null)
        if(countryCode!="*"){
           
           if( currencyDetails[countryCode]){
                setactiveCurrency({
                    ['currency_change'] : countryCode == country.country_code ? false : true ,
                    ["active_currency"] : currencyDetails[countryCode].currency_code,
                    ["active_currency_val"] : ''
                })
                const currentCountryactiveCurrency = currencyDetails[countryCode].currency_code
                setcurrentCountryactiveCurrency({
                    ["active_currency"] : currentCountryactiveCurrency
                })
            }
        }
        else{
            
            setactiveCurrency({
                ['currency_change'] : countryCode == country.country_code ? false : true ,
                ["active_currency"] : 'UGX',
                ["active_currency_val"] : ''
            }) 
        }
        setCurrencyHandler("USD")
        var req = {country_code: countryCode, time_zone: countryCode === "UGA" ? "EAT" : "CAT", status: ""};
        listAccountProvider({...req, status: 'enabled', biz_account: true})
            .then((response)=>{
                if(!response){return };
                if(response.status === "success"){
                    let acc_prvdrs = []
                    response.data.list.map(each=>
                        acc_prvdrs.push(each.acc_prvdr_code)
                    )
                    setallAccPrvdr(acc_prvdrs)
                }
            });
      
        
    }
    
    const prvdrSelectHandler =(accPrvdr) => {
        setcursorPointerStatus(false)
        setaccPrvdr(accPrvdr)
        if(acc_prvdr_changed){
            acc_prvdr_changed(accPrvdr)
        }
        setactive_acc_prvdr(accPrvdr)
        if(accPrvdr == null){
            setshow(false)
        }else{
            setshow(true)
        }
    }
    
    const accPurposeClickHandler = (accPur) => {
        setaccPurpose(accPur)
    }
    
    const  handleStartDate = (value) => {
            setStartDate(value)
    }
    
    const  handleEndDate = (value) => {
            setEndDate(value)
    }
    
    const  handlRangeClearBtn = () => {
        setStartDate(moment().subtract('6', 'months').format("Y-MM"));
        setEndDate(moment().format("Y-MM"));
    }
    
    
    const setCurrencyHandler = (curren) => {
        if(country.country_code !="*"){
            if(curren === "USD" || curren === "EUR" ){
                setactiveCurrency({
                    ["currency_change"] : true,
                    ["active_currency"] : curren,
                    ["active_currency_val"] : currencyDetails[country.country_code].forex_rates[curren]
                })
            }
            else if (curren === "UGX" || curren === "RWF") {
                setactiveCurrency({
                    ["currency_change"] : false,
                    ["active_currency"] : curren,
                    ["active_currency_val"] : ""
                })
            }
           
        }else{
           if(currencyDetails){
            setactiveCurrency({
                ["currency_change"] : true,
                ["active_currency"] : curren,
                ["active_currency_val"] : currencyDetails["UGA"].forex_rates[curren]
            })
        }
 
        }
       
    }
  const handleLenderCode = (res) =>{
    setLender(res.value)
  }
     
    
        set_attr({country_code : country.country_code, activeCurrency : activeCurrency.active_currency, activeCurrencyVal : activeCurrency.active_currency_val, country : country.country, startDate , endDate,gds_report_active_accPrvds:active_acc_prvdr , showlist:country.showlist, showaccList:showaccList,dateWiseFilter,lender:lender});

        return (
        // <div className='containerTopmargin max-width no-padding' id='convertPDF'>
            <div  className='manage_dashContainer'>
                {live_dashbaord_reduce_space  ? "" :
                <div className='row no-margin align-items-center py-3'>
                    <div className='col-md-8 no-padding pb-5'>
                        <h5>{title}</h5>
                    </div>
                    {/* {/<div className='col-md-4 no-padding d-flex justify-content-end'>/} */}
                    {/*    {window.location.pathname === '/management-dashboard/print' ?*/}
                    {/*        <div>*/}
                    {/*            <button className='btn-std' style={{borderRadius:5,border:'none',backgroundColor:'#fff'}} onClick={()=>window.print()}>Export to PDF</button>*/}
                    {/*            <Link to={"/management-dashboard"} className='btn-std' style={{marginLeft:15,borderRadius:5,border:'none',backgroundColor:'#fff'}}>Back</Link>*/}
                    {/*        </div>*/}
                    {/*        :*/}
                    {/*        <Link className='btn-std' style={{marginLeft:15,borderRadius:5,border:'none',backgroundColor:'#fff'}} to={"/management-dashboard/print"}>Export To PDF</Link>*/}
                    {/*    }*/}
                    {/* {/</div>/} */}
                </div>
                }
                {showCountry&&
                <div className='row no-margin '>
                    <div className={`col-md-8 row no-padding `}>
                        <div className='d-flex  w-100 p-0 m-0 '>
                        {/* <div className={`${fromChart ?"col-md-auto mt-3 row align-items-center":'row align-items-center w-100 ml-3'}`}> */}
                        <div className={`d-flex col-md-3 align-items-center mt-4`}>
                                <label className='no-margin pr-2'>Market</label>
                            
                            <div className={`${fromChart?' m-0 ' : ''}dropdown country_dropDownbg dark_theme_bg  p-0 `} >
                                
                                    <div className="dropdown-toggle d-flex align-items-center dropdown_trig justify-content-between" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                     {country.country_code !="*" ? 
                                      <>
                                      {/* <Flag id="market" name={country.country_code} format="png" pngSize={48} shiny={false} alt={country.country_code} basePath="/img/flags"/> */}
                                      <CustomFlag country_code={country.country_code} alt={country.country_code} height={30} width={30}/>
                                      <span >{country.country ? country.country : ((current_country !=null) ? current_country[0].country :'')}</span>
                                      <span><i className="fa-solid fa-chevron-down"></i></span>
                                      </> 
                                      :
                                      
                                      <>
                                    
                                      <IoIosGlobe size={20} style={{margin:'4px 0px'}}/>
                                      <span>{country.country}</span>
                                      <span><i className="fa-solid fa-chevron-down"></i></span> 
                                      </>
                                    
                                
                                      
                                     }
                                       
                                      
                                    </div>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{width:160}}>
                                        {show_global &&
                                        <li className="country_selector" onClick={()=>countrySelecthandler("*","Global")}>
                                            <IoIosGlobe size={20} style={{margin:'4px 0px'}}/>
                                            <span style={{paddingLeft:15 }}>Global</span>
                                        </li>
                                        }
                                        { market_list &&  market_list.map((each,idx)=>
                                            <li key={idx} className="country_selector" onClick={()=>countrySelecthandler(each.country_code,each.country)}>
                                                {/* <Flag id="market" name={each.country_code} format="png" pngSize={48} shiny={false} alt={each.country_code} basePath="/img/flags"/> */}
                                                <CustomFlag country_code={each.country_code} alt={each.country_code} height={30} width={30}/>
                                                <span style={{paddingLeft:5}}>{each.country}</span>
                                            </li>
                                        )}

                                    </ul>
                                </div>
                                </div>
                                {/* {fromChart&&
                            <div className=" pr-0 pl-4  mx-0  col-md-4 col-lg-2  row d-flex chart_radio_font  ">
                                <div className=" col-12 pr-0 pl-3 m-0 d-flex  align-items-end ">
                                <input className=" m-0 p-0 " type="radio" id="daily" value="daily" onClick={()=>setdateWiseFilter(true)} name="dateWiseFilter" />&nbsp;&nbsp;
                                <label htmlFor="daily" className="p-0 m-0 ">Day Wise</label>
                                </div>
                                <div className=" col-12 pr-0 pl-3  m-0 d-flex align-items-end ">
                                <input className=" mb-1 p-0 " type="radio" id="monthly" value="monthly"defaultChecked onClick={()=>setdateWiseFilter(false)}name="dateWiseFilter" />&nbsp;&nbsp;
                                <label htmlFor="monthly" className="p-0 m-0">Month Wise</label>
                                </div>
                            </div>} */}
                           {/* {LenderCodes&&  
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-2 d-flex align-items-end m-0 p-0 justify-content-center">
                                        {LenderCodes()}
                                        </div>} */}
                                {LenderCodes &&
                                    <div className={`d-flex align-items-end m-0 p-0  px-2 justify-content-center`} style={{zIndex :fromChart ? 2000 : ''}}>
                                        <LenderCode country_code={country.country_code} onChange = {(res)=>handleLenderCode(res) } width ="160px" />
                                    </div>}

                            {fromChart &&
                                <div className=" px-1  m-0  col-md-auto row d-flex chart_radio_font  chart_toggle ">
                                    
                                    <div className=" mr-2 w-100">
                                    <Toggle perWidth="105px" id='day_to_month' title={'Select Period'} leftLabel= {"Day wise"} rightLabel={"Month wise"} fontSize="15" aligntext={'start'} paddingSize ={'0'} marginsize = {'0'} val={!dateWiseFilter} fromChart={true} set_tog_val={(res) => setdateWiseFilter(res)} />
                                    </div>
                                </div>
                            }

                        {/* </div> */}
                        <div className={`${fromChart ?"col-md-auto mt-3 row align-items-center ":''}`}>
                                {showMonthRange &&
                                    <div className={"d-flex col-md-12  align-items-end  p-0 m-0"} style={{"marginTop": "-16px", "paddingLeft": "5px"}}>
                                        <div className="col-md-5 px-sm-3 px-lg-0 px-md-0 ">
                                            <label className="text-white no-margin" >{dateWiseFilter?'Start Date':'Start Month'}</label>
                                            <div className="form-group monthly_report_input col-md-12 col-lg-12 col-sm-12 px-2 py-0 m-0  d-flex align-items-center  churn_report_date_picker" >

                                                <label className='col-1.5 no-margin d-flex p-0 ' style={{ position: 'static' }} htmlFor='churn_report_start_date'>
                                                    <p className='m-0 pr-1'><BiCalendar size={20} /></p>
                                                    {/* <div className="churn_report_vl pr-2" > <b className="pl-1" style={{ color: 'red' }}>*</b></div> */}
                                                </label>

                                                <div className='col  px-2 py-1 pr-0 '>
                                                    
                                                <DatePicker closeOnSelect={true} 
                                                isValidDate={(current) => {
                                                const today = moment().startOf('day');
                                                return current.isSameOrBefore(today, 'day');  }} 
                                                value={ startDate ? dateWiseFilter ? moment(startDate).format('DD MM YYYY'): moment(startDate).format('MMMM YYYY'):''}  onChange={(value)=>handleStartDate(  moment(value).format("YYYY-MM-DD"),"to")}  inputProps={{ id: 'churn_report_start_date', name: 'Referral_from',autoComplete:'off' }}  dateFormat= { dateWiseFilter?"DD MM YYYY" :"MMMM yyyy"}
                                                showMonthYearPicker timeFormat={false} required="required" onKeyDown={(event) => event.preventDefault()}/>
                                                
                                                </div>
                                            </div>

                                            {/* <input className='monthly_report_input m-0 w-100' type={`${dateWiseFilter?"date":"month"}`} max={new Date().toISOString().slice(0, 7)} min ={ dateWiseFilter?moment(endDate).subtract(90, 'days').format('YYYY-MM-DD'):''}onChange={(event) => handleStartDate(event)} value={startDate} onKeyDown={(event) => event.preventDefault()}/> */}
                                        </div>
                                        <div className="col-md-5 px-3 ">
                                            <label className="text-white no-margin " >{dateWiseFilter?'End Date':'End Month'}</label>
                                            {/* <input className='monthly_report_input m-0 w-100' type={`${dateWiseFilter?"date":"month"}`}  max={dateWiseFilter? moment(startDate).add(90, 'days').format('YYYY-MM-DD'):!dataProjections||!isactive? new Date().toISOString().slice(0, 7): moment().add(12, "months").format("Y-MM")} onChange={handleEndDate} value={endDate} onKeyDown={(event) => event.preventDefault()}/> */}
                                            <div className="form-group monthly_report_input col-md-12 col-lg-12 col-sm-12 px-2 py-0 m-0  d-flex align-items-center  churn_report_date_picker" >

                                            <label className='col-1.5 no-margin d-flex p-0 ' style={{ position: 'static' }} htmlFor='report_end_date'>
                                                <p className='m-0 pr-1'><BiCalendar size={20} /></p>
                                            </label>

                                            <div className='col  p-0 py-1 pr-0 '>
                                                
                                            <DatePicker closeOnSelect={true} 
                                            isValidDate={(current) => {
                                            const today = moment().startOf('day');
                                            return current.isSameOrBefore(today, 'day');  }} 
                                            value={ endDate ? dateWiseFilter ? moment(endDate).format('DD MM YYYY'): moment(endDate).format('MMMM YYYY'):''}  onChange={(value)=>handleEndDate(  moment(value).format("YYYY-MM-DD"),"to")}  inputProps={{ id: 'report_end_date', name: 'Referral_from',autoComplete:'off' }}  dateFormat= { dateWiseFilter?"DD MM YYYY" :"MMMM yyyy"}
                                            showMonthYearPicker timeFormat={false} required="required" onKeyDown={(event) => event.preventDefault()}/>

                                            </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2 p-0 d-flex align-items-end" >
                    
                                          <div className="">
                                                {(startDate !== "" || endDate !== "") &&
                                                    <>
                                                    {showSubmitbtn?
                                                    <button data-tip data-for="Submit" disabled={!submitbtnenable} className={"btn btn-sm btn btn-info  align-items-center no-margin "}
                                                            onClick={()=>onsubmit()}>
                                                        Submit
                                                    </button>:
                                                    <button data-tip data-for="dateRangeClear" className={` ${fromChart ? 'grey_button px-3 py-2' : 'px-4 clear_btn_pad btn mb-1'}`}
                                                            onClick={() => handlRangeClearBtn()}>
                                                        Reset
                                                    </button>}
                                                    {/* <ReactTooltip id="dateRangeClear" place="top" effect="solid">
                                                    Show all time data
                                                    </ReactTooltip> */}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                </div>
                            </div>
                        </div>
                       
                        <>
                        {country.country_code !="*" &&
                            <>
                           

                    <div className={`${fromChart ? 'col mt-3' : 'col-md-2 no-padding'}  d-flex align-items-center justify-content-end`}>
                        {show_currency &&
                        <div style={{flexDirection:'column',display:'flex'}}>
                            <div className={`${fromChart ? 'col' : 'col'} no-padding  d-flex align-items-center justify-content-end`}>
                                <p className='no-margin text-white' style={{fontWeight:600}}>{activeCurrency.active_currency_val === "" ? ""  : `1 ${activeCurrency.active_currency} =`}&nbsp;</p>
                                <p className='no-margin text-white' style={{fontWeight:600}}>{activeCurrency.active_currency_val === "" ? ""  : Number(1/activeCurrency.active_currency_val).toFixed(0)}&nbsp;{activeCurrency.active_currency_val === "" ? ""  : currencyDetails[country.country_code].currency_code}</p>
                            </div>

                      
                        <ul className='manDash_currencySelector no-padding no-margin justify-content-end align-items-center'>
                            
                            <li className='border_left px-3' style={{backgroundColor : activeCurrency.active_currency === "USD" ? 'lightgray' : '' }} onClick={() => setCurrencyHandler('USD')}>
                                <p className='no-margin'>USD</p>
                            </li>
                            <li style={{backgroundColor : activeCurrency.active_currency === "EUR" ? 'lightgray' : '',borderRadius:global.globalval?"0px 5px 5px 0px":'',marginRight:global.globalval?'20px':'',borderRadius:country.country_code!="*" ? '' :'0px 5px 5px 2px'}} onClick={() => setCurrencyHandler('EUR')}>
                                <p className='no-margin px-3'>EUR</p>
                            </li>
                            <li style={{backgroundColor : activeCurrency.active_currency === currentCountryactiveCurrency.active_currency ? 'lightgray' : '' }} className='border_right px-3' onClick={() => setCurrencyHandler(currentCountryactiveCurrency.active_currency)} >
                                <p className='no-margin'>{currentCountryactiveCurrency.active_currency}</p>
                            </li>
                        </ul>
                        </div>
                    }
                    </div>
                                </>
                        }
                    </>
                </div>}
                {incl_prvdr === true && lender == null &&
                <div className={`row no-margin justify-content-between ${par_chart?' par_chart_provider ':''}`} style={{paddingBottom:live_dashbaord_reduce_space ? "0px":15,paddingTop: live_dashbaord_reduce_space ? "0px":15}}>

                            <div className={`${par_chart?' col-md-12 ':' col-md-8 '} no-padding`}>
                            <div className='d-flex align-items-center'>
                                <label className='no-margin' style={{paddingRight:15}}>Provider</label>     
                                <button className={`filterBtns allBtn_size pointer ${accPrvdr === null  ? " " : "opac_50"}`} style={{height:30,width:60,cursor : cursorPointerStatus ? "pointer" : 'default',marginRight:15}} key={0} onClick={()=>prvdrSelectHandler(null)}>ALL</button>                    
                                                
                                {allAccPrvdr.map((each,idx)=>                                    
                                    <div style={{height:30,width:60,cursor : 'pointer',marginRight:15}} className={select_multiple_prvdrs?((select_multiple_prvdrs.length>0&&select_multiple_prvdrs.includes(each))?"pointer":"opac_50"): accPrvdr === null || accPrvdr === each ? "pointer" : "opac_50"} key={idx} onClick={()=>prvdrSelectHandler(each)}>         
                                        <img className="img-fluid" src={`/img/${each}_logo_color.png`} alt={each}/>         
                                    </div>
                                )}                                                    
                            </div> 
                        </div>
                    {/* {/<div className='col-md-8 no-padding'>/} */}
                    {/*    <div className='d-flex align-items-center'>*/}
                    {/*        <label className='no-margin' style={{paddingRight:15}}>Provider</label>*/}
                    {/*        <button className={`filterBtns allBtn_size ${accPrvdr === null  ? "" : "opac_50"}`} style={{height:30,width:60,cursor : cursorPointerStatus ? "pointer" : 'default',marginRight:15}} key={0} onClick={()=>prvdrSelectHandler(null)}>ALL</button>*/}

                    {/*        {allAccPrvdr.map((each,idx)=>*/}
                    {/*            <div style={{height:30,width:60,cursor : cursorPointerStatus ? "pointer" : 'default',marginRight:15}} className={accPrvdr === null || accPrvdr === each ? "" : "opac_50"} key={idx} onClick={()=>prvdrSelectHandler(each)}>*/}
                    {/*                <img className="img-fluid" src={`/img/${each}_logo_color.png`} alt={each}/>*/}
                    {/*            </div>*/}
                    {/*        )}*/}
                    {/*    </div>*/}
                    {/* {/</div>/} */}
                    {/* <div className='col-md-4 no-padding d-flex justify-content-center align-items-center'> */}
                        {/* <div>
                                <label className='no-margin'>Loan Purpose</label>
                            </div>
                            <div className="dropdown country_dropDownbg">
                                <div className="dropdown-toggle d-flex align-items-center justify-content-between dropdown_trig" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span>{accPurpose}</span>
                                    <span><i className="fa-solid fa-chevron-down"></i></span>
                                </div>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{width:160}}>
                                    {acc_purpose.map((each,idx)=>
                                        <li className="country_selector" key={idx} onClick={() => accPurposeClickHandler(each)}>
                                            <span style={{paddingLeft:5}}>{each}</span>
                                        </li>
                                    )}
                                </ul>
                            </div> */}

                          
                            {/* <div >
                                <label className="text-white no-margin" style={{paddingLeft:'30px'}}>Start Date</label> 
                                <input className='monthly_report_input' style={{width:'110px'}}type="month" />
                            </div>
                            <div >
                            <label className="text-white no-margin "  style={{paddingLeft:'30px'}}>End Date</label>
                            <input className='monthly_report_input' style={{width:'110px'}} type="month" /> 
                            </div>  */}
                           


                  
                    {/* </div> */}

                </div>}
            </div>
    )

    }
