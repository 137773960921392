import * as moment from "moment";

export const sortAmtcommon = (a,b) => {      
    const aSplited = a.split(" ")
    const bSplited = b.split(" ")
    const comaSpliteda = aSplited[0].split(",")        
    const comaSplitedb = bSplited[0].split(",")
    const joinedA = comaSpliteda.join('')
    const joinedB = comaSplitedb.join('')                
    if(parseInt(joinedA) > parseInt(joinedB) || parseInt(joinedA) === parseInt(joinedB)) {        
        return 1
    }else{
        return -1
    }  
}

export const sortBizcommon = (a,b) => {   
    const currentBizsplited = a.split(' ')
    const prevBizsplited = b.split(' ')
    const aLowercased = currentBizsplited[0].toLowerCase()
    const bLowercased = prevBizsplited[0].toLowerCase()    
    const sortArr = [aLowercased,bLowercased]        
    const sorted = sortArr.sort()                   
    if(aLowercased === sorted[0]){
        return 1
    }else{
        return -1
    }         
}

export const sortTimecommon = (a,b,c) => {     
    const aTimediff = moment.duration(moment(c).diff(moment(a)))
    const bTimediff = moment.duration(moment(c).diff(moment(b)))
    let days_a = aTimediff.days()    
	let hours_a =  aTimediff.hours()
	let mins_a = aTimediff.minutes()
    let days_b = bTimediff.days()
	let hours_b =  bTimediff.hours()
	let mins_b = bTimediff.minutes()
    if(days_a > days_b){        
        return 1
    }else if(days_a === days_b){
        if(hours_a > hours_b){
            return 1
        }else if(hours_a === hours_b){
            if(mins_a > mins_b){
                return 1
            }else if(mins_a === mins_b){
                return 1
            }else if(mins_b > mins_a){
                return -1
            }
        }else if(hours_b > hours_a) {
            return -1
        }
    }else if(days_b > days_a) {
        return -1
    }
}

export const sortDatecommon = (a,b) => {    
    const aDate = new Date(a)
    const bDate = new Date(b)       
    // if(aDate.getTime() > bDate.getTime() || aDate.getTime() === bDate.getTime() ){
    //     return 1
    // }else{
    //     return -1
    // }    
    return aDate - bDate
}
