import { get_path } from '../helpers/common_helper';
import {call_fetch, admin_path, app_path} from './../helpers/fetch_helper';

export const StartCallLog = request  => {
   return call_fetch(get_path("admin") + '/call_log/start', request);
}
export const CompleteCall = request  => {
   return call_fetch(get_path("admin") + '/call_log/complete', request);
}
export const CancelCall = request  => {
   return call_fetch(get_path("admin") + '/call_log/cancel', request);
}

export const getCallLogs = request  => {
   return call_fetch(get_path("admin") + '/call_log/search', request);
}

export const callLogs = request => {
   return call_fetch(get_path("admin") + '/call_log/details', request);

}
export const ManageCSRosters = request => {
   return call_fetch(get_path("admin") + '/call_log/cs_devices', request);
}
export const updateCSstatus = request =>{
   return call_fetch(get_path("admin") + '/call_log/update_cs_status', request);
}

export const getInboundCallList = request =>{
   return call_fetch(get_path("admin") + '/call_log/inbound_call_list', request);
}
export const get_cs_namelist = request =>{
   return call_fetch(get_path("admin") + '/call_log/name_list', request);
}
export const getcallstatus = request => {
   return call_fetch(get_path("admin") + '/call_log/get_call_status', request);
}
export const updateCsCallStatus = request => {
   return call_fetch(get_path("admin") + '/call_log/update_call_status', request);
}

export const getAgentNumber =  request => {
   return call_fetch(get_path("admin") + '/cs_roster/num_list', request);
}

export const cs_activities =  request => {
   return call_fetch(get_path('app') + "/common/cs_activities", request)
}
