import CommonComponent from './../../CommonComponent';
import  {lbl_status, empty_obj} from '../../../../helpers/common_helper';
import { updateStatus, getOrgDetails} from '../../../../actions/common_actions';
import React from 'react';


class FlowContainer extends CommonComponent {
    constructor() {
      super();
 
      this.handleComponentChange = this.handleComponentChange.bind(this);
      this.handleComponentReset = this.handleComponentReset.bind(this);
    }

  handleComponentChange = (event, comp_id) => {
    const id = event.target.id;
    const value = event.target.value;
    var comp = this.state[comp_id];
    comp[id] = value;
    this.setState({[comp_id]: comp});    
  }

 

   handleComponentReset = (event, comp_id) => {
    this.setState({[comp_id]: {}});    
  }
  
  handleComponentElemetChange = (comp_id, element, element_value) => {
    var comp = this.state[comp_id];
    comp[element] = element_value;
    this.setState({[comp_id]: comp});    
   
  }

  handleResetOrgNAddr = (event) => {
    const org = empty_obj(this.state.org);
    const reg_address = empty_obj(this.state.reg_address);
    this.setState({org ,reg_address, div_style: "show_div"});  
  }

  load_flow_rel_mgr = (dp_code,addl_key=null) =>{
    this.setState({flow_rel_mgr_dd : null});
      var associated_with =  "flow";
      // const associated_entity_code = get('acc_prvdr_code');
      //const designation = 'relationship_manager'
      this.setOptionsToStateFromApi("rel_mgr", {...this.def_req, associated_with},addl_key, "kyc");
  }

  load_sales_rep = (dp_code,addl_key=null, ids = null) =>{
    this.setState({sales_rep_dd : null});
      var associated_with =  "flow";
      let req = {...this.def_req, associated_with}
      if(ids != null){
        var rm_ids = ids
        req = {...this.def_req, associated_with, rm_ids}
      }
      this.setOptionsToStateFromApi("sales_rep", req, addl_key, "kyc");
  }
  
  handleOrgChange = (event) =>{
    const org = empty_obj(this.state.org);
    const reg_address = empty_obj(this.state.reg_address);

    this.setState({org ,reg_address,
                   div_style: "disable_div"});
    //const id = document.getElementById("name_list").value;
    const id = event.target.value;  
    const request = {id};
  
    getOrgDetails(this.req(request))
        .then((response) => {
        if(!response){return };

          if(response.data != null){
            var org_detail = response.data;
            var reg_address = org_detail.reg_address;
            this.setState({org: org_detail,
                          reg_address: reg_address});
          }
        });

  }

/* updateStatus = (event) => {
  
  let status = event.target.value.toLowerCase()+"d";
 
  const request = {"id":event.target.id,"status":status};
  //const entity = event.target.entity;
  const request_data = {entity : request}
  

    updateStatus(request_data)
       .then((response) => {
        if(!response){return };

            if(response.status === "success"){
              window.location.reload();
            

            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          }
        ); 
     
      //this.setState({credit_score_factor_id: event.target.id, toView: true});
   } 

StatusButton(props){
    
    return (  
       <input id={props.id} type='button' onClick={this.updateStatus} 
                              value={lbl_status(props.status)}  
                              className={this.style_status(props.status)} />

     );
  }*/

}

export default FlowContainer;