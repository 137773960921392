import React from 'react';
import '../../../styles/flow.css';
import FlowComponent from '../../common/component/core/FlowComponent';
import {dd_value} from '../../../actions/common_actions';
import {createCustCSFValues} from '../../../actions/score_model_actions';
import $ from 'jquery';
export class ListFactorValues extends FlowComponent {
	state = {
     csfList: null    
    }

    componentWillMount(){

      
    	this.result = this.props.factors_list;
      this.result.customer_factor = this.props.customer_factor;





	    const csfList = this.result.score_values.map((csf,i) => <tr key={i}>
	                <td > {dd_value(csf.csf_type)}</td>
	                <td > {csf.csf_gross_value}</td>
	                <td > {csf.csf_normal_value}</td>
	             
	              </tr>
	             );
	              this.setState({csfList});
	              

    }
    componentDidMount(){
      $('#table').removeAttr('width').dataTable({
                "order" : [],
                  "columns": [
                       {"width" : "22%"}, 
                       {"width": "22%"},
                       {"width": "22%"}
                      
                     ],
                "bPaginate": false,
                "searching": false,
                "info": false
                  
            
              });
    }

    handleSave = (event) => {
      this.result.country_code = this.country_code;
     
      createCustCSFValues(this.req(this.result))
        .then((response) => {
          if(!response){return };
            if(response.status === "success"){
             alert("Score Factors saved successfully");
             window.location.reload();
            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          }
        );
    }


    render(){

     
		    return(
          <>
          <div className="row">
          
          <div className="col-md-3">
            <h5><b>Run ID</b> : {this.result.run_id}</h5>
          </div>
          <div className="col-md-3">
            <h5><b>Score</b> : {this.result.score} / 1000</h5>
          </div>
          <div className="col-md-3">
            <h5><b>Result</b> : {dd_value(this.result.result)}</h5>
          </div>
          <div className="col-md-3">
          {(this.result.existing_customer) ?
            <h5><b>Flow Customer ID</b> : {this.result.cust_id}</h5>
            :
            <h5 id="red_color">New Customer</h5>
          }
          </div>
        </div>
        <br/>
              <div className="row">
                <div className="col-md-3">
                </div>
                <div className="col-md-3">
                <h4>Score Factors</h4>
                </div>
              </div>
		            
		             <table id ='table' className="table-responsive table">
		                      <thead className={"text-info"}>
		                      <tr>
		                        <th>Factor</th>      
		                        <th>Gross Value</th>       
		                        <th>Normal Value</th>
		                      </tr></thead>
		                      <tbody> 
		                       {this.state.csfList}
		                      </tbody>

		             </table> 
                 
                 <br/>

                 <input id="float_style" type="button" className="btn btn-primary" onClick={this.handleSave} value="Accept and Save" />
		       
            </>
		      ); 
    }	
  
    
}
export default ListFactorValues;