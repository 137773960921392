
import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { Stack } from '@mui/material';

const data = [1, 2, 3, 4, 5, 67];

function Media(props) {
    const { loading = false } = props;

    return (
        <Grid container wrap="nowrap">
            <div className="row col-12 mx-0 my-2 px-0 d-flex justify-content-center">
                <div className="col-lg-11 mx-2 px-0 col-md-12 col-sm-12">
                    <p className="text-white sizel mt-5 mx-5"> <b className="pl-2">
                        <Skeleton animation="wave" style={{ height: "20px", width: "55%" }} />
                    </b></p>
                    <div className="d-flex align-items-center justify-content-between row mx-5">
                        {data.map((cust_datas, idx) => {
                            return (
                                <div className="col-6 d-flex justify-content-center align-items-center row mx-0 my-3 pl-0">
                                    <p className="m-0 sizem col-5 text_gray">
                                        <Skeleton animation="wave" style={{ height: "40px", width: "50%" }} />

                                    </p>
                                    <p className="mb-0 mt-2 col-7 font_weight_600 sizem text-white secondary_text_width">
                                        <Skeleton animation="wave" style={{ height: "40px", width: "95%" }} />

                                    </p>

                                </div>
                            )
                        })}
                    </div>
                    <div className="text_bottom_border my-3 mx-5"></div>
                    <p className="text-white sizel mx-5 pl-2 mt-5"> <b>
                        <Skeleton animation="wave" style={{ height: "20px", width: "95%" }} />

                    </b></p>
                    <div className="d-flex align-items-center row mx-5 justify-content-between">
                        {data.map((items, idx) => {
                            return (
                                <div className="col-6 d-flex justify-content-center  align-itmes-center row mx-0  my-3 pl-0">
                                    <p className="m-0 col-5  sizem text_gray">
                                        <Skeleton animation="wave" style={{ height: "40px", width: "60%" }} />

                                    </p>
                                    {/* <p className="m-0 col-7">{cust_info.fa_details[items] ?cust_info.fa_details[items]:"" }</p> */}

                                    <p className="mb-0 mt-2 col-7 font_weight_600 sizem text-white secondary_text_width">
                                        <Skeleton animation="wave" style={{ height: "40px", width: "95%" }} />

                                    </p>

                                </div>
                            )
                        })}
                    </div>

                    <div className="text_bottom_border my-2 mx-5 mt-3" ></div>

                    <>
                        <p className="text-white sizel mt-5 mx-5"> <b className="pl-2">
                            <Skeleton animation="wave" style={{ height: "20px", width: "50%" }} />
                        </b></p>
                        <div>
                            <div className={`d-flex row col-12 mx-3 `}>
                                <div className="col-6 px-0  row mx-0">
                                    <div className="col-12 px-0 d-flex align-items-center">
                                        <p className="m-0 col-5  sizem text_gray">
                                            <Skeleton animation="wave" style={{ height: "40px", width: "95%" }} />

                                        </p>
                                        <p className="text-white m-0 font_weight_600 sizem">
                                            <Skeleton animation="wave" style={{ height: "40px", width: "95%" }} />

                                        </p>

                                    </div>

                                    <div className="col-12 px-0 d-flex align-items-center ">
                                        <p className="m-0 col-5  sizem text_gray">                                    <Skeleton animation="wave" style={{ height: "40px", width: "50%" }} />
                                        </p>
                                        <div className="col-7 px-0">
                                            <Skeleton animation="wave" style={{ height: "40px", width: "95%" }} />

                                        </div>

                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className={`col-12 w-100 h-100 p-0 mt-4  mx-5`}>
                                        <Skeleton animation="wave" style={{ height: "40px", width: "95%" }} />

                                        <div className='d-flex align-items-center row m-0'>
                                            <Skeleton animation="wave" style={{ height: "40px", width: "95%" }} />


                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className={`d-flex align-items-center justify-content-center  my-3 col-12`}>
                                <Skeleton animation="wave" style={{ height: "40px", width: "50px" }} />

                            </div>
                        </div>
                    </>

                </div>
                <div className="prev_interction_div">
                    <div className={`bg_gray  px-3  py-2`}>
                        <div className="d-flex align-items-center justify-content-between" >
                            <p className={`text-white sizel px-3 mb-0 pointer `} ><b>
                            <Skeleton animation="wave" style={{ height: "10px", width: "25%" }} />

                                </b> 
                             </p>
                             </div>
                             <Skeleton animation="wave" style={{ height: "20px", width: "45%" }} />

                        </div>
                    </div>
            </div>




        </Grid>
    );
}

Media.propTypes = {
    loading: PropTypes.bool,
};

export default function CustomSkeleton() {
    return (
        <Box sx={{ overflow: 'hidden' }}>
            <Media loading />
            {/* <Media loading/> */}
        </Box>
    );
}