import { Navigate } from 'react-router-dom';
import React from 'react';
import {createAccountProvider} from '../../../actions/account_provider_actions';
import FlowContainer from '../../common/container/core/FlowContainer';
import AccountProvider from '../component/AccountProvider';
import Organisation from '../../common/component/Organisation';
import AddressInfo from '../../common/component/AddressInfo';
import ChooseOrg from '../../common/component/ChooseOrg';
//import {ChooseOrg} from '../../common/partial';
//import {getOrgDetails} from '../../../actions/common_actions';
import '../../../styles/flow.css';

class AccountProviderContainer extends FlowContainer {

  state = {
    toList: false,
    account_provider: {},
    reg_address: {},
    org:{},
    div_style: "show_div",
    dropdown_div_style: "disable_div"
    
  }

   
 componentDidMount() {
      
 }

  handleSubmit = (event)=>{
    event.preventDefault();
    //alert(this.state.account_provider);
    var acc_provider = this.state.account_provider;
    acc_provider.country_code = this.country_code;
    acc_provider.status = "enabled";
    var org = this.state.org;
    org.reg_address = this.state.reg_address;
    org.reg_address.touch = "";
    org.country_code = this.country_code;
    acc_provider.org = org;
   
    
    createAccountProvider(this.req({acc_provider}))
        .then((response) => {
          if(!response){return };
            //alert(JSON.stringify(response.data));
            if(response.status === "success"){
              this.setState({toList: true});
              alert("New Account Provider created successfully");
            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          }
        );
  }


render(){

      if (this.state.toList === true) { 
          
          return <Navigate to={`/account_provider/list/`}/>
        }

      return (
 
      <div className="container text-labelblue">
         <h5>{this.props.title + (this.state.account_provider.name? " : " + this.state.account_provider.name : "")}</h5>
        <form id="account_provider_form" onSubmit={this.handleSubmit}>
            <div id="">
            
            <AccountProvider id="account_provider" title="Account Provider" data={this.state.account_provider} mode={this.props.mode} 
                    onComponentChange={this.handleComponentChange}/>
            <hr/>
            {this.props.mode === "create" && 
            <ChooseOrg id="acc_prvdr_choose_org" resetOrgNAddr={this.handleResetOrgNAddr} onChange={this.handleOrgChange} />
            }
            
            <div className={`${this.state.div_style}`} >
            <Organisation id="org" title="Organisation Details" data={this.state.org} mode={this.props.mode} 
                    onComponentChange={this.handleComponentChange} />
            <br/>
            <hr/>

             <AddressInfo id="reg_address" title="Registered Address" data={this.state.reg_address} mode={this.props.mode} 
                    onComponentChange={this.handleComponentChange} 
                    onComponentReset={this.handleComponentReset}/>

            <br/>
            <hr/>
            </div>
            <input type="button" className={`btn btn-primary ${this.props.mode  === "create" ? "show_button": "hide_button"}`} onClick={this.handleSubmit} value="Create New Account Provider"/>
  
          </div>

        </form>  
        <br/><br/>
      </div>

      );
  }
}

export default AccountProviderContainer;
