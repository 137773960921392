import { } from 'react-router-dom';
import React from 'react';
// import {createLender, viewLender} from '../../../actions/lender_actions';
import FlowContainer from '../../common/container/core/FlowContainer';
import EditAccount from '../component/edit_account';
import Account from '../component/account';
import ListAccount from '../../account/component/list_account';
import '../../../styles/flow.css';
import {createAccount} from '../../../actions/account_actions';
import {check_priv, Config} from '../../../helpers/storage_helper';
import moment from 'moment';
class AccountContainer extends FlowContainer {

  state = {
     view: "account_list",
     acc_elig_appr : false
  }

  componentWillMount(){
    this.ap_alw_appr = Config('acc_prvdrs_allow_approval')
    this.acc_elig_days = Config('acc_elig_validity_days')

    let validity_date = new Date();
    this.validity_date = moment(validity_date).add(this.acc_elig_days, 'day')

    const entity = this.props.entity;
    this.create_acc_btn = 'show_div'
    if(check_priv(entity , 'create_account')){
          this.create_acc_btn_style = "show_div"
    }else{
          this.create_acc_btn_style = "disable_div"
    }
   
    if(this.props.view == "account_view"){
      this.setState({view: "account_view"});
    }
  }

  handleSubmit = (event)=>{
    event.preventDefault();
    var account = this.state.account;
    account.country_code = this.country_code;
    account.network_prvdr_code = account.acc_prvdr_code;
    account.entity = this.props.entity;
    
    if(this.ap_alw_appr.includes(account.acc_prvdr_code) && account.acc_purpose.includes("float_advance")  && (this.state.account.acc_elig_reason == null)){
      alert("Please choose the eligibity type");
      return
    }
    if(['appr_unknown_elig_new_acc', 'appr_inelig_on_comms'].includes(this.state.account.acc_elig_reason) && this.state.acc_elig_appr){
        account.acc_elig_appr = this.state.acc_elig_appr;
        account.validity_date = this.validity_date.format('YYYY-MM-DD');
      
        // alert("This eligibility requires approval, please select the approval check box.");
        
    }
    if(this.props.entity == "customer"){
      account.cust_id = this.props.entity_id;
      if(account.acc_purpose.includes("float_advance")){
          account.assessment_type = "self"
      }
      if(account.acc_purpose.includes("terminal_financing")){
          account.acc_number = account.reconfirm_acc_number = "dummy"
      }
    }
    else if(this.props.entity == "lender"){
       account.lender_code = this.props.entity_id;
       account.network_prvdr_code = account.acc_prvdr_code; 
    }
    else if(this.props.entity == "acc_prvdr"){
       account.acc_prvdr_code = this.props.entity_id;
    }    
    createAccount(this.req({account}))
        .then((response) => {
            this.resp(response)
          if(!response){return };
            if(response.status === "success"){
                alert(response.message);
                this.setState({view : "account_list"});
            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          }
        );
  }

  handleAddAccountClick = () =>{
    this.setState({view : "account",
                  account: {is_primary_acc: false,
                  holder_name: this.props.cust_name,
                  acc_number:  this.props.dp_cust_id,
                  acc_prvdr_code:this.acc_prvdr_code
                  }
                });
  }


  handleBackClick  = (event) =>{
    this.create_acc_btn = 'show_div'
    this.setState({view : "account_list"});
  }

  handleEdit = (event) => {
    this.setState({view : "edit_account", account_id: event.target.id});
  }

  handleAccElig = (e) => {
    
    this.create_acc_btn = "disable_div"
    this.setState({'acc_elig_appr' : false});
    if(this.state.account.acc_elig_reason === 'no_data'){
      alert("Cannot add a MTN account without commission data")
      return
    }
    else if(['appr_unknown_elig_new_acc', 'appr_inelig_on_comms'].includes(this.state.account.acc_elig_reason) && this.state.acc_elig_appr){
        this.create_acc_btn = "show_div"
        this.setState({'acc_elig_appr' : true});
    }
    else if(['elig_on_comms', 'elig_on_txn_stmt'].includes(this.state.account.acc_elig_reason)) {
      this.create_acc_btn = "show_div"
      
    }
  }
  handleAccEligAppr = (e) => {
    
    if(this.state.acc_elig_appr){
      this.setState({'acc_elig_appr' : false});
      this.create_acc_btn = "disable_div"
    }
    else{
      this.setState({'acc_elig_appr' : true});
      this.create_acc_btn = "show_div"
    }
    
  }
  
    createUI(view){
        
        var el= null;
         if(view == "account"){ 
          el =  <div>
                  <input  type='button' onClick={this.handleBackClick} value='Back'  className='btn btn-primary' />                  
                  <Account  id="account" entity={this.props.entity} title="Account Provider" data={this.state.account} mode={this.props.mode} 
                          onComponentChange={this.handleComponentChange} onAccElig = {this.handleAccElig} onAccEligAppr ={this.handleAccEligAppr}
                          ap_alw_appr = {this.ap_alw_appr} validity_date = {this.validity_date} acc_elig_days={this.acc_elig_days}/><br/>
                  <input type="button" className={`btn btn-primary ${this.create_acc_btn}`} onClick={this.handleSubmit} value="Create New Account"/>
                </div>
         }else if(view == "account_list"){ 
          
          el =  
            <div>
                {/* <input type='button' className={`btn btn-primary account ${this.create_acc_btn_style}`} onClick={this.handleAddAccountClick} value="Add New Account"/> */}
                <ListAccount entity={this.props.entity} entity_id={this.props.entity_id} onEditClick={this.handleEdit} disabled={this.state.calling_api}/>
            </div>

        }else if(view == "edit_account"){ 
          
          el =  
            <div>                
                <EditAccount id="edit_account" entity={this.props.entity} entity_id={this.props.entity_id} account_id={this.state.account_id} handleBackClick={this.handleBackClick}/>
            </div>

        }

         return  (
               <div>
              
                {el}
                </div>
         
          );
     
  }

render(){
      return (
          <div className="container px-0" >
            {this.createUI(this.state.view)}
            <br/><br/>
          </div>
      );
  }

}

export default AccountContainer;
