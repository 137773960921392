import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import '../../../styles/flow.css';
import ReconfirmBoxes from '../../common/component/core/ReconfirmBoxes';
import {dd_parent} from "../../../actions/common_actions";
import AccPurposeMultiSelect from '../../../helpers/accPurposemulti';
import {getMasterData} from '../../../actions/common_actions';

class Account extends FlowComponent {
  state = {
    comp_name: "account",
    toList: false,
    acc_purposes_to_post:[]
  }
  componentWillMount(){

    super.componentWillMount(); 
    /*var country_code = this.country_code;
    var status = "enabled";
    var  request = {"country_code": country_code, "status": status};*/

    this.setOptionsListToStateFromAppApi('common', 'list', this.def_req , ['acc_prvdr_code', 'name'], "acc_prvdrs");
    this.setOptionsListToStateFromAppApi('common', 'list', this.def_req, ['account_types', 'account_types'], "acc_types");

    if(this.props.entity == "lender"){
      this.setOptionsToStateFromApi("acc_prvdr");
    }
    //this.acc_purpose.push({'id': 'others', 'name': 'Others'});
     let entity = this.props.entity
     if(this.props.entity == "data_prvdr"){
         entity = "data_provider"
     }
    this.getSelectOptionsFromResult(dd_parent('account_purpose', entity), "acc_purpose", ['data_code','data_value']);


   }

  addAccpurTostate = (val) => {    
    this.setState(state=>state.acc_purposes_to_post=val) 
  }

  loadbranch= (event)=>{
    const acc_prvdr_code = event.target.value.toLowerCase();
    var data = this.state.account;
    const data_key = acc_prvdr_code+"_branches";

    const request = {"status": 'enabled', data_key,"country_code": this.country_code }
    const request_data = {"master_data": request};

      getMasterData(this.req(request_data))
         .then((response) => {
          if(!response){return };
            if(response.status === "success"){

                this.getSelectOptionsFromResult(response.data, "acc_branches", ["data_code", "data_value"]);
            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          }
        );          
  }

  render(){

    return (
     <form autoComplete='off'>

    <div id="" className="text-labelblue">

      <div className="row">
        <div className="col-md-3"><h4>Account Details</h4>
        </div>
      </div>
       {/* {this.props.entity == "lender" &&
          <div className='row'>
          <div className="col-md-3">
                <span>Data Provider</span><font className="text-danger">   *  </font>                
                <select id="network_prvdr_code" onChange={this.handleChange}  disabled value={this.acc_prvdr_code} className="form-control" type="text" required="required">
                   {this.state.data_provider_dd}
                </select>
          </div>
          </div>
        } */}
      <div className="row">

        <div className="col-md-3">
            <span>Account Provider</span><font className="text-danger">   *  </font>
            <select id="acc_prvdr_code" value={this.props.data.acc_prvdr_code}  onChange={(e) => {this.handleChange(e); this.loadbranch(e);}}  className="form-control" type="text" required="required">
              {this.state.acc_prvdrs_dd}
            </select>
        </div>
         <div className="col-md-3">
          {/* <span>Account Purpose</span><font className="text-danger">   *  </font>
          <select id="acc_purpose" value={this.props.data.acc_purpose} onChange={this.handleChange} className="form-control" required>
            {this.state.acc_purpose_dd}
            {this.getSelectOptions('account_purpose')} 

          </select>               */}
          <AccPurposeMultiSelect id="acc_purpose" onChange={this.handleChange} acc_purposes={this.state.acc_purpose_dd} initialAct_purpose={null} must={true}/>
          
        </div>
          {this.state.acc_branches_dd &&
        <div className="col-md-3">
            <span>Branch</span><font className="text-danger"> </font>
            <select id="branch" value={this.props.data.branch} onChange={this.handleChange} className="form-control" type="text" required="required">
              {this.state.acc_branches_dd}
            </select>
        </div>
  }
        <div className="col-md-3">
      <span>Account Holder Name</span><font className="text-danger"> </font>

      <input id="holder_name" value={this.props.data.holder_name} onChange={this.toUpperHandleChange} className="form-control" required/>
      </div>
      
      

    </div> <br />      

      {this.props.data.acc_purpose && !this.props.data.acc_purpose.includes('terminal_financing') &&
        <div className="row">
          <div className="col-md-6">
              <div className="row">
            <ReconfirmBoxes className="col-md-6 " label="Account Number" id="acc_number" onChange={this.handleChange}/>
            {/* <span>Account Number</span><font className="text-danger">   *  </font>
            <input id="acc_number"  value={this.props.data.acc_number} onChange={this.handleChange} className="form-control" required /> */}
              </div>
          </div>
        </div>
      }
      {/*<div className="row">
        <div className="col-md-3">
          <span>Balance</span>
          <input id="balance" value={this.props.data.balance} onChange={this.handleChange} className="form-control" />
      </div>

      </div>*/}<br/>
        {this.props.data.acc_purpose && this.props.data.acc_purpose.includes('float_advance') &&
          <div className="row">
            <div className="col-md-3">
              <span>Assess By</span>
              <font className="text-danger"> </font>
              <select id="assessment_type" value={this.props.data.assessment_type} onChange={this.handleChange} className="form-control" required>
                  <option selected disabled value='self'>Self Assessment</option>
              </select>
            </div>
          </div>
        }<br/>
        {this.props.data.acc_purpose && this.props.data.acc_purpose.includes('float_advance') && this.props.ap_alw_appr.includes(this.props.data.acc_prvdr_code) &&
        <div className="container acc_elig">
            <div className="row justify-content-center">
              <div className="col-md-7" >	
                <span>Customer holds a, </span><br/><br/>
                <label><input id="acc_elig_reason" type="radio" name="acc_elig_reason" value="elig_on_txn_stmt" onChange={(e) => {this.handleChange(e); this.props.onAccElig(e)}} checked = {this.props.data.acc_elig_reason === 'elig_on_txn_stmt'} />  Existing Account (ELIGIBLE based on transaction statement data) &nbsp;&nbsp;&nbsp;</label>
                <label><input id="acc_elig_reason" type="radio" name="acc_elig_reason" value="elig_on_comms" onChange={(e) => {this.handleChange(e); this.props.onAccElig(e)}} checked = {this.props.data.acc_elig_reason === 'elig_on_comms'} />  Existing Account (ELIGIBLE based on commission) &nbsp;&nbsp;&nbsp;</label>
                <label><input id="acc_elig_reason" type="radio" name="acc_elig_reason" value="appr_inelig_on_comms" onChange={(e) => {this.handleChange(e); this.props.onAccElig(e)}} checked = {this.props.data.acc_elig_reason === 'appr_inelig_on_comms'} />  Existing Account (NOT ELIGIBLE based on commission) &nbsp;&nbsp;&nbsp;</label>
                <label><input id="acc_elig_reason" type="radio" name="acc_elig_reason" value="no_data" onChange={(e) => {this.handleChange(e); this.props.onAccElig(e)}} checked = {this.props.data.acc_elig_reason === 'no_data'} />  Existing Account (Do not have commission data) &nbsp;&nbsp;&nbsp;</label>
                <label><input id="acc_elig_reason" type="radio" name="acc_elig_reason" value="appr_unknown_elig_new_acc" onChange={(e) => {this.handleChange(e); this.props.onAccElig(e)}} checked = {this.props.data.acc_elig_reason === 'appr_unknown_elig_new_acc'} />  New Account (Cannot determine Eligibilty) &nbsp;&nbsp;&nbsp;</label>
              </div>
            </div>
          <br/>
          
          {(this.props.data.acc_elig_reason === 'appr_unknown_elig_new_acc' || this.props.data.acc_elig_reason === 'appr_inelig_on_comms') &&
            <>
            <div className="row justify-content-center">
                <div className="col-md-4" >	
                  <span>Approve for {this.props.acc_elig_days} days</span>
                </div>
                <div className="col-md-4" >	
                  <span>Validity untill {this.props.validity_date.format('MMM Do YYYY')}</span><br/><br/>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-5" >	
                  <span>This case requires COO approval</span><font className="text-danger">   *  </font> <br/><br/>
                </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-3">	
                <label className="checkbox-inline">
                <input id="acc_elig_appr" type="checkbox" onChange = {this.props.onAccEligAppr}/>
                <span> Approve</span>
              </label>
              </div>
            </div>
            </>
          }
        
        </div>
      }
    </div>
    <br/>
    <br/>
    <div className="text-white">
        <b>Note:</b> 
        <ul>
          <li>Adding a new account to the customer profile will initiate a ReKYC process.</li>
          <li>The account will be in disabled status until the ReKYC is approved by the auditor.</li>
          <li>Existing agreement will be inactivated.</li>
        </ul>
    </div>
    
     </form>

      );
  }
}

export default Account;
