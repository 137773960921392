import { get_path } from '../helpers/common_helper';
import {call_fetch, admin_path,app_path} from './../helpers/fetch_helper';

export const createAccountTxn = request   => {
   return call_fetch(get_path("admin") + '/account/create_acc_txn', request);
}

/*export const listAccountTxns = request  => {
   return call_fetch(admin_path + '/account/list_acc_txns', request);
}*/

export const getRefAccounts = request  => {
   return call_fetch(get_path("admin")  + '/account/ref_accounts', request);
}
export const getFloatSwitchAccounts = request  => {
   return call_fetch(get_path("admin")  + '/sales/list_float_switch_aps', request);
}
export const getAccountTxns = request =>{
	return call_fetch(get_path("admin")  + '/account/search',request)
}
/*export const getAccountTxnsType = request => {
   return call_fetch(app_path + '/common/account_txn_type', request);
}*/