import React from "react";
import FlowContainer from "../../common/container/core/FlowContainer";
import {Modal,Button } from 'react-bootstrap';
import { getLoan } from "../../../actions/loan_actions";
import { listSmsLogs } from "../../../actions/sms_action";
import { BasicReactTable } from "../../../helpers/react_table";
import moment from "moment";
import { Homeacc_prvdrLogoselector, capitalize_first_letter } from "../../../helpers/common_helper";
import { dd_value } from "../../../actions/common_actions";
import CommonReactTable from "../../../helpers/commonReact_table";
class Sms_modal extends FlowContainer{
    state={
        loan:[],
        sms_rowdata:[],
		sms_column:[],
        current_id:'loan_doc_id',
        acc_prvdr_code:''
    }
    get_sms_data=()=>{
        let loan;
            const loan_doc_id = this.props.loan_doc_id;
            
            this.setState({loader:true})
                getLoan(this.req({loan_doc_id}))
                    .then((response) => {
                    this.resp(response);
                      if(!response){return this.setState({loader:false})};
                        if(response.status === "success"){
                            loan = response.data.loan;
                            response.data.loan&&
                               this.setState({loan});
                               if(this.props.view=='home'||(this.props.view=='fa_page' && response.data.loan.status=='pending_disbursal')){
                                this.setState({current_id:'loan_doc_id',acc_prvdr_code:response.data.loan.acc_prvdr_code}, ()=>this.handle_sms_data(response.data.loan.loan_doc_id))
                                
                               }
                               else{
                                
                                this.setState({current_id:'mobile_num',acc_prvdr_code:response.data.loan.acc_prvdr_code}, ()=>this.handle_sms_data(response.data.loan.cust_mobile_num))
                                
                               }
                        }
                    }
                );
            
                
        }
    
    handleClose=()=>{this.setState({sms_modal:false})}
    
    handle_sms_data=(val)=>{
        var request={
            sms_search_criteria:{
                start_date:moment(this.state.loan.loan_appl_date).format('YYYY-MM-DD'),
                end_date:moment(this.state.loan.repayment_date ?this.state.loan.repayment_date :this.state.loan.due_date? this.state.loan.due_date : new Date()).format('YYYY-MM-DD'),
                [this.state.current_id]:val,
                
             
        }}
        listSmsLogs(this.req(request))
        .then((response) => {
            this.resp(response)
            if(!response){return this.setState({loader:false})};
                if(response.status === "success"){
                    const smsArray = [];
                    for (let i=0; i<response.data.length; i++){
                      smsArray.push({...response.data[i], acc_prvdr_code: this.state.acc_prvdr_code});
                    }
                    this.setState({sms_modal: true,loader: false,sms_column: this.column_data(),sms_rowdata: smsArray
                    });
                  }
    })
    }
    find_diff_time=(val)=>{
        const diff = moment().diff(moment(val));
        const duration = moment.duration(diff);
        const years = duration.years();
        const months = duration.months();
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        if (years !== 0) {
            return `${years} year${years > 1 ? 's' : ''} ago`;
          } else if (months !== 0) {
            return `${months} month${months > 1 ? 's' : ''} ago`;
          } else if (days !== 0) {
            return `${days} day${days > 1 ? 's' : ''} ago`;
          } else if (hours !== 0) {
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
          } else if (minutes !== 0) {
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
          } else if (seconds !== 0) {
            return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
          } else {
            return 'just now';
          }
    }
    column_data=()=>{
        return[
            {
                Header : "Vendors",
                id: "vendor_code",  
                accessor:row =>  row.vendor_code
            },
            {
                Header : "Direction",
                id: "direction",  
                accessor:row => capitalize_first_letter(row.direction) 
            },
            {
                Header : "Status",
                id: "status",  
                accessor:row => capitalize_first_letter(row.status)
            },
            {
                Header : "Mobile Number",
                id: "mobile_num",  
                accessor:row => row.mobile_num
            },
            {
                Header : "Sent Time",
                id: "date",  
                accessor:row =>this.find_diff_time(row.created_at)
            },
        ]
    }
    render(){
        return(<>
        	<button className={`btn btn-primary p-2 w-100 ${this.props.btn_style?this.props.btn_style:''}`}disabled={this.state.loader?true:false} onClick={this.get_sms_data}>{!this.state.loader?'Show SMS Logs':(
                 <div class="sms_modal_container p-2">
                 <div class="sms_modal_wrapper">
                    <div class="sms_modal_loader">
                       <div class="sms_modal_dot"></div>
                    </div>
                    <div class="sms_modal_loader">
                       <div class="sms_modal_dot"></div>
                    </div>
                    <div class="sms_modal_loader">
                       <div class="sms_modal_dot"></div>
                    </div>
                    <div class="sms_modal_loader">
                       <div class="sms_modal_dot"></div>
                    </div>
                 </div>
              </div>
            )}</button>
        <Modal className='sms_modal' show={this.state.sms_modal} onHide={this.handleClose} backdrop="static" keyboard={false}>
					<Modal.Header className='p-0 sms_for_close' closeButton>
						<Modal.Title className='sms_modal_header p-2'><h4 className=' text-center text-light mt-3'>SMS Logs</h4></Modal.Title>
					</Modal.Header>
					<Modal.Body className='text-center sms_modal_table'><div className="w-100 msg_container">
					{this.state.sms_rowdata&&this.state.sms_rowdata.length>0?
                    // <CommonReactTable row_data={this.state.sms_rowdata} column_data={this.state.sms_column} showPag={false} minRow={0} /> 

                    <BasicReactTable row_data={this.state.sms_rowdata !== undefined ? this.state.sms_rowdata : []} column_data={this.state.sms_column}  pagination={false}  />    

					:<h4 className='text-center p-3'>No Sms Found</h4> }
                    </div>
					</Modal.Body>
					<Modal.Footer>
						
					</Modal.Footer>
				</Modal>
        </>)
    }
}
export default Sms_modal