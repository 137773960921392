import React from 'react';

export 	function RepaymentDetail (props) {
		var days = '';
		if(props.duration){
			days = props.duration +" day";
		}
			return(  
			<div>
					*<i>Total repayment if float is utilized for all the {days} duration</i>
					<br/>
			</div>);
}