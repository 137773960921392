import React from "react"
import { Config, check_priv, get } from "../../helpers/storage_helper"
import {FaRegBell} from '@react-icons/all-files/fa/FaRegBell'
import { useEffect, useMemo, useState } from "react"
import CustomFlag from "../common/component/CustomFlag"
import { useDispatch, useSelector } from "react-redux"
import SelectMarket from "../home/SelectMarket"
import { setValuesIntoRedux } from "../../Redux/Slices/CommonSlice"
import { Link, useLocation } from "react-router-dom"
import { GetLatestNotifications, UpdateReadStatus, updateFcmToken } from "../../actions/notification_action"
import { changeNotificationState, updateNotficationModalstate } from "../../Redux/Slices/HeaderSlice"
import Notification from "../Notification/Notification"
import { common_req } from "../../helpers/common_helper"
import NotificationModal from "../utility/Component/NotificationModal"
// import { onMessage } from "firebase/messaging"
// import { messaging } from "../../firebase/config"
import Logo from "../common/partials/logo"
import { Box } from "@mui/material"
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import IconButton from '@mui/material/IconButton';
import createCustomTheme from "../../MUI/Theme"
import { data } from "jquery"
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import Router from "../../Router/Router"

const Header = ()=>{

  const dispatch = useDispatch()
  const {notification} = useSelector(state=>state.HeaderSlice)
  const [update_market,setUpdatemarket] = useState(false) 
  const admin_roles = ['super_admin', 'ops_admin', 'market_admin', 'it_admin','ops_analyst']
  const change_market = (get('role_codes') == 'super_admin'|| get('role_codes') == 'it_admin' ||  get('role_codes') == 'ops_analyst' || (Config('global_user') && Config('global_user').includes(get('user_id')))) 
  const datas = useSelector(state=>state.Values)

  const [market, setMarket] = useState(get('market'))
  const location = useLocation();

  const onMessageListener = () =>
    onMessage(messaging,(payload) => {
      getNotifications()
  
    }
  );

  const  handle_visibility_change = ()=>{
    if(document.visibilityState === 'visible'){
      getNotifications()
    }
  }
  const theme = useTheme();


  useEffect(()=>{
    getNotifications()
    update_fcm_token()

     // get the latest notifications if background notifications is arrived
    //  document.addEventListener('visibilitychange', handle_visibility_change)

    //  whenever new fcm arrived
    //  onMessageListener()


     return ()=>{
      // document.removeEventListener('visibilitychange', handle_visibility_change);
     }
  },[])

  
  const update_fcm_token = ()=>{
    const fcmToken = localStorage.getItem('fcmToken') ? localStorage.getItem('fcmToken') : null;
    const updatedfcmToken = localStorage.getItem('updated_fcmToken') ? localStorage.getItem('updated_fcmToken') : '';
        if (fcmToken && (fcmToken != updatedfcmToken)){
          updateFcmToken(common_req({fcm_token:fcmToken})).then((response)=>{
            if(!response) return;
            if(response.status=='success'){
              localStorage.setItem('updated_fcmToken',fcmToken)
            }
            else{
              return
            }
          });
         
        }
  }




  const getNotifications = ()=>{
    GetLatestNotifications(common_req({notification_count:8})).then((response)=>{
      dispatch(changeNotificationState({loader:true}))
      if(!response){
        dispatch(changeNotificationState({loader:false,no_notification:true}))
        return
      }
      if(response.status=='success'){
        if(response.data.length==0){
          dispatch(changeNotificationState({loader:false,no_notification:true}))
          return
  
        }
        dispatch(changeNotificationState({loader:false,notification_arr:response.data, no_notification:false}))
  
        let un_read = response.data.filter(val=>val.read==false)
        dispatch(changeNotificationState({un_read_notifications_count:un_read.length}))
      }
    })
  }

  const selectAccPrvdr = (event) => {
    dispatch(setValuesIntoRedux({update_market_details:true,update_type:"acc_prvdr_only"}))

    // const roles = get('role_codes')
    // if(roles === "super_admin" || roles=== "it_admin" ||roles ===  'ops_analyst'){
    //     // this.toChange = "acc_prvdr";
    //     Navigate('/management-dashboard')
    // }else{
    //     this.toChange = "acc_prvdr_only";
    // }
   
    // this.setState({toSelectMarket:true});                                  
}
// var select_market = false

const selectMarket = (event) => {
  if(change_market){
    dispatch(setValuesIntoRedux({update_market_details:true,update_type:"market"}))
  }
}


   const  handleBellclick = (option)=>{
      if(option==null){
        option = !notification.show_notification
      }
     
      dispatch(changeNotificationState({
          show_notification: option
        }))
   
  }

  const change_read = (notification)=>{
    if(notification.read == false){
      dispatch(changeNotificationState({loader:true}))
  
      UpdateReadStatus(common_req({notification_id:[notification.id]})).then(response=>{
        if(!response){
          dispatch(changeNotificationState({loader:false,no_notification:true}))
          return
         };
        if(response.status === 'success'){
            getNotifications()
        }}
      )
    }
  }

  const handle_portfolio_modal = (option)=>{
    if((notification.portfolio_details && notification.portfolio_details.type =="flagged_customers" ) && (!option)){
      change_read(notification.portfolio_details)
    }
    dispatch(changeNotificationState( {
        portfolio_modal: option
      }))
  
  
  }

  const set_portfolio_details = (notification) =>{
    dispatch(updateNotficationModalstate(notification)).then(()=>{
      handle_portfolio_modal(true)
    })
    
  }

  const setColorMode = () =>{

    const current_mode  = datas ? datas.mode :"dark"
    const theme = current_mode == 'light' ? 'dark' : 'light' 
     dispatch(setValuesIntoRedux({mode : theme}))
  }
  




    return (
        <div className="main-pannel">
          
            <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top pb-3">
                <div className="container-fluid">
                  <div className="navbar-wrapper">
                    <div className="navbar-minimize">
                      {/* <button id="minimizeSidebar" className="btn btn-just-icon btn-white btn-fab btn-round">
                        <i className="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
                        <i className="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
                      </button> */}
                    </div>
                  </div>
                  <button className="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="navbar-toggler-icon icon-bar"></span>
                    <span className="navbar-toggler-icon icon-bar"></span>
                    <span className="navbar-toggler-icon icon-bar"></span>
                  </button>
                  <div className="collapse navbar-collapse justify-content-end">

                           {notification.show_notification && <Notification
                                props={notification} 
                                handleBell ={handleBellclick} 
                                handle_modal = {handle_portfolio_modal} 
                                set_portfolio_details={set_portfolio_details} 
                                change_read={change_read}
                            />}
                         
                  
                    <ul className="navbar-nav">

                    {location.pathname != '/view_all_notifications' && location.pathname != '/report/par_health_report' && check_priv('user','web_notification') &&

                        <li style={{position:'relative'}}>

                            <FaRegBell size={25} style={{marginRight:'30px',cursor:'pointer'}} onClick={(e)=>
                            handleBellclick(null)

                            }/>
                           {   <span className='notification-count'>
                            {notification.un_read_notifications_count}
                            </span>}

                          </li>}
                      
                        <li>
                            <div className={ change_market ? "flag_cursor pointer" :""} 
                            onClick={()=>selectMarket()}
                            >
                                <CustomFlag country_code={market.country_code} height={30} width={30}/>
                            </div>
                        </li>
                        {(admin_roles.includes(get('role_codes')) || (Config('global_user') && Config('global_user').includes(get('user_id')))) &&
                          <li>
                              <div className="flag_cursor pl-5 pointer" onClick={()=>{selectAccPrvdr()}}>
                                  <Logo file_name={"t_"+get('acc_prvdr_logo')} entity="acc_prvdr"
                                        entity_code={get('acc_prvdr_code')}
                                        country_code={market.country_code}
                                        color="logo_style" />&nbsp;&nbsp;
                              </div>
                          </li>
                        }
                      <li className="nav-item dropdown px-4">
                        <Link to={"/logout"} >
                          <i className="material-icons text-white px-2 fs-2">logout</i>
                      
                        </Link>
                      </li>
                    </ul>
                  </div>
                 
                </div>
                <div>
                {/* <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'background.default',
                    color: 'text.primary',
                    borderRadius: 1,
                    p: 3,
                  }}
                >
                  {theme.palette.mode}
                  <IconButton sx={{ ml: 1 }} onClick={setColorMode} color="inherit">
                    {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                  </IconButton>
                </Box> */}
                </div>
              </nav>

              {location.pathname != '/report/par_health_report' && location.pathname !='/view_all_notifications' && notification.portfolio_modal &&
                 <NotificationModal notification={notification} handle_modal={handle_portfolio_modal} handle_bell_click = {handleBellclick} id = "portfolio_modal"/>
              }
        </div>
    )
}


export default Header