import React from "react";

import FlowComponent from '../../common/component/core/FlowComponent';
import { AiOutlineIdcard } from "react-icons/ai";
import { BiMap } from "react-icons/bi";
import { MdAccountCircle, MdEdit } from "react-icons/md";
import { el, th } from "date-fns/locale";
import { dd_value } from '../../../actions/common_actions';
import DatePicker from 'react-datetime';
import DisplayImage from '../../common/component/DisplayImage';
import { Homeacc_prvdrLogoselector, get_dir_path, capitalize_first_letter, multi_acc_pur_tostring, get_path } from '../../../helpers/common_helper';
import moment from "moment";
import { get_pending_list, update_lead, get_data, retrieve_name, submit_handler, submit_holder_name_proof } from '../../../actions/lead_actions';
import { get } from '../../../helpers/storage_helper';
import GoogleMapSetup from '../../../helpers/googleMap';
import { BsChevronDown, BsTrash } from 'react-icons/bs';
import FileUploadComponent from '../container/FileUploadComponent';
import { BiPhoneCall, BiCheck } from "react-icons/bi";
import { GoVerified } from 'react-icons/go';
import PDFtoImage from '../component/Pdf_to_image';
import Modal from 'react-bootstrap/Modal';
import { Navigate } from 'react-router-dom';
import { HiArrowCircleRight, HiArrowCircleLeft } from "react-icons/hi";
import withRouter from "../../../Router/Withrouter";


class SelfRegContainer extends FlowComponent {
  constructor() {
    super();

    this.state = {
      audit_kyc_wizard_menus: {
        national_id: false,
        gps_address: true,
        biz_accounts: false,
      },
      seconds: [45],
      timer: [0],
      wizard_menu: [],
      active_key: "national_id",
      get_data_value: '',
      loader: false,
      index: 1,
      retrieve_button_status: true,
      name_match_reason: null,
      holder_name: null,
      loader: false,
      time: [],
      edit_status: false,
      approve_btn: '',
      reject_btn: '',
      reassign_modal: false,
      retrieve_holder_name: true,
      lists: [],
      name_mismatch_loader: false,
      active_index: 0,

    }
    this.timer = [0];

    this._isMounted = false;
    this.national_id_details = ["first_name", "last_name", "national_id", "gender", "dob"]
  }





  componentDidMount() {

    this.get_menus();

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  get_data_handler(first_time_render = null) {

    const paramValue = this.props.params.id;
    this.setState({ loader: this.state.name_mismatch_loader ? false : true })
    var request = { "id": paramValue, "entity_id": paramValue, "entity": "leads", "country_code": get('market').country_code, }
    get_data(this.req(request))
      .then((response) => {
        if (!response) { return }
        if (response.status == "success") {
          var responseData = response.data
          if (first_time_render != null) {
            var audit_kyc_wizard_menus = this.state.audit_kyc_wizard_menus
            audit_kyc_wizard_menus["national_id"] = true
            audit_kyc_wizard_menus["gps_address"] = false
            audit_kyc_wizard_menus["biz_accounts"] = false
            this.setState({ audit_kyc_wizard_menus })
          }
          this.setState({ responseData, lead_id: paramValue, loader: false, get_data_value: response.data, gender_edit: false, dob_edit: false }, () => { this.bttn_status_handler() })

        }
      })

  }


  get_menus() {
    var audit_kyc_wizard_icons = [
      { "icon": <AiOutlineIdcard />, "key": "national_id", "title": 'National ID', "verify_key": "national_id_verify" },
      { "icon": <BiMap />, "key": "gps_address", "title": `Shop Photo`, "verify_key": "gps_address_verify" },
      { "icon": <MdAccountCircle />, "key": "biz_accounts", "title": `Biz Account`, "verify_key": "biz_accounts_verify" },

    ]

    this.setState({ wizard_menu: audit_kyc_wizard_icons }, () => { this.get_data_handler("initial_time") })
  }


  active_menu(active_key,index) {
    var obj = {}
    var active_index=""

    for (const [key, value] of Object.entries(this.state.audit_kyc_wizard_menus)) {
      if (key == active_key) {
        obj[active_key] = true
        active_index=index

      }
      else {
        obj[key] = false
      }
    }

    this.setState({ audit_kyc_wizard_menus: obj, active_key,active_index })


  }
  spinner() {
    return (
      <div class="d-flex col-lg-10 py-2 px-5 pb-5 justify-content-center ml-5">
        <div class="spinner-border text-light" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    )
  }

  call_log_verification(type = null) {
    return (
      <div>

        <div>
          <br />
          <div className='row no-margin' >
            <div className='col-md-12' style={{ background: '#c4c7ca', borderRadius: '0px 20px 20px 0px' }}>
              <div className='row' >
                <div className='col-md-2 ' style={{ background: '#59687c', borderRadius: '0px 20px 20px 0px' }}>
                  <p className='no-margin text-white agreementPersonalDetailstext' style={{ padding: `20px 10px` }}> <b>Step 1</b></p>

                </div>
                <div className='col-md-7 d-flex justify-centent-end align-self-center'>
                  <p className='no-margin  agreementPersonalDetailstext  no-padding' style={{ color: 'black' }}> Please dial the number </p>
                </div>
                <div className='col-md-3 d-flex justify-centent-end align-self-center'>
                  <p className='no-margin agreementPersonalDetailstext' style={{ color: 'black' }}> <BiPhoneCall size={25} color={'green'} style={{ paddingRight: '5px' }} /><b> {(this.state.get_data_value) ? this.state.get_data_value.mobile_num : "-"}</b> </p>

                </div>
              </div>
            </div>

          </div>


          <br /><br />
          <div className='row no-margin' >
            <div className='col-md-12' style={{ background: '#c4c7ca', borderRadius: '0px 20px 20px 0px' }}>
              <div className='row' >
                <div className='col-md-2 ' style={{ background: '#59687c', borderRadius: '0px 20px 20px 0px' }}>
                  <p className='no-margin text-white agreementPersonalDetailstext' style={{ padding: `20px 10px` }}> <b>Step 2</b></p>

                </div>
                {type == 'entirely_different_name' &&
                  <div className='col-md-10 d-flex justify-centent-end align-self-center'>
                    <p className='no-margin  agreementPersonalDetailstext  no-padding' style={{ color: 'black' }}> Reconfirm the Account Ownership with the customer and take a decision to approve or reject by changing the ownership appropriately</p>

                  </div>
                }

                {type == "name_match_false" &&
                  <div className='col-md-10 d-flex justify-centent-end align-self-center'>
                    <div className="row col-12">
                      <p className='no-margin  col-10 agreementPersonalDetailstext  ' style={{ color: 'black' }}> Confirm the third party’s name with the customer</p>
                      {(this.state.verified_call_log || (this.state.get_data_value && this.state.get_data_value.call_log_verify)) ?

                        <GoVerified color={"green"} size="25" /> :
                        <a className='no-margin col-md-2 p-0  agreementPersonalDetailstext pointer ' onClick={() => this.call_log_verify_handler(true, "call_log_verify")} style={{ color: '#0051C9', textDecoration: 'underline' }} ><b> I Confirmed</b> </a>
                      }

                    </div>
                  </div>
                }

                {type == "name_match_true" &&
                  <>
                    <div className='col-md-10 d-flex justify-centent-end align-self-center'>
                      <p className='no-margin  agreementPersonalDetailstext  no-padding' style={{ color: 'black' }}> Reconfirm the Account Ownership with the customer and take a decision to approve or reject by changing the ownership appropriately</p>

                    </div>
                  </>
                }

              </div>
            </div>

          </div>




          <br />

          <br />

        </div>
      </div>
    )
  }

  get_national_id_datas(value, type) {

    this.setState({
      ...this.state,
      [type]: value
    })
  }

  sumbit_handler_proof() {
    this.setState({ call_log_text_status: true })
  }

  bttn_status_handler() {
    var a = "";

    if (this.state.get_data_value && this.state.index) {

      if (this.state.get_data_value.name_match) {
        if ((this.state.acc_owner_type == "rented/bought") || (this.state.get_data_value.acc_owner_type == "rented/bought")) {
          this.setState({ approve_btn: true, reject_btn: true })
        }
        if (((this.state.acc_owner_type == "owned") || (this.state.get_data_value.acc_owner_type == "owned"))) {

          this.setState({ approve_btn: true, reject_btn: true })
        }
      }
      else if (this.state.get_data_value.name_match == false) {


        if ((this.state.acc_owner_type == "rented/bought") || (this.state.get_data_value.acc_owner_type == "rented/bought")) {

          if (((this.state.mismatch_reason == "entirely_different_name") || (this.state.get_data_value.mismatch_reason == "entirely_different_name"))) {
            this.setState({ approve_btn: this.state.get_data_value.mismatch_reason ? true : false, reject_btn: this.state.get_data_value.mismatch_reason ? true : false })
          } else {
            this.setState({ approve_btn: this.state.get_data_value.call_log_verify ? true : false, reject_btn: this.state.get_data_value.call_log_verify ? false : true })
          }
        }
        if (((this.state.acc_owner_type == "owned") || (this.state.get_data_value.acc_owner_type == "owned"))) {

          if (((this.state.mismatch_reason == "franchise_r_distributor_name") || (this.state.get_data_value.mismatch_reason == "franchise_r_distributor_name"))) {
            this.setState({ approve_btn: (this.state.franchise_approved) ? true : false, reject_btn: this.state.mismatch_reason ? true : false })

          } else {
            this.setState({ approve_btn: this.state.mismatch_reason ? true : false, reject_btn: this.state.mismatch_reason ? true : false })

          }
        }

      }
      this.all_section_verify()

    }


  }
  all_section_verify() {
    var count = 0
    var verify_key = ["national_id_verify", "gps_address_verify", "biz_accounts_verify"]
    for (var i = 0; i < verify_key.length; i++) {

      if (this.state.get_data_value.hasOwnProperty(verify_key[i]) && (this.state.get_data_value[verify_key[i]])) {
        count = i + 1

      }
    }

    if (count == 3) {
      return <Navigate to={"/"} />


    }
  }

  reassign_modal_handleClose(e) {
    this.setState({ remarks: '', reassign_modal: false })
  }
  reassign_reason(type) {
    this.setState({ reassign_modal: true, modal_type: type })
  }

  spinner() {
    return (
      <div class="d-flex col-lg-10 py-2 px-5 pb-5 justify-content-center ml-5">
        <div class="spinner-border text-light" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    )
  }

  try_again_to_name_retrieve(id) {
    const { seconds } = this.state;
    const updatedSeconds = [...seconds];
    updatedSeconds[id] = 45;

    const { timer } = this.state;
    const updatedtimer = [...timer];
    updatedtimer[id] = 0;

    this.setState({ seconds: updatedSeconds, timer: updatedtimer }, () => this.retrieveNameHandler(id))

  }

  setAuditLeadState = (event) => {
    this.setChangeToState(event)
  }

  remarks_handler(value) {
    this.setState({ remarks: value })
  }



  remove_acc_holderproof() {
    // this.setState({time:[],seconds:[]})
    var req = { "action": "remove", "holder_name": this.state.get_data_value.holder_name, "account_holder_name_proof": this.state.get_data_value.account_holder_name_proof, "entity_id": this.state.get_data_value.entity_id, "entity": this.state.get_data_value.entity, "country_code": get('market').country_code }
    submit_holder_name_proof(this.req(req))
      .then((response) => {
        if (!response) { return "" }
        if (response.status == "success") {
          this.setState({ acc_holder_removed: true }, () => { this.get_data_handler() })

          // this.set_initial_timer(this.state.index-1)

        }
      })
  }


  update_lead_handler(value, key_name) {
    var loader_name = value == "owned" ? "owned_loader" : value == "rented/bought" ? "rented/bought_loader" : ""
    this.setState({ [loader_name]: true })
    var request = { "key": key_name, "value": value, "id": this.state.lead_id, "entity": "leads", "entity_id": this.state.lead_id, "country_code": get('market').country_code }
    update_lead(this.req(request))
      .then((response) => {
        if (!response) { return }
        if (response.status == "success") {
          this.get_data_handler(null, null, null)
        }
      })
  }


  multiple_acc_pur_tostring(review_reason) {

    if (review_reason) {

      for (var i = 0; i < review_reason.length; i++) {
        return (
          <span style={{ paddingRight: 3 }} key={i}>{dd_value(review_reason[i])},</span>
        )
      }
    } else {
      return ""
    }
  }

  startTimer = (id) => {
    if (this.state.timer[id] == 0 && this.state.seconds[id] > 0) {

      this.state.timer[id] = setInterval(() => this.countDown(id), 1000);
    }
  }

  countDown(id) {


    var time = this.state.time,
      time = { ...time }
    time[id] = this.secondsToTime(this.state.seconds[id])
    let seconds = this.state.seconds
    if (seconds[id] % 5 == 0 || seconds[id] == 1) {
      if (!this.state.get_data_value.holder_name) {
        this.get_data_handler()

      }
    }
    seconds[id] = seconds[id] - 1;
    this.setState({
      time: time,
      seconds: seconds,
    });
    if (seconds[id] == 0) {
      clearInterval(this.state.timer[id]);
    }
  }

  retrieveNameHandler(id) {

    this.setState({ retrieve_button_status: false, ussd_code: this.get_ussd_code() })
    this.startTimer(id)
    var request = {
      "acc_number": this.state.get_data_value.account_num,
      "category": "self_reg_retry",
      "acc_prvdr_code": this.state.get_data_value.acc_prvdr_code,
      "lead_id": this.state.get_data_value.entity_id,
      "entity": "leads",
      "country_code": get('market').country_code,
    }

    retrieve_name(this.req(request))
      .then((response) => {
        if (!response) { return }
        if (response.status == "success") {


        }
      })
  }

  secondsToTime(secs) {

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {

      "m": minutes,
      "s": seconds
    };
    return obj;
  }

  verificationModal = (index_val, data) => {
    this.set_initial_timer(index_val - 1)

    this.setState({ index: index_val, acc_owner_type: data.entity_json.acc_owner_type }, () => { this.get_data_handler(index_val, data) })
    // setacc_owner_type(row_data[index_val-1].entity_json.acc_owner_type)


  }

  get_ussd_code(data) {
    var ussd_code;
    var holder_name_retrieval_ussd_code = get('app_config').holder_name_retrieval_ussd_codes[this.state.get_data_value.acc_prvdr_code]
    if (holder_name_retrieval_ussd_code) {
      ussd_code = holder_name_retrieval_ussd_code.replace(":recipient", this.state.get_data_value.account_num)
    } else {
      ussd_code = "-"
    }

    return ussd_code;
  }

  set_initial_timer(id) {
    const { seconds } = this.state;
    const updatedSeconds = [...seconds];
    updatedSeconds[id] = 45;

    const { timer } = this.state;
    const updatedtimer = [...timer];
    updatedtimer[id] = 0;

    this.setState({ seconds: updatedSeconds, timer: updatedtimer })

  }


  modal_handleClose() {
    this.setState({ modal_status: false, edit_status: false, retrieve_button_status: true, call_log_text_status: false, name_match_reason: "", get_data_value: [], time: [], seconds: [], acc_owner_type: '', reassign_modal: false, mismatch_reason: "", remarks: '' })
  }

  handleToggle(e) {
    this.setState({ show_table: !this.state.show_table })
  }

  updateLeadJson(value, key_name) {
    this.update_lead_handler(value, key_name)
    this.get_data_handler()

  }

  handleModalClose(type){
    this.setState({pdfMOdal: type=="open" ? true :false})
  }

  Pdf_modal(props) {


    return (
      <>

        <div  className="w-100 h-100 p-0  " style={{position:'relative'}}  >
          {props.pdf_view_error ?                     
            <object id="pdf-object" type='application/pdf' className='bg-secondary' data={`${props.root}${props.modal_title}?#toolbar=0&navpanes=0&page=1`}  width={'100%'} height={'100%'} allowtransparency='true' onLoad={()=>props.pdf_viewer(`${props.root}${props.modal_title}`)}>  
            {/* {props.device&&<a href={`${props.root}${props.modal_title}`} className='btn btn-primary w-100 d-block'>View Pdf </a>} */}
            {props.device&& <PDFtoImage pdfUrl={`${props.root}${props.modal_title}`}/>}
            <p className='text-center'>NO DATA</p>
           </object> 
           :
            <div class="d-flex align-items-center">
            <p className='text-center float_acc_balance_view_bttn' >NO DATA</p>

          </div> }
           
        </div>
        <div className={`w-100 h-100 p-0 ${props.device?"":'position_ab'}`} style={{top:0}}  ></div>
      
        <Modal show={props.pdfMOdal} onHide={()=>props.handleModalClose("close")} size = 'lg' >
                   <div className="row d-flex justify-content-center align-items-center ml-0">
                   <h5 className="modal-title text-center mb-0 mt-5 " id="">{props.title}</h5>

                  </div>
                  <Modal.Body >
                  <div className="modal-body py-0" >
              <object type='application/pdf' className='forpdf'  data={`${props.root}${props.modal_title}?#toolbar=0&navpanes=0&page=1`} width={'100%'} height={'485vh'} allowtransparency='true'>
              {props.device&& <PDFtoImage pdfUrl={`${props.root}${props.modal_title}`}/>}
              </object>
              </div>
              <div className="modal-footer d-flex">
              <a href={`${props.root}${props.modal_title}`} className='btn btn-success btn-sm my-0 mx-3' target="_blank">Download Pdf</a>
                <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal" onClick={()=>props.handleModalClose("close")}>Close</button>
                
              </div>
                  </Modal.Body>
          </Modal>
      </>
    );
  }

  pdf_viewer(link) {

    const url = link;
    fetch(url, { method: 'HEAD' })
      .then(response => {
        const contentType = response.headers.get('Content-Type');

        if (contentType === 'application/pdf') {
          this.setState({ pdf_view_error: true })
          console.log('The URL points to a PDF file.');
        } else {
          this.setState({ pdf_view_error: false })

          console.log('The URL does not point to a PDF file.');
        }
      })
      .catch(error => {
        console.log('An error occurred:', error);
        this.setState({ pdf_view_error: false })

      });
  }


  // Pdf_modal(props) {


  //   return (
  //     <>

  //       <div className="w-100 h-100 p-0  " style={{ position: 'relative' }} data-toggle={`${props.device ? "" : 'modal'}`} data-target=".bd-example-modal-lg">
  //         {
  //         // props.pdf_view_error ?
  //         //   <object id="pdf-object" type='application/pdf' className='bg-secondary' data={`${props.root}${props.modal_title}?#toolbar=0&navpanes=0&page=1`} width={'100%'} height={'1200px'} allowtransparency='true' onLoad={() => props.pdf_viewer(`${props.root}${props.modal_title}`)}>
  //         //     {/* {props.device&&<a href={`${props.root}${props.modal_title}`} className='btn btn-primary w-100 d-block'>View Pdf </a>} */}
  //         //     {props.device && <PDFtoImage pdfUrl={`${props.root}${props.modal_title}`} />}

  //         //   </object>
  //         //   :
  //         //   <p className='text-center'>NO DATA</p>
  //         }

  //           <object id="pdf-object" type='application/pdf' className='bg-secondary' data={`${props.root}${props.modal_title}?#toolbar=0&navpanes=0&page=1`} width={'100%'} height={'1200px'} allowtransparency='true' onLoad={() => props.pdf_viewer(`${props.root}${props.modal_title}`)}>
  //             {/* {props.device&&<a href={`${props.root}${props.modal_title}`} className='btn btn-primary w-100 d-block'>View Pdf </a>} */}
  //             {props.device && <PDFtoImage pdfUrl={`${props.root}${props.modal_title}`} />}

  //           </object>

  //       </div>
  //       <div className={`w-100 h-100 p-0 ${props.device ? "" : 'position_ab'}`} style={{ top: 0 }} data-toggle={`${props.device ? "" : 'modal'}`} data-target=".bd-example-modal-lg"></div>
  //       <div className="modal fade bd-example-modal-lg mt-0" tabIndex="-1" role="dialog" aria-l abelledby="myLargeModalLabel" aria-hidden="true">
  //         <div className="modal-dialog modal-lg d-flex justify-content-center">
  //           <div className="modal-content" style={{ width: '60vw' }}>
  //             <div className="modal-header">
  //               <h5 className="modal-title" id="exampleModalLabel">{props.title}</h5>
  //               <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  //                 <span aria-hidden="true">&times;</span>
  //               </button>
  //             </div>
  //             <div className="modal-body" style={{ width: '60vw' }}>
  //               <object type='application/pdf' className='forpdf' data={`${props.root}${props.modal_title}?#toolbar=0&navpanes=0&page=1`} width={'100%'} height={'485vh'} allowtransparency='true'>
  //                 {props.device && <PDFtoImage pdfUrl={`${props.root}${props.modal_title}`} />}
  //               </object>
  //             </div>
  //             <div className="modal-footer d-flex">
  //               <a href={`${props.root}${props.modal_title}`} className='btn btn-success btn-sm my-0 mx-3' target="_blank">Download Pdf</a>
  //               <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">Close</button>

  //             </div>
  //           </div>
  //         </div>
  //       </div>

  //     </>
  //   );
  // }

  submitHandler(type) {

    var remarks = (type == "reject" || type == "reassign") ? "remarks" : null
    var request = { "section": this.state.active_key + `_verify`, "entity_id": this.state.get_data_value.entity_id, [remarks]: this.state.remarks, "entity": "leads", "country_code": get('market').country_code, "time_zone": get('market').time_zone }
    submit_handler(request, type)
      .then((response) => {
        if (!response) { return }
        if (response.status == "success") {

          if (type != "verify") {
            this.modal_handleClose()
            window.location.replace("/")

          } else {
            this.setState({ modal_status: (type == "verify") ? true : false, national_id_verify: (this.state.active_key == "national_id") ? true : false },()=>{this.previousAndNextButtonHandler((this.state.active_key=="biz_accounts") ? null:"next")})
          }

          this.get_data_handler(null, null, null, type)
          alert(response.message);
        }
      })
  }
  edit_handler(val, type) {
    this.setState({ [type]: val })
  }

  call_log_verify_handler(value, key) {
    this.setState({ verified_call_log: true })
    this.update_lead_handler(value, key)

  }

  Ismobile = () => {
    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(details);
    if (isMobileDevice) {
      return true
    }
    else {
      return false
    }
  }
  name_mismatch_reason = (value, name = null, loader_status = null) => {

    this.setState({ [name]: value, name_mismatch_loader: loader_status != null ? true : false }, () => { this.update_lead_handler(value, name) })
  }

  previousAndNextButtonHandler(button_type=null) {
    if(button_type!=null){

    var index = this.state.active_index
    if (button_type == "previous") {
      index = index - 1
    } else {
      index = index + 1

    }
    this.setState({ active_index: index }, () => this.change_active_menu(index))
  }
  }

  change_active_menu(active_index=null) {
    if(active_index!=null){

      var audit_kyc_wizard_menus = this.state.audit_kyc_wizard_menus
      var active_key = this.state.active_key
      Object.entries(this.state.audit_kyc_wizard_menus).forEach(([key, value], index) => {
  
        if (active_index == index) {
          audit_kyc_wizard_menus[key] = true
          active_key = key
          window.scrollTo(0, 0);

        } else {
          audit_kyc_wizard_menus[key] = false
  
        }
  
      });
      this.setState({ audit_kyc_wizard_menus, active_key })
  

    }
  


  }



  render() {
    return (
      <div>
        {this.state.reassign_modal &&
          <Modal show={this.state.reassign_modal} onHide={(e) => this.reassign_modal_handleClose(e)} backdrop="static"
            keyboard={false} >
            <Modal.Header closeButton>
              <Modal.Title>Reason</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row d-flex align-items-center m-0">
                <div className="col-4">
                  <p className="m-0 text-white"> Remarks</p>
                </div>
                <div className="col-8">
                  <textarea type="text" id="remarks" className="form-control" onChange={(e) => { this.remarks_handler(e.target.value) }} maxLength='256' />

                </div>
              </div>

              <div className="my-4">
                {this.state.modal_type == "reject" &&
                  <div className="d-flex justify-content-center"><button type="button" class=" ml-3 btn btn-danger btn-lg" disabled={this.state.remarks ? false : true} onClick={() => this.submitHandler("reject")}>Reject</button></div>
                }

                {this.state.modal_type == "reassign" &&
                  <div className="d-flex justify-content-center"><button type="button" class=" ml-3 btn btn-danger btn-lg" disabled={this.state.remarks ? false : true} onClick={() => this.submitHandler("reassign")}>Reassign</button></div>
                }

              </div>
            </Modal.Body>

          </Modal>
        }
        <h2 className="text-white text-center"> <b>Self Registration</b></h2>

        {this.state.wizard_menu.length > 0 && this.state.get_data_value &&
          <div className=' d-flex justify-content-center   align-items-center   col-md-12  my-5  audit_kyc_wizard_menus' >
            {this.state.wizard_menu.map((item, idx) => {
              return (
                <div key={idx} id={idx + 1}>
                  <div >
                    <div className={`d-flex align-items-center pointer`} style={{ position: 'relative' }} onClick={() => this.active_menu(item.key,idx)}>
                      <div style={{ position: 'relative' }} className={`${this.state.audit_kyc_wizard_menus[item.key] ? `active_verification_wizard` : 'audit_kyc_verification_wizard'}  ${((this.state.get_data_value.hasOwnProperty(item.verify_key)) && (this.state.get_data_value[item.verify_key])) ? "section_verified " : "audit_kyc_verification_wizard"} `}>

                        <p className={`no-margin text-white audit_kyc_icon_font`}> {item.icon}</p>
                      </div>
                      {idx < 2 &&
                        <div className={` ${this.state.get_data_value[item.verify_key] ? "verified_wizard" : "audit_kyc_wizard_right_border"}`} style={{ width: '10vw' }}>
                        </div>
                      }


                    </div>

                    <p className={`text-white no-margin mt-2 audit_kyc_title`}> {item.title}</p>
                  </div>

                </div>
              )
            })}
          </div>

        }

        <div className=' col-lg-12 py-3 mt-5 ' style={{ background: ' #01062c' }}>
          <h2 className='text-white text-center'>
            <b> {this.state.active_key == "gps_address" ? "GPS Address & Shop Photo" : this.state.active_key == "national_id" ? "National ID":   this.state.active_key == "biz_accounts" ? "Biz Account" :dd_value(this.state.active_key, this.state.active_key)}</b>
          </h2>

        </div>

        {this.state.audit_kyc_wizard_menus.national_id &&
          <div className="row mx-0 mt-5 mb-3 justify-content-center"  >
            {this.state.get_data_value[this.state.active_key + `_verify`] &&
              <div class="closedStamp ">
                <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
              </div>
            }
            <div className="col-lg-10 py-5" style={{ background: '#223b56' }} >


              <div className="row d-flex py-3 px-3 m-0 justify-content-center" >
                <div className="col-6 ">
                  {this.state.responseData &&
                    <DisplayImage image_path={get_dir_path(this.state.get_data_value.photo_national_id_full_path, this.state.get_data_value.photo_national_id)} style={{ height: '520px', width: '100%' }} file_name={this.state.get_data_value.photo_national_id} saved_file_name={"photo_national_id"} />
                  }
                </div>
                <div className="col-6 " style={{ border: '1px solid gray' }}>

                  {this.national_id_details.map((item, idx) => {
                    return (

                      <div className={`my-4 mx-4 ${this.state.get_data_value[this.state.active_key + "_verify"] ? "disable_div" : ""}`} key={idx} >
                        <p className="m-0 text-white" style={{ fontSize: '16px' }}> {item == "dob" ? "DOB" : item == "national_id" ? "National ID" : capitalize_first_letter(dd_value(item))}</p>
                        {(this.state.get_data_value && this.state.get_data_value[item]) ?
                          <>
                            {item == "dob" ?
                              <>
                                {this.state.gender_edit ?
                                  <DatePicker id="date" closeOnSelect={true}

                                    isValidDate={(current) => {
                                      const maxDate = moment('2023-01-01').subtract(18, 'years').endOf('year');
                                      return current.isBefore(maxDate, 'day');
                                    }}

                                    dateFormat="DD MMM YYYY" timeFormat={false} required="required" onChange={(value) => { this.name_mismatch_reason(moment(value, 'YYYY-MM-DD').format("YYYY-MM-DD"), "dob") }} />
                                  :
                                  <div className="row " >
                                    <p className="m-0 pt-2 text-white col-8" style={{ fontSize: '16px' }}> <b>{moment(this.state.get_data_value[item]).format("DD MMM YYYY")} </b> <MdEdit onClick={() => { this.edit_handler(true, "gender_edit") }} className="col-4 pointer" size={15} color="white" /></p>
                                  </div>
                                }
                              </>
                              :
                              <>
                                {item == "gender" ?

                                  <>{this.state.dob_edit ?
                                    <div className="d-flex align-items-center mt-3 row">
                                      <div className="d-flex align-items-center  col-4">
                                        <input type="radio" className="pointer" id="male" name="male" value="male" onChange={(e) => { this.name_mismatch_reason(e.target.value, "gender") }} style={{ height: '18px', width: '21px' }} />
                                        <label className="m-0 pointer" for="male" style={{ fontSize: '15px' }}>Male</label>
                                      </div>
                                      <div className="d-flex align-items-center col-4  ml-2">
                                        <input type="radio" className="pointer" id="female" name="female" value="female" onChange={(e) => { this.name_mismatch_reason(e.target.value, "gender") }} style={{ height: '18px', width: '21px' }} />
                                        <label className="m-0 pointer" for="female" style={{ fontSize: '15px' }}> Female</label>
                                      </div>
                                    </div>
                                    :
                                    <div className="row d-flex pt-2 align-items-center" >
                                      <p className="m-0  col-3 text-white" style={{ fontSize: '16px' }}> <b>{(this.state.get_data_value && this.state.get_data_value[item]) ? capitalize_first_letter(this.state.get_data_value[item]) : "-"}</b> </p>
                                      <MdEdit onClick={() => { this.edit_handler(true, "dob_edit") }} className="col-4 pl-4 pointer" size={15} color="white" />
                                    </div>

                                  }
                                  </>
                                  :
                                  <p className="m-0 pt-2 text-white" style={{ fontSize: '16px' }}> <b>{(this.state.get_data_value && this.state.get_data_value[item]) ? capitalize_first_letter(this.state.get_data_value[item]) : "-"}</b> </p>
                                }
                              </>
                            }
                          </>
                          :
                          <>
                            {item == "gender" &&
                              <>
                                <div className="d-flex align-items-center mt-3 row">
                                  <div className="d-flex align-items-center  col-4">
                                    <input type="radio" id="male" className="pointer" name="male" value="male" onChange={(e) => { this.name_mismatch_reason(e.target.value, "gender") }} style={{ height: '18px', width: '21px' }} />
                                    <label className="m-0 pointer" for="male" style={{ fontSize: '15px' }}>Male</label>
                                  </div>
                                  <div className="d-flex align-items-center col-4  ml-2">
                                    <input type="radio" className="pointer" id="female" name="female" value="female" onChange={(e) => { this.name_mismatch_reason(e.target.value, "gender") }} style={{ height: '18px', width: '21px' }} />
                                    <label className="m-0 pointer" for="female" style={{ fontSize: '15px' }}> Female</label>
                                  </div>
                                </div>
                              </>
                            }
                            {item == "dob" &&
                              <DatePicker id="date" closeOnSelect={true}
                                isValidDate={(current) => {
                                  const maxDate = moment('2023-01-01').subtract(18, 'years').endOf('year'); // Last day of the year 18 years ago from 2023
                                  return current.isBefore(maxDate, 'day'); // Allow dates strictly before maxDate

                                }}
                                dateFormat="DD MMM YYYY" timeFormat={false} required="required" onChange={(value) => { this.name_mismatch_reason(moment(value, 'YYYY-MM-DD').format("YYYY-MM-DD"), "dob") }} />
                            }
                            {item != "dob" & item != "gender" &&
                              <input type="text" className=" col-12 py-2 form-control" style={{ fontSize: '16px' }} autoComplete="off" onChange={(e) => { this.get_national_id_datas(e.target.value, item) }} />
                            }
                          </>
                        }
                      </div>

                    )
                  })}

                </div>

              </div>

              <div >

                <div className='col-lg-12 p-3 d-flex my-3' style={{ background: '#fe9f9f' }}>
                  <p className='no-margin pl-3' style={{ color: 'black', fontSize: '14px' }}> <b className='pr-2' style={{ color: 'red' }}>NOTE : </b> The name entered by the customer manually is used in the customer agreement. So, verify if the name that you see on the right side matches with the Photo ID proof on the left side.</p>
                </div>

                {this.state.loader ? this.spinner() :
                  <div className={(this.state.get_data_value.national_id_verify || (this.state.get_data_value.status == " 55_self_reg_inprogress")) ? 'disable_div' : ""}>
                    <div className='d-flex mx-3  align-items-center'>
                      <input type="checkbox" id="vehicle4" className='pointer' style={{ height: '19px', width: '20px' }} name="vehicle4" value="Bike" checked={this.state.get_data_value && this.state.get_data_value['matches_with_customer'] ? this.state.get_data_value['matches_with_customer'] : false} onChange={(e) => this.update_lead_handler(e.target.checked, "matches_with_customer")} />
                      <label className='no-margin pl-3 audit_kyc_text pointer' for="vehicle4">The details entered by the customer matches with the National ID.</label><br /><br />
                    </div>


                  </div>
                }

              </div>

              {this.state.get_data_value &&
                <div className={`col-12 d-flex align-items-center my-3 mt-3 justify-content-center ${((this.state.get_data_value.status == " 55_self_reg_inprogress") || (this.state.get_data_value[this.state.active_key + "_verify"])) ? "disable_div" : ""} `}>
                  <div><button type="button" class="btn btn-success btn-lg" disabled={(this.state.get_data_value && this.state.get_data_value['matches_with_customer'] && this.state.get_data_value["dob"] && this.state.get_data_value["gender"]) ? false : true} onClick={() => this.submitHandler("verify")}>Verify</button></div>
                  <div><button type="button" class=" ml-3 btn btn-danger btn-lg" disabled={(this.state.get_data_value && this.state.get_data_value['matches_with_customer']) ? true : false} onClick={() => this.reassign_reason("reject")}>Reject</button></div>
                  <div><button type="button" class=" ml-3 btn btn-danger btn-lg" disabled={(this.state.get_data_value && this.state.get_data_value['matches_with_customer']) ? true : false} onClick={() => this.reassign_reason("reassign")}>Reassign</button></div>

                </div>
              }


            </div>
          </div>
        }

        {this.state.audit_kyc_wizard_menus.gps_address &&
          <div className="row my-5 justify-content-center">
            {this.state.get_data_value[this.state.active_key + `_verify`] &&
              <div class="closedStamp ">
                <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
              </div>
            }
            <div className="col-lg-10 col-md-12" style={{ background: '#223b56' }} >
              <div className="row m-0 d-flex py-5 align-items-center justify-content-center">
                <div className="col-lg-6 col-md-12 h-100">
                  <h2 className="text-white text-center">Shop Photo</h2>
                  <div style={{height:`${get('market').country_code == "UGA" ? '633px' : '556px'} `}}>
                    {this.state.get_data_value &&
                      <DisplayImage image_path={get_dir_path(this.state.get_data_value.photo_shop_full_path, this.state.get_data_value.photo_shop)} style={{ height: '100%', width: '100%' }} file_name={this.state.get_data_value.photo_shop} saved_file_name={"photo_shop"} />
                    }
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 h-100">
                  <h2 className="text-white text-center">GPS & Address</h2>
                  <div>
                    {this.state.get_data_value &&
                      <GoogleMapSetup coordinates={this.state.get_data_value.gps} height={get('market').country_code == "UGA" ? '633px' : '556px'} />
                    }
                  </div>
                </div>
              </div>
              <div className={`d-flex justify-content-center mb-5 ${(this.state.get_data_value.status == "55_self_reg_inprogress") ? "disable_div" : ""} `}>
                {this.state.get_data_value &&
                  <div className={`col-12 d-flex align-items-center my-3 mt-3 justify-content-center  ${(this.state.get_data_value.gps_address_verify || (this.state.get_data_value.status == " 55_self_reg_inprogress")) ? 'disable_div' : ""}`}>
                    <div><button type="button" class="btn btn-success btn-lg" onClick={() => this.submitHandler("verify")}>Verify</button></div>
                    <div><button type="button" class=" ml-3 btn btn-danger btn-lg" onClick={() => this.reassign_reason("reject")}>Reject</button></div>
                    <div><button type="button" class=" ml-3 btn btn-danger btn-lg" onClick={() => this.reassign_reason("reassign")}>Reassign</button></div>

                  </div>
                }

              </div>

            </div>
          </div>
        }

        {this.state.audit_kyc_wizard_menus.biz_accounts &&
          <>
            <div className="row my-5 justify-content-center">
              {this.state.get_data_value[this.state.active_key + `_verify`] &&
                <div class="closedStamp ">
                  <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
                </div>
              }
              <div className="col-lg-8 col-md-12" style={{ border: '1px solid gray' }} >

                {(this.state.get_data_value) &&
                  <div>
                    <div className='row m-0 pt-4'>
                      <div className='col-8'>
                        <p className='m-0 pb-2 text-white'>CUSTOMER'S NAME</p>

                        <p style={{ fontSize: '20px', color: `${(this.state.get_data_value && ((this.state.get_data_value.acc_owner_type == "owned") && (this.state.get_data_value.name_match))) ? "green" : "white"}` }}><b>{this.state.get_data_value.first_name} {this.state.get_data_value.last_name}</b> </p>

                      </div>
                      <div className='col-4'>
                        <div className='d-flex justify-content-end'>
                          <img className="img-fluid" style={{ width: '65px', height: '32px' }} src={`/img/${this.state.get_data_value.acc_prvdr_code}_logo_color.png`}></img>
                        </div>
                        {this.state.get_data_value &&
                          <div className='d-flex justify-content-end'>
                            <p className='m-0 text-white'>{this.state.get_data_value.account_num}</p>
                          </div>
                        }
                      </div>

                      <div className='col-12 mt-3'>
                        <p className='m-0 pb-2 text-white'>CUSTOMER'S MOBILE NUMBER</p>

                        <p className="text-white" style={{ fontSize: '20px' }}><b>{this.state.get_data_value.mobile_num} </b> </p>

                      </div>

                      <div className='col-12 mt-4'>
                        <p className='m-0 text-white'>ACCOUNT OWNERSHIP</p>
                        <div className={`d-flex row mt-3 justify-content-center align-items-center ${(((this.state.mismatch_reason != "entirely_different_name") || (this.state.get_data_value.mismatch_reason != "entirely_different_name")) && (this.state.get_data_value.hasOwnProperty("review_reason") ? (this.state.get_data_value.review_reason[0] == "name_not_confirmed") : true) && ((this.state.acc_owner_type == "owned") || (this.state.get_data_value.acc_owner_type == "owned"))) ? "disable_div" : ""}  ${(this.state.get_data_value[this.state.active_key + "_verify"] || ((this.state.get_data_value.status == "55_self_reg_inprogress"))) ? "disable_div" : ""}`}>
                          <div className={`col-3 px-2 mr-4 py-3 pointer ${(((this.state.acc_owner_type ? this.state.acc_owner_type : this.state.get_data_value.acc_owner_type) == "owned")) ? 'self_registration_acc_ownertype' : ''}`} onClick={() => { this.name_mismatch_reason("owned", "acc_owner_type") }} style={{ border: '1px solid white', borderRadius: '20px' }}>
                            <p className='m-0 text-center d-flex align-items-center justify-content-center text-white'>  Owned</p>
                          </div>
                          <div className={`col-3 ml-5 px-2 py-3 pointer ${(((this.state.acc_owner_type ? this.state.acc_owner_type : this.state.get_data_value.acc_owner_type) == "rented/bought")) ? 'self_registration_acc_ownertype' : ''}`} onClick={() => { this.name_mismatch_reason("rented/bought", "acc_owner_type") }} style={{ border: '1px solid white', borderRadius: '20px' }}>
                            <p className='m-0 text-center  d-flex align-items-center justify-content-center text-white'> Rented/Bought</p>
                          </div>

                        </div>
                      </div>

                      {
                        <div className={`col-12 mt-5 ${(this.state.get_data_value.status == "55_self_reg_inprogress") ? "disable_div" : ""}`}>

                          {(this.state.get_data_value && this.state.get_data_value.hasOwnProperty("holder_name")) ?

                            <div className="py-3 px-3 pb-5" >
                              <p className='m-0 text-white pb-3'>ACCOUNT HOLDER NAME</p>

                              {this.state.loader ? this.spinner() : <>

                                <p className="m-0" style={{ color: `${this.state.get_data_value.name_match ? "green" : "white"}`, fontSize: '20px' }}> <b>{this.state.get_data_value.holder_name}</b></p>

                                {(this.state.get_data_value.hasOwnProperty('holder_name_proof')) &&
                                  <div className="d-flex align-items-center row" >
                                    <div className='ml-2 my-2 pointer col-2'>
                                      <DisplayImage image_path={get_dir_path(this.state.get_data_value.holder_name_proof_path, this.state.get_data_value.holder_name_proof)} style={{ height: '50px', width: '90px' }} file_name={this.state.get_data_value.holder_name_proof} />
                                    </div>
                                    {(this.state.get_data_value.name_match == false) &&
                                      <div className="col-1 p-0 pointer" onClick={() => { this.remove_acc_holderproof() }}>
                                        <BsTrash color="red" size="25" />
                                      </div>
                                    }
                                  </div>
                                }

                                {(((this.state.get_data_value.acc_owner_type == "rented/bought")) && (this.state.get_data_value.name_match)) &&
                                  <>
                                    <div className='col-lg-12 p-3 d-flex my-3' style={{ background: '#fe9f9f' }}>
                                      <p className='no-margin pl-3' style={{ color: 'black', fontSize: '14px' }}> <b className='pr-2' style={{ color: 'red' }}>Note</b>  The customer’s name and the account owner name are the same.Its indicated that the account is “Rented/Bought”. Please call the customer and verify the ownership of the account.</p>
                                    </div>
                                    <div className="col-12">
                                      {this.call_log_verification("name_match_true")}

                                    </div>
                                  </>
                                }



                                {(this.state.get_data_value.name_match == false) &&
                                  <div className='col-12 p-0 mt-2 mx-0 row d-flex align-items-center'>
                                    {(((this.state.get_data_value.acc_owner_type == "owned" || this.state.get_data_value.acc_owner_type == "rented/bought")) && (this.state.get_data_value.name_match == false)) &&

                                      <div className="col-12 p-0">
                                        {((this.state.mismatch_reason == "entirely_different_name")) ?
                                          <div className='col-lg-12 p-3 my-3' style={{ background: '#fe9f9f' }}>
                                            <p className='no-margin pl-3' style={{ color: 'black', fontSize: '14px' }}> <b className='pr-2' style={{ color: 'red' }}>Note</b>  The customer’s name and the account owner name are entirely different. Its indicated that the account is owned. Please call the customer and verify the ownership of the account. </p>
                                          </div> :
                                          <>
                                            {this.state.mismatch_reason != "franchise_r_distributor_name" && (this.state.get_data_value.acc_owner_type == "owned") &&
                                              <div className='col-lg-12 p-3 d-flex my-3' style={{ background: '#fe9f9f' }}>
                                                <p className='no-margin pl-3' style={{ color: 'black', fontSize: '14px' }}> <b className='pr-2' style={{ color: 'red' }}>Note</b>    The name of the customer doesn’t match with the customer name. </p>
                                              </div>}
                                          </>
                                        }
                                      </div>
                                    }

                                    {(this.state.get_data_value.name_match == false) && (this.state.get_data_value.acc_owner_type == "owned") &&
                                      <div className="col-12 p-0 row d-flex align-items-center">
                                        <div className='col-4'> <p className='text-white m-0'> NAME MISMATCH REASON</p></div>
                                        <div className={`col-8 ${(this.state.get_data_value[this.state.active_key + "_verify"] || ((this.state.get_data_value.status == "55_self_reg_inprogress"))) ? "disable_div" : ""}`}>
                                          <select id="holder_name_mismatch_reason text-white" className="form-control col-md-12 col-lg-12" value={(this.state.mismatch_reason) ? (this.state.mismatch_reason) : " "} type="text" required="required" onChange={(e) => this.name_mismatch_reason(e.target.value, "mismatch_reason", "loader_false")} >
                                            {this.getSelectOptions("self_reg_name_mismatch_reason")}
                                          </select>
                                        </div>
                                      </div>
                                    }
                                    {(this.state.mismatch_reason == "franchise_r_distributor_name") && (this.state.get_data_value.acc_owner_type == "owned") &&
                                      <div className="col-12 p-0 mt-3">
                                        <div className='col-lg-12 p-3 my-3' style={{ background: '#fe9f9f' }}>
                                          <p className='no-margin pl-3' style={{ color: 'black', fontSize: '14px' }}> Here upload the Photo of mobile screen showing that the account number <b>{this.state.get_data_value.account_num}</b> is registered in the name <b>{this.state.get_data_value.first_name} {this.state.get_data_value.last_name}</b>  </p>

                                          {/* <p className='no-margin pl-3' style={{color:'black',fontSize:'14px'}}> <b className='pr-2' style={{color:'red'}}>Note</b>                    If the verfication sms is shown on to mobile screen views please merge them into a single image using any photo editor software and upload. </p> */}
                                        </div>
                                        <FileUploadComponent entity={"leads"} entity_id={this.state.get_data_value.entity_id} lead_id={this.state.get_data_value.entity_id} name_mismatch_reason={(a, b) => this.name_mismatch_reason(a, b)} call_log_verification={() => { this.call_log_verification() }} data={this.state} get_data_handler={(a, b, c, d) => { this.get_data_handler(a, b, c, d) }} id={"lead"} />




                                      </div>

                                    }



                                    {
                                      <div className="col-12">
                                        {(this.state.get_data_value.name_match == false) ?

                                          ((((this.state.mismatch_reason == "entirely_different_name")))) ?
                                            <>{this.call_log_verification("entirely_different_name")}</>
                                            :
                                            <>
                                              {(((this.state.get_data_value.acc_owner_type == "rented/bought"))) &&

                                                <>
                                                  {this.call_log_verification("name_match_false")}
                                                </>
                                              }
                                            </>

                                          : ""}

                                      </div>

                                    }







                                  </div>
                                }

                              </>}

                            </div>

                            :
                            <>
                              {(get('app_config').holder_name_retrieval_ussd_codes[this.state.get_data_value.acc_prvdr_code]) ?
                                <>

                                  {(this.state.get_data_value && this.state.seconds[this.state.index - 1] >= 0 && this.state.retrieve_button_status) ?
                                    <div className="mb-5">

                                      <button type="button" class="btn btn-primary btn-lg mt-2" onClick={() => { this.retrieveNameHandler(this.state.index - 1) }}>Retrieve Name</button>

                                    </div>
                                    :
                                    <div className="py-3 px-3" >
                                      <p className='m-0 text-white pb-3'>ACCOUNT HOLDER NAME</p>

                                      {this.state.seconds[this.state.index - 1] == 0 ?
                                        <>
                                          <div >
                                            <button type="button" class="btn btn-primary btn-lg mt-2" onClick={() => { this.try_again_to_name_retrieve(this.state.index - 1) }}>Retrieve Name</button>
                                          </div>
                                          {this.state.get_data_value &&
                                            <div className='col-lg-12 p-3 mt-3' style={{ background: '#fe9f9f' }}>
                                              <p className='no-margin pl-3' style={{ color: 'black', fontSize: '14px' }}> <b style={{ color: 'red' }}>NOTE:</b>  Unable to retrieve the name </p>
                                              <p className='no-margin pl-3' style={{ color: 'black', fontSize: '14px' }}>  1. Try again to retrieve the name by using the <b>Retrieve Name</b> Button.</p>
                                              <p className='text-center m-0'><b className='text-center' style={{ color: 'black' }}>(OR)</b></p>
                                              <p className='no-margin pl-3' style={{ color: 'black', fontSize: '14px' }}>  2.Please retrieve the name manually using USSD code (<b>{this.state.ussd_code ? this.state.ussd_code : "-"}</b>). Once retrieved on your phone using the above USSD, take a screenshot and upload here. </p>
                                            </div>
                                          }
                                          <div className='col-12 p-0 my-3 mt-4'>
                                            <FileUploadComponent entity={this.state.get_data_value.entity} entity_id={this.state.get_data_value.entity_id} lead_id={this.state.get_data_value.entity_id} name_mismatch_reason={(a, b) => this.name_mismatch_reason(a, b)} call_log_verification={() => { this.call_log_verification() }} data={this.state} get_data_handler={(a, b, c, d) => { this.get_data_handler(a, b, c, d) }} id={"lead"} />

                                          </div>



                                        </>

                                        :
                                        this.spinner()
                                      }
                                    </div>
                                  }
                                </>
                                :
                                <div className="py-3 px-3" style={{ border: '1px solid gray' }}>
                                  <p className='m-0 text-white pb-3'>ACCOUNT HOLDER NAME</p>
                                  <>
                                    {this.state.get_data_value &&
                                      <div className='col-lg-12 p-3 mt-3' style={{ background: '#fe9f9f' }}>
                                        <p className='no-margin pl-3' style={{ color: 'black', fontSize: '14px' }}> <b style={{ color: 'red' }}>NOTE:</b>  Unable to retrieve the name </p>
                                      </div>
                                    }
                                    <div className='col-12 p-0 my-3 mt-4'>
                                      <FileUploadComponent entity={this.state.get_data_value.entity} entity_id={this.state.get_data_value.entity_id} lead_id={this.state.get_data_value.entity_id} name_mismatch_reason={(a, b) => this.name_mismatch_reason(a, b)} call_log_verification={() => { this.call_log_verification() }} data={this.state} get_data_handler={(a, b, c, d) => { this.get_data_handler(a, b, c, d) }} id={"lead"} />

                                    </div>

                                  </>

                                </div>

                              }
                            </>
                          }
                        </div>
                      }

                    </div>
                  </div>

                }

              </div>





            </div>
            <div className='row col-lg-12 py-3 mt-5  mx-0 justify-content-center' style={{ background: ' #01062c' }}>
              <h2 className='text-white text-center'>
                <b>Customer Service Usage Agreement</b>
              </h2>
            </div>

            <div className="d-flex align-items-center justify-content-center">
              <div className='col-sm-6 p-2'>
                <div className='m-2 text-light border h-100 w-100' >

                  {this.state.get_data_value &&
                  <div style={{height:'100%'}} onClick={()=>{!this.state.pdfMOdal ?  this.handleModalClose('open'):null}}>

                  
                    <this.Pdf_modal title={"Agreement PDF"} pdf_view_error={this.state.pdf_view_error} root={get_path("view_file")} device={this.Ismobile()} modal_title={this.state.get_data_value.signed_declaration_path} pdf_viewer={(a) => { this.pdf_viewer(a) }} pdfMOdal={this.state.pdfMOdal}  handleModalClose ={(type)=>{this.handleModalClose(type)}}/>
                    </div>}
                  {this.state.get_data_value &&
                    <div>
                      {this.state.verify &&
                        <div className="closedStamp ">
                          <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
                        </div>
                      }
                    </div>
                  }



                </div>
              </div>
            </div>

            {this.state.get_data_value &&
            <>
            {this.state.loader ?
            this.spinner()
            :
              <div className={`col-12 d-flex align-items-center my-3 mt-5 justify-content-center ${(this.state.get_data_value[this.state.active_key + `_verify`] || ((this.state.get_data_value.status == "55_self_reg_inprogress"))) ? "disable_div" : ""}`}>
                {this.state.get_data_value && this.state.get_data_value.holder_name && <div><button type="button" class="btn btn-success btn-lg" disabled={this.state.approve_btn ? false : true} onClick={() => this.submitHandler("approve")}>Approve </button></div>}
                <div><button type="button" class=" ml-3 btn btn-danger btn-lg" disabled={this.state.reject_btn ? false : true} onClick={() => this.reassign_reason("reject")}>Reject</button></div>
                <div><button type="button" class=" ml-3 btn btn-danger btn-lg" onClick={() => this.reassign_reason("reassign")}>Reassign</button></div>


              </div>
            }
            </>
            }
          </>

        }
        {this.state.audit_kyc_wizard_menus&&
          <div className='row d-flex justify-content-between mb-5 px-4' >

            <div className={`col  d-flex btn_holder_left  justify-content-left text-center mt-2 mb-5 mx-1 `} >

              <button className={`text-white no-margin  btn btn-secondary btn-sm ${this.state.active_index == 0 ? "disable_div" : ""}`} onClick={() => this.previousAndNextButtonHandler("previous")}>{
                <HiArrowCircleLeft style={{ marginRight: '10px' }} size={30} className='text-white  bottom-arrow-left' />}
                <b>PREVIOUS</b>
              </button>
            </div>

            {
              <div className={`col d-flex btn_holder_right justify-content-end text-center mt-2 mb-5 mx-1 `}>
                <button className={`text-white no-margin  btn btn-secondary btn-sm ${this.state.active_index == 2 ? "disable_div" : ""}`} onClick={() => this.previousAndNextButtonHandler("next")} >
                  <b>NEXT</b>
                  {<HiArrowCircleRight style={{ marginLeft: '10px' }} size={30} className='text-white arrow-right' />}</button>

              </div>
            }

          </div>
        }
      </div>
    );
  }
}
export default withRouter (SelfRegContainer)