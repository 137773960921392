import FlowComponent from "../common/component/core/FlowComponent";
import React from "react";
import CommonReactTable from '../../helpers/commonReact_table';
import Accordion from 'react-bootstrap/Accordion';
import { Button, Card, Modal } from "react-bootstrap";
import { BsChevronDown } from "react-icons/bs";
import {ListProofs} from '../../actions/loan_actions';
import { Homeacc_prvdrLogoselector, get_dir_path } from "../../helpers/common_helper";
import DisplayImage from "../common/component/DisplayImage";
import { Link } from "react-router-dom";
import { getcallstatus,updateCsCallStatus } from "../../actions/call_log_actions";
import { dd_value } from "../../actions/common_actions";
import { Config, get } from "../../helpers/storage_helper";
import { BasicReactTable } from "../../helpers/react_table";

class CSCallStatus extends FlowComponent{
    state={
        rowdata : [],
        columndata : [],
        show_table:false,
        modal_data:{}
    }

    componentWillMount(){

        setInterval(() => this.refreshList(), Config("cs_call_status"))
        this.drawTable()
      
        
    }

    refreshList = () => {		
		this.setState({refresh: true})
        this.drawTable()
	}

    handleToggle = (e) => {

        this.setState(state => state.show_table = !this.state.show_table);
    }

    updated_time(apiTime){
        let apiDateTime = new Date(apiTime);
        // const countryTime = new Date().toLocaleString('en-US', {timeZone: this.time_zone ,hour12: false});
        const market = get('market')
        const countryTime = new Date().toLocaleString('en-US', {timeZone: Config('country_time_zone_str')[market.country_code] ,hour12: false});

        const country= new Date(countryTime);

        const timeDifferenceInSeconds = Math.abs(country - apiDateTime) / 1000; 

        if (timeDifferenceInSeconds < 30) {// 30 sec
            return "just now";
        }else if(timeDifferenceInSeconds < 60) {
            return `${timeDifferenceInSeconds} secs ago` 
        } else if (timeDifferenceInSeconds < 3600) { //1 hour
            const minutes = Math.floor(timeDifferenceInSeconds / 60);
            return `${minutes} min${minutes >1 ? 's' : ''} ago`;
        } else if (timeDifferenceInSeconds < 86400) { // 1 day
            const minute = Math.floor((timeDifferenceInSeconds % 3600) / 60);
            const hours = Math.floor(timeDifferenceInSeconds / 3600);
            return `${hours} hr${hours > 1 ? 's' : ''} ${minute} min${minute > 1 ?'s':''} ago`;
        } else {
            const days = Math.floor(timeDifferenceInSeconds / 86400);
            return `${days} day${days > 1 ? 's' : ''} ago`;
        }
    }

    drawTable(){

        getcallstatus()
        .then((response) => {
            if(response){
                this.setState(state => state.rowdata = response.data)
                this.setState(state => state.columndata =  this.columndatas())
            }
        })

    }

    handlecallstatus = (e, row) => {

        updateCsCallStatus(this.req({ mob_num : row.csm_number, status : "available"}))
            .then((response) => {
                if (!response) { return }
                if (response.status === "success") {
                    this.refreshList()

                   
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.refreshList()
                }
            })    

    }

   callStatus(val){

    if(val=='dialed_xml' || val=='Dialing'){
        return 'Dialing'
    }else if(val=='Bridged'){
        return 'Connected';
    }else{
        return val
    }
   } 

    columndatas(){
        let column
        column = [
            {
                Header : "Cust ID",
                id: 'cust_id',
                minWidth:100,
                accessor: row=>row.cust_id == "unknown_customer" ? "Unknown Customer" : <Link style={{color: "#17a2b8 !important"}} to={"/borrower/indiv/view/"+row.cust_id} target="_blank">{row.cust_id}</Link>,
            },
            {
                Header : "Dialler Number",
                id: 'cust_number',
                minWidth:75,
                accessor: row=>row.cust_number
            
            },
            // {
            //     Header : "CS Name",
            //     id: 'cs_name',
            //     minWidth:100,
            //     accessor: row=>row.cs_name
            // },
            {
                Header : "CS Number",
                id: 'cs_number',
                minWidth:100,
                accessor: row=>row.csm_number
            },
            {
                Header : "Device Type",
                id: 'type',
                minWidth:100,
                accessor: row=>dd_value(row.type)
            },
            {
                Header : "Call Start Time",
                id: 'call_start_time',
                minWidth:100,
                accessor: row=>row.call_start_time
            },
            {
                Header : "Call End Time",
                id: 'call_end_time',
                minWidth:100,
                accessor: row=>(row.call_end_time == null) ? "NA" : row.call_end_time
            },
            {
                Header : "Current Call Activity",
                id: 'call_activity_status',
                minWidth:100,
                accessor: row =>(this.callStatus(row.call_activity_status))
            },
            {
                Header : "CS Call Status",
                id: 'call_status',
                minWidth:100,
                accessor: row=> (row.call_status == 'available' ? 
                <div>
                    <div><span class="badge rounded-pill bg-success my-2" style={{fontSize:"12px", minWidth : 90}}>UnOccupied</span></div> 
                    <label style={{marginLeft:'5px',color:'GrayText',fontSize:'12px',fontWeight:'bolder'}}><i>{this.updated_time(row.updated_at)}</i></label>
                </div> 
                : 
                <div>
                    <div><span class="badge rounded-pill bg-danger my-2" style={{fontSize:"12px", minWidth : 90}} >Occupied</span></div> 
                    <label style={{marginLeft:'5px',color:'GrayText',fontSize:'12px',fontWeight:'bolder'}}><i>{this.updated_time(row.updated_at)}</i></label>
                </div>),
            },
            {
                Header : "Action",
                id: 'action',
                minWidth:100,
                accessor: row => (row.call_status == 'unavailable' ? <Button className="btn-sm" variant="primary" onClick={e => this.handlecallstatus(e ,row)}>Release</Button> : " " ),
            }
        ]
        return column
    }

    render(){
        return(
            <>
            <Accordion>
                <Card>		
                    <div className="card-header card-header-icon card-header-primary home_header_d-flex_at_response justify-space-between">
                        <h4 className="card-title text-white tableHead_font title-bottom ">CSO Status For Last Call</h4>
                        <Accordion.Toggle className = "col" as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle()}>
                            <BsChevronDown className = {this.state.show_table ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow"} size="18"/>
                        </Accordion.Toggle>		
                    </div>
                    <div className="card-body no-padding justify-content-center">
                    <Accordion.Collapse eventKey="0">
					    <Card.Body>
                        <Button style={{marginLeft:"92%"}} onClick={()=>this.drawTable()}>Refresh</Button>
                            {/* <CommonReactTable showPag={true} defaultPageSize={20} minRow={0} row_data={this.state.rowdata !== undefined ? this.state.rowdata : []} column_data={this.state.columndata} resizable={false} />   */}

                            <BasicReactTable csv_file={true} row_data={this.state.rowdata !== undefined ? this.state.rowdata : []} column_data={this.state.columndata} pagination={this.state.rowdata.length>9? true:false} default_page_size = {10}   />  

                        </Card.Body>
					</Accordion.Collapse>
                    </div>
                </Card>
            </Accordion>
            
        
            </>
        )
    }
}
export default CSCallStatus ;