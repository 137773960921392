import FlowComponent from './FlowComponent';
import React from 'react';
import { check_priv, get } from '../../../../helpers/storage_helper';
import {Link} from 'react-router-dom';
import {searchLoanAppl} from '../../../../actions/loan_appl_actions';
import  {format_month_change, getValueFromLangJson} from '../../../../helpers/common_helper';
import {dd_value} from '../../../../actions/common_actions';
import {CustID} from '../../partial';
import '../../../../styles/flow.css';
import $ from 'jquery';
import { sortBizcommon,sortAmtcommon,sortDatecommon } from '../../../../helpers/sortingHelpers';
import { lbl_amt } from '../../../../helpers/common_helper';
import Accordion from 'react-bootstrap/Accordion';
import {Card} from 'react-bootstrap'
import {BsChevronDown} from 'react-icons/bs';
import { BasicReactTable } from '../../../../helpers/react_table';

class LoanPendingApproval extends FlowComponent {
    
    state = {
        rowdatas:[],
        columndatas:[],  
        show_table: false ,
        role_code : get('role_codes')     
    }

	handleToggle = (e) => {

        switch(e){
            case("Account"):
                this.setState(state => state.show_table = !this.state.show_table);
                break;
        }
    }

    componentWillMount(){
        const loan_appl_search_criteria = {status : "pending_approval"};

        if(this.state.role_code == 'loan_approver'){
            loan_appl_search_criteria.approver_role = 'loan_approver';
        }else{
            loan_appl_search_criteria.loan_approver_id = get('user_id');
        }
        
        
        searchLoanAppl(this.req({loan_appl_search_criteria}, true))
        .then((response) => {
            // console.log("response  "+response);
            if(!response){return };
            if(response){
                this.setState(state => state.rowdatas = response.data)                
                this.setState(state => state.columndatas = this.columndatas())                
                // // var loanApplicationResults =[];
                    
                // // loanApplicationResults = response.data.map((loan_appl,i)=> <tr>
                    
                // //     <td>{loan_appl.loan_appl_doc_id}</td>
                // //     <td><Link to={"/borrower/indiv/view/"+loan_appl.acc_number} target="_blank">{loan_appl.acc_number}</Link></td>
                // //     <td>{loan_appl.cust_name }</td>
                // //     <td>{loan_appl.cust_id }</td>
                // //     <td>{loan_appl.biz_name }</td>
                // //     <td>{loan_appl.loan_principal} UGX</td>
                // //     <td>{format_month_change(loan_appl.loan_appl_date)}</td>
                // //     {/* <td>{loan_appl.cs_result_code ? dd_value(loan_appl.cs_result_code) : "NULL"}</td> */}
                // //     <td>{loan_appl.cs_result_code ? 
                // //           (loan_appl.cs_result_code == "ineligible" ? "Ineligible" : 
                // //           ((loan_appl.cs_result_code == "requires_flow_rm_approval"  || loan_appl.cs_result_code == "first_n_fas_wo_score" )? "Need Approval" : "NULL"))
                // //           : "NULL"}</td>
                // //     <td>
                // //         {loan_appl.cs_result_code != "ineligible" ?
                // //             <input id={loan_appl.loan_appl_doc_id} 
                // //             type='submit' value='Take Action' onClick={(event) => {this.takeAction(event, loan_appl.cs_result_code, loan_appl.cust_id)}} className='btn btn-primary' />
                // //         :
                // //         "NULL"
                // //         }
                // //     </td>
                    
                // //     </tr>
                // //     ); 
           
                //     this.setState({loanApplicationResults: loanApplicationResults});
                //         $('#loan_list').removeAttr('width').dataTable({
                //             "order" : [],
                //             "columns": [
                //                 {"width": "5%"}, 
                //                 {"width": "5%"}, 
                //                 {"width": "5%"},
                //                 {"width": "5%"},
                //                 {"width": "5%"},
                //                 {"width": "5%"},
                //                 {"width": "5%"},
                //                 {"width": "5%"},
                //                 {"width": "5%"},
                                
                                      
                //              ],
                //             "searching": false,
                //             "paging": false,
                //             "info": false,
                //     });
                }
        });
        
    }

    sortBiz(a,b){                
        return sortBizcommon (a,b)                 
    }
    sortAmt(a,b){         
        const curr_Amt = a.props.children
        const prev_Amt = b.props.children     
        return sortAmtcommon(curr_Amt,prev_Amt)      
    }
    sortDate (a,b) {	 
		const current_Date = a//.props.children[1]
		const prev_Date = b//.props.children[1]		
		return sortDatecommon(current_Date,prev_Date)        
	}

    columndatas () {
        const column = [
            {
                Header : getValueFromLangJson('ac_number'),
                id: "acc_number",
                sortable: false,
                accessor : row => <Link to={"/borrower/indiv/view/"+row.acc_number} target="_blank">{row.acc_number}</Link>
            },
            {
                Header : getValueFromLangJson('fa_applcation_id'),
                id: "loan_appl_doc_id",
                sortable: false,
                accessor : row => <Link to={"/fa_appl/view/"+row.loan_appl_doc_id} target="_blank">{row.loan_appl_doc_id}</Link>
            },
            {
                Header : getValueFromLangJson("cust_name"),
                id: "cust_name",
                sortable: false,
                accessor : row => {return row.cust_name }
            },
            {
                Header : getValueFromLangJson("cust_id"),
                id: "cust_id",
                sortable: false,
                accessor : row => {return row.cust_id }
            },
            {
                Header : getValueFromLangJson("bz_name"),
                id: "biz_name",
                sortable : false,
                sortMethod: (a,b) => this.sortBiz(a,b),
                accessor : row => {return row.biz_name }
            },
            {
                Header : () => (<div className={'tableAmount_centerAlign'}>{getValueFromLangJson('fa_amount')}</div>),
                id: "loan_principal",
                sortable : false,
                sortMethod: (a,b) => this.sortAmt(a,b),
                accessor : row => <div className={'tableAmount_rightAlign'}>{lbl_amt(row.loan_principal, this.currency_code)}</div>
            },
            {
                Header : getValueFromLangJson("date"),
                id: "loan_appl_date",
                sortable : false,
                sortMethod: (a,b) => this.sortDate(a,b),
                accessor : row => {return format_month_change(row.loan_appl_date)}
            },
            {
                Header : getValueFromLangJson("action"),
                id: "cs_result_code",
                sortable: check_priv('application', 'approval') ? false : true,
                accessor : row => {return row.cs_result_code ? 
                    (row.cs_result_code == "ineligible" ? "Ineligible" : 
                    ((row.cs_result_code == "requires_flow_rm_approval"  || row.cs_result_code == "first_n_fas_wo_score" ) ?  getValueFromLangJson('need_approval') : "NULL"))
                    : "NULL"}
            },
        ]

        return column.filter((val) => val.sortable == false)
    }

    takeAction = (event, result_code, cust_id) => {
       

        if(result_code == "requires_flow_rm_approval" || result_code == "eligible" || result_code == "first_n_fas_wo_score"){
            const loan_appl_doc_id = event.target.id;
            alert("Please approve/reject the FA from the new tab");
            window.open("/fa_appl/view/"+loan_appl_doc_id,"_blank");
        } 
       	
        else {
            window.open("/borrower/indiv/view/"+cust_id,"_blank");
        }
	}
    render(){        
        return (
            <Accordion
                defaultActiveKey={this.state.role_code == "loan_approver" ? "0" : "1"}>
                <Card >
        {/* <div className='home_table_box_margin_at_response'> */}
            {/* <div className="card"> */}
            <div className={`${this.state.rowdatas && this.state.rowdatas.length==0 ?'empty_display_list': 'non_empty_display_list'}`}>
            <div className="card-header card-header-icon card-header-primary home_header_d-flex_at_response justify-space-between">
                    <h4 className="card-title text-white d-inline-block tableHead_font title-bottom " >{getValueFromLangJson('fas_pend_ur_approval')}</h4>
                    <Accordion.Toggle className = "col" as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("Account")}>
                            <BsChevronDown className = {this.state.show_table ? "arrow-icon down accordin-arrow" : "arrow-icon accordin-arrow"} size="18"/>
                        </Accordion.Toggle>
                </div>
            </div>               
                
                <div className="card-body no-padding">
                <Accordion.Collapse eventKey="0">
							<Card.Body>
                          

                     <BasicReactTable row_data={this.state.rowdatas !== undefined ? this.state.rowdatas : []} column_data={this.state.columndatas} pagination={ this.state.rowdatas !== undefined && this.state.rowdatas.length > 9 ? true : false}  export_csv_datas={this.state.logs ? this.state.logs : []}  defaultSorted={[{id:"loan_appl_date",desc:true}]} default_page_size = {50}  />  

                    </Card.Body>
						</Accordion.Collapse>
                </div>
            {/* </div> */}
        {/* </div> */}
        </Card>
            </Accordion>
        );
    
}
}
export default LoanPendingApproval;