import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import FileUpload from '../../common/component/FileUpload';
import {getRefAccounts} from '../../../actions/account_txn_actions';
import DateTimePicker from 'react-datetime';
import moment from 'moment';

class AccountStmtTxns extends FlowComponent{
    
  state = {
    acc_stmts:{},
    txn_date : moment().format("YYYY-MM-DD HH:mm:ss"),
    mode : false,
  }

  componentWillMount(){
    this.loadAccounts()
  }

  componentDidUpdate(prevState){
    if(prevState.acc_stmts != this.state.acc_stmts){
      this.props.get_state_value(this.state.acc_stmts)
    }

  }
 
  loadAccounts = (event) => {
    var account = {"country_code": this.country_code,'status' : 'enabled', to_recon : true};    
      getRefAccounts(this.req({account},true))
        .then((response) => {
          if(!response){return };
          if(response.status === "success"){
            if(response.data != ""){
              this.getSelectOptionsFromResult(response.data, "lender_accounts", ['id','name']);
            }     
          }else{ 
            alert(response.message + "\n" + JSON.stringify(response.data));  
          }
        }
      );
  }
  handleModeChange = (event) => {
    if(event == 'time'){
      this.setState({mode:true})
    }
    // else{
    //   this.setState({mode:false})
    // }
  }

  removeFile = () =>{
  
    this.setState((prevState) => ({ 
      acc_stmts: {
      ...prevState.acc_stmts,
      photo_statement_proof: null,
      orig_photo_statement_proof: null
    }}));
  }
  
 
  
  

render(){

    return(
      <>
      <div className = "row no-margin justify-content-center">
        <div className = "col-md-5 reset-padding">
          <div className="row no-margin justify-content-center align_ver_center">
              <label className="col-md-5 no-margin reset-padding"><b>Accounts </b><font className="text-danger">   *  </font> </label>
              <div className="col-sm-5 reset-padding">
                <select  id="account_id" ref={select => this.to_acc = select} onChange={(e) => { this.handleChange(e);}} className={`form-control ${this.state.credit_style}`} type="text">
                    {this.state.lender_accounts_dd}   
                </select>
              </div> 
          </div>
          <div className="row no-margin justify-content-center align_ver_center">
              <label className="col-md-5 no-margin reset-padding"><b>Stmt Txn Date</b><font className="text-danger">   *  </font> </label>
              <div className="col-sm-5 reset-padding">
                <DateTimePicker showTimeInput onChange={(value) => {this.setState({txn_date : moment(value).format("YYYY-MM-DD hh:mm A")});this.handleDateTimeChange("stmt_txn_date", value)}}   onViewModeChange={(event) => {this.props.onChange(event);this.handleModeChange(event)}}  dateFormat="DD MMM YYYY" inputProps={{value:this.state.txn_date && this.state.mode ? this.state.txn_date:''}}/>
                <p className='text-danger'> Please select a transaction date along with the time</p>
              </div>                                        
              <div className="col-sm-5 reset-padding">
              </div>
          </div>  
          <div className="row no-margin justify-content-center align_ver_center">
                <label className="col-md-5 reset-padding no-margin"><b>Stmt Txn Type</b><font className="text-danger">   *  </font> </label>
                <div className={`col-sm-5 reset-padding  ${this.state.credit_style}`}>
                    <select id="stmt_txn_type" className = "form-control" onChange={this.handleChange}>
                          {this.getSelectOptions("stmt_txn_type")}
                    </select>                                         
                </div>
          </div>

          <div className="row no-margin justify-content-center align_ver_center">
                              <label className="col-md-5 reset-padding no-margin"><b>Reason for Add Txn</b><font className="text-danger">   *  </font></label>
                                 <div className="col-sm-5 reset-padding">
                                    <select id="reason_for_add_txn" onChange={(e) => { this.handleChange(e);}} className={`form-control ${this.state.credit_style}`} type="text" required="required">
                                       {this.getSelectOptions("skip_txn_id_check")}
                                    </select>
                                 </div>                                        
                           </div>
          
         
        </div>   
        <div className = "col-md-5 reset-padding">  
            <div className="row no-margin justify-content-center align_ver_center">
              <label className="col-md-5 no-margin reset-padding"><b>Stmt Txn ID</b><font className="text-danger">   *  </font> </label>
              <div className="col-sm-5 reset-padding">
                <input autoComplete='off' type="text" id = "stmt_txn_id" className={`form-control ${this.state.txn_id_style} ${this.state.credit_style}`}  onChange={(e) => { this.handleChange(e)}} tabIndex = '-1'  required="required"/>
              </div>
              <div className="col-sm-5 reset-padding">
              </div>
          </div>
          <div className="row no-margin justify-content-center align_ver_center">
              <label className="col-md-5 no-margin reset-padding"><b>Amount </b><font className="text-danger">   *  </font> </label>
              <div className="col-sm-5 reset-padding">
                <input autoComplete='off' type="text" className={`form-control ${this.state.credit_style}`} id = "amount" onChange={(e) => { this.handleChange(e)}}  required="required"/>                                                                                 
              </div>                                           
              <div className="col-sm-5 reset-padding">
              </div>
          </div>  
         
          
          <div className="row no-margin justify-content-center align_ver_center">
              <label className="col-md-5 no-margin reset-padding"><b>Description </b><font className="text-danger">   *  </font> </label>
              <div className="col-sm-5 reset-padding">
                <textarea id="descr"  onChange={this.handleChange}  className="form-control" required ></textarea>
              </div>
              <div className="col-sm-5 reset-padding">
              </div>
          </div>

        </div>
      </div>

      <div className="row mx-0 mt-3 justify-content-center align_ver_center">
              <label  className="col-md-2 my-0 reset-padding"><b>FA ID / Switch ID </b></label>
              <div className="col-sm-2 reset-padding">
                <input autoComplete='off' type="text" className={`form-control ${this.state.credit_style}`} id = "loan_doc_id" onChange={(e) => { this.handleChange(e)}}  required="required"/>                                                                                 
              </div>
             
          </div>

          <div className="row labelTopgap reset-padding justify-content-center no-marginn  align-items-center" >
          <p className='form-group text-white text-bold  text-center' style={{color : "#01062c", fontSize : "11px"}}>Note : This transaction will be captured against this FA / Switch.</p>

             
          </div>    


      <div className="d-flex  labelTopgap justify-content-center  align-items-center" >
              <FileUpload id="photo_statement_proof" mode = "create" entity ='acc_stmt' type="image"        
                            onCreateUpload={this.setChangeToState} remove_file = {true} removeFile={() => this.removeFile()} onEditUpload={(e) => {this.handleSave(e, "reload")}}  label="Photo Statement Proof" />
          </div>    
</>
  )
}

}

export default AccountStmtTxns;