import React from 'react';
import { Link} from 'react-router-dom';
import '../../../styles/flow.css';
import FlowContainer from '../../common/container/core/FlowContainer';
import SearchLoanCriteria from '../../loan_appl/component/SearchLoanCriteria';
import {borrowerSearch} from '../../../actions/borrower_actions';
import {dd_value,dd} from '../../../actions/common_actions';
import { Modal , Button} from 'react-bootstrap';
import {person_name, calc_percent,format_date, Homeacc_prvdrLogoselector, title_case, lbl_amt} from '../../../helpers/common_helper';
import AccountContainer from '../../account/container/account_container';
import StatusButton from '../../common/component/core/StatusButton';
import {TooltipLabel,Remarks} from '../../common/partial';
import {check_priv} from '../../../helpers/storage_helper';
import DatePicker from 'react-datetime';
import moment from 'moment';
import $ from 'jquery';
import {get} from '../../../helpers/storage_helper';
import CommonReactTable from '../../../helpers/commonReact_table';
import { sortDatecommon,sortBizcommon} from '../../../helpers/sortingHelpers';
import {Config} from '../../../helpers/storage_helper';
import AlerModal from '../../common/component/AlertModal';
import { BasicReactTable } from '../../../helpers/react_table';

class BorrowerSearchContainer extends FlowContainer{
	constructor(props){
	    super(props);
	this.state = {
		searchBorrower:{
			profile_status : "open",
			linked_with_rm : true
		
		},
		paginate : Config('paginate') ? Config('paginate') : null,
		datatable_div_style: "hide_div",
	    search_div_style: "show_div",
	    modalBox: false,
	    modify_search:"hide_div",
        accountList: null,
		profile_status:{'closed_profile' : false,'flagged_profile':false} ,
		columndatas:[],
		rowdatas:[],
		copy:[],
		export_data:[],
		csv_loaded:false,
		under_watch:false,
		total :0,
		loader : false,
		index : 0,
		copy_search : null,
		language_json : get("language_json"),
		show_alert : false,
		alert_message:"",
		page_count:0
		};
 }

  /*handleClick = borrower_type => event => {
        const cust_id = event.target.id;
        if(borrower_type === "Institution"){
          window.open("/borrower/ins/edit/"+cust_id,"_blank");
        }else if(borrower_type === "Individual"){
          window.open("/borrower/indiv/edit/"+cust_id,"_blank");
        }
    }*/
  handleViewAccountsClick = (event,acc_number,person_name) => {
  	
  	this.setState({cust_id: event.target.id});
    this.acc_number =  acc_number;
    this.person_name = person_name;
    this.setState({modalBox: "true"});
  }
  handleModalClose = (event) => {
	
		this.setState({ audit_modal:false , modalBox: false});


  }  
  handleModifySearch = (event) => {
	this.setState({
		search_div_style:'show_div',
		modify_search:'hide_div'
	})
  }  
  handleClick = (mode , borrower_type, cust_id) => event => {
  	event.preventDefault();
  	
  	//var any_id = document.getElementById('req_parameter').value;
  	
  	if(cust_id == null)
  	{	
  		if(this.state.searchBorrower.req_parameter){
  			cust_id = this.state.searchBorrower.req_parameter;	
  		}else{
  			alert("Please enter a criteria in 'Any Customer ID / Mobile No / National ID'")
  			return
  		}
  	}

    if(borrower_type === "institutional"){

      window.open("/borrower/ins/"+mode+ "/" +cust_id,"_blank");
    }else {//if(borrower_type === "Individual"){
      window.open("/borrower/indiv/"+mode+ "/" +cust_id,"_blank");
    }
  }

  	// showBorrowers(){	
  	// if(check_priv("customer" , 'list_account')){
    //       this.btn_style = "show_div";
    // }else{
    //       this.btn_style = "disable_div";
    // }
  	// const can_edit = check_priv('customer', 'edit');
  	// const show_risk_category = check_priv('customer' , 'risk_category');
  	// const r = Math.random();
	// const borrowerResults = this.borrower.map((borrower,i)=> <tr key={r+i}>
	// 	<td ><Homeacc_prvdrLogoselector logotype={borrower.acc_prvdr_code} /></td>
    //     <td ><a href="#" className="flow_custid" onClick={this.handleClick('view', borrower.biz_type, borrower.cust_id)} target="_blank">{borrower.cust_id}</a></td>
    //     <td > {borrower.acc_number}</td>
    //     <td > {borrower.category}</td>
    //     <td > <TooltipLabel full_text={borrower.biz_name} overlay={person_name(borrower)} /></td>
    //     <td > {borrower.flow_rel_mgr_name}</td>
    //     <td > {borrower.reg_flow_rel_mgr_name}</td>
    //     {/* <td > {borrower.data_prvdr_name}</td> */}
    //     <td > {borrower.tot_loans ? borrower.tot_loans : 0}</td>
    //     {/* <td > {calc_percent(borrower.late_loans, borrower.tot_loans)}</td> */}
    //     <td > 
	// 	{borrower.is_og_loan_overdue && borrower.ongoing_loan_doc_id ?  
	// 	<Link to={"/fa/view/"+borrower.ongoing_loan_doc_id} target="_blank" className="overdue">{borrower.ongoing_loan_doc_id}</Link>
	// 	:
	// 	borrower.ongoing_loan_doc_id ?
	// 	<Link to={"/fa/view/"+borrower.ongoing_loan_doc_id} target="_blank" className="on_going">{borrower.ongoing_loan_doc_id}</Link>
	// 	:
	// 	borrower.pending_loan_appl_doc_id ?  
	// 	<Link to={"/fa_appl/view/"+borrower.pending_loan_appl_doc_id} target="_blank" className="pending">{borrower.pending_loan_appl_doc_id}</Link>
	// 	:
	// 	<Link to={"/fa_appl_for/"+borrower.cust_id} target="_blank" className="apply">Apply FA</Link>
	// 	}
	// 	</td>    
	// 	{/* <td>{borrower.last_loan_doc_id ? 
	// 		<Link to={"/fa/view/"+borrower.last_loan_doc_id} target="_blank" className="last_FA">{borrower.last_loan_doc_id}</Link>
	// 		:"NULL"}</td> */}
	// 	<td>{borrower.is_og_loan_overdue ? 'YES' : 'NO'}</td>
	// 	<td>{borrower.reg_date ? 
	// 			<span>{format_date(borrower.reg_date)}</span> : "NA"}</td>
	// 	<td>{borrower.last_visit_date ?
	// 			<span>{format_date(borrower.last_visit_date)}</span> : "NA"}</td>
	// 	<td>{borrower.last_loan_date ?
	// 			<span>{format_date(borrower.last_loan_date)}</span> : "NA"}</td>
    //     {/*<td ><Remarks remarks={borrower.remarks} /></td>*/} 
    //      <td >{borrower.cond_count}</td>
	// 	 <td >{dd_value(borrower.territory)}</td> 
	// 	 <td >{dd_value(borrower.location)}</td> 
    //      <td >{dd_value(borrower.profile_status)}</td>
    //      <td >{dd_value(borrower.activity_status)}</td>
	// 	 {show_risk_category &&
	// 		 <td >{dd_value(borrower.risk_category)}</td>
	// 	 }
    //      <td >{dd_value(borrower.status)}</td>
    //     {can_edit &&
    //     	<td > <input id={borrower.cust_id} type='button' onClick={this.handleClick('edit' , borrower.biz_type, borrower.cust_id)} value='Edit'  className='btn-sm btn-primary' /> </td>
    // 	}
    //    </tr>
	   
	// 	);
		
   	// 	this.setState({can_edit, show_risk_category, search_div_style:'hide_div', borrowerResults, datatable_div_style: "show_div",modify_search:"show_div"});
    //     var col_width = [
    //             {"width" : "8%"},
    //             {"width" : "8%"},
	// 			{"width": "8%"},
	// 			{"width": "8%"},
	// 			{"width": "8%"},
	// 			{"width": "8%"},
	// 			{"width": "8%"},
	// 			{"width": "8%"},
	// 			{"width": "8%"},
	// 			{"width": "8%"},
	// 			{"width": "8%"},
	// 			{"width": "8%"},
	// 			{"width": "8%"},
	// 			{"width": "8%"},
	// 			{"width": "8%"},
	// 			{"width": "8%"},
	// 			{"width": "8%"},
	// 			{"width": "8%"},
	// 			{"width": "8%"}
				
    //              ];

    //       if(can_edit){
    //          col_width.push({"width": "8%"});
    //       }
	// 	  if(show_risk_category){
	// 		col_width.push({"width": "8%"});
	// 	  }

	//         $('#borrower_list').removeAttr('width')
	//         	.dataTable({
	//           	"order" : [],
	// 			"columns": col_width,

	// 		dom:'Bfrtip',
	// 		buttons: [
	// 		'csv','excel','print'
	// 		]
	// 		});
	// 	// $('#borrower_list').removeClass("dataTable");
  	// }
 	 handleSearch = (mode, borrower_type, is_btn_search = false) => event => {
    	var prev_table = $('#borrower_list').DataTable();
		prev_table.destroy();
		this.setState(state=>state.rowdatas=[])
    	var borrower_search_criteria = this.state.searchBorrower; 
		borrower_search_criteria.mode = mode 
		
		if(this.state.under_watch){
			console.log('state.underwatch', this.state.under_watch) 
			borrower_search_criteria.under_watch = this.state.under_watch
		}else{
			delete borrower_search_criteria.under_watch;
		}
 		if(Object.keys(borrower_search_criteria).length > 0){
 			this.setState({datatable_div_style: "hide_div", total : 0, loader : true,copy_search : {...JSON.parse(JSON.stringify(this.state.searchBorrower))}});
			if(this.state.paginate){
				this.setState((state) => {
					return {
						paginate: Config('paginate'),
					};
				  }, ()=>this.borrow_serch_cri(mode, borrower_type));
			}
			else{
				this.borrow_serch_cri(mode, borrower_type)
			}
		  
		}else{
			alert("Please enter valid criteria")
		}
					        	
 	}

	borrow_serch_cri = (mode, borrower_type, cond = null) =>{
		this.setState({loader : true})
			var borrower_search_criteria = this.state.searchBorrower; 
			borrower_search_criteria.mode = mode  
			borrower_search_criteria.check_for_restricted_data = true
			if(borrower_search_criteria.hasOwnProperty('linked_with_rm') && !borrower_search_criteria.linked_with_rm){
				delete borrower_search_criteria.linked_with_rm
			}
			this.setState({datatable_div_style: "hide_div", total : 0, loader : true,copy_search : {...JSON.parse(JSON.stringify(this.state.searchBorrower))}});
			var req_json = this.state.paginate != null ? {borrower_search_criteria, paginate: this.state.paginate} : {borrower_search_criteria};
		
		borrowerSearch(this.req(req_json,true))
		        .then((response) => {										
		        	this.resp(response)
		        	if(!response){return this.setState({loader : false,reload:false})};
	           	    // var borrowerResults = [];
	           		if(response.status === "success"){
						if(response.data.mode == 'view'){
							if(borrower_type === "institutional"){
								window.open("/borrower/ins/"+mode+ "/" +response.data.cust_id,"_blank");
							}else {
								window.open("/borrower/indiv/"+mode+ "/" +response.data.cust_id,"_blank");
							}
						}
						else if(response.data.mode == 'search'){
							this.borrower =  response.data.results;
							// this.showBorrowers();	
						}else if(response.data.mode == "alert"){
							this.setState({alert_message: response.data.message})
							this.handleAlertModal(true);
						}	

						if(check_priv("customer" , 'list_account')){
							this.btn_style = "show_div";
						}else{
								this.btn_style = "disable_div";
						}						
						this.setState(state=>state.columndatas = this.drawColumndata(this.state.language_json))
						this.setState(state=>state.rowdatas=response.data.results)	
						// this.setState(state=>state.copy=response.data.results)	
						if(this.state.paginate != null){
							this.setState(state=>state.index=this.state.paginate.pagination_count*this.state.paginate.pagination_page)
							this.setState(state=>state.total=response.data.total_count)	
							this.page_count()
						}
						else
						{
							this.setState(state=>state.total=response.data.results.length)	
						}

						this.setState((state) => {
							return { copy : response.data.results };
						}, ()=>this.setState({loader : false,reload:false}));
		           	}else{
		          		alert("No results found."); 
		          		  		
		           	}
		          }
		        );
	}

	changeHandler = (e) => {		
		const toFilter = e
		const filterfrom = this.state.rowdatas
		const filteredData = filterfrom.filter(value => {
			return(
				value.cust_id !==null && value.cust_id.toLowerCase().includes(toFilter.toLowerCase()) ||
				value.acc_number !==null && value.acc_number.includes(toFilter) ||
				value.category !==null && value.category.toLowerCase().includes(toFilter.toLowerCase()) ||
				value.biz_name !==null && value.biz_name.toLowerCase().includes(toFilter.toLowerCase()) ||
				value.flow_rel_mgr_name !==null && value.flow_rel_mgr_name.toLowerCase().includes(toFilter.toLowerCase()) ||
				value.reg_flow_rel_mgr_name !==null && value.reg_flow_rel_mgr_name.toLowerCase().includes(toFilter.toLowerCase()) ||
				value.territory !== null && value.territory.toLowerCase().includes(toFilter.toLowerCase()) ||
				value.location !== null && value.location.toLowerCase().includes(toFilter.toLowerCase()) 
			)
		})
		this.setState(state=>state.copy = filteredData)		
	}
	// resetHandler = () => {
	// 	this.setState(state=>state.copy=this.state.rowdatas)
	// }

	drawColumndata (language_json) {
		const can_edit = check_priv('customer', 'edit');
		const show_risk_category = check_priv('customer' , 'risk_category');
	
		return [
			{
				Header : "SI No.", 
				id: "row",	
				width:60,
				enableSortBy:  true,						       
				Cell: ({row}) => {
				  return <div>{((row.index+1))}</div>;
				}
			},
			{
                Header : language_json.ac_number,
                id: "acc_prvdr_code",
				sortable:true,
				width:130,

                accessor:row => 
				<div className='d-flex align-items-center '>
				<Homeacc_prvdrLogoselector logotype={row.acc_prvdr_code} />
                <p className='my-0 mx-2'>{row.acc_number}</p>
				</div>
            },
			{
                Header : language_json.flow_cust_id,
                id: "cust_id",
				// sortable:false,
                accessor:row => <a href="#" className="flow_custid" onClick={this.handleClick('view', row.biz_type, row.cust_id)} target="_blank">{row.cust_id}</a>
            },
			// {
            //     Header : language_json.ac_number,
            //     id: "acc_number",
			// 	// sortable:false,
            //     accessor:row => {return row.acc_number}
            // }, 
			// {
            //     Header : language_json.category,
            //     id: "category",
            //     accessor:row => {return row.category}
            // },
			{
                Header : language_json.shop_type,
                id: "biz_addr_prop_type",
                accessor:row => {return  <TooltipLabel full_text={dd_value(row.biz_addr_prop_type, "biz_addr_prop_type")} />}
			},
			{
                Header : language_json.biz_name,
                id: "biz_name",
				sortMethod : (a,b) => this.sortBiz(a,b),
                accessor:row => <TooltipLabel full_text={row.biz_name} overlay={person_name(row)} />
            }, 
			{
                Header : language_json.rm_name,
                id: "flow_rel_mgr_name",
				sortable:this.state.paginate ? false : true,
                accessor:row => {return row.flow_rel_mgr_name}
            },         
			{
                Header : language_json.reg_rm_name,
                id: "reg_flow_rel_mgr_name",
				sortable:this.state.paginate ? false : true,
                accessor:row => {return row.reg_flow_rel_mgr_name}
            },
			
			{
                Header : language_json.tot_fas,
                id: "tot_loans",
                accessor:row => {return  <p className='my-0 ml-3'>{row.tot_loans ? row.tot_loans :0}</p> }
            },
			{
				Header : language_json.fa_limit,
                id: "fa_limit",
				sortable: this.state.paginate ? false : true,
                accessor:row => {return row.fa_limit ? <div>{row.fa_limit} {get('market').currency_code}</div>: <div></div>}
			},

			{
				Header : language_json.first_fa_amt ,
				id: "first_fa_amt",
				accessor:row => {return row.first_fa_amt ?
					<p className=' m-0'>{lbl_amt(row.first_fa_amt,get('market').currency_code)}</p> : "NA"}
			},  
			{
				Header : language_json.ongoing_fa_amt ,
				id: "ongoing_fa_amount",
				width:130,

				accessor:row => {return row.ongoing_fa_amount ?
					<p  className=' m-0'>{lbl_amt(row.ongoing_fa_amount,get('market').currency_code)}</p> : "NA"}
			},


			{
				Header : language_json.last_fa_amount,
				id : "last_fa",
				sortable: this.state.paginate ? false : true,
				accessor:row => {return row.last_fa ? <div>{row.last_fa} {get('market').currency_code}</div>: <div>-</div>}
			},
			{
                Header : language_json.open_fa_appl,
				sortable:false,
                id: "ongoing_loan_doc_id",
				show:check_priv("loan", "apply"),
				sortable:this.state.paginate ? false : true,
                accessor:row => {return row.is_og_loan_overdue && row.ongoing_loan_doc_id ?  
									<Link to={"/fa/view/"+row.ongoing_loan_doc_id} target="_blank" className="overdue">{row.ongoing_loan_doc_id}</Link>
									:
									row.ongoing_loan_doc_id ?
									<Link to={"/fa/view/"+row.ongoing_loan_doc_id} target="_blank" className="on_going">{row.ongoing_loan_doc_id}</Link>
									:
									row.pending_loan_appl_doc_id ?  
									<Link to={"/fa_appl/view/"+row.pending_loan_appl_doc_id} target="_blank" className="pending">{row.pending_loan_appl_doc_id}</Link>
									:
									<Link to={"/fa_appl_for/"+row.cust_id} target="_blank" className="apply">Apply FA</Link> 
									
								}
            },
			{
                Header : <p className='m-0 text-center'>{language_json.overdue}</p>,
                id: "is_og_loan_overdue",
                accessor:row => {return <p className='my-0 text-center'>{row.is_og_loan_overdue ? 'YES' : 'NO'}</p>}
            },
			{
                Header : language_json.reg_date,
                id: "reg_date",
				sortMethod: (a,b) => this.sortDate(a,b),
                accessor:row => {return row.reg_date ? 
									<span>{format_date(row.reg_date)}</span> : "NA"}
            },
			{
                Header : language_json.last_visit,
                id: "last_visit_date",
				sortMethod: (a,b) => this.sortDate(a,b),
                accessor:row => {return row.last_visit_date ?
									<span>{format_date(row.last_visit_date)}</span> : "NA"}
            },
			{
                Header : language_json.last_fa,
                id: "last_loan_date",
				sortMethod: (a,b) => this.sortDate(a,b),
                accessor:row => {return row.last_loan_date ?
									<span>{format_date(row.last_loan_date)}</span> : "NA"}
            },
			// {
            //     Header : language_json.cond_count,
            //     id: "cond_count",
            //     accessor:row => {return row.cond_count}
            // },
			{
                Header : language_json.territory,
                id: "territory",
                accessor:row => {return dd_value(row.territory) == null ? 'NA' : dd_value(row.territory)}
            },
			{
                Header : language_json.location,
                id: "location",
                accessor:row => {return dd_value(row.location) == null ? 'NA' : dd_value(row.location)}				
            },
			{
                Header : language_json.landmark,
                id: "landmark",
                accessor:row => <TooltipLabel full_text={row.landmark}  />
            },
			{
                Header : language_json.profile_status,
                id: "profile_status",
                accessor:row => {return dd_value(row.profile_status)}				
            },	
			{
                Header : language_json.activity_status,
                id: "activity_status",
                accessor:row => {return dd_value(title_case(row.activity_status))},
            },
			{
                Header : language_json.risk_category,
                id: "risk_category",				
				show:show_risk_category,
                accessor:row => {return show_risk_category && dd_value(row.risk_category)}
            },
			{
                Header : language_json.status,				
                id: "status",
                accessor:row => {return dd_value(row.status)}
            },
			{
                Header : "Audit Info",				
                id: "audit_by",
                accessor:row => {return <div className='d-flex '>
					{(row.last_audited_date || row.audited_by )?
					<input id={row.cust_id} type='button' onClick={()=>{this.setState({audit_data:row,audit_modal:true})}}value='view'  className='btn-sm btn-primary' />:<p className='ml-4'>-</p>}

				</div>}
            },
			{
                Header : language_json.edit,
				sortable:false,
                id: "edit",
				show:can_edit,
                accessor:row => {return can_edit && <input id={row.cust_id} type='button' onClick={this.handleClick('edit' , row.biz_type, row.cust_id)} value='Edit'  className='btn-sm btn-primary' />}
            },
		]
	}

	sortDate (a,b) {		
		const current_Date = a.props.children
		const prev_Date = b.props.children		
		return sortDatecommon(current_Date,prev_Date)		
	}
	sortBiz (a,b) {		
		return sortBizcommon(a.props.full_text,b.props.full_text)		
	}

		handlecloseprofilechange =(event) =>{
			var profile_status = this.state.profile_status

			if(event.target && event.target.id=='include_unlinked_cust'){
				this.state.searchBorrower.linked_with_rm = event.target.checked ? false : true
			}
			const value = ((event.target.id === 'closed_profile')||(event.target.id =="flagged_profile") )? event.target.checked : event.target.value;
			profile_status[event.target.id] = value
			
			if(profile_status['flagged_profile']){
				this.state.searchBorrower["flagged_profile"]=true
			}
			this.state.searchBorrower.profile_status = profile_status.closed_profile == true ? 'closed' :'open';	
		}
		
		pagination = (page, count, sorted)=>{
			var copy_search = Object.keys(this.state.copy_search).filter((key)=>this.state.copy_search[key]!=null)
			var search = Object.keys(this.state.searchBorrower).filter((key)=>this.state.searchBorrower[key]!=null)
			var is_equal = copy_search.every(value => search.includes(value)) && search.every(value => copy_search.includes(value))
			if(!is_equal){
				this.setState({paginate : Config('paginate'), reload:true, copy : [], total : 0, rowdatas : [], next : false, copy_search : {...JSON.parse(JSON.stringify(this.state.searchBorrower))}}, ()=>{
					this.borrow_serch_cri('search')	
				})
			}
			else{
				this.setState((state) => {
					return {
					  paginate: {
						...state.paginate,
						pagination_count: count,
						pagination_page:page,
						sorting:sorted,
						total_count : this.state.total
					  },
					};
				  }, ()=>this.borrow_serch_cri('search'));
			}

			
		}

		page_count = ()=>{
			
			const total = this.state.total
			const page_count_mod = (total%this.state.paginate.pagination_count)
			
			if(page_count_mod > 0){
				const page_count = (total/this.state.paginate.pagination_count)
				this.setState({page_count : Math.trunc(page_count)+1}, ()=>console.log(this.state.page_count))

			}else{
				const page_count = (total/this.state.paginate.pagination_count)
				this.setState({page_count : page_count})
			}
		}

		

		handleExportCSV = (event) => {
			let brwr = JSON.parse(JSON.stringify(this.state.searchBorrower));
			let resp = null;
			if(event == 'email') {
				brwr.export = 'email'
				resp = window.confirm(`The current search exceeds ${Config("export_csv").limit} rows. So the exported data will be sent to your email shortly.`)
			}

			if(event == 'email' && !resp){
				return
			}else{
				this.handleCSVapi(brwr, event)
			}
		}
		
		handleCSVapi(brwr, action){
			let calling_api = true 
			var request={borrower_search_criteria:brwr, paginate : false}
			if (action == 'email') calling_api = false 
			borrowerSearch(this.req(request, true, null, calling_api), true)
		        .then((response) => {
					if(response.data){
						this.resp(response)
						if(action == 'email'){
							return
						}
						this.setState(state=>state.export_data = response.data.results)

					}
				});
		}
		search_under_watch =(event) =>{
			if(event.target.checked){
				this.setState({under_watch:true})
			}
			else{
				this.setState({under_watch:false})
			}
				
		}

		handlePurposeChange = (selected_options,id) =>{
			this.setMultiselectOptionOnState(selected_options,id,this.state.searchBorrower)
			
		}

		handleAlertModal = (val) => {
			this.setState({show_alert:val})
		}

render(){	
return (
	      <div className="container containerTopmargin max-width no-padding">
			<AlerModal handleAlert={this.handleAlertModal} show_alert={this.state.show_alert} alert_message={this.state.alert_message} header="Access restricted!"/>
	      <div className="floatAdvancetitleBox">
	      	<h5 className='no-margin preHeaderpad text-white' style={{paddingLeft:15,fontSize:20}}>{this.state.language_json.search_customer_titile}</h5>
	      </div>
           	 <div className={this.state.modify_search}>
				<button type="button" className="btn btn-md btn-light" id="float_style"onClick={this.handleModifySearch} >{this.state.language_json.modify_search}</button>
			</div>
			<div className={this.state.show_alert ? "disable_div" : ""}>
				<div className={this.state.search_div_style}>
				<SearchLoanCriteria  id="searchBorrower" mode="borrower"
										onSearch={this.handleSearch} 
										onView ={this.handleClick}
										disable_btn = {this.state.calling_api} 
										onComponentChange={this.handleComponentChange} 
										onComponentReset={this.handleComponentReset} 
										onComponentElementChange={this.handleComponentElemetChange}
										onComponentCheckboxclick= {this.handlecloseprofilechange}
										search_under_watch= {this.search_under_watch}
										under_watch={this.state.under_watch}
										handlePurposeChange={(a,b)=>{this.handlePurposeChange(a,b)}}
										data={this.state}
										profile_status = {this.state.profile_status}/> 
				</div>
			</div>
			  {this.state.rowdatas &&
			  <div className={this.state.datatable_div_style + 'card'}>
				  <div id=''>
					  <div className="customer_list_reactTable" style={{position: `${this.state.paginate && 'relative'}`}} >
						  {this.state.rowdatas.length > 0 &&
						  	<>
								<div className= {`${this.state.paginate ? 'd-flex justify-content-between align-items-center col-md-5' : 'd-flex justify-content-between align-items-center'}`} style={{padding:5, top : `${this.state.paginate ? '30px' : '0px'}`}}>
									{!this.state.loader && <p className='no-margin text-white'><b>{this.state.language_json.total_entries} : {this.state.total}</b></p>}
									{!this.state.paginate &&
										<div style={{position:'relative'}}>
											<i class="fa-solid fa-magnifying-glass search_pos"></i>
											<input placeholder='Search here' className='search_posInput' onChange={(e)=>this.changeHandler(e.target.value)}></input>
											{/* <button className='btn-std btn btn-primary' onClick={()=>this.resetHandler()}>Reset</button> */}
										</div>
									}
								</div>
								<div>{  this.state.copy &&  this.state.copy.length > 0 &&
									<div style={{display:!this.state.loader ?'':'none'}}>
											{this.state.paginate == null ?
												// <CommonReactTable showPag={true} defaultPageSize={10} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} resizable={false} exportCSV={true} csvFile={"Customer Lists"} csvData={this.state.copy !== undefined ? this.state.copy : []} searchData={[{...this.state.searchBorrower,title:'Search Customer'}]}/> 
												<BasicReactTable pagination={true} default_page_size={10}  row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas}  csv_file={true} FName={"Customer Lists"} export_csv_datas={this.state.copy !== undefined ? this.state.copy : []} searchData={[{...this.state.searchBorrower,title:'Search Customer'}]}/> 
												: !this.state.reload && 
												<BasicReactTable pagination={true} default_page_size={10}  row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas}  csv_file={true} FName={"Customer Lists"} export_csv_datas={this.state.export_data !== undefined ? this.state.export_data : []} searchData={[{...this.state.searchBorrower,title:'Search Customer'}]} filter_data = {this.pagination} server_side_pagination = {this.state.paginate} total_page_count = {this.state.paginate.pagination_count > 0 ? Math.ceil(this.state.total / this.state.paginate.pagination_count) : 0} handleExport = {this.handleExportCSV} csv_total_data = {this.state.total !== undefined ? this.state.total : 0}/> 
												// <CommonReactTable showPag={true} defaultPageSize={10} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} resizable={false} exportCSV={true} csvFile={"Customer Lists"} csvData={this.state.export_data !== undefined ? this.state.export_data : []} searchData={[{...this.state.searchBorrower,title:'Search Customer'}]} filter_data = {this.pagination} pagination = {true} page_count = {this.state.page_count} handleExport = {this.handleExportCSV} csv_total_data = {this.state.total !== undefined ? this.state.total : 0}/> 
												}
									</div> }
									
                    			</div>
								
								
							</>
						  }		
						  <div style={{display:this.state.loader ?'':'none'}}>
										<div style={{marginTop:"15%"}} > <p className='text-light mt-3 text-center'>Loading...</p>
                        					<div className="product_takeup_loader"></div>
										</div>
									</div>				  						  
					  </div>
					  <Modal className={this.state.audit_modal ?'audit_modal' :""} show={this.state.modalBox || this.state.audit_modal} onHide={()=>this.handleModalClose()} size={ this.state.audit_modal ? "" :"xl"}>
						  <Modal.Header className={this.state.audit_modal ?'py-3':""} closeButton show={this.state.modalBox} onHide={()=>this.handleModalClose()}>
							  <Modal.Title> {  this.state.audit_modal ? <b> Audit Info</b> : <>Customer Accounts :  {this.state.cust_id}</>}</Modal.Title>
						  </Modal.Header>
						  <Modal.Body>
							{this.state.audit_modal ? 
							<div>
								<div className='d-flex align-items-center row m-0 mb-4'>
								<p className='text-white m-0 sizem col-4'>Last Audit Date : </p>
								<p className='text-white m-0 sizem col-8'><b>{this.state.audit_data.last_audited_date ? moment(this.state.audit_data.last_audited_date).format('DD MMM YYYY'):"-"}</b></p>
								</div>
								<div className='d-flex align-items-center row m-0'>
									<p className='text-white m-0 col-4 sizem'> Audited By : </p>
								<p className='text-white m-0 col-8 sizem'> <b>{this.state.audit_data.audited_by ? this.state.audit_data.audited_by.first_name +" " + this.state.audit_data.audited_by.last_name:"-"}</b></p>
								</div>
							</div>
							:
							  <AccountContainer entity="customer" dp_cust_id={this.acc_number}
												cust_name={this.person_name} 
												entity_id={this.state.cust_id}/>
						}
						  </Modal.Body>
						  <Modal.Footer>
							  <Button variant="secondary" onClick={()=>this.handleModalClose()}>Close</Button>
						  </Modal.Footer>
					  </Modal>
				  </div>
			  </div>
			  }
          </div>


	);
}
}
export default BorrowerSearchContainer;
