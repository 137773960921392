import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import {Form, Col , Button ,Checkbox} from 'react-bootstrap';
import DatePicker from 'react-datetime';
import moment from 'moment';
import { format } from 'date-fns';
import { event } from 'jquery';



class Assign extends FlowComponent{



    render(){
            
        const cur_month = this.props.cur_month
        return(
            <div className="col-md-2" style={{paddingBottom:15}}>
			    	<Form.Label>Month & Year </Form.Label>
			    	<DatePicker id="date" closeOnSelect={true} dateFormat="MMM YYYY" timeFormat={false} required="required" onChange={(value) => {this.handleDateChange("past_month", value)} } maxMonth = {cur_month}/>
			    </div>
                )
    
    }
    

}
export default Assign;