// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";
import { onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCNKNIOH35C-SGW-jkwoP_ngvuKVa_YNok",
  authDomain: "flow-fb-msgr.firebaseapp.com",
  projectId: "flow-fb-msgr",
  storageBucket: "flow-fb-msgr.appspot.com",
  messagingSenderId: "166107630135",
  appId: "1:166107630135:web:0b5d4569ee2fea038c0db2",
  measurementId: "G-L0R5YBF3E8"
};




// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const messaging = getMessaging(app);


const register_service_worker = () => {
  return new Promise((resolve, reject) => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        if (registrations) {
          const matchingRegistration = registrations.find(registration => {
            return registration.active && registration.active.scriptURL.endsWith('firebase-messaging-sw.js');
          });
  
          if (matchingRegistration) {
            // console.log('Service worker already registered:', matchingRegistration);
            resolve(matchingRegistration);
          } 
          else{
            window.navigator.serviceWorker.register('../firebase-messaging-sw.js').then((serviceWorker) => {
              resolve(navigator.serviceWorker.ready);
            }).catch((error) => {
              reject(error);
              console.log(error);
            });

          }
        } 
        else {
          window.navigator.serviceWorker.register('../firebase-messaging-sw.js').then((serviceWorker) => {
            resolve(navigator.serviceWorker.ready);
          }).catch((error) => {
            reject(error);
            console.log(error);
          });
        }
      }).catch(error => {
        reject(error);
        console.log(error);
      });
    } else {
      reject('Service workers are not supported in this browser.');
    }
  });
};

export const get_notification_permission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const serviceWorker = await register_service_worker();
      if (serviceWorker) {
        // console.log(serviceWorker, 'service');
        const token = await getToken(messaging, {
          // serviceWorkerRegistration: serviceWorker,
          vapidKey: process.env.REACT_APP_FCM_VAPID_KEY
        });
        if (token) {
          // console.log(token);
          // alert(token);
          localStorage.setItem('fcmToken',token)
        } else {
          console.log('no token');
        }
      }
    }
  } catch (error) {
    console.error(error);
    alert(error.message);
  }
};