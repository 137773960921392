import React from "react";
import DatePicker from 'react-datetime';
import FlowComponent from '../../common/component/core/FlowComponent';
import Accordion from 'react-bootstrap/Accordion';
import {Card} from 'react-bootstrap'
import {BsChevronDown,BsTrash} from 'react-icons/bs';
import {GoVerified }from 'react-icons/go';
import { BasicReactTable } from '../../../helpers/react_table'
import  {Homeacc_prvdrLogoselector,get_dir_path,capitalize_first_letter,multi_acc_pur_tostring} from '../../../helpers/common_helper';
import {get_pending_list,update_lead,get_data,retrieve_name,submit_handler,submit_holder_name_proof,verified_by_audit} from '../../../actions/lead_actions';
import { get } from '../../../helpers/storage_helper';
import {dd_value,dd} from '../../../actions/common_actions';
import Modal from 'react-bootstrap/Modal';
import DisplayImage from '../../common/component/DisplayImage';
import { MdEdit } from 'react-icons/md';
import Tooltip from 'react-bootstrap/Tooltip';
import { BiPhoneCall,BiCheck} from "react-icons/bi";
import  FileUploadComponent from '../container/FileUploadComponent';
import moment from "moment";
import { listAccountProvider } from '../../../actions/account_provider_actions';



class NewAccountVerification  extends FlowComponent {
  constructor() {
    super();

   this.state={
    lead:{},
    show_table:false,
    column_data:[],
    row_data:[],
    modal_status:false,
    index:null,
    seconds:[],
    timer:[0,0,0,0],
    get_data_value:[],
    retrieve_button_status:true,
    name_match_reason:null,
    holder_name:null,
    loader:false,
    time: [],
    edit_status:false,
    approve_btn:'',
    reject_btn:'',
    reassign_modal:false ,
    retrieve_holder_name:true ,
    lists:[]
   }
   this.national_id_details=["first_name","last_name","national_id","gender","dob"]
}


componentWillMount(){
  this.get_pending_list_data();
  this.setState({column_data:this.columndatas()});
  

}
get_verified_by_datas(index,data,addl_key=null){
  var req = {country_code: get('market').country_code,"id": data.id};		
  verified_by_audit(this.req(req))
  .then((response)=>{
    if(!response){return}
    if(response.status=="success"){
      this.verificationModal(index,data) 
    }
  })
}

get_acc_prvdr_lists(){
  const ls_market = get('market')
		var req = {country_code: ls_market.country_code, time_zone: ls_market.time_zone, status: ""};		
		listAccountProvider({...req, status: 'enabled', biz_account: true})
		.then((response)=>{					
			if(!response){return };
			if(response.status === "success"){
				let acc_prvdrs = []            
				response.data.list.map(each=>
				acc_prvdrs.push(each.acc_prvdr_code)
				)  
				this.setState({lists:acc_prvdrs},()=>{this.select_acc_prvdr("ALL",acc_prvdrs)}) 				          
			}
		});
}

 renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Simple tooltip
  </Tooltip>
);


 columndatas () {
  const columns = [
    {
      Header : <div className='text-center'>Type</div>,

      maxWidth:150,
      width:150,
      accessor: "type", 
      show:true, 

      Cell : cell => {return <><div className='text-center'>{"New Account"}</div></>},    

  },
    {
      Header : "Account Number",
      id: "acc_prvdr_code",
      accessor: "acc_prvdr_code",  
      Cell : cell => {return <div className="d-flex align-items-center">   <Homeacc_prvdrLogoselector logotype={cell.row.original.entity_json.acc_prvdr_code} /> 
      <div className='ml-2'>{cell.row.original.entity_json.acc_number }</div></div>}    ,
             
   },
    
    {
        Header : <div className='text-center'>Mobile Number</div>,
        
        accessor: "mobile_num",   
        maxWidth:120, 
        
        Cell : cell => {return <><div className='text-center'>{cell.row.original.entity_json.mobile_num}</div></>},    
    

    },
    
      { 
        
        Header : <div className='text-center'>customer Name</div>,
        id: "lead_name",
        accessor: "lead_name", 

        maxWidth:200,
        width:150,
        show:true,
        Cell : cell => {return <><div className='text-center'>{cell.row.original.entity_json.first_name} {cell.row.original.entity_json.last_name}</div></>},    


    },
    
    {
    
        Header : <div className='text-center'>Account Ownership</div>,
       
        maxWidth:150,
        width:150,
        accessor: "acc_owner_type", 
        show:true,    
        Cell : cell => {return <><div className='text-center'>{dd_value(cell.row.original.entity_json.acc_owner_type,"acc_owner_type")}</div></>},    

    },
    {
    
      Header : <div className='text-center'>Account Holder Name</div>,

      maxWidth:150,
      width:150,
      accessor: "holder_name", 
      show:true,   
      Cell : cell => {return <><div className='text-center'>{cell.row.original.entity_json.holder_name ? cell.row.original.entity_json.holder_name:"-"}</div></>},    

  },
  {
      Header : <div className='text-center'>Reasons for Verification</div>,

      maxWidth:150,
      width:150,
      accessor: "review_reason", 
      show:true, 

      Cell : cell => {return  multi_acc_pur_tostring(cell.row.original.review_reason,"cust_review_reason") },    

  },
  {
    Header : <div className='text-center'>Verified by</div>,

    maxWidth:150,
    width:150,
    accessor: "auditor_name", 
    show:true, 

    Cell : cell => {return <div className="text-white text-center">{cell.row.original.auditor_name?cell.row.original.auditor_name :"-"}</div>},    

},
  {
    
      Header : <div className='text-center'>Action</div>,
      id: "action",
      maxWidth:150,
      width:150,
      accessor: "action", 
      show:true, 
      Cell : cell => {return <div className="d-flex justify-content-center">  
  
      <button type="button" class="btn btn-primary" onClick={(ev)=>{this.get_verified_by_datas(cell.row.index+1,cell.row.original) }}>Action </button>
      
      </div>},    

  },
   
   
    ]
    return columns.filter((column) => column.show !== false);
}

// redirectSelfRegComponent(lead_id){
//   window.location.replace(`/lead/self_registration/${lead_id}`);
// }

 get_pending_list_data (){
    
    get_pending_list (this.req({"country_code" : get('market').country_code, entity:'account'}))
    .then((response)=>{
       if(!response){return}
       if(response.status=="success"){
          const national_id_verify= response.data.hasOwnProperty("national_id_verify") ? response.data.national_id_verify:null
           this.setState({row_data:response.data,national_id_verify,all_data:response.data},()=>{this.get_acc_prvdr_lists()})
       }
    })

  }

 handleToggle  (e) {
  this.setState({show_table:!this.state.show_table}) 
}

 updateLeadJson (value,key_name){
   this.update_lead_handler(value,key_name)
    this.get_data_handler()

}

 update_lead_handler(value,key_name){
  var loader_name= value=="owned" ? "owned_loader":value=="rented/bought" ?"rented/bought_loader":""
  this.setState({[loader_name]:true})
  var request={"key":key_name,"value":value,"id":this.state.row_data[this.state.index-1].entity_id, "entity":this.state.row_data[this.state.index-1].entity,"entity_id":this.state.row_data[this.state.index-1].entity_id,"country_code": get('market').country_code}
    update_lead(this.req(request))
    .then((response)=>{
      if(!response){return}
      if(response.status=="success"){
          this.get_data_handler(null,null,null)
      }
    })
}
 name_mismatch_reason =(value,name=null,loader_status=null)=>{
  // if(name=="acc_owner_type"){
  //   var a=this.state.get_data_value.acc_owner_type=value
  //   this.setState({[name]:value,a})
  // }else{
    this.setState({[name]:value, name_mismatch_loader:loader_status!=null?true:false},()=>{ this.update_lead_handler(value,name) })

  // }

}

 get_data_handler (index_val=null,data=null,addl_key=null,modal_type=null){

  var set_loader= this.state["owned_loader"] ? "owned_loader": this.state["rented/bought_loader"] ? "rented/bought_loader" :''
  var value= index_val!=null ?index_val-1 :this.state.index
  const entity_id = ( this.state.row_data.length>0 && index_val==null && data==null )? this.state.row_data[this.state.index-1].entity_id: data.entity_id
  
  const entity = ( this.state.row_data.length>0 && index_val==null&& data==null )? this.state.row_data[this.state.index-1].entity: data.entity
  this.setState({loader:this.state.name_mismatch_loader ?false: true})
  var request={"id":entity_id,"entity_id": entity_id,"entity":entity,"country_code": get('market').country_code,}
      get_data(this.req(request))
      .then((response)=>{
        if(!response){return}
        if(response.status=="success"){
         const national_id_verify= response.data.hasOwnProperty("national_id_verify") ? response.data.national_id_verify:null
          this.setState({loader:false,modal_status:((modal_type!="verify")&&(modal_type !=null))?false :true,get_data_value:response.data,[set_loader]:false,national_id_verify ,dob_edit:false,gender_edit:false, 
            mismatch_reason:addl_key!=null?"":this.state.mismatch_reason ,franchise_approved:addl_key!=null?true:false , ussd_code:this.get_ussd_code(response.data)},()=>{ if((modal_type ==null)) {this.bttn_status_handler()}})
        }
      })
  }
  
   multiple_acc_pur_tostring (review_reason){

    if(review_reason){
        
        for(var i=0;i<review_reason.length;i++){
            return(
                <span style={{paddingRight:3}} key={i}>{dd_value(review_reason[i])},</span>
            )
        }
    }else{
        return ""
    }
}

 startTimer=(id)=>{
  if (this.state.timer[id] == 0 && this.state.seconds[id] > 0) {

    this.state.timer[id] = setInterval(()=> this.countDown(id), 1000);
    }
}

countDown(id) {
 

  var time=this.state.time,
  time={...time}
  time[id]= this.secondsToTime(this.state.seconds[id])
  let seconds=this.state.seconds
  if( seconds[id] % 5 ==0 || seconds[id]==1){
     if(!this.state.get_data_value.holder_name){
      this.get_data_handler()

     }
  }
  seconds[id]= seconds[id] - 1;
   this.setState({
     time: time,
     seconds: seconds,
   });
   if(seconds[id] == 0) { 
     clearInterval(this.state.timer[id]);
   }
 }

 retrieveNameHandler(id){

  this.setState({retrieve_button_status:false})
  this.startTimer(id)
   var request={"acc_number": (this.state.row_data[this.state.index-1].entity=="account" )? this.state.get_data_value.acc_number :this.state.row_data[this.state.index-1].entity_json.account_num ,
    "category":(this.state.row_data[this.state.index-1].entity=="account" )? "float_switch" :"self_reg_retry", 
    "acc_prvdr_code":this.state.get_data_value.acc_prvdr_code ,
    "lead_id":this.state.row_data[this.state.index-1].entity_id,
     "entity": this.state.row_data[this.state.index-1].entity,
     "country_code": get('market').country_code,}

   retrieve_name(this.req(request))
   .then((response)=>{
       if(!response){return}
       if(response.status=="success"){
        
          
       }
   })
}

 secondsToTime (secs){

  let divisor_for_minutes = secs % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);

  let divisor_for_seconds = divisor_for_minutes % 60;
  let seconds = Math.ceil(divisor_for_seconds);

  let obj = {
  
    "m": minutes,
    "s": seconds
  };
  return obj;
}

 verificationModal=(index_val,data)=>{
  
  this.set_initial_timer(index_val-1)
  

  this.setState({index:index_val,acc_owner_type:data.entity_json.acc_owner_type},()=>{ this.get_data_handler(index_val,data)})
  // setacc_owner_type(row_data[index_val-1].entity_json.acc_owner_type)
 

}

get_ussd_code(data){
  var ussd_code;
  var holder_name_retrieval_ussd_code= get('app_config').holder_name_retrieval_ussd_codes[data.acc_prvdr_code]
  if(holder_name_retrieval_ussd_code){
     ussd_code=holder_name_retrieval_ussd_code.replace(":recipient", (this.state.get_data_value.entity=="account" )? this.state.get_data_value.acc_number :this.state.get_data_value.account_num)
  }else{
    ussd_code="-"
  }
   
    return ussd_code;
}

set_initial_timer(id){
  const { seconds } = this.state;
  const updatedSeconds = [...seconds];  
  updatedSeconds[id] = 45; 

  const { timer } = this.state;
  const updatedtimer = [...timer];  
  updatedtimer[id] = 0; 
  
  this.setState({seconds:updatedSeconds,timer:updatedtimer})
 
}


 modal_handleClose(){
  this.setState({modal_status:false,edit_status:false,retrieve_button_status:true,call_log_text_status:false,name_match_reason:"",get_data_value:[],time:[],seconds:[],acc_owner_type:'',reassign_modal:false,mismatch_reason:"",remarks:''})
}

 submitHandler(type){

  var remarks= (type=="reject"||type=="reassign") ? "remarks":null
  var request={"entity_id":this.state.row_data[this.state.index-1].entity_id,[remarks]:this.state.remarks, "entity": this.state.row_data[this.state.index-1].entity,"country_code": get('market').country_code,"time_zone":get('market').time_zone}
  submit_handler(request,type)
  .then((response)=>{
  if(!response){return}
  if(response.status=="success"){

    if(type!="verify"){
      this.modal_handleClose()
    }else{
      this.setState({modal_status: (type=="verify") ? true :false })
    }
   
    this.get_pending_list_data ()
    this.get_data_handler(null,null,null,type)
    alert(response.message);

  }
})
}
edit_handler(val,type){
  this.setState({[type]:val})
}

call_log_verify_handler(value,key){
  this.setState({verified_call_log:true})
  this.update_lead_handler(value,key)

}


call_log_verification(type=null){
  return(
    <div>
      
        <div>
                    <br/>
                    <div className='row no-margin' >
                      <div className='col-md-12' style={{background:'#c4c7ca',borderRadius:'0px 20px 20px 0px'}}>
                      <div className='row' >
                     <div className='col-md-2 ' style={{background:'#59687c',borderRadius:'0px 20px 20px 0px'}}>
                     <p className='no-margin text-white agreementPersonalDetailstext' style={{padding:`20px 10px`}}> <b>Step 1</b></p>

                     </div>
                     <div className='col-md-7 d-flex justify-centent-end align-self-center'>
                     <p className='no-margin  agreementPersonalDetailstext  no-padding' style={{color:'black'}}> Please dial the number </p>
                     </div>
                  <div className='col-md-3 d-flex justify-centent-end align-self-center'>
                   <p className='no-margin agreementPersonalDetailstext' style={{color:'black'}}> <BiPhoneCall size={25} color={'green'} style={{paddingRight:'5px'}}/><b> {(this.state.get_data_value  )? this.state.get_data_value.mobile_num : "-"}</b> </p>

                  </div>
                     </div>
                      </div>
                    
                    </div>

                  
                    <br/><br/>
                    <div className='row no-margin' >
                      <div className='col-md-12' style={{background:'#c4c7ca',borderRadius:'0px 20px 20px 0px'}}>
                      <div className='row' >
                     <div className='col-md-2 ' style={{background:'#59687c',borderRadius:'0px 20px 20px 0px'}}>
                     <p className='no-margin text-white agreementPersonalDetailstext' style={{padding:`20px 10px`}}> <b>Step 2</b></p>

                     </div>
                     {type=='entirely_different_name' &&
                     <div className='col-md-10 d-flex justify-centent-end align-self-center'>
                     <p className='no-margin  agreementPersonalDetailstext  no-padding' style={{color:'black'}}> Reconfirm the Account Ownership with the customer and take a decision to approve or reject by changing the ownership appropriately</p>
                     
                     </div>
                    }

                    {type=="name_match_false" &&
                    <div className='col-md-10 d-flex justify-centent-end align-self-center'>
                      <div className="row col-12">
                    <p className='no-margin  col-10 agreementPersonalDetailstext  ' style={{color:'black'}}> Confirm the third party’s name with the customer</p>
                     {(this.state.verified_call_log || ( this.state.get_data_value && this.state.get_data_value.call_log_verify) )? 

                       <GoVerified color={"green"} size="25"/>:
                        <a className='no-margin col-md-2 p-0  agreementPersonalDetailstext pointer ' onClick={()=>this.call_log_verify_handler(true,"call_log_verify")}style={{color:'#0051C9',textDecoration: 'underline'}} ><b> I Confirmed</b> </a>
                         }

                    </div>
                    </div>
                    }

                    {type=="name_match_true" &&
                    <>
                    <div className='col-md-10 d-flex justify-centent-end align-self-center'>
                     <p className='no-margin  agreementPersonalDetailstext  no-padding' style={{color:'black'}}> Reconfirm the Account Ownership with the customer and take a decision to approve or reject by changing the ownership appropriately</p>
                     
                     </div>
                    </>
                    }
                    
                    </div>
                    </div>
                    
                    </div>
                   
                      
                       

                    <br/>
                   
                    <br/>

        </div>
    </div>
  )
}

get_national_id_datas(value,type){

  this.setState({
    ...this.state,
    [type]:value
  })
}

sumbit_handler_proof(){
this.setState({call_log_text_status:true})
}

bttn_status_handler(){
  var a="";

if(this.state.get_data_value && this.state.row_data && this.state.index ){

  if((this.state.row_data[this.state.index-1].review_reason.includes("nid_manual_capture") )&& (this.state['national_id_verify']==null) ){
   
    for(var i=0;i<this.national_id_details.length;i++){
      if(this.state.get_data_value.hasOwnProperty(this.national_id_details[i]) && (this.state.get_data_value[this.national_id_details[i]] !=null)){
        a=true;

      }else{
       
        a=false;
        break;
      }
    }
   
  this.setState({national_id_capture:a})

  }


  if(this.state.get_data_value.name_match){
    if((this.state.acc_owner_type=="rented/bought") ||  (this.state.get_data_value.acc_owner_type=="rented/bought")){
      this.setState({approve_btn:true,reject_btn:true})
    }
    if(( (this.state.acc_owner_type=="owned") || (this.state.get_data_value.acc_owner_type=="owned"))){
    
      this.setState({approve_btn:true,reject_btn:true})
    }
  }
  else if(this.state.get_data_value.name_match==false){


    if( (this.state.acc_owner_type=="rented/bought")||(this.state.get_data_value.acc_owner_type=="rented/bought")){

      if(((this.state.mismatch_reason=="entirely_different_name")|| (this.state.get_data_value.mismatch_reason=="entirely_different_name"))){
        this.setState({approve_btn:this.state.get_data_value.mismatch_reason ? true : false, reject_btn:this.state.get_data_value.mismatch_reason ? true : false})
      }else{
        this.setState({approve_btn:this.state.get_data_value.call_log_verify ? true : false, reject_btn:this.state.get_data_value.call_log_verify ? false : true})
      }
    }
    if(((this.state.acc_owner_type=="owned")||(this.state.get_data_value.acc_owner_type=="owned"))){

      if(((this.state.mismatch_reason=="franchise_r_distributor_name")|| (this.state.get_data_value.mismatch_reason=="franchise_r_distributor_name"))){
        this.setState({approve_btn:(this.state.franchise_approved) ? true : false, reject_btn:this.state.mismatch_reason ? true : false})

      }else{
        this.setState({approve_btn:this.state.mismatch_reason ? true : false, reject_btn:this.state.mismatch_reason ? true : false})

      }
     }

  }
}


}

reassign_modal_handleClose(e){
  this.setState({remarks:'',reassign_modal:false})
}
reassign_reason(type){
  this.setState({reassign_modal:true,modal_type:type})
}

spinner(){
  return(
    <div class="d-flex col-lg-10 py-2 px-5 pb-5 justify-content-center ml-5">
    <div class="spinner-border text-light" role="status">
    <span class="sr-only">Loading...</span>
        </div>
    </div>
  )
}

try_again_to_name_retrieve(id){
  const { seconds } = this.state;
  const updatedSeconds = [...seconds];  
  updatedSeconds[id] = 45; 

  const { timer } = this.state;
  const updatedtimer = [...timer];  
  updatedtimer[id] = 0; 
  
  this.setState({seconds:updatedSeconds,timer:updatedtimer},()=>this.retrieveNameHandler(id))

}

  setAuditLeadState = (event) => {
    this.setChangeToState(event)
   }

   remarks_handler(value){
    this.setState({remarks:value})
   }

  

  remove_acc_holderproof(){
    // this.setState({time:[],seconds:[]})
    var req={"action":"remove" ,"holder_name":this.state.get_data_value.holder_name,"account_holder_name_proof":this.state.get_data_value.account_holder_name_proof,"entity_id":this.state.row_data[this.state.index-1].entity_id,"entity":this.state.row_data[this.state.index-1].entity,"country_code":get('market').country_code}
    submit_holder_name_proof(this.req(req))
    .then((response)=>{
      if(!response){return ""}
      if(response.status=="success"){
        this.setState({acc_holder_removed:true},()=>{this.get_data_handler()})
        
        // this.set_initial_timer(this.state.index-1)
        
      }
    })
  }

  verification_reason(){
    if(this.state.get_data_value){
      if(this.state.get_data_value.review_reason.length>1){
        return(
          <>
            {this.state.get_data_value.review_reason.map((each,idx)=>{

              return(
                  <span style={{paddingRight:3,fontSize:'19px'}} key={idx} className={idx==0 ? "active_reason":""}>   {dd_value(each,"cust_review_reason")} <b> {(this.state.get_data_value.review_reason.length-1== idx)? "":<b style={{fontSize:'26px'}}>| </b>} </b></span>                
              )
            })}
          </>
        )	
      }

      else{
        return  <b style={{fontSize:'16px'}}>{dd_value(this.state.get_data_value.review_reason[0],"cust_review_reason")}</b>
      }
    }
  }
  select_acc_prvdr(current_acc_prvdr=null,acc_prvdr_lists=null){

    if(current_acc_prvdr!=null){
      if(current_acc_prvdr=="ALL"){
        this.setState({row_data:this.state.all_data,active_acc_prvdr:current_acc_prvdr})
      }else{
        const filter_prvdr=this.state.all_data.filter(e=> e.entity_json.acc_prvdr_code==current_acc_prvdr) 
        this.setState({row_data:filter_prvdr,active_acc_prvdr:current_acc_prvdr})
  
      }
     
    }
    if(acc_prvdr_lists!=null){
      var count={}
      acc_prvdr_lists.map((item,idx)=>{
        const filter_prvdr=this.state.all_data.filter(e=> e.entity_json.acc_prvdr_code==item) 
        count[item]=filter_prvdr.length

      })

      this.setState({count:count})
    }
  

  }

    render() {
      return (
        <div className={`${(this.state.modal_status||this.state.reassign_modal) ? "disable_div":''}`}>
        {this.state.modal_status &&
          <Modal
            show={this.state.modal_status}
            onHide={(e)=>this.modal_handleClose(e)}
            backdrop="static"
            keyboard={false}
            size="lg"
            
          >
            <Modal.Header className="py-3" style={{background:'#223b56'}} closeButton>
             {this.state.row_data &&this.state.index && 
              <Modal.Title  className='mb-2'>VERIFICATION <br/> {this.verification_reason()} </Modal.Title>
             }
            </Modal.Header>
          
            <Modal.Body style={{padding:'0 10px 10px'}}>
            
              {(this.state.row_data &&this.state.index && (((this.state['national_id_verify']==null) && (this.state.row_data[this.state.index-1].review_reason.includes("nid_manual_capture") )   )))? 
              <div>
                <p className='m-0 text-white pt-3 mx-3 pl-3' style={{fontSize:'18px'}}> <b>National ID</b> </p>
                <div className="row d-flex py-3 px-3 m-0 justify-content-center">
                <div className="col-6 ">
                  {this.state.get_data_value &&
                <DisplayImage image_path={get_dir_path(this.state.get_data_value.photo_national_id_full_path,this.state.get_data_value.photo_national_id)} style={{height:'100%',width:'100%'}} file_name={this.state.get_data_value.photo_national_id}   saved_file_name={"photo_national_id"}  />
                  }
                </div>
                <div className="col-6 " style={{border:'1px solid gray'}}>
                
                  {this.national_id_details.map((item,idx)=>{return(
                    <div className="my-4 mx-4">
                      <p className="m-0 text-white" style={{fontSize:'16px'}}> {item=="dob" ? "DOB" : capitalize_first_letter(dd_value(item))}</p>
                      {(this.state.get_data_value && this.state.get_data_value[item]) ?
                      <>
                      {item=="dob" ?
                      <>
                      {this.state.gender_edit ? 
                      <DatePicker id="date" closeOnSelect={true}   dateFormat="DD MMM YYYY" timeFormat={false} required="required" onChange={(value) => {this.name_mismatch_reason(moment(value, 'YYYY-MM-DD').format("YYYY-MM-DD"),"dob")} } />
                      :
                      <div className="row " >
                      <p className="m-0 pt-2 text-white col-8" style={{fontSize:'16px'}}> <b>{ moment(this.state.get_data_value[item]).format("DD MMM YYYY")} </b> <MdEdit onClick={()=>{this.edit_handler(true,"gender_edit")}} className="col-4 pointer" size={15} color="white"/></p>
                      </div>
                      }
                      </>
                      :
                      <>
                      {item=="gender" ?
                      
                      <>{  this.state.dob_edit? 
                        <div className="d-flex align-items-center mt-3 row">
                        <div className="d-flex align-items-center  col-4">
                        <input type="radio" className="pointer" id="male" name="male" value="male" onChange={(e)=>{this.name_mismatch_reason(e.target.value,"gender")}} style={{height:'18px',width: '21px'}}/>
                          <label className="m-0 pointer" for="male" style={{fontSize:'15px'}}>Male</label>
                        </div>
                        <div className="d-flex align-items-center col-4  ml-2">
                        <input type="radio" className="pointer" id="female" name="female" value="female" onChange={(e)=>{this.name_mismatch_reason(e.target.value,"gender")}} style={{height:'18px',width: '21px'}}/>
                          <label className="m-0 pointer"  for="female" style={{fontSize:'15px'}}> Female</label>
                        </div>
                      </div>
                      :
                      <div className="row d-flex pt-2 align-items-center" >
                      <p className="m-0  col-3 text-white" style={{fontSize:'16px'}}> <b>{ (this.state.get_data_value && this.state.get_data_value[item]  )?capitalize_first_letter(this.state.get_data_value[item]):"-"}</b> </p>
                      <MdEdit onClick={()=>{this.edit_handler(true,"dob_edit")}} className="col-4 pl-4 pointer" size={15} color="white"/>
                        </div> 
                        
                        }
                      </>
                      :
                        <p className="m-0 pt-2 text-white" style={{fontSize:'16px'}}> <b>{ (this.state.get_data_value && this.state.get_data_value[item]  )?capitalize_first_letter(this.state.get_data_value[item]):"-"}</b> </p>
                      } 
                      </>
                      }
                      </>
                      :
                      <>
                      {item=="gender" &&
                      <>
                      <div className="d-flex align-items-center mt-3 row">
                          <div className="d-flex align-items-center  col-4">
                          <input type="radio" id="male" className="pointer" name="male" value="male" onChange={(e)=>{this.name_mismatch_reason(e.target.value,"gender")}} style={{height:'18px',width: '21px'}}/>
                          <label className="m-0 pointer" for="male" style={{fontSize:'15px'}}>Male</label>
                          </div>
                          <div className="d-flex align-items-center col-4  ml-2">
                          <input type="radio" className="pointer" id="female" name="female" value="female" onChange={(e)=>{this.name_mismatch_reason(e.target.value,"gender")}} style={{height:'18px',width: '21px'}}/>
                          <label className="m-0 pointer"  for="female" style={{fontSize:'15px'}}> Female</label>
                          </div>
                      </div>
                      </>
                      }
                      {item=="dob" &&
                        <DatePicker id="date" closeOnSelect={true}   dateFormat="DD MMM YYYY" timeFormat={false} required="required" onChange={(value) => {this.name_mismatch_reason(moment(value, 'YYYY-MM-DD').format("YYYY-MM-DD"),"dob")} } />
                      }
                      {item !="dob" &item!="gender" &&
                        <input type="text"  className=" col-12 py-2 form-control" style={{fontSize:'16px'}}  autoComplete="off" onChange={(e)=>{this.get_national_id_datas(e.target.value,item)}}/>
                      }
                      </>
                      }
                    </div>
                  )})}
                 
                </div>

                </div>
                
                <div >

                      <div className='col-lg-12 p-3 d-flex my-3' style={{background:'#fe9f9f'}}>
                      <p className='no-margin pl-3' style={{color:'black',fontSize:'14px'}}> <b className='pr-2' style={{color:'red'}}>NOTE : </b> The name entered by the customer manually is used in the customer agreement. So, verify if the name that you see on the right side matches with the Photo ID proof on the left side.</p>
                      </div>

                  { this.state.loader ? this.spinner():
                  <div className={this.state.national_id_capture==false ? 'disable_div':""}>
                  <div className='d-flex mx-3  align-items-center'>
                      <input type="checkbox" id="vehicle4" className='pointer'   style={{height:'19px',width:'20px'}} name="vehicle4" value="Bike" checked={ this.state.get_data_value && this.state.get_data_value['matches_with_customer'] ? this.state.get_data_value['matches_with_customer'] :false } onChange={(e)=>this.update_lead_handler(e.target.checked,"matches_with_customer")}/>
                      <label className='no-margin pl-3 audit_kyc_text pointer'  for="vehicle4">The details entered by the customer matches with the National ID.</label><br/><br/>
                  </div>

                  
                  </div>
                  }
                
                </div>

                {this.state.get_data_value  &&
                      <div className={`col-12 d-flex align-items-center my-3 mt-3 justify-content-center  `}>
                      <div><button type="button" class="btn btn-success btn-lg" disabled={ (this.state.get_data_value && this.state.get_data_value['matches_with_customer'] &&(this.state.national_id_capture==true)  )?false:true  } onClick={()=>this.submitHandler("verify")}>Verify</button></div>
                      <div><button type="button" class=" ml-3 btn btn-danger btn-lg" disabled={(this.state.get_data_value && this.state.get_data_value['matches_with_customer'] &&(this.state.national_id_capture) )? true:false }  onClick={()=>this.reassign_reason("reject")}>Reject</button></div>
                      <div><button type="button" class=" ml-3 btn btn-danger btn-lg" disabled={(this.state.get_data_value && this.state.get_data_value['matches_with_customer'] && ( (this.state.national_id_capture)) )?true:false }  onClick={()=>this.reassign_reason("reassign")}>Reassign</button></div>

                      </div>
                }
                
              </div>
              :
              <>
                {((this.state.index !=null )&&this.state.row_data) &&
                <div>
                  <div className='row m-0 pt-4'>
                      <div className='col-8'>
                          <p className='m-0 pb-2 text-white'>CUSTOMER'S NAME</p>
                        
                        <p  style={{fontSize:'20px',color:`${(this.state.get_data_value&&((this.state.get_data_value.acc_owner_type=="owned")&& (this.state.get_data_value.name_match)))?"green" :"white"}`}}><b>{this.state.row_data[this.state.index-1].entity_json.first_name} {this.state.row_data[this.state.index-1].entity_json.last_name}</b> </p>
                        
                      </div>
                      <div className='col-4'>
                          <div className='d-flex justify-content-end'>
                              <img className="img-fluid" style={{width:'65px',height:'32px'}} src={`/img/${this.state.row_data[this.state.index-1].entity_json.acc_prvdr_code}_logo_color.png`}></img>
                          </div>
                          {this.state.get_data_value &&
                          <div className='d-flex justify-content-end'>
                          <p className='m-0 text-white'>{(this.state.row_data[this.state.index-1].entity=="account" )?this.state.get_data_value.acc_number :this.state.get_data_value.account_num}</p>
                          </div>
                          }
                      </div>

                      <div className='col-12 mt-4'>
                      <p className='m-0 text-white'>ACCOUNT OWNERSHIP</p>
                      <div className={`d-flex row mt-3 justify-content-center align-items-center ${ (((this.state.mismatch_reason != "entirely_different_name")||(this.state.get_data_value.mismatch_reason != "entirely_different_name") ) && (this.state.get_data_value.review_reason[0]=="name_not_confirmed" ) && ((this.state.acc_owner_type=="owned")||(this.state.get_data_value.acc_owner_type=="owned")))? "disable_div":"" }`}>
                          <div className={`col-3 px-2 mr-4 py-3 pointer ${( ((this.state.acc_owner_type ?this.state.acc_owner_type:this.state.get_data_value.acc_owner_type )=="owned")) ?'self_registration_acc_ownertype' :''}`}   onClick={()=>{this.name_mismatch_reason("owned","acc_owner_type")}} style={{border:'1px solid white',borderRadius:'20px'}}>
                                <p className='m-0 text-center d-flex align-items-center justify-content-center text-white'>  Owned</p> 
                          </div>
                          <div className={`col-3 ml-5 px-2 py-3 pointer ${( ((this.state.acc_owner_type ?this.state.acc_owner_type:this.state.get_data_value.acc_owner_type )=="rented/bought") ) ?'self_registration_acc_ownertype' :''}`}  onClick={()=>{this.name_mismatch_reason("rented/bought","acc_owner_type")}} style={{border:'1px solid white',borderRadius:'20px'}}>
                                  <p className='m-0 text-center  d-flex align-items-center justify-content-center text-white'> Rented/Bought</p> 
                          </div>

                      </div>
                      </div>

                     {
                      <div className='col-12 mt-4'>

                      {this.state.get_data_value && this.state.get_data_value.holder_name  ? 
                        
                      <div className="py-3 px-3 pb-5" style={{border:'1px solid gray'}}>
                        <p className='m-0 text-white pb-3'>ACCOUNT HOLDER NAME</p>
                      
                      { this.state.loader?this.spinner(): <>

                        <p  className= "m-0" style={{color:`${this.state.get_data_value.name_match ? "green" : "white"}`,fontSize:'20px'}}> <b>{this.state.get_data_value.holder_name}</b></p>

                        { (this.state.get_data_value.hasOwnProperty('holder_name_proof')) &&
                        <div className="d-flex align-items-center row" >
                          <div className='ml-2 my-2 pointer col-2'>
                          <DisplayImage image_path={get_dir_path(this.state.get_data_value.holder_name_proof_path,this.state.get_data_value.holder_name_proof)} style={{height: '50px',width:'90px'}} file_name={this.state.get_data_value.holder_name_proof} />
                          </div>
                          {(this.state.get_data_value.name_match==false) &&
                          <div className="col-1 p-0 pointer" onClick={()=>{this.remove_acc_holderproof()}}>
                          <BsTrash color="red" size="25" />
                          </div>
                         }
                          </div>
                        }

                        { (((this.state.get_data_value.acc_owner_type =="rented/bought")) && (this.state.get_data_value.name_match)) &&
                        <>
                      <div className='col-lg-12 p-3 d-flex my-3' style={{background:'#fe9f9f'}}>
                      <p className='no-margin pl-3' style={{color:'black',fontSize:'14px'}}> <b className='pr-2' style={{color:'red'}}>Note</b>  The customer’s name and the account owner name are the same.Its indicated that the account is “Rented/Bought”. Please call the customer and verify the ownership of the account.</p>
                      </div>
                      <div className="col-12">
                      { this.call_log_verification("name_match_true")}

                      </div>
                        </>
                        }

                    

                      {(this.state.get_data_value.name_match==false )&&
                      <div className='col-12 p-0 mt-2 mx-0 row d-flex align-items-center'>
                      {( ((this.state.get_data_value.acc_owner_type =="owned" || this.state.get_data_value.acc_owner_type =="rented/bought")) && (this.state.get_data_value.name_match==false  )) &&
                      
                      <div className="col-12 p-0">
                      {((this.state.mismatch_reason == "entirely_different_name" )) ?
                        <div  className='col-lg-12 p-3 my-3' style={{background:'#fe9f9f'}}>
                        <p className='no-margin pl-3' style={{color:'black',fontSize:'14px'}}> <b className='pr-2' style={{color:'red'}}>Note</b>  The customer’s name and the account owner name are entirely different. Its indicated that the account is owned. Please call the customer and verify the ownership of the account. </p>
                        </div> :
                        <>
                        {this.state.mismatch_reason!="franchise_r_distributor_name"  && (this.state.get_data_value.acc_owner_type =="owned")&&
                      <div className='col-lg-12 p-3 d-flex my-3' style={{background:'#fe9f9f'}}>
                        <p className='no-margin pl-3' style={{color:'black',fontSize:'14px'}}> <b className='pr-2' style={{color:'red'}}>Note</b>    The name of the customer doesn’t match with the customer name. </p>
                      </div>}
                      </>
                      }
                      </div>
                      }

                        { (this.state.get_data_value.name_match==false) && (this.state.get_data_value.acc_owner_type=="owned") &&
                     <div className="col-12 p-0 row d-flex align-items-center">
                      <div className='col-4'> <p className='text-white m-0'> NAME MISMATCH REASON</p></div>
                      <div className='col-8'> 
                      <select id="holder_name_mismatch_reason text-white" className = "form-control col-md-12 col-lg-12"  value={(this.state.mismatch_reason )? (this.state.mismatch_reason ) : ""} type="text" required="required" onChange={(e)=>this.name_mismatch_reason(e.target.value,"mismatch_reason","loader_false")} >
                          {this.getSelectOptions("self_reg_name_mismatch_reason")}
                      </select>
                      </div>
                      </div>
                      }
                      {(this.state.mismatch_reason=="franchise_r_distributor_name")&& (this.state.get_data_value.acc_owner_type=="owned") &&
                      <div className="col-12 p-0 mt-3">
                          <div className='col-lg-12 p-3 my-3' style={{background:'#fe9f9f'}}>
                      <p className='no-margin pl-3' style={{color:'black',fontSize:'14px'}}> Here upload the Photo of mobile screen showing that the account number <b>{this.state.get_data_value.account_num}</b> is registered in the name <b>{this.state.get_data_value.first_name} {this.state.get_data_value.last_name}</b>  </p>

                      {/* <p className='no-margin pl-3' style={{color:'black',fontSize:'14px'}}> <b className='pr-2' style={{color:'red'}}>Note</b>                    If the verfication sms is shown on to mobile screen views please merge them into a single image using any photo editor software and upload. </p> */}
                      </div>
                      <FileUploadComponent  entity={"leads"}  entity_id={this.state.get_data_value.entity_id} lead_id = {this.state.get_data_value.entity_id} name_mismatch_reason={(a,b)=>this.name_mismatch_reason(a,b)} call_log_verification={()=>{this.call_log_verification()}} data={this.state} get_data_handler={(a,b,c,d)=>{this.get_data_handler(a,b,c,d)}} id={"lead"} />
                    
                    
                    
                    
                      </div>

                      }
                        


                      {
                        <div className="col-12">
                      {(this.state.get_data_value.name_match==false)?

                        ((((this.state.mismatch_reason == "entirely_different_name")))) ?
                        <>{  this.call_log_verification("entirely_different_name")}</>
                        :
                        <>
                        {( ( (this.state.get_data_value.acc_owner_type=="rented/bought"))) &&
                        
                        <>
                        { this.call_log_verification("name_match_false")}
                        </>
                        }
                        </>
                        
                        :""}
                      
                        </div>
                      
                      }

                    
                    
                      

                      

                      </div>
                      }

                      </>}

                      </div>
                      
                      :
                      <>
                      {(get('app_config').holder_name_retrieval_ussd_codes[this.state.get_data_value.acc_prvdr_code]) ?
                      <>
                    
                      {(this.state.get_data_value && this.state.seconds[this.state.index-1]>=0  && this.state.retrieve_button_status) ?
                      <>
                      
                        <button type="button" class="btn btn-primary btn-lg mt-2" onClick={()=>{this.retrieveNameHandler(this.state.index-1)}}>Retrieve Name</button>
          
                      </>
                      :  
                      <div className="py-3 px-3" style={{border:'1px solid gray'}}>
                        <p className='m-0 text-white pb-3'>ACCOUNT HOLDER NAME</p>

                      {this.state.seconds[this.state.index-1]==0 ? 
                      <>
                      <div >
                      <button type="button" class="btn btn-primary btn-lg mt-2" onClick={()=>{this.try_again_to_name_retrieve(this.state.index-1)}}>Retrieve Name</button>
                      </div>
                      {this.state.get_data_value &&
                      <div className='col-lg-12 p-3 mt-3' style={{background:'#fe9f9f'}}>
                      <p className='no-margin pl-3' style={{color:'black',fontSize:'14px'}}> <b style={{color:'red'}}>NOTE:</b>  Unable to retrieve the name </p>
                      <p className='no-margin pl-3' style={{color:'black',fontSize:'14px'}}>  1. Try again to retrieve the name by using the <b>Retrieve Name</b> Button.</p>
                        <p  className='text-center m-0'><b className='text-center' style={{color:'black'}}>(OR)</b></p>
                      <p className='no-margin pl-3' style={{color:'black',fontSize:'14px'}}>  2.Please retrieve the name manually using USSD code (<b>{this.state.ussd_code?this.state.ussd_code:"-"}</b>). Once retrieved on your phone using the above USSD, take a screenshot and upload here. </p>
                      </div>
                      }
                        <div className='col-12 p-0 my-3 mt-4'>
                        <FileUploadComponent entity={this.state.get_data_value.entity}  entity_id={ this.state.get_data_value.entity_id} lead_id = {this.state.get_data_value.entity_id} name_mismatch_reason={(a,b)=>this.name_mismatch_reason(a,b)} call_log_verification={()=>{this.call_log_verification()}} data={this.state} get_data_handler={(a,b,c,d)=>{this.get_data_handler(a,b,c,d)}} id={"lead"} />
                        
                        </div>
                      
                
                            
                      </>

                      :
                        this.spinner()
                      }
                      </div>
                      }
                      </>
                      :
                      <div className="py-3 px-3" style={{border:'1px solid gray'}}>
                      <p className='m-0 text-white pb-3'>ACCOUNT HOLDER NAME</p>
                     <>
                    {this.state.get_data_value &&
                    <div className='col-lg-12 p-3 mt-3' style={{background:'#fe9f9f'}}>
                    <p className='no-margin pl-3' style={{color:'black',fontSize:'14px'}}> <b style={{color:'red'}}>NOTE:</b>  Unable to retrieve the name </p>
                    </div>
                    }
                      <div className='col-12 p-0 my-3 mt-4'>
                      <FileUploadComponent entity={this.state.get_data_value.entity}  entity_id={ this.state.get_data_value.entity_id} lead_id = {this.state.get_data_value.entity_id} name_mismatch_reason={(a,b)=>this.name_mismatch_reason(a,b)} call_log_verification={()=>{this.call_log_verification()}} data={this.state} get_data_handler={(a,b,c,d)=>{this.get_data_handler(a,b,c,d)}} id={"lead"} />
                      
                      </div>
                     
                    </>

                    </div>
                      
                      }
                      </>
                      }
                      </div>
                    }
                      {this.state.get_data_value && this.state.get_data_value.holder_name  && 
                      <div className='col-12 d-flex align-items-center my-3 mt-5 justify-content-center '>
                      <div><button type="button" class="btn btn-success btn-lg" disabled={this.state.approve_btn?false:true} onClick={()=>this.submitHandler("approve")}>Approve </button></div>
                      <div><button type="button" class=" ml-3 btn btn-danger btn-lg" disabled={this.state.reject_btn?false:true}   onClick={()=>this.reassign_reason("reject")}>Reject</button></div>


                      </div>
                      }
                  </div>
                </div>
                }
              </>
              }
              
            </Modal.Body>
          
          </Modal>
        }
        {this.state.reassign_modal &&
          <Modal show={this.state.reassign_modal } onHide={(e)=>this.reassign_modal_handleClose(e)}  backdrop="static"
          keyboard={false} >
          <Modal.Header closeButton>
            <Modal.Title>Reason</Modal.Title>
          </Modal.Header>
          <Modal.Body> 
            <div className="row d-flex align-items-center m-0">
              <div className="col-4">
                <p className="m-0 text-white"> Remarks</p>
              </div>
              <div className="col-8">
              <textarea type="text" id="remarks" className="form-control" onChange={(e)=>{this.remarks_handler(e.target.value)}}  maxLength='256'/>

                </div>
            </div>

            <div className="my-4">
              {this.state.modal_type=="reject" &&
              <div className="d-flex justify-content-center"><button type="button" class=" ml-3 btn btn-danger btn-lg" disabled={this.state.remarks?false:true }  onClick={()=>this.submitHandler("reject")}>Reject</button></div>
              }

              {this.state.modal_type=="reassign" &&
            <div className="d-flex justify-content-center"><button type="button" class=" ml-3 btn btn-danger btn-lg" disabled={this.state.remarks?false:true } onClick={()=>this.submitHandler("reassign")}>Reassign</button></div>
          }
          
            </div>
          </Modal.Body>
        
          </Modal>
        }

         	<Accordion>
					<Card>

						<div className="card-header card-header-icon card-header-primary home_header_d-flex_at_response justify-space-between"> 

									
										<>
                    <div className='home_header_d-flex_at_response'>
												<h4 className="card-title text-white tableHead_font ">New Account Verification</h4>
												{this.state.lists.length > 0 &&
													<>
														<div className='countREL card-title'>
															<button className={'filterBtns allBtn_size'} onClick={()=>this.select_acc_prvdr("ALL")} style={{filter:`${this.state.active_acc_prvdr!="ALL" ? "opacity(0.5)" :""}`}}>ALL</button>
															<div className='countABS' style={{backgroundColor : `${this.state.active_acc_prvdr !="ALL" ? "lightgrey" :""}`}} >
																<label style={{color:'black'}}>{this.state.row_data.length}</label>
															</div>
														</div>
														{this.state.lists.map((each,idx)=>
															<div key={idx} className='countREL card-title'>
																<>
																	<div style={{width:60,height:30}} className="filterBtns"  onClick={()=>this.select_acc_prvdr(each)}>
																		<img className='img-fluid' src={`/img/${each}_logo_color.png`} style={{filter:`${((this.state.active_acc_prvdr !=each )&& (this.state.active_acc_prvdr !="ALL" ))? "opacity(0.5)" : ""}`}} ></img>
																	</div>
																	<div className='countABS' style={{backgroundColor : `${((this.state.active_acc_prvdr !=each )&& (this.state.active_acc_prvdr !="ALL" )) ? "lightgrey" :""}`}}>
																		<label>{this.state.count ? this.state.count[each]:0}</label>
																	</div>
																</>
															</div>
														)}
													</>
												}
											</div>	
																					
											<Accordion.Toggle className = "col" as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("Account")}>
												<BsChevronDown className = {this.state.show_table ? "arrow-icon accordin-arrow down " : "arrow-icon accordin-arrow"} size="18"/>
											</Accordion.Toggle>
										</>

								</div>																
								<div className="card-body no-padding" >	
								<>
									
										<Accordion.Collapse eventKey="0">
											<Card.Body>	
                          <BasicReactTable row_data={this.state.row_data.length > 0?this.state.row_data:[]} column_data={this.state.column_data.length > 0 ? this.state.column_data:[]}  pagination={true}  default_page_size={15} /> 
					
											</Card.Body>
										</Accordion.Collapse>
									
								{/* </div> */}
							{/* </div> */}
												</>
								</div>
					</Card>
				</Accordion>
        </div>
    );
    }
  }
  export default NewAccountVerification