import { get_path } from '../helpers/common_helper';
import {call_fetch, app_path} from '../helpers/fetch_helper';
import { get } from '../helpers/storage_helper';


export const createPerson = request => {
   return call_fetch(get_path('kyc') + '/person/create', request);
}

export const viewPerson = request => {
   return call_fetch(get_path('kyc') + '/person/view', request)
}

export const listPerson = request => {
   return call_fetch(get_path('kyc') + '/person/list', request)
}

export const resetAppUserPassword = request => {
   return call_fetch(get_path('kyc') + '/user/app/password/email', request)
}

export const resetInvUserPassword = request => {
   return call_fetch(get_path('admin') + '/user/inv/password/email', request)
}

export const get_person_email = request =>{
   return call_fetch(app_path + '/common/person/get_user_email', request)
}