import FlowContainer from '../../common/container/core/FlowContainer';
import React from 'react';
import AccountStmtTxns from '../component/AccountStmtTxns';
import '../../../styles/flow.css';
import {Button,Card} from 'react-bootstrap';
import {addAccStmt} from '../../../actions/lender_actions';
import moment from 'moment';
import withRouter from '../../../Router/Withrouter';


class AddAccountStmtTxnsContainer extends FlowContainer {
    state = {
        acc_stmts:{},
        format : false,
    }

    validate_fields(acc_stmts) {
        const validAmountRegex = /^\d*$/;
        if(acc_stmts.account_id && acc_stmts.stmt_txn_id && acc_stmts.amount &&
            acc_stmts.stmt_txn_date && acc_stmts.descr && acc_stmts.stmt_txn_type && acc_stmts.photo_statement_proof && acc_stmts.reason_for_add_txn ){
            
            if (!validAmountRegex.test(acc_stmts.amount)) {
                alert("Please enter a valid amount without special characters")
            }else{
                return true
            }
         
       }else{
         alert("Please enter required fields")
   
         return false
       }
     }
   
     handleSubmit =(event)=>{
           var is_valid = this.validate_fields(this.state.acc_stmts)
           if(is_valid){
            if(!this.state.format){
                alert("Please enter required fields.")
                return;
            }
            this.state.acc_stmts.by_evidence = this.props.location.state ? this.props.location.state : null;
            addAccStmt(this.req({acc_stmts: this.state.acc_stmts}))
               .then((response) => {
                   this.resp(response)
                   if(!response){return };
                   if(response.status === "success"){
                    alert(response.message);  
                    window.location.reload();
                   }
            })
          }
       }

    get_state_value = (acc_stmts) =>{
        if(this.state.acc_stmts != acc_stmts ){
            this.setState({ acc_stmts : acc_stmts})
        }
    }
   handleChange=(event)=>{
        if(event == 'time'){
            this.setState({format : true})
        }
   }

    render()
    {
        return (
            <>
                <div className="container containerTopmargin max-width no-padding">            
                    <form className="mb-3" autocomplete="off">
                        <Card>
                            <Card.Header style = {{backgroundColor: "#01062c" , color: "white"}}><h4>Add Statement Transaction</h4></Card.Header>
                                <Card.Body style={{padding:15}}>
                                    <AccountStmtTxns get_state_value = {this.get_state_value} id="acc_stmts" onChange={this.handleChange} onComponentChange={this.handleComponentChange}
                                        onlist={this.handleTransactionlist} disable_btn = {this.state.calling_api}/>                                
                                    <div className="form-group row reset-margin justify-content-center py-4">
                                        <Button  disabled={this.state.calling_api} className= {`col-md-2 mr-3 btn btn-primary${this.state.submit_btn_style}`} onClick={this.handleSubmit} >Submit</Button>
                                    </div>
                                </Card.Body>
                        </Card>
                    </form>       
                </div>          
            </>
        )
    }
}

export default withRouter(AddAccountStmtTxnsContainer);