import { event } from 'jquery';
import React from 'react';
import DatePicker from 'react-datetime';
import FlowComponent from '../../common/component/core/FlowComponent';
import { BasicReactTable } from '../../../helpers/react_table';
import {raised_complaints,resolved_complaints,view_cust_complaints} from '../../../actions/borrower_actions'
import { Modal, Col,Form} from 'react-bootstrap';
import {capitalize_first_letter} from '../../../helpers/common_helper';
import { Link} from 'react-router-dom';
import {dd_value} from '../../../actions/common_actions';
import moment, { isMoment } from 'moment';
import { GoVerified } from "react-icons/go";


export class ComplaintLists extends FlowComponent{
    state = {
        columndatas:[],
        row_Datas:[],
        modal_view:false,
        modal_view_row_datas:[],
        cust_id:"",
        status:'raised',
        update_table_id:null,
        active_status:false,
        loader:false,
        search_criteria: {}

       
     
    }

    componentWillMount()
    {   
         this.load_flow_rel_mgr();
         this.setState(state => state. columndatas= this. columndatas())  
        //  this.Search_Complaints_handler();
    }

    view_handler(val,complaint_type,index,table_id){
    
       const datas=this.state.row_Datas.filter((each,idx)=>each.cust_id==val && each.complaint_type==complaint_type && idx==index)
       this.setState({modal_view:true ,modal_view_row_datas:datas,cust_id:val,update_table_id:table_id})
       
    }

    view_raised_complaints(){
        var status = this.getSelectOptions("complaint_status").filter((each)=>each.props.children !="choose")
       
        this.setState({complaint_status:status})
        raised_complaints(this.req())
       .then((response)=>{
           if(!response){return}
        if(response.status=="success"){
            var raised_compliants=response.data.filter((each)=>each.status=="raised")
            
           this.setState({row_Datas:raised_compliants,past_complaints_raw_data:response.data})

        }
       })

    }

    resolution_handler(value,type){
        this.setState({[type]:[value]})
    }

   
    
    resolved_submit_handler(table_id ){

        this.setState({update_table_id:table_id})
  
        if(this.state.resolution1 ){  

          var raised_date= moment(this.state.modal_view_row_datas[0].raised_date).format("DD-MM-YYYY")
          
            var request={"cust_id":this.state.modal_view_row_datas[0].cust_id,"complaint_about":this.state.modal_view_row_datas[0].complaint_type,"resolution":this.state.resolution1,"raised_date":raised_date,"remarks":this.state.modal_view_row_datas[0].remarks,"id":this.state.update_table_id!=null ? this.state.update_table_id:table_id}
            resolved_complaints(this.req(request))
            .then((response)=>{
                if(!response){return}
                if(response.status=="success"){
                    alert(response.message);
                    window.location.reload()
                }
            })
        }else{
            alert("please add a resolution")
        }

       

    }
   

    columndatas () {
        return [
              {
                Header : "Customer ID",
                accessor: "cust_id",       
                Cell : cell =>{return <div> <Link to={"/borrower/indiv/view/"+cell.row.original.cust_id} target="_blank" className="flow_custid" >{cell.row.original.cust_id}</Link>  </div>}
            },
            {
              Header : "Business Name",
              id: "biz_name",
            //   maxWidth:150,
            //   width:200, 
              accessor: "biz_name",  
            
              Cell : cell => {return <>{cell.row.original.biz_name}</>}    ,
                     
      
          },
            {
                Header : "Mobile Number",
                id: "mob_number",
                accessor: "mobile_num", 
                Cell : cell => {return <>{cell.row.original.mobile_num}</>}          
            },
            {
                Header : "Complaint About",
                id: "complaint_type",  
                accessor: "complaint_type",   
                // maxWidth:200, 
                // width:200
                Cell : cell => {return <>{cell.row.original.complaint_type == 'ops_admin' ? dd_value(cell.row.original.complaint_type, 'any') : dd_value(cell.row.original.complaint_type, 'complaint_type')}</>}  ,
                        
       
            },
            
            { 
                
                Header : "Raised on Date",
                id: "raised_date",
                accessor: "raised_date",
                Cell : cell => {return <>{ moment(cell.row.original.raised_date).format("DD-MMM-YYYY")}</>},
            },   

            { 
                
                Header :"Status" ,
                id: "status",
                accessor: "status",
                Cell : cell => {return <>{capitalize_first_letter(cell.row.original.status)}</>},
            },   

            

            { 
                
                Header : "Action",
                accessor: "cust_ids",
                Cell : cell => {return <div><button type="button"  data-toggle="modal" data-target="#exampleModal" onClick={()=>this.view_handler(cell.row.original.cust_id,cell.row.original.complaint_type,cell.row.index,cell.row.original.id)} className ={`btn  btn-sm btn-primary` } > View</button></div>}       
            }, 

        ]
    }

    StatusModalClose = () =>{
		this.setState({modal_view:false,cust_id:''})
	}
 

    Search_handler=(val,type,section)=>{
        if(isMoment(val)) val = val.format('YYYY-MM-DD')
        let criteria_state = this.state.search_criteria
        criteria_state[type] = val
        this.setState({search_criteria :criteria_state})
    }

    

    Search_Complaints_handler(){
        

        if(Object.keys(this.state.search_criteria).length > 0){
            this.setState({loader:true})
            var borrower_search_criteria={
                "req_parameter":this.state.search_criteria.cust_id
            }

            
            let criteria = {...this.state.search_criteria}
            delete criteria.cust_id

    
  
            var request={...criteria, "borrower_search_criteria": this.state.search_criteria.cust_id ?  borrower_search_criteria : '' }
            view_cust_complaints(this.req(request))
            .then((res)=>{
                this.setState({loader:false})
                if(!res){return}
                if(res.status=="success"){
                  this.setState({modal_view_row_datas:res.data,row_Datas:res.data,active_status:true, loader:false})
                //    var raised_complaints=res.data.filter((each)=>each.status== this.state.status)
                
                }
            })
            
        }else{
            alert("choose select valid field");
        }
    }

    Reset_Complaints_handler(){
      
        this.setState({search_criteria:{}})
    }

  
	 
    date_handler(val,type){

     var date= moment(val).format("YYYY-MM-DD")
     this.setState({[type]:date})
    }


    render(){
        return (
            <div>
                <div className='container containerTopmargin max-width no-padding' style={{background:'#202940'}} >
                    <h2 className='text-white floatAdvancetitleBox' style={{padding:'10px'}}> Complaints Search</h2>   
                    <div className={`d-flex justify-content-around align-items-center `} style={{padding:'20px'}}>
                    
                      <div className='col-md-6 no-padding-input'>
                             <span className='text-white'>Cust Name / Cust ID / Account Num / Mobile No </span>
                            <input  autocomplete="off"  className="form-control" type="text"  onChange={(e)=>this.Search_handler(e.target.value,"cust_id")}/> 
                       
                           
                       
                        </div>  

                    <div className='col-md-3 no-padding-input'>
                            <span className = "form-label text-white">Status</span>
                                <select id="status" className="form-control" required="required" value={this.state.search_criteria.status} onChange={(event)=>this.Search_handler(event.target.value,"status")} >
                                { this.state.status!="resolved" ?  this.getSelectOptions("complaint_status") : this.getSelectOptions("complaint_status") }
                                </select>
                    </div> 
                    <div className='col-md-3 no-padding-input'>
                            <span className = "form-label text-white">Complaint Type</span>
                                <select id="status" className="form-control" required="required" value={this.state.search_criteria.complaint_type} onChange={(e)=>this.Search_handler(e.target.value,"complaint_type")}  >
                                {this.getSelectOptions("complaint_type")}
                                    
                                </select>
                    </div> 
                    </div>
                    <div className={`d-flex  align-items-left `} style={{padding:'20px'}}>
                    
                      <div className='col-md-3'>
                             <span className='text-white'>Raised From </span>
                             <DatePicker closeOnSelect={true} onChange={(value)=>this.Search_handler(value,"raised_from")}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>                 
                           
                        </div>  
                        <div className='col-md-3'>
                             <span className='text-white'>Raised To </span>
                             <DatePicker closeOnSelect={true} onChange={(value)=>this.Search_handler(value,"raised_to")}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>                 
                           
                        </div>
                    </div>
                    
                    <div className=' d-flex justify-content-center'>
                            
                                
                            <button type="button" class="btn btn-primary btn-lg" onClick={()=>this.Search_Complaints_handler()}>search</button>
                           
                           
                            </div>
                </div>
           

            {this.state.loader?
            <div className='my-5'>
                <p className='text-light  text-center' >Loading...</p>
                <div className="product_takeup_loader"></div>
            </div> : 
             <div style={{marginTop:'4%'}}>
                <div className='d-flex justify-content-between align-items-center'>
                <h2 className='text-white' style={{margin:'20px'}}> Results</h2>
                <p className='no-margin text-white'><b>Total Entries : {this.state.row_Datas.length}</b></p> 
                </div>
         
             <BasicReactTable row_data={this.state.row_Datas} FName={ this.state.status=="raised" ? " Raised_Complaints" :"Resolved_complaints"} column_data={this.state.columndatas}  pagination={true} export_csv={true} csv_file={true}  default_page_size={10} csvData={this.state.row_Datas !== undefined ? this.state.status=="raised"? this.state.row_Datas :this.state.modal_view_row_datas : []} searchData={[{cust_id:this.state.cust_id,status:this.state.status,complaint_about:this.state.complaint_about,title:'Search Complaints'}]} /> 
             </div>}
           
            {this.state.modal_view &&this.state.modal_view_row_datas.length>0 &&
            <>
            
            
             <>
            <Modal show={this.state.modal_view} onHide={this.StatusModalClose} id="exampleModal" > 
   
				<Modal.Header className='pt-2' closeButton show={this.state.modal_view} onHide={this.StatusModalClose} >
					<Modal.Title style={{marginBottom:'15px'}}>Complaints</Modal.Title>
				</Modal.Header>	
                {this.state.modal_view &&
                <>	
                <div  style={{margin:'10px 25px'}}>	
                {this.state.modal_view_row_datas.map((item,idx)=>{ return(
                        <div>
                           
                            
                          {this.state.modal_view_row_datas.length >1 ?  <p className='text-white text-center' style={{marginTop:'10px'}}> <b>Complaint {idx +1}</b></p> :''}
                        <div>
                           <div className='row cust_complaints_resolved'>
                               <div className='col-md-6 complaint_modal_box' >
                                   <div className='d-flex align-items-center'>
                                    <p className='text-white ' > <b> Customer ID</b>  </p> 
                                    <p className='text-white' style={{marginLeft:'30px'}} >{ item.cust_id}</p> 
                                    </div>
                              </div>
                                <div className='col-md-6 complaint_modal_box'>
                                <div className='d-flex row '>
                                    <p className='text-white col-md-3 ' > <b> Biz Name</b> </p> 
                                    <p className='text-white col-md-9 '>{ item.biz_name}</p> 
                                    </div>

                                </div>
                                <div className='col-md-6 complaint_modal_box'>
                                   <div className='d-flex align-items-center'>
                                    <p className='text-white ' > <b> Complaint  About </b> </p> 
                                    <p className='text-white  ' style={{marginLeft:'9px'}} >{ item.complaint_type == 'ops_admin' ?  dd_value(item.complaint_type, 'any') : dd_value(item.complaint_type, 'complaint_type')}</p> 
                                    </div>
                              </div>
                                <div className='col-md-6 complaint_modal_box'>
                                <div className='d-flex row '>
                                    <p className='text-white col-3' > <b> Raised On</b></p> 
                                    <p className='text-white  col-9'>{  moment(item.raised_date).format("DD-MMM-YYYY")}</p> 
                                    </div>

                                </div>

                               
                            </div> 
                            <div className='row complaint_modal_box'>
                                  <div className='col-md-3'>
                                    <p className='text-white '> <b> Remarks </b> </p> 
                                  </div>
                                    <div className='col-md-9' style={{paddingLeft:'10px'}}>
                                    <p className='text-white ' >{item.remarks}</p> 
                                    </div>
                            </div>
                          
                            <div className='row complaint_modal_box d-flex align-items-center' >
                                  <div className='col-md-3'>
                                    <p className='text-white '> <b> Resolution </b></p> 
                                  </div>
                                  
                                    <div className='col-md-9 ' style={{paddingLeft:'10px'}}>
                                    {item.resolution!=null ? 
                                     <p className='text-white '>{item.resolution} </p>
                                      :
                                    <textarea id="w3review" style={{resize:'horizontal',padding:'10px',width:'100%'}}  name="w3review"  rows="4" onChange={(event)=>this.resolution_handler(event.target.value,"resolution1",1)} >
                                    </textarea>
                                         }
                              
                                    </div>
                               
                            </div>
                            <br/>
                             <div className={`${ item.status=="raised" ? "col-md-6" : "col-md-12" }no-padding `}>
                                 {item.status=="raised" ?
                                <div className='d-flex align-items-center no-padding'>
                                    <p className='text-white ' > <b> Status </b> </p> 
                                     <div className='text-center ' style={{margin:'0px 0px 0px 30%'}}>
                                        <button  type="button" className={`btn-primary btn btn-lg `} onClick={()=>this.resolved_submit_handler(item.id)}  >Resolved</button>
                                    </div>
                                </div> 
                                :
                                <div>
                                    <div className='d-flex no-padding'>
                                    <p className='text-white ' > <b> Status </b> </p> 
                                    
                                     <p className='text-white resolved_status'> <b style={{color:'green'}}> Resolved </b> <GoVerified size={23} style={{marginLeft:'10px'}} color={'green'}/> </p>  
                                    
                                     </div> 
                                     {item.resolved_by && <div className='d-flex no-padding'>
                                        <p className='text-white' > <b> Resolved By </b> </p> 
                                    
                                     <p className='text-white' style={{marginLeft:'36px'}}>{item.resolved_by ? item.resolved_by.toUpperCase() : ''}</p>  
                                    
                                     </div> }
                                  
                                </div>
                                }
                                </div>
                                <br/>
                       
                      
                        </div>
                       
                        </div>

                 ) })}
                  
                
                </div>	
               
                </>
                 }
                

        	</Modal>
            </>
                  
           
                   
               
            
            </>
           }
        
            </div>
           
        );
    }
}
export default ComplaintLists;

