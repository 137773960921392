import { } from 'react-router-dom';
import React from 'react';
import '../../../styles/flow.css';
import FlowContainer from '../../common/container/core/FlowContainer';
//import {  } from '../../../actions/master_data_actions';
import MasterData from '../component/MasterData';

class MasterDataContainer extends FlowContainer{

	state = {
		data_keys : "",
		master_data: {}
	}

  render(){
    return (
	 <div id="" >
	 	<div className="container">
	        <MasterData id="master_data" data={this.state.master_data} onComponentChange={this.handleComponentChange}/>
        <br/>
   </div>
    </div>
    );
  }
}

export default MasterDataContainer;

