import React from 'react';
import {borrowerSearch} from '../../../actions/borrower_actions';
import {format_date,Homeacc_prvdrLogoselector,person_name} from '../../../helpers/common_helper';
import {dd_value} from '../../../actions/common_actions';
import { Link} from 'react-router-dom';
import _ from 'lodash';
import FlowContainer from '../../common/container/core/FlowContainer';
import CommonReactTable from '../../../helpers/commonReact_table';
import {TooltipLabel} from "../../common/partial";
import {check_priv, get } from '../../../helpers/storage_helper';
import { sortBizcommon,sortAmtcommon, sortTimecommon} from '../../../helpers/sortingHelpers';
import { custFollowUpTask } from '../../../actions/churn_actions';
import moment from 'moment';
import { getHolidayList } from '../../../actions/lead_actions';
import { Modal } from 'react-bootstrap';
import { FcCalendar } from 'react-icons/fc';
import { Config } from '../../../helpers/storage_helper';
import Select from "react-select";
import {Button} from 'react-bootstrap';
import { BasicReactTable } from '../../../helpers/react_table';
import { setValuesIntoRedux } from '../../../Redux/Slices/CommonSlice';
import { connect } from 'react-redux';
import withRouter from '../../../Router/Withrouter';

class ActiveCustWoFAContainer extends FlowContainer {

    state ={
        cust_wo_fa :{
		},
        columns :[],
        cust_wo_fa_list:[],
        cust_wo_fa_table:"hide_div",
		copy:[],
        cust_follow_up:'',
        reason:'',
        next_follow_up_date:'',
        max_follow_up_days:'',
        market_holidays:[],
        resp_day_for_reason:''

    }
    componentDidUpdate(prevProps,prevState){
        if(prevState.resp_day_for_reason!=this.state.resp_day_for_reason){
            if(this.state.resp_day_for_reason=='NA'){
                this.setState({next_follow_up_date:null})
            }
        }
    }
    sortBiz (a,b) {				        		
		return sortBizcommon(a.props.full_text,b.props.full_text)        
	}
	sortAmount (a,b) { 		   
        return sortAmtcommon(a.props.children,b.props.children)
    }
	sortTime(a,b){
        const currentTime = a.props.data.loan_appl_date
        const prevTime = b.props.data.loan_appl_date
        const timeServer = this.state.server_time        
        return sortTimecommon(currentTime,prevTime,timeServer)		
    }
    
    handleCustFollowUp(){

        var request={
            task_type:'cust_follow_up',
            reason:this.state.reason,
            cust_id:this.state.cust_follow_up.cust_id,
            next_follow_up_date:this.state.next_follow_up_date,
            last_loan_doc_id : this.state.cust_follow_up.last_loan_doc_id
        }
        custFollowUpTask(this.req(request))
        .then((response)=>{
          this.resp(response)
          if(!response)return;
          if(response){
            this.setState({reason:'',next_follow_up_date:null,cust_follow_up:[]})
            alert(response.message)
            this.setState({cust_follow_up_modal:false,reason:'',next_follow_up_date:null})
          }
        }
        )}
    changeDaysForReason=(e)=>{
    
        var reason_with_day = Config('follow_up_intervals').second_follow_up
        this.setState({resp_day_for_reason:reason_with_day[this.state.reason]})
        this.minDateAfter(reason_with_day[this.state.reason])
    }
    minDateAfter(number) {
        if(Number.isInteger(number)){
            const dates = [];
            const today = moment();
            var i=0;
            while(dates.length<=number) {
              const nextDate = moment(today).add(i, 'days');
              if (nextDate.day() !== 0 &&  !this.state.market_holidays.includes(nextDate.format('YYYY-MM-DD'))) {
                dates.push(moment(nextDate).format('YYYY-MM-DD'));
              }
              i++
            }
            this.setState({min_follow_up_date:dates[dates.length-1]},()=>
            this.setState({next_follow_up_date:moment(this.state.min_follow_up_date). format('YYYY-MM-DD')}))
        }
        else{
            this.setState({min_follow_up_date:false})
        }
      }
    changeHandler = (e) => {      
        const toFilter = e
        const filterfrom = this.state.cust_wo_fa_list    
        const filteredData = filterfrom.filter(value => { 
          return(    
          value.cust_id !== null && value.cust_id.toLowerCase().includes(toFilter.toLowerCase())     
          )
        })
        this.setState(state=>state.copy = filteredData)		
      }
        get_holidays=()=>{
            var request={
                days:60
            }
            getHolidayList(this.req(request))
            .then((response)=>{	
                if(!response){return};
                if(response.status == "success"){
                    this.setState({market_holidays:response.data.market_holidays})
                }
                })
        }

    componentWillMount(){
        this.get_holidays()
        this.get_borrowers()
        this.load_flow_rel_mgr(null,"multiselect_rm_name")
    }

    get_borrowers = () => {
        this.setState({loader:true,copy:[],no_data:false})
        let search = {active_cust : 'true', not_have_ongoing_loan : 'true', not_have_overdue_loan : 'true', not_have_pending_loan_appl : 'true', profile_status : 'open', status : 'enabled'}
        if(this.state.flow_rel_mgr_id){
            search = {...search, flow_rel_mgr_id: this.state.flow_rel_mgr_id}
        }
		borrowerSearch(this.req({borrower_search_criteria : search}))
		.then((response) => {
			this.resp(response)
			if(!response){
                this.setState({loader:false,no_data:true}) 
                return };
			if(response.status === "success"){
				this.resp(response)
				var columns = this.state.columns
                const show_risk_category = check_priv('customer' , 'risk_category');
                columns = [
                {
                    Header : "SI No.",
                    id: "row",
                    Cell: (cell) => {
                        return <div>{cell.row.index+1}</div>;
                    },
                    width : 70
                   
                },
                {
                    Header : <p>ACC PRVDR &<br/> ACC Number</p>,
                    id: "acc_prvdr_code",
                    sortable:false,
                    accessor:row =>row,
                    Cell: (cell) => {
                        return <div className='d-flex align-items-center'> <Homeacc_prvdrLogoselector logotype={cell.value.acc_prvdr_code} />
                        <p className='my-0 ml-2'>{cell.value.acc_number}</p></div>;
                    },
                },
                {
                    Header : "FLOW Cust ID",
                    id: "cust_id",
                    sortable:false,
                    accessor:row => <Link to={"/borrower/indiv/view/"+row.cust_id} target="_blank" className="flow_custid" >{row.cust_id}</Link>,
                    width : 150
                },
                {
                    Header : "Category",
                    id: "category",
                    accessor:row => {return row.category}
                },
                {
                    Header : "Business Name",
                    id: "biz_name",
                    sortMethod : (a,b) => this.sortBiz(a,b),
                    accessor:row => <TooltipLabel full_text={row.biz_name} overlay={person_name(row)} />
                },
                {
                    Header : "RM Name",
                    id: "flow_rel_mgr_name",
                    sortable:true,
                    accessor:row => {return row.flow_rel_mgr_name}
                },
                {
                    Header : "Reg RM Name",
                    id: "reg_flow_rel_mgr_name",
                    sortable:true,
                    accessor:row => {return row.reg_flow_rel_mgr_name}
                },
                {
                    Header : "Total FAs",
                    id: "tot_loans",
                    accessor:row => {return row.tot_loans ? row.tot_loans : 0},
                    width : 70

                },
                {
                    Header : "Reg Date",
                    id: "reg_date",
                    accessor:row => {return row.reg_date ?
                        <span>{format_date(row.reg_date)}</span> : "NA"}
                },
                {
                    Header : "Last Visit",
                    id: "last_visit_date",
                    accessor:row => {return row.last_visit_date ?
                        <span>{format_date(row.last_visit_date)}</span> : "NA"}
                },
                {
                    Header : "Last FA",
                    id: "last_loan_date",
                    accessor:row => {return row.last_loan_date ?
                        <span>{format_date(row.last_loan_date)}</span> : "NA"}
                },
                {
                    Header : "Last FA Paid Date",
                    id: "last_fa_paid_date",
                    width: 120,

                    accessor:row => {return row.last_fa_paid_date ?
                        <span>{format_date(row.last_fa_paid_date)}</span> : "NA"}
                },
                {
                    Header : "Cond Count",
                    id: "cond_count",
                    accessor:row => {return row.cond_count}
                },
                {
                    Header : "Territory",
                    id: "territory",
                    accessor:row => {return row.territory ? dd_value(row.territory) : "NA"}
                },
                {
                    Header : "Location",
                    id: "location",
                    accessor:row => {return row.location ? dd_value(row.location) : "NA"}
                },
                {
                    Header : "Profile Status",
                    id: "profile_status",
                    accessor:row => {return dd_value(row.profile_status)}
                },
                {
                    Header : "Risk Category",
                    id: "risk_category",
                    show:show_risk_category,
                    accessor:row => {return show_risk_category && dd_value(row.risk_category)}
                },
                // {
                //     Header : "Action",
                //     id: "action",
                //     accessor:row => {return <button className='btn product_takeup_submit px-4 py-3' onClick={()=>this.setState({cust_follow_up:row},()=>this.setState({cust_follow_up_modal:true}))} >Call Log</button>}
                // },
            ]
            }
            this.setState({columns,cust_wo_fa_list:response.data.results,copy :response.data.results,cust_wo_fa_table:"show_div",loader:false,no_data:false})
            if(response.data.results.length<=0){
                this.setState({no_data:true})
            }
        })
    }

    handleFilter = (event) => {
        let ids = event.map((e)=>e.value);
        this.setState({flow_rel_mgr_id:ids})
    }

	handleSearch = () =>{
		this.get_borrowers()
	}

    handleClose=()=>{
        this.setState({cust_follow_up_modal:false})
    }
    render(){
        
        return(
			<div className='container containerTopmargin max-width no-padding cust_follow_up'>

                {/* modal */}
                {/* {this.state.cust_follow_up&&
                <Modal className='cust_follow_up'  show={this.state.cust_follow_up_modal} onHide={this.handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header class="modal-header" >
                        <h4 class="modal-title py-2" id="exampleModalLabel">Call Log</h4>
                        <div>
                            <button type="button" className="close d-flex align-items-center" onClick={this.handleClose}>
                                <span aria-hidden="true" className='close_call_log'>&times;</span>
                            </button>
                        </div>
                    </Modal.Header>
                    <Modal.Body class="modal-body">
                        <div className='card p-3'>
                        <fieldset className='border border-dark p-2 my-2'>
                            <legend className='w-auto text-light'>{this.state.cust_follow_up.owner_name}</legend>
                            <div className ="row p-3">
                      <div className = "col-md-6">
                       <div className="form-group row w-100 p-0 m-0 reset-margin justify-content-center">
                          <label className="col-md-6 p-0 m-0"><b>Business Name</b></label>
                          <label className="col-md-6 p-0 m-0 text-right cust_follow_up_font" >{this.state.cust_follow_up.biz_name}</label>  
                       </div>
                       <div className="form-group row w-100 p-0 m-0 reset-margin justify-content-center py-2 ">
                          <label className="col-md-6 p-0 m-0"><b>Mobile Number</b></label>
                          <label className="col-md-6 p-0 m-0 text-right cust_follow_up_font" >{this.state.cust_follow_up.mobile_num} </label>  
                       </div>
                       <div className="form-group row w-100 p-0 m-0 reset-margin justify-content-center">
                          <label className="col-md-6 p-0 m-0"><b>RM Name</b></label>
                          <label className="col-md-6 p-0 m-0 text-right cust_follow_up_font" >{this.state.cust_follow_up.flow_rel_mgr_name} </label>  
                       </div>
                      </div>
                      <div className = "col-md-6">
                       <div className="form-group row w-100 p-0 m-0 reset-margin justify-content-center ">
                          <label className="col-md-6 p-0 m-0"><b>Location</b></label>
                          <label className="col-md-6 p-0 m-0 text-right cust_follow_up_font" >{dd_value(this.state.cust_follow_up.location)} </label>  
                       </div>
                       
                        <div className="form-group row w-100 p-0 m-0 reset-margin justify-content-center py-2 ">
                            <label className="col-md-6 p-0 m-0"><b>Flow Cust ID</b></label>
                            <label className="col-md-6 p-0 m-0 text-right cust_follow_up_font" ><Link  to={"/borrower/indiv/view/"+this.state.cust_follow_up.cust_id}  target="_blank">{this.state.cust_follow_up.cust_id} </Link></label>  
                        </div>
                       
                        <div className="form-group row w-100 p-0 m-0 reset-margin justify-content-center ">
                            <label className="col-md-6 p-0 m-0"><b>Category</b></label>
                            <label className="col-md-6 p-0 m-0 text-right cust_follow_up_font" >{dd_value(this.state.cust_follow_up.category)} </label>  
                        </div>

                       </div>
                       </div>
                        </fieldset>
                        <fieldset className='border border-dark p-2 my-2'>
                            <legend className='w-auto text-light'>{this.state.cust_follow_up.last_loan_doc_id}</legend>
                            <div className='  row m-0 p-0 w-100'>
                                <div className = "col-md-6 my-2">
                                    <div className="form-group row w-100 p-0 m-0 reset-margin justify-content-center ">
                                        <label className="col-md-6 p-0 m-0"><b>Last Loan Doc ID</b></label>
                                        <label className="col-md-6 p-0 m-0 text-right cust_follow_up_font" >{dd_value(this.state.cust_follow_up.last_loan_doc_id)} </label>  
                                    </div>
                                </div>
                                <div className = "col-md-6 my-2">
                                    <div className="form-group row w-100 p-0 m-0 reset-margin justify-content-center ">
                                        <label className="col-md-6 p-0 m-0"><b>Status</b></label>
                                        <label className="col-md-6 p-0 m-0 text-right cust_follow_up_font" >{dd_value(this.state.cust_follow_up.status)} </label>  
                                    </div>
                                </div>
                                <div className = "col-md-6 my-2">
                                    <div className="form-group row w-100 p-0 m-0 reset-margin justify-content-center ">
                                        <label className="col-md-6 p-0 m-0"><b>Last FA Amount</b></label>
                                        <label className="col-md-6 p-0 m-0 text-right cust_follow_up_font" >{dd_value(this.state.cust_follow_up.last_fa)} </label>  
                                    </div>
                                </div>
                                <div className = "col-md-6 my-2">
                                    <div className="form-group row w-100 p-0 m-0 reset-margin justify-content-center ">
                                        <label className="col-md-6 p-0 m-0"><b>Last Loan Date</b></label>
                                        <label className="col-md-6 p-0 m-0 text-right cust_follow_up_font" >{dd_value(this.state.cust_follow_up.last_loan_date)} </label>  
                                    </div>
                                </div>

                        </div>
                        </fieldset>
                        <fieldset className='border border-dark p-2 my-2'>
                            <div className='  row m-0 p-0 w-100'>
                                <div className='col-md-6'>
                                    <label className='col-12 p-0 font mb-0 mt-2' >Reason <span className='product_red_star'>*</span> </label>
                                    <div className='w-100 col-12 m-0 p-0'>
                                        <select className='form-control' onChange={(e)=>this.setState({reason:e.currentTarget.value,next_follow_up_date:null,min_follow_up_date:null},this.changeDaysForReason)}>       
                                        {this.getSelectOptions('cust_follow_up_reasons')}
                                        </select>  
                                    </div>
                                </div>
                                {this.state.min_follow_up_date&&
                                <div className='col-md-6 row d-flex justify-content-between'>
                                    <label className='col p-0 font mb-0 mt-2' >Next Follow Up Date <span className='product_red_star'>*</span> </label>
                                    <label className=' col-2 p-0 m-0 d-flex justify-content-end'><FcCalendar size={24}/></label>
                                    <div className='w-100 col-12 m-0 p-0'>
                                        <input className='col-12 pr-1 p-0 form-control' defaultValue={this.state.min_follow_up_date} onChange={(e)=>this.setState({next_follow_up_date:moment(e). format('YYYY-MM-DD')})}  type='date' 
                                        min={this.state.min_follow_up_date}
                                        id='next_follow_up_date'
                                        onKeyDown={(event) => event.preventDefault()}
                                         disabled/>
                                        
                                    </div>
                                </div>}
                                <div className='mt-4 col d-flex w-100 justify-content-center'>
                                    <button className='btn product_takeup_submit' onClick={()=>this.handleCustFollowUp()} disabled={this.state.resp_day_for_reason=='NA'?false:(!this.state.next_follow_up_date||!this.state.reason)?true:false} >Submit</button>
                                </div>
                        </div>
                        </fieldset>
                        </div>
                    
                    
                    </Modal.Body>
                </Modal>
                }  */}
			<div>
				<h5 className='no-margin headerTitle'>Active Customers Without FA</h5>    
                {get("role_codes") == "relationship_manager"  &&
                <div className='row mt-4 mb-5'>
					<div className="col-md-3">
						<Select onChange={(e) => {this.handleFilter(e)}}
                        value = {this.state.select_value}
						options={this.state.flow_rel_mgr}
						styles={this.select_custom_styles()}
						classNamePrefix="my-select" 
						placeholder="Flow Relationship Manager"
						isMulti/>
                    </div>
					<div className='col-md-2'>
                        <Button disabled={this.state.flow_rel_mgr_id ? false : true} className= "mr-3 btn btn-primary" onClick={this.handleSearch}>SUBMIT</Button>
					</div>
				</div>   
                }       
				{this.state.cust_wo_fa_list.length > 0 &&
					<>
						<div className='d-flex justify-content-between align-items-center' style={{padding:5}}>
							<p className='no-margin text-white'><b>Total Entries : {this.state.cust_wo_fa_list.length}</b></p>
							<div style={{position:'relative'}}>
								<i class="fa-solid fa-magnifying-glass search_pos"></i>
								<input placeholder='Search here' className='search_posInput' onChange={(e)=>this.changeHandler(e.target.value)}></input>
								{/* <button className='btn-std btn btn-primary' onClick={()=>this.resetHandler()}>Reset</button> */}
							</div>
						</div>
                        {this.state.copy.length>0&&
                            <BasicReactTable csv_file={true} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} FName={"Active customers W/o FA"}  pagination={this.state.copy.length>9? true:false} default_page_size = {10} export_csv_datas={this.state.copy ? this.state.copy : []}   searchData={[{title:'Active Customers without FA'}]}/>  

						// <CommonReactTable showPag={true} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} resizable={false} defaultSorted={[{id:"name",desc:false}]} exportCSV={true} csvFile={"Active customers W/o FA"} csvData={this.state.copy ? this.state.copy : []} searchData={[{title:'Active Customers without FA'}]}/>
                        }
             
                    </>
				}
                {this.state.no_data&&<h6 className='text-center text-light p-5 m-5'>No Data Found</h6>}
                {this.state.copy.length<=0&&this.state.loader&&
                <>
                    <p className='text-light  text-center' style={{marginTop:'35vh'}}>Loading...</p>
                    <div className="product_takeup_loader" style={{marginTop:'10vh'}}></div>
                </>
                }
			</div>
		  </div>

			 )
    }

}

const mapDispatchToProps ={
    setValuesIntoRedux
  
  };
  const mapStateToProps = (state) => {
    return ;
  };
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActiveCustWoFAContainer));
